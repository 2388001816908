import React from 'react';

import { Content, Name, Role } from './styles/styledItemCarousel';
import { AvatarImage } from '../avatar-roles/index';

import { UserInfo } from './interface';

const ItemCaurosel: React.FC<{ user: UserInfo; role?: string }> = ({
  user: { user },
  role,
}) => {
  return (
    <Content>
      <AvatarImage
        size="large"
        name={`${user?.firstName} ${user?.lastName}`}
        src={user?.avatar}
      />
      <Name>{`${user?.firstName} ${user?.lastName}`}</Name>
      <Role>{user.role === 'PRO' ? 'Profissional' : 'Paciente'}</Role>
    </Content>
  );
};

export default ItemCaurosel;
