import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  background: #fbfbfb;
  border: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 50px;

  svg {
    margin-right: 10px;
  }
`;

export const Body = styled.form`
  position: relative;
  padding-top: 20px;
  font-size: 16px;
  font-weight: bold;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'a a a' 'b c footer';
  justify-items: center;
`;

export const Footer = styled.div`
  grid-area: footer;
  width: 100%;
  grid-column-start: 3;
  grid-column-end: 4;
  max-width: 300px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  #action {
    width: 200px;
  }
`;
