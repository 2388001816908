import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CompanionsAppointment as UsecaseRemoteCompanionsAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteCompanionsAppointment } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteCompanionsAppointment =
  (): UsecaseRemoteCompanionsAppointment =>
    new RemoteCompanionsAppointment(
      makeApiUrl('/appointments/{appointmentId}/companions'),
      makeHttpClient(),
    );
