import { GetAllUsers } from '~/domain/usecases/users/redux';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  UsersTypes,
} from '~/domain/interfaces/redux/users/getAll';

export const getAllRequest = (payload: GetAllUsers.Params): iActionGetAll => ({
  type: UsersTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllUsers.Model,
): iActionGetAllSuccess => ({
  type: UsersTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: UsersTypes.GETALL_FAILED,
});
