export const formattedUrl = (url: string): string => {
  if (url.includes('https://www.youtube.com/')) {
    return url.replace('watch?v=', 'embed/');
  }
  if (url.includes('https://youtu.be/')) {
    return url.replace('youtu.be/', 'youtube.com/embed/');
  }

  return url;
};
