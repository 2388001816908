import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteAppointment as UseCaseRemoteDeleteAppointment } from '~/domain/usecases/appointment/remote';
import { DeleteAppointment as UsecaseReduxDeleteAppointment } from '~/domain/usecases/appointment/redux';

import { RemoteDeleteAppointment } from '~/data/repository/appointment';
import { ReduxDeleteAppointment } from '~/data/store/reducer/appointment/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteAppointment = (): UseCaseRemoteDeleteAppointment =>
  new RemoteDeleteAppointment(makeApiUrl('/appointments'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxDeleteAppointment = (): UsecaseReduxDeleteAppointment =>
  new ReduxDeleteAppointment();
