import { Dispatch } from 'redux';
import { SelectUser } from '~/domain/usecases/auth/redux';
import { iActionSelectUser } from '~/domain/interfaces/redux/auth/SelectUser';
import { selectUser } from '~/data/store/reducer/auth/actions/selectUser';
import store from '~/data/store';

export class ReduxSelectUser implements SelectUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: SelectUser.Params): iActionSelectUser =>
    this.send(selectUser(params));
}
