import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetCitiesByUf as UsecaseRemoteGetCitiesByUf } from '~/domain/usecases/externalServices/remote';

import { RemoteGetCitiesByUf } from '~/data/repository/externalServices/RemoteGetCitiesByUf';

/**
 * send request via API.
 */
export const makeRemoteGetCitiesByUf = (): UsecaseRemoteGetCitiesByUf =>
  new RemoteGetCitiesByUf(
    makeApiUrl('/external/IBGE/states/{stateId}/cities/'),
    makeHttpClient(),
  );
