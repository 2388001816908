import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { iStore } from '~/domain/interfaces/models';
import { RegisteredParticipant } from './Lists';
import { iListRegisteredParticipant } from '../ListProfessional';
import { ConnectParticipant } from './mapper/MapperListParticipant';
import { ListEmpty } from '../componentEmpty';
import { translator } from '../i18n';

const ListRegisteredParticipant: React.FC = () => {
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );
  useEffect(() => {
    makeReduxDataPagination().set([]);
    // return () => {};
  }, []);

  const { loading } = useSelector((store: iStore) => store.users);
  return dataPagination.length === 0 && !loading ? (
    <ListEmpty
      message={translator(
        'Os pacientes cadastrados aparecerão aqui. No momento não há nenhum',
      )}
    />
  ) : (
    <RegisteredParticipant dataList={dataPagination} />
  );
};

export default ListRegisteredParticipant;
