import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/specialty/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/specialty/getAll';

import { GetAllSpecialty } from '~/domain/usecases/specialty/redux';

export class ReduxGetAllSpecialty implements GetAllSpecialty {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllSpecialty.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
