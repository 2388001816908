import { GetAllAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGetAllAppointment implements GetAllAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllAppointment.Model>;

  constructor(url: string, httClient: HttpClient<GetAllAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (
    params: GetAllAppointment.Params,
  ): Promise<GetAllAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
