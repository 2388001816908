import { IconButton, Textarea, Typography } from '@wisecare/design-system-web';
import React from 'react';
import { DataContainer, FileCardContainer, FileCardHeader } from './styles';

export const FileCard: React.FC = () => {
  return (
    <FileCardContainer>
      <FileCardHeader>
        <DataContainer spacingY="0">
          <Typography variant="b3_14medium">Nome do arquivo</Typography>
          <Typography variant="b4_14regular" className="tw-text-black-4">
            Exames de sangue
          </Typography>
        </DataContainer>
        <IconButton variant="primary" icon="visibility" size="sm" />
      </FileCardHeader>
      <Textarea
        disabled
        value="Lorem ipsum dolor sit amet consectetur. Cras sagittis a quisque tempor quis facilisis sed. Dictum egestas turpis condimentum eget et consectetur. Non ut scelerisque ut ipsum pharetra mi ut tortor blandit. Eu vulputate neque a in turpis egestas tellus quisque."
      />
    </FileCardContainer>
  );
};
