import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { StartAppointment as UsecaseRemoteStartAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteStartAppointment } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteStartAppointment = (): UsecaseRemoteStartAppointment =>
  new RemoteStartAppointment(makeApiUrl('/appointments'), makeHttpClient());
