import { call, put } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/professionalList/getAll';
import { GetAllProfessional } from '~/domain/usecases/professionalList/redux/GetAllProfessional';
import { makeRemoteGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import {
  getAllSucess,
  getAllFailed,
} from '~/data/store/reducer/Professional/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllProfessional = makeRemoteGetAllProfessionals();

  try {
    const response: GetAllProfessional.Model = yield call(
      remoteGetAllProfessional.getAll,
      action.payload,
    );

    yield put(getAllSucess(response));
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
