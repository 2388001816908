import { Dispatch } from 'redux';
import store from '~/data/store';
import { UpdateRequest } from '~/data/store/reducer/appointment/actions/update';

import { iActionUpdate } from '~/domain/interfaces/redux/appointment/update';

import { UpdateAppointment } from '~/domain/usecases/appointment/redux';

export class ReduxUpdateAppointment implements UpdateAppointment {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateAppointment.Params): iActionUpdate =>
    this.send(UpdateRequest(params));
}
