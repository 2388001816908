import styled, { css } from 'styled-components';
import { Button } from '../../UI';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 28.0625rem;

  background-color: #fdfdfd;

  border: 1px solid #dedede;
  border-radius: 0.75rem;

  z-index: 5;
  overflow: hidden;

  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  max-height: 35rem;
  overflow-y: auto;
`;

export const SectionFilter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 1.5rem;

  border-bottom: 1px solid #dedede;

  &:last-child {
    border-bottom: none;
  }
`;

export const SectionFilterLabel = styled.label`
  color: #222529;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
`;

export const RestoreFilters = styled(Button)`
  background-color: #fdfdfd;
  border: 1px solid #b4b7b9;
  color: #222529;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  &:hover {
    background-color: #fdfdfd;
    border: 1px solid #b4b7b9;
    color: #222529;
  }
`;

export const FilterChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 1rem;

  gap: 1rem;
`;

type FilterChipsProps = {
  active?: boolean;
};

export const FilterChips = styled.button<FilterChipsProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  background-color: #fdfdfd;
  border: 1px solid #dedede;
  color: #222529;

  font-size: 0.875rem;

  & svg {
    margin-right: 0.5rem;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: #e8f6ec;
      border-color: transparent;
      color: #116427;
      font-weight: 500;
    `};
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: #fdfdfd;

  position: sticky;
  bottom: 0;

  padding: 1.5rem;
  border-top: 1px solid #dedede;
`;
