import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
`;

export const Content = styled.form`
  min-width: 450px;
  min-height: 523px;
  position: fixed;
  padding: 20px 30px;
  border-radius: 5px;
  background: #ffffff;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
`;

export const Form = styled.form`
  width: 520px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  padding: 15px;
  Input {
    background: white;
  }
  Select {
    background: white;
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
`;

export const Body = styled.div`
  width: 100%;
  margin-top: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;

  #button_cancel {
    color: #ec5c52;
  }

  #button_cancel:hover {
    color: white;
    transition: 0.2s;
  }
`;
