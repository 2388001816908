import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { JoinOnCallRequester as UsecaseRemoteJoinOnCallRequester } from '~/domain/usecases/onCallRequester/remote';
// import { JoinOnCallRequester as UsecaseReduxJoinOnCallRequester } from '~/domain/usecases/onCallRequester/redux';

import { RemoteJoinOnCallRequester } from '~/data/repository/onCallRequester';
// import { ReduxJoinOnCallRequester } from '~/data/store/reducer/onCallRequester/usecases';

/**
 * send request via API.
 */
export const makeRemoteJoinOnCallRequester =
  (): UsecaseRemoteJoinOnCallRequester =>
    new RemoteJoinOnCallRequester(
      makeApiUrl(
        '/oncall/{onCallId}/requesters/{requesterId}/requests/{requestId}/JOIN',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxJoinOnCallRequester =
  (): UsecaseReduxJoinOnCallRequester =>
    new ReduxJoinOnCallRequester(); */
