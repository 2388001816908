import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateOnCallRequest as UsecaseRemoteCreateOnCallRequest } from '~/domain/usecases/onCallRequest/remote';

import { RemoteCreateOnCallRequest } from '~/data/repository/onCallRequest';

/**
 * send request via API.
 */
export const makeRemoteCreateOnCallRequest =
  (): UsecaseRemoteCreateOnCallRequest =>
    new RemoteCreateOnCallRequest(
      makeApiUrl('/oncall/requests'),
      makeHttpClient(),
    );
