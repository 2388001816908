import { Dispatch } from 'redux';
import store from '~/data/store';
import { SetData } from '~/domain/usecases/appointment/redux/SetData';
import { iActionSetData } from '~/domain/interfaces/redux/appointment/setData';

import { setData } from '../actions/setData';

export class ReduxSetData implements SetData {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  set = (params: SetData.Params): iActionSetData => this.send(setData(params));
}
