import { call, put, select } from 'redux-saga/effects';
import { iActionGet } from '~/domain/interfaces/redux/specialty/get';

export function* onGetRequest(action: iActionGet): Generator {
  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetFailed() {}
