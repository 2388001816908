/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import {
  DropzoneInputProps as ReactDropzoneProps,
  useDropzone,
} from 'react-dropzone';

import axios from 'axios';
import { UploadImageSector } from '~/presentation/base/icons';

import {
  DropContainer,
  DropInput,
  DropText,
  ContentFiles,
  PreviewImage,
} from './styled/StyledDropzone';

export interface fileProps extends ReactDropzoneProps {
  handleFile?: (file: any) => void;
  defaultImage?: string;
  id?: string;
}

const LogoDropzone: React.FC<fileProps> = ({
  handleFile,
  defaultImage,
  id,
  ...rest
}) => {
  const [file, setFile] = useState<any>([]);

  useEffect(() => {
    if (!defaultImage) return;

    if (typeof defaultImage === 'string') {
      axios
        .get(`${defaultImage}`, {
          headers: { 'Content-Type': 'application/octet-stream' },
          responseType: 'blob',
        })
        .then(res => {
          const value = new File([res.data], 'defaultImage.png', {
            type: 'image/png',
          });
          const updatedFileValue = Object.assign(value, {
            preview: defaultImage,
          });

          setFile([updatedFileValue]);
        });
    } else {
      setFile([defaultImage]);
    }
  }, [defaultImage]);

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const updatedFile = acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      );

      setFile(updatedFile);
      handleFile?.(acceptedFiles[0]);
    },
    [handleFile],
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const files = file.map((file: any) => (
    <div key={file.name}>
      <PreviewImage src={file.preview} />
    </div>
  ));

  const { getRootProps, getInputProps, inputRef, isFileDialogActive } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: ['image/jpeg', 'image/png'],
      maxFiles: 1,
    });

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <DropContainer
        {...rest}
        id={id}
        htmlFor={id}
        {...getRootProps()}
        color={rest.color}
      >
        <DropInput
          {...getInputProps()}
          ref={inputRef}
          isFileDialogActive={isFileDialogActive}
        />
        {files.length > 0 ? (
          files[0]
        ) : (
          <ContentFiles>
            <UploadImageSector className="icon-upload-file" />
            <DropText>
              <p>Arraste a imagem ou</p>
              <p style={{ color: '#1756E8' }}>clique para fazer upload</p>
            </DropText>
          </ContentFiles>
        )}
      </DropContainer>
    </div>
  );
};

export default LogoDropzone;
