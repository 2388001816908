/* eslint-disable import/no-duplicates */
import React, { useCallback, useEffect, useMemo } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { format } from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconBtnClose } from '~/presentation/base/icons';

import { Shift } from '~/domain/interfaces/models/Shifts';
import { makeReduxUpdateShifts } from '~/main/factories/usecases/shifts';
import { closeModal } from '~/utils/closeModal';
import { getLocale } from '~/utils/getLocale';
import { updateShiftValidator } from '~/validation/validators/shifts';
import { Button, Select } from '../UI';
import { V4hSpin } from '../spin';
import {
  Box,
  ButtonClose,
  ButtonView,
  Container,
  Content,
  Divider,
  InputContent,
  MessageError,
  SelectLabel,
  Text,
  TopContent,
} from './styles';
import SearchSelect from '../UI/searchSelect';
import _ from 'lodash';

type Props = {
  message: iMessage;
};

const EditShift: React.FC<Props> = ({ message }) => {
  const { user, professionals } = useSelector(
    (store: iStore) => store.auth.info,
  );
  const { results } = useSelector((store: iStore) => store.professional);
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);
  const { loading, date: dateRedux } = useSelector(
    (store: iStore) => store.shifts,
  );

  const { active: actualModalActive, data, actionOk } = message;
  const shift = data as Shift['shift'];
  const modalName = MessageOptions.editShifts;
  const isActive = actualModalActive === modalName;

  const imAProfessional = user?.mainRole === 'PRO';

  const professionalId = useMemo(() => {
    if (!professionals) return null;

    const id = professionals?.find(item => item.org.id === orgId)?.id;

    return id;
  }, [orgId, professionals]);

  const {
    getValues,
    setValue,
    setError,
    handleSubmit,
    register,
    errors,
    watch,
    reset,
    control,
    formState: { isDirty },
  } = useForm({
    resolver: zodResolver(updateShiftValidator),
    reValidateMode: 'onChange',
    mode: 'all',
    shouldFocusError: true,
    criteriaMode: 'firstError',
    defaultValues: {
      begin: shift?.begin?.slice(0, 5),
      end: shift?.end?.slice(0, 5),
      professional: imAProfessional ? professionalId : shift?.professional?.id,
    },
  });

  const handleSetValues = useCallback(() => {
    setValue('begin', shift?.begin?.slice(0, 5));
    setValue('end', shift?.end?.slice(0, 5));
    setValue(
      'professional',
      imAProfessional ? professionalId : shift?.professional?.id,
    );
  }, [
    imAProfessional,
    professionalId,
    setValue,
    shift?.begin,
    shift?.end,
    shift?.professional?.id,
  ]);

  useEffect(() => {
    handleSetValues();
  }, [handleSetValues]);

  const begin = watch('begin');

  const dateIsLessThan = (start: string, end: string) => {
    const [startHour, startMinute] = (start ?? '').split(':');
    const [endHour, endMinute] = (end ?? '').split(':');

    const startDate = new Date();
    startDate.setHours(Number(startHour));
    startDate.setMinutes(Number(startMinute));

    const endDate = new Date();
    endDate.setHours(Number(endHour));
    endDate.setMinutes(Number(endMinute));

    return startDate.getTime() > endDate.getTime();
  };

  const createLabelsHours = useMemo(() => {
    return Array.from({ length: 48 }).map((__, index) => {
      const hour = index % 2 === 0 ? index / 2 : (index - 1) / 2;
      const minute = index % 2 === 0 ? '00' : '30';
      const label = `${String(hour).padStart(2, '0')}:${minute}`;

      const disabled = dateIsLessThan(begin, label);

      return {
        label,
        disabled,
      };
    });
  }, [begin]);

  const handleCloseModal = () => {
    reset();
    closeModal();
  };

  const handleSubmitForm = () => {
    const shifts = makeReduxUpdateShifts();

    const endIsLessThanBegin = dateIsLessThan(
      getValues().begin,
      getValues().end,
    );

    if (endIsLessThanBegin) {
      setError('end', {
        type: 'manual',
        message: 'O horário final deve ser maior que o horário inicial.',
      });
      return;
    }

    shifts.update({
      shiftId: shift.id,
      onCallId: shift.onCall.id,
      body: {
        ...getValues(),
        professional: imAProfessional
          ? Number(professionalId)
          : Number(getValues().professional),
        upcomingDates: false,
        begin: getValues().begin.padEnd(8, ':00'),
        end: getValues().end.padEnd(8, ':00'),
      },
    });
    actionOk?.();
    handleCloseModal();
  };

  const dateFormat = () => {
    const [year, month, day] = shift?.date?.split('-').map(Number) ?? [];
    const date = new Date(year, month - 1, day);

    const locale: Record<string, Locale> = {
      pt: ptBR,
      en: enUS,
      es,
    };

    return format(date, 'EEEE, dd/MM/yyyy', {
      locale: locale[getLocale().slice(0, 2)],
    });
  };

  const generateProfessionaltSelectOptions = useCallback(() => {
    if (imAProfessional) {
      return [
        {
          value: Number(imAProfessional),
          label: `${user?.firstName} ${user?.lastName}`,
        },
      ];
    }
    return _.sortBy(
      results?.flatMap(value => {
        return [
          {
            value: value.professional.id,
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [results]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionaltSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionaltSelectOptions, watch]);

  return isActive ? (
    <Container>
      <Content>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <Box>
            <TopContent>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text>Editar plantão</Text>
                <p style={{ fontSize: 14, textTransform: 'capitalize' }}>
                  {dateFormat()}
                </p>
              </div>
              <ButtonClose onClick={handleCloseModal}>
                <IconBtnClose />
              </ButtonClose>
            </TopContent>
            <SelectLabel>Profissional</SelectLabel>
            <SearchSelect
              name="professional"
              height="40px"
              register={() => register('professional')}
              placeholder="Selecione um profissional"
              onChange={e => {
                setValue('professional', Number(e?.value));
              }}
              error={!!errors.professional}
              message={errors.professional?.message}
              options={generateProfessionaltSelectOptions()}
              value={professionalMultiselectSelected}
              isDisabled={imAProfessional}
            />
          </Box>
          <Divider />
          <Box>
            <InputContent>
              <div>
                <SelectLabel>Inicia em</SelectLabel>
                <Controller
                  name="begin"
                  control={control}
                  defaultValue={shift?.begin?.slice(0, 5)}
                  render={props => (
                    <Select
                      height="40px"
                      width="168px"
                      error={!!errors.begin}
                      // message={errors.begin?.message}
                      register={() => register('begin')}
                      {...props}
                    >
                      {createLabelsHours.map(({ label }) => (
                        <option key={label} value={label}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </div>
              <div>
                <SelectLabel>Termina em</SelectLabel>
                <Controller
                  name="end"
                  control={control}
                  defaultValue={shift?.end?.slice(0, 5)}
                  render={props => (
                    <Select
                      height="40px"
                      width="168px"
                      error={!!errors?.end}
                      // message={errors?.end?.message}
                      register={() => register('end')}
                      {...props}
                    >
                      {createLabelsHours.map(({ label, disabled }) => (
                        <option key={label} disabled={disabled}>
                          {label}
                        </option>
                      ))}
                    </Select>
                  )}
                />
              </div>
            </InputContent>
            {errors && (
              <MessageError>
                {errors.end?.message || errors.begin?.message}
              </MessageError>
            )}
          </Box>
          <Divider />
          <ButtonView>
            <Button type="submit" disabled={loading || !isDirty}>
              {loading ? (
                <V4hSpin fullScreen={false} size="1.2rem" />
              ) : (
                'Salvar'
              )}
            </Button>
            <Button variant="secundary" onClick={handleCloseModal}>
              Cancelar
            </Button>
          </ButtonView>
        </form>
      </Content>
    </Container>
  ) : null;
};

export default EditShift;
