import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllSupportDocsByCode as UseCaseReduxGetAllSupportDocsByCode } from '~/domain/usecases/supportDoc/redux';
import { GetAllSupportDocsByCode as UseCaseRemoteGetAllSupportDocsByCode } from '~/domain/usecases/supportDoc/remote';

import { ReduxGetAllSupportDocsByCode } from '~/data/store/reducer/supportDoc/usecases/ReduxGetAllSupportDocsByCode';
import { RemoteGetAllSupportDocsByCode } from '~/data/repository/supportDoc';

export const makeRemoteGetAllSupportDocsByCode =
  (): UseCaseRemoteGetAllSupportDocsByCode =>
    new RemoteGetAllSupportDocsByCode(
      makeApiUrl('/supportDocs/details/{code}'),
      makeHttpClient(),
    );

export const makeReduxGetAllSupportDocsByCode =
  (): UseCaseReduxGetAllSupportDocsByCode => new ReduxGetAllSupportDocsByCode();
