import React from 'react';

export const MapUsers = (appointment: any): any[] => {
  const arr: any[] = [
    {
      id: appointment.professional.id,
      firstName: appointment.professional.firstName,
      lastName: appointment.professional.lastName,
      avatar: appointment.professional.avatar || '',
      role: 'PRO',
    },
    {
      id: appointment.consultant.id,
      firstName:
        appointment.consultant.firstName ??
        appointment.appointment.info?.name?.split(' ')?.[0],
      lastName:
        appointment.consultant.lastName ??
        appointment.appointment.info?.name?.split(' ')?.[
          appointment.appointment.info?.name?.split(' ')?.length - 1
        ],
      avatar: appointment.consultant.avatar || '',
      role: 'CON',
    },
  ];

  return arr;
};
