import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
`;

export const Header = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #efefef;
  color: #7a7a7a;
  gap: 10px;

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #000;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Footer = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 25em;
  max-height: 15em;
  width: 100%;
  height: 100%;

  position: fixed;
  background: white;
  padding: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 15px;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  gap: 10px;
`;

export const CloseButton = styled.div`
  padding: 10px 18px 5px 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #c9c9c9;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`;
