import { InviteCompanionsAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteInviteCompanionsAppointment
  implements InviteCompanionsAppointment
{
  private readonly url: string;

  private readonly httpClient: HttpClient<InviteCompanionsAppointment.Model>;

  constructor(
    url: string,
    httClient: HttpClient<InviteCompanionsAppointment.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  inviteCompanions = async (
    params: InviteCompanionsAppointment.Params,
  ): Promise<InviteCompanionsAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url
        .replace('{appointmentId}', params.appointmentId)
        .replace('{companionId}', params.companionId)}`,
      method: 'post',
      body: {
        ...params,
        appointmentId: undefined,
        companionId: undefined,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
