import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import { IconPlus } from '~/presentation/base/icons';
import { DocsEmpty } from '~/presentation/components/componentEmpty';
import { DocumentsList } from '~/presentation/components/documents-list';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { Attendiment } from '~/presentation/roles/manager/pages/ListProfessional/StyledSchedule';
import { iClinicalDocs } from '~/presentation/roles/professional/pages/appointment/interface';

interface ownProps {
  docs: iClinicalDocs[];
  openFunction: Function;
}

interface iState {
  appointmentId: string;
  professional: string;
  consultant: string;
  consultantId: string;
}

const ClinicalDocuments: React.FC<ownProps> = ({ docs, openFunction }) => {
  const stateParams = useLocation<iState>().state;
  const message = translator(
    'Você não tem nenhum documento associado ao atendimento.',
  );
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  function permissions() {
    if (role === 'ORG') {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '20px',
          }}
        >
          {docs.length ? (
            <DocumentsList
              type="clinical-documents"
              docs={docs}
              openFunction={openFunction}
            />
          ) : (
            <DocsEmpty message={message} />
          )}
        </div>
      );
    }
    return (
      // TODO: Adicionar um scroll na lista dos documentos clinicos
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end',
          gap: '20px',
        }}
      >
        <Button
          id="new_clinicalDocs"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            lineHeight: '100%',
            fontWeight: 500,
            fontStyle: 'normal',
          }}
          rounded
          size="large"
          onClick={() => {
            History.getHistory().push('/clinicaldoc/new', {
              appointmentId: stateParams.appointmentId,
              professional: stateParams.professional,
              consultant: stateParams?.consultantId,
            });
          }}
        >
          <Attendiment>
            <IconPlus
              width="14px"
              height="14px"
              style={{
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
              }}
            />
          </Attendiment>
          {`${translator('Novo documento clínico')}`}
        </Button>
        {docs.length ? (
          <DocumentsList
            type="clinical-documents"
            docs={docs}
            openFunction={openFunction}
          />
        ) : (
          <DocsEmpty message={message} />
        )}
      </div>
    );
  }

  return permissions();
};

export default ClinicalDocuments;
