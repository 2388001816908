import { Dispatch } from 'redux';
import store from '~/data/store';
import { UserQueue } from '~/domain/usecases/waitingRoom/redux';

import {
  iActionAddUserQueue,
  iActionRemoveUserQueue,
} from '~/domain/interfaces/redux/waitingroom/userQueue';

import {
  AddUserQueue,
  RemoveUserQueue,
} from '~/data/store/reducer/waitingroom/action/userQueue';

export class ReduxUserQueue implements UserQueue {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  add = (params: UserQueue.Params): iActionAddUserQueue =>
    this.send(AddUserQueue(params));

  remove = (params: UserQueue.Params): iActionRemoveUserQueue =>
    this.send(RemoveUserQueue(params));
}
