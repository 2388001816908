import React, { SelectHTMLAttributes, useState } from 'react';
import IconArrowSelect from '~/presentation/base/icons/arrowSelect.svg';

import { Container, Label, Message, SelectContainer } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name?: string;
  label?: string;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  labelStyle?: React.CSSProperties;
  fontSize?: string;
  borderLeft?: boolean;
  backgroundIcon?: boolean;
  ref?: React.Ref<HTMLSelectElement>;
  register?: () => any;
}

const Select: React.FC<SelectProps> = ({
  children,
  name,
  label,
  message,
  error = false,
  width = '100%',
  height = '48px',
  fontSize = '16px',
  borderLeft = true,
  backgroundIcon = true,
  register,
  required = false,
  labelStyle = {},
  ...rest
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '4px' : '0px';
  // const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;

  //
  // if the component are use react-hook-form.
  if (register) register();

  if (required) label = `${label}*`;

  return (
    <Container width={width}>
      {label && <Label style={labelStyle}>{label}</Label>}
      <SelectContainer
        style={{ marginTop: mTop }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
        fontSize={fontSize}
        borderLeft={borderLeft}
        icon={IconArrowSelect}
      >
        <select
          {...rest}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          name={name}
        >
          {children}
        </select>
        <Message error={error}>{message}</Message>
      </SelectContainer>
    </Container>
  );
};

export default Select;
