import { UpdateUserAvatar } from '~/domain/usecases/users/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
  UnProcessableEntity,
} from '~/domain/errors';

export class RemoteUpdateUserAvatar implements UpdateUserAvatar {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateUserAvatar.Model>;

  constructor(url: string, httClient: HttpClient<UpdateUserAvatar.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  post = async (
    params: UpdateUserAvatar.Params,
  ): Promise<UpdateUserAvatar.Model> => {
    const { userId } = params;
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${userId}/avatar`,
      method: 'post',
      body: params.avatar,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
