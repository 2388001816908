import React from 'react';

import { Button } from '~/presentation/components/UI';
import { History } from '~/main/routes';
import { Footer } from './style/StyledNavigation';
import { translator } from '../i18n';

interface ownProps {
  back?: () => any;
  isFinish?: boolean;
}

export const Navigator: React.FC<ownProps> = ({
  back,
  isFinish,
}): JSX.Element => {
  return (
    <Footer>
      <div className="left">
        <Button
          id="btn_cancel"
          className="btnAction"
          onClick={() => History.push('/professional')}
          variant="secundary"
          color="red"
          rounded
        >
          {translator('Cancelar')}
        </Button>
      </div>
      <div className="right">
        {back && (
          <Button
            id="btn_prev_step"
            type="button"
            className="btnAction"
            variant="secundary"
            onClick={back}
            rounded
          >
            {translator('Voltar')}
          </Button>
        )}
        <Button id="btn_next_step" type="submit" className="btnAction" rounded>
          {isFinish ? translator('Concluir') : translator('Próximo')}
        </Button>
      </div>
    </Footer>
  );
};
