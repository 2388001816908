import { Dispatch } from 'redux';
import store from '~/data/store';
import { createSocketChatMessageRequest } from '~/data/store/reducer/chat/actions/socketCreateMessage';

import { iActionSocketCreateMessage } from '~/domain/interfaces/redux/chat/socketCreateMessage';

import { CreateSocketChatMessage } from '~/domain/usecases/chat/redux';

export class ReduxSocketCreateChatMessage implements CreateSocketChatMessage {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  createMessage = (
    params: CreateSocketChatMessage.Params,
  ): iActionSocketCreateMessage =>
    this.send(createSocketChatMessageRequest(params));
}
