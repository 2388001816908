import styled, { css } from 'styled-components';

export const WaitingRoomContentStyle = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  border-radius: 0.63rem;
  overflow: hidden;
  border: 1px solid #dedede;
`;

export const WaitingRoomContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dedede;
`;
export const WaitingRoomContentTabs = styled.div``;

export const WaitingRoomContentBody = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #222529;

  object-fit: cover;

  & video#webcam {
    height: 100%;
  }
`;
export const WaitingRoomContentBodyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & > p {
    margin-top: 1.25rem;
    max-width: 15rem;
    text-align: center;
    white-space: wrap;
  }
`;

export const WaitingRoomContentFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid #dedede;
`;
export const WaitingRoomContentFooterButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

type WaitingRoomContentFooterButtonProps = {
  isActive?: boolean;
};

export const WaitingRoomContentFooterButton = styled.button<WaitingRoomContentFooterButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 1.2px solid #b4b7b9;
  flex-shrink: 0;
  background-color: #fdfdfd;

  transition: background 0.2s ease;

  &:hover {
    background-color: #ecedee;

    ${({ isActive }) => (isActive ? css`` : css``)}

    & svg path {
      fill: ${({ isActive }) => (isActive ? '#222529' : '#FD3939')};
    }
  }

  &:focus-visible {
    border: 2.4px solid #3d94fd;
    box-shadow: 0 0 14px #3a8aea;

    & svg path {
      fill: ${({ isActive }) => (isActive ? '#222529' : '#FD3939')};
      stroke: ${({ isActive }) => (isActive ? '#222529' : '#FD3939')};
    }
  }

  &:disabled {
    background-color: transparent;
    border-color: #b4b7b9;
    & svg path,
    & svg rect {
      fill: #b4b7b9;
      stroke: #b4b7b9;
    }

    cursor: default;
  }
`;
