import { Dispatch } from 'redux';
import store from '~/data/store';
import { setData } from '~/data/store/reducer/message/actions/setData';

import { iActionSetData } from '~/domain/interfaces/redux/message/setData';

import { SetData } from '~/domain/usecases/message/redux';

export class ReduxSetData implements SetData {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setData = (params: SetData.Params): iActionSetData =>
    this.send(setData(params));
}
