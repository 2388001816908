import ptBrTranslations from './pt-br';
import enUsTranslations from './en-us';
import enTranslations from './es';

export default {
  // Brazil
  'pt-BR': ptBrTranslations,
  'pt-br': ptBrTranslations,
  pt: ptBrTranslations,
  PT: ptBrTranslations,

  // Unites States
  'en-US': enUsTranslations,
  'en-us': enUsTranslations,

  // Spain
  es: enTranslations,
  'es-ES': enTranslations,
  'es-es': enTranslations,

  // Dominican Republic
  'es-DO': enTranslations,
  'es-do': enTranslations,

  // Guatemala
  'es-GT': enTranslations,
  'es-gt': enTranslations,

  // Honduras
  'es-HN': enTranslations,
  'es-hn': enTranslations,

  // México
  'es-MX': enTranslations,
  'es-mx': enTranslations,

  // Panamá
  'es-PA': enTranslations,
  'es-pa': enTranslations,

  // Peru
  'es-PE': enTranslations,
  'es-pe': enTranslations,

  // Sweden
  'es-SV': enTranslations,
  'es-SE': enTranslations,
  'es-sv': enTranslations,
  'es-se': enTranslations,
};
