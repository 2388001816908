import { GetDashboardProducts } from '~/domain/usecases/dashboard/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UnexpectedError, NotFound, Forbidden } from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteGetDashboardProducts implements GetDashboardProducts {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetDashboardProducts.Model>;

  constructor(url: string, httClient: HttpClient<GetDashboardProducts.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: GetDashboardProducts.Params,
  ): Promise<GetDashboardProducts.Model> => {
    const initialDate = new Date('1950-10-10');
    const today = new Date();

    let queryParams = {
      begin: initialDate.toISOString(),
      end: today.toISOString(),
    };

    if (
      params.filter &&
      params.filter.year?.length &&
      params.filter.month?.length
    ) {
      queryParams = {
        begin: new Date(
          params.filter.year[0],
          params.filter.month[0] - 1,
          1,
        ).toISOString(),
        end: new Date(
          params.filter.year[0],
          params.filter.month[0],
        ).toISOString(),
      };
    }

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.filter?.org}/products`,
      method: 'get',
      params: queryParams,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
