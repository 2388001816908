import { add, format, isBefore } from 'date-fns';
import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import { iStore } from '~/domain/interfaces/models';

import { makeReduxCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';
import { typeArrayTime } from '../DateAndTime';

import { ownProps } from '../RegiterAppointment';

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const userId = useSelector((store: iStore) => store.auth.info.user!.id);

    Component.defaultProps = {
      getTimes: (e: string) => setUpArrayTimes(e),
      appointment: makeReduxCreateAppointment(),
      userId,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

const setUpArrayTimes = (dateIsoString: string) => {
  const time: typeArrayTime[] = [];

  if (!(dateIsoString.length > 0)) return time;
  let start = new Date(2020, 1, 1);
  let i = 0;
  let disabled = false;
  while (i <= 95) {
    const timeFormat = format(start, 'HH:mm');
    const date = new Date(
      new Date(dateIsoString).getFullYear(),
      new Date(dateIsoString).getMonth(),
      new Date(dateIsoString).getDate(),
      Number(timeFormat.split(':')[0]),
      Number(timeFormat.split(':')[1]),
    );

    if (isBefore(date, new Date())) {
      disabled = true;
    } else {
      disabled = false;
    }

    const obj = {
      disabled,
      time: date.toISOString().replace('000Z', `${date.getTimezoneOffset()}Z`),
      timeFormat: format(start, 'HH:mm'),
    };
    time.push(obj);
    start = add(start, { minutes: 15 });
    i += 1;
  }
  return time;
};
