import { DeleteShifts } from '~/domain/usecases/shifts/redux';

import {
  ShiftsTypes,
  iActionDeleteShifts,
  iActionDeleteShiftsFailed,
  iActionDeleteShiftsSuccess,
} from '~/domain/interfaces/redux/shifts/delete';

export const deleteShiftsByIdRequest = (
  payload: DeleteShifts.Params,
): iActionDeleteShifts => ({
  type: ShiftsTypes.DELETE,
  payload,
});

export const deleteShiftsByIdSuccess = (
  params: DeleteShifts.Model,
): iActionDeleteShiftsSuccess => ({
  type: ShiftsTypes.DELETE_SUCCESS,
  payload: params,
});

export const deleteShiftsByIdFailed = (): iActionDeleteShiftsFailed => ({
  type: ShiftsTypes.DELETE_FAILED,
});
