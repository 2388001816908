import { call, put, select } from 'redux-saga/effects';
import { iActionList } from '~/domain/interfaces/redux/onCallSpecialist/list';
import { ListOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote/ListOnCallSpecialist';
import { makeRemoteListOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/ListOnCallSpecialistFactory';
import {
  listSuccess,
  listFailed,
} from '~/data/store/reducer/onCallSpecialist/actions/list';

export function* onList(action: iActionList) {
  const remoteListOnCallSpecialist = makeRemoteListOnCallSpecialist();

  try {
    const response: ListOnCallSpecialist.Model = yield call(
      remoteListOnCallSpecialist.list,
      action.payload,
    );
    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
