import { iSupportDoc } from '~/domain/interfaces/models/SupportDoc';
import { SupportTypes } from '~/domain/interfaces/redux/supportDoc/types';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { SupportDocActions } from './actions';

export const initialState: iSupportDoc = {
  loading: false,
  pageSize: 0,
  totalItems: 0,
  results: [],
};

const reducer = (
  state = initialState,
  action: SupportDocActions,
): iSupportDoc => {
  switch (action.type) {
    case SupportTypes.CREATE:
      return { ...state, loading: true };
    case SupportTypes.CREATE_SUCCESS:
      return { ...state, loading: false };
    case SupportTypes.CREATE_FAILED:
      return { ...state, loading: false };

    case SupportTypes.GETALL:
      return { ...state, loading: true };
    case SupportTypes.GETALL_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case SupportTypes.GETALL_FAILED:
      return { ...state, loading: false };

    case SupportTypes.GETALL_BY_CODE:
      return { ...state, loading: true };
    case SupportTypes.GETALL_BY_CODE_SUCCESS:
      return { ...state, loading: false, ...action.payload };
    case SupportTypes.GETALL_BY_CODE_FAILED:
      return { ...state, loading: false };

    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
