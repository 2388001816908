import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  UnProcessableEntity,
  UnexpectedError,
} from '~/domain/errors';
import { GetAuditRecord } from '~/domain/usecases/AuditRecord/remote';

export class RemoteAuditRecord implements GetAuditRecord {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAuditRecord.Model>;

  constructor(url: string, httpClient: HttpClient<GetAuditRecord.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  list = async (
    params: GetAuditRecord.Params,
  ): Promise<GetAuditRecord.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
