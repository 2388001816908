import { object, string } from 'zod';

export const schemaGuest = object({
  companionCode: string().nonempty({ message: 'Campo vazio' }),
  password: string().nonempty().optional(),
});

export const schemaCompanionEmail = object({
  email: string()
    .nonempty({ message: 'Campo vazio' })
    .email({ message: 'Email inválido' }),
});
