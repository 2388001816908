import { Dispatch } from 'redux';
import store from '~/data/store';
import { clearRequest } from '~/data/store/reducer/Consultant/actions/clear';
import { iActionClear } from '~/domain/interfaces/redux/Consultant/clear';

import { ClearConsultant } from '~/domain/usecases/consultant/redux';

export class ReduxClearConsultant implements ClearConsultant {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  clear = (): iActionClear => this.send(clearRequest());
}
