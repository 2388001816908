import { CreateShifts as UsecaseReduxCreateShifts } from '~/domain/usecases/shifts/redux/CreateShifts';
import { CreateShifts as UsecaseRemoteCreateShifts } from '~/domain/usecases/shifts/remote';
import { makeApiUrl, makeHttpClient } from '../../http';

import { RemoteCreateShifts } from '~/data/repository/shifts';
import { ReduxCreateShifts } from '~/data/store/reducer/shifts/usecases/ReduxCreateShifts';

export const makeRemoteCreateShifts = (): UsecaseRemoteCreateShifts =>
  new RemoteCreateShifts(
    makeApiUrl('/oncall/{onCallId}/shifts'),
    makeHttpClient(),
  );

export const makeReduxCreateShifts = (): UsecaseReduxCreateShifts =>
  new ReduxCreateShifts();
