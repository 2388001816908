import { Dispatch } from 'redux';
import store from '~/data/store';

import { iActionGetAll } from '~/domain/interfaces/redux/healthUnits/getAll';
import { GetAllHealthUnits } from '~/domain/usecases/healthUnits/redux';
import { getAllRequest } from '../actions/getAll';

export class ReduxGetAllHealthUnits implements GetAllHealthUnits {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllHealthUnits.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
