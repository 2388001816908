import React, { useState } from 'react';

import { PageHeader } from '~/presentation/components/header';
import { IconUser } from '~/presentation/base/icons';

import { Perfil } from '~/presentation/components/formPerfilData';
import { DeleteAccount } from '~/presentation/components/deleteAccount';
import { ChangePassword } from '~/presentation/components/changePassword';
import { Menu } from '~/presentation/components/menu';
import { Container, Body, LockScroll } from './StyleProfile';
import { ProfileNavigator } from './ProfileNavigation';
import { translator } from '~/presentation/components/i18n';

interface ownProps {
  permission: string;
}

export const Profile: React.FC = (): JSX.Element => {
  const [navigation, setNavigation] = useState<number>(1);

  const RenderSubPages: React.FC = () => {
    switch (navigation) {
      case 1:
        return <Perfil />;
      case 2:
        return <ChangePassword />;

      case 4:
        return <DeleteAccount />;
      default:
        return <div />;
    }
  };

  return (
    <Container>
      <LockScroll>
        <Menu />
        <PageHeader title={translator('Perfil do usuário')} icon={IconUser} />
      </LockScroll>
      <Body>
        <ProfileNavigator nav={setNavigation} active={navigation} />
        <div id="container">
          <RenderSubPages />
        </div>
      </Body>
    </Container>
  );
};
