import { Dispatch } from 'redux';
import store from '~/data/store';
import { AppointmentConfirmation } from '~/domain/usecases/appointment/redux';
import { iActionAppointmentConfirm } from '~/domain/interfaces/redux/appointment/appointmentConfirmation';
import { confirmRequest } from '../actions/appointmentConfirmation';

export class ReduxAppointmentConfirmation implements AppointmentConfirmation {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  confirm = (
    params: AppointmentConfirmation.Params,
  ): iActionAppointmentConfirm => this.send(confirmRequest(params));
}
