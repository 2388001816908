import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ProfessionList as UsecaseRemoteProfessionList } from '~/domain/usecases/profession/remote';
import { ProfessionList as UsecaseReduxProfessionList } from '~/domain/usecases/profession/redux';

import { RemoteProfessionList } from '~/data/repository/profession';
import { ReduxProfessionList } from '~/data/store/reducer/profession/usecases/ReduxProfessionList';

/**
 * send request via API.
 */
export const makeRemoteProfessionList = (): UsecaseRemoteProfessionList =>
  new RemoteProfessionList(makeApiUrl('/professions'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxProfessionList = (): UsecaseReduxProfessionList =>
  new ReduxProfessionList();
