import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/onCallRequester/actions/list';

import { iActionList } from '~/domain/interfaces/redux/onCallRequester/list';

import { ListOnCallRequester } from '~/domain/usecases/onCallRequester/redux';

export class ReduxListOnCallRequester implements ListOnCallRequester {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListOnCallRequester.Params): iActionList =>
    this.send(listRequest(params));
}
