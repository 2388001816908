import { GetAllSupportDocs } from '~/domain/usecases/supportDoc/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteGetAllSupportDocs implements GetAllSupportDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllSupportDocs.Model>;

  constructor(url: string, httClient: HttpClient<GetAllSupportDocs.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (
    params: GetAllSupportDocs.Params,
  ): Promise<GetAllSupportDocs.Model> => {
    let query = '';
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
