import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  min-width: 440px;
  max-width: 440px;
  height: fit-content;
  border-radius: 16px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  margin: 24px 0px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    margin: 12px 0px !important;
  }
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 16px 0px 24px;
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  row-gap: 16px;
`;

export const ConsultInfoMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  row-gap: 8px;
  text-align: start;
`;

export const ConsultInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 12px 8px 0px 16px;
`;

export const ScrollContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  text-wrap: balance;
  padding: 0px 8px 12px 0px;
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  padding: 24px;
  border-top: 1px solid #dedede;
  background: #f8f8f8;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: #222529;
`;

export const TextBoldSmall = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #222529;
`;

export const TextBoldTitle = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #818385;
`;

export const TextNormalSubtitle = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: #818385;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;
// End Text Components

export const Loader = styled.div`
  width: 24px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #818385 90%, #0000);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;

  @keyframes l7 {
    33% {
      background-size:
        calc(100% / 3) 0%,
        calc(100% / 3) 100%,
        calc(100% / 3) 100%;
    }
    50% {
      background-size:
        calc(100% / 3) 100%,
        calc(100% / 3) 0%,
        calc(100% / 3) 100%;
    }
    66% {
      background-size:
        calc(100% / 3) 100%,
        calc(100% / 3) 100%,
        calc(100% / 3) 0%;
    }
  }
`;
