import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  width: 352px;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  overflow-y: auto;
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  height: 183px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #f2f8ff;
  border-bottom: 1px solid #dedede;
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 16px;
  row-gap: 16px;
`;

export const ConsultInfoMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  row-gap: 16px;
  text-align: center;
`;

export const ConsultInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 10px;
  width: 100%;
  max-width: 320px;
  min-height: 200px;
  max-height: 200px;

  border: 1px solid #dedede;
  border-radius: 10px;
  padding: 12px 8px 0px 16px;
`;

export const ScrollContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  text-wrap: balance;
  padding: 0px 8px 12px 0px;
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  padding: 0 24px 24px;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  color: #222529;
`;

export const TextBoldTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #818385;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;
// End Text Components
