import {
  OnCallTypes,
  iActionStopOnCall,
  iActionStopOnCallSuccess,
  iActionStopOnCallFailed,
} from '~/domain/interfaces/redux/onCall/stopOnCall';

import { StopAppointmentOnCall } from '../../../../../domain/usecases/onCall/redux';

export const stopRequest = (
  params: StopAppointmentOnCall.Params,
): iActionStopOnCall => ({
  type: OnCallTypes.STOP_ONCALL,
  payload: params,
});

export const stopSuccess = (): iActionStopOnCallSuccess => ({
  type: OnCallTypes.STOP_ONCALL_SUCCESS,
});
export const stopFailed = (): iActionStopOnCallFailed => ({
  type: OnCallTypes.STOP_ONCALL_FAILED,
});
