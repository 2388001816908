import { Dispatch } from 'redux';
import store from '~/data/store';
import { chatSetup } from '~/data/store/reducer/chat/actions/setup';

import { iActionSetup } from '~/domain/interfaces/redux/chat/setup';

import { SetupChat } from '~/domain/usecases/chat/redux';

export class ReduxSetupChat implements SetupChat {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setup = (params: SetupChat.Params): iActionSetup =>
    this.send(chatSetup(params));
}
