export enum PermissionTypes {
  GET_ACL_USER = '@permissions/GET_ACL_USER',
  GET_ACL_USER_SUCCESS = '@permissions/GET_ACL_USER_SUCCESS',
  GET_ACL_USER_FAILED = '@permissions/GET_ACL_USER_FAILED',

  GET_PERMISSION = '@permissions/GET_PERMISSION',
  GET_PERMISSION_SUCCESS = '@permissions/GET_PERMISSION_SUCCESS',
  GET_PERMISSION_FAILED = '@permissions/GET_PERMISSION_FAILED',

  GET_ROLES = '@permissions/GET_ROLES',
  GET_ROLES_SUCCESS = '@permissions/GET_ROLES_SUCCESS',
  GET_ROLES_FAILED = '@permissions/GET_ROLES_FAILED',
  GET_PERMISSIONS = '@permissions/GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS = '@permissions/GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAILED = '@permissions/GET_PERMISSIONS_FAILED',

  GET_ACL = '@permissions/GET_ACL',
  GET_ACL_SUCCESS = '@permissions/GET_ACL_SUCCESS',
  GET_ACL_FAILED = '@permissions/GET_ACL_FAILED',

  UPDATE_PERMISSIONS = '@permissions/UPDATE_PERMISSIONS',
  UPDATE_PERMISSIONS_SUCCESS = '@permissions/UPDATE_PERMISSIONS_SUCCESS',
  UPDATE_PERMISSIONS_FAILED = '@permissions/UPDATE_PERMISSIONS_FAILED',

  UPDATE_ROLES = '@permissions/UPDATE_ROLES',
  UPDATE_ROLES_SUCCESS = '@permissions/UPDATE_ROLES_SUCCESS',
  UPDATE_ROLES_FAILED = '@permissions/UPDATE_ROLES_FAILED',
}
