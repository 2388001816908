import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import ReactInputMask from 'react-input-mask';

import { schemaPacientData } from '~/validation/validators/document/CreateClinicalDocSideBarValidator';

import { iProfessional } from '~/domain/interfaces/models/Professional';

import { Button, Input, Select } from '~/presentation/components/UI';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';

import { translator } from '~/presentation/components/i18n';
import { Container, Form, Navigation } from '../styles/StyledGeneralData';
import { iListConsultant, iCreateExamRequest } from '../interface';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import _ from 'lodash';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateExamRequest) => any;
  back: (data: iCreateExamRequest) => any;
  professional: iProfessional;
  state?: iCreateExamRequest;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const PacientData: React.FC<ownProps> = ({
  next,
  back,
  consultant,
  state,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;

  const [consultantId, setConId] = useState<number | undefined>(
    stateParams.consultantId,
  );

  const { errors, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPacientData),
    defaultValues: {
      situation: 'REGISTERED',
      patient: {
        id: state?.patient?.id || -1,
      },
      ...state,
    },
  });

  const [thisSituation, setThisSituation] = useState<string | undefined>(
    watch('situation') ?? 'REGISTERED',
  );

  const onBack = () => back({ ...state });

  const onSubmit = handleSubmit(data => {
    if (data.birthDate) {
      const age = calculateAge(data.birthDate);
      next({ ...data, patient: { ...data.patient, age: Number(age) } });
    } else {
      next({ ...data });
    }
  });

  function calculateAge(birthdate: string): number | null {
    const today = new Date();
    const birthdateParts = birthdate.split('/');

    // Ensure the birthdate is in the correct format
    if (birthdateParts.length !== 3) {
      return null;
    }

    // Extract day, month, and year from the birthdate
    const birthDay = parseInt(birthdateParts[0], 10);
    const birthMonth = parseInt(birthdateParts[1], 10);
    const birthYear = parseInt(birthdateParts[2], 10);

    // Calculate the age
    let age = today.getFullYear() - birthYear;

    // Check if the birthday hasn't occurred yet this year
    const todayMonth = today.getMonth() + 1;
    const todayDay = today.getDate();

    if (
      todayMonth < birthMonth ||
      (todayMonth === birthMonth && todayDay < birthDay)
    ) {
      age -= 1;
    }

    return age;
  }

  const onChangeDate = (value: string) => {
    const date = value.trim();

    setValue('birthDate', date);
  };

  useEffect(() => {
    register('patient.id');

    if (stateParams.consultantId) {
      setConId(stateParams.consultantId);
      setValue('patient.id', stateParams.consultantId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateParams]);

  useEffect(() => {
    setValue('situation', thisSituation);
    if (thisSituation === 'NOT-REGISTERED') {
      setValue('patient.id', -1);
    }
    if (thisSituation === 'REGISTERED') {
      setValue('patient.fullname', undefined);
      setValue('birthDate', undefined);
    }
  }, [thisSituation]);

  const generateConsultantSelectOptions = useCallback(() => {
    return _.sortBy(
      consultant?.flatMap(value => {
        return [
          {
            value: value.id,
            label: `${value.firstName} ${value.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [consultant]);

  const consultantMultiselectSelected = useMemo(() => {
    return generateConsultantSelectOptions().find(
      option => option.value === watch('patient.id'),
    );
  }, [generateConsultantSelectOptions, watch]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Select
          id="select_situation"
          label={`${translator('Situação cadastral')}`}
          onChange={e => {
            setThisSituation(e.target.value);
            setValue('situation', e.target.value);
          }}
          name="situation"
          defaultValue={watch('situation')}
          register={() => register('situation')}
          error={Boolean(errors.situation)}
          message={errors?.situation?.message}
        >
          <option value="REGISTERED">
            {translator('Paciente cadastrado')}
          </option>
          <option value="NOT-REGISTERED">
            {translator('Paciente não cadastrado')}
          </option>
        </Select>

        {thisSituation === 'REGISTERED' ? (
          <SearchSelect
            key={consultantId}
            id="select_patient"
            name="patient.id"
            register={() => register('patient.id')}
            label={`${translator('Paciente')}`}
            placeholder={translator('Selecione um paciente')}
            onChange={e => {
              setConId(Number(e?.value));
              setValue('patient.id', Number(e?.value));
            }}
            error={Boolean(errors?.patient?.id)}
            message={
              errors?.patient?.id?.message
                ? translator(errors?.patient?.id?.message)
                : ''
            }
            options={generateConsultantSelectOptions()}
            value={consultantMultiselectSelected}
          />
        ) : (
          <Input
            id="input_patient"
            label={`${translator('Paciente')}`}
            onChange={e => setValue('patient.fullname', e.target.value)}
            name="patient.fullname"
            defaultValue={watch('patient.fullname')}
            register={() => register('patient.fullname')}
            error={Boolean(errors?.patient?.fullname)}
            message={
              errors?.patient?.fullname?.message
                ? translator(errors?.patient?.fullname?.message)
                : ''
            }
            placeholder="Digite o nome do paciente"
            autoFocus
          />
        )}

        {thisSituation === 'NOT-REGISTERED' && (
          <>
            <div>
              <ReactInputMask
                mask="99/99/9999"
                name="birthDate"
                className="birthdate"
                defaultValue={state?.birthDate}
                ref={() => register('birthDate')}
                onChange={e => {
                  onChangeDate(e.target.value.trim());
                }}
              >
                <Input
                  gridArea="birthDate"
                  id="registerBirthDate"
                  placeholder={translator('DD/MM/AAAA')}
                  label={translator('Data de Nascimento')}
                  error={Boolean(errors.birthDate)}
                  message={
                    errors?.birthDate?.message
                      ? translator(errors?.birthDate?.message)
                      : ''
                  }
                  required
                />
              </ReactInputMask>{' '}
            </div>
            <Select
              id="select_gender"
              label={`${translator('Sexo')}`}
              defaultValue={state?.patient?.gender}
              onChange={e => setValue('patient.gender', e.target.value)}
              name="patient.gender"
              register={() => register('patient.gender')}
              error={Boolean(errors.patient?.gender)}
              message={errors?.patient?.gender?.message}
            >
              <option value="selectSexo">
                {translator('Selecione o sexo')}
              </option>
              <option value="MALE">{translator('Masculino')}</option>
              <option value="FEMALE">{translator('Feminino')}</option>
            </Select>
          </>
        )}
      </Form>
      <Navigation>
        <Button variant="secundary" onClick={onBack} size="small" rounded>
          Anterior
        </Button>
        <Button variant="primary" size="medium" onClick={onSubmit} rounded>
          Próximo
        </Button>
      </Navigation>
    </Container>
  );
};

export default PacientData;
