import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { OnCallRequestTypes } from '~/domain/interfaces/redux/onCallRequest/types';
import { OnCallRequestActions } from './actions';

export const initialState: iOnCallRequest = {
  loading: false,
  records: [],
  metadata: {
    offset: 0,
    limit: 0,
    total: 0,
  },
};

const reducer = (
  state = initialState,
  action: OnCallRequestActions,
): iOnCallRequest => {
  switch (action.type) {
    case OnCallRequestTypes.LIST:
      return { ...state, loading: true };
    case OnCallRequestTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OnCallRequestTypes.LIST_SUCCESS: {
      return {
        loading: false,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};

export default reducer;
