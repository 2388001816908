import { GetVidasCertificate } from 'domain/usecases/vidas/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Forbidden,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteGetVidasCertificate implements GetVidasCertificate {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetVidasCertificate.Model>;

  constructor(url: string, httClient: HttpClient<GetVidasCertificate.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: GetVidasCertificate.Params,
  ): Promise<GetVidasCertificate.Model> => {
    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
