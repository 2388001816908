import { useLocation } from 'react-router';
import { call, put } from 'redux-saga/effects';
import {
  deleteFailed,
  deleteSuccess,
} from '~/data/store/reducer/users/actions/delete';
import { iStore } from '~/domain/interfaces/models';
import { iActionDeleteSuccess } from '~/domain/interfaces/redux/supportDoc/delete';
import { iActionDelete } from '~/domain/interfaces/redux/users/delete';
import { DeleteUser, GetAllUsers } from '~/domain/usecases/users/remote';
import { makeReduxGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';
import { makeReduxGetAllOrganizer } from '~/main/factories/usecases/organizer/GetAllOrganizer';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeRemoteDeleteUser } from '~/main/factories/usecases/users/DeleteUserFactory';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { History } from '~/main/routes';
import storeDev from '../..';
import { ReduxLogout } from '../../reducer/auth/usecases/ReduxLogout';
import { getAllRequest } from '../../reducer/users/actions/getAll';

export function* onDeleteRequest(action: iActionDelete) {
  const makeRemoteDelete = makeRemoteDeleteUser();
  // const idLog = storeDev.getState().auth.info.user.id;
  // const logout = new ReduxLogout();
  try {
    const response: DeleteUser.Model = yield call(
      makeRemoteDelete.delete,
      action.payload,
    );
    yield put(deleteSuccess(action.payload));
    // if (idLog === action.payload.id) {
    //   yield logout.logout();
    //   yield History.getHistory().push('/');
    // } else {
    //   History.back();
    // }
  } catch (e) {
    console.error(e);
    yield put(deleteFailed());
  }
}

export function* onDeleteSuccess(action: any) {
  yield 10;
  const getAllRedux = makeReduxGetAllUsers();
  const getAllConsultantRedux = makeReduxGetAllConsultant();
  const getAllProfessionalRedux = makeReduxGetAllProfessionals();
  const getAllOrganizerRedux = makeReduxGetAllOrganizer();
  const store = storeDev;
  const logout = new ReduxLogout();
  const { location } = History.getHistory();
  const newLocation = location.pathname.substr(1, 7);

  const storeOrgs: iStore = storeDev.getState();
  const { orgId, orgUnitId, role, orgUnits } = storeOrgs.auth.selectUser;

  try {
    // yield put(getAllRequest({ limit: 0, page: 0 }));
    if (store && store.getState().auth.info.user.id === action.payload.id) {
      logout.logout();
      History.getHistory().push('/');
    }
    if (newLocation === 'profile') {
      History.getHistory().goBack();
    }

    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    getAllRedux.getAll({ pageSize: 0, pageCount: 0 });
    getAllProfessionalRedux.getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        unit: role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
    getAllOrganizerRedux.getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: orgUnitId ?? undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
    getAllConsultantRedux.getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  } catch (e) {
    console.log(e);
  }
}

export function* onDeleteFailed() {
  yield 10;
}
