import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 5px;
  max-height: 5px;
  min-height: 240px;
  padding: 10px;
  overflow-y: auto;
  flex-direction: column;
`;

export const Label = styled.span`
  width: 100%;
  display: flex;
  padding: 5px;
  text-align: left;
  font-size: 12px;
`;
