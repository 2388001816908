import { Dispatch } from 'redux';
import { UpdateUser } from '~/domain/usecases/users/redux';
import store from '~/data/store';
import { updateRequest } from '~/data/store/reducer/users/actions/update';

import { iActionUpdate } from '~/domain/interfaces/redux/users/update';

export class ReduxUpdateUser implements UpdateUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateUser.Params): iActionUpdate =>
    this.send(updateRequest(params));
}
