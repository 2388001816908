import React from 'react';

import {
  Content,
  Message,
  Image,
  Action,
} from './styles/StyledServiceCanceled';
import { CancelBig } from '~/presentation/base/icons';
import { Button } from '~/presentation/components/UI';
import { History } from '~/main/routes';
import { translator } from '~/presentation/components/i18n';

interface ownProps {
  id: Number;
}

const Canceled: React.FC<ownProps> = ({ id }) => {
  const url = window.location.pathname;

  return (
    <Content>
      <Image>
        <CancelBig />
      </Image>
      <Message>{translator('Atendimento cancelado')}</Message>
      <Action>
        {translator('Deseja agendar um novo atendimento?')}
        <Button
          rounded
          size="medium"
          onClick={() =>
            History.getHistory().push('/appointment/new', {
              consultant: id,
              old: 1,
              from: url.replaceAll('/', ''),
            })
          }
        >
          {translator('Novo atendimento')}
        </Button>
      </Action>
    </Content>
  );
};

export default Canceled;
