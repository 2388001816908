import { createIntlCache, createIntl } from 'react-intl';
import { ClinicalDocsTypes } from '~/domain/interfaces/redux/clinicalDocs/types';
import { active as activeMessage } from '~/data/store/reducer/message/actions/active';
import { MessageOptions } from '~/domain/interfaces/redux/message/types';
import { History } from '~/main/routes';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

/**
 * Middleware to handler the user message.
 * @param action action received by redux.
 * @param next function to pass the action.
 */
export const ClinicalDocsNotifications = (
  action: any,
  next: any,
  closeNotification: any,
) => {
  const { type } = action;
  switch (type) {
    case ClinicalDocsTypes.CREATE_SUCCESS:
      AlertMessage({
        message: intl.formatMessage({
          id: 'Documento clínico criado com sucesso!',
        }),
        type: 'success',
        position: 'top-right',
      });

      if (!action?.payload?.sidebar && !action?.payload?.signDocWithVidaas)
        History.back();
      if (action?.payload?.goBackToListDocument)
        action?.payload?.goBackToListDocument();
      if (action?.payload?.signDocWithVidaas)
        action?.payload?.signDocWithVidaas(action?.payload?.id);

      return next(closeNotification);
    case ClinicalDocsTypes.CREATE_BY_EMAIL_SUCESS:
      return next(
        activeMessage({
          active: MessageOptions.success,
          content: intl.formatMessage({
            id: 'Documento clínico enviado com sucesso!',
          }),
          actionOk: () => {
            next(closeNotification);
          },
        }),
      );
    case ClinicalDocsTypes.SIGN:
      return next(
        activeMessage({
          active: MessageOptions.success,
          content: intl.formatMessage({
            id: 'Documento assinado com sucesso!',
          }),
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
        }),
      );
    default:
      break;
  }
  return next(action);
};
