import React from 'react';

import { TextNormal, ConsultInfo, TextBoldSmall } from './styles/styles';
import { translator } from '../i18n';

interface ConsultDetailFragmentProps {
  label?: string;
  content?: string;
}

const ConsultDetailFragment: React.FC<ConsultDetailFragmentProps> = ({
  label = '',
  content = '',
}) => {
  return (
    <ConsultInfo>
      <TextNormal>
        <TextBoldSmall>{translator(`${label}: `)}</TextBoldSmall>
        {content !== '' ? content : 'Não informado'}
      </TextNormal>
    </ConsultInfo>
  );
};
export default ConsultDetailFragment;
