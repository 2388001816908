import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Center, Spinner, useToast } from '@chakra-ui/react';
import {
  Guest,
  ParticipantPages,
  InviteOtherProfessionalPage,
} from '~/presentation/pages';
import { PreSettingsGuest } from '~/presentation/components/preConfSettings';
import { PageNotFound } from '~/presentation/pages/errors/index';
import InviteProfessional from '~/presentation/pages/inviteProfessional';
import { makeRemoteUpdateAppointmentCompanion } from '~/main/factories/usecases/appointmentCompanion/UpdateAppointmentCompanionFactory';
import { makeRemoteGuestAppointment } from '~/main/factories/usecases/appointment/GuestAppointmentFactory';
import { GuestAppointment } from '~/domain/usecases/appointment/remote/GuestAppointment';
import { sleep } from '~/utils/sleep';

export const MakeAccessCodeFactory: React.FC = () => {
  const info = useLocation().pathname.split('/');
  const role = info[2];
  const code = info[info.length - 1];
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<GuestAppointment.Model>(
    {} as GuestAppointment.Model,
  );
  const toast = useToast();

  useEffect(() => {
    const getInfoAppointment = async () => {
      await sleep(3000);
      makeRemoteGuestAppointment()
        .guest({
          code,
        })
        .then(response => {
          setData(response);
        })
        .catch(() => {
          toast({
            title: `Atendimento não encontrado`,
            status: 'error',
            position: 'top-right',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };

    getInfoAppointment();
  }, []);

  const renderPage = () => {
    switch (role) {
      case 'con':
        if (Object.keys(data).length) {
          return (
            <PreSettingsGuest
              inviteData={{
                ...data,
                guest: {
                  identification: {
                    fullName: data.consultant.firstName,
                    role: 'CON',
                    code,
                  },
                },
              }}
            />
          );
        }

        return <></>;

      case 'pro': {
        if (Object.keys(data).length) {
          return <InviteProfessional data={data} />;
        }

        return <></>;
      }

      case 'opro': {
        return <InviteOtherProfessionalPage />;
      }

      default:
        if (info.includes('invite')) {
          return (
            <Guest
              authenticationCode={makeRemoteUpdateAppointmentCompanion()}
              code={code}
            />
          );
        }

        return <PageNotFound />;
    }
  };
  return (
    <>
      {loading ? (
        <Center w="100vw">
          <Spinner />
        </Center>
      ) : (
        renderPage()
      )}
    </>
  );
};
