import { ListModalitiesDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux/ListModalitiesDiagnosisReport';
import {
  iActionListModalities,
  iActionListModalitiesFailed,
  iActionListModalitiesSuccess,
  DiagnosisReportTypes,
} from '~/domain/interfaces/redux/diagnosisReport/listModalities';

export const listModalitiesRequest = (
  payload: ListModalitiesDiagnosisReport.Params,
): iActionListModalities => ({
  type: DiagnosisReportTypes.LISTMODALITIES,
  payload,
});

export const listModalitiesSuccess = (
  params: ListModalitiesDiagnosisReport.Model,
): iActionListModalitiesSuccess => ({
  type: DiagnosisReportTypes.LISTMODALITIES_SUCCESS,
  payload: params,
});

export const listModalitiesFailed = (): iActionListModalitiesFailed => ({
  type: DiagnosisReportTypes.LISTMODALITIES_FAILED,
});
