import { AppointmentFiltered } from '~/domain/usecases/appointment/redux/AppointmentFiltered';
import {
  iActionAppointmentFiltered,
  AppointmentTypes,
  iActionAppointmentFilteredSuccess,
  iActionAppointmentFilteredFailed,
} from '~/domain/interfaces/redux/appointment/appointmentFiltered';

export const filterRequest = (
  payload: AppointmentFiltered.Params,
): iActionAppointmentFiltered => ({
  type: AppointmentTypes.APPOINTMENT_FILTER,
  payload,
});

export const filterSuccess = (
  payload: AppointmentFiltered.Model,
): iActionAppointmentFilteredSuccess => ({
  type: AppointmentTypes.APPOINTMENT_FILTER_SUCCESS,
  payload,
});

export const filterFailed = (): iActionAppointmentFilteredFailed => ({
  type: AppointmentTypes.APPOINTMENT_FILTER_FAILED,
});
