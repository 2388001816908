import { Dispatch } from 'redux';
import { iActionSetData } from '~/domain/interfaces/redux/dataPagination/setData';
import { SetData } from '~/domain/usecases/dataPagination/redux/SetData';
import store from '~/data/store';

import { setData } from '../actions/setData';

export class ReduxSetData implements SetData {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  set = (params: SetData.Params): iActionSetData => this.send(setData(params));
}
