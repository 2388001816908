import styled from 'styled-components';

interface StatusProps {
  status: 'ACTIVE' | '2FAWAIT' | 'SUSPENDED' | 'REMOVED';
}

export const Container = styled.div``;

export const Status = styled.div<StatusProps>`
  ::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-right: 6px;
    border-radius: 50%;
    background-color: ${props => {
      switch (props.status) {
        case 'ACTIVE':
          return '#519A08';
        case '2FAWAIT':
          return '#B4B7B9';
        case 'SUSPENDED':
          return '#DD0404';
        default:
          return '';
      }
    }};
  }
`;
