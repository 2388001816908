import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateAppointment as UsecaseReduxCreateAppointment } from '~/domain/usecases/appointment/redux';
import { CreateAppointment as UsecaseRemoteCreateAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteCreateAppointment } from '~/data/repository/appointment';
import { ReduxCreateAppointment } from '~/data/store/reducer/appointment/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateAppointment = (): UsecaseRemoteCreateAppointment =>
  new RemoteCreateAppointment(makeApiUrl('/appointments'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCreateAppointment = (): UsecaseReduxCreateAppointment =>
  new ReduxCreateAppointment();
