import { GetAllSupportDocsByCode } from '~/domain/usecases/supportDoc/redux';
import {
  iActionGetAllByCode,
  iActionGetAllByCodeFailed,
  iActionGetAllByCodeSuccess,
  SupportTypes,
} from '~/domain/interfaces/redux/supportDoc/getAllByCode';

export const getAllByCodeRequest = (
  payload: GetAllSupportDocsByCode.Params,
): iActionGetAllByCode => ({
  type: SupportTypes.GETALL_BY_CODE,
  payload,
});

export const getAllByCodeSuccess = (
  Params: GetAllSupportDocsByCode.Model,
): iActionGetAllByCodeSuccess => ({
  type: SupportTypes.GETALL_BY_CODE_SUCCESS,
  payload: Params,
});

export const getAllByCodeFailed = (): iActionGetAllByCodeFailed => ({
  type: SupportTypes.GETALL_BY_CODE_FAILED,
});
