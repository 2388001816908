import React, { ComponentType, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { createIntlCache, createIntl } from 'react-intl';
import { iStore } from '~/domain/interfaces/models';
import { iListClinDocs, ClinDocsParams } from '../interface';
import { ownProps, externalProps } from '../notes';

import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { makeRemoteGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P & externalProps> {
  const Render: React.FC<P & externalProps> = ({ ...rest }) => {
    const [data, setData] = useState<iListClinDocs[]>([]);
    const { clinicalDocs } = useSelector((state: iStore) => state);

    useEffect(() => {
      MapClinDocs({
        appointment: rest?.appointment?.appointment?.id ?? -1,
        consultant: rest?.appointment?.consultant?.id ?? -1,
        professional: rest?.appointment?.professional?.id ?? -1,
      })
        .then(res => {
          setData(_.sortBy(res, (item: iListClinDocs) => item.id));
        })
        .catch(err => {
          console.log('mapper effect error: ', err);
        });
    }, [rest.appointment, clinicalDocs]);

    Component.defaultProps = {
      data,
      ...(rest as P & externalProps),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * Map list clinical docs to consultant with specific professional.
 * @param appointment appointment id.
 * @param consultant consultant id.
 * @param professional professional id.
 */
const MapClinDocs = async ({
  appointment,
  consultant,
  professional,
}: ClinDocsParams): Promise<iListClinDocs[]> => {
  const arr: iListClinDocs[] = [];

  await makeRemoteGetAllAppointment()
    .getAll({
      filter: {
        consultant,
        professional,
      },
    })
    .then(res => {
      res.results.forEach(item => {
        const formattedDate = new Date(
          item.appointment.scheduled,
        ).toLocaleDateString(getLocale());
        const isToday = item?.appointment?.id === appointment;

        arr.push({
          id: item?.appointment?.id,
          label: isToday ? intl.formatMessage({ id: 'Hoje' }) : formattedDate,
        });
      });
    })
    .catch(err => {
      console.log('mapper err: ', err);
    });

  return arr;
};
