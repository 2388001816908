import * as datefns from 'date-fns';
import { AppointmentsFilteredToResultsDTO } from '~/domain/dtos/appointment/FilteredToResults';
import { iAppointment } from '~/domain/interfaces/models/Appointment';
import { AppointmentTypes } from '~/domain/interfaces/redux/appointment/types';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { AppointmentActions } from './actions';

export const initialState: iAppointment = {
  loading: false,
  pageSize: 0,
  selected: 0,
  totalItems: 0,
  resultsMap: {},
  results: [],
  create: {
    step: 1,
    data: {},
  },
  date: new Date(),
};

const reducer = (
  state = initialState,
  action: AppointmentActions,
): iAppointment => {
  switch (action.type) {
    case AppointmentTypes.GETALL:
      return { ...state, loading: true };
    case AppointmentTypes.GETALL_FAILED:
      return { ...state, loading: false };
    case AppointmentTypes.GETALL_SUCCESS: {
      const { results } = action.payload;

      const selectDate = datefns.format(
        state.date instanceof Date ? state.date : new Date(state.date),
        'yyyy-MM-dd',
      );

      const appointmentList = state.resultsMap === null ? {} : state.resultsMap;
      let totalItems = 0;

      try {
        appointmentList[selectDate] = [];

        results.forEach(item => {
          const itemDate = new Date(item.appointment.scheduled);

          if (datefns.isSameDay(state.date, itemDate)) {
            appointmentList[selectDate].push(item);
          }
        });

        totalItems = appointmentList[selectDate].length;
      } catch (e) {
        console.log('... error', e);
      }

      return {
        ...state,
        resultsMap: appointmentList,
        results,
        loading: false,
        totalItems,
      };
    }

    case AppointmentTypes.CREATE:
      return { ...state, loading: true };
    case AppointmentTypes.CREATE_FAILED:
      return { ...state, loading: false };
    case AppointmentTypes.CREATE_SUCCESS:
      return { ...state, loading: false };

    case AppointmentTypes.UPDATE:
      return { ...state, loading: true };
    case AppointmentTypes.UPDATE_FAILED:
      return { ...state, loading: false };
    case AppointmentTypes.UPDATE_SUCCESS:
      return { ...state, loading: false };
    case AppointmentTypes.ADD_APPOINTMENT_DATA:
      return {
        ...state,
        create: {
          step: action.payload.step || state.create.step,
          data: { ...(state.create.data as any), ...action.payload.data },
        },
      };
    case AppointmentTypes.STOP:
      return { ...state, loading: true };
    case AppointmentTypes.STOP_FAILED:
      return { ...state, loading: false };
    case AppointmentTypes.STOP_SUCCESS:
      return { ...state, loading: false };
    case AppointmentTypes.SET_DATA: {
      const date = new Date(action.payload.date);

      return {
        ...state,
        date,
      };
    }
    case AuthTypes.LOGOUT:
      state = initialState;
      state.resultsMap = {};
      return { ...state };
    case AppointmentTypes.SETUP_APPOINTMENT:
      return { ...state, selected: action.payload.appointmentId };
    case AppointmentTypes.CANCEL:
      return {
        ...state,
        loading: true,
        selected: action.payload.appointmentId,
      };
    case AppointmentTypes.CANCEL_FAILED:
      return { ...state, loading: false, selected: 0 };
    case AppointmentTypes.CANCEL_SUCCESS:
      return { ...state, loading: false, selected: 0 };

    case AppointmentTypes.APPOINTMENTS_CONFIRMATION:
      return { ...state, loading: true };
    case AppointmentTypes.APPOINTMENTS_CONFIRMATION_SUCCESS:
      return { ...state, loading: false };
    case AppointmentTypes.APPOINTMENTS_CONFIRMATION_FAILED:
      return { ...state, loading: false };

    case AppointmentTypes.INSERT_APPOINTMENT: {
      console.log('... adicionando por web sockets', action.payload);

      const itemDate = new Date(action.payload.appointment.scheduled);
      const selectDate = datefns.format(itemDate, 'yyyy-MM-dd');

      if (state.resultsMap[selectDate] === undefined) return state;

      state.resultsMap[selectDate].push(action.payload as any);
      return { ...state };
    }

    case AppointmentTypes.LIST:
      return { ...state, loading: true };
    case AppointmentTypes.LIST_FAILED:
      return { ...state, loading: false };
    case AppointmentTypes.LIST_SUCCESS: {
      const { results } = action.payload;
      return { ...state, loading: false, results };
    }

    case AppointmentTypes.APPOINTMENT_FILTER:
      return { ...state, loading: true };
    case AppointmentTypes.APPOINTMENT_FILTER_FAILED:
      return { ...state, loading: false };
    case AppointmentTypes.APPOINTMENT_FILTER_SUCCESS: {
      const formattedResults = AppointmentsFilteredToResultsDTO(action.payload);

      return { ...state, loading: false, results: formattedResults };
    }
    default:
      return state;
  }
};

export default reducer;
