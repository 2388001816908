import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: flex-start;
  flex-direction: column;
`;

export const Header = styled.div`
  max-width: 1000px;
  width: 100%;
  margin-top: 50px;
`;

export const Body = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0px;
`;

export const Footer = styled.div`
  max-width: 1000px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .btnAction {
    width: 150px;
  }
`;

export const Separate = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
`;
