import { call, put } from 'redux-saga/effects';
import { iActionGetBillingService } from '~/domain/interfaces/redux/billing/getBillingService';
import { GetBillingService } from '~/domain/usecases/billing/remote/GetBillingService';
import {
  getFailed,
  getSuccess,
} from '~/data/store/reducer/billing/actions/getBillingService';

import { makeRemoteGetBillingService } from '~/main/factories/usecases/billing/GetBillingServiceFactory';

export function* onGetBillingService(action: iActionGetBillingService) {
  const remoteGetBillingService = makeRemoteGetBillingService();

  try {
    const response: GetBillingService.Model = yield call(
      remoteGetBillingService.get,
      action.payload,
    );

    yield put(getSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetBillingServiceSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetBillingServiceFailed() {}
