import { Dispatch } from 'redux';
import store from '~/data/store';
import { MakeClinicalDoc } from '~/domain/usecases/clinicalDocs/redux/MakeClinicalDoc';
import {
  iActionMakeClinicalDoc,
  iActionSubmitMakeClinicalDoc,
  iActionCancelMakeClinicalDoc,
} from '~/domain/interfaces/redux/clinicalDocs/makeClinicalDoc';
import {
  makeClinicalDoc,
  makeClinicalDocCancel,
  makeClinicalDocSubmit,
} from '../actions/makeClinicalDoc';

export class ReduxMakeClinicalDoc implements MakeClinicalDoc {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  make = (params: MakeClinicalDoc.Params): iActionMakeClinicalDoc =>
    this.send(makeClinicalDoc(params));

  cancel = (): iActionCancelMakeClinicalDoc =>
    this.send(makeClinicalDocCancel());

  submit = (params: MakeClinicalDoc.Params): iActionSubmitMakeClinicalDoc =>
    this.send(makeClinicalDocSubmit(params));
}
