import { call, put } from 'redux-saga/effects';
import {
  iActionLeftWaitingRoom,
  iActionLeftSuccessWaitingRoom,
} from '~/domain/interfaces/redux/waitingroom/left';

import {
  LeftSuccess,
  Leftfailed,
} from '~/data/store/reducer/waitingroom/action/left';

import { makeSocketLeftWaitingRoom } from '~/main/factories/usecases/waitingRoom/LeftWaitingRoomFactory';

export function* onLeft(action: iActionLeftWaitingRoom) {
  const waitingRoom = makeSocketLeftWaitingRoom();

  try {
    yield call(waitingRoom.left, action.payload);
    yield put(LeftSuccess());
  } catch (e) {
    console.error('error: ', e);

    yield put(Leftfailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLeftSuccess(action: iActionLeftSuccessWaitingRoom) {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLeftFailed() {}
