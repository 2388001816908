import { iActionGetClinicalDocsById } from '~/domain/interfaces/redux/clinicalDocs/getById';

export function* onGetById(action: iActionGetClinicalDocsById): Generator {
  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetByIdSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetByIdFailed() {}
