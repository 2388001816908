import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { iListAppointment } from './interface';

import { ConnectComponent } from './mapper/MapperListAppointment';

import { Appointment } from './Lists';
import AppointmentEmpty from '../componentEmpty/AppointmentEmpty';
import { iStore } from '~/domain/interfaces/models';

export interface ownProps {
  data: iListAppointment[];
}

const ListAppointment: React.FC<ownProps> = ({ data }) => {
  const [appointment, setAppointment] = useState(data);

  useEffect(() => {
    setAppointment(data);
  }, [data]);

  const { loading } = useSelector((store: iStore) => store.appointment);
  return data.length === 0 && !loading ? (
    <AppointmentEmpty />
  ) : (
    <Appointment dataList={data} />
  );
};

export default ConnectComponent(ListAppointment);
