import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    transform: translate(0, -100px);

  }
  50% {
    transform: translate(0, 10px);
  }
  100% {
    transform: translate(0, -10px);
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  z-index: 4;
  margin-top: 44px;
  animation: ${fadeIn} 0.5s ease-in-out;
  cursor: pointer;

  .arrow {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-top: 10px solid transparent;
    border-right: 15px solid yellow;
    border-bottom: 10px solid green;
    box-shadow: 0 3px 0.5rem rgba(28, 51, 68, 0.1);
  }

  #icon_close_toast {
    margin-left: 20px;
    width: 1.4rem;
    height: 1.4rem;
    cursor: pointer;
  }
`;

interface contentProps {
  icon: boolean;
}

export const Content = styled.div<contentProps>`
  position: relative;
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 3px 0.5rem rgba(28, 51, 68, 0.1);

  border: 1px solid #c7ced4;

  :before,
  :after {
    display: ${props => (props.icon ? 'block' : 'none')};
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
  }

  :before {
    left: -30px;
    bottom: 26px;
    border-width: 8px 12px 16px 24px;
    border-color: transparent #c7ced4 #c7ced4 transparent;
    border-style: solid;
    transform: rotate(-4deg);
    z-index: -1;
  }

  :after {
    left: -26px;
    bottom: 27px;
    border-width: 6px 8px 14px 20px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
    transform: rotate(-3deg);
  }
`;

export const IconContainer = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* width: 7rem;
  height: 6rem; */
  height: 100%;
  width: 68px;
  border-radius: 6rem;
  margin-right: 40px;
  /* overflow: hidden; */
  /* box-shadow: 0 3px 0.5rem rgba(28, 51, 68, 0.1); */
`;
