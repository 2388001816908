import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useLocation } from 'react-router';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { useSelector } from 'react-redux';
import { iListConsultant, iCreateExamRequest } from '../interface';

import { schemaGeneralData } from '~/validation/validators/document/CreateClinicalDocSideBarValidator';

import { iProfessional } from '~/domain/interfaces/models/Professional';

import { Container, Form, Navigation } from '../styles/StyledGeneralData';
import { Button, Input } from '~/presentation/components/UI';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';

import { translator } from '~/presentation/components/i18n';
import { iStore } from '~/domain/interfaces/models';
import SearchSelect from '~/presentation/components/UI/searchSelect';
import _ from 'lodash';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateExamRequest) => any;
  professional: iProfessional;
  state?: iCreateExamRequest;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const GeneralData: React.FC<ownProps> = ({
  next,
  professional,
  state,
}): JSX.Element => {
  const { info } = useSelector((store: iStore) => store.auth);
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  let stateParams = useLocation<iStateParams>().state;
  stateParams = {
    ...stateParams,
    professionalId:
      info.professionals?.find(prof => prof.org.id === orgId)?.id ??
      stateParams.professionalId,
  };

  const [professionalId, setProfId] = useState<number | undefined>(
    stateParams.professionalId,
  );

  const { errors, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      org: state?.org || -1,
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  useEffect(() => {
    register('professional');

    if (stateParams.professionalId) {
      setProfId(stateParams.professionalId);
      setValue('professional', stateParams.professionalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateProfessionalSelectOptions = useCallback(() => {
    return _.sortBy(
      professional?.results.flatMap(value => {
        return [
          {
            value: value.professional.id,
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [professional]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionalSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionalSelectOptions, watch]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          id="input_instituition"
          onChange={e => setValue('org', Number(e.target.value))}
          register={() => register('org')}
          error={Boolean(errors.org)}
          message={errors?.org?.message}
          placeholder={state?.orgUnitName}
          name="org"
          label={`${translator('Instituição')}`}
          autoFocus
          required
          disabled
        />
        <SearchSelect
          key={professionalId}
          id="select_professional"
          name="professional"
          register={() => register('professional')}
          label={`${translator('Profissional')}`}
          placeholder={translator('Selecione um profissional')}
          onChange={e => {
            setProfId(Number(e?.value));
            setValue('professional', Number(e?.value));
          }}
          error={Boolean(errors.professional)}
          message={
            errors?.professional?.message
              ? translator(errors?.professional?.message)
              : ''
          }
          isDisabled={Boolean(professionalId && professionalId !== -1)}
          options={generateProfessionalSelectOptions()}
          value={professionalMultiselectSelected}
        />
        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
          disabled
        />
      </Form>
      <Navigation>
        <Button variant="primary" size="medium" onClick={onSubmit} rounded>
          Próximo
        </Button>
      </Navigation>
    </Container>
  );
};

export default GeneralData;
