import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListOnCallRequester as UsecaseRemoteListOnCallRequester } from '~/domain/usecases/onCallRequester/remote';
import { ListOnCallRequester as UsecaseReduxListOnCallRequester } from '~/domain/usecases/onCallRequester/redux';

import { RemoteListOnCallRequester } from '~/data/repository/onCallRequester';
import { ReduxListOnCallRequester } from '~/data/store/reducer/onCallRequester/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOnCallRequester =
  (): UsecaseRemoteListOnCallRequester =>
    new RemoteListOnCallRequester(
      makeApiUrl('/oncall/{onCallId}/requesters/list'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListOnCallRequester =
  (): UsecaseReduxListOnCallRequester => new ReduxListOnCallRequester();
