import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  height: 3.3em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: #7a7a7a;
  background-color: #f4f7fe;
  border-radius: 4px;

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #ec5c52;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 18px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 21em;
  max-height: 13em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div`
  width: 65%;
  height: 100%;
  padding-top: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    color: #565757;
    font-size: 13px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const ConfirmCancel = styled.button`
  width: 240px;
  height: 38px;
  margin-top: 11px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 2px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;
