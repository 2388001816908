import { CreateSupportDocs } from '~/domain/usecases/supportDoc/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Accepted,
  NotFound,
} from '~/domain/errors';

export class RemoteCreateSupportDocs implements CreateSupportDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateSupportDocs.Model>;

  constructor(url: string, httClient: HttpClient<CreateSupportDocs.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateSupportDocs.Params,
  ): Promise<CreateSupportDocs.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params.data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.accepted:
        throw new Accepted();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
