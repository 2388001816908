import { object, string, number } from 'zod';

export const schemaInstantAppointment = object({
  name: string()
    .nonempty({ message: 'Campo obrigatório.' })
    .min(3, { message: 'Digite ao menos 3 caracteres.' }),
  unit: number().optional(),
  // unit: string()
  //   .nonempty({ message: 'Campo obrigatório.' })
  //   .min(2, { message: 'Unidade de saúde inválida.' }),
  regType: string().nonempty({ message: 'Selecione uma opção.' }),
  uf: string().nonempty({ message: 'Selecione uma opção.' }),
  regValue: string()
    .nonempty({ message: 'Registro inválido.' })
    .min(3, { message: 'Digite ao menos 3 caracteres.' })
    .max(16, {
      message: 'Você atingiu o limite de 16 caracteres para este campo',
    }),
});
