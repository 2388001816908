import { SetupAppointment } from '~/domain/usecases/appointment/redux';
import {
  iActionSetup,
  AppointmentTypes,
} from '~/domain/interfaces/redux/appointment/setupAppointment';

export const setupRequest = (
  payload: SetupAppointment.Params,
): iActionSetup => ({
  type: AppointmentTypes.SETUP_APPOINTMENT,
  payload,
});
