import { GetAuditRecord as UseCaseReduxLogin } from '~/domain/usecases/AuditRecord/redux';
import { GetAuditRecord as UseCaseRemoteLogin } from '~/domain/usecases/AuditRecord/remote';

import { RemoteAuditRecord } from '~/data/repository/AuditRecord';
import { ReduxGetAuditRecord } from '~/data/store/reducer/AuditRecord/usecases';
import { makeApiUrl, makeHttpClient } from '../../http';

/**
 * make login via API.
 */
export const makeRemoteAuditRecord = (): UseCaseRemoteLogin =>
  new RemoteAuditRecord(makeApiUrl('/audit/logs'), makeHttpClient());

/**
 * make login via SAGA.
 */
export const makeReduxAuditRecord = (): UseCaseReduxLogin =>
  new ReduxGetAuditRecord();
