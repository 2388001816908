import React, { useState } from 'react';
import { Calendar as CalendarMui } from '@material-ui/pickers';
import { withStyles } from '@material-ui/core/styles';

import { Container } from './styles';

import { ConnectComponent } from './mapper/MapperCalendar';

export interface ownProps {
  date: Date;
  set: Function;
  minDate?: Date;
}

const GlobalCss = withStyles({
  '@global': {
    '.MuiPickersCalendarHeader-switchHeader': {
      justifyContent: 'flex-end',
      position: 'relative',
    },
    '.MuiPickersCalendarHeader-transitionContainer': {
      position: 'absolute',
      marginTop: '16px',
      /* textAlign: 'start !important',
      right: '76px', */
    },
    '.MuiPickersCalendarHeader-transitionContainer > p': {
      textAlign: 'start',
      paddingLeft: '10px',
    },
    '.MuiPickersSlideTransition-slideEnter-left': {
      transform: 'translate(0%)',
      willChange: 'transform',
    },
    '.MuiPickersSlideTransition-slideEnter-right': {
      transform: 'translate(0%)',
      willChange: 'transform',
    },
    '.MuiPickersSlideTransition-slideEnterActive': {
      transform: 'translate(0%)',
      transition: 'transform 350ms cubic-bezier(0.35, 0.8, 0.4, 1) 0ms',
    },
    '.MuiPickersSlideTransition-slideExit': {
      transform: 'translate(0%)',
    },
    '.MuiPickersSlideTransition-slideExitActiveLeft-left': {
      opacity: 0,
    },
    '.MuiPickersSlideTransition-slideExitActiveLeft-right': {
      opacity: 0,
    } /*
    '.MuiPickersDay-hidden': {
      opacity: 100,
      color: '#A8A8A8',
    }, */,
  },
})(() => null);

const Calendar: React.FC<ownProps> = ({ date, set, minDate }): JSX.Element => {
  const selectDate = (data: any) => {
    set(data);
  };

  return (
    <>
      <GlobalCss />
      <Container>
        <CalendarMui
          date={new Date(date)}
          onChange={selectDate}
          minDate={minDate}
        />
      </Container>
    </>
  );
};

export default ConnectComponent(Calendar);
