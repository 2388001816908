import React from 'react';

import { translator } from '../i18n';
import {
  ConsultInfo,
  TextBold,
  TextNormal,
} from './styles/StyledConsultationData';

interface ConsultDetailFragmentProps {
  label?: string;
  content?: string;
}

const ConsultDetailFragment: React.FC<ConsultDetailFragmentProps> = ({
  label = ' ',
  content = ' ',
}) => {
  return (
    <ConsultInfo>
      <TextBold>{`${label ? translator(`${label}`) : ' '}: `}</TextBold>
      <TextNormal>{`${content ? translator(`${content}`) : ' '}`}</TextNormal>
    </ConsultInfo>
  );
};
export default ConsultDetailFragment;
