import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  &:hover {
    rect {
      fill: #1756e8;
      stroke: #1756e8;
    }

    path {
      fill: white;
    }
  }
`;

const appointmentHistoryIcon = (): JSX.Element => {
  return (
    <Container>
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="24"
          height="24"
          rx="4"
          fill="none"
          stroke="#1756E8"
          strokeWidth="2"
        />
        <path
          d="M13 20C11.0556 20 9.40278 19.3194 8.04167 17.9583C6.68056 16.5972 6 14.9444 6 13H7.5C7.5 14.5139 8.03819 15.809 9.11458 16.8854C10.191 17.9618 11.4861 18.5 13 18.5C14.5139 18.5 15.809 17.9618 16.8854 16.8854C17.9618 15.809 18.5 14.5139 18.5 13C18.5 11.4861 17.9618 10.191 16.8854 9.11458C15.809 8.03819 14.5139 7.5 13 7.5C12.1389 7.5 11.3434 7.67778 10.6136 8.03333C9.8838 8.38889 9.26954 8.87778 8.77083 9.5H11V11H6V6H7.5V8.70833C8.13889 7.875 8.93403 7.21528 9.88542 6.72917C10.8368 6.24306 11.875 6 13 6C13.9722 6 14.8829 6.18472 15.7319 6.55417C16.581 6.92361 17.3199 7.42269 17.9486 8.0514C18.5773 8.68009 19.0764 9.41898 19.4458 10.2681C19.8153 11.1171 20 12.0278 20 13C20 13.9722 19.8153 14.8829 19.4458 15.7319C19.0764 16.581 18.5773 17.3199 17.9486 17.9486C17.3199 18.5773 16.581 19.0764 15.7319 19.4458C14.8829 19.8153 13.9722 20 13 20ZM15.0833 15.8333L12.25 13V9H13.75V12.375L16.1458 14.7708L15.0833 15.8333Z"
          fill="#1756E8"
        />
      </svg>
    </Container>
  );
};

export default appointmentHistoryIcon;
