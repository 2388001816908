import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateUserAvatar as UsecaseRemoteUpdateUserAvatar } from '~/domain/usecases/users/remote';
import { UpdateUserAvatar as UsecaseReduxUpdateUserAvatar } from '~/domain/usecases/users/redux';

import { RemoteUpdateUserAvatar } from '~/data/repository/users';
import { ReduxUpdateUserAvatar } from '~/data/store/reducer/users/usecases/ReduxUpdateUserAvatar';

/**
 * send request via API.
 */
export const makeRemoteUpdateUserAvatar = (): UsecaseRemoteUpdateUserAvatar =>
  new RemoteUpdateUserAvatar(makeApiUrl('/users'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxUpdateUserAvatar = (): UsecaseReduxUpdateUserAvatar =>
  new ReduxUpdateUserAvatar();
