import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

export const Body = styled.div`
  position: fixed;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;

  max-width: 29em;
  max-height: 20em;
  width: 70%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;

  span {
    width: 100%;
    margin-top: 5px;
    font-size: 17px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 40px;

  border-bottom: 1px #c4c4c4 solid;
  p {
    color: red;
    font-size: 20px;
    margin-bottom: 12px;
    font-weight: bold;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Text = styled.p`
  width: 100%;
  font-size: 17px;
  margin-top: 20px;
  text-align: center;
  text-overflow: ellipsis;
`;
