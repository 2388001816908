import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import _ from 'lodash';
import { Input, Select, Button } from '~/presentation/components/UI';
import { IconSaudeMeetHorizontal } from '~/presentation/base/icons';
import { makeRemoteGetDetailsInstantAppointment } from '~/main/factories/usecases/appointment/GetDetailsInstantAppointmentFactory';
import { makeRemoteUpdateInstantAppointment } from '~/main/factories/usecases/appointment/UpdateInstantAppointmentFactory';
import { makeRemoteGetUfs } from '~/main/factories/usecases/externalServices/GetUfs';
import { GetDetailsInstantAppointment } from '~/domain/usecases/appointment/remote/GetDetailsInstantAppointment';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { schemaInstantAppointment } from '~/validation/validators/appointment/InstantAppointmentValidator';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { iStore } from '~/domain/interfaces/models';
import { Label } from '~/presentation/components/UI/select/styles';
import {
  Control,
  DropdownIndicator,
  Option,
} from '~/presentation/components/filter/styles/StyledFilterAppointmentHistory';

import { selectStyles } from '~/presentation/components/modalRequestConsultation/styles/StyledModalRequestConsultation';
import {
  Container,
  InviteOtherProfessionalContainer,
  Header,
  TitleAndSubtitle,
  Title,
  Description,
  Form,
  InlineInputs,
  Buttons,
} from './styles/styles';

interface iUfResponse {
  id: number;
  nome: string;
  sigla: string;
  regiao: {
    id: number;
    nome: string;
    sigla: string;
  };
}

const InviteProfessional: React.FC = () => {
  const [ufs, setUfs] = useState<iUfResponse[]>([]);
  const [professional, setProfessional] =
    useState<GetDetailsInstantAppointment.Model>();
  const [loading, setLoading] = useState<boolean>(false);

  const info = useLocation().pathname.split('/');
  const history = useHistory();

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { errors, register, setValue, control, setError, formState, watch } =
    useForm({
      mode: 'all',
      shouldFocusError: true,
      resolver: zodResolver(schemaInstantAppointment),
      defaultValues: {},
    });

  useEffect(() => {
    if (info) {
      setLoading(true);
      makeRemoteGetDetailsInstantAppointment()
        .getDetailsInstantAppointment({
          code: info[info.length - 1],
        })
        .then(res => {
          setProfessional(res);
          setLoading(false);
        })
        .catch(() =>
          AlertMessage({
            type: 'danger',
            message: 'Ocorreu um erro ao buscar o código.',
          }),
        )
        .finally(() => setLoading(false));
    }

    makeRemoteGetUfs()
      .getUfs({})
      .then(res => setUfs(res))
      .catch(() =>
        AlertMessage({
          type: 'danger',
          message: 'Ocorreu um erro ao buscar os estados.',
        }),
      );

    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
    });
  }, []);

  useEffect(() => {
    register('unit');

    // Se for um profissional pré-cadastrado, preenche os campos
    if (professional?.otherProfessionals?.[0]?.professional) {
      setValue('name', professional?.otherProfessionals[0]?.name);
      setValue('unit', professional?.healthUnit?.id);
      setValue('regType', professional?.otherProfessionals[0]?.regType);
      setValue('uf', professional?.otherProfessionals[0]?.uf);
      setValue('regValue', professional?.otherProfessionals[0]?.regValue);
    } else {
      setValue('name', professional?.otherProfessionals[0]?.name);
      setValue('unit', professional?.healthUnit?.id);
    }
  }, [
    professional?.healthUnit?.id,
    professional?.otherProfessionals,
    register,
    setValue,
  ]);

  const unitLabel = healthUnits.find(
    item => item.id === Number(watch('unit')),
  )?.name;

  const handleSubmitInstantAppointment = () => {
    if (professional && professional?.otherProfessionals?.length) {
      const unit = healthUnits.find(item => item.id === Number(watch('unit')));

      makeRemoteUpdateInstantAppointment()
        .updateInstantAppointment({
          appointmentId: professional?.appointment?.id,
          otherProfessionalId: professional?.otherProfessionals[0]?.id,
          name: watch('name'),
          unit: unit?.name,
          appointment: {
            healthUnit: Number(unit?.id),
          },
          regType: watch('regType'),
          regValue: watch('regValue'),
          uf: watch('uf'),
        })
        .then(() => {
          history.push('/conf', {
            guestData: {
              appointment: {
                id: professional?.appointment?.id,
              },
              guest: {
                identification: {
                  fullName: watch('name'),
                  code: info[info.length - 1],
                },
              },
            },
          });
        })
        .catch(() =>
          AlertMessage({
            type: 'danger',
            message: 'Ocorreu um erro ao tentar entrar na consulta.',
          }),
        );
    }
  };

  const generateHealthUnitSelectOptions = useCallback(() => {
    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            value: value.id,
            label: value.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  return (
    <Container>
      <InviteOtherProfessionalContainer>
        <Header>
          <IconSaudeMeetHorizontal />
        </Header>
        <TitleAndSubtitle>
          <Title>Consulta instantânea</Title>
          {!loading && (
            <Description>
              {`${professional?.professional?.firstName} ${professional?.professional?.lastName} está te enviando um link de consulta instantânea.
            Preencha seus dados para acessá-la.`}
            </Description>
          )}
        </TitleAndSubtitle>
        <Form>
          <Controller
            name="name"
            control={control}
            render={() => (
              <Input
                label="Nome do profissional"
                placeholder="Digite o nome do profissional"
                id="professional_name"
                name="name"
                required
                register={() => register('name')}
                onChange={e => {
                  setValue('name', e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={
                  professional?.otherProfessionals[0]?.professional
                    ? professional?.otherProfessionals[0]?.name
                    : professional?.otherProfessionals[0]?.name
                }
                error={Boolean(errors?.name)}
                message={errors?.name?.message}
                disabled={Boolean(
                  professional?.otherProfessionals[0]?.professional,
                )}
              />
            )}
          />
          <Controller
            name="unit"
            control={control}
            render={({ value, onChange }) => (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Label style={{ marginBottom: '6px' }}>Unidade de saúde</Label>
                <CreatableSelect
                  className="select"
                  components={{
                    Control,
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Option,
                  }}
                  controlShouldRenderValue
                  placeholder="Selecione a unidade"
                  formatCreateLabel={(label: string) => `Buscar por ${label}`}
                  options={generateHealthUnitSelectOptions()}
                  defaultValue={
                    professional?.healthUnit?.name?.length
                      ? {
                          label: professional?.healthUnit?.name,
                          value: professional?.healthUnit?.id,
                        }
                      : undefined
                  }
                  value={generateHealthUnitSelectOptions().find(
                    option => option.value === value,
                  )}
                  onChange={e => {
                    onChange(Number(e?.value), { shouldValidate: true });
                    setValue('unit', Number(e?.value), {
                      shouldValidate: true,
                    });
                  }}
                  styles={selectStyles()}
                  isValidNewOption={() => false}
                  noOptionsMessage={() => 'Nenhum resultado encontrado'}
                  isDisabled={Boolean(
                    professional?.otherProfessionals[0]?.professional,
                  )}
                />
              </div>
            )}
          />
          <InlineInputs>
            <Controller
              name="regType"
              control={control}
              render={() => (
                <Select
                  label="Conselho"
                  name="regType"
                  id="professional_conselho"
                  required
                  register={() => register('regType')}
                  onChange={e => {
                    setValue('regType', e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  error={Boolean(errors?.regType)}
                  message={errors?.regType?.message}
                  value={
                    professional?.otherProfessionals[0]?.professional &&
                    watch('regType')
                  }
                  disabled={Boolean(
                    professional?.otherProfessionals[0]?.professional,
                  )}
                >
                  <option value="">Selecione</option>
                  <option value="CRM">CRM</option>
                  <option value="COREN">COREN</option>
                  <option value="CRESS">CRESS</option>
                </Select>
              )}
            />
            <Controller
              name="uf"
              control={control}
              render={() => (
                <Select
                  label="UF"
                  name="uf"
                  id="select_uf"
                  required
                  register={() => register('uf')}
                  onChange={e => {
                    setValue('uf', e.target.value, {
                      shouldValidate: true,
                    });
                  }}
                  error={Boolean(errors?.uf)}
                  message={errors?.uf?.message}
                  value={
                    professional?.otherProfessionals[0]?.professional &&
                    watch('uf')
                  }
                  disabled={Boolean(
                    professional?.otherProfessionals[0]?.professional,
                  )}
                >
                  <option value="-">Selecione</option>
                  {ufs &&
                    ufs.length > 0 &&
                    ufs.map(uf => (
                      <option key={uf.id} value={uf.sigla}>
                        {uf.sigla}
                      </option>
                    ))}
                </Select>
              )}
            />
          </InlineInputs>
          <Controller
            name="regValue"
            control={control}
            render={() => (
              <Input
                label="Número do conselho"
                placeholder="00000000"
                name="regValue"
                register={() => register('regValue')}
                id="professional_value"
                required
                onChange={e => {
                  setValue('regValue', e.target.value, {
                    shouldValidate: true,
                  });
                }}
                defaultValue={
                  professional?.otherProfessionals[0]?.professional
                    ? professional?.otherProfessionals[0]?.regValue
                    : ''
                }
                error={Boolean(errors?.regValue)}
                message={errors?.regValue?.message}
                disabled={Boolean(
                  professional?.otherProfessionals[0]?.professional,
                )}
              />
            )}
          />
        </Form>
        <Buttons>
          <Button
            variant="primary"
            onClick={() => handleSubmitInstantAppointment()}
            disabled={
              professional?.otherProfessionals[0]?.professional
                ? false
                : !formState.isValid
            }
          >
            Entrar na consulta
          </Button>
        </Buttons>
      </InviteOtherProfessionalContainer>
    </Container>
  );
};

export default InviteProfessional;
