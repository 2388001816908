import React from 'react';
import {
  Content,
  ContentHeader,
  HeaderTitle,
  ContentBody,
  HeaderCloseIcon,
} from '../styles/sidebarStyles';

import { translator } from '../../i18n';
import Documents from './Documents';

interface SupportDocsProps {
  handleClose: () => void;
}

const SupportDocs: React.FC<SupportDocsProps> = ({ handleClose }) => {
  return (
    <Content overflow>
      <ContentHeader>
        <HeaderTitle>{translator('Documentos de apoio')}</HeaderTitle>
        <HeaderCloseIcon onClick={handleClose} />
      </ContentHeader>
      <ContentBody>
        <Documents />
      </ContentBody>
    </Content>
  );
};

export default SupportDocs;
