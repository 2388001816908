import React, { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDeleteClinicalDocs } from '~/main/factories/usecases/clinicalDocs/DeleteClinicalDocsFactory';
import { makeReduxSelectOne } from '~/main/factories/usecases/clinicalDocs/SelectOneFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import {
  IconCrossBlue,
  IconCrossRed,
  IconDelete,
} from '~/presentation/base/icons';
import { closeModal } from '~/utils/closeModal';
import { translator } from '../i18n';

import {
  Body,
  Container,
  Delete,
  Prefix,
  SubTitle,
  Title,
  Wrapper,
} from './styles/StyledSidebarDocumentItem';

interface DocumentsItemProps {
  type?: string;
  documentSize?: number | string;
  title: string;
  subTitle: string;
  isSigned?: boolean;
  id?: any;
  open?: Function;
  deleteDocument?: Function;
}

const SidebarDocumentItem: React.FC<DocumentsItemProps> = ({
  title,
  subTitle,
  isSigned,
  id,
  open,
  deleteDocument,
}) => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const pending = translator('Excluir documento');
  const confirmExclude = translator('Você deseja excluir o documento?');

  function handleDoc(idDoc: number) {
    makeReduxSelectOne().select({
      id: idDoc,
    });
  }

  const handleDelete = useCallback(() => {
    makeReduxActiveMessage().active({
      active: 'delete',
      title: pending,
      content: confirmExclude,
      actionOk: () => {
        makeReduxDeleteClinicalDocs().delete({
          docId: id,
          handleDelete: deleteDocument ?? undefined,
        });
        closeModal();
      },
      actionCancel: closeModal,
    });
  }, [confirmExclude, deleteDocument, id, pending]);

  return (
    <Container id={id}>
      <Prefix>
        <Wrapper isSigned={isSigned} id={id}>
          {isSigned ? <IconCrossBlue /> : <IconCrossRed />}
        </Wrapper>
      </Prefix>
      <Body
        onClick={() => {
          if (open) open();
        }}
      >
        <Title id={id} isSigned={isSigned} onClick={() => handleDoc(id)}>
          {title}
        </Title>
        <SubTitle>{subTitle}</SubTitle>
      </Body>
      {/* TODO: Atualizar com Role ACL */}
      {role === 'PRO' && (
        <Delete>
          <IconDelete onClick={handleDelete} />
        </Delete>
      )}
    </Container>
  );
};
export default SidebarDocumentItem;
