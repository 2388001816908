import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iActionUpdateUserAvatar } from '~/domain/interfaces/redux/users/updateUserAvatar';
import {
  updateSuccess,
  updateFailed,
} from '~/data/store/reducer/users/actions/updateUserAvatar';
import { makeRemoteUpdateUserAvatar } from '~/main/factories/usecases/users/UpdateUserAvatarFactory';
import { makeReduxGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onUpdateUserAvatarRequest(action: iActionUpdateUserAvatar) {
  const remoteUpdate = makeRemoteUpdateUserAvatar();
  const reduxGetOneUser = makeReduxGetOneUser();

  const { userId } = action.payload;
  try {
    yield call(remoteUpdate.post, action.payload);

    yield reduxGetOneUser.getOne({ userId: userId! });
    yield put(updateSuccess());
  } catch (error) {
    yield put(updateFailed());
  }
}

export function onUpdateUserAvatarSuccess() {
  // AlertMessage({
  //   message: 'Usuário atualizado com sucesso',
  //   type: 'success',
  // });
}

export function onUpdateUserAvatarFailed() {
  AlertMessage({
    message: intl.formatMessage({
      id: 'Não foi possível atualizar o usuário. Você digitou os campos corretamente?',
    }),
    type: 'danger',
  });
}
