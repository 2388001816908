/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { TableSortLabel } from '@material-ui/core';
import { translator } from '~/presentation/components/i18n';

import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { GetAuditRecord } from '~/domain/usecases/AuditRecord/remote';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { IconSort } from '~/presentation/base/icons';
import orderData from '~/utils/orderData';
import { ActionsList } from '../actionsList';
import ListSkeleton from '../skeleton/ListSkeleton';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    height: '100%',
  },
  container: {},
});

interface iTranslate {
  [key: string]: string;
}

type iAuditRecord = GetAuditRecord.Model['records'][0];

interface ownProps {
  dataList: iAuditRecord[];
}

type iColumn = {
  id: iOrder | 'actions';
  label?: string;
  minWidth?: number;
  maxWidth?: number;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
};

type iOrder = 'timestamp' | 'group' | 'event' | 'user';

const styles = {
  text: {
    padding: '1rem',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 'normal',
    hover: {
      backgroundColor: '#f8f8f8',
    },
  },
};

const AuditRecordList: React.FC<ownProps> = props => {
  const [order, setOrder] = useState<boolean>(true);
  const [statusDateToOrder, setStatusDateToOrder] = useState(true);
  const [statusTimeToOrder, setStatusTimeToOrder] = useState(true);
  const [dataList, setData] = useState<iAuditRecord[]>([...props.dataList]);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);

  const arrToCompare = ['timestamp', 'group', 'event', 'user'];

  const classes = useStyles();
  const columns: iColumn[] = [
    {
      id: 'timestamp',
      label: translator('Data'),
      maxWidth: 120,
      align: 'left',
    },
    {
      id: 'group',
      label: translator('Grupo'),
      minWidth: 50,
      maxWidth: 200,
      align: 'left',
    },
    {
      id: 'event',
      label: translator('Evento'),
      minWidth: 200,
      align: 'left',
    },
    {
      id: 'user',
      label: translator('Usuário'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 100,
      align: 'right',
    },
  ];

  function handleSortByCategory(type: keyof iAuditRecord | 'event' | 'group') {
    switch (type) {
      case 'timestamp':
        const orderStatusDate = statusDateToOrder ? 'asc' : 'desc';

        setData([
          ..._.orderBy(
            dataList,
            dateSort => dateSort.timestamp,
            `${orderStatusDate}`,
          ),
        ]);
        return setStatusDateToOrder(!statusDateToOrder);

      case 'user':
        setData([
          ..._.orderBy(
            dataList,
            dateSort => dateSort.user.firstName,
            `${order ? 'asc' : 'desc'}`,
          ),
        ]);
        return setOrder(!order);

      case 'action':
        const orderStatusTime = statusTimeToOrder ? 'asc' : 'desc';

        setData([
          ..._.orderBy(
            dataList,
            dateSort => dateSort.action.groupName,
            `${orderStatusTime}`,
          ),
        ]);
        return setStatusTimeToOrder(!statusTimeToOrder);

      case 'group':
        setData([
          ..._.orderBy(
            dataList,
            dateSort => dateSort.action.groupName,
            `${order ? 'asc' : 'desc'}`,
          ),
        ]);
        return setOrder(!order);

      case 'event':
        setData([
          ..._.orderBy(
            dataList,
            dateSort => dateSort.action.descr,
            `${order ? 'asc' : 'desc'}`,
          ),
        ]);
        return setOrder(!order);

      default:
        console.log('type', type);
        setOrder(prevState => !prevState);
        return setData([...orderData(dataList, order, 'timestamp')]);
    }
  }

  const RenderComponents: React.FC<{
    id: iOrder | 'actions';
    auditRecord: iAuditRecord;
  }> = ({ id, auditRecord }) => {
    switch (id) {
      case 'timestamp':
        const { timestamp } = auditRecord;
        const date = new Date(timestamp ?? 0);
        const dateFormatted = Intl.DateTimeFormat('pt-BR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        }).format(date);
        return <div>{dateFormatted}</div>;
      case 'group':
        return <div>{auditRecord?.action?.groupName}</div>;
      case 'event':
        return <div>{auditRecord?.action?.descr}</div>;
      case 'user':
        return (
          <div>{`${auditRecord?.user?.firstName} ${auditRecord?.user?.lastName}`}</div>
        );

      case 'actions':
        return (
          <ActionsList
            actions={{
              viewAudit: () =>
                makeReduxActiveMessage().active({
                  active: MessageOptions.viewAudit,
                  userId: auditRecord?.id,
                }),
            }}
          />
        );
      default:
        return <div>{auditRecord[id]}</div>;
    }
  };

  useEffect(() => {
    setOrder(false);
    setData([...orderData(props?.dataList, true, 'timestamp')]);
  }, [props.dataList]);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow
            style={{
              padding: '1rem',
            }}
          >
            {columns.map((item, index) => {
              return (
                <TableCell align={item.align ?? 'left'} key={index}>
                  {arrToCompare.includes(item.id) ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div style={{ paddingLeft: 3 }}>
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      onClick={() => handleSortByCategory(item.id as any)}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow key={item.id}>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            dataList.map((item: any) => (
              <TableRow
                key={item.id}
                hover
                style={{
                  padding: '2rem',
                }}
              >
                {columns.map(columnProps => {
                  return (
                    <TableCell
                      key={columnProps.id}
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        ...styles.text,
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        auditRecord={item}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AuditRecordList;
