import React from 'react';

import { PreSettingsGuest } from '~/presentation/components/preConfSettings';

import { Container } from './styles/StyledPreConf';

const PreConf: React.FC = () => {
  return (
    <Container>
      <PreSettingsGuest />
    </Container>
  );
};

export default PreConf;
