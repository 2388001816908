import { call, put } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/Consultant/getAll';
import { GetAllConsultant } from '~/domain/usecases/consultant/redux/GetAllConsultant';
import { makeRemoteGetAllConsultant } from '~/main/factories/usecases/consultant/GetAllConsultant';

import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/Consultant/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllConsultant = makeRemoteGetAllConsultant();

  try {
    const response: GetAllConsultant.Model = yield call(
      remoteGetAllConsultant.getAll,
      action.payload,
    );
    yield put(getAllSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getAllFailed());
  }
}

export function* onGetAllSuccess() {
  yield 10;
}

export function* onGetAllFailed() {
  yield 10;
}
