import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import AppointmentEmpty from '../componentEmpty/AppointmentEmpty';
import { iListRegisteredAppointment } from './interface';
import { ListAppointment } from './Lists';
import { ConnectComponent } from './mapper/MapperListRegisteredAppointment';

export interface ownProps {
  data: iListRegisteredAppointment[];
}

const ListRegisteredAppointment: React.FC<ownProps> = ({ data }) => {
  useEffect(() => {
    makeReduxDataPagination().set([]);
  }, []);
  const { loading } = useSelector((store: iStore) => store.appointment);
  return data.length === 0 && !loading ? (
    <AppointmentEmpty />
  ) : (
    <ListAppointment />
  );
};

export default ConnectComponent(ListRegisteredAppointment);
