import {
  iActionSelectUser,
  AuthTypes,
} from '~/domain/interfaces/redux/auth/SelectUser';

import { SelectUser } from '~/domain/usecases/auth/redux';

export const selectUser = (payload: SelectUser.Params): iActionSelectUser => ({
  type: AuthTypes.SELECT_USER,
  payload,
});
