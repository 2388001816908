import { CreateConsultant } from '~/domain/usecases/consultant/redux/CreateConsultant';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  ConsultantTypes,
} from '~/domain/interfaces/redux/Consultant/create';

export const createRequest = (
  payload: CreateConsultant.Params,
): iActionCreate => ({
  type: ConsultantTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateConsultant.Model,
): iActionCreateSuccess => ({
  type: ConsultantTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: ConsultantTypes.CREATE_FAILED,
});
