import { takeLatest } from 'redux-saga/effects';
import * as Get from './Get';
import * as GetAll from './GetAll';
import * as GetAllMessage from './GetAllMessage';
import * as CreateMessage from './CreateMessage';
import * as Setup from './Setup';
import * as ChatMessage from './ChatMessage';
import * as SocketCreateMessage from './SocketCreateMessage';
import { ChatTypes } from '~/domain/interfaces/redux/chat';

const tasks = [
  takeLatest(ChatTypes.GET, Get.onGet),
  takeLatest(ChatTypes.GET_SUCCESS, Get.onGetSuccess),
  takeLatest(ChatTypes.GET_FAILED, Get.onGetFailed),
  takeLatest(ChatTypes.GETALL, GetAll.onGetAll),
  takeLatest(ChatTypes.GETALL_SUCCESS, GetAll.onGetAllSuccess),
  takeLatest(ChatTypes.GETALL_FAILED, GetAll.onGetAllFailed),
  takeLatest(ChatTypes.GETALL_MESSAGE, GetAllMessage.onGetAllMessage),
  takeLatest(
    ChatTypes.GETALL_MESSAGE_SUCCESS,
    GetAllMessage.onGetAllMessageSuccess,
  ),
  takeLatest(
    ChatTypes.GETALL_MESSAGE_FAILED,
    GetAllMessage.onGetAllMessageFailed,
  ),
  takeLatest(ChatTypes.CREATE_MESSAGE, CreateMessage.onCreateMessage),
  takeLatest(
    ChatTypes.CREATE_MESSAGE_SUCCESS,
    CreateMessage.onCreateMessageSuccess,
  ),
  takeLatest(
    ChatTypes.CREATE_MESSAGE_FAILED,
    CreateMessage.onCreateMessageFailed,
  ),
  takeLatest(ChatTypes.CHAT_SETUP, Setup.onSetup),
  takeLatest(
    ChatTypes.SOCKET_CREATE_MESSAGE,
    SocketCreateMessage.onSocketCreateMessage,
  ),
  takeLatest(
    ChatTypes.SOCKET_CREATE_MESSAGE_SUCCESS,
    SocketCreateMessage.onSocketCreateMessageSuccess,
  ),
  takeLatest(
    ChatTypes.SOCKET_CREATE_MESSAGE_FAILED,
    SocketCreateMessage.onSocketCreateMessageFailed,
  ),
];

export default tasks;
