import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { SoapAiAppointment as UsecaseRemoteSoapAiAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteSoapAiAppointment } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteSoapAiAppointment = (): UsecaseRemoteSoapAiAppointment =>
  new RemoteSoapAiAppointment(
    makeApiUrl('/appointments/{appointmentId}/soap'),
    makeHttpClient(),
  );
