import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { GetUserDataWaitingRoom } from '~/domain/usecases/waitingRoom/remote';
import { ParticipantPages } from '~/presentation/pages';
import WaitingRoomLaoha from '~/presentation/pages/WaitingRoomLaoha';
import { makeRemoteGetUserDataWaitingRoom } from '../../usecases/waitingRoom/GetUserDataWaitingRoomFactory';
import { MakeWaitingRoomLaoha } from './WaitingRoomLaohaFactory';
import { WaitingRoom as WaitingRoomProfessional } from '~/presentation/roles/professional/pages';

interface iStateParams {
  appointmentId: number;
}

export const MakeWaitingRoomParticipant: React.FC = (): JSX.Element => {
  const [waitingRoom, setWaitingRoom] = useState<
    GetUserDataWaitingRoom.Model | undefined
  >();
  const [loading, setLoading] = useState(true);
  const user = useSelector((store: iStore) => store.auth);
  const { appointmentId } = useLocation<iStateParams>().state;

  useEffect(() => {
    const getWaitingRoom = async () => {
      const userId = user.info.user?.id;

      if (userId) {
        await makeRemoteGetUserDataWaitingRoom()
          .getUserData({
            appointmentId,
            userId,
          })
          .then(response => {
            setWaitingRoom(response);
            setLoading(false);
          })
          .catch(error => {
            setLoading(false);
          });
      }
    };

    getWaitingRoom();
  }, [appointmentId, user]);

  if (!loading && waitingRoom?.id)
    return <WaitingRoomLaoha medias={waitingRoom.medias} />;

  if (!loading && !waitingRoom?.id)
    return <WaitingRoomProfessional role="CON" />;

  return <div />;
};
