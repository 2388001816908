import { GetAllAppointmentPriorities } from '~/domain/usecases/appointmentPriorities/redux/GetAllAppointmentPriorities';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  AppointmentPrioritiesTypes,
} from '~/domain/interfaces/redux/appointmentPriorities/getAll';

export const getAllRequest = (
  payload: GetAllAppointmentPriorities.Params,
): iActionGetAll => ({
  type: AppointmentPrioritiesTypes.GET_ALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllAppointmentPriorities.Model,
): iActionGetAllSuccess => ({
  type: AppointmentPrioritiesTypes.GET_ALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: AppointmentPrioritiesTypes.GET_ALL_FAILED,
});
