import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeRemoteCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { makeRemoteAcceptOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AcceptOnCallSpecialistFactory';
import { IconCamPlus } from '~/presentation/base/icons';
import { closeModal } from '~/utils/closeModal';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { Button } from '../UI';
import { AlertMessage } from '../messages/AlertMessage';
import { CheckIcon } from './styles/Styled';

interface OwnProps {
  info: iOnCallRequest['records'][0] | null;
  selectedRequester?: boolean;
  variant: 'SPECIALIST' | 'REQUESTER';
  handleReset?: () => void;
}

const DetailsCardButtons: React.FC<OwnProps> = ({
  info,
  variant,
  handleReset,
}) => {
  const { accepted } = useSelector((store: iStore) => store.onCall);
  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const id = useSelector((state: iStore) => state.auth.info.user?.id);

  const professional = getProfessionalInfo();
  const isMyRequest =
    info?.requester?.id === professional?.id ||
    info?.specialist?.id === professional?.id;

  const cancelInterconsult = () => {
    makeReduxActiveMessage().active({
      active: 'cancelInterconsult',
      actionCancel: () => {
        closeModal();
      },
      data: {
        id: info?.id,
        onSuccess: () => {
          makeReduxListOnCallRequest().list({
            body: {
              dataControl: {
                limit: 9999,
              },
              filters: {
                requester: professional?.id,
                status: 'WAITING',
                healthUnit: info?.healthUnit.id,
              },
            },
          });
          handleReset?.();
        },
      },
    });
  };

  const continueInterconsult = () => {
    const isRequester = professional?.id === info?.requester?.id;

    makeRemoteCreateAppointment()
      .create({
        org: orgId,
        orgUnit: orgUnitId,
        organizer: id,
        professional: professional?.id,
        specialty: professional?.professions?.specialties?.[0]?.id,
        healthUnit: info?.healthUnit?.id,
        date: String(new Date()),
        professionals: [
          {
            id: isRequester ? info?.specialist?.id : info?.requester?.id,
            name: isRequester
              ? info?.specialist?.fullname
              : info?.requester?.fullname,
          },
        ],
        info: {
          name: info?.consultant?.fullname,
          birthDate: info?.consultant?.birthdate,
          regType: info?.consultant?.docType,
          regValue: info?.consultant?.docValue,
          sex: info?.consultant?.sex ?? undefined,
        },
        service: 'INSTANTANEOUS',
        onCallRequest: info?.id,
      })
      .then(res => {
        makeReduxActiveMessage().active({
          active: 'instantConsultationSuccess',
          link: res.links.otherProfessionals?.[0].link,
          data: {
            professional: id,
            appointmentId: res.id,
            isRequester,
            healthUnit: info?.healthUnit,
            outcomeByRequester: isRequester,
            onCall: {
              sessionName: '',
              id: info?.onCall?.id,
              ...(isRequester
                ? { requester: professional?.id }
                : {
                    name: info?.onCall?.name,
                    specialist: professional?.id,
                  }),
            },
          },
        });
      })
      .catch(() => {
        AlertMessage({
          message: 'Falha ao criar interconsulta!',
          type: 'danger',
        });
      });
  };

  const acceptInterconsult = () => {
    if (professional && info)
      makeRemoteAcceptOnCallSpecialist()
        .accept({
          onCallId: info?.onCall.id,
          specialistId: professional?.id,
          requestId: info?.id,
        })
        .catch(() => {
          AlertMessage({
            message: 'Erro ao aceitar solicitação!',
            type: 'danger',
          });
        });
  };

  const isAccepted = () => {
    return accepted?.state && accepted.request === info?.id;
  };

  const renderButton = () => {
    if (info?.status === 'WAITING') {
      if (variant === 'REQUESTER')
        return (
          <Button
            variant="secundary"
            height="40px"
            style={{ width: '240px' }}
            onClick={cancelInterconsult}
          >
            Cancelar solicitação
          </Button>
        );
      if (variant === 'SPECIALIST')
        return (
          <Button
            variant={accepted?.state ? 'secundary' : 'primary'}
            height="40px"
            style={{ width: '240px' }}
            onClick={acceptInterconsult}
            icon={isAccepted() ? CheckIcon : undefined}
            disabled={accepted?.state || isMyRequest}
          >
            {isAccepted() ? 'Solicitação aceita' : 'Aceitar solicitação'}
          </Button>
        );
    }
    if (
      (info?.status === 'INPROGRESS' && info?.outcome.msgKey !== 'DEATH') ||
      info?.outcome?.msgKey !== 'CLINICAL_DISCHARGE'
    ) {
      if (variant === 'REQUESTER')
        return (
          <Button
            variant="primary"
            height="40px"
            style={{ width: '240px' }}
            onClick={continueInterconsult}
          >
            Continuar solicitação
          </Button>
        );
    }
    if (variant === 'SPECIALIST')
      return (
        <Button
          variant="primary"
          height="40px"
          style={{ width: '240px' }}
          onClick={continueInterconsult}
          icon={IconCamPlus}
        >
          Criar interconsulta
        </Button>
      );

    return null;
  };

  return <>{renderButton()}</>;
};

export default DetailsCardButtons;
