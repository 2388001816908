import React, { useState, useEffect, useCallback } from 'react';
import { IconCloseModal } from '~/presentation/base/icons';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { makeRemoteGetAppointmentOutcome } from '~/main/factories/usecases/appointment/GetAppointmentOutcomeFactory';
import { makeRemoteUpdateOnCallRequest } from '~/main/factories/usecases/onCallRequest/UpdateOnCallRequestFactory';
import { closeModal } from '~/utils/closeModal';
import { AlertMessage } from '../messages/AlertMessage';
import { Button, Select } from '../UI';
import {
  BoxIcon,
  Container,
  ContainerModal,
  HeaderModal,
  SubTitle,
  Title,
  HeaderContent,
  Form,
  Divider,
  TextAreaForm,
  Footer,
} from './styles/StyledModalUpdateRequestInProgress';

interface propsModalUpdateRequestWaiting {
  message: iMessage;
}

interface iUpdatedRequest {
  outcome: number;
  forwarding: string;
  description: string;
}

interface iAppointmentOutcome {
  id: number;
  client: string;
  name: string;
  descr: string;
  msgKey: string;
  conclusive: boolean;
}

const ModalUpdateRequestInProgress: React.FC<
  propsModalUpdateRequestWaiting
> = ({ message }) => {
  const { active, actionCancel, data } = message;

  const [requestUpdated, setRequestUpdated] =
    useState<Partial<iUpdatedRequest>>();

  const [appointmentOutcomes, setAppointmentOutcomes] = useState<
    iAppointmentOutcome[]
  >([]);

  const msgName = MessageOptions.updatedRequestWaiting;

  useEffect(() => {
    if (active === msgName) {
      makeRemoteListOnCallRequest()
        .list({
          body: {
            filters: {
              id: Number(data?.requestId),
            },
          },
        })
        .then(response => {
          setRequestUpdated({
            description: response.records[0].appointments[0].clinicalCondition,
            forwarding: response.records[0].appointments[0].forwarding,
            outcome: response.records[0].outcome.id,
          });
        })
        .catch(() => {
          AlertMessage({
            type: 'danger',
            message: 'Erro ao buscar a solicitação. Tente novamente.',
          });
        });

      makeRemoteGetAppointmentOutcome()
        .getRecordings({})
        .then(res => {
          setAppointmentOutcomes(res as iAppointmentOutcome[]);
        })
        .catch(() => {
          AlertMessage({
            type: 'danger',
            message: 'Erro ao buscar desfecho. Tente novamente.',
          });
        });
    }
  }, [active, data?.requestId, msgName]);

  const updatedOutcome = useCallback(() => {
    makeRemoteUpdateOnCallRequest()
      .update({
        requestId: Number(data?.requestId),
        body: {
          appointment: {
            outcome: requestUpdated?.outcome,
            forwarding: requestUpdated?.forwarding ?? undefined,
            clinicalCondition: requestUpdated?.description ?? undefined,
          },
        },
      })
      .then(() => {
        AlertMessage({
          type: 'success',
          message: 'Informações atualizadas com sucesso!',
        });
        data?.onSuccess();
        setRequestUpdated({});
        closeModal();
      })
      .catch(e => {
        AlertMessage({
          type: 'danger',
          message: 'Erro ao atualizar informações. Tente novamente.',
        });
        closeModal();
      });
  }, [data, requestUpdated]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <HeaderContent>
              <HeaderModal>
                <Title>Atualização da solicitação</Title>
                <BoxIcon
                  onClick={() => {
                    actionCancel?.();
                    setRequestUpdated({});
                  }}
                >
                  <IconCloseModal />
                </BoxIcon>
              </HeaderModal>
              <SubTitle>
                Sua atualização ficará registrada nos detalhes do atendimento.
              </SubTitle>
            </HeaderContent>
            <Divider />
            <Form>
              <Select
                label="Desfecho do atendimento"
                onChange={e =>
                  setRequestUpdated({
                    ...requestUpdated,
                    outcome: Number(e.target.value),
                  })
                }
                defaultValue={requestUpdated?.outcome}
                value={requestUpdated?.outcome}
              >
                {appointmentOutcomes?.map((outcome: iAppointmentOutcome) => (
                  <option key={outcome.id} value={outcome.id}>
                    {`${outcome.name} (${outcome.descr})`}
                  </option>
                ))}
              </Select>

              <TextAreaForm>
                <h3>Subjetivo e Objetivo</h3>
                <textarea
                  style={{
                    width: '100%',
                    height: '120px',
                  }}
                  placeholder="Digite as informações"
                  onChange={e =>
                    setRequestUpdated({
                      ...requestUpdated,
                      description: e.target.value,
                    })
                  }
                  defaultValue={requestUpdated?.description}
                />
              </TextAreaForm>

              <TextAreaForm>
                <h3>Avaliação e plano de cuidado</h3>
                <textarea
                  style={{
                    width: '100%',
                    height: '120px',
                  }}
                  placeholder="Digite as informações"
                  onChange={e =>
                    setRequestUpdated({
                      ...requestUpdated,
                      forwarding: e.target.value,
                    })
                  }
                  defaultValue={requestUpdated?.forwarding}
                />
              </TextAreaForm>
            </Form>
            <Divider />
            <Footer>
              <Button
                style={{ width: '233px' }}
                variant="secundary"
                height="40px"
                onClick={() => {
                  actionCancel?.();
                  setRequestUpdated({});
                }}
              >
                Cancelar
              </Button>
              <Button
                style={{ width: '233px' }}
                variant="primary"
                height="40px"
                onClick={updatedOutcome}
              >
                Atualizar atendimento
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalUpdateRequestInProgress;
