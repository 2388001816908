/* eslint-disable @typescript-eslint/no-empty-function */
import { call, put } from 'redux-saga/effects';
import { iActionListShifts } from '~/domain/interfaces/redux/shifts/list';

import { ListShifts } from '~/domain/usecases/shifts/remote';

import {
  listShiftsFailed,
  listShiftsSuccess,
} from '~/data/store/reducer/shifts/actions/list';

import { makeRemoteListShifts } from '~/main/factories/usecases/shifts';

export function* onListShifts(action: iActionListShifts) {
  const remoteDeleteShifts = makeRemoteListShifts();

  try {
    const response: ListShifts.Model = yield call(
      remoteDeleteShifts.list,
      action.payload,
    );

    yield put(listShiftsSuccess(response));
  } catch (e) {
    yield put(listShiftsFailed());
  }
}

export function onListShiftsSuccess() {}

export function onListShiftsFailed() {}
