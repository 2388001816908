import {
  AuthTypes,
  iActionUpdateUserInfo,
} from '~/domain/interfaces/redux/auth/UpdateUserInfo';
import { GetOneUser } from '~/domain/usecases/users/remote/GetOneUser';

export const updateUserInfo = (
  payload: GetOneUser.Model,
): iActionUpdateUserInfo => ({
  type: AuthTypes.UPDATE_USER_INFO,
  payload,
});
