import { GetBillingDetailed } from '~/domain/usecases/billing/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UnexpectedError, NotFound, Forbidden } from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteGetBillingDetailed implements GetBillingDetailed {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetBillingDetailed.Model>;

  constructor(url: string, httClient: HttpClient<GetBillingDetailed.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getBillingDetailedService = async (
    params: GetBillingDetailed.Params,
  ): Promise<GetBillingDetailed.Model> => {
    let query = '';

    Object.entries(params.query).forEach(([key, value]) => {
      if (value) query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.orgId}/billing/detailed/?${query}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
