import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateInstantAppointment as UseCaseRemoteUpdateInstantAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteUpdateInstantAppointment } from '~/data/repository/appointment/RemoteUpdateInstantAppointment';

/**
 * send request via API.
 */

export const makeRemoteUpdateInstantAppointment =
  (): UseCaseRemoteUpdateInstantAppointment =>
    new RemoteUpdateInstantAppointment(
      makeApiUrl('/appointments/'),
      makeHttpClient(),
    );
