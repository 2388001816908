import { iOnCallSpecialist } from '~/domain/interfaces/models/OnCallSpecialist';
import { OnCallSpecialistTypes } from '~/domain/interfaces/redux/onCallSpecialist/types';
import { OnCallSpecialistActions } from './actions';

export const initialState: iOnCallSpecialist = {
  loading: false,
  records: [],
  metadata: '',
};

const reducer = (
  state = initialState,
  action: OnCallSpecialistActions,
): iOnCallSpecialist => {
  switch (action.type) {
    case OnCallSpecialistTypes.LIST:
      return { ...state, loading: true };
    case OnCallSpecialistTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OnCallSpecialistTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
