import { takeLatest } from 'redux-saga/effects';
import {
  onUpdateFailed,
  onUpdateNotification,
  onUpdateSuccess,
} from './Update';
import { onGetAllSuccess, onGetAllFailed, onGetAllRequest } from './GetAll';
import { NotificationTypes } from '~/domain/interfaces/redux/Notifications/types';

const tasks = [
  takeLatest(NotificationTypes.GETALL, onGetAllRequest),
  takeLatest(NotificationTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(NotificationTypes.GETALL_FAILED, onGetAllFailed),

  takeLatest(NotificationTypes.UPDATE, onUpdateNotification),
  takeLatest(NotificationTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(NotificationTypes.UPDATE_FAILED, onUpdateFailed),
];

export default tasks;
