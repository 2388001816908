import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CancelOnCallRequest as UsecaseRemoteCancelOnCallRequest } from '~/domain/usecases/onCallRequest/remote';

import { RemoteCancelOnCallRequest } from '~/data/repository/onCallRequest';

/**
 * send request via API.
 */
export const makeRemoteCancelOnCallRequest =
  (): UsecaseRemoteCancelOnCallRequest =>
    new RemoteCancelOnCallRequest(
      makeApiUrl('/oncall/requests'),
      makeHttpClient(),
    );
