import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { UpdateSupportDocs } from '~/domain/usecases/supportDoc/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemoteUpdateSupportDocs implements UpdateSupportDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateSupportDocs.Model>;

  constructor(url: string, httClient: HttpClient<UpdateSupportDocs.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (
    params: UpdateSupportDocs.Params,
  ): Promise<UpdateSupportDocs.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.docId}`,
      method: 'patch',
      body: {
        appointment: params.appointment,
        type: params.type,
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badGateway:
        throw new UnexpectedError();
      default:
        throw new UnexpectedError();
    }
  };
}
