import React from 'react';
import { saveAs } from 'file-saver';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '~/presentation/components/UI';

import { Container } from '../Background';

import { ownProps } from '../interface';

import {
  ContainerTerms,
  Header,
  Footer,
  FooterColumnLeft,
  FooterColumnRight,
  ContentTermsOfUse,
  TextTerms,
  Text,
} from './styles/StyledTermsOfUse';
import { Option } from '../../optionsMore/styles/optionsMore';
import { IconCloud, Impress } from '~/presentation/base/icons';

const TermsOfUse: React.FC<ownProps> = ({ message, id }) => {
  const msgName = MessageOptions.termsOfUse;

  const { active, actionCancel, actionOk } = message;

  // const downloadFile = async () => {
  //   const data =

  //   const link = document.createElement('a');
  //   const urlToDownload = window.URL.createObjectURL(data);
  //   const name = 'receituario';
  //   link.href = urlToDownload;
  //   link.download = name;
  //   link.click();
  // };

  return (
    <>
      {msgName === active ? (
        <Container>
          <ContainerTerms>
            <Header>
              <span>Termos de Uso</span>
            </Header>
            <ContentTermsOfUse>
              <strong>
                TERMO DE CONSENTIMENTO INFORMADO ELETRÔNICO PARA CONSULTA A DIST
                NCIA (TCLE)
              </strong>
              <br />
              <TextTerms>
                <p>
                  Declaro que ao acessar o ambiente de teleatendimento on-line
                  disponibilizado por meio do link enviado, autorizo o
                  profissional de saúde a realizar a consulta através de sistema
                  de atendimento remoto por vídeo (teleatendimento).
                </p>
                <br />
                <p>Declaro ter ciência que:</p>
                <br />
                <p>
                  1) a consulta por teleatendimento possui algumas limitações;
                </p>
                <br />

                <p>
                  2) poderá ser necessária a realização de exames
                  complementares, a critério do médico;
                </p>
                <br />

                <p>
                  3) poderá ser necessária complementar com uma consulta
                  presencial para realização de exame clínico, a critério do
                  médico;
                </p>
                <br />

                <p>
                  4) deverei procurar uma unidade de pronto atendimento ou
                  hospitalar em caso de quadro de saúde de URGÊNCIA ou
                  EMERGÊNCIA;
                </p>
                <br />

                <p>
                  5) a consulta eletrônica é individual e pontual, e não garante
                  ao Paciente o direito ao atendimento por tempo indeterminado
                  ou à disposição do profissional de saúde em outros horários
                  não acordados previamente entre as partes;
                </p>
                <br />

                <p>
                  6) falhas na conexão da internet podem exigir o cancelamento e
                  remarcação da consulta; estou ciente da necessidade de testar
                  meu equipamento 15 minutos antes da hora agendada para a
                  consulta;
                </p>
                <br />

                <p>
                  7) deverei preservar e manter a confidencialidade das imagens
                  (foto e vídeo), dos dados, dos diálogos, orientações,
                  prescrições e todo o conteúdo referentes à teleconsulta;
                </p>
                <br />

                <p>
                  8) autorizo a utilização de tecnologia e aplicativos da
                  internet como forma de comunicação e remessa de receitas,
                  pedidos de exames, relatórios, ou atestados, ciente das
                  vulnerabilidades do sistema quanto ao sigilo da informação,
                  assumindo eventuais riscos;
                </p>
                <br />

                <p>
                  9) concordo com a entrega de material físico de solicitação de
                  exames, receitas, relatórios ou atestados médicos a portador
                  autorizado por mim, e a meu pedido, observando-se, a
                  necessidade de proteção de todas as informações;
                </p>
                <br />
              </TextTerms>
              <br />
              <strong>
                Como condição para utilizar o serviço de Teleatendimento, você
                concorda em:
              </strong>
              <br />
              <TextTerms>
                <p>10) fornecer informações verdadeiras e exatas;</p>
                <br />

                <p>
                  11) aceitar que o você é o único responsável por toda e
                  qualquer informação, estando sujeito às conseqüências,
                  administrativas e legais, decorrentes de declarações falsas ou
                  inexatas que vierem a causar prejuízos ao profissional ou
                  instituição de saúde ou a terceiros;
                </p>
                <br />

                <p>
                  12) não utilizar o serviço para fins ilícitos ou proibidos;
                </p>
                <br />

                <p>
                  13) não utilizar o serviço para transmitir/divulgar material
                  ilícito, proibido ou difamatório, que viole a privacidade de
                  terceiros, ou que seja abusivo, ameaçador, discriminatório,
                  injurioso, ou calunioso;
                </p>
                <br />

                <p>
                  14) não utilizar o serviço para transmitir/divulgar material
                  que incentive discriminação ou violência;
                </p>
                <br />

                <p>
                  15) não transmitir e/ou divulgar qualquer material que viole
                  direitos de terceiros, incluindo direitos de propriedade
                  intelectual;
                </p>
                <br />

                <p>
                  16) não obter ou tentar obter acesso não-autorizado a outros
                  sistemas ou redes de computadores conectados ao serviço (ações
                  de hacker);
                </p>
                <br />

                <p>
                  17) não interferir ou interromper o serviço, as redes ou os
                  servidores conectados ao serviço;
                </p>
                <br />

                <p>
                  18) não criar falsa identidade ou utilizar-se de subterfúgios
                  com a finalidade de enganar outras pessoas ou de obter
                  benefícios;
                </p>
                <br />

                <p>
                  19) solicitar autorização para incluir links para outros sites
                  e/ou bases de dados; e
                </p>
                <br />

                <p>
                  20) comunicar imediatamente qualquer discrepância constatada
                  pelo usuário nos dados e informações cadastradas e divulgadas
                  no e pelo Sistema, concomitante à sua correção procedida por
                  ele próprio.
                </p>
                <br />

                <p>
                  Este TCLE segue as orientações dispostas na Lei nº 12.842, de
                  10 de julho de 2013, na Lei nº 12.965, de 23 de abril de 2014,
                  na Lei nº 13.709, de 14 de agosto de 2018, na Lei nº 13.787,
                  de 27 de dezembro de 2018 e na Portaria GM/MS Nº 1.348, de 02
                  de junho de 2022.
                </p>
                <br />

                <p>
                  O presente TCLE é firmado entre a Rede Nacional de Ensino e
                  Pesquisa. (“RNP”) e você ou a instituição que você representa
                  (“você” ou “suas”) para o uso dos Produtos/Serviços da RNP.
                </p>
                <br />

                <p>
                  Por fim, declaro ter lido as informações e orientações
                  contidas no presente instrumento, as quais entendi
                  perfeitamente e aceito.
                </p>
                <br />

                <p>
                  Assim, ao clicar no botão abaixo, expresso meu pleno
                  consentimento para a realização da teleconsulta.
                </p>
              </TextTerms>
              <br />
              <text>Rio de Janeiro</text>
              <br />
            </ContentTermsOfUse>
            <Footer>
              <FooterColumnLeft>
                <Option>
                  <IconCloud style={{ width: '30px', height: '30px' }} />
                  <Text
                    onClick={() =>
                      saveAs(
                        'https://minio.v4h.cloud/public/TERMO_DE_CONSENTIMENTO_INFORMADO_ELETRONICO_PARA_CONSULTA_A_DISTANCIA.pdf',
                        'TERMO_DE_CONSENTIMENTO_INFORMADO_ELETRONICO_PARA_CONSULTA_A_DISTANCIA.pdf',
                      )
                    }
                  >
                    Download
                  </Text>
                </Option>
                <Option>
                  <Impress style={{ width: '28px', height: '28px' }} />
                  <Text
                    target="blank"
                    href="https://minio.v4h.cloud/public/TERMO_DE_CONSENTIMENTO_INFORMADO_ELETRONICO_PARA_CONSULTA_A_DISTANCIA.pdf"
                  >
                    Imprimir
                  </Text>
                </Option>
              </FooterColumnLeft>
              <FooterColumnRight>
                <Button
                  variant="primary"
                  size="medium"
                  rounded
                  onClick={actionCancel}
                >
                  OK
                </Button>
              </FooterColumnRight>
            </Footer>
          </ContainerTerms>
        </Container>
      ) : null}
    </>
  );
};

export default TermsOfUse;
