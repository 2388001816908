import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetUserDataWaitingRoom as UsecaseRemoteGetUserDataWaitingRoom } from '~/domain/usecases/waitingRoom/remote';
// import { GetUserDataWaitingRoom as UsecaseReduxGetUserDataWaitingRoom } from '~/domain/usecases/waitingRoom/redux';

import { RemoteGetUserDataWaitingRoom } from '~/data/repository/waitingRoom';
// import { ReduxGetUserDataWaitingRoom } from '~/data/store/reducer/waitingRoom/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetUserDataWaitingRoom =
  (): UsecaseRemoteGetUserDataWaitingRoom =>
    new RemoteGetUserDataWaitingRoom(
      makeApiUrl('/appointments/{appointmentId}/users/{userId}/waitingRoom'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxGetUserDataWaitingRoom =
  (): UsecaseReduxGetUserDataWaitingRoom =>
    new ReduxGetUserDataWaitingRoom(); */
