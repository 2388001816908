import { ResendEmail } from '~/domain/usecases/users/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteResendEmail implements ResendEmail {
  private readonly url: string;

  private readonly httpClient: HttpClient<ResendEmail.Model>;

  constructor(url: string, httClient: HttpClient<ResendEmail.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  resend = async (params: ResendEmail.Params): Promise<ResendEmail.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    console.log('Resposta do servidor ao criar um usuário:', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.conflict:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
