import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import {
  CreateSupportDocs,
  GetAllSupportDocs,
} from '~/domain/usecases/supportDoc/remote';
import { makeRemoteCreateSupportDocs } from '~/main/factories/usecases/supportDoc/CreateSupportDocsFactory';
import { makeRemoteGetAllSupportDocs } from '~/main/factories/usecases/supportDoc/GetAllSupportDocsFactory';
import { IconPlus } from '~/presentation/base/icons';
import { DocsEmpty } from '~/presentation/components/componentEmpty';
import { DocumentsList } from '~/presentation/components/documents-list';
import { translator } from '~/presentation/components/i18n';
import { iSupportDocs } from '~/presentation/roles/professional/pages/appointment/interface';
import { V4hSpin } from '../spin';
import { Container, StyleInput } from './style/StyledUploadFiles';

interface ownProps {
  docs?: iSupportDocs[];
}

interface Params {
  id: string;
}
interface iStateParams {
  appointmentId: number;
}

const UploadFiles: React.FC<ownProps> = () => {
  const userID = useSelector((store: iStore) => store.auth.info.user?.id);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const {
    state: { appointmentId },
  } = useLocation<iStateParams>();
  const [docsup, setDocs] = useState<GetAllSupportDocs.Model>();
  const message = translator(
    'Você não tem nenhum documento associado ao atendimento.',
  );

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getAllDocs();
  }, []);

  const getAllDocs = () => {
    makeRemoteGetAllSupportDocs()
      .getAll({
        appointment: Number(appointmentId),
      })
      .then((response: GetAllSupportDocs.Model) => {
        setDocs(response);
      })
      .catch(er => {
        console.error(er);
      });
  };

  const onChange = (data: any) => {
    const formData = new FormData();
    formData.append('filename', data.target.files[0]);
    formData.append('sender', String(userID!));
    formData.append('appointment', String(appointmentId));
    formData.append('type', '1'); // TODO: Dados mockados alterar eventualmente

    setLoading(true);

    makeRemoteCreateSupportDocs()
      .create({ data: formData })
      .then((response: CreateSupportDocs.Model) => {
        getAllDocs();
      })
      .catch(er => {
        console.error(er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function permissions() {
    if (role === 'PRO') {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '20px',
          }}
        >
          <Container disabled={loading}>
            <StyleInput disabled={loading}>
              {loading ? (
                <V4hSpin fullScreen={false} size="24px" />
              ) : (
                <>
                  <IconPlus
                    width="14px"
                    height="14px"
                    style={{
                      display: 'flex',
                      textAlign: 'center',
                      alignItems: 'center',
                    }}
                  />
                  {`${translator('Novo documento de apoio')}`}
                  <input
                    type="file"
                    width="400vw"
                    id="upload"
                    name="upload"
                    accept=".pdf, .jpg, .jpeg, .png, .bmp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .odt, .odp, .ods, .mp4, .exe, .dvg"
                    onChange={onChange}
                    style={{ display: 'none' }}
                  />
                </>
              )}
            </StyleInput>
          </Container>
          {docsup?.results.length ? (
            <DocumentsList type="supporting-documents" docsSup={docsup} />
          ) : (
            <DocsEmpty message={message} />
          )}
        </div>
      );
    }

    if (role === 'CON' || role === 'ORG') {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '20px',
          }}
        >
          {docsup?.results.length ? (
            <DocumentsList type="supporting-documents" docsSup={docsup} />
          ) : (
            <DocsEmpty message={message} />
          )}
        </div>
      );
    }

    return '';
  }

  return <>{permissions()}</>;
};

export default UploadFiles;
