import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  div:nth-child(1):nth-last-child(2),
  div:nth-child(1):nth-last-child(1) {
    flex: 1 0 33%;
  }

  div:nth-child(2):only-child,
  div:nth-child(2):nth-last-child(2) {
    flex: 1 0 33%;
  }

  div:nth-child(3):nth-last-child(1) {
    flex: 1 0 33%;
  }
`;

export const RowBiggerCenter = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  div:nth-child(1):nth-last-child(2),
  div:nth-child(1):nth-last-child(1) {
    flex: 1 0 33%;
  }

  div:nth-child(2):only-child,
  div:nth-child(2):nth-last-child(2) {
    flex: 1 0 47%;
  }

  div:nth-child(3):nth-last-child(1) {
    flex: 1 0 25%;
  }
`;
