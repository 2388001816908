import { call, put, select } from 'redux-saga/effects';
import { iActionUpdate } from '~/domain/interfaces/redux/administrator/update';
import {
  UpdateFailed,
  UpdateSuccess,
} from '~/data/store/reducer/administrator/actions/update';

export function* onUpdate(action: iActionUpdate) {
  yield 10;
}

export function* onUpdateSuccess() {
  yield 10;
}

export function* onUpdateFailed() {
  yield 10;
}
