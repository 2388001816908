import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllProfession as UsecaseRemoteGetAllProfession } from '~/domain/usecases/profession/remote';
import { GetAllProfession as UsecaseReduxGetAllProfession } from '~/domain/usecases/profession/redux';

import { RemoteGetAllProfession } from '~/data/repository/profession';
import { ReduxGetAllProfession } from '~/data/store/reducer/profession/usecases/ReduxGetAllProfession';

/**
 * send request via API.
 */
export const makeRemoteGetAllProfession = (): UsecaseRemoteGetAllProfession =>
  new RemoteGetAllProfession(makeApiUrl('/professions'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllProfession = (): UsecaseReduxGetAllProfession =>
  new ReduxGetAllProfession();
