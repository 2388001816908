import { Dispatch } from 'redux';
import { DeleteUser } from '~/domain/usecases/users/redux';
import store from '~/data/store';
import { deleteRequest } from '~/data/store/reducer/users/actions/delete';

import { iActionDelete } from '~/domain/interfaces/redux/users/delete';

export class ReduxDeleteUser implements DeleteUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  delete = (params: DeleteUser.Params): iActionDelete =>
    this.send(deleteRequest(params));
}
