import { takeLatest } from 'redux-saga/effects';
import { HealthUnitsTypes } from '~/domain/interfaces/redux/healthUnits/types';
import { onGetAllRequest, onGetAllSuccess, onGetAllFailed } from './GetAll';

const tasks = [
  takeLatest(HealthUnitsTypes.GETALL, onGetAllRequest),
  takeLatest(HealthUnitsTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(HealthUnitsTypes.GETALL_FAILED, onGetAllFailed),
];

export default tasks;
