import React, { useState } from 'react';

import { useLocation } from 'react-router';
import Input from '~/presentation/components/UI/input/index';
import Select from '~/presentation/components/UI/select/index';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { Icon } from '~/presentation/components/icon';
import { GuestV4H } from '~/presentation/base/icons';
import { CarouselParticipants } from '~/presentation/components/carouselParticipant';
import {
  AvatarContainer,
  Container,
  Wrapper,
  Left,
  Content,
  Right,
  Title,
  Box,
  HeaderBox,
  ConfirmCancel,
  Message,
} from './styles/StyledIdentifyGuest';
import ScheduleStatus from '~/presentation/components/statusList/ScheduleStatus';
import { History } from '~/main/routes';
import { UpdateAppointmentCompanion as interfaceCompanion } from '~/domain/usecases/appointmentCompanion/remote';

interface ownProps {
  getCompanion: interfaceCompanion;
}
interface locationParams {
  schedule: any;
  code: string;
}
const IdentifyGuest: React.FC<ownProps> = props => {
  const { schedule, code }: any = useLocation<locationParams>().state;
  const [error, setError] = useState<boolean>(false);
  const { consultant, professional } = schedule;

  const [identification, setIdentification] = useState<{
    fullName: string;
    role: string;
  }>({
    fullName: '',
    role: 'PARENT',
  });

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Enter') {
      document.getElementById('next')?.click();
    }
  });

  const goTo = () => {
    if (identification.fullName.length > 0) {
      History.getHistory().push('/guest/preconference', {
        conference: {
          ...schedule,
          guest: { identification: { ...identification, code } },
        },
      });
    } else {
      setError(true);
    }
  };

  const backTo = () => {
    History.getHistory().push('/guest');
  };

  return (
    <Wrapper>
      <Icon src={GuestV4H} width="300px" height="140px" />
      <Container>
        <Left>
          <Box>
            <HeaderBox>
              <span>{translator('Atendimento')}</span>
            </HeaderBox>
            <Message>
              <span>
                {translator(
                  'Você irá participar como convidado do atendimento do(a) Paciente',
                )}
                <strong>{consultant.firstName}</strong>
              </span>
            </Message>
            <p>{translator('Status do atendimento')}</p>
            <ScheduleStatus status={schedule.appointment.status} />
            <AvatarContainer>
              <CarouselParticipants users={[consultant, professional]} />
            </AvatarContainer>
          </Box>
        </Left>
        <Right>
          <Content>
            <Title>{translator('Identifique-se')}</Title>
            <p>{translator('Insira seus dados para entrar no atendimento')}</p>
            <Input
              error={error}
              onFocus={() => setError(false)}
              autoFocus
              message="Digite seu nome completo"
              name="fullName"
              label={translator('Nome completo')}
              placeholder={translator('Digite seu nome')}
              onChange={e =>
                setIdentification({
                  ...identification,
                  [e.target.name]: e.target.value,
                })
              }
            />
            <Select
              name="role"
              defaultValue={identification.role}
              onChange={e =>
                setIdentification({
                  ...identification,
                  [e.target.name]: e.target.value,
                })
              }
              label={translator('Sua relação familiar com o paciente')}
            >
              <option value="PARENT">{translator('Pai/Mãe')}</option>
              <option value="SIBLING">{translator('Irmã(o)')}</option>
              <option value="SON">{translator('Filho(a)')}</option>
              <option value="SPOUSE">{translator('Esposa(o)')}</option>
              <option value="GRANDPARENT">{translator('Avô/Avó')}</option>
              <option value="FRIEND">{translator('Amigo(a)')}</option>
              <option value="RELATIVE">{translator('Outro parente')}</option>
              <option value="OTHER">{translator('Não listado')}</option>
            </Select>
            <Button rounded height="48px" onClick={() => goTo()} id="next">
              {translator('Avançar')}
            </Button>
            <ConfirmCancel onClick={() => backTo()}>
              {translator('Sair')}
            </ConfirmCancel>
          </Content>
        </Right>
      </Container>
    </Wrapper>
  );
};

export default IdentifyGuest;
