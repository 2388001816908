import React from 'react';
import ItemParticipatingProfessional from './ItemParticipatingProfessionals';

import {
  Content,
  Label,
  Container,
} from './styles/StyledListParticipatingProfessionals';
import { ListProps, Professional } from './interface';

const ListParticipatingProfessional: React.FC<ListProps> = ({
  professionals,
  setProfessionals,
  className,
  label,
  setIds,
  ids,
  setZodValue,
}) => {
  const magB = label ? '4px' : '0px';

  function handleDeleteInvite(professional: Professional) {
    const updatedProfessionals = professionals.filter(
      item => item.cardId !== professional.cardId,
    );
    setProfessionals?.(updatedProfessionals);
    setZodValue?.(updatedProfessionals);

    if (ids && professional.id && ids.includes(professional.id)) {
      setIds?.(ids.filter(item => item !== professional.id));
    }
  }

  return (
    <Container>
      <Label style={{ marginBottom: magB }}>{label}</Label>
      <Content id="listProfessionals" className={className}>
        {professionals.map(professional => (
          <ItemParticipatingProfessional
            professional={professional}
            handleDeleteInvite={() => handleDeleteInvite(professional!)}
            key={professional.id}
          />
        ))}
      </Content>
    </Container>
  );
};

export default ListParticipatingProfessional;
