import {
  iActionGetEmailSuccess,
  UsersTypes,
} from '~/domain/interfaces/redux/users/getEmail';

interface iParams {
  email: string;
}

export const getEmailSuccess = (params: iParams): iActionGetEmailSuccess => ({
  type: UsersTypes.GET_EMAIL,
  payload: params,
});
