import { call, put } from 'redux-saga/effects';
import { iActionCreateMessage } from '~/domain/interfaces/redux/chat/createMessage';
import { CreateChatMessage } from '~/domain/usecases/chat/remote/CreateChatMessage';
import { makeRemoteCreateChatMessage } from '~/main/factories/usecases/chat/CreateChatMessageFactory';
import {
  createChatMessageSuccess,
  createChatMessageFailed,
} from '~/data/store/reducer/chat/actions/createMessage';

export function* onCreateMessage(action: iActionCreateMessage) {
  const remoteCreateChat = makeRemoteCreateChatMessage();

  try {
    const response: CreateChatMessage.Model = yield call(
      remoteCreateChat.createMessage,
      action.payload,
    );

    yield put(createChatMessageSuccess(response));
  } catch (e) {
    yield put(createChatMessageFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateMessageSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateMessageFailed() {}
