import { UpdateNotificationUser } from '~/domain/usecases/Notifications/redux';
import {
  iActionUpdate,
  iActionUpdateSuccess,
  iActionUpdateFailed,
  NotificationTypes,
} from '~/domain/interfaces/redux/Notifications/update';

export const updateRequest = (
  payload: UpdateNotificationUser.Params,
): iActionUpdate => ({
  type: NotificationTypes.UPDATE,
  payload,
});

export const updateSuccess = (
  params: UpdateNotificationUser.Model,
): iActionUpdateSuccess => ({
  type: NotificationTypes.UPDATE_SUCCESS,
  payload: params,
});

export const updateFailed = (): iActionUpdateFailed => ({
  type: NotificationTypes.UPDATE_FAILED,
});
