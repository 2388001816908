import { iWaitingRoom } from '~/domain/interfaces/models/WaitingRoom';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { WaitingRoomActions, waitingRoomTypes } from './action';

const initialState: iWaitingRoom = {
  waitingRoomUserId: 0,
  data: {
    status: '',
    userId: 0,
    appointmentId: 0,
  },
  rooms: {},
};

const reducer = (
  state = initialState,
  action: WaitingRoomActions,
): iWaitingRoom => {
  switch (action.type) {
    case waitingRoomTypes.JOIN_SUCCESS:
      return { ...state, waitingRoomUserId: action.payload.waitingRoomUserId };
    case waitingRoomTypes.LEFT:
      return { ...state, waitingRoomUserId: 0 };
    case waitingRoomTypes.ADD_USER_QUEUE: {
      const { rooms } = state;

      // Agora o Profissional e o Convidado são capazes de iniciar conferência sem paciente
      // if (!(action.payload.from.userId >= 1)) return state;

      const newRoom = rooms;

      if (rooms[action.payload.data.appointment]) {
        if (
          !rooms[action.payload.data.appointment]?.find(
            item =>
              item.userId === action.payload.from.userId ||
              (!item.userId && action.payload.from.userId === null) ||
              (item.userId === null && !action.payload.from.userId),
          )
        )
          newRoom[action.payload.data.appointment].push(action.payload.from);
      } else {
        newRoom[action.payload.data.appointment] = [action.payload.from];
      }

      return {
        ...state,
        rooms: newRoom,
        data: {
          status: 'JOINED',
          userId: action.payload.from.userId,
          appointmentId: action.payload.data.appointment,
        },
      };
    }
    case waitingRoomTypes.REMOVE_USER_QUEUE: {
      if (state.rooms[action.payload.data.appointment]?.length === 1) {
        delete state.rooms[action.payload.data.appointment];
      } else {
        const index = state.rooms[action.payload.data.appointment]?.findIndex(
          item =>
            item.userId === action.payload.from.userId ||
            (!item.userId && action.payload.from.userId === null) ||
            (item.userId === null && !action.payload.from.userId),
        );

        if (index >= 0)
          state.rooms[action.payload.data.appointment].splice(index, 1);
      }

      return {
        ...state,
        data: {
          status: 'LEFT',
          userId: action.payload.from.userId,
          appointmentId: action.payload.data.appointment,
        },
      };
    }
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
