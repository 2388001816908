import { GetDiagnosisReportByID } from '~/domain/usecases/diagnosisReport/remote';

import {
  BadGateway,
  BadRequestError,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';

import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGetDiagnosisReportByID implements GetDiagnosisReportByID {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetDiagnosisReportByID.Model>;

  constructor(
    url: string,
    httClient: HttpClient<GetDiagnosisReportByID.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: GetDiagnosisReportByID.Params,
  ): Promise<GetDiagnosisReportByID.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.id}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
