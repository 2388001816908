import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.form`
  width: 530px;

  border-radius: 16px;
  border: 1px #dedede solid;
  background-color: white;
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px;
`;

export const HeaderModal = styled.div`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
`;

export const Title = styled.div`
  color: #222529;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 5px;
  border: 1px #dedede solid;

  cursor: pointer;
`;

export const SubTitle = styled.div`
  color: #62666c;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  width: 80%;
`;

export const Form = styled.form`
  display: flex;
  padding: 32px 24px;
  gap: 24px;
  flex-direction: column;
`;

export const Divider = styled.div`
  border-top: 1px solid #dedede;
  width: 100%;
`;

export const TextAreaForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h3 {
    color: #444a51;
    font-size: 14px;
    margin-bottom: 4px;
  }

  textarea {
    resize: none;
    width: 400px;
    height: 120px;
    border-radius: 6px;
    border: 1px solid #b4b7b9;
    background: #fdfdfd;
    padding: 12px;

    ::placeholder {
      color: #818385;
    }
  }
`;

export const Footer = styled.div`
  padding: 24px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
`;
