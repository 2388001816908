/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { HTMLAttributes } from 'react';
import {
  Container,
  Header,
  Content,
  Options,
  OptionItem,
} from './styles/StyledServices';
import { ServicesIcon, InvoiceIcon } from '~/presentation/base/icons';
import { translator } from '../i18n';
import HealthyHeartIcon from '~/presentation/base/icons/healthyHeartIcon';

interface ownProps extends HTMLAttributes<HTMLDivElement> {
  setState: (e: number) => any;
  options: string[];
  active: number;
}

const Services: React.FC<ownProps> = ({
  setState,
  options,
  active,
  ...props
}) => {
  function handleIcon(index: number) {
    switch (index) {
      case 0:
        return <ServicesIcon />;
      case 1:
        return <InvoiceIcon />;
      case 2:
        return <HealthyHeartIcon />;
      default:
        return '';
    }
  }
  return (
    <Container>
      <Content>
        <Header>{translator('Menu de Serviços')}</Header>

        <Options>
          {options.map((elem, index) => (
            <OptionItem
              id={index === 0 ? 'servicesTab' : 'receiptsTab'}
              selected={index === active}
              onClick={() => setState(index)}
            >
              {handleIcon(index)}
              {elem}
            </OptionItem>
          ))}
        </Options>
      </Content>
    </Container>
  );
};

export default Services;
