import React from 'react';
import { useSelector } from 'react-redux';
import { IconSuccessIcon } from '~/presentation/base/icons';
import { RegisterBody, Content, Text } from './styles/StyledSuccessMessage';
import { Container } from '../Background';

import { Button } from '~/presentation/components/UI';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { ownProps } from '../interface';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../../i18n';

const UserRegisterSuccess: React.FC<ownProps> = ({ message }) => {
  const newUserEmail = useSelector((store: iStore) => store.users.getEmail);

  const msgName = MessageOptions.userCreateSuccess;

  const { active, actionOk } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <RegisterBody>
            <Content>
              <IconSuccessIcon width="60px" height="60px" />
              <p>{translator('Cadastro realizado com sucesso!')}</p>
            </Content>
            <Text>
              {translator('Enviamos um email de confirmação para')}
              <strong>{` ${newUserEmail}`}</strong>
            </Text>
            <span>
              {translator(
                'Falta pouco: para habilitar sua conta, acesse a mensagem enviada e clique no link de confirmação.',
              )}
            </span>
            <Button
              id="button_confirm"
              rounded
              variant="primary"
              size="large"
              onClick={actionOk}
              autoFocus
              style={{ marginTop: '35px' }}
            >
              {translator('OK')}
            </Button>
            {/* <Button
              rounded
              variant="secundary"
              size="large"
              onClick={actionOk}
              style={{ marginTop: '10px' }}
            >
              Não recebi o email
            </Button> */}
          </RegisterBody>
        </Container>
      ) : null}
    </>
  );
};

export default UserRegisterSuccess;
