import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllClinicalDocsByCode as UseCaseRemoteGetAllClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/remote';
import { GetAllClinicalDocsByCode as UseCaseReduxGetAllClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteGetAllClinicalDocsByCode } from '~/data/repository/clinicalDocs';
import { ReduxGetAllClinicalDocsByCode } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteGetAllClinicalDocsByCode =
  (): UseCaseRemoteGetAllClinicalDocsByCode =>
    new RemoteGetAllClinicalDocsByCode(
      makeApiUrl('/clinicalDocs/details/{code}'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxGetAllClinicalDocsByCode =
  (): UseCaseReduxGetAllClinicalDocsByCode =>
    new ReduxGetAllClinicalDocsByCode();
