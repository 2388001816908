import { takeLatest } from 'redux-saga/effects';
import { AuditRecordTypes } from '~/domain/interfaces/redux/auditRecord';
import * as GetAuditRecord from './GetAuditRecord';

const tasks = [
  takeLatest(
    AuditRecordTypes.GET_AUDIT_RECORD,
    GetAuditRecord.onGetAuditRecord,
  ),
  takeLatest(
    AuditRecordTypes.GET_AUDIT_RECORD_SUCCESS,
    GetAuditRecord.onGetAuditRecordSuccess,
  ),
  takeLatest(
    AuditRecordTypes.GET_AUDIT_RECORD_FAIL,
    GetAuditRecord.onGetAuditRecordFailed,
  ),
];

export default tasks;
