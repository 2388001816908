import { Dispatch } from 'redux';
import store from '~/data/store';

import { deleteRequest } from '~/data/store/reducer/clinicalDocs/actions/delete';

import { iActionDelete } from '~/domain/interfaces/redux/clinicalDocs/delete';

import { DeleteClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

export class ReduxDeleteClinicalDocs implements DeleteClinicalDocs {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  delete = (params: DeleteClinicalDocs.Params): iActionDelete =>
    this.send(deleteRequest(params));
}
