import { Dispatch } from 'redux';
import store from '~/data/store';
import { createRequest } from '~/data/store/reducer/clinicalDocs/actions/create';

import { iActionCreate } from '~/domain/interfaces/redux/clinicalDocs/create';

import { CreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

export class ReduxCreateClinicalDocs implements CreateClinicalDocs {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateClinicalDocs.Params): iActionCreate =>
    this.send(createRequest(params));
}
