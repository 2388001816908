import React from 'react';

import { Content, Image, Message } from './styles/StyledServiceAvaliable';

import { Notify } from '~/presentation/base/icons';

const AVAILABLE: React.FC = () => {
  return (
    <Content>
      <Image>
        <Notify />
      </Image>
      <Message>Atendimento em andamento</Message>
    </Content>
  );
};

export default AVAILABLE;
