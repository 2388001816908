import styled from 'styled-components';

export const Container = styled.div`
  width: 530px;
  /* height: 346px; */

  background-color: white;

  border-radius: 16px;
  border: 1px #dedede solid;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border-radius: 50%;

  background-color: #ebf3d9;

  margin-bottom: 10px;
`;

export const Box = styled.div`
  padding: 16px 0 16px 24px;
`;

export const Title = styled.div`
  color: #222529;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
`;

export const SubTitle = styled.div`
  color: #62666c;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: #dedede;
`;

export const Label = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;
`;

export const Required = styled.div`
  color: #dd0404;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const Content = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* align-items: center;
  justify-content: center; */
  /* padding: 24px; */
  align-items: end;

  gap: 16px;

  padding: 24px 0 24px 24px;
`;

export const CopyLink = styled.button`
  /* padding: 16px 24px; */
  gap: 8px;
  /* width: 139px; */
  /* height: 40px; */

  width: 139px;
  height: 40px;

  /* padding: 9px 0; */

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  border: 1px #b4b7b9 solid;

  color: #3d4349;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  word-wrap: break-word;

  cursor: pointer;
`;
