import { call, put } from 'redux-saga/effects';
import { iActionProfessionList } from '~/domain/interfaces/redux/profession/professionList';

import { ProfessionList } from '~/domain/usecases/profession/remote';
import { makeRemoteProfessionList } from '~/main/factories/usecases/profession/ProfessionListFactory';

import {
  professionListSuccess,
  professionListFailed,
} from '~/data/store/reducer/profession/actions/professionList';

export function* onProfessionalList(action: iActionProfessionList) {
  const remoteProfessionalList = makeRemoteProfessionList();

  try {
    const response: ProfessionList.Model = yield call(
      remoteProfessionalList.post,
      action.payload,
    );

    yield put(professionListSuccess(response));
  } catch (e) {
    yield put(professionListFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onProfessionalListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onProfessionalListFailed() {}
