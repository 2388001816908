import styled from 'styled-components';

export const Container = styled.body`
  width: 100%;
  background: #001e3a;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  justify-content: center;
  width: 100%;
  max-width: 360px;
  border-radius: 4px;
  padding: 10px;
`;

export const Title = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 5px;
  height: 50px;
  border-radius: 4px 4px 0px 0px;
  background: #f4f7fe;
  h1 {
    text-align: center;
    color: #004ba5;
  }
`;

export const InformationGuest = styled.div`
  width: 90%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const MessageGuest = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
`;

export const GetOut = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
