import { GetAllChatMessage } from '~/domain/usecases/chat/redux/GetAllChatMessage';
import {
  ChatTypes,
  iActionGetAllChatMessage,
  iActionGetAllChatMessageFailed,
  iActionGetAllChatMessageSuccess,
} from '~/domain/interfaces/redux/chat/getAllMessage';

export const getAllChatMessageRequest = (
  payload: GetAllChatMessage.Params,
): iActionGetAllChatMessage => ({
  type: ChatTypes.GETALL_MESSAGE,
  payload,
});

export const getAllChatMessageSuccess = (
  params: GetAllChatMessage.Model,
): iActionGetAllChatMessageSuccess => ({
  type: ChatTypes.GETALL_MESSAGE_SUCCESS,
  payload: params,
});

export const getAllChatMessageFailed = (): iActionGetAllChatMessageFailed => ({
  type: ChatTypes.GETALL_MESSAGE_FAILED,
});
