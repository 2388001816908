import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListAppointment as UsecaseRemoteListAppointment } from '~/domain/usecases/appointment/remote';
import { ListAppointment as UsecaseReduxListAppointment } from '~/domain/usecases/appointment/redux';

import { RemoteListAppointment } from '~/data/repository/appointment';
import { ReduxListAppointment } from '~/data/store/reducer/appointment/usecases';

/**
 * send request via API.
 */
export const makeRemoteListAppointment = (): UsecaseRemoteListAppointment =>
  new RemoteListAppointment(makeApiUrl('/appointments/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxListAppointment = (): UsecaseReduxListAppointment =>
  new ReduxListAppointment();
