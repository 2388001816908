import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadFilesDiagnosisReport as UsecaseRemoteDownloadFilesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteDownloadFilesDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */

export const makeRemoteDownloadFilesDiagnosisReport =
  (): UsecaseRemoteDownloadFilesDiagnosisReport =>
    new RemoteDownloadFilesDiagnosisReport(
      makeApiUrl('/reports'),
      makeHttpClient(),
    );
