/* eslint-disable react/require-default-props */
import { Spinner } from '@chakra-ui/react';
import React from 'react';
import { IconClose } from '~/presentation/base/icons';
import { Container, IconContainer, Content } from './styles';

interface ToastNotifyProps {
  children: React.ReactNode;
  icon?: React.ComponentType;
  spin?: boolean;
  close?: () => any;
  notIcon?: boolean;
  width?: string;
}

export const ToastNotify: React.FC<ToastNotifyProps> = ({
  children,
  icon: Icon,
  spin = true,
  close,
  width,
  notIcon,
}) => {
  return (
    <Container style={{ width: width || '100%' }}>
      {Icon && (
        <IconContainer>
          <Icon />
        </IconContainer>
      )}
      <Content icon={Icon !== undefined}>
        {children}
        {spin && (
          <Spinner
            fontSize={20}
            size="lg"
            color="#df2c26"
            style={{ marginLeft: 12 }}
          />
        )}
        {close && <IconClose id="icon_close_toast" onClick={close} />}
      </Content>
    </Container>
  );
};
