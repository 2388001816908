import React from 'react';
import { IconPlus } from '~/presentation/base/icons';

import { DocumentsList } from '~/presentation/components/documents-list';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { iSupportDocs } from '~/presentation/pages/participant/appointment/interface';
import { UploadFiles } from '../../uploadFiles';
import { Attendiment } from '~/presentation/roles/manager/pages/ListProfessional/StyledSchedule';

interface ownProps {
  docs: iSupportDocs[];
}

const SupportingDocuments: React.FC<ownProps> = ({ docs }) => {
  return (
    // <div
    //   style={{
    //     width: '100%',
    //     height: '100%',
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'flex-end',
    //     gap: '20px',
    //   }}
    // >
    //   <Button rounded size="large">
    //     {`+ ${translator('Novo documento de apoio')}`}
    //   </Button>
    //   <DocumentsList type="supporting-documents" docsSup={docs} />
    <UploadFiles docs={docs} />
    // </div>
  );
};

export default SupportingDocuments;
