import { GetAllSelectedOrg } from '~/domain/usecases/selectedOrg/redux';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  SelectedOrgTypes,
} from '~/domain/interfaces/redux/selectedOrg/getAll';

export const getAllRequest = (
  payload: GetAllSelectedOrg.Params,
): iActionGetAll => ({
  type: SelectedOrgTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllSelectedOrg.Model,
): iActionGetAllSuccess => ({
  type: SelectedOrgTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: SelectedOrgTypes.GETALL_FAILED,
});
