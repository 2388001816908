/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { translator } from '~/presentation/components/i18n';

import ListSkeleton from '../../skeleton/ListSkeleton';
import { ListAppointment } from '~/domain/usecases/appointment/remote';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
});

interface iTranslate {
  [key: string]: string;
}

interface ownProps {
  dataList: ListAppointment.Model['results'];
  loading?: boolean;
}

const SpecialtyAppointments: React.FC<ownProps> = props => {
  const [dataList, setData] = useState<ListAppointment.Model['results']>([
    ...props.dataList,
  ]);

  const classes = useStyles();
  const columns = [
    {
      id: 'profession',
      label: 'Área',
      minWidth: 200,
      align: 'left',
    },
    {
      id: 'specialty',
      label: 'Especialidade',
      minWidth: 300,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'clinicalDocCount',
      label: 'Doc. clínicos',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'appointmentsInPerson',
      label: 'Presenciais',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'appointmentsRemote',
      label: 'Teleatendimentos',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'duration',
      label: 'Duração (h)',
      minWidth: 100,
      align: 'left',
    },
  ];

  const RenderComponents: React.FC<{
    id: any;
    clinicaldoc: any;
  }> = ({ id, clinicaldoc }) => {
    const formattedClinicalDoc: any = {
      specialty: clinicaldoc?.specialty?.name ?? '(não informado)',
      profession: clinicaldoc?.profession?.name ?? '(não informado)',
      clinicalDocCount: clinicaldoc?.info?.clinicalDocCount,
      appointmentsInPerson: clinicaldoc?.info?.appointmentsInPerson,
      appointmentsRemote: clinicaldoc?.info?.appointmentsRemote,
      duration: clinicaldoc?.info?.duration / 60,
    };

    switch (id) {
      default:
        return <div>{formattedClinicalDoc[`${id}`]}</div>;
    }
  };

  useEffect(() => {
    setData(props.dataList);
  }, [props.dataList]);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map((item, index) => {
              return (
                <TableCell align="left" key={index}>
                  <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                    {item.label}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            dataList.map((item: any) => (
              <TableRow key={item.id}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      key={columnProps.id}
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        clinicaldoc={item}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SpecialtyAppointments;
