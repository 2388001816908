import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /*   height: auto; */
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  margin: 20px;
  gap: 20px;

  .links {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 12px;
  }
`;

export const Content = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
`;

export const Span = styled.span`
  text-align: center;
  font-size: 14px;
  color: ${props => props.theme.typographyDarkGray};
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: 400;
  padding: 50px 50px;
  color: #004ba5;

  span {
    text-align: center;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #004ba5;
  }
`;
