import { SetSpecialistStatusOnCall } from '~/domain/usecases/onCall/redux/SetSpecialistStatusOnCall';
import {
  iActionSetSpecialistStatus,
  OnCallTypes,
} from '~/domain/interfaces/redux/onCall/setSpecialistStatus';

export const setSpecialistStatusRequest = (
  payload: SetSpecialistStatusOnCall.Params,
): iActionSetSpecialistStatus => ({
  type: OnCallTypes.SETSPECIALISTSTATUS,
  payload,
});
