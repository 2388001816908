/**
 * Check if date is valid.
 * @param date date in format DD/MM/YYYY.
 * @return boolean
 */
export const validateDate = (date: string): Boolean => {
  const splitDate = date.split('/') || [];

  if (!splitDate.length) return false;

  const [year, month, day] = [
    Number(splitDate[2]),
    Number(splitDate[1]) - 1,
    Number(splitDate[0]),
  ];

  const formattedDate = new Date(year, month, day);
  const now = new Date();

  return (
    formattedDate.getFullYear() === year &&
    formattedDate.getMonth() === month &&
    formattedDate.getDate() === day &&
    now.getTime() >= formattedDate.getTime()
  );
};
