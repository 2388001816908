import styled, { css } from 'styled-components';

interface ownProps {
  isOk: boolean;
}

export const Content = styled.div<ownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  background: ${props => (props.isOk ? '#f4f7fe' : '#fef4f4')};
  padding-bottom: 10px;
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0 0 20px;
`;

export const HeaderDivision = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Moree = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

const divVariant = css<ownProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props =>
    props.isOk ? props.theme.primary : props.theme.statusCancel};
`;

export const ButtonBack = styled.div`
  ${divVariant}
  font-size: 12px;
  cursor: pointer;
`;

export const Title = styled.div`
  ${divVariant}
  font-size: 16px;
  font-weight: 700;
`;

export const Body = styled.div`
  width: 100%;
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;
`;

export const BodyColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const BodyColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const Patient = styled.div`
  font-size: 14px;
  color: #7a7a7a;
  display: flex;
  flex-direction: row;

  .patient-name {
    font-size: 14px;
    font-weight: bold;
    margin-left: 5px;
  }
`;

export const CreatedAt = styled.div`
  font-size: 14px;
  color: #7a7a7a;
`;

export const Signed = styled.div`
  ${divVariant}
  font-size: 14px;
`;

export const DateStyle = styled.div<ownProps>`
  color: ${props =>
    props.isOk ? props.theme.typographyDarkGray : props.theme.statusCancelBack};
  font-size: 14px;
`;
