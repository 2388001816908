import { GetChat } from '~/domain/usecases/chat/redux/GetChat';
import {
  ChatTypes,
  iActionGet,
  iActionGetFailed,
  iActionGetSuccess,
} from '~/domain/interfaces/redux/chat/get';

export const getRequest = (payload: GetChat.Params): iActionGet => ({
  type: ChatTypes.GET,
  payload,
});

export const getSuccess = (params: GetChat.Model): iActionGetSuccess => ({
  type: ChatTypes.GET_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetFailed => ({
  type: ChatTypes.GET_FAILED,
});
