import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import { SpecialPrescriptionView } from '~/presentation/components/specialPrescription';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';

interface iStateParams {
  docId: string;
  from: string;
}
const DocumentDetailPage: React.FC = () => {
  const { docId } = useLocation<iStateParams>().state;
  const { orgId, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const info = useSelector((state: iStore) => state.auth.info);

  const professionalId = info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  useEffect(() => {
    makeReduxGetAllClinicalDocs().getAll({
      filter: {
        professional: professionalId,
        org: orgId,
        enabled: true,
      },
    });
  }, [professionalId, orgId]);

  return <SpecialPrescriptionView id={docId} />;
};
export default DocumentDetailPage;
