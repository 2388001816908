import { UpdateOrgUnit } from '~/domain/usecases/orgUnit/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
  Forbidden,
  UnProcessableEntity,
  Conflict,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteUpdateOrgUnit implements UpdateOrgUnit {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateOrgUnit.Model>;

  constructor(url: string, httClient: HttpClient<UpdateOrgUnit.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  patch = async (
    params: UpdateOrgUnit.Params,
  ): Promise<UpdateOrgUnit.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.ouId}`,
      method: 'patch',
      body: params.logo ? params.logo : { name: params.name },
      headers: {
        'Content-Type': params.logo
          ? 'multipart/form-data'
          : 'application/json',
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.unprocessableEntity:
        throw new UnProcessableEntity();
      default:
        throw new UnexpectedError();
    }
  };
}
