import { UpdateNotificationUser } from '~/domain/usecases/Notifications/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  BadRequestError,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteUpdateNotificationUser implements UpdateNotificationUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateNotificationUser.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<UpdateNotificationUser.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  update = async (
    params: UpdateNotificationUser.Params,
  ): Promise<UpdateNotificationUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.notificationUserId}`,
      method: 'patch',
      body: { viewed: params.viewed, read: params.read },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
