import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllAppointment as UsecaseRemoteGetAllAppointment } from '~/domain/usecases/appointment/remote';
import { GetAllAppointment as UsecaseReduxGetAllAppointment } from '~/domain/usecases/appointment/redux';

import { RemoteGetAllAppointment } from '~/data/repository/appointment';
import { ReduxGetAllAppointment } from '~/data/store/reducer/appointment/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllAppointment = (): UsecaseRemoteGetAllAppointment =>
  new RemoteGetAllAppointment(
    makeApiUrl('/appointments/list'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxGetAllAppointment = (): UsecaseReduxGetAllAppointment =>
  new ReduxGetAllAppointment();
