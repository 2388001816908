import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
  max-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  height: 20%;
  display: flex;
  top: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  margin-top: 10px;
  font-size: 28px;
  font-weight: bold;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 18px;
  span {
    color: black;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;

    width: 21em;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
  width: 100%;
  margin-bottom: 50px;
  gap: 15px;
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  margin: 20px;
  gap: 20px;
`;
