import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

export const Body = styled.div`
  background-color: white;
  position: relative;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  color: black;
  padding: 21px 0px 18px 0px;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  margin-right: 22px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 17px;

  button {
    margin-left: 17px;
  }
`;

export const Content = styled.div`
  padding: 24px 24px 15px 24px;
`;
