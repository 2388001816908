import {
  DiagnosisReportListRecords,
  Status,
} from '~/domain/usecases/diagnosisReport/remote';

export function findLastOccurrence(
  history: DiagnosisReportListRecords['history'],
  status: Status,
) {
  return (
    history
      .filter(entry => entry.status.toUpperCase() === status.toUpperCase())
      .slice(-1)[0] || null
  );
}
