import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button, Select } from '../UI';

import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxListShifts } from '~/main/factories/usecases/shifts';
import {
  IconArrowLeftAgenda,
  IconArrowRightAgenda,
  IconScale,
} from '~/presentation/base/icons';
import { useShifts } from '~/presentation/hooks/shifts';
import { V4hSpin } from '../spin';
import {
  Box,
  ButtonView,
  Container,
  Content,
  CurrentWeek,
  DaysOfWeek,
  Divider,
  SelectLabel,
  Text,
  TextDays,
  Title,
  Top,
  Type,
} from './styles';
import { translator } from '../i18n';

const LayOverLeftCard: React.FC = () => {
  const {
    goToNextWeek,
    goToPreviousWeek,
    weekLabel,
    onCalls,
    getWeekShiftsByDate,
  } = useShifts();

  const { onCallId } = useSelector((state: iStore) => state.shifts);

  const setOnCallId = (id: number) => {
    const shifts = makeReduxListShifts();
    shifts.setOnCallId(id);
  };

  const onCallSelected = useMemo(
    () => onCalls.find(onCall => onCall.id === onCallId),
    [onCallId, onCalls],
  );

  const openDialog = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.createShifts,
      actionOk: getWeekShiftsByDate,
      data: onCallId,
    });
  };

  if (!onCalls.length) {
    return <V4hSpin background="#fff" fullScreen />;
  }

  return (
    <Container>
      <Top>
        <SelectLabel>{translator('Plantão')}</SelectLabel>
        <Select
          height="40px"
          onChange={e => setOnCallId(Number(e.target.value))}
        >
          {onCalls.map(onCall => (
            <option
              key={onCall.id}
              onChange={() => setOnCallId(onCall.id)}
              value={onCall.id}
            >
              {onCall.descr}
            </option>
          ))}
        </Select>
      </Top>
      <Divider />
      <Content>
        <Title>
          {onCallSelected?.logo ? (
            <img
              id="logo-escala"
              src={onCallSelected?.logo}
              alt={`logo-escala-${onCallSelected?.descr}`}
            />
          ) : (
            <IconScale width={32} />
          )}
          <Type>{`Escala de ${onCallSelected?.descr}`}</Type>
        </Title>
        <CurrentWeek>
          <Box onClick={goToPreviousWeek}>
            <IconArrowLeftAgenda />
          </Box>
          <DaysOfWeek>
            <Text>Semana</Text>
            <TextDays>{weekLabel}</TextDays>
          </DaysOfWeek>

          <Box onClick={goToNextWeek}>
            <IconArrowRightAgenda />
          </Box>
        </CurrentWeek>
        <ButtonView>
          <Button
            onClick={openDialog}
            resourcers={['ONCALL']}
            actions={['CREATE_SHIFT_ORG', 'CREATE_SHIFT_ANY']}
          >
            Adicionar escala
          </Button>
        </ButtonView>
      </Content>
    </Container>
  );
};

export default LayOverLeftCard;
