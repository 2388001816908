import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
`;

export const Form = styled.form`
  display: grid;
  grid-template-areas: 'fullname fullname gender' 'birthdate mothername mothername' 'footer footer footer';
  grid-gap: 10px;
`;

export const FormContainer = styled.div`
  padding: 1.5rem 2rem;
  background: ${({ theme }) => theme.hover};
  display: flex;
  border-radius: 5px;

  gap: 10px;

  margin-bottom: 2.3rem;
`;
