import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/users/getAll';
import { GetAllUsers } from '~/domain/usecases/users/remote/GetAllUsers';
import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/users/actions/getAll';

import { makeRemoteGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';

export function* onGetAllRequest(action: iActionGetAll) {
  const remoteGetAllUsers = makeRemoteGetAllUsers();

  try {
    const response: GetAllUsers.Model = yield call(
      remoteGetAllUsers.getAll,
      action.payload,
    );
    yield put(getAllSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getAllFailed());
  }
}

export function* onGetAllSuccess() {
  yield 10;
}

export function* onGetAllFailed() {
  yield 10;
}
