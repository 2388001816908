import React from 'react';
import { GridContainer } from './styles/style';

interface ownProps {
  columns?: number;
}

const OrgUnitGrid: React.FC<ownProps> = ({ children, columns = 3 }) => {
  return <GridContainer columns={columns}>{children}</GridContainer>;
};

export default OrgUnitGrid;
