/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, InputHTMLAttributes } from 'react';
import { IconErrorInput } from '~/presentation/base/icons';

import {
  Container,
  Label,
  InputContainer,
  Message,
  LinkContainer,
  HighlightLink,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  label?: string;
  labelLink?: string;
  linkHandler?: () => void;
  message?: string;
  width?: string;
  height?: string;
  error?: boolean;
  checked?: boolean;
}

const Check: React.FC<InputProps> = ({
  name,
  label,
  labelLink,
  linkHandler = () => {},
  message,
  error = false,
  width = '100%',
  height = '18px',
  checked = false,
  ...rest
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const mTop = label ? '0px' : '0px';
  const mBottom = message ? '2px' : '0px';
  const { disabled } = rest;

  return (
    <Container width={width}>
      <InputContainer
        style={{ marginTop: mTop, marginBottom: mBottom }}
        height={height}
        focus={focus}
        disabled={disabled}
        error={error}
      >
        <input
          type="checkbox"
          checked={checked}
          {...rest}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
        {/* {error && <IconErrorInput className="icon" />} */}
      </InputContainer>
      {label && labelLink ? (
        <LinkContainer>
          <Label>
            {label}
            &nbsp;
          </Label>
          <HighlightLink onClick={linkHandler}>{labelLink}</HighlightLink>
        </LinkContainer>
      ) : (
        <Label>{label}</Label>
      )}
      <Message error={error}>{message}</Message>
    </Container>
  );
};

export default Check;
