import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetDashboardProducts as UsecaseRemoteGetDashboardProducts } from '~/domain/usecases/dashboard/remote';
import { GetDashboardProducts as UseCaseReduxDashboardProducts } from '~/domain/usecases/dashboard/redux';

import { RemoteGetDashboardProducts } from '~/data/repository/dashboard';
import { ReduxGetDashboardProducts } from '~/data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetDashboardProducts =
  (): UsecaseRemoteGetDashboardProducts =>
    new RemoteGetDashboardProducts(makeApiUrl('/dashboard'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetDashboardProducts =
  (): UseCaseReduxDashboardProducts => new ReduxGetDashboardProducts();
