import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 50%;
  grid-template-rows: 1fr;
  gap: 30px;
  grid-template-areas: 'c c c' 'c c c' 'footer footer footer';
`;
