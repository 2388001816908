import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/Professional/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/professionalList/getAll';

import { GetAllProfessional } from '~/domain/usecases/professionalList/redux';

export class ReduxGetAllProfessional implements GetAllProfessional {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllProfessional.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
