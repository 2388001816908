import React from 'react';

import { Container, Bar, MiniBar } from './style/StepMagic';

import InternalStep from './InternalStep';

interface OwnProps {
  children: React.ReactElement[];
  current: number;
}

type Props = OwnProps;

const StepMagic: React.FC<Props> = props => {
  const data = [];
  let active = true;

  props.children.map((elem, index) => {
    active = index + 1 <= props.current;
    const current = index + 1 === props.current;

    if (index === 0) {
      data.push(<MiniBar active={active} />);
    } else {
      data.push(<Bar active={active} />);
    }

    data.push(
      <InternalStep
        title={elem.props.title}
        active={active}
        current={current}
      />,
    );

    return elem;
  });

  if (data.length) data.push(<MiniBar active={active} />);

  return <Container>{data}</Container>;
};

export default StepMagic;
