import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetDashboardUnits as UsecaseRemoteGetDashboardUnits } from '~/domain/usecases/dashboard/remote';
import { GetDashboardUnits as UseCaseReduxDashboardUnits } from '~/domain/usecases/dashboard/redux';

import { RemoteGetDashboardUnits } from '~/data/repository/dashboard';
import { ReduxGetDashboardUnits } from '~/data/store/reducer/dashboard/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetDashboardUnits = (): UsecaseRemoteGetDashboardUnits =>
  new RemoteGetDashboardUnits(makeApiUrl('/dashboard'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetDashboardUnits = (): UseCaseReduxDashboardUnits =>
  new ReduxGetDashboardUnits();
