import React from 'react';
import { IconClock } from '~/presentation/base/icons';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '../UI';
import {
  ContainerModal,
  MessageExpired,
  Information,
  Action,
  Container,
  Message,
  CarrouselDiv,
  FremiumDiv,
} from './styles/StyledModalExpiredFremium';

interface propsExpiredFremium {
  message: iMessage;
}

const ModalExpiredFremium: React.FC<propsExpiredFremium> = ({
  message,
}): JSX.Element => {
  const { active, actionOk, type } = message;
  const msgName = MessageOptions.expiredFremium;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            {/* <CarrouselDiv
              src="https://quatrorodas.abril.com.br/wp-content/uploads/2019/07/p90332326_highres_the-all-new-bmw-320d-e1564083729655.jpg?quality=70&strip=info"
              alt=""
            /> */}
            <FremiumDiv>
              <IconClock />
            </FremiumDiv>
            <Information>
              <MessageExpired>
                O seu período de testes chegou ao fim.
              </MessageExpired>
              <Message>
                In hac habitasse platea dictumst. Donec nec sapien sed massa
                posuere fringilla nec non tortor. Suspendisse potenti. Nullam
                dapibus diam eros, ut auctor metus ultrices et. Donec euismod
                efficitur vulputate. Proin in fermentum tortor, sodales iaculis
                lectus. Proin consectetur justo nec orci iaculis, sit amet
                scelerisque dui consequat. Aenean tortor nibh, congue id
                malesuada dignissim, tristique at tortor.
              </Message>
              <Action>
                <Button variant="primary" size="medium" onClick={actionOk}>
                  Conhecer planos
                </Button>
              </Action>
            </Information>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalExpiredFremium;
