import { call, put } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/Notifications/getAll';

import { GetAllNotificationUser } from '~/domain/usecases/Notifications/remote';
import { makeRemoteGetAllNotificationUser } from '~/main/factories/usecases/Notifications/GetAllNotificationUserFactory';

import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/Notifications/actions/getAll';

export function* onGetAllRequest(action: iActionGetAll) {
  const remoteGetAllNotificationUser = makeRemoteGetAllNotificationUser();

  try {
    const response: GetAllNotificationUser.Model = yield call(
      remoteGetAllNotificationUser.getAll,
      action.payload,
    );

    yield put(getAllSuccess(response));
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
