/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ParticipantPages, Error } from '~/presentation/pages';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';

import ClinicalDocuments from '~/presentation/roles/professional/pages/clinicalDocument';
import ClinicalDocumentsADM from '~/presentation/roles/adm/pages/clinicalDocuments';
import { Unauthorized } from '~/presentation/pages/errors';
import { V4hSpin } from '~/presentation/components/spin';
import { History } from '~/main/routes';

interface iParamsTypes {
  language: string;
}

export const ClinicalDocsFactory: React.FC = () => {
  const { language } = useParams<iParamsTypes>();

  if (language && language.length === 5 && language.split('-').length === 2) {
    localStorage.setItem('i18nextLng', language);
    History.getHistory().push('/clinicaldocs');
    window.location.reload();
  } else if (language) {
    History.getHistory().replace('/clinicaldocs');
  }

  const loading = useSelector((state: iStore) => state.clinicalDocs.loading);
  const { orgId, role, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const info = useSelector((state: iStore) => state.auth.info);

  const professionalId = info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const consultantId = info.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  useEffect(() => {
    if (role === 'PRO')
      makeReduxGetAllClinicalDocs().getAll({
        filter: {
          professional: professionalId,
          org: orgId,
          enabled: true,
        },
      });

    if (role === 'CON')
      makeReduxGetAllClinicalDocs().getAll({
        filter: {
          patient: consultantId,
          org: orgId,
          enabled: true,
        },
      });

    if (role === 'ADM')
      makeReduxGetAllClinicalDocs().getAll({
        filter: {
          org: orgId,
          enabled: true,
        },
      });
  }, [consultantId, orgId, professionalId, role]);

  // if (loading) return <V4hSpin fullScreen background="white" />;
  switch (role) {
    case 'CON':
      return <ParticipantPages.ClinicalDocuments />;
    case 'PRO':
      return <ClinicalDocuments />;
    case 'ADM':
      return <ClinicalDocumentsADM />;
    default:
      return <Unauthorized />;
  }
};
