import React, { HTMLAttributes } from 'react';

import { CheckboxContainer, CheckboxRound } from './styles';

interface CheckboxProps extends HTMLAttributes<HTMLDivElement> {
  checked: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, ...rest }) => {
  return (
    <CheckboxContainer {...rest}>
      <CheckboxRound checked={checked} />
    </CheckboxContainer>
  );
};

export default Checkbox;
