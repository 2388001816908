/* eslint-disable react/no-array-index-key */
/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { IconCloseButtonModal } from '~/presentation/base/icons';
import { Drawer } from '../drawer';
// import { translator } from '../i18n';
import { translator } from '../../i18n';
import { mapLog, mapLogUser } from './functions';
import {
  CloseButton,
  CloseButtonContainer,
  ContentSection,
  ContentSectionItem,
  ContentSectionItemLabel,
  ContentSectionItemValue,
  Divider,
  DrawerTitle,
  Padding,
  Section,
  TableOrganizations,
  TableOrganizationsBody,
  TableOrganizationsCell,
  TableOrganizationsRow,
  TitleSection,
} from './styles';

type AuditRecordDrawerProps = {
  message: iMessage;
};

const AuditRecordDrawer: React.FC<AuditRecordDrawerProps> = ({ message }) => {
  const handleClose = () => {
    makeReduxActiveMessage().active({
      active: MessageOptions.none,
    });
  };

  const { active, userId } = message;
  const isActive = active === MessageOptions.viewAudit;

  const { data: audit, loading } = useSelector(
    (store: iStore) => store.auditRecord,
  );
  const logSelected = audit?.records?.find(log => log.id === userId);

  const logMapped = mapLog(logSelected as any);
  const logUserMapped = mapLogUser(logSelected as any);

  return (
    <Drawer
      isOpen={isActive}
      style={{ padding: 0 }}
      anchor="right"
      handleClose={handleClose}
    >
      <Padding>
        <CloseButtonContainer>
          <CloseButton>
            <IconCloseButtonModal onClick={handleClose} />
          </CloseButton>
        </CloseButtonContainer>
        <DrawerTitle>{translator('Detalhes da operação')}</DrawerTitle>
      </Padding>
      <Divider />
      <Padding paddingTopBottom="0">
        <Section>
          <TitleSection>{translator('Evento')}</TitleSection>
          <ContentSection>
            {logMapped.map((item, index) => (
              <ContentSectionItem key={`log-${index}`} action={item.action}>
                <ContentSectionItemLabel>
                  {`${translator(item.label)}:`}
                </ContentSectionItemLabel>
                <ContentSectionItemValue>{item.value}</ContentSectionItemValue>
              </ContentSectionItem>
            ))}
          </ContentSection>
        </Section>
      </Padding>
      <Divider />
      <Padding paddingTopBottom="0">
        <Section>
          <TitleSection>{translator('Usuário')}</TitleSection>
          <ContentSection>
            {logUserMapped.map(item => {
              return item.isTable ? (
                <ContentSectionItem flexDirection="column">
                  <ContentSectionItemLabel>
                    {`${translator(item.label)}:`}
                  </ContentSectionItemLabel>
                  <TableOrganizations>
                    <TableOrganizationsBody style={{ maxHeight: '10rem' }}>
                      {item.value.map(org => (
                        <TableOrganizationsRow>
                          <TableOrganizationsCell>
                            {org.name}
                          </TableOrganizationsCell>
                          <TableOrganizationsCell
                            style={{
                              color: '#818385',
                              fontSize: '0.875rem',
                              fontWeight: 400,
                            }}
                          >
                            {org.role}
                          </TableOrganizationsCell>
                        </TableOrganizationsRow>
                      ))}
                    </TableOrganizationsBody>
                  </TableOrganizations>
                </ContentSectionItem>
              ) : (
                <ContentSectionItem>
                  <ContentSectionItemLabel>
                    {`${translator(item.label)}:`}
                  </ContentSectionItemLabel>
                  <ContentSectionItemValue>
                    {item.value}
                  </ContentSectionItemValue>
                </ContentSectionItem>
              );
            })}
          </ContentSection>
        </Section>
      </Padding>
    </Drawer>
  );
};

export default AuditRecordDrawer;
