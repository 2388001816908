import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Sectors } from '~/presentation/pages';

import { makeReduxGetDashboardUnits } from '~/main/factories/usecases/dashboard/GetDashboardUnitsFactory';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllOrgUnitList } from '../../usecases/orgUnit/OrgUnitListFactory';

const SectorsFactory: React.FC = () => {
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  useEffect(() => {
    makeReduxGetAllOrgUnitList().list({
      pageSize: 9999,
      filter: { org: orgId, enabled: true },
    });

    makeReduxGetDashboardUnits().get({
      filter: {
        org: orgId,
      },
    });
  }, [orgId]);

  return (
    <div style={{ width: '100%' }}>
      <Sectors />
    </div>
  );
};

export default SectorsFactory;
