import { takeLatest } from 'redux-saga/effects';
import {
  onListModalities,
  onListModalitiesSuccess,
  onListModalitiesFailed,
} from './ListModalities';
import { onList, onListSuccess, onListFailed } from './List';
import {
  onListExamTypes,
  onListExamTypesSuccess,
  onListExamTypesFailed,
} from './ListExamTypes';
import { DiagnosisReportTypes } from '~/domain/interfaces/redux/diagnosisReport/types';

const tasks = [
  takeLatest(DiagnosisReportTypes.LISTMODALITIES, onListModalities),
  takeLatest(
    DiagnosisReportTypes.LISTMODALITIES_SUCCESS,
    onListModalitiesSuccess,
  ),
  takeLatest(
    DiagnosisReportTypes.LISTMODALITIES_FAILED,
    onListModalitiesFailed,
  ),
  takeLatest(DiagnosisReportTypes.LIST, onList),
  takeLatest(DiagnosisReportTypes.LIST_SUCCESS, onListSuccess),
  takeLatest(DiagnosisReportTypes.LIST_FAILED, onListFailed),
  takeLatest(DiagnosisReportTypes.LIST_EXAM_TYPES, onListExamTypes),
  takeLatest(
    DiagnosisReportTypes.LIST_EXAM_TYPES_SUCCESS,
    onListExamTypesSuccess,
  ),
  takeLatest(
    DiagnosisReportTypes.LIST_EXAM_TYPES_FAILED,
    onListExamTypesFailed,
  ),
];

export default tasks;
