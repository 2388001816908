import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Container = styled.div`
  max-width: 1026px;
  width: 100%;
  max-height: 67vh;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  grid-gap: 120px;
`;

export const Content = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const FirstContent = styled.div`
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  p {
    font-size: 15px;
    font-weight: bold;
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  font-weight: bold;
`;

export const Left = styled.div``;

export const Right = styled.div`
  /* grid-column-start: 4;
  grid-column-end: 6; */
`;

export const Box = styled.div`
  background: white;
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: 400px;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #efefef;
  border-radius: 4px;
  p {
    font-size: small;
    margin-bottom: 5px;
  }
`;

export const HeaderBox = styled.div`
  width: 100%;
  height: 3.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7a7a7a;
  background-color: #efefef;
  margin-bottom: 10px;
  span {
    font-size: 1.4em;
    font-weight: bold;
    color: black;
    text-align: center;
    text-overflow: ellipsis;
    padding-bottom: 3px;
  }
`;

export const Message = styled.div`
  height: 15%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: center;
  justify-content: center;

  span {
    color: black;
    font-size: medium;
    text-align: center;
    text-overflow: ellipsis;
    width: 21em;
  }

  strong {
    margin-left: 3px;
  }
`;

export const AvatarContainer = styled.div`
  width: 60%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const ConfirmCancel = styled.button`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 14px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;
