import React, { Ref, forwardRef, useEffect, useRef } from 'react';
import Quill, { QuillOptions } from 'quill';
import { Container } from './styles';
import 'quill/dist/quill.snow.css';

interface ownProps {
  value: string;
  onChange: (content: string) => void;
}

const Editor = ({ value, onChange }: ownProps) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            [{ align: [] }],
            ['clean'],
          ],
        },
        formats: [
          'header',
          'font',
          'list',
          'bullet',
          'bold',
          'italic',
          'underline',
          'link',
          'align',
        ],
      });

      quillRef.current.on('text-change', () => {
        const html = editorRef.current!.children[0].innerHTML;
        onChange(html);
      });
    }

    return () => {
      if (quillRef.current) {
        quillRef.current.off('text-change');
      }
    };
  }, [onChange]);

  useEffect(() => {
    if (quillRef.current) {
      const currentHtml = editorRef.current!.children[0].innerHTML;
      if (value !== currentHtml) {
        quillRef.current.root.innerHTML = value;
      }
    }
  }, [value]);

  return <Container ref={editorRef}></Container>;
};

export default Editor;
