/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { IconPadlock } from '~/presentation/base/icons';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxUpdateUser } from '~/main/factories/usecases/users/UpdateUserFactory';
import { PasswordInstructions } from '~/presentation/pages/CreateUsersConfirmation/styles/CreateUsersConfirmationStyled';
import { Container, Header, Body } from './styles/StyledChangePassword';

import { Button } from '../UI/buttons';
import { translator } from '../i18n';
import { Icon } from '../icon';
import { Input } from '../UI';
import { V4hSpin } from '../spin';
import { AlertMessage } from '../messages/AlertMessage';
import { PasswordValidationBar } from '../passwordValidationBar';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface iParamsTypes {
  userId: string;
}

const ChangePassword: React.FC = () => {
  const [isSecurity, setSecurity] = useState<any>({
    current: true,
    newPassword: true,
    confirmation: true,
  });
  const [currentPassword, setCurrent] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmation, setConfirmation] = useState<string>('');
  const [erro, setErro] = useState<boolean>(false);
  const [score, setScore] = useState(0);

  const id = useSelector((state: iStore) => state.auth.info.user?.id);
  const role = useSelector((state: iStore) => state.auth.selectUser.role);
  const { loading } = useSelector((state: iStore) => state.users);

  const actionUpdate = makeReduxUpdateUser();
  const { userId } = useParams<iParamsTypes>();

  const passwordLabel = translator('Nova senha');
  const passwordPlaceholder = translator('Digite nova senha');
  const currentPasswordLabel = translator('Senha atual');
  const changePasswordLabel = translator('Repita nova senha');
  const changePasswordPlaceholder = translator('Digite novamente a nova senha');

  const submit = () => {
    if (!userId && currentPassword === '') {
      AlertMessage({
        message: intl.formatMessage({ id: 'Digite sua senha atual.' }),
        type: 'danger',
      });
      setErro(true);
      return;
    }

    if (newPassword !== confirmation) {
      AlertMessage({
        message: intl.formatMessage({ id: 'Confirmação de senha incorreta.' }),
        type: 'danger',
      });
      return;
    }

    if (score < 5) {
      return AlertMessage({
        message: intl.formatMessage({
          id: 'A senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.',
        }),
        type: 'danger',
      });
    }

    actionUpdate.update({
      id: Number(userId ?? id!),
      password: {
        currentPassword,
        newPassword,
        confirmation,
      },
    });
  };

  useEffect(() => {
    if (newPassword === confirmation) {
      setErro(false);
    } else {
      setErro(true);
    }
  }, [confirmation]);

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Enter') {
      document.getElementById('action')?.click();
    }
  });

  return (
    <Container>
      <Header>
        <Icon src={IconPadlock} color="#656A6E" height="24px" />
        {translator('Mudar senha')}
      </Header>
      <Body>
        <form>
          {!userId && (
            <Input
              label={currentPasswordLabel}
              placeholder={currentPasswordLabel}
              value={currentPassword}
              onChange={e => setCurrent(e.target.value)}
              security={isSecurity.current}
              type="password"
              autoFocus
            />
          )}
          <Input
            label={passwordLabel}
            placeholder={passwordPlaceholder}
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            security={isSecurity.newPassword}
            type="password"
          />
          <Input
            error={erro}
            label={changePasswordLabel}
            placeholder={changePasswordPlaceholder}
            value={confirmation}
            onChange={e => setConfirmation(e.target.value)}
            security={isSecurity.confirmation}
            type="password"
          />

          <PasswordInstructions>
            {translator(
              '● A senha deve ter no mínimo 8 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial.',
            )}
          </PasswordInstructions>

          <PasswordValidationBar
            password={confirmation}
            updateScore={setScore}
          />
        </form>
        <Button
          id="action"
          type="submit"
          rounded
          onClick={submit}
          disabled={
            newPassword !== confirmation ||
            (newPassword === '' && confirmation === '') ||
            loading
          }
        >
          {loading ? 'Salvando...' : translator('Salvar alterações')}
        </Button>
      </Body>
    </Container>
  );
};

export default ChangePassword;
