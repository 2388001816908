import { ReduxMenu } from '~/domain/interfaces/models/components/ReduxMenu';
import { MenuTypes } from '~/domain/interfaces/redux/menu/types';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';
import { MenuActions } from './actions/index';

export const initialState: ReduxMenu = {
  actions: {
    options: {
      schedule: () => '/appointment',
      patient: () => '/appointment',
      clinicalDocuments: () => '/appointment',
      user: {
        manager: () => '/appointment',
        patient: () => '/appointment',
        professional: () => '/appointment',
      },
      adm: {
        activityLog: () => '/appointment',
        panels: () => '/appointment',
      },
    },
    user: {
      logout: () => '/appointment',
      profile: () => '/appointment',
    },
  },
  options: {
    schedule: true,
    patient: true,
    clinicalDocuments: true,
    user: {
      active: true,
      options: {
        manager: true,
        patient: true,
        professional: true,
      },
    },
    adm: {
      active: true,
      options: {
        activityLog: true,
        panels: true,
      },
    },
  },
  user: {
    name: 'Vinicius',
    role: 'Organizer',
  },
  active: 'manager',
  test: 'test',
};

const reducer = (state = initialState, action: MenuActions): ReduxMenu => {
  switch (action.type) {
    case MenuTypes.MENU_SETUP:
      return { ...state, ...action.payload };
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
