import React from 'react';
import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import { ShiftsProvider } from '~/presentation/hooks/shifts';
import Shifts from '~/presentation/pages/Shifts';
import { Container } from './styles';

export const MakeShiftsFactory: React.FC = () => {
  // const { onCallId } = useLocation<{ onCallId: number }>().state;

  return (
    <ShiftsProvider>
      <Container>
        <Menu />
        <PageHeader backDescription="Escala semanal" />
        <Shifts />
      </Container>
    </ShiftsProvider>
  );
};
