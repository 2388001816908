import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TitleDoc = styled.div`
  width: 100%;
  display: flex;
  height: 46px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background: #f4f7fe;
  color: #004ba5;
`;

interface ItemProps {
  disabled?: boolean;
}
export const Item = styled.div<ItemProps>`
  display: flex;
  width: 100%;
  height: 52px;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  padding: 16px 36px;
  color: gray;
  background-color: ${props => props.disabled && '#d3d3d3'};
  border-color: ${props => props.disabled && '#d3d3d3'};
  opacity: ${props => props.disabled && '0.5'};

  :hover {
    background-color: ${props => !props.disabled && '#efefef'};
  }
`;

export const ItemTitle = styled.text`
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
`;
