/* eslint-disable react/react-in-jsx-scope */
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { iMessage, iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeReduxListOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/ListOnCallSpecialistFactory';
import { IconClose } from '~/presentation/base/icons';
import { closeModal } from '~/utils/closeModal';
import {
  ProfessionalBadge,
  ProfessionalBadgeAvatar,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
  ProfessionalBadgeStatus,
  Table,
  Td,
  Th,
  Tr,
} from '../Lists/styles';
import {
  Container,
  Content,
  ListHeader,
  ListHeaderCloseButton,
  ListHeaderTitle,
} from './styles';

type ProfessionalsOnDutySidesheetProps = {
  message: iMessage;
};

interface iParamsType {
  callId: string;
}

const ProfessionalsOnDutySidesheet: React.FC<
  ProfessionalsOnDutySidesheetProps
> = ({ message }) => {
  const records = useSelector(
    (store: iStore) => store.onCallSpecialist.records,
  );

  const modalName = MessageOptions.professionalsOnDuty;
  const meIsOpen = modalName === message.active;

  const handleCloseModalWithEsc = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  const labelStatus = {
    WAITING: 'Aguardando',
    AVAILABLE: 'Disponível',
    UNAVAILABLE: 'Indisponível',
    ATTENDING: 'Em atendimento',
  };

  useEffect(() => {
    if (meIsOpen && message.data?.onCallId)
      makeReduxListOnCallSpecialist().list({
        onCallId: Number(message.data?.onCallId),
      });
  }, [meIsOpen, message.data?.onCallId]);

  return meIsOpen ? (
    <Container tabIndex={0} onKeyUp={handleCloseModalWithEsc}>
      <Content>
        <ListHeader>
          <ListHeaderTitle>Profissionais neste plantão</ListHeaderTitle>
          <ListHeaderCloseButton onClick={closeModal}>
            <IconClose />
          </ListHeaderCloseButton>
        </ListHeader>
        <Table>
          <thead>
            <tr>
              <Th>Nome do profissional</Th>
              <Th>Status</Th>
            </tr>
          </thead>
          <tbody>
            {records?.map((item, index) => (
              <Tr key={item.id}>
                <Td>
                  <ProfessionalBadge>
                    <ProfessionalBadgeAvatar src={item.avatar} />
                    <ProfessionalBadgeInfo>
                      <ProfessionalBadgeName>
                        {item.fullname}
                      </ProfessionalBadgeName>
                      <ProfessionalBadgeRole>
                        {item.profession.name}
                      </ProfessionalBadgeRole>
                    </ProfessionalBadgeInfo>
                  </ProfessionalBadge>
                </Td>
                <Td>
                  <ProfessionalBadgeStatus
                    status={
                      item.status as
                        | 'WAITING'
                        | 'AVAILABLE'
                        | 'UNAVAILABLE'
                        | 'ATTENDING'
                    }
                  >
                    {
                      labelStatus[
                        item.status as
                          | 'WAITING'
                          | 'AVAILABLE'
                          | 'UNAVAILABLE'
                          | 'ATTENDING'
                      ]
                    }
                  </ProfessionalBadgeStatus>
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Content>
    </Container>
  ) : null;
};

export default ProfessionalsOnDutySidesheet;
