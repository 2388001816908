import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ListAdministrator } from '~/presentation/roles/adm/pages';
import { makeReduxGetAllAdministrator } from '../../usecases/administrator/GetAllAdministratorFactory';
import { iStore } from '~/domain/interfaces/models';

export const MakeListAdministratorPage: React.FC = () => {
  const { selectUser } = useSelector((store: iStore) => store.auth);

  useEffect(() => {
    makeReduxGetAllAdministrator().getAll({
      pageSize: 9999,
      filter: {
        org: selectUser?.orgId,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [selectUser?.orgId]);

  return <ListAdministrator />;
};
