import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface ownProps {
  active: boolean;
}
export const Container = styled.div``;

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  .Icons {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  margin-left: 10px;
`;

export const CountPage = styled.div<ownProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0 5px;
  cursor: pointer;
  text {
    color: ${({ active }) =>
      active ? defaultTheme.typographyDark : defaultTheme.typographyDarkGray};
    border: ${({ active }) =>
      active ? `1px solid ${defaultTheme.typographyDark}` : null};
    border-radius: 8px;
    padding: 4px 8px;
  }
`;
