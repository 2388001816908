import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};
  overflow: auto; //Mudar o overflow
  max-height: 100vh;
  position: relative;
`;

export const Body = styled.div`
  display: grid;
  margin-top: 40px;

  justify-content: space-around;
  grid-template-areas: 'content-left content-right';
  grid-template-columns: 0.4fr 1fr;
  /* height: 100%; */
`;

export const ContentLeft = styled.div`
  width: 100%;
  grid-area: content-left;
`;

export const ContentRight = styled.div`
  width: 100%;
  grid-area: content-right;
`;

export const HeaderStatusServices = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;

  #service {
    font-weight: 500;
    color: #000000;
    font-size: 20px;
  }

  #flat {
    color: #004ba5;
    font-size: 20px;
  }
`;

export const ContainerRight = styled.div`
  width: 98%;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #efefef;
  height: 100%;
`;

export const ContentStatusServices = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 40px;
`;

export const StatusServices = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 5px;
  line-height: 18px;

  p {
    color: #000000;
    font-size: 16px;
  }

  text {
    color: #7a7a7a;
  }
`;

export const Cards = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

interface statusProps {
  status?: 'opened' | 'paid out' | 'overdue';
}

export const Status = styled.strong<statusProps>`
  color: ${props => {
    switch (props.status) {
      case 'paid out':
        return '#5BC766';
      case 'overdue':
        return '#EC5C52';
      case 'opened':
        return '#004BA5';
      default:
        return 'none';
    }
  }};
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;
