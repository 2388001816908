import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface ContainerProps {
  isOpen?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: ${props => props.isOpen && '25px'};
  gap: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
  font-size: 14px;
  color: ${defaultTheme.typographyDark};

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text {
      padding: 3px;
    }
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;

  margin-top: 24px;
  padding: 0 38px;
`;

interface SignedProps {
  isSigned?: boolean;
}

export const Signed = styled.div<SignedProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props =>
    props.isSigned ? props.theme.secundaryDark : props.theme.statusNo};
  font-size: 14px;
`;

export const DocListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 240px;
  gap: 16px;

  margin-top: 16px;
  padding: 0 38px;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;
