import styled from 'styled-components';

interface deleteProps {
  hasContent?: boolean;
}

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.7);
`;

export const Header = styled.div`
  width: 100%;
  height: 3.3em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-bottom: 2px solid #efefef;
  color: #7a7a7a;

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #000;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: fit-content;
  height: fit-content;

  position: fixed;
  background: white;
  padding: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 18px;

  width: 27em;
  margin-top: 40px;
  margin-bottom: 15px;
`;

export const ButtonsDiv = styled.div<deleteProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => (props.hasContent ? '25px' : '40px')};
  gap: 10px;
`;

export const CloseButton = styled.div`
  padding: 10px 18px 5px 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #c9c9c9;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`;
