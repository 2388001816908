import styled from 'styled-components';

interface ownProps {
  width?: string;
  color?: string;
  subtitle?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 272px;
  height: 163px;
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 3px;
  padding: 16px 24px;
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 32px;

  margin-bottom: 16px;
`;

export const HeaderImage = styled.div`
  max-width: 64px;
  margin-right: 16px;
`;

export const HeaderTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 32px;

  margin-bottom: 16px;
`;

export const BarSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 16px;

  margin-bottom: 1px;
`;

export const BarSubtitle = styled.h3<ownProps>`
  width: ${props => props.width ?? '25%'};
  text-align: center;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  color: #004ba5;
`;

export const Bar = styled.div`
  display: flex;
  width: 100%;
  height: 14px;

  background-color: #d4dfec;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  margin-bottom: 16px;
`;

export const FilledBar = styled.div<ownProps>`
  display: flex;
  width: ${props => props.width ?? '25%'};
  height: 14px;

  background: #004ba5;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0 12px;
`;

export const FooterItem = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FooterItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 116px;
`;

export const FooterItemText = styled.h3<ownProps>`
  font-weight: normal;
  font-size: ${props => (props.subtitle ? '12px' : '18px')};
  line-height: 15px;
  color: ${props => props.color ?? '#004BA5'};
  margin-bottom: 5px;
`;

export const CardImage = styled.div`
  width: 8px;
  height: 8px;

  padding-top: 4px;
  margin-right: 5px;
`;
