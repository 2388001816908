import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/Notifications/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/Notifications/getAll';

import { GetAllNotificationUser } from '~/domain/usecases/Notifications/redux';

export class ReduxGetAllNotificationUser implements GetAllNotificationUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllNotificationUser.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
