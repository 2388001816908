import styled from 'styled-components';

export const AlertFreemium = styled.div`
  width: 100%;
  /* padding: 20px 0px 20px 0px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

  span {
    color: black;
    font-weight: normal;
  }

  a {
    color: #005bc8;
    text-decoration: underline;
  }

  .css-1c3trtb-MuiPaper-root-MuiAlert-root {
    background-color: #e5eff9;
  }

  .MuiSvgIcon-root {
    fill: #005bc8;
  }

  .MuiAlert-filledInfo {
    background-color: transparent;
  }
`;
