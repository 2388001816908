import { GetAllAdministrator } from '~/domain/usecases/administrator/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGetAllAdministrator implements GetAllAdministrator {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllAdministrator.Model>;

  constructor(url: string, httClient: HttpClient<GetAllAdministrator.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (
    params: GetAllAdministrator.Params,
  ): Promise<GetAllAdministrator.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
