import { Dispatch } from 'redux';
import store from '~/data/store';
import { listExamTypesRequest } from '~/data/store/reducer/diagnosisReport/actions/listExamTypes';

import { iActionListExamTypes } from '~/domain/interfaces/redux/diagnosisReport/listExamTypes';

import { ListExamTypesDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

export class ReduxListExamTypesDiagnosisReport
  implements ListExamTypesDiagnosisReport
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listExamTypes = (
    params: ListExamTypesDiagnosisReport.Params,
  ): iActionListExamTypes => this.send(listExamTypesRequest(params));
}
