import { call, put } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/profession/getAll';

import { GetAllProfession } from '~/domain/usecases/profession/remote';
import { makeRemoteGetAllProfession } from '~/main/factories/usecases/profession/GetAllProfessionFactory';

import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/profession/actions/getAll';

export function* onGetAllRequest(action: iActionGetAll) {
  const remoteGetAllProfession = makeRemoteGetAllProfession();

  try {
    const response: GetAllProfession.Model = yield call(
      remoteGetAllProfession.getAll,
      action.payload,
    );

    yield put(getAllSuccess(response));
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
