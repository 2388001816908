import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/onCallSpecialist/actions/list';

import { iActionList } from '~/domain/interfaces/redux/onCallSpecialist/list';

import { ListOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

export class ReduxListOnCallSpecialist implements ListOnCallSpecialist {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListOnCallSpecialist.Params): iActionList =>
    this.send(listRequest(params));
}
