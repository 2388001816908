/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useMemo, useState } from 'react';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '~/presentation/components/UI';
import { IconProfessionalAvailable } from '~/presentation/base/icons';

import { translator } from '../i18n'; // TODO: check this component translation

import {
  Body,
  ConsultInfoContainer,
  ConsultInfoMessage,
  Container,
  ContainerModal,
  Footer,
  Header,
  ScrollContainer,
  TextBold,
  TextBoldTitle,
  TextNormal,
} from './styles/styles';

interface propsModalReadyWaitingRoom {
  message: iMessage;
}

const ModalReadyWaitingRoom: React.FC<propsModalReadyWaitingRoom> = ({
  message,
}) => {
  const { active, actionOk, actionCancel, data } = message;

  const msgName = MessageOptions.readyWaitingRoom;

  const readyWaitingRoom = data?.readyWaitingRoom;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <IconProfessionalAvailable />
            </Header>
            <Body>
              <ConsultInfoMessage>
                <div>
                  {readyWaitingRoom?.role === 'CON' && (
                    <TextBoldTitle>
                      {translator('Chegou a sua vez!')}
                    </TextBoldTitle>
                  )}

                  <TextBoldTitle>
                    {`O ${
                      readyWaitingRoom?.role === 'PRO'
                        ? translator('paciente')
                        : translator('profissional')
                    } ${translator('já está aguardando')}`}
                  </TextBoldTitle>
                </div>

                <TextNormal>
                  {readyWaitingRoom?.role === 'PRO'
                    ? translator(
                        'Clique em "Entrar na sala" para iniciar a consulta.',
                      )
                    : translator(
                        'Clique em "Entrar na sala" para ser atendido agora.',
                      )}
                </TextNormal>
              </ConsultInfoMessage>
            </Body>
            <Footer>
              <Button variant="primary" size="bigger" onClick={actionOk}>
                {translator('Entrar na sala')}
              </Button>
              {readyWaitingRoom?.role === 'CON' && (
                <Button
                  variant="secundary"
                  size="bigger"
                  onClick={actionCancel}
                >
                  {translator('Passar a vez')}
                </Button>
              )}
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalReadyWaitingRoom;
