import { Dispatch } from 'redux';
import store from '~/data/store';
import { selectOneRequest } from '~/data/store/reducer/clinicalDocs/actions/selectOne';

import { iSelectOne } from '~/domain/interfaces/redux/clinicalDocs/selectOne';

import { SelectOne } from '~/domain/usecases/clinicalDocs/redux';

export class ReduxSelectOne implements SelectOne {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  select = (params: SelectOne.Params): iSelectOne =>
    this.send(selectOneRequest(params));
}
