import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { WaitingRoom } from '~/domain/interfaces/socketEvents/WaitingRoom';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import { makeReduxInsertAppointment } from '~/main/factories/usecases/appointment/InsertAppointmentFactory';
import { makeReduxGetAllNotificationUser } from '~/main/factories/usecases/Notifications/GetAllNotificationUserFactory';
import { makeReduxUserQueueWaitingRoom } from '~/main/factories/usecases/waitingRoom/UserQueueWaitingRoomFactory';
import { makeReduxInsertClinicalDocs } from '~/main/factories/usecases/clinicalDocs/InsertClinicalDocsFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { translator } from '~/presentation/components/i18n';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export const HandlerEvents = async (data: any): Promise<void> => {
  const { auth } = JSON.parse(localStorage.getItem('persist:reducers') || '');
  const { info } = JSON.parse(auth);

  console.log('>>> Notification listener: ', data);

  if (info.user === undefined) return;

  console.log('atualizando notificações handler: ', info.user.id);

  makeReduxGetAllNotificationUser().getAll({
    filters: {
      user: info.user.id,
      channel: 'WEB',
    },
  });

  switch (data?.notification?.name) {
    case 'WAITINGROOM_JOINED': {
      const x: WaitingRoom = data;

      makeReduxUserQueueWaitingRoom().add(x);

      if (x.from?.userId !== info.user.id) {
        makeRemoteGetAppointmentById()
          .getAppointmentById({
            id: x.data.appointment,
          })
          .then(response => {
            AlertMessage({
              message: `${x.from.firstName} ${
                x.from.lastName
              } ${intl.formatMessage({
                id: 'entrou no atendimento de data',
              })} ${new Date(response.appointment.scheduled).toLocaleString()}`,
              type: 'info',
            });

            // if (
            //   window.location.pathname === '/appointment' ||
            //   window.location.pathname === '/appointment/waitingroom'
            // )
            //   makeReduxActiveMessage().active({
            //     active: 'readyWaitingRoom',
            //     actionCancel: closeModal,
            //   });
          })
          .catch(() => {
            AlertMessage({
              message: `${x.from.firstName} ${
                x.from.lastName
              } ${intl.formatMessage({ id: 'entrou no atendimento' })}`,
              type: 'info',
            });
          });
      }
      break;
    }
    case 'WAITINGROOM_LEFT': {
      const x: WaitingRoom = data;
      makeReduxUserQueueWaitingRoom().remove(x);
      break;
    }
    case 'APPOINTMENT_CREATED': {
      console.log('###########appointment created: ', data.data);

      const appointment = await makeRemoteGetAppointmentById()
        .getAppointmentById({
          id: data.data.appointment,
        })
        .catch(err => {
          console.log('Não foi possível adicionar o agendamento: ', err);
          // AlertMessage({
          //   message: intl.formatMessage({
          //     id: 'Falha ao adicionar agendamento a lista de atendimentos!',
          //   }),
          //   type: 'danger',
          // });

          return null;
        });

      if (appointment) makeReduxInsertAppointment().insert(appointment);
      break;
    }
    case 'APPOINTMENT_CANCELLED': {
      // case 'APPOINTMENT_UPDATED':
      break;
    }
    case 'CREATED_ATTACHED': {
      makeReduxInsertClinicalDocs().insert(
        await makeRemoteGetClinicalDocs().get({ docId: data.data.clinicalDoc }),
      );
      break;
    }
    default:
      break;
  }
};
