import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { SendFilesDignosisReport as UsecaseRemoteSendFilesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteSendFilesDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */
export const makeRemoteSendFilesDiagnosisReport =
  (): UsecaseRemoteSendFilesDiagnosisReport =>
    new RemoteSendFilesDiagnosisReport(
      makeApiUrl('/reports/files'),
      makeHttpClient(),
    );
