/**
 * component data mapper
 * @param item date in format isoString.
 * @param duration seconds
 */
export const makeDuration = (item: string, duration: number) => {
  const time = new Date(item);
  const dateStartIsoString = time.toISOString();

  const dateStartFormattedIsoString = dateStartIsoString.replace(
    '000Z',
    `${time.getTimezoneOffset()}Z`,
  );

  const timeStart = new Date(time.getTime()).toLocaleTimeString('pt-BR', {
    hour12: false,
  });
  const timeEnd = new Date(
    time.getTime() + duration * 60000,
  ).toLocaleTimeString('pt-BR', { hour12: false });

  const dateEndIsoString = new Date(
    time.getFullYear(),
    time.getMonth(),
    time.getDate(),
    Number(timeEnd.split(':')[0]),
    Number(timeEnd.split(':')[1]),
  ).toISOString();

  const dateEndFormattedIsoString = dateEndIsoString.replace(
    '000Z',
    `${time.getTimezoneOffset()}Z`,
  );

  const startFormat = `${timeStart.split(':')[0]}:${timeStart.split(':')[1]}`;
  const endFormat = `${timeEnd.split(':')[0]}:${timeEnd.split(':')[1]}`;
  return {
    startFormat,
    dateStartIsoString: dateStartFormattedIsoString,
    endFormat,
    dateEndIsoString: dateEndFormattedIsoString,
  };
};
