import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetACL as UsecaseRemoteGetACL } from '~/domain/usecases/permissions/remote';
import { GetACL as UsecaseReduxGetACL } from '~/domain/usecases/permissions/redux';

import { RemoteGetACL } from '~/data/repository/permissions';
import { ReduxGetACL } from '~/data/store/reducer/permissions/usecases';

export const makeRemoteGetACL = (): UsecaseRemoteGetACL =>
  new RemoteGetACL(makeApiUrl('/permissions'), makeHttpClient());

export const makeReduxGetACL = (): UsecaseReduxGetACL => new ReduxGetACL();
