/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-duplicates */
import { ChakraProvider } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import enUS from 'date-fns/locale/en-CA';
import es from 'date-fns/locale/es';
import ptBR from 'date-fns/locale/pt';
import { ThemeProvider } from 'styled-components';

import { IntlProvider } from 'react-intl';
import { ReactNotifications } from 'react-notifications-component';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import store from '~/data/store';
import { ConnectSocket } from '~/infra/socket/ConnectSocket';
import { Routes } from '~/main/routes';
import LogoutHandler from '~/presentation/components/LogoutHandler';
import Notifications from '~/presentation/components/messages';
import AccessControl from '~/presentation/hooks/access';
import translations from './infra/i18n/locales';
import { defaultTheme } from './presentation/base/themes';

import GlobalStyle from '~/presentation/base/styles/globals';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'animate.css/animate.compat.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notifications-component/dist/theme.css';

import '@wisecare/design-system-web/dist/styles.css';
import './infra/global/variables';

const persistor = persistStore(store);

export interface iSupportedLanguages {
  language:
  | 'pt-BR'
  | 'pt-br'
  | 'en-US'
  | 'en-us'
  | 'es'
  | 'es-ES'
  | 'es-es'
  | 'es-DO'
  | 'es-do'
  | 'es-GT'
  | 'es-gt'
  | 'es-HN'
  | 'es-hn'
  | 'es-MX'
  | 'es-mx'
  | 'es-PA'
  | 'es-pa'
  | 'es-PE'
  | 'es-pe'
  | 'es-SV'
  | 'es-SE'
  | 'es-sv'
  | 'es-se';
}

function App(): JSX.Element {
  const [selectedLocale, setSelectedLocale] =
    useState<iSupportedLanguages['language']>('en-US');
  const [dateLocale, setDateLocale] = useState(ptBR);

  useEffect(() => {
    let userLanguage = localStorage.getItem('i18nextLng');

    if (!userLanguage) {
      userLanguage = navigator.language;
      localStorage.setItem('i18nextLng', userLanguage);
    }

    setSelectedLocale(userLanguage as iSupportedLanguages['language']);
    if (userLanguage.includes('pt')) setDateLocale(ptBR);
    if (userLanguage.includes('en')) setDateLocale(enUS);
    if (userLanguage.includes('es')) setDateLocale(es);
  }, []);

  return (
    <IntlProvider
      messages={translations[selectedLocale]}
      locale={String(selectedLocale)}
      onError={(err): void => {
        // console.log(
        //   'Supress missing keys error, uncomment to fill missing keys: ',
        //   err,
        // );
      }}
      defaultLocale="pt-BR"
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <AccessControl>
            <ConnectSocket>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateLocale}>
                <Notifications>
                  <ThemeProvider theme={defaultTheme}>
                    <ChakraProvider>
                      <GlobalStyle />
                      <ReactNotifications />
                      <Routes />
                      <LogoutHandler />
                    </ChakraProvider>
                  </ThemeProvider>
                </Notifications>
              </MuiPickersUtilsProvider>
            </ConnectSocket>
          </AccessControl>
        </PersistGate>
      </Provider>
    </IntlProvider>
  );
}

export default App;
