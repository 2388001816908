import { DeleteClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';
import {
  iActionDelete,
  ClinicalDocsTypes,
  iActionDeleteFailed,
  iActionDeleteSuccess,
} from '~/domain/interfaces/redux/clinicalDocs/delete';

export const deleteRequest = (
  payload: DeleteClinicalDocs.Params,
): iActionDelete => ({
  type: ClinicalDocsTypes.DELETE,
  payload,
});

export const deleteSucess = (): iActionDeleteSuccess => ({
  type: ClinicalDocsTypes.DELETE_SUCCESS,
});

export const deleteFailed = (): iActionDeleteFailed => ({
  type: ClinicalDocsTypes.DELETE_FAILED,
});
