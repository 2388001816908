import { call, put, select } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iActionCreate } from '~/domain/interfaces/redux/appointment/create';
import { CreateAppointment } from '~/domain/usecases/appointment/remote/CreateAppointment';
import {
  createSuccess,
  createFailed,
} from '~/data/store/reducer/appointment/actions/create';

import { makeRemoteCreateAppointment } from '~/main/factories/usecases/appointment/CreateAppointmentFactory';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onCreate(action: iActionCreate) {
  const remoteCreateAppointment = makeRemoteCreateAppointment();

  try {
    const response: CreateAppointment.Model = yield call(
      remoteCreateAppointment.create,
      action.payload,
    );
    yield put(createSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {}

export function onCreateFailed() {
  AlertMessage({
    message: intl.formatMessage({ id: 'Falha ao criar agendamento!' }),
    type: 'danger',
  });
}
