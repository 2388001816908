import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { PostPasswordRecovery } from '~/domain/usecases/password/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import '~/infra/global/variables';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { History } from '~/main/routes';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemotePostPasswordRecovery implements PostPasswordRecovery {
  private readonly url: string;

  private readonly httpClient: HttpClient<PostPasswordRecovery.Model>;

  constructor(url: string, httClient: HttpClient<PostPasswordRecovery.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  post = async (
    params: PostPasswordRecovery.Params,
  ): Promise<PostPasswordRecovery.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        AlertMessage({
          message: intl.formatMessage({ id: 'Email enviado com sucesso!' }),
          type: 'success',
        });
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
