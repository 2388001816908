/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as datefns from 'date-fns';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import CreatableSelect from 'react-select/creatable';
import * as _ from 'lodash';
import { Input, Select, Button, Check } from '~/presentation/components/UI';

import { translator } from '~/presentation/components/i18n';
import { iStore } from '~/domain/interfaces/models';
// import { makeReduxListAppointment } from '~/main/factories/usecases/appointment/ListAppointmentFactory';
import { iFilterProps } from '~/presentation/pages/AppointmentHistory/interface';
import { makeReduxAppointmentFiltered } from '~/main/factories/usecases/appointment/AppointmentFiltered';
import { makeReduxListAppointment } from '~/main/factories/usecases/appointment/ListAppointmentFactory';
import { GetCitiesByUf as RemoteGetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';

import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { makeRemoteGetAllDocType } from '~/main/factories/usecases/docType/GetAllDocType';
import { DocType } from '~/domain/usecases/docType/remote';
import { makeRemoteGetAppointmentOutcome } from '~/main/factories/usecases/appointment/GetAppointmentOutcomeFactory';
import { iAppointmentOutcome } from '~/domain/usecases/appointment/remote';
import { Label } from '../UI/select/styles';
import InputKeyboardDate from '../inputKeyboardDate';
import {
  Container,
  ContainerButtons,
  ContainerForm,
  InlineFilters,
  FilterDataPatient,
  InlineInputs,
  Options,
  ContentFilters,
  FilterData,
  ContentPatient,
  selectStyles,
  Control,
  DropdownIndicator,
  Option,
} from './styles/StyledFilterAppointmentHistory';

type LocationState = {
  user: any;
};

const status = [
  {
    name: 'Agendado',
    value: 'SCHEDULED',
  },
  {
    name: 'Em andamento',
    value: 'INPROGRESS',
  },
  {
    name: 'Finalizado',
    value: 'ENDED',
  },
  {
    name: 'Cancelado',
    value: 'CANCELED',
  },
  {
    name: 'Expirado',
    value: 'EXPIRED',
  },
];

const services = [
  {
    name: 'Agendamentos',
    value: 'CONSULTATION',
  },
  {
    name: 'Plantão',
    value: 'ONCALL',
  },
  {
    name: 'Interconsulta instanteânea',
    value: 'INSTANTANEOUS',
  },
];

const FilterAppointmentHistory: React.FC<iFilterProps> = ({
  filter,
  handleSetFilter,
}) => {
  const [cities, setCities] = useState<RemoteGetCitiesByUf.Model>([]);
  const [clinicalDocTypes, setClinicalDocTypes] = useState<DocType[]>([]);
  const [supportDocTypes, setSupportDocTypes] = useState<DocType[]>([]);
  const [appointmentOutcomes, setAppointmentOutcomes] = useState<
    iAppointmentOutcome[]
  >([]);

  const { auth, consultant, professions, professional } = useSelector(
    (store: iStore) => store,
  );
  const specialties = useSelector((store: iStore) => store.specialty.results);

  const {
    role,
    orgId,
    orgUnitId,
    orgUnits: orgUnitsSelectUser,
  } = useSelector((store: iStore) => store.auth.selectUser);

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { results: orgUnits } = useSelector((store: iStore) => store.orgUnit);

  const location = useLocation<LocationState>();

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const defaultEndDate = datefns.addDays(new Date(), 2);
  defaultEndDate.setHours(23, 59, 59, 999);

  const defaultStartDate = new Date();
  defaultStartDate.setHours(0, 0, 0, 0);

  const user = location.state?.user;

  const orgUnitsFormatted =
    (orgUnitsSelectUser?.map(item => item.id).filter(Boolean) as number[]) ??
    [];

  const handleFilter = () => {
    switch (role) {
      case 'CON':
        makeReduxAppointmentFiltered().post({
          orderBy: [
            {
              attribute: 'TIMESTAMP',
              direction: 'DESC',
            },
          ],
          dataControl: {
            limit: 9999,
          },
          consultant: consultantId,
          timestamp: {
            begin: filter?.begin?.toISOString(),
            end: filter?.end?.toISOString(),
          },
          status: filter?.statusIs?.length
            ? filter.statusIs
            : ['ENDED', 'CANCELED'],
          healthUnit:
            filter?.healthUnit !== -1 ? filter?.healthUnit : undefined,
          profession:
            filter?.profession !== 'DEFAULT'
              ? Number(filter?.profession)
              : undefined,
          specialty:
            filter?.specialtyIs !== 'DEFAULT'
              ? Number(filter?.specialtyIs)
              : undefined,
          professionalName: filter?.professionalName,
          modality:
            filter?.modalityIs !== 'DEFAULT' ? filter?.modalityIs : undefined,
          clinicalDocType:
            filter?.clinicalDocType !== 'DEFAULT'
              ? filter?.clinicalDocType
              : undefined,
          supportDocType:
            filter?.supportDocType !== 'DEFAULT'
              ? filter?.supportDocType
              : undefined,
          outcome: filter?.outcome?.length ? filter.outcome : undefined,
          orgUnitId: filter?.orgUnitId !== -1 ? filter?.orgUnitId : undefined,
        });
        break;
      case 'PRO':
        makeReduxAppointmentFiltered().post({
          orderBy: [
            {
              attribute: 'TIMESTAMP',
              direction: 'DESC',
            },
          ],
          dataControl: {
            limit: 9999,
          },
          timestamp: {
            begin: filter?.begin?.toISOString(),
            end: filter?.end?.toISOString(),
          },
          // TODO: Reativar quando UF for considerada no Backend
          // orgUF: filter?.orgUf,
          city: filter?.orgCity !== 'DEFAULT' ? filter?.orgCity : undefined,
          healthUnit:
            filter?.healthUnit !== -1 ? Number(filter?.healthUnit) : undefined,
          professional: professionalId,
          status: filter?.statusIs?.length
            ? filter.statusIs
            : ['ENDED', 'CANCELED'],
          service: filter?.service?.length ? filter.service : undefined,
          consultantName: filter?.consultant?.length
            ? filter.consultant
            : undefined,
          consultant:
            filter?.consultantId !== -1
              ? Number(filter?.consultantId)
              : undefined,
          outcome: filter?.outcome?.length ? filter.outcome : undefined,
          orgUnitId: filter?.orgUnitId !== -1 ? filter?.orgUnitId : undefined,
        });
        break;
      case 'ORG':
        makeReduxAppointmentFiltered().post({
          orderBy: [
            {
              attribute: 'TIMESTAMP',
              direction: 'DESC',
            },
          ],
          dataControl: {
            limit: 9999,
          },
          timestamp: {
            begin: filter?.begin?.toISOString(),
            end: filter?.end?.toISOString(),
          },
          orgId,
          // TODO: Reativar quando UF for considerada no Backend
          // orgUF: filter?.orgUf,
          city: filter?.orgCity !== 'DEFAULT' ? filter?.orgCity : undefined,
          healthUnit:
            filter?.healthUnit !== -1 ? Number(filter?.healthUnit) : undefined,
          service: filter?.service?.length ? filter.service : undefined,
          status: filter?.statusIs?.length
            ? filter.statusIs
            : ['ENDED', 'CANCELED'],
          professional:
            filter?.professional !== -1
              ? Number(filter?.professional)
              : undefined,
          professionalName: filter?.professionalName?.length
            ? filter.professionalName
            : undefined,
          outcome: filter?.outcome?.length ? filter.outcome : undefined,
          orgUnitId: filter?.orgUnitId !== -1 ? filter?.orgUnitId : undefined,
        });
        break;
      case 'ADM':
        makeReduxAppointmentFiltered().post({
          orderBy: [
            {
              attribute: 'TIMESTAMP',
              direction: 'DESC',
            },
          ],
          dataControl: {
            limit: 9999,
          },
          timestamp: {
            begin: filter?.begin?.toISOString(),
            end: filter?.end?.toISOString(),
          },
          orgId,
          // TODO: Reativar quando UF for considerada no Backend
          // orgUF: filter?.orgUf,
          city: filter?.orgCity !== 'DEFAULT' ? filter?.orgCity : undefined,
          healthUnit:
            filter?.healthUnit !== -1 ? Number(filter?.healthUnit) : undefined,
          service: filter?.service?.length ? filter.service : undefined,
          professional:
            filter?.professional !== -1
              ? Number(filter?.professional)
              : undefined,
          professionalName: filter?.professionalName?.length
            ? filter.professionalName
            : undefined,
          status: filter?.statusIs?.length
            ? filter.statusIs
            : ['ENDED', 'CANCELED'],
          outcome: filter?.outcome?.length ? filter.outcome : undefined,
          orgUnitId: filter?.orgUnitId !== -1 ? filter?.orgUnitId : undefined,
          ...(user?.role === 'CON' && { consultant: Number(user?.cod) }),
          ...(user?.role === 'PRO' && { professional: Number(user?.cod) }),
        });
        break;
      default:
        break;
    }

    handleSetFilter({
      ...filter,
      isBeginDirty: true,
    });

    document.getElementById('close_filter')?.click();
  };

  const resetFilter = () => {
    handleSetFilter({
      isBeginDirty: false,
      consultantId: -1,
      consultant: '',
      professional: -1,
      professionalName: '',
      healthUnit: -1,
      service: [],
      statusIs: [],
      outcome: [],
      orgCity: 'DEFAULT',
      modalityIs: 'DEFAULT',
      specialtyIs: 'DEFAULT',
      clinicalDocType: 'DEFAULT',
      supportDocType: 'DEFAULT',
      profession: 'DEFAULT',
      begin: defaultStartDate,
      end: defaultEndDate,
      orgUf: 'PB',
      orgUnitId: -1,
    });

    switch (role) {
      case 'CON':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              org: orgId,
              consultant: consultantId,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;
      case 'PRO':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              org: orgId,
              orgUnit: orgUnitsFormatted,
              professional: professionalId,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;
      case 'ORG':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              org: orgId,
              orgUnit: orgUnitsFormatted,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;
      case 'ADM':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              status: ['ENDED', 'CANCELED'],
              org: orgId,
              ...(user?.role === 'CON' && { consultant: Number(user?.cod) }),
              ...(user?.role === 'PRO' && { professional: Number(user?.cod) }),
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const generateConsultantSelectOptions = useCallback(() => {
    return [
      {
        value: -1,
        label: 'Todos',
      },
      ..._.sortBy(
        consultant?.results?.flatMap(value => {
          if (value.user.id !== auth?.info?.user?.id)
            return [
              {
                value: value.consultant.id,
                label: `${value.user?.firstName} ${value.user?.lastName}`,
              },
            ];

          return [];
        }),
        item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
      ),
    ];
  }, [auth?.info?.user?.id, consultant?.results]);

  const generateProfessionalSelectOptions = useCallback(() => {
    return [
      {
        value: -1,
        label: 'Todos',
      },
      ..._.sortBy(
        professional?.results?.flatMap(value => {
          if (value.user.id !== auth?.info?.user?.id)
            return [
              {
                value: value.professional.id,
                label: `${value.user?.firstName} ${value.user?.lastName}`,
              },
            ];

          return [];
        }),
        item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
      ),
    ];
  }, [auth?.info?.user?.id, professional?.results]);

  const generateHealthUnitSelectOptions = useCallback(() => {
    return [
      {
        value: -1,
        label: 'Todos',
      },
      ..._.sortBy(
        healthUnits?.flatMap(value => {
          return [
            {
              value: value.id,
              label: value.name,
            },
          ];
        }),
        item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
      ),
    ];
  }, [healthUnits]);

  const generateOrgUnitSelectOptions = useCallback(() => {
    return [
      {
        value: -1,
        label: 'Todos',
      },
      ..._.sortBy(
        orgUnits?.flatMap(value => {
          return [
            {
              value: value.orgUnit.id,
              label: value.orgUnit.name,
            },
          ];
        }),
        item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
      ),
    ];
  }, [orgUnits]);

  const patientMultiselectSelected = useMemo(() => {
    return filter?.consultant?.length
      ? {
          label: filter?.consultant,
          value: filter?.consultant,
        }
      : generateConsultantSelectOptions().find(
          option => option.value === filter?.consultantId,
        );
  }, [
    filter?.consultant,
    filter?.consultantId,
    generateConsultantSelectOptions,
  ]);

  const professionalMultiselectSelected = useMemo(() => {
    return filter?.professionalName?.length
      ? {
          label: filter?.professionalName,
          value: filter?.professionalName,
        }
      : generateProfessionalSelectOptions().find(
          option => option.value === filter?.professional,
        );
  }, [
    filter?.professional,
    filter?.professionalName,
    generateProfessionalSelectOptions,
  ]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(
      option => option.value === filter?.healthUnit,
    );
  }, [filter?.healthUnit, generateHealthUnitSelectOptions]);

  const orgUnitMultiselectSelected = useMemo(() => {
    return generateOrgUnitSelectOptions().find(
      option => option.value === filter?.orgUnitId,
    );
  }, [filter?.orgUnitId, generateOrgUnitSelectOptions]);

  useEffect(() => {
    if (filter?.orgUf) {
      makeRemoteGetCitiesByUf()
        .getCitiesByUf({ uf: filter?.orgUf })
        .then(res => {
          setCities(res);
        })
        .catch(err => {
          console.log('Erro ao buscar cidades: ', err);
        });
    }
  }, [filter?.orgUf]);

  useEffect(() => {
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
    });

    makeRemoteGetAllDocType()
      .getAll({
        limit: 9999,
        type: 'SUPPORT',
      })
      .then(res => {
        setSupportDocTypes(res);
      })
      .catch(err => {
        console.log('err', err);
      });

    makeRemoteGetAllDocType()
      .getAll({
        limit: 9999,
        type: 'CLINICAL',
      })
      .then(res => {
        setClinicalDocTypes(res);
      })
      .catch(err => {
        console.log('err', err);
      });

    makeRemoteGetAppointmentOutcome()
      .getRecordings({})
      .then(response => {
        setAppointmentOutcomes(response);
      });
  }, []);

  return (
    <Container
      role={role}
      style={{ height: role !== 'CON' ? '574px' : 'fit-content' }}
    >
      <ContainerForm
        onSubmit={e => {
          e.preventDefault();
          handleFilter();
        }}
      >
        {(role === 'ORG' || role === 'ADM' || role === 'PRO') && (
          <ContentFilters>
            <span>Período</span>
            <FilterData>
              <InputKeyboardDate
                state={filter?.begin ?? defaultStartDate}
                height="40px"
                setState={(date: Date) =>
                  date &&
                  date.setHours(0, 0, 0, 0) &&
                  handleSetFilter({
                    ...filter,
                    begin: date,
                    isBeginDirty: true,
                  })
                }
                placeholder="DD/MM/AAAA"
                endAdornment
              />
              <>
                <span>até</span>
                <InputKeyboardDate
                  state={filter?.end ?? defaultEndDate}
                  height="40px"
                  setState={(date: Date) =>
                    date &&
                    date.setHours(23, 59, 59, 999) &&
                    handleSetFilter({ ...filter, end: date })
                  }
                  minDate={filter?.begin ?? undefined}
                  placeholder="DD/MM/AAAA"
                  endAdornment
                />
              </>
            </FilterData>
          </ContentFilters>
        )}

        {(role === 'PRO' || role === 'ADM' || role === 'ORG') && (
          <ContentFilters>
            <InlineFilters>
              <Select
                label="UF"
                height="40px"
                width="72px"
                disabled
                value={filter?.orgUf}
              >
                <option value="UF">PB</option>
              </Select>

              <Select
                label="Município"
                width="320px"
                height="40px"
                onChange={e =>
                  handleSetFilter({
                    ...filter,
                    orgCity: e.target.value,
                  })
                }
                value={filter?.orgCity}
              >
                <Options value="DEFAULT">Todos</Options>

                {cities?.map(item => (
                  <Options key={item?.id} value={item?.id}>
                    {item?.nome}
                  </Options>
                ))}
              </Select>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <Label style={{ marginBottom: '6px' }}>
                  Estabelecimento de saúde
                </Label>
                <CreatableSelect
                  className="select"
                  components={{
                    Control,
                    DropdownIndicator,
                    IndicatorSeparator: () => null,
                    Option,
                  }}
                  controlShouldRenderValue
                  placeholder="Estabelecimento de saúde"
                  formatCreateLabel={(label: string) => `Buscar por ${label}`}
                  options={generateHealthUnitSelectOptions()}
                  value={healthUnitMultiselectSelected}
                  onChange={e => {
                    handleSetFilter({
                      ...filter,
                      healthUnit: e?.value,
                    });
                  }}
                  styles={selectStyles()}
                  isValidNewOption={() => false}
                  noOptionsMessage={() => 'Nenhum resultado encontrado'}
                />
              </div>
            </InlineFilters>
          </ContentFilters>
        )}

        {role === 'PRO' && (
          <ContentFilters>
            <Label style={{ marginBottom: '6px' }}>Paciente</Label>
            <CreatableSelect
              className="select"
              components={{
                Control,
                DropdownIndicator,
                IndicatorSeparator: () => null,
                Option,
              }}
              controlShouldRenderValue
              placeholder="Nome do paciente"
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              options={generateConsultantSelectOptions()}
              value={patientMultiselectSelected}
              onChange={(e: any) => {
                handleSetFilter({
                  ...filter,
                  consultantId: e?.__isNew__ ? -1 : e?.value,
                  consultant: e?.__isNew__ ? e.value : '',
                });
              }}
              styles={selectStyles()}
            />
          </ContentFilters>
        )}

        {(role === 'ORG' || role === 'ADM') && (
          <>
            <ContentFilters>
              <Label style={{ marginBottom: '6px' }}>Setor</Label>
              <CreatableSelect
                className="select"
                components={{
                  Control,
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Option,
                }}
                controlShouldRenderValue
                placeholder="Nome do setor"
                formatCreateLabel={(label: string) => `Buscar por ${label}`}
                options={generateOrgUnitSelectOptions()}
                value={orgUnitMultiselectSelected}
                onChange={(e: any) => {
                  handleSetFilter({
                    ...filter,
                    orgUnitId: e?.value,
                  });
                }}
                styles={selectStyles()}
                isValidNewOption={() => false}
                noOptionsMessage={() => 'Nenhum resultado encontrado'}
              />
            </ContentFilters>
            <ContentFilters>
              <Label style={{ marginBottom: '6px' }}>Profissional</Label>
              <CreatableSelect
                className="select"
                components={{
                  Control,
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                  Option,
                }}
                controlShouldRenderValue
                placeholder="Nome do profissional"
                formatCreateLabel={(label: string) => `Buscar por ${label}`}
                options={generateProfessionalSelectOptions()}
                value={professionalMultiselectSelected}
                onChange={(e: any) => {
                  handleSetFilter({
                    ...filter,
                    professional: e?.__isNew__ ? -1 : e?.value,
                    professionalName: e?.__isNew__ ? e.value : '',
                  });
                }}
                styles={selectStyles()}
              />
            </ContentFilters>
          </>
        )}

        {(role === 'ORG' || role === 'ADM' || role === 'PRO') && (
          <ContentFilters>
            <>
              <span>Serviço</span>
              <div style={{ marginTop: '16px' }}>
                {services?.map((item, index) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '16px',
                    }}
                  >
                    <Check
                      id={item.value}
                      name={item.value}
                      value={item.value}
                      checked={
                        !!filter?.service?.find(
                          service => service === item?.value,
                        )
                      }
                      label={item.name}
                      onChange={e => {
                        handleSetFilter({
                          ...filter,
                          service: filter?.service?.includes(e.target.value)
                            ? filter?.service?.filter(
                                service => service !== e.target.value,
                              )
                            : [...(filter?.service ?? []), e.target.value],
                        });
                      }}
                    />
                  </div>
                ))}
              </div>
            </>
          </ContentFilters>
        )}

        {(role === 'PRO' || role === 'ADM' || role === 'ORG') && (
          <ContentFilters>
            <span>Status</span>
            <div style={{ marginTop: '16px' }}>
              {status?.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '16px',
                  }}
                >
                  <Check
                    id={item.value}
                    name={item.value}
                    value={item.value}
                    checked={
                      !!filter?.statusIs?.find(stat => stat === item?.value)
                    }
                    label={item.name}
                    onChange={e => {
                      handleSetFilter({
                        ...filter,
                        statusIs: filter?.statusIs?.includes(e.target.value)
                          ? filter?.statusIs?.filter(
                              statusIs => statusIs !== e.target.value,
                            )
                          : [...(filter?.statusIs ?? []), e.target.value],
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </ContentFilters>
        )}

        {(role === 'PRO' || role === 'ADM' || role === 'ORG') && (
          <ContentFilters>
            <span>Desfecho</span>
            <div style={{ marginTop: '16px' }}>
              {appointmentOutcomes?.map((item, index) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '16px',
                  }}
                >
                  <Check
                    id={item.msgKey}
                    name={item.msgKey}
                    value={item.id}
                    checked={!!filter?.outcome?.find(stat => stat === item?.id)}
                    label={translator(item.msgKey)}
                    onChange={e => {
                      handleSetFilter({
                        ...filter,
                        outcome: filter?.outcome?.includes(
                          Number(e.target.value),
                        )
                          ? filter?.outcome?.filter(
                              outcome => outcome !== Number(e.target.value),
                            )
                          : [
                              ...(filter?.outcome ?? []),
                              Number(e.target.value),
                            ],
                      });
                    }}
                  />
                </div>
              ))}
            </div>
          </ContentFilters>
        )}

        {role === 'CON' && (
          <ContentPatient>
            <FilterDataPatient>
              <span>A partir de</span>
              <InputKeyboardDate
                state={filter?.begin ?? defaultStartDate}
                height="40px"
                width="26.5%"
                setState={(date: Date) =>
                  date &&
                  date.setHours(0, 0, 0, 0) &&
                  handleSetFilter({
                    ...filter,
                    begin: date,
                    isBeginDirty: true,
                  })
                }
                placeholder="DD/MM/AAAA"
                endAdornment
              />
              <span>até</span>
              <InputKeyboardDate
                state={filter?.end ?? defaultEndDate}
                height="40px"
                width="26.5%"
                setState={(date: Date) =>
                  date &&
                  date.setHours(23, 59, 59, 999) &&
                  handleSetFilter({ ...filter, end: date })
                }
                minDate={filter?.begin ?? undefined}
                placeholder="DD/MM/AAAA"
                endAdornment
              />
            </FilterDataPatient>

            <InlineInputs>
              <p>Status</p>
              <Select
                width="307px"
                height="40px"
                onChange={e =>
                  handleSetFilter({ ...filter, statusIs: [e.target.value] })
                }
              >
                {status?.map(item => (
                  <Options id={item.value} value={item.value}>
                    {item.name}
                  </Options>
                ))}
              </Select>
            </InlineInputs>

            <InlineInputs>
              <p>Unidade de saúde:</p>
              <Select
                id="select_healthUnit"
                width="307px"
                height="40px"
                value={filter.healthUnit}
                onChange={e =>
                  handleSetFilter({
                    ...filter,
                    healthUnit: Number(e.target.value),
                  })
                }
              >
                <Options id="healthUnit_1" value={-1}>
                  {translator('Todos')}
                </Options>

                {healthUnits?.map(item => (
                  <option value={item.id}>{item.name}</option>
                ))}
              </Select>
            </InlineInputs>

            <InlineInputs>
              <p>Area: </p>
              <Select
                width="307px"
                height="40px"
                id="select_profession"
                value={filter.profession}
                placeholder={`${translator('Area')}`}
                onChange={e =>
                  handleSetFilter({ ...filter, profession: e.target.value })
                }
              >
                <Options value="DEFAULT">Todas</Options>

                {professions?.results?.map(item => (
                  <Options value={item.profession.id}>
                    {item.profession.name}
                  </Options>
                ))}
              </Select>
            </InlineInputs>

            <InlineInputs>
              <p>{`${translator('Especialidade')}:`}</p>
              <Select
                id="select_especialty"
                width="307px"
                height="40px"
                value={filter.specialtyIs}
                placeholder={`${translator('Especialidade')}`}
                onChange={e =>
                  handleSetFilter({ ...filter, specialtyIs: e.target.value })
                }
              >
                <Options id="specialty_0" value="DEFAULT">
                  {`${translator('Todas')}`}
                </Options>
                {specialties.map(item => (
                  <Options
                    id={`specialty_${item.specialty?.id}`}
                    value={item.specialty?.id}
                  >
                    {item.specialty?.name}
                  </Options>
                ))}
              </Select>
            </InlineInputs>

            <InlineInputs>
              <p>{`${translator('Profissional')}:`}</p>
              <Input
                id="input_professional"
                height="40px"
                placeholder={`${translator('Digite o nome do profissional')}`}
                className="hover-input"
                width="307px"
                value={filter.professionalName}
                autoFocus
                onChange={e =>
                  handleSetFilter({
                    ...filter,
                    professionalName: e.target.value,
                  })
                }
              />
            </InlineInputs>

            <InlineInputs>
              <p>{`${translator('Modalidade')}:`}</p>
              <Select
                id="select_modality"
                height="40px"
                value={filter.modalityIs}
                placeholder={`${translator('Modalidade')}:`}
                width="307px"
                onChange={e =>
                  handleSetFilter({ ...filter, modalityIs: e.target.value })
                }
              >
                <Options id="modality_1" value="DEFAULT">
                  {`${translator('Todos')}`}
                </Options>
                <Options id="modality_2" value="REMOTE">
                  {`${translator('Remoto')}`}
                </Options>
                <Options id="modality_3" value="INPERSON">
                  {`${translator('Presencial')}`}
                </Options>
              </Select>
            </InlineInputs>

            <InlineInputs>
              <p>{`${translator('Documentos de apoio')}:`}</p>
              <Select
                id="select_support_doc"
                height="40px"
                value={`${filter.supportDocType}`}
                width="305px"
                placeholder={`${translator('Documentos de apoio')}`}
                onChange={e =>
                  handleSetFilter({ ...filter, supportDocType: e.target.value })
                }
              >
                <Options id="support_doc_1" value="DEFAULT">
                  {`${translator('Todos')}`}
                </Options>

                {supportDocTypes.map(item => (
                  <Options id={`support_doc_${item.id}`} value={item.name}>
                    {item.descr}
                  </Options>
                ))}
              </Select>
            </InlineInputs>

            <InlineInputs>
              <p>{`${translator('Documentos clínicos')}:`}</p>
              <Select
                id="select_clinical_doc"
                width="305px"
                height="40px"
                value={`${filter.clinicalDocType}`}
                placeholder={`${translator('Documentos clínicos')}`}
                onChange={e =>
                  handleSetFilter({
                    ...filter,
                    clinicalDocType: e.target.value,
                  })
                }
              >
                <Options id="clinical_doc_1" value="DEFAULT">
                  {`${translator('Todos')}`}
                </Options>

                {clinicalDocTypes.map(item => (
                  <Options id={`clinical_doc_${item.id}`} value={item.name}>
                    {item.descr}
                  </Options>
                ))}
              </Select>
            </InlineInputs>
          </ContentPatient>
        )}
      </ContainerForm>
      <ContainerButtons
        style={{ padding: role === 'CON' ? '12px 24px' : '24px' }}
      >
        <Button
          rounded
          variant="secundary"
          size="medium"
          height="40px"
          onClick={() => resetFilter()}
        >
          {`${translator('Redefinir')}`}
        </Button>
        <Button
          id="btn_search"
          rounded
          height="40px"
          variant="primary"
          size="medium"
          onClick={() => handleFilter()}
          autoFocus
        >
          {`${translator('Pesquisar')}`}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default FilterAppointmentHistory;
