import React, { useEffect } from 'react';

import { UploadFiles } from '~/presentation/components/uploadFiles';

import { iSupportDocs } from '../../pages/appointment/interface';

interface ownProps {
  supportingDocs?: iSupportDocs[];
}

const SupportingDocuments: React.FC<ownProps> = ({ supportingDocs }) => {
  return <UploadFiles docs={supportingDocs} />;
};

export default SupportingDocuments;
