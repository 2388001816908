/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iPrescription } from '~/presentation/components/simplePrescription/interface';
import MoreOptions from '~/presentation/components/simplePrescription/MoreOptions';
import { More } from '~/presentation/components/UI/popover';

import {
  ListDrugs,
  ContainerListDrugs,
  DrugsAndDosage,
  DosageAndOptions,
} from './styles/StyledMedication';

interface ItemDrugsProps {
  content?: Array<iPrescription>;
  setContent?: Function;
  updatePrescription?: Function;
  setUpdateButton?: Function;
}

const ItemDrugs: React.FC<ItemDrugsProps> = ({
  content,
  setContent,
  updatePrescription,
  setUpdateButton,
}): JSX.Element => {
  function deletePrescription(
    arrPrescription: Array<iPrescription>,
    id: number,
  ) {
    setContent?.(arrPrescription.filter(item => item.id !== id));
  }

  return (
    <ContainerListDrugs>
      {content?.map(item => (
        <ListDrugs key={item?.id}>
          <DrugsAndDosage>
            <h1>{item.name}</h1>
            <h2>{item.posology}</h2>
          </DrugsAndDosage>
          <DosageAndOptions>
            <h1>{item.dosage}</h1>
            <More
              content={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <MoreOptions
                  actionDelete={() => deletePrescription(content, item.id!)}
                  actionEdit={() => {
                    updatePrescription?.(item.id);
                    setUpdateButton?.(true);
                    AlertMessage({
                      message: 'Atualize os dados da sua prescrição.',
                      type: 'info',
                    });
                  }}
                />
              }
              positionY="top"
              positionX="left"
            />
          </DosageAndOptions>
        </ListDrugs>
      ))}
    </ContainerListDrugs>
  );
};

export default ItemDrugs;
