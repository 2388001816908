import React from 'react';
import { FolderIcon } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';

import { Container, Icon } from './styles/Styled';

interface ownProps {
  message: string;
}

const DocsEmpty: React.FC<ownProps> = ({ message }) => {
  return (
    <Container>
      <Icon>
        <FolderIcon />
      </Icon>
      {/* <p>OPS!</p> */}
      <text>{message}</text>
    </Container>
  );
};

export default DocsEmpty;
