import { GetAvailability } from '~/domain/usecases/professionalList/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGetAvailability implements GetAvailability {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAvailability.Model>;

  constructor(url: string, httpClient: HttpClient<GetAvailability.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  get = async (
    params: GetAvailability.Params,
  ): Promise<GetAvailability.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
