import { makeHttpClient, makeApiUrl } from '~/main/factories/http';
import { GetAll as UsecaseRemoteGetAllSelectedOrg } from '~/domain/usecases/selectedOrg/remote';
import { GetAllSelectedOrg as UseCaseReduxGetAllSelectedOrg } from '~/domain/usecases/selectedOrg/redux';

import { RemoteGetAll } from '~/data/repository/selectedOrg/RemoteGetAll';
import { ReduxGetAllSpecialty } from '~/data/store/reducer/selectedOrg/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllSelectedOrg = (): UsecaseRemoteGetAllSelectedOrg =>
  new RemoteGetAll(makeApiUrl('/orgs/list'), makeHttpClient());

/**
 * send request via Redux.
 */
export const makeReduxGetAllSelectedOrg = (): UseCaseReduxGetAllSelectedOrg =>
  new ReduxGetAllSpecialty();
