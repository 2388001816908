import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAppointmentIdRecording as UsecaseRemoteGetAppointIdRecording } from '~/domain/usecases/appointment/remote';
import { RemoteGetAppointmentIdRecording } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteGetAppointmentIdRecording =
  (): UsecaseRemoteGetAppointIdRecording =>
    new RemoteGetAppointmentIdRecording(
      makeApiUrl('/appointments'),
      makeHttpClient(),
    );
