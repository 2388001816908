import { components } from 'react-select';
import { IconArrowSelect } from '~/presentation/base/icons';

export const Option = ({ children: childrenOption, ...props }: any) => (
  <components.Option {...props} data-testid="option-participant">
    {childrenOption}
  </components.Option>
);

export const Control = ({ children: childrenControl, ...props }: any) => (
  <components.Control {...props} data-testid="input-participants">
    {childrenControl}
  </components.Control>
);

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <IconArrowSelect data-testid="icon-participants" />
    </components.DropdownIndicator>
  );
};
