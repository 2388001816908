import { GetDashboardProducts } from '~/domain/usecases/dashboard/redux';
import {
  iActionGetDashboardProducts,
  iActionGetDashboardProductsFailed,
  DashboardTypes,
  iActionGetDashboardProductsSuccess,
} from '~/domain/interfaces/redux/dashboard/getDashboardProducts';

export const getRequest = (
  payload: GetDashboardProducts.Params,
): iActionGetDashboardProducts => ({
  type: DashboardTypes.GET_PRODUCTS,
  payload,
});

export const getSuccess = (
  params: GetDashboardProducts.Model,
): iActionGetDashboardProductsSuccess => ({
  type: DashboardTypes.GET_PRODUCTS_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetDashboardProductsFailed => ({
  type: DashboardTypes.GET_PRODUCTS_FAILED,
});
