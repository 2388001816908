import { Dispatch } from 'redux';
import store from '~/data/store';
import { GetEmail } from '~/domain/usecases/users/redux/GetEmail';
import { iActionGetEmailSuccess } from '~/domain/interfaces/redux/users/getEmail';

import { getEmailSuccess } from '../actions/getEmail';

export class ReduxGetEmail implements GetEmail {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetEmail.Params): iActionGetEmailSuccess =>
    this.send(getEmailSuccess(params));
}
