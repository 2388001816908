import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface StyledProps {
  isSigned: Boolean;
}

export const Container = styled.div`
  display: flex;
  border: 1px solid ${defaultTheme.statusNone};
  border-radius: 5px;
  gap: 10px;
  /* grid-template-columns: 1fr 3fr 2fr; */
  padding: 5px;
  margin-bottom: 4px;
  height: 5em;

  -webkit-box-shadow: 4px 1px 12px 0px #efefef;
  -moz-box-shadow: 4px 1px 12px 0px #efefef;
  box-shadow: 4px 1px 12px 0px #efefef;
`;

export const Prefix = styled.div`
  display: flex;
  width: 20%;
  /* min-width: 80px; */
  border-right: 1px solid ${defaultTheme.statusNone};
  gap: 30px;
`;

export const Body = styled.div`
  display: flex;
  padding-left: 5px;
  width: 60%;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

export const Suffix = styled.div`
  display: flex;
  padding-left: 5px;
  align-items: center;
  width: 20%;
  justify-content: flex-end;
`;

export const Wrapper = styled.div<StyledProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ isSigned }) =>
    isSigned ? defaultTheme.primary : defaultTheme.statusNo};
  text {
    padding: 3px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    text {
      padding-left: 5px;
    }
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text {
      color: ${defaultTheme.typographyDarkGray};
    }
  }
`;

export const Title = styled.text<StyledProps>`
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  color: ${({ isSigned }) =>
    isSigned ? defaultTheme.primary : defaultTheme.statusNo};
`;

export const SubTitle = styled.text`
  font-size: 12px;
  padding-top: 6px;
  color: ${defaultTheme.typographyDarkGray};
`;
