import { GetAllClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/GetAllClinicalDocs';
import {
  ClinicalDocsTypes,
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
} from '~/domain/interfaces/redux/clinicalDocs/getAll';

export const getAllRequest = (
  payload: GetAllClinicalDocs.Params,
): iActionGetAll => ({
  type: ClinicalDocsTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllClinicalDocs.Model,
): iActionGetAllSuccess => ({
  type: ClinicalDocsTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: ClinicalDocsTypes.GETALL_FAILED,
});
