import { takeLatest } from 'redux-saga/effects';
import {
  onGetDashboardProductsFailed,
  onGetDashboardProductsSuccess,
  onGetDashboardProducts,
} from './GetDashboardProducts';
import {
  onGetDashboardUnits,
  onGetDashboardUnitsFailed,
  onGetDashboardUnitsSuccess,
} from './GetDashboardUnits';

import { DashboardTypes } from '~/domain/interfaces/redux/dashboard/types';

const tasks = [
  takeLatest(DashboardTypes.GET_PRODUCTS, onGetDashboardProducts),
  takeLatest(
    DashboardTypes.GET_PRODUCTS_SUCCESS,
    onGetDashboardProductsSuccess,
  ),
  takeLatest(DashboardTypes.GET_PRODUCTS_FAILED, onGetDashboardProductsFailed),

  takeLatest(DashboardTypes.GET_UNITS, onGetDashboardUnits),
  takeLatest(DashboardTypes.GET_UNITS_SUCCESS, onGetDashboardUnitsSuccess),
  takeLatest(DashboardTypes.GET_UNITS_FAILED, onGetDashboardUnitsFailed),
];

export default tasks;
