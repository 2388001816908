import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  UnexpectedError,
  Forbidden,
  NotFound,
} from '~/domain/errors';
import { PostUsersConfirmation } from '~/domain/usecases/createUserConfirmation/remote';
import '~/infra/global/variables';
import { History } from '~/main/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemotePostUsersConfirmation implements PostUsersConfirmation {
  private readonly url: string;

  private readonly httpClient: HttpClient<PostUsersConfirmation.Model>;

  constructor(url: string, httClient: HttpClient<PostUsersConfirmation.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  post = async (
    params: PostUsersConfirmation.Params,
  ): Promise<PostUsersConfirmation.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        AlertMessage({
          message: intl.formatMessage({ id: 'Criação de conta confirmada!' }),
          type: 'success',
        });
        // setTimeout(() => History.push('/'), 1200);
        return httpResponse.body;
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
