import styled from 'styled-components';

const StatusBoxColor: Record<string, string> = {
  INPROGRESS: '#dfeefa',
  CANCELED: '#FDE8E8',
  WAITING: '#E8F6EC',
  SCHEDULED: '#FFDEC7',
  ENDED: '#F8F8F8',
};

const StatusTextColor: Record<string, string> = {
  INPROGRESS: '#0A5D9E',
  CANCELED: '#A31313',
  WAITING: '#116427',
  SCHEDULED: '#C25200',
  ENDED: '#D8D8D8',
};

interface StatusProps {
  status: string;
}

export const Status = styled.div<StatusProps>`
  color: ${({ status }) => StatusTextColor[status]};
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  width: 115px;

  border-radius: 4px;
  background: ${({ status }) => StatusBoxColor[status]};

  display: inline-flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
`;
