import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Text = styled.div`
  margin-bottom: 10px;
  textarea {
    width: 100%;
    max-width: 950px;
    min-height: 498px;
    resize: none;
    padding: 10px;
    border: 1px solid #c7cbd1;
    box-sizing: border-box;
    border-radius: 3px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: #444a51;
    padding-bottom: 10px;
  }
`;
