import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import Socket from './index';

export const ConnectSocket: React.FC = ({ children }): JSX.Element => {
  const token = useSelector((store: iStore) => store.auth.access.token);
  const subs = (Socket.getSocket() as any)?.subs;

  if (token && (subs === undefined || subs.length < 1)) {
    Socket.connect(token);
  }

  return <>{children}</>;
};
