import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import InputMask from 'react-input-mask';

import { Container, Title } from './style/StyledContacts';

import Input from '../UI/input';

import { translator } from '../i18n';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';

interface ownProps {
  form: any;
  state: any;
  disableAutoFocus?: boolean;
}

const Contacts: React.FC<ownProps> = ({
  form,
  state,
  disableAutoFocus = false,
}) => {
  const { errors, register, setValue } = form;
  const [contacts, setContacts] = useState(state);
  const { loading } = useSelector((store: iStore) => store.users);
  const { profile } = useSelector((store: iStore) => store.showModal);

  useEffect(() => {
    makeReduxSetShowModal().set({ profile: false });
  }, []);

  useEffect(() => {
    setContacts(state);
  }, [state]);

  return (
    <Container>
      <Title>{translator('Contato')}</Title>
      <Input
        key={contacts.email}
        name="email"
        label={translator('E-mail')}
        height="40px"
        defaultValue={contacts.email}
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });

          setValue('email', e.target.value.trim());
        }}
        register={() => register('email')}
        error={Boolean(errors.email)}
        message={
          errors?.email?.message ? translator(errors?.email?.message) : ''
        }
        required
        autoFocus={!disableAutoFocus}
        disabled={loading}
      />
      <InputMask
        key={contacts.phone}
        mask="(99) 99999-9999"
        name="phone"
        ref={() => register('phone')}
        defaultValue={contacts.phone}
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });

          const phone = e.target.value.replace(/[^\d]+/g, '').trim();
          setValue('phone', phone);
        }}
        disabled={loading}
      >
        <Input
          name="phone"
          label={translator('Telefone celular')}
          height="40px"
          message={
            errors?.phone?.message ? translator(errors?.phone?.message) : ''
          }
          error={Boolean(errors.phone)}
          required
          disabled={loading}
        />
      </InputMask>
      <InputMask
        key={contacts.phone2}
        mask="(99) 99999-9999"
        name="phone2"
        defaultValue={contacts.phone2}
        ref={() => register('phone2')}
        onChange={e => {
          if (!profile) makeReduxSetShowModal().set({ profile: true });

          const phone2 = e.target.value.replace(/[^\d]+/g, '').trim();
          setValue('phone2', phone2);
        }}
        disabled={loading}
      >
        <Input
          name="phone2"
          label={translator('Telefone alternativo')}
          height="40px"
          disabled={loading}
        />
      </InputMask>
    </Container>
  );
};

export default Contacts;
