import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateClinicalDocs as UseCaseRemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { CreateClinicalDocs as UsecaseReduxCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteCreateClinicalDocs } from '~/data/repository/clinicalDocs';
import { ReduxCreateClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteCreateClinicalDocs =
  (): UseCaseRemoteCreateClinicalDocs =>
    new RemoteCreateClinicalDocs(makeApiUrl('/clinicalDocs'), makeHttpClient());

export const makeReduxCreateClinicalDocs = (): UsecaseReduxCreateClinicalDocs =>
  new ReduxCreateClinicalDocs();
