import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { RefuseOnCallRequester as UsecaseRemoteRefuseOnCallRequester } from '~/domain/usecases/onCallRequester/remote';
// import { RefuseOnCallRequester as UsecaseReduxRefuseOnCallRequester } from '~/domain/usecases/onCallRequester/redux';

import { RemoteRefuseOnCallRequester } from '~/data/repository/onCallRequester';
// import { ReduxRefuseOnCallRequester } from '~/data/store/reducer/onCallRequester/usecases';

/**
 * send request via API.
 */
export const makeRemoteRefuseOnCallRequester =
  (): UsecaseRemoteRefuseOnCallRequester =>
    new RemoteRefuseOnCallRequester(
      makeApiUrl(
        '/oncall/{onCallId}/requesters/{requesterId}/requests/{requestId}/REFUSE',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxRefuseOnCallRequester =
  (): UsecaseReduxRefuseOnCallRequester =>
    new ReduxRefuseOnCallRequester(); */
