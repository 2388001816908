import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Popover from './Popover';

interface ownProps {
  content: JSX.Element;
  elevation?: number;
  originX?: 'left' | 'center' | 'right';
  originY?: 'top' | 'center' | 'bottom';
  positionX?: 'left' | 'center' | 'right';
  positionY?: 'top' | 'center' | 'bottom';
  rotate?: boolean;
}

const More: React.FC<ownProps> = (props): JSX.Element => {
  return (
    <Popover {...props}>
      <IconButton
        aria-label="more"
        style={{ transform: props.rotate ? undefined : 'rotate(90deg)' }}
      >
        <MoreVertIcon id="btn_moreOptions" />
      </IconButton>
    </Popover>
  );
};

More.defaultProps = {
  originX: 'right',
  originY: 'top',
  positionX: 'right',
  positionY: 'bottom',
};

export default More;
