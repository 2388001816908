import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* h3 {
    color: #7a7a7a;
  } */
`;

export const Icon = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .Icons {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  margin-left: 10px;
`;

export const Time = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #4f4f4f;
`;

export const DayWeek = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 14px;
  color: #7a7a7a;
`;
