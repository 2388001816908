/* eslint-disable no-param-reassign */
import { UpdateUser } from '~/domain/usecases/users/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';

export class RemoteUpdateUser implements UpdateUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateUser.Model>;

  constructor(url: string, httClient: HttpClient<UpdateUser.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (params: UpdateUser.Params): Promise<UpdateUser.Model> => {
    const { id } = params;
    delete params.id;
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'patch',
      body: params.avatar ? params.avatar : params,
      headers: {
        'Content-Type': params.avatar
          ? 'multipart/form-data'
          : 'application/json',
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
