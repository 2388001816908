import { GetAllAdministrator } from '~/domain/usecases/administrator/redux/GetAllAdministrator';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  AdministratorTypes,
} from '~/domain/interfaces/redux/administrator/getAll';

export const getAllRequest = (
  payload: GetAllAdministrator.Params,
): iActionGetAll => ({
  type: AdministratorTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllAdministrator.Model,
): iActionGetAllSuccess => ({
  type: AdministratorTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: AdministratorTypes.GETALL_FAILED,
});
