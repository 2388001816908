import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { IconOrg } from '~/presentation/base/icons';

import { makeReduxSetData } from '~/main/factories/usecases/message/SetData';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxRefresh } from '~/main/factories/usecases/auth/RefreshFactory';
import { makeReduxSelectUser } from '~/main/factories/usecases/auth/SelectUserFactory';
import { closeModal } from '~/utils/closeModal';
import History from '~/main/routes/History';
import { ReduxLogout } from '~/data/store/reducer/auth/usecases/ReduxLogout';
import { translator } from '../i18n';
import { ConnectComponent } from './mapper/MapperChangeRoles';
import {
  Container,
  Body,
  Itens,
  Text,
  Orgs,
  Title,
  RolesOverflow,
} from './styles/StyledChangeRoles';
import { iOrgs } from './interface';
import OrgItem from './OrgItem';

export interface ownProps {
  orgs: iOrgs[];
  orgId: number | undefined;
  role: 'ORG' | 'PRO' | 'CON' | 'ADM';
  data: any;
}

const ChangeRole: React.FC<ownProps> = ({
  orgs,
  orgId,
  role,
  data,
}): JSX.Element => {
  const path = window.location.pathname;
  const roleName = {
    PRO: translator('Profissional'),
    ORG: translator('Organizador'),
    CON: translator('Paciente'),
    ADM: translator('Administrador'),
  };

  const sUser = useSelector((store: iStore) => store.auth.selectUser);
  const messageData = useSelector((store: iStore) => store.message.data);
  const {
    orgs: authOrgs,
    info,
    refresh,
  } = useSelector((store: iStore) => store.auth);

  const messageOrgId =
    messageData.orgId === undefined ? sUser.orgId : messageData.orgId;

  const expiration = useMemo(() => {
    const temp = authOrgs?.[0]?.expiration ?? null;

    if (temp) {
      return Math.floor(
        (new Date(temp).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
      );
    }

    return temp;
  }, [authOrgs]);

  return (
    <Container>
      <Body>
        {path !== '/' ? (
          <Text>
            {translator('Atualmente você está como')}
            <strong>{` ${roleName[role]} `}</strong>
            {translator('em')}
            <strong>
              {/* TODO: Observar porque que ele vem undefined após um periodo sem utilizar a plataforma */}
              {` ${orgs[orgs.findIndex(el => el.id === orgId)]?.name} `}
            </strong>
          </Text>
        ) : (
          ''
        )}
        {path !== '/' ? (
          <Text>
            {translator(
              'Para alterar sua função, selecione o papel em uma organização',
            )}
          </Text>
        ) : (
          <Text>{translator('Defina seu papel na organização desejada.')}</Text>
        )}
        <RolesOverflow>
          {orgs.map(value => (
            <Orgs key={value.id}>
              {[0].map(v => {
                const orgUnit = value.orgUnits?.[0];

                return (
                  <Itens
                    selected={messageOrgId === value.id}
                    onClick={() => {
                      makeReduxSetData().setData({
                        data: {
                          orgUnitId: Number(orgUnit?.id),
                          orgId: value.id,
                          roleId: orgUnit?.roles?.[0]?.id,
                          role: orgUnit?.roles?.[0]?.role,
                        },
                      });

                      const url = window.location.pathname;

                      makeReduxActiveMessage().active({
                        actionOk: () => {
                          makeReduxRefresh().refresh({
                            user: info.user?.id,
                            org: value.id,
                            refreshToken: refresh.token,
                          });
                          makeReduxSelectUser().update({
                            orgUnitId: Number(orgUnit?.id),
                            orgId: value.id,
                            orgUnits: value.orgUnits,
                          });
                          closeModal();
                          if (url === '/') History.push('/appointment');
                        },
                        actionCancel: () => {
                          closeModal();
                          if (url === '/') new ReduxLogout().logout();
                        },
                      });
                    }}
                  >
                    <Title
                      selected={
                        (value.id === orgId && data.orgId === undefined) ||
                        value.id === data.orgId
                      }
                    >
                      <IconOrg />
                      {` ${value.name}`}
                    </Title>
                    {value.orgUnits?.map(item => {
                      return (
                        <OrgItem
                          orgUnitId={Number(item.id)}
                          title={item.name}
                          roles={item.roles}
                          orgId={value.id}
                          displayUnitTitle={!expiration}
                        />
                      );
                    })}
                  </Itens>
                );
              })}
            </Orgs>
          ))}
        </RolesOverflow>
      </Body>
    </Container>
  );
};

export default ConnectComponent(ChangeRole);
