import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { GetAllProfessional as UsecaseRemoteGetAllProfessional } from '~/domain/usecases/professionalList/remote';
import { GetAllProfessional as UsecaseReduxGetAllProfessional } from '~/domain/usecases/professionalList/redux';

import { RemoteGetAllProfessional } from '~/data/repository/professionalList';
import { ReduxGetAllProfessional } from '~/data/store/reducer/Professional/usecases/ReduxGetAllProfessional';

/**
 * send request via API.
 */
export const makeRemoteGetAllProfessionals =
  (): UsecaseRemoteGetAllProfessional =>
    new RemoteGetAllProfessional(
      makeApiUrl('/professionals/list'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxGetAllProfessionals =
  (): UsecaseReduxGetAllProfessional => new ReduxGetAllProfessional();
