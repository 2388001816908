import { GetShiftsById } from '~/domain/usecases/shifts/redux';

import {
  ShiftsTypes,
  iActionGetShiftsById,
  iActionGetShiftsByIdFailed,
  iActionGetShiftsByIdSuccess,
} from '~/domain/interfaces/redux/shifts/getById';

export const getShiftsByIdRequest = (
  payload: GetShiftsById.Params,
): iActionGetShiftsById => ({
  type: ShiftsTypes.GET_BY_ID,
  payload,
});

export const getShiftsByIdSuccess = (
  params: GetShiftsById.Model,
): iActionGetShiftsByIdSuccess => ({
  type: ShiftsTypes.GET_BY_ID_SUCCESS,
  payload: params,
});

export const getShiftsByIdFailed = (): iActionGetShiftsByIdFailed => ({
  type: ShiftsTypes.GET_BY_ID_FAILED,
});
