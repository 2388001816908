import { Dispatch } from 'redux';
import store from '~/data/store';
import {
  ShiftsTypes,
  iActionListShifts,
} from '~/domain/interfaces/redux/shifts/list';

import { ListShifts } from '~/domain/usecases/shifts/redux';
import { listShiftsRequest } from '../actions/list';

export class ReduxListShifts implements ListShifts {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListShifts.Params): iActionListShifts =>
    this.send(listShiftsRequest(params));

  setOnCallId = (onCallId: number): void => {
    this.send({
      type: ShiftsTypes.SET_ON_CALL_ID,
      payload: onCallId,
    });
  };

  setDate = (date: string): void => {
    this.send({
      type: ShiftsTypes.SET_DATE,
      payload: date,
    });
  };
}
