import React, { Children } from 'react';

import { translator } from '../i18n';
import {
  CardInfoContainer,
  ConsultInfo,
  DataContent,
  DataContentTitle,
  TextBold,
  TextBoldTitle,
  TextNormal,
} from './styles/StyledConsultationData';
import ConsultDetailFragment from './ConsultDetailFragment';

interface ConsultDetailCardProps {
  title?: string;
  children?: JSX.Element[] | JSX.Element;
}

const ConsultDetailCard: React.FC<ConsultDetailCardProps> = ({
  title = '',
  children,
}) => {
  return (
    <DataContent>
      <DataContentTitle>
        <TextBoldTitle>{title ?? ''}</TextBoldTitle>
      </DataContentTitle>
      <CardInfoContainer>{children ?? <></>}</CardInfoContainer>
    </DataContent>
  );
};
export default ConsultDetailCard;
