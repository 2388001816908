import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Tags, Typography } from '@wisecare/design-system-web';
import { DiagnosisReportListRecords } from '~/domain/usecases/diagnosisReport/remote';
import { timeAgo } from '~/utils/getTimeAgo';
import {
  calculateAgeLaudo,
  formatISODateTimeLaudo,
} from '~/utils/tableDateLaudo';
import Actions from './actions';
import { Information } from './styles';
import { findLastOccurrence } from '~/utils/laudoLastOccurrence';

export const columns_delayed: ColumnDef<DiagnosisReportListRecords>[] = [
  {
    accessorKey: 'consultant',
    header: ({ column }) => <SortButton column={column}>Paciente</SortButton>,
    cell: ({ row }) => {
      const data = row.getValue(
        'consultant',
      ) as DiagnosisReportListRecords['consultant'];

      const value = row.original;

      const consultantName = data ? `${data?.firstName} ${data?.lastName}` : '';

      const consultantExternal = value?.consultantExternal
        ? `${value?.consultantExternal?.fullname}`
        : '';

      return (
        <Information>
          <Typography variant="b4_14regular">
            {consultantName.length > 0 ? consultantName : consultantExternal}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {calculateAgeLaudo(
              data?.birthdate ?? value?.consultantExternal?.birthdate,
            )}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'modality',
    header: ({ column }) => {
      return <SortButton column={column}>Modalidade do laudo</SortButton>;
    },
    cell: ({ row }) => {
      const modality = row.getValue(
        'modality',
      ) as DiagnosisReportListRecords['modality'];

      return <Typography variant="b4_14regular">{modality}</Typography>;
    },
  },
  {
    accessorKey: 'requester',
    header: ({ column }) => (
      <SortButton column={column}>Solicitante</SortButton>
    ),
    cell: ({ row }) => {
      const requester = row.getValue(
        'requester',
      ) as DiagnosisReportListRecords['requester'];

      const rowOriginal = row.original as DiagnosisReportListRecords;

      return (
        <Information>
          <Typography variant="b4_14regular">{requester?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {rowOriginal.healthUnit.name}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'specialist',
    header: ({ column }) => (
      <SortButton column={column}>Designado para</SortButton>
    ),
    cell: ({ row }) => {
      const specialist = row.getValue(
        'specialist',
      ) as DiagnosisReportListRecords['specialist'];

      const rowOriginal = row.original as DiagnosisReportListRecords;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {specialist?.fullname ?? '-'}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {rowOriginal?.specialty.name}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'requested_at',
    header: ({ column }) => (
      <SortButton column={column}>Solicitado em</SortButton>
    ),
    cell: ({ row }) => {
      const history = row.getValue(
        'history',
      ) as DiagnosisReportListRecords['history'];

      const lastOccurence = findLastOccurrence(history, 'SUBMITTED');

      const lastOccurrenceDate = formatISODateTimeLaudo(
        lastOccurence?.timestamp,
      );

      return (
        <Information>
          <Typography variant="b4_14regular">
            {lastOccurrenceDate.date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {lastOccurrenceDate.hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'history',
    header: ({ column }) => (
      <SortButton column={column}>Atrasado há</SortButton>
    ),
    cell: ({ row }) => {
      const history = row.getValue(
        'history',
      ) as DiagnosisReportListRecords['history'];

      return (
        <Tags
          variant="red"
          text={history?.[0]?.timestamp ? timeAgo(history[0]?.timestamp) : ''}
        />
      );
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <Actions documentId={value.id} actions={value.actions} />
        </div>
      );
    },
  },
];
