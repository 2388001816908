import { iAppointmentPrioritiesState } from '~/domain/interfaces/models/AppointmentPriorities';
import { AppointmentPrioritiesTypes } from '~/domain/interfaces/redux/appointmentPriorities/types';
import { AppointmentTypeActions } from './actions';

export const initialState: iAppointmentPrioritiesState = {
  loading: false,
  data: {} as iAppointmentPrioritiesState['data'],
};

const reducer = (
  state = initialState,
  action: AppointmentTypeActions,
): iAppointmentPrioritiesState => {
  switch (action.type) {
    case AppointmentPrioritiesTypes.GET_ALL:
      return { ...state, loading: true };
    case AppointmentPrioritiesTypes.GET_ALL_FAILED:
      return { ...state, loading: false };
    case AppointmentPrioritiesTypes.GET_ALL_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reducer;
