import styled from 'styled-components';

export const Content = styled.div`
  width: 88px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;
