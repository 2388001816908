import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  max-height: 612px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 8px;
  & .my-appointment-card:nth-child(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  /* & .my-appointment-card:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  } */
`;

export const Text = styled.div`
  color: #222529;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 16px;
`;

export const PatientText = styled.div`
  color: #818385;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CardBox = styled.div`
  /* max-height: 600px; */
  overflow-y: auto;
  height: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
`;

export const EmptyState = styled.div`
  text-align: center;
  color: #818385;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    width: 261px;
    white-space: break-spaces;
  }
`;
