import { Dispatch } from 'redux';
import store from '~/data/store';
import { chatMessageRequest } from '~/data/store/reducer/chat/actions/chatMessage';

import { iActionChatMessage } from '~/domain/interfaces/redux/chat/chatMessage';

import { ChatMessage } from '~/domain/usecases/chat/redux';

export class ReduxChatMessage implements ChatMessage {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  add = (params: ChatMessage.Params): iActionChatMessage =>
    this.send(chatMessageRequest(params));
}
