import { call, put, select } from 'redux-saga/effects';
import { iActionCreate } from '~/domain/interfaces/redux/Consultant/create';
import { CreateConsultant } from '~/domain/usecases/consultant/remote/CreateConsultant';
import { makeRemoteCreateConsultant } from '~/main/factories/usecases/consultant/CreateConsultantFactory';
import {
  createSuccess,
  createFailed,
} from '~/data/store/reducer/Consultant/actions/create';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { History } from '~/main/routes';
import { closeModal } from '~/utils/closeModal';

export function* onCreate(action: iActionCreate) {
  const remoteCreateConsultant = makeRemoteCreateConsultant();

  try {
    const response: CreateConsultant.Model = yield call(
      remoteCreateConsultant.create,
      action.payload,
    );

    makeReduxActiveMessage().active({
      active: MessageOptions.userCreateSuccess,
      actionOk: () => {
        History.back();
        closeModal();
      },
    });

    yield put(
      createSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateFailed() {}
