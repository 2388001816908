import { Dispatch } from 'redux';
import { CreateUser } from '~/domain/usecases/users/redux';
import store from '~/data/store';
import { createRequest } from '~/data/store/reducer/users/actions/create';

import { iActionCreate } from '~/domain/interfaces/redux/users/create';

export class ReduxCreateUser implements CreateUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateUser.Params): iActionCreate =>
    this.send(createRequest(params));
}
