import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
  z-index: 3;
`;

export const ContainerMain = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 15px;
`;
