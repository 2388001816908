/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import AuditRecordPage from '~/presentation/pages/AuditRecord';
import { makeReduxAuditRecord } from '../../usecases/AuditRecord/GetAuditRecordFactory';
import { makeReduxDataPagination } from '../../usecases/dataPagination/SetDataFactory';

const AuditRecordFactory: React.FC = () => {
  const { orgs } = useSelector((store: iStore) => store.auth);

  const loadAuditRecord = useCallback(() => {
    makeReduxAuditRecord().list({
      user: {
        org: orgs[0].id,
      },
      dataControl: {
        paging: true,
        limit: 9999,
      },
    });
  }, [orgs]);

  useEffect(() => {
    loadAuditRecord();
    makeReduxDataPagination().set([]);
  }, [loadAuditRecord]);

  return <AuditRecordPage />;
};

export default AuditRecordFactory;
