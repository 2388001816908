import { GetAllClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGetAllClinicalDocs implements GetAllClinicalDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllClinicalDocs.Model>;

  constructor(url: string, httClient: HttpClient<GetAllClinicalDocs.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (
    params: GetAllClinicalDocs.Params,
  ): Promise<GetAllClinicalDocs.Model> => {
    // let query = '';
    // Object.entries(params).forEach(([key, value]) => {
    //   query += `${key}=${value}&`;
    // });

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
