import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  margin-top: 20%;
  gap: 40px;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.statusCancelBack};
`;

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
