import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Visualization = styled.div`
  color: #222529;
  /* b3_14regular */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 8px;
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-left: 14px;
`;

export const LeftSide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const Box = styled.div`
  height: 40px;
  border-radius: 6px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

export const DateText = styled.div`
  color: #222529;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 110px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ButtonFilterProps = {
  isFilterActive: boolean;
};

export const ButtonFilter = styled.button<ButtonFilterProps>`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0.13rem;
  width: 2.125rem;
  height: 2.125rem;

  border-radius: 0.38rem;

  & svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  transition: background 0.2s ease-in-out;
  position: relative;

  &:disabled {
    cursor: default;
    & svg path {
      fill: #b4b7b9;
    }
  }

  &:hover:not(:disabled) {
    background: #ecedee;
  }

  &:focus-visible {
    background: #fdfdfd;
    outline: 0.2rem solid #3d9ffd;

    box-shadow: 0px 0px 12px 0px #3a8aea;
  }

  ${({ isFilterActive }) =>
    isFilterActive &&
    css`
      &::after {
        content: '';
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 50%;
        background-color: #e20613;
        top: 15%;
        right: 10%;
      }
    `}
`;
