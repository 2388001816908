import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/clinicalDocs/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/clinicalDocs/getAll';

import { GetAllClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

export class ReduxGetAllClinicalDocs implements GetAllClinicalDocs {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllClinicalDocs.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
