import { Dispatch } from 'redux';
import store from '~/data/store';
import { createRequest } from '~/data/store/reducer/organizer/actions/create';

import { iActionCreate } from '~/domain/interfaces/redux/Organizer/create';

import { CreateOrganizer } from '~/domain/usecases/organizer/redux';

export class ReduxCreateOrganizer implements CreateOrganizer {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateOrganizer.Params): iActionCreate =>
    this.send(createRequest(params));
}
