import { call, put } from 'redux-saga/effects';
import { iActionGetInvoiceService } from '~/domain/interfaces/redux/billing/getInvoiceService';
import { GetInvoiceService } from '~/domain/usecases/billing/remote/GetInvoiceService';
import {
  getInvoiceServiceSuccess,
  getInvoiceServiceFailed,
} from '~/data/store/reducer/billing/actions/getInvoiceService';

import { makeRemoteGetInvoiceService } from '~/main/factories/usecases/billing/GetInvoiceService';

export function* onGetInvoiceService(action: iActionGetInvoiceService) {
  const remoteGetInvoicecService = makeRemoteGetInvoiceService();

  try {
    const response: GetInvoiceService.Model = yield call(
      remoteGetInvoicecService.getBillingInvoiceService,
      action.payload,
    );

    yield put(getInvoiceServiceSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getInvoiceServiceFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetInvoiceServiceSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetInvoiceServiceFailed() {}
