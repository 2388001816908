import styled, { css } from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface IStyledUploadFilesProps {
  disabled?: boolean;
}

export const StyleInput = styled.div<IStyledUploadFilesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  height: 39px;
  width: 300px;
  border: 1px solid #004ba5;
  border-radius: 6px;
  background: #1756e8;
  color: white;
  gap: 8px;
  transition: opacity 0.2s;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  font-style: normal;
  &:hover {
    background-color: #006ef2;
    color: white;
    transition: all 0.3s;
  }
  &:active {
    background-color: #004ba5;
    transition: 0.2s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Plus = styled.div`
  width: 25px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-right: 10px;
`;

interface iContainer {
  size?: string;
  disabled?: boolean;
}

export const Container = styled.label<iContainer>`
  display: flex;
  margin-top: 8px;
  margin-bottom: 9px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${defaultTheme.highlight};
  width: 240px;
  height: 22px;
  cursor: pointer;

  :hover {
    background-color: ${defaultTheme.primaryLight};
    transition: 0.5s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
      background-color: transparent;
    `}
`;
