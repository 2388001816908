import React from 'react';
import { ClinicalDoc } from '~/presentation/components/clinicalDoc';
import { Menu } from '~/presentation/components/menu';
import { HeaderSimples } from '~/presentation/components/header';
import { IconHome } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';
import { Container, Body, LockScroll } from './styles/StyledClinical';

const ClinicalPage: React.FC = () => {
  return (
    <Container>
      <LockScroll>
        <Menu />
        <HeaderSimples
          title={translator('Documentos clínicos')}
          icon={IconHome}
        />
      </LockScroll>

      <Body>
        <div id="clinicalContent">
          <ClinicalDoc />
        </div>
      </Body>
    </Container>
  );
};

export default ClinicalPage;
