/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { Tooltip } from '@material-ui/core';
import { IconAppointmentHistory } from '~/presentation/base/icons';
import { translator } from '../i18n';
import {
  ActionButton,
  Actions,
  Cancel,
  CopyLink,
  Dashboard,
  Delete,
  Details,
  Download,
  EditIcon,
  Home,
  NewDocument,
  Person,
  ResendEmailConfirmation,
  SendWhats,
  ShareEmail,
  Start,
  Stop,
  Transcription,
  Wait,
} from './styles/StyledActions';

interface ownProps {
  actions?: {
    details?: () => any;
    cancel?: () => any;
    person?: () => any;
    dashboard?: () => any;
    editSector?: () => any;
    delete?: () => any;
    deleteSector?: () => any;
    start?: () => any;
    stop?: () => any;
    wait?: () => any;
    menuInfo?: () => any;
    newDocument?: () => any;
    shareEmail?: () => any;
    home?: () => any;
    download?: () => any;
    deleteSpecialty?: () => any;
    editSpecialty?: () => any;
    deleteClinicalDocument?: () => any;
    copyLink?: () => any;
    sendWhats?: () => any;
    resendEmail?: () => any;
    resendConfirmation?: () => any;
    viewAudit?: () => void;
    appointmentHistory?: () => void;
    downloadTranscription?: () => void;
    deleteOnCall?: () => void;
    editOnCall?: () => void;
  };
}

const ActionsList: React.FC<ownProps> = ({ actions }) => {
  return (
    <Actions>
      {actions?.start && (
        <Tooltip
          title={`${translator('Iniciar atendimento')}`}
          placement="top"
          arrow
          onClick={actions.start}
        >
          <div>
            <Start id="start_appointment" />
          </div>
        </Tooltip>
      )}

      {actions?.home && (
        <Tooltip
          title={`${translator('Acessas farmácia')}`}
          placement="top"
          onClick={actions.home}
          arrow
        >
          <Home id="pharmacy_access" />
        </Tooltip>
      )}

      {actions?.details && (
        <Tooltip
          title={`${translator('Detalhes')}`}
          placement="top"
          arrow
          onClick={actions.details}
        >
          <div>
            <Details id="details" />
          </div>
        </Tooltip>
      )}

      {actions?.downloadTranscription && (
        <Tooltip
          title="Download da transcrição"
          placement="top"
          arrow
          onClick={actions.downloadTranscription}
        >
          <div>
            <Transcription id="transcription" />
          </div>
        </Tooltip>
      )}

      {actions?.person && (
        <Tooltip
          title={`${translator('Ver perfil')}`}
          placement="top"
          arrow
          onClick={actions.person}
        >
          <div>
            <Person id="profile_details" />
          </div>
        </Tooltip>
      )}

      {actions?.download && (
        <Tooltip
          title={`${translator('Baixar documento')}`}
          placement="top"
          onClick={actions.download}
          arrow
        >
          <Download id="download_document" />
        </Tooltip>
      )}

      {actions?.wait && (
        <Tooltip
          title={`${translator('Ir para sala de espera')}`}
          placement="top"
          onClick={actions.wait}
          arrow
        >
          <div>
            <Wait id="join_waitingRoom" />
          </div>
        </Tooltip>
      )}

      {actions?.shareEmail && (
        <Tooltip
          title={`${translator('Compartilhar por email')}`}
          placement="top"
          onClick={actions.shareEmail}
          arrow
        >
          <div>
            <ShareEmail id="share_email" />
          </div>
        </Tooltip>
      )}

      {actions?.newDocument && (
        <Tooltip
          title={`${translator('Novo documento clínico')}`}
          placement="top"
          onClick={actions.newDocument}
          arrow
        >
          <div>
            <NewDocument id="new_clinicalDocs" />
          </div>
        </Tooltip>
      )}

      {actions?.dashboard && (
        <Tooltip
          title={`${translator('Painel')}`}
          placement="top"
          arrow
          onClick={actions.dashboard}
        >
          <div>
            <Dashboard id="start_appointment" />
          </div>
        </Tooltip>
      )}

      {actions?.editSector && (
        <Tooltip
          title={`${translator('Editar setor')}`}
          placement="top"
          arrow
          onClick={actions.editSector}
        >
          <div>
            <EditIcon id="edit_sector" />
          </div>
        </Tooltip>
      )}

      {actions?.delete && (
        <Tooltip
          title={`${translator('Excluir usuario')}`}
          placement="top"
          arrow
          onClick={actions.delete}
        >
          <div>
            <Delete id="profile_delete" />
          </div>
        </Tooltip>
      )}

      {actions?.deleteSector && (
        <Tooltip
          title={`${translator('Excluir setor')}`}
          placement="top"
          arrow
          onClick={actions.deleteSector}
        >
          <div>
            <Delete id="delete_sector" />
          </div>
        </Tooltip>
      )}

      {actions?.cancel && (
        <Tooltip
          title={`${translator('Cancelar atendimento')}`}
          placement="top"
          onClick={actions.cancel}
          arrow
        >
          <div>
            <Cancel id="cancel_appointment" />
          </div>
        </Tooltip>
      )}
      {actions?.stop && (
        <Tooltip
          title={`${translator('Finalizar atendimento')}`}
          placement="top"
          onClick={actions.stop}
          arrow
        >
          <Stop id="finish_appointment" />
        </Tooltip>
      )}
      {actions?.editSpecialty && (
        <Tooltip
          title={`${translator('Editar especialidade')}`}
          placement="top"
          onClick={actions.editSpecialty}
          arrow
        >
          <div>
            <EditIcon id="edit_specialty" />
          </div>
        </Tooltip>
      )}
      {actions?.editOnCall && (
        <Tooltip
          title={`${translator('Editar escala')}`}
          placement="top"
          onClick={actions.editOnCall}
          arrow
        >
          <div>
            <EditIcon id="edit_on_call" />
          </div>
        </Tooltip>
      )}
      {actions?.deleteSpecialty && (
        <Tooltip
          title={`${translator('Excluir especialidade')}`}
          placement="top"
          onClick={actions.deleteSpecialty}
          arrow
        >
          <div>
            <Delete id="delete_specialty" />
          </div>
        </Tooltip>
      )}
      {actions?.deleteOnCall && (
        <Tooltip
          title={`${translator('Excluir escala')}`}
          placement="top"
          onClick={actions.deleteOnCall}
          arrow
        >
          <div>
            <Delete id="delete_on_call" />
          </div>
        </Tooltip>
      )}
      {actions?.copyLink && (
        <Tooltip
          title={`${translator('Copiar link de atendimento')}`}
          placement="top"
          arrow
          onClick={actions.copyLink}
        >
          <div>
            <CopyLink id="copy_link" />
          </div>
        </Tooltip>
      )}
      {actions?.resendEmail && (
        <Tooltip
          title={`${translator('Reenviar email')}`}
          placement="top"
          arrow
          onClick={actions.resendEmail}
        >
          <div>
            <ShareEmail id="resend_email" />
          </div>
        </Tooltip>
      )}
      {actions?.resendConfirmation && (
        <Tooltip
          title={`${translator('Reenviar email de confirmação')}`}
          placement="top"
          arrow
          onClick={actions.resendConfirmation}
        >
          <div>
            <ResendEmailConfirmation id="resend_confirmation" />
          </div>
        </Tooltip>
      )}
      {actions?.sendWhats && (
        <Tooltip
          title={`${translator('Enviar convite por Whatsapp')}`}
          placement="top"
          arrow
          onClick={actions.sendWhats}
        >
          <div>
            <SendWhats id="send_whats" />
          </div>
        </Tooltip>
      )}
      {actions?.viewAudit && (
        <Tooltip
          title={translator('Detalhes da auditoria')}
          placement="top"
          arrow
          onClick={actions?.viewAudit}
        >
          <div>
            <Details data-testid="audit_details" id="audit_details" />
          </div>
        </Tooltip>
      )}
      {actions?.deleteClinicalDocument && (
        <Tooltip
          title={`${translator('Excluir documento')}`}
          placement="top"
          onClick={actions.deleteClinicalDocument}
          arrow
        >
          <div>
            <Delete />
          </div>
        </Tooltip>
      )}
      {actions?.appointmentHistory && (
        <Tooltip
          title={`${translator('Histórico de atendimentos')}`}
          placement="top"
          onClick={actions.appointmentHistory}
          arrow
        >
          <ActionButton>
            <IconAppointmentHistory />
          </ActionButton>
        </Tooltip>
      )}
    </Actions>
  );
};
export default ActionsList;
