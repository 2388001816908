import React, { useEffect } from 'react';
import { SelectSpecialtyPage } from '~/presentation/pages';
import { makeReduxSetSpecialistStatusOnCall } from '../../usecases/onCall/SetSpecialistStatusOnCallFactory';

export const SelectSpecialtyFactory: React.FC = () => {
  useEffect(() => {
    makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
      available: false,
    });
  }, []);

  return <SelectSpecialtyPage />;
};
