import React, { useEffect, useState } from 'react';
import * as datefns from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import { format, parseISO, isBefore } from 'date-fns';
import { useSelector } from 'react-redux';
import { schemaDateAndTime } from '~/validation/validators/appointment/CreateAppointmentValidator';
import { iStore } from '~/domain/interfaces/models';
import { Select } from '../UI';

import { Container, Form, Content } from './styles/StyledDateAndTime';

import { translator } from '../i18n';
import InputKeyboardDate from '../inputKeyboardDate';
import { iRegisterAppointment } from './interface';

import { Navigator } from '../register/Navigator';
import { makeRemoteGetAvailability } from '~/main/factories/usecases/professional/GetAvailabilityFactory';

interface ownProps {
  next: (data: iRegisterAppointment) => any;
  back: (data: iRegisterAppointment) => any;
  state: iRegisterAppointment;

  getTime: (e: string) => typeArrayTime[];
}
export interface typeArrayTime {
  time: string;
  timeFormat: string;
  disabled: boolean;
}
const DateAndTime: React.FC<ownProps> = ({
  state,
  next,
  getTime,
  back,
}): JSX.Element => {
  const {
    errors,
    handleSubmit,
    getValues,
    setValue,
    register,
    control,
    watch,
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaDateAndTime),
    defaultValues: {
      ...state,
    },
  });
  const labelTimeEnd = translator('Hora fim');
  const labelTimeStart = translator('Hora início');
  const selectDate = useSelector((store: iStore) =>
    datefns.format(
      store.appointment.date instanceof Date
        ? store.appointment.date
        : new Date(store.appointment.date),
      'yyyy-MM-dd',
    ),
  );

  const [selectedFilterDate, setSelectedFilterDate] =
    useState<string>(selectDate);
  const [timesEnd, setTimesEnd] = useState<typeArrayTime[]>([]);
  const [defaultStart, setDefaultStart] = useState<string>(
    state.hourStart || '',
  );
  const [defaultEnd, setDefaultEnd] = useState<string>(state.hourEnd || '');
  const [dateIsoString, setDate] = useState<string>(state?.date || '');
  const [oldDate, setOldDate] = useState<string>(state.date || '');

  const [times, setTimes] = useState<typeArrayTime[]>([
    ...getTime(dateIsoString),
  ]);

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  const handleChange = (event: Date) => {
    const isoString = event.toISOString();
    const isoStringWithTimezone = isoString.replace(
      '000Z',
      `${event.getTimezoneOffset()}Z`,
    );

    setDate(isoStringWithTimezone);
  };
  const onBack = () => back({ ...getValues() });

  useEffect(() => {
    if (state.hourStart === state.hourEnd) {
      setValue('hourStart', '');
      setValue('hourEnd', '');
    }

    if (defaultStart !== defaultEnd) {
      setUpTimesEnd(defaultStart);
    }

    if (dateIsoString !== oldDate) {
      setOldDate(dateIsoString);
      const newDate = new Date();
      setDefaultStart(newDate.toISOString());
      setTimesEnd([]);
      setValue('hourStart', '');
      setValue('hourEnd', '');
    }
  }, [dateIsoString, selectedFilterDate]);

  const setUpTimesEnd = (event: string) => {
    const timeStart = new Date(event);
    const professionals =
      (state.professionals
        ?.map(item => item.id ?? null)
        .filter(Boolean) as number[]) ?? [];

    if (timeStart)
      makeRemoteGetAvailability()
        .get({
          timestamp: {
            begin: timeStart.toISOString(),
            end: datefns.endOfDay(new Date(timeStart)).toISOString(),
          },
          professionals: professionals,
        })
        .then(res => {
          let disableAllNext = false;

          const formatTimes = res.availability
            ?.map(item => {
              if (item.end === timeStart.toISOString()) return;

              const utcEnd = parseISO(item.end);

              if (
                !disableAllNext &&
                datefns.isAfter(utcEnd, timeStart) &&
                !item.available
              ) {
                disableAllNext = true;
              }

              return {
                time: item.end,
                timeFormat: format(utcEnd, 'HH:mm'),
                disabled: disableAllNext || !item.available,
              };
            })
            .filter(Boolean) as typeArrayTime[];

          setTimesEnd(formatTimes);
        })
        .catch(err => {
          console.log('>>> Err: ', err);
        });
  };

  useEffect(() => {
    if (watch('date')) {
      setSelectedFilterDate(
        datefns.format(new Date(watch('date')), 'yyyy-MM-dd'),
      );
    }
  }, [watch]);

  useEffect(() => {
    const professionals =
      (state.professionals
        ?.map(item => item.id ?? null)
        .filter(Boolean) as number[]) ?? [];

    if (watch('date'))
      makeRemoteGetAvailability()
        .get({
          timestamp: {
            begin: datefns.startOfDay(new Date(watch('date'))).toISOString(),
            end: datefns.endOfDay(new Date(watch('date'))).toISOString(),
          },
          professionals: professionals,
        })
        .then(res => {
          const formatTimes = res.availability
            ?.map(item => {
              if (isBefore(new Date(item.begin), new Date())) return;

              const utcBegin = parseISO(item.begin);

              return {
                time: item.begin,
                timeFormat: format(utcBegin, 'HH:mm'),
                disabled: !item.available,
              };
            })
            .filter(Boolean) as typeArrayTime[];

          setTimes(formatTimes);
        })
        .catch(err => {
          console.log('>>> Err: ', err);
        });
  }, [watch('date')]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Content>
          <Controller
            render={({ value, onChange }) => (
              <InputKeyboardDate
                width="53%"
                state={value}
                setState={(date: Date) => {
                  handleChange(date);
                  onChange(date?.toISOString());
                }}
                name="date"
                autofocus
                error={Boolean(errors.date)}
                message={errors.date?.message}
                label={translator('Data do atendimento')}
                minDate={new Date()}
              />
            )}
            name="date"
            control={control}
            defaultValue={state.date}
            label={translator('Data do atendimento')}
            minDate={new Date()}
          />
          <Select
            id="input_hourStart"
            width="160px"
            defaultValue={state.hourStart}
            value={defaultStart}
            onChange={e => {
              setUpTimesEnd(e.target.value);
              setDefaultStart(e.target.value);
              setValue('hourStart', e.target.value as string);
              setValue('hourEnd', '');
              setDefaultEnd('');
            }}
            register={() => register('hourStart')}
            error={Boolean(errors.hourStart)}
            message={
              errors?.hourStart?.message
                ? translator(errors?.hourStart?.message)
                : ''
            }
            label={labelTimeStart}
            required
            name="hourStart"
          >
            <option id="option_0" value="">
              {labelTimeStart}
            </option>
            {times.map((item: typeArrayTime, index) => (
              <option
                id={`option_${index}`}
                disabled={item.disabled}
                value={item.time}
                style={{
                  backgroundColor: item.disabled ? '#C9C9C9' : '#fff',
                }}
              >
                {item.timeFormat}
              </option>
            ))}
          </Select>
          <Select
            label={labelTimeEnd}
            defaultValue={state.hourEnd}
            value={defaultEnd}
            width="160px"
            id="input_hourFinish"
            onChange={e => {
              setDefaultEnd(e.target.value);
              setValue('hourEnd', e.target.value as string);
            }}
            register={() => register('hourEnd')}
            name="hourEnd"
            error={Boolean(errors.hourEnd)}
            message={
              errors?.hourEnd?.message
                ? translator(errors?.hourEnd?.message)
                : ''
            }
            required
          >
            <option id="option_0" value="">
              {labelTimeEnd}
            </option>
            {timesEnd.map((item: typeArrayTime, index) => (
              <option
                id={`option_${index}`}
                disabled={item.disabled}
                value={item.time}
              >
                {item.timeFormat}
              </option>
            ))}
          </Select>
        </Content>

        <Navigator back={onBack} />
      </Form>
    </Container>
  );
};

export default DateAndTime;
