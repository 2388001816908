import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DocsEmpty } from '../componentEmpty';
import { ClinicalDocs } from './Lists';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';

const ListClinicalDocument: React.FC = () => {
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );

  useEffect(() => {
    makeReduxDataPagination().set([]);
    // return () => {};
  }, []);

  const { loading } = useSelector((store: iStore) => store.clinicalDocs);

  return dataPagination.length === 0 && !loading ? (
    <DocsEmpty
      message={translator(
        'Seus documentos clínicos (receitas, atestados etc) aparecerão aqui. No momento você não tem nenhum',
      )}
    />
  ) : (
    <ClinicalDocs dataList={dataPagination} />
  );
};

export default ListClinicalDocument;
