import styled, { css } from 'styled-components';

import { Button } from '~/presentation/components/UI/buttons';

export const Container = styled.div`
  overflow-x: scroll;
`;

export const Body = styled.div``;

export const Title = styled.div`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 11px;
  color: #7a7a7a;
`;

interface iItemRole {
  selected: boolean;
}

export const ItemRole = styled(Button)<iItemRole>`
  border: 1px solid #c4c4c4;
  border-radius: 3px;
  padding: 2px 10px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: black;
  width: auto;
  padding: 0px 15px;

  &:hover {
    background-color: transparent;
    color: black;
    font-style: normal;
    font-weight: 400;
  }

  ${props =>
    props.selected === true &&
    css`
      background-color: #004ba5;
      color: white;
      font-style: normal;
      font-weight: 500;
    `}
`;

export const Itens = styled.div`
  display: flex;
`;
