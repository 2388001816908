import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iAuth, iStore } from '~/domain/interfaces/models';

import { iOrgs, iRoles } from '../interface';
import { ownProps } from '../ChangeRoles';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { auth } = useSelector((store: iStore) => store);
    const { role, orgId } = useSelector(
      (store: iStore) => store.auth.selectUser,
    );
    const { data } = useSelector((store: iStore) => store.message);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      orgs: MapOrgsData(auth),
      orgId,
      role,
      data,
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

const MapOrgsData = (auth: iAuth): iOrgs[] => {
  const { orgs } = auth;
  const array: iOrgs[] = [];

  orgs.forEach(v => {
    const aux: iOrgs = {
      name: v.fullName,
      id: v.id,
      orgUnits: [],
    };
    let roles: iRoles[] = [];

    if (v.administrator !== null) {
      roles.push({
        name: 'Administrador',
        id: v.administrator.id,
        role: 'ADM',
      });
    }

    if (v.consultant !== null) {
      roles.push({
        name: 'Paciente',
        id: v.consultant.id,
        role: 'CON',
      });
    }

    if (roles.length) {
      aux.orgUnits?.push({
        name: 'Geral',
        id: undefined,
        roles,
      });
    }

    v.orgUnits.forEach(value => {
      roles = [];

      if (value.professionals.length)
        roles.push({
          name: 'Profissional',
          id: value.professionals[0].id,
          role: 'PRO',
        });

      if (value.organizers.length)
        roles.push({
          name: 'Organizador',
          id: value.organizers[0].id,
          role: 'ORG',
        });

      aux.orgUnits?.push({
        name: value.name,
        id: value.id,
        roles,
      });
    });

    array.push(aux);
  });

  return array;
};
