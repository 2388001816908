import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  display: grid;
  grid-gap: 10px;
  height: fit-content;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Group = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-gap: 15px;
`;

export const GroupNeighborhood = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
`;

export const GroupCity = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 15px;
`;
