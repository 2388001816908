import { Dispatch } from 'redux';
import store from '~/data/store';
import { professionListRequest } from '~/data/store/reducer/profession/actions/professionList';

import { iActionProfessionList } from '~/domain/interfaces/redux/profession/professionList';

import { ProfessionList } from '~/domain/usecases/profession/redux';

export class ReduxProfessionList implements ProfessionList {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  post = (params: ProfessionList.Params): iActionProfessionList =>
    this.send(professionListRequest(params));
}
