import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateAdministrator as UseCaseRemoteCreateAdministrator } from '~/domain/usecases/administrator/remote';
import { CreateAdministrator as UsecaseReduxCreateAdministrator } from '~/domain/usecases/administrator/redux';

import { ReduxCreateAdministrator } from '~/data/store/reducer/administrator/usecases';

import { RemoteCreateAdministrator } from '~/data/repository/administrator';

export const makeRemoteCreateAdministrator =
  (): UseCaseRemoteCreateAdministrator =>
    new RemoteCreateAdministrator(
      makeApiUrl('/administrators'),
      makeHttpClient(),
    );

export const makeReduxCreateAdministrator =
  (): UsecaseReduxCreateAdministrator => new ReduxCreateAdministrator();
