import { takeLatest } from 'redux-saga/effects';
import { onGetAll, onGetAllFailed, onGetAllSuccess } from './GetAll';
import { AppointmentPrioritiesTypes } from '~/domain/interfaces/redux/appointmentPriorities/types';

const tasks = [
  takeLatest(AppointmentPrioritiesTypes.GET_ALL, onGetAll),
  takeLatest(AppointmentPrioritiesTypes.GET_ALL_SUCCESS, onGetAllSuccess),
  takeLatest(AppointmentPrioritiesTypes.GET_ALL_FAILED, onGetAllFailed),
];

export default tasks;
