import { GetBillingService } from '~/domain/usecases/billing/redux';
import {
  BillingTypes,
  iActionGetBillingService,
  iActionGetBillingServiceFailed,
  iActionGetBillingServiceSuccess,
} from '~/domain/interfaces/redux/billing/getBillingService';

export const getRequest = (
  payload: GetBillingService.Params,
): iActionGetBillingService => ({
  type: BillingTypes.GET_BILLING_SERVICE,
  payload,
});

export const getSuccess = (
  params: GetBillingService.Model,
): iActionGetBillingServiceSuccess => ({
  type: BillingTypes.GET_BILLING_SERVICE_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetBillingServiceFailed => ({
  type: BillingTypes.GET_BILLING_SERVICE_FAILED,
});
