import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/appointmentType/getAll';
import { GetAppointmentType } from '~/domain/usecases/appointmentType/remote/GetAppointmentType';
import { makeRemoteGetAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/appointmentType/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllAppointment = makeRemoteGetAppointmentType();

  try {
    const response: GetAppointmentType.Model = yield call(
      remoteGetAllAppointment.list,
      action.payload,
    );

    yield put(getAllSuccess(response));
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
