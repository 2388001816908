import React, { useEffect, useState } from 'react';
import { Input } from '../UI';
import { Container, Form } from './style/StyledPasswordRegister';
import { PasswordValidationBar } from '../passwordValidationBar';
import { Navigator } from './Navigator';
import { translator } from '../i18n';
import {
  iRegisterParticipant,
  iRegisterProfessional,
  iRegisterManager,
} from '~/domain/interfaces/components/list';

interface OwnProps {
  change: Function;
  password?: string;
  setPassword?: Function;
  setPassConfirm?: Function;
  passConfirm?: string;
  state?: iRegisterProfessional | iRegisterParticipant | iRegisterManager;
}

const PasswordRegister: React.FC<OwnProps> = ({
  change,
  state,
  password,
  setPassword,
  setPassConfirm,
  passConfirm,
}): JSX.Element => {
  const [pass, setPass] = useState<string>('');
  const [erro, setErro] = useState<boolean>(true);

  useEffect(() => {
    if (password === passConfirm!) {
      setErro(false);
    } else {
      setErro(true);
    }
  }, [passConfirm!]);

  function handlePassStrong(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setPassword!(e.target.value);
  }

  function handleConfirmPass(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setPassConfirm!(e.target.value);
    change(e);
  }

  return (
    <Container>
      <Form>
        <Input
          id="registerPassWordAcess"
          type="password"
          name="passWordAcess"
          placeholder={translator('Digite uma senha pessoal')}
          label={translator('Senha de Acesso')}
          required
          onChange={handlePassStrong}
          value={password}
          autoFocus
        />
        <Input
          error={erro}
          id="registerPassWordRepeat"
          type="password"
          name="password"
          placeholder={translator('Confirme a senha')}
          label={translator('Repita a senha de acesso')}
          required
          onChange={handleConfirmPass}
        />
        <PasswordValidationBar password={password!} />
      </Form>
    </Container>
  );
};

export default PasswordRegister;
