import { put, call } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  deleteFailed,
  deleteSuccess,
} from '~/data/store/reducer/appointment/actions/delete';
import { iActionDelete } from '~/domain/interfaces/redux/appointment/delete';
import { DeleteAppointment } from '~/domain/usecases/appointment/remote';
import { makeRemoteDeleteAppointment } from '~/main/factories/usecases/appointment/DeleteAppointmentFactory';

import { getAllRequest } from '~/data/store/reducer/appointment/actions/getAll';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onDeleteRequest(action: iActionDelete) {
  // const ok = false;
  const remoteDeleteAppointment = makeRemoteDeleteAppointment();

  try {
    const response: DeleteAppointment.Model = yield call(
      remoteDeleteAppointment.delete,
      action.payload,
    );
    yield put(deleteSuccess());
  } catch (e) {
    yield put(deleteFailed());
  }
}

export function* onDeleteSuccess() {
  yield put(getAllRequest({}));
}

export function onDeleteFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao remover agendamento.' }),
  });
}
