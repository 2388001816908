import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { GetShiftsById } from '~/domain/usecases/shifts/remote';

import {
  BadRequestError,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteGetShiftsById implements GetShiftsById {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetShiftsById.Model>;

  constructor(url: string, httpClient: HttpClient<GetShiftsById.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getById = async (
    params: GetShiftsById.Params,
  ): Promise<GetShiftsById.Model> => {
    const url = this.url.replace('{onCallId}', params.onCallId.toString());

    const httpResponse = await this.httpClient.request({
      url: `${url}/${params.shiftId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
