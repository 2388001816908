import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetChat as UsecaseReduxGetChat } from '~/domain/usecases/chat/redux';
import { GetChat as UsecaseRemoteGetChat } from '~/domain/usecases/chat/remote';

import { RemoteGetChat } from '~/data/repository/chat';
import { ReduxGetChat } from '~/data/store/reducer/chat/usecases';

export const makeRemoteGetChat = (): UsecaseRemoteGetChat =>
  new RemoteGetChat(makeApiUrl('/chats/{chatId}'), makeHttpClient());

export const makeReduxGetChat = (): UsecaseReduxGetChat => new ReduxGetChat();
