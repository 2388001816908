import { CreateShifts } from '~/domain/usecases/shifts/redux';

import {
  ShiftsTypes,
  iActionCreateShifts,
  iActionCreateShiftsFailed,
  iActionCreateShiftsSuccess,
} from '~/domain/interfaces/redux/shifts/create';

export const createShiftsRequest = (
  payload: CreateShifts.Params,
): iActionCreateShifts => ({
  type: ShiftsTypes.CREATE,
  payload,
});

export const createShiftsSuccess = (
  params: CreateShifts.Model,
): iActionCreateShiftsSuccess => ({
  type: ShiftsTypes.CREATE_SUCCESS,
  payload: params,
});

export const createShiftsFailed = (
  params: CreateShifts.Model,
): iActionCreateShiftsFailed => ({
  type: ShiftsTypes.CREATE_FAILED,
  payload: params,
});
