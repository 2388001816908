import { ValidateCaptcha } from '~/domain/usecases/externalServices/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  UnexpectedError,
  InvalidCredentialsError,
  NotFound,
  BadRequestError,
} from '~/domain/errors';

export class RemoteValidateCaptcha implements ValidateCaptcha {
  private readonly url: string;

  private readonly httpClient: HttpClient<ValidateCaptcha.Model>;

  constructor(url: string, httpClient: HttpClient<ValidateCaptcha.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  validate = async (
    params: ValidateCaptcha.Params,
  ): Promise<ValidateCaptcha.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: {
        token: params.response,
      },
    });

    console.log('ValidateCaptcha request: ', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
