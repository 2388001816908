import { takeLatest } from 'redux-saga/effects';
import { onGetAll, onGetAllFailed, onGetAllSuccess } from './GetAll';
import { AppointmentTypeTypes } from '~/domain/interfaces/redux/appointmentType/types';

const tasks = [
  takeLatest(AppointmentTypeTypes.GET_ALL, onGetAll),
  takeLatest(AppointmentTypeTypes.GET_ALL_SUCCESS, onGetAllSuccess),
  takeLatest(AppointmentTypeTypes.GET_ALL_FAILED, onGetAllFailed),
];

export default tasks;
