import React from 'react';
import { Button } from '../UI';
import { ContainerButton } from './styles/StyledButtonSave';

import { translator } from '../i18n';

interface ownProps {
  action?: () => any;
  disable?: boolean;
}

export const ButtonSave: React.FC<ownProps> = ({ action, disable }) => {
  return (
    <ContainerButton>
      <Button
        id="btn_save"
        type={action ? 'button' : 'submit'}
        size="medium"
        rounded
        disabled={disable}
        onClick={action}
      >
        {`${translator('Salvar')}`}
      </Button>
    </ContainerButton>
  );
};

export default ButtonSave;
