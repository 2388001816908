import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetHealthPlan as UsecaseGetHealthPlan } from '~/domain/usecases/healthPlan/Remote';

import { RemoteGetHealthPlan } from '~/data/repository/healthPlan/RemoteGetHealthPlan';

/**
 * send request via API.
 */

export const makeRemoteGetHealthPlan = (): UsecaseGetHealthPlan =>
  new RemoteGetHealthPlan(makeApiUrl('/healthPlan/'), makeHttpClient());
