import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Input, Select, Button } from '~/presentation/components/UI';
import {
  ContainerButtons,
  ContainerForm,
  FormItem,
  Container,
  Options,
} from './styles/StyledFilterProfessional';
import { iListSpecialty, iListProfessional } from './interface';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { translator } from '../i18n';

interface selectMap {
  specialties?: iListSpecialty[];
  filterProfessional?: (id: number | undefined) => iListProfessional[];
}

const FilterProfessional: React.FC<selectMap> = ({ specialties }) => {
  const [professional, setProfessional] = useState('');
  const [specialtySelected, setSpecialtySelected] = useState<number>(-1);
  const [status, setStatus] = useState('DEFAULT');
  const [register, setRegister] = useState('');

  const { orgId, orgUnitId, orgUnits, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const specialtyList = useSelector((store: iStore) => store.specialty.results);

  useEffect(() => {
    makeReduxGetAllSpecialty().getAll({
      filter: {
        hasProfessional: true,
        enabled: true,
      },
      pageSize: 9999,
    });
  }, []);

  const handleFilter = () => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    makeReduxGetAllProfessionals().getAll({
      pageSize: 9999,
      filter: {
        name: professional,
        specialty: specialtySelected === -1 ? undefined : specialtySelected,
        status: status === 'DEFAULT' ? undefined : status,
        docProf: register,
        org: orgId,
        unit: role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });

    document.getElementById('close_filter')?.click();
  };

  const resetFilter = () => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    setProfessional('');
    setSpecialtySelected(0);
    setStatus('DEFAULT');
    setRegister('');

    makeReduxGetAllProfessionals().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        unit: role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  };

  return (
    <Container>
      <ContainerForm>
        <FormItem>
          <p>{`${translator('Profissional')}`}</p>
          <Input
            id="input_professional"
            value={professional}
            onChange={e => setProfessional(e.target.value)}
            placeholder={`${translator('Digite o nome do profissional')}`}
            className="hover-input"
          />
        </FormItem>
        <FormItem>
          <p>Especialidades</p>
          <Select
            id="select_speciality"
            name="specialty"
            value={specialtySelected}
            defaultValue={Number(specialtySelected)}
            onChange={e => setSpecialtySelected(Number(e.target.value))}
          >
            <option value={-1}>Selecione</option>
            {specialtyList.map(item => (
              <option value={Number(item.specialty?.id)}>
                {item.specialty?.name}
              </option>
            ))}
          </Select>
        </FormItem>
        {/* {/* <FormItem>
          <p>Data inclusão</p>
          <InputKeyBoardDate
            state={new Date()}
            setState={() => ''}
            placeholder="Data"
          />
        </FormItem> */}
        <FormItem>
          <p>Registro</p>
          <Input
            id="input_register"
            value={register}
            name="register"
            onChange={e => setRegister(e.target.value)}
            placeholder="Digite o registro de um profissonal"
            className="hover-input"
          />
        </FormItem>
        <FormItem>
          <p>Status</p>
          <Select
            id="select_status"
            value={status}
            name="status"
            onChange={e => setStatus(e.target.value)}
            placeholder="Selecione"
          >
            <Options value="DEFAULT">Selecione</Options>
            <Options value="ONLINE">Online</Options>
            <Options value="OFFLINE">Offline</Options>
          </Select>
        </FormItem>
      </ContainerForm>
      <ContainerButtons>
        <Button
          id="btn_reset"
          onClick={() => resetFilter()}
          variant="secundary"
          size="medium"
          rounded
        >
          Redefinir
        </Button>
        <Button
          id="btn_search"
          onClick={() => handleFilter()}
          variant="primary"
          size="medium"
          rounded
        >
          Pesquisar
        </Button>
      </ContainerButtons>
    </Container>
  );
};
export default FilterProfessional;
