import { iShowModal } from '~/domain/interfaces/models/ShowModal';
import { ShowModalTypes } from '~/domain/interfaces/redux/showModal/types';
import { ShowModalActions } from './actions';

export const initialState: iShowModal = {
  specialty: false,
  appointment: false,
  profile: false,
};

const reducer = (
  state = initialState,
  action: ShowModalActions,
): iShowModal => {
  switch (action.type) {
    case ShowModalTypes.SET:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
