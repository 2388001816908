import React from 'react';

import Carousel from 'react-material-ui-carousel';
import ItemCaurosel from './itemCarousel';
import { Content, Header, Body } from './styles/styledCarousel';

import { User, ListUserProps } from './interface';

const CarouselParticipants: React.FC<ListUserProps> = ({ users }) => {
  return (
    <Content>
      {/* <Header>
        Profissionais participantes
        <br />
        {`${users.length} profissionais`}
      </Header> */}
      <Body>
        <ItemCaurosel user={{ user: users[1] }} role="PRO" />
        <ItemCaurosel user={{ user: users[0] }} />
      </Body>
    </Content>
  );
};

export default CarouselParticipants;
