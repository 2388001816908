import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'period a a' 'observacoes observacoes observacoes' 'footer footer footer';
  grid-gap: 20px;
`;

export const Period = styled.div`
  grid-area: period;
  height: fit-content;
`;

export const Text = styled.div`
  grid-area: observacoes;

  textarea {
    max-width: 950px;
    min-height: 184px;
    width: 100%;
    resize: none;
    padding: 10px;
    border: 1px solid #c7cbd1;
    box-sizing: border-box;
    border-radius: 3px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #444a51;
    padding-bottom: 10px;
  }
`;
