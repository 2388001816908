export enum AppointmentTypes {
  CREATE = '@appointment/CREATE',
  CREATE_SUCCESS = '@appointment/CREATE_SUCCESS',
  CREATE_FAILED = '@appointment/CREATE_FAILED',

  ADD_APPOINTMENT_DATA = '@appointment/ADD_APPOINTMENT_DATA',

  UPDATE = '@appointment/UPDATE',
  UPDATE_SUCCESS = '@appointment/UPDATE_SUCCESS',
  UPDATE_FAILED = '@appointment/UPDATE_FAILED',

  DELETE = '@appointment/DELETE',
  DELETE_SUCCESS = '@appointment/DELETE_SUCCESS',
  DELETE_FAILED = '@appointment/DELETE_FAILED',

  GETALL = '@appointment/GETALL',
  GETALL_SUCCESS = '@appointment/GETALL_SUCCESS',
  GETALL_FAILED = '@appointment/GETALL_FAILED',

  GET = '@appointment/GET',
  GET_SUCCESS = '@appointment/GET_SUCCESS',
  GET_FAILED = '@appointment/GET_FAILED',

  STOP = '@appointment/STOP',
  STOP_SUCCESS = '@appointment/STOP_SUCCESS',
  STOP_FAILED = '@appointment/STOP_FAILED',

  SET_DATA = '@appointment/SET_DATA',
  SET_DATA_SUCCESS = '@appointment/SET_DATA_SUCCESS',
  SET_DATA_FAILED = '@appointment/SET_DATA_FAILED',

  CANCEL = '@appointment/CANCEL',
  CANCEL_SUCCESS = '@appointment/CANCEL_SUCCESS',
  CANCEL_FAILED = '@appointment/CANCEL_FAILED',

  SETUP_APPOINTMENT = '@appointment/SETUP_APPOINTMENT',

  APPOINTMENTS_CONFIRMATION = '@appointment/APPOINTMENTS_CONFIRMATION',
  APPOINTMENTS_CONFIRMATION_SUCCESS = '@appointment/APPOINTMENTS_CONFIRMATION_SUCCESS',
  APPOINTMENTS_CONFIRMATION_FAILED = '@appointment/APPOINTMENTS_CONFIRMATION_FAILED',

  INSERT_APPOINTMENT = '@appointment/INSERT_APPOINTMENT',

  LIST = '@appointment/LIST',
  LIST_SUCCESS = '@appointment/LIST_SUCCESS',
  LIST_FAILED = '@appointment/LIST_FAILED',

  APPOINTMENT_FILTER = '@appointment/APPOINTMENT_FILTER',
  APPOINTMENT_FILTER_SUCCESS = '@appointment/APPOINTMENT_FILTER_SUCCESS',
  APPOINTMENT_FILTER_FAILED = '@appointment/APPOINTMENT_FILTER_FAILED',
}
