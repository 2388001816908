import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { JoinOnCallSpecialist as UsecaseRemoteJoinOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
// import { JoinOnCallSpecialist as UsecaseReduxJoinOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemoteJoinOnCallSpecialist } from '~/data/repository/onCallSpecialist';
// import { ReduxJoinOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteJoinOnCallSpecialist =
  (): UsecaseRemoteJoinOnCallSpecialist =>
    new RemoteJoinOnCallSpecialist(
      makeApiUrl(
        '/oncall/{onCallId}/specialists/{specialistId}/requests/{requestId}/JOIN',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxJoinOnCallSpecialist =
  (): UsecaseReduxJoinOnCallSpecialist =>
    new ReduxJoinOnCallSpecialist(); */
