import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import HeaderExpiration from '~/presentation/components/HeaderExpiration/HeaderExpiration';

import { HeaderSimples } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import { SearchListAppointment } from '~/presentation/components/search-list';
import { Button, Input } from '~/presentation/components/UI';
import { Chat as Cchat } from '~/presentation/components/chat';
import { HistoryChat } from '~/presentation/components/historyChat';
import { PaginationByDate } from '~/presentation/components/paginationByDate';
import { ListAppointment } from '~/presentation/components/ListParticipant';
import Calendar from '~/presentation/components/calendar';

import {
  IconCalendar,
  IconChatBalon,
  IconPlus,
} from '~/presentation/base/icons';

import { FilterAppointment } from '~/presentation/components/filter';
import { translator } from '~/presentation/components/i18n';
import {
  Container,
  Body,
  Attendiment,
  Chat,
  IconBalon,
  Inputs,
  HistoryComponent,
  ButtonBig,
  LockScroll,
} from './StyledSchedules';
import { ProfessionalPages } from '../..';

interface ownProps {
  totalAppointment: number;
}

const Appointment: React.FC<ownProps> = ({ totalAppointment }): JSX.Element => {
  const message = useSelector((store: iStore) => store.message);

  const { orgs } = useSelector((store: iStore) => store.auth);

  const expiration = useMemo(() => {
    const temp = orgs?.[0]?.expiration ?? null;

    if (temp) {
      return Math.floor(
        (new Date(temp).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
      );
    }

    return temp;
  }, [orgs]);

  return (
    <Container
      style={{
        opacity:
          message.active !== 'none' && message.active !== 'professionalReady'
            ? '0.7'
            : '1',
      }}
    >
      <LockScroll>
        <Cchat />
        <Menu />
        <HeaderSimples
          title={translator('Agenda de atendimentos')}
          value={totalAppointment}
          icon={IconCalendar}
        />
      </LockScroll>

      {expiration && <HeaderExpiration daysUse={`${expiration} dias`} />}

      <Body>
        <div id="left">
          <Calendar />
          {/* <Chat>
            Chat
            <IconBalon>
              <IconChatBalon />
            </IconBalon>
          </Chat>
          <Inputs>
            <Input placeholder={`${translator('Pesquisar usuário')}`} />
          </Inputs>
          <HistoryComponent>
            <HistoryChat />
          </HistoryComponent> */}
        </div>
        <div id="right">
          <div id="header">
            <PaginationByDate />
            <SearchListAppointment>
              <FilterAppointment />
            </SearchListAppointment>
            <ButtonBig>
              <Button size="large" rounded disabled>
                <Attendiment>
                  <IconPlus />
                </Attendiment>
                {translator('Novo agendamento')}
              </Button>
            </ButtonBig>
          </div>
          <div id="list">
            <ListAppointment />
          </div>
        </div>
      </Body>
    </Container>
  );
};

export default Appointment;
