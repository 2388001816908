import { createIntlCache, createIntl } from 'react-intl';
import { object, string, number } from 'zod';
import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export const schema = object({
  login: string()
    .email({ message: intl.formatMessage({ id: 'E-mail inválido' }) })
    .nonempty({ message: intl.formatMessage({ id: 'E-mail é obrigatório' }) }),
  email: string()
    .email({ message: intl.formatMessage({ id: 'E-mail inválido' }) })
    .optional(),
  password: string()
    .min(6, { message: intl.formatMessage({ id: 'Senha inválida' }) })
    .nonempty({ message: intl.formatMessage({ id: 'Senha é obrigatória' }) }),
  org: number().optional(),
}).refine(body => body.email || body.login, {
  message: intl.formatMessage({
    id: 'Você deve inserir um email válido ou um nome de usuário de login',
  }),
});
