import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;
  padding-bottom: 24px;
`;

export const Header = styled.div`
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #dedede;
  background: #f2f8ff;
  display: flex;
  justify-content: center;
  padding: 24px 16px;
`;

export const Title = styled.div`
  width: 100%;
  color: #222529;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding-top: 23px;
`;

export const Content = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #62666c;
  padding: 16px 16px 24px 16px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;
