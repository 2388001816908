import { UpdateUser } from '~/domain/usecases/users/redux';
import {
  iActionUpdate,
  iActionUpdateFailed,
  iActionUpdateSuccess,
  UsersTypes,
} from '~/domain/interfaces/redux/users/update';

export const updateRequest = (payload: UpdateUser.Params): iActionUpdate => ({
  type: UsersTypes.UPDATE,
  payload,
});

export const updateSuccess = (): iActionUpdateSuccess => ({
  type: UsersTypes.UPDATE_SUCCESS,
});

export const updateFailed = (): iActionUpdateFailed => ({
  type: UsersTypes.UPDATE_FAILED,
});
