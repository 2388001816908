import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface iContainer {
  size?: string;
}

export const Container = styled.label<iContainer>`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${defaultTheme.highlight};
  width: ${props => props.size || '40px'};
  height: ${props => props.size || '40px'};
  cursor: pointer;

  :hover {
    background-color: ${defaultTheme.primaryLight};
    transition: 0.5s;
  }
`;
