import React from 'react';

const HealthyHeartIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.19592 13.7833L5.57925 12.3467C4.61379 11.4919 3.81403 10.7406 3.17997 10.0927C2.54591 9.44492 2.04313 8.85772 1.67163 8.33115C1.30013 7.80458 1.03872 7.3154 0.8874 6.86364C0.736067 6.41188 0.6604 5.94562 0.6604 5.46484C0.6604 4.43489 1.00945 3.56738 1.70755 2.8623C2.40564 2.15723 3.26287 1.80469 4.27925 1.80469C4.83196 1.80469 5.36537 1.91604 5.87947 2.13875C6.39357 2.36145 6.83238 2.67728 7.19592 3.08622C7.56277 2.67622 7.99297 2.36013 8.48652 2.13795C8.98006 1.91578 9.49986 1.80469 10.0459 1.80469C10.9392 1.80469 11.7267 2.07722 12.4086 2.62227C13.0905 3.16732 13.5119 3.86267 13.6727 4.70832H12.2195C12.0666 4.27354 11.7981 3.91147 11.414 3.62209C11.03 3.33272 10.5739 3.18804 10.0459 3.18804C9.43384 3.18804 8.95703 3.34504 8.61548 3.65904C8.27394 3.97305 7.94242 4.34141 7.62092 4.76412H6.7749C6.43963 4.3303 6.09833 3.95916 5.75098 3.6507C5.40364 3.34226 4.91306 3.18804 4.27925 3.18804C3.65388 3.18804 3.12562 3.40648 2.69447 3.84337C2.26331 4.28026 2.04773 4.82075 2.04773 5.46484C2.04773 5.81602 2.11643 6.17085 2.25383 6.52935C2.39123 6.88785 2.64968 7.30839 3.02918 7.79097C3.40869 8.27355 3.93124 8.84407 4.59682 9.50252C5.26239 10.161 6.12876 10.968 7.19592 11.9235C7.45509 11.695 7.82955 11.3605 8.31928 10.9199C8.80902 10.4793 9.15835 10.1615 9.36728 9.96629L9.52163 10.1213L9.85605 10.4571L10.1905 10.7928L10.3448 10.9478C10.1248 11.1621 9.89513 11.3748 9.65588 11.586C9.41663 11.7973 9.21608 11.974 9.05425 12.1163L7.19592 13.7833ZM11.9488 10.8917V9.09165H10.1488V7.70832H11.9488V5.90832H13.3322V7.70832H15.1322V9.09165H13.3322V10.8917H11.9488Z"
        fill="#656A6E"
      />
    </svg>
  );
};

export default HealthyHeartIcon;
