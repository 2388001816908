import { SocketClient } from '~/data/protocols/socket';
import { Disconnect } from '~/domain/usecases/waitingRoom/socket';

export class SocketDisconnect implements Disconnect {
  private readonly event: string;

  private readonly SocketClient: SocketClient<Disconnect.Model>;

  constructor(event: string, socketClient: SocketClient<Disconnect.Model>) {
    this.event = event;
    this.SocketClient = socketClient;
  }

  disconnect = (): void => {
    this.SocketClient.disconnect();
  };
}
