import { call, put } from 'redux-saga/effects';
import { iActionGetBillingDetailed } from '~/domain/interfaces/redux/billing/getBillingDetailed';
import { GetBillingDetailed } from '~/domain/usecases/billing/remote/GetBillingDetailed';
import {
  getFailed,
  getSuccess,
} from '~/data/store/reducer/billing/actions/getBillingDetailed';

import { makeRemoteGetBillingDetailed } from '~/main/factories/usecases/billing/GetBillingDetailedFactory';

export function* onGetBillingDetailed(action: iActionGetBillingDetailed) {
  const remoteGetBillingDetailed = makeRemoteGetBillingDetailed();

  try {
    const response: GetBillingDetailed.Model = yield call(
      remoteGetBillingDetailed.getBillingDetailedService,
      action.payload,
    );

    yield put(getSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetBillingDetailedSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetBillingDetailedFailed() {}
