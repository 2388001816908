import { call, put, select } from 'redux-saga/effects';
import { iActionUpdate } from '~/domain/interfaces/redux/Notifications/update';

import { UpdateNotificationUser } from '~/domain/usecases/Notifications/remote';

import {
  updateSuccess,
  updateFailed,
} from '~/data/store/reducer/Notifications/actions/update';

import { makeRemoteUpdateNotificationUser } from '~/main/factories/usecases/Notifications/UpdateNotificationUser';

export function* onUpdateNotification(action: iActionUpdate) {
  const remoteUpdateNotification = makeRemoteUpdateNotificationUser();

  try {
    const response: UpdateNotificationUser.Model = yield call(
      remoteUpdateNotification.update,
      action.payload,
    );

    yield put(updateSuccess(response));
  } catch (e) {
    yield put(updateFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateFailed(e: any) {}
