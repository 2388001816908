import { SoapAiAppointment } from '~/domain/usecases/appointment/remote';

import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteSoapAiAppointment implements SoapAiAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<SoapAiAppointment.Model>;

  constructor(url: string, httClient: HttpClient<SoapAiAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  soapAi = async (
    params: SoapAiAppointment.Params,
  ): Promise<SoapAiAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{appointmentId}', params.appointmentId),
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
