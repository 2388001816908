import React, { useState, useEffect } from 'react';
import * as datefns from 'date-fns';
import { KeyboardTimePicker } from '@material-ui/pickers';

import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { translator } from '~/presentation/components/i18n';

import { AddAppointmentData } from '~/domain/usecases/appointment/redux/AddApointmentData';
import { iAppointment } from '~/domain/interfaces/models/Appointment';
import { Container, Form } from './styles/styledDate';

import { ConnectComponent } from './mapper/MapperDateAndTime';

export interface ownProps {
  state: iAppointment;
  actionAppointment: AddAppointmentData;
}

const DateAndTime: React.FC<ownProps> = ({
  state,
  actionAppointment,
}): JSX.Element => {
  const [start, setStart] = useState<Date>(
    state.create.data.start || new Date(),
  );
  const [end, setEnd] = useState<Date>(
    state.create.data.end || datefns.addHours(start, 1),
  );
  const [date, setDate] = useState<Date>(
    state.create.data.date ? new Date(state.create.data.date) : new Date(),
  );

  useEffect(() => {
    return () => {
      actionAppointment.add({
        data: {
          date: datefns.format(date, 'yyyy/MM/dd'),
          duration: datefns.differenceInMinutes(end, start),
          start,
          end,
        },
      });
    };
  }, [start, end]);

  const handleStartTime = (dateTime: Date | null) => {
    if (dateTime) setStart(dateTime);
    // selectedDate?.getMinutes() + selectedDate?.getHours() * 60,
  };

  const handleEndTime = (dateTime: Date | null) => {
    if (dateTime) setEnd(dateTime);
    // selectedDate2?.getMinutes() + selectedDate2?.getHours() * 60,
  };

  return (
    <Container>
      <Form>
        <InputKeyboardDate
          name="date"
          label={translator('Data do atendimento')}
          state={date}
          setState={setDate}
        />
        <KeyboardTimePicker
          onChange={handleStartTime}
          value={start}
          label={translator('Hora início')}
          variant="dialog"
          name="hourStart"
        />
        <KeyboardTimePicker
          onChange={handleEndTime}
          value={end}
          label={translator('Hora fim')}
          variant="dialog"
          id="time"
          name="hourFinish"
          error={start >= end ? true : undefined}
        />
      </Form>
    </Container>
  );
};

export default ConnectComponent(DateAndTime);
