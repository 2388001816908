import React, { useCallback, useEffect, useMemo } from 'react';
import { Textarea } from '@wisecare/design-system-web';
import { useFormContext } from 'react-hook-form';
import {
  Container,
  InlineForms,
  TextAreaForm,
  selectStyles,
  Required,
} from './styles/StyledRequest';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../filter/styles/StyledFilterAppointmentHistory';
import _ from 'lodash';
import { FormData } from './CreateReport';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { makeReduxListModalitiesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListModalitiesDiagnosisReportFactory';
import SearchSelect from '../UI/searchSelect';

const Request = () => {
  const [cities, setCities] = React.useState<GetCitiesByUf.Model>([]);

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { records } = useSelector(
    (store: iStore) => store.diagnosisReport?.modalities,
  );

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<FormData>();

  const generateCitySelectOptions = () => {
    return _.sortBy(
      cities?.flatMap(value => {
        return [
          {
            label: value.nome,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const cityMultiselectSelected = useMemo(() => {
    return generateCitySelectOptions().find(
      option => option?.value === Number(watch('county')),
    );
  }, [generateCitySelectOptions, watch('county')]);

  const generateHealthUnitSelectOptions = useCallback(() => {
    if (!watch('county')) return [];

    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            label: `${value.cnes} - ${value.name}`,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(option => {
      return option?.value === Number(watch('healthUnit'));
    });
  }, [generateHealthUnitSelectOptions, watch('healthUnit')]);

  const generatePrioritySelectOptions = () => {
    const options = [
      { label: 'Baixa - Até 72 horas', value: 'LOW' },
      { label: 'Média - Até 48 horas', value: 'NORMAL' },
      { label: 'Alta - Até 24 horas', value: 'HIGH' },
    ];

    const optionsMap = options?.flatMap(value => {
      return [
        {
          label: value?.label,
          value: value?.value,
        },
      ];
    });

    return optionsMap;
  };

  const priorityMultiselectSelected = useMemo(() => {
    return generatePrioritySelectOptions().find(
      option => option?.value === watch('responsePriority'),
    );
  }, [generatePrioritySelectOptions]);

  const generateModalitySelectOptions = () => {
    return _.sortBy(
      records?.flatMap(value => {
        return [
          {
            label: value?.descr,
            value: value?.id,
          },
        ];
      }),
      item => item?.label?.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const modalityMultiselectSelected = useMemo(() => {
    return generateModalitySelectOptions().find(
      option => option?.value === Number(watch('modality')),
    );
  }, [generateModalitySelectOptions]);

  const generateStateSelectOptions = () => {
    return [
      {
        label: 'Paraíba',
        value: 'PB',
      },
    ];
  };

  const stateMultiselectSelected = useMemo(() => {
    return generateStateSelectOptions().find(
      option => option?.value === watch('state'),
    );
  }, [generateStateSelectOptions]);

  useEffect(() => {
    makeRemoteGetCitiesByUf()
      .getCitiesByUf({ uf: 'PB' })
      .then(res => {
        setCities(res);
      });

    makeReduxListModalitiesDiagnosisReport().listModalities({});
  }, []);

  useEffect(() => {
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
      city: watch('county'),
    });
  }, [watch('county')]);

  return (
    <Container>
      <InlineForms>
        <SearchSelect
          {...(register('state') as any)}
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          className="select"
          controlShouldRenderValue
          options={generateStateSelectOptions()}
          value={stateMultiselectSelected}
          onChange={(e: { value: string }) => {
            if (!e) return;
            setValue('state', e?.value);
            setValue('county', '');
            setValue('healthUnit', '');
          }}
          isDisabled
          placeholder="Selecione"
          formatCreateLabel={(label: string) => ({
            label: `Buscar por ${label}`,
          })}
          styles={selectStyles()}
          isValidNewOption={() => false}
          required
          label="Estado"
          error={errors?.state}
          message={errors?.state?.message}
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />

        <SearchSelect
          className="select"
          {...(register('county') as any)}
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          options={generateCitySelectOptions()}
          value={cityMultiselectSelected}
          onChange={(e: { value: string }) => {
            if (!e) return;
            setValue('county', String(e?.value));
            setValue('healthUnit', '');
          }}
          controlShouldRenderValue
          defaultValue={watch('county')}
          placeholder="Selecione"
          formatCreateLabel={(label: string) => `Buscar por ${label}`}
          styles={selectStyles()}
          label="Município"
          required
          error={errors?.county}
          message={errors?.county?.message}
          isValidNewOption={() => false}
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />
      </InlineForms>

      <div>
        <SearchSelect
          {...(register('healthUnit') as any)}
          className="select"
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          defaultValue={watch('healthUnit')}
          options={generateHealthUnitSelectOptions()}
          placeholder="Selecione"
          formatCreateLabel={(label: string) => `Buscar por ${label}`}
          styles={selectStyles()}
          onChange={(e: { value: string }) => {
            console.log('##e', e);
            setValue('healthUnit', String(e?.value));
          }}
          value={healthUnitMultiselectSelected}
          isValidNewOption={() => false}
          label="Unidade de Saúde"
          error={errors?.healthUnit}
          message={errors?.healthUnit?.message}
          required
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />
      </div>

      <InlineForms>
        <SearchSelect
          {...(register('modality') as any)}
          className="select"
          id="modality"
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          controlShouldRenderValue
          options={generateModalitySelectOptions()}
          value={modalityMultiselectSelected}
          onChange={(e: { value: string }) => {
            if (!e) return;
            setValue('modality', String(e?.value));
          }}
          placeholder="Selecione"
          label="Modalidade"
          width="70%"
          error={errors?.modality}
          message={errors?.modality?.message}
          formatCreateLabel={(label: string) => `Buscar por ${label}`}
          styles={selectStyles()}
          isValidNewOption={() => false}
          required
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />

        <SearchSelect
          className="select"
          id="responsePriority"
          {...(register('responsePriority') as any)}
          components={{
            Control,
            DropdownIndicator,
            IndicatorSeparator: () => null,
            Option,
          }}
          controlShouldRenderValue
          options={generatePrioritySelectOptions()}
          value={priorityMultiselectSelected}
          onChange={(e: { value: string }) => {
            if (!e) return;
            setValue('responsePriority', String(e?.value));
          }}
          width="30%"
          label="Prioridade de resposta"
          error={errors?.responsePriority}
          message={errors?.responsePriority?.message}
          placeholder="Selecione"
          formatCreateLabel={(label: string) => `Buscar por ${label}`}
          styles={selectStyles()}
          isValidNewOption={() => false}
          required
          noOptionsMessage={() => 'Nenhum resultado encontrado'}
        />
      </InlineForms>

      <TextAreaForm>
        <Textarea
          {...(register('description') as any)}
          defaultValue={watch('description')}
          placeholder="Descreva o motivo pela qual solicita este laudo."
          onChange={e => setValue('description', e.target.value)}
          label="Subjetivo e Objetivo"
          required
        />
        {errors?.description && (
          <Required style={{ fontSize: '12px' }}>
            {errors?.description?.message}
          </Required>
        )}
      </TextAreaForm>
    </Container>
  );
};

export default Request;
