import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateConsultant as UsecaseRemoteCreateConsultant } from '~/domain/usecases/consultant/remote';
import { CreateConsultant as UsecaseReduxCreateConsultant } from '~/domain/usecases/consultant/redux';

import { RemoteCreateConsultant } from '~/data/repository/consultant';
import { ReduxCreateConsultant } from '~/data/store/reducer/Consultant/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateConsultant = (): UsecaseRemoteCreateConsultant =>
  new RemoteCreateConsultant(makeApiUrl('/consultants'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCreateConsultant = (): UsecaseReduxCreateConsultant =>
  new ReduxCreateConsultant();
