export enum DiagnosisReportTypes {
  LISTMODALITIES = '@diagnosisReport/LISTMODALITIES',
  LISTMODALITIES_SUCCESS = '@diagnosisReport/LISTMODALITIES_SUCCESS',
  LISTMODALITIES_FAILED = '@diagnosisReport/LISTMODALITIES_FAILED',
  LIST = '@diagnosisReport/LIST',
  LIST_SUCCESS = '@diagnosisReport/LIST_SUCCESS',
  LIST_FAILED = '@diagnosisReport/LIST_FAILED',
  LIST_EXAM_TYPES = '@diagnosisReport/LIST_EXAM_TYPES',
  LIST_EXAM_TYPES_SUCCESS = '@diagnosisReport/LIST_EXAM_TYPES_SUCCESS',
  LIST_EXAM_TYPES_FAILED = '@diagnosisReport/LIST_EXAM_TYPES_FAILED',
}
