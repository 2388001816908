import { GetAllChat } from '~/domain/usecases/chat/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGetAllChat implements GetAllChat {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllChat.Model>;

  constructor(url: string, httClient: HttpClient<GetAllChat.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (params: GetAllChat.Params): Promise<GetAllChat.Model> => {
    let query = '';
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
