export const outcomeMap = (
  outcome:
    | 'PERMANENCE'
    | 'REFERRAL'
    | 'RETURN'
    | 'DEATH'
    | 'CLINICAL_DISCHARGE'
    | 'TRANSFER',
): string => {
  switch (outcome) {
    case 'DEATH':
      return 'Óbito';
    case 'CLINICAL_DISCHARGE':
      return 'Alta clínica';
    case 'PERMANENCE':
      return 'Permanência';
    case 'REFERRAL':
      return 'Encaminhamento';
    case 'RETURN':
      return 'Retorno';
    case 'TRANSFER':
      return 'Transferência';
    default:
      return 'Alta clínica';
  }
};

export const priorityMap = (priority: string): 'Alta' | 'Média' | 'Baixa' => {
  switch (priority) {
    case 'EMERGENCY':
      return 'Alta';

    case 'NORMAL':
      return 'Média';

    case 'LOW':
      return 'Baixa';

    default:
      return 'Baixa';
  }
};
