/* eslint-disable @typescript-eslint/no-empty-function */
import { call, put } from 'redux-saga/effects';
import {
  iActionCreateShifts,
  iActionCreateShiftsFailed,
} from '~/domain/interfaces/redux/shifts/create';

import { CreateShifts } from '~/domain/usecases/shifts/remote';

import {
  createShiftsFailed,
  createShiftsSuccess,
} from '~/data/store/reducer/shifts/actions/create';

import { makeRemoteCreateShifts } from '~/main/factories/usecases/shifts';
import { closeModal } from '~/utils/closeModal';

export function* onCreateShifts(action: iActionCreateShifts) {
  const remoteCreateShifts = makeRemoteCreateShifts();

  try {
    const response: CreateShifts.Model = yield call(
      remoteCreateShifts.create,
      action.payload,
    );

    yield put(createShiftsSuccess(response));
  } catch (e) {
    yield put(createShiftsFailed(e as CreateShifts.Model));
  }
}

export function onCreateShiftsSuccess() {
  // makeReduxListShifts().list({
  //   onCallId: storeDev.getState().shifts.onCallId,
  //   body: {},
  // });
  closeModal();
}

export function onCreateShiftsFailed(action: iActionCreateShiftsFailed) {}
