import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllChat as UsecaseRemoteGetAllChat } from '~/domain/usecases/chat/remote';
import { GetAllChat as UsecaseReduxGetAllChat } from '~/domain/usecases/chat/redux';

import { RemoteGetAllChat } from '~/data/repository/chat';
import { ReduxGetAllChat } from '~/data/store/reducer/chat/usecases';

export const makeRemoteGetAllChat = (): UsecaseRemoteGetAllChat =>
  new RemoteGetAllChat(makeApiUrl('/chats/'), makeHttpClient());

export const makeReduxGetAllChat = (): UsecaseReduxGetAllChat =>
  new ReduxGetAllChat();
