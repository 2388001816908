import { DeleteSupportDocs } from '~/domain/usecases/supportDoc/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
} from '~/domain/errors';

export class RemoteDeleteSupportDocs implements DeleteSupportDocs {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteSupportDocs.Model>;

  constructor(url: string, httClient: HttpClient<DeleteSupportDocs.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete = async (
    params: DeleteSupportDocs.Params,
  ): Promise<DeleteSupportDocs.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.docId}`,
      method: 'delete',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
