import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadRequestError,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';
import { GetDutyRequesters } from '~/domain/usecases/duty/remote';
import '~/infra/global/variables';

export class RemoteGetDutyRequesters implements GetDutyRequesters {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetDutyRequesters.Model>;

  constructor(url: string, httClient: HttpClient<GetDutyRequesters.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: GetDutyRequesters.Params,
  ): Promise<GetDutyRequesters.Model> => {
    const makeQueryString = () => {
      const { query } = params;
      return Object.entries(query ?? {})
        .map(([key, value]) => {
          return `${key}=${value}`;
        })
        .join('&');
    };

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${
        params.onCallId
      }/requesters/list?${makeQueryString()}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
