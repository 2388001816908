/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { createIntlCache, createIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schemaCompanionEmail } from '~/validation/validators/guest/Guest';
import ListSkeleton from '~/presentation/components/skeleton/ListSkeleton';
import ActionsList from '~/presentation/components/actionsList/ActionsList';
import {
  PlusPerson,
  SharedEmail,
  IconShareBlue,
} from '~/presentation/base/icons';
import { Button, Input } from '~/presentation/components/UI';
import {
  Container,
  Invite,
  TitleEmail,
  TitleInvite,
  ContentMultiEmail,
  InviteLink,
} from './styles/StyledLink';
import { makeRemoteUpdateAppointment } from '~/main/factories/usecases/appointment/UpdateAppointmentFactory';
import { AlertMessage } from '../messages/AlertMessage';
import { isNumeric } from '~/utils/IsNumber';
import { MapAppointmentDetail } from '~/presentation/roles/professional/pages/appointment/mapper/map';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeRemoteInviteAppointment } from '~/main/factories/usecases/appointment/InviteAppointmentFactory';
import { makeRemoteInviteCompanionsAppointment } from '~/main/factories/usecases/appointment/InviteCompanionsAppointmentFactory';
import { makeRemoteInviteOtherProfessionalsAppointment } from '~/main/factories/usecases/appointment/InviteOtherProfessionalsAppointmentFactory';
import { translator } from '../i18n';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { makeRemoteCompanionsAppointment } from '~/main/factories/usecases/appointment/CompanionsAppointmentFactory';
import { closeModal } from '~/utils/closeModal';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface iParamsTypes {
  appointmentId: string;
}

interface iListInvite {
  date: string;
  participant: string;
  contact: string;
  phone?: string;
  link?: string;
  otherProfessionalId?: number;
  companionId?: number;
  type: 'consultant' | 'professional' | 'otherProfessional' | 'companion';
  contactType?: 'email' | 'phone';
}

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '200px',
    overflowY: 'auto',
    padding: '10px',
  },
});

const Link: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [updateInitialValues, setUpdateInitialValues] =
    useState<boolean>(false);
  const [data, setData] = useState<iListInvite[]>([]);
  const [linkAppointment, setLinkAppointment] = useState('');

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schemaCompanionEmail),
    defaultValues: {
      email: '',
    },
  });

  const stateParams = useLocation<iParamsTypes>().state;

  const classes = useStyles();
  const locale = getLocale();

  const columns = [
    {
      id: 'participant',
      label: intl.formatMessage({ id: 'Participante' }),
      minWidth: 30,
      align: 'left',
    },
    {
      id: 'contact',
      label: intl.formatMessage({ id: 'Contato' }),
      minWidth: 20,
      align: 'left',
    },
    {
      id: 'date',
      label: intl.formatMessage({ id: 'Data do envio' }),
      minWidth: 20,
      align: 'left',
    },
    {
      id: 'actions',
      label: intl.formatMessage({ id: 'Ação' }),
      minWidth: 20,
      align: 'left',
    },
  ];

  useEffect(() => {
    const fetchAppointmentDetails = async () => {
      try {
        const response = await MapAppointmentDetail(
          Number(stateParams.appointmentId),
        );

        if (Object.keys(response.links).length === 0) {
          setIsInitialized(true);
          setLinkAppointment('');
          setData([]);
          return;
        }

        const formatContactDate = (emailLastSent: string) => {
          const date = new Date(emailLastSent);
          return `${date.toLocaleDateString(locale)} ${intl.formatMessage({ id: 'às' })} ${date.toLocaleTimeString(locale).substring(0, 5)}`;
        };

        const createInviteData = ({
          contact,
          participant,
          date,
          link,
          type,
          phone,
          otherProfessionalId,
        }: iListInvite) => ({
          contact,
          participant,
          date,
          link,
          type,
          phone: phone ?? undefined,
          otherProfessionalId: otherProfessionalId ?? undefined,
        });

        const updatedData = [];

        const {
          consultant,
          professional,
          otherProfessionals,
          companions,
          generic,
        } = response.links;

        if (consultant.email || consultant.phone) {
          updatedData.push(
            createInviteData({
              contact: consultant.email || consultant.phone,
              participant: intl.formatMessage({ id: 'Paciente' }),
              date: formatContactDate(consultant.emailLastSent),
              link: consultant.link,
              type: 'consultant',
              phone: consultant.phone,
            }),
          );
        }

        if (professional.email || professional.phone) {
          updatedData.push(
            createInviteData({
              contact: professional.email || professional.phone,
              participant: intl.formatMessage({ id: 'Profissional' }),
              date: formatContactDate(professional.emailLastSent),
              link: professional.link,
              type: 'professional',
              phone: professional.phone,
            }),
          );
        }

        otherProfessionals?.forEach(item => {
          if (item.email || item.phone) {
            const participantLabel = `${item.firstName} (${intl.formatMessage({ id: item.professional ? 'interno' : 'externo' })})`;
            updatedData.push(
              createInviteData({
                contact: item.email || item.phone,
                participant: participantLabel,
                date: formatContactDate(item.emailLastSent),
                link: item.link,
                type: 'otherProfessional',
                phone: item.phone,
                otherProfessionalId: item.professional,
              }),
            );
          }
        });

        companions?.forEach(item => {
          if (
            (item.email && item.email !== '') ||
            (item.phone && item.phone !== '999999999')
          ) {
            const participantLabel =
              item.firstName === 'Convidado'
                ? intl.formatMessage({ id: 'Convidado' })
                : `${item.firstName} (${intl.formatMessage({ id: 'Convidado' }).toLocaleLowerCase()})`;
            updatedData.push(
              createInviteData({
                contact: item.email || item.phone,
                participant: participantLabel,
                date: formatContactDate(item.emailLastSent),
                link: item.link,
                type: 'companion',
                phone: item.phone,
                otherProfessionalId: item.id,
              }),
            );
          }
        });

        setLinkAppointment(generic);
        setData(updatedData);
        setIsInitialized(true);
      } catch (error) {
        console.error('Error fetching appointment details:', error);
        // Handle error state if needed
      }
    };

    fetchAppointmentDetails();
  }, [locale, stateParams.appointmentId, updateInitialValues]);

  const RenderComponents: React.FC<{ id: any; item: any }> = ({ id, item }) => {
    switch (id) {
      case 'actions':
        return (
          <ActionsList
            actions={{
              copyLink: () => {
                document.execCommand('copy', true, item.link);
                navigator.clipboard.writeText(item.link);
                AlertMessage({
                  message: intl.formatMessage({
                    id: 'Link copiado para área de transferência',
                  }),
                  type: 'success',
                });
              },
              resendEmail: () => {
                handleResend(item, 'email');
              },
              sendWhats: () => {
                handleResend(item, 'phone');
              },
            }}
          />
        );
      default:
        return <div>{item[`${id}`]}</div>;
    }
  };

  const handleAddValue = handleSubmit(() => {
    const isNumber = isNumeric(email);

    makeRemoteCompanionsAppointment()
      .companions({
        appointmentId: stateParams.appointmentId,
        email: isNumber ? '' : email,
        role: 'OTHER',
        name: 'Convidado',
        phone: isNumber ? email : '999999999',
      })
      .then(() => {
        AlertMessage({
          type: 'success',
          message: intl.formatMessage({ id: 'Convite enviado com sucesso!' }),
        });

        setEmail('');
        setUpdateInitialValues(!updateInitialValues);
      })
      .catch(() => {
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Falha ao enviar convite!' }),
        });
      });
  });

  async function handleResend(val: iListInvite, type: 'email' | 'phone') {
    try {
      switch (val.type) {
        case 'consultant':
        case 'professional':
          await makeRemoteInviteAppointment().invite({
            appointmentId: stateParams.appointmentId,
            consultant: {
              email: val.type === 'consultant' && type === 'email',
              whatsapp: val.type === 'consultant' && type === 'phone',
            },
            professional: {
              email: val.type === 'professional' && type === 'email',
              whatsapp: val.type === 'professional' && type === 'phone',
            },
          });

          break;
        case 'otherProfessional':
          await makeRemoteInviteOtherProfessionalsAppointment().inviteOtherProfessionals(
            {
              appointmentId: stateParams.appointmentId,
              // TODO: Profissionais externos não possuem ID, perguntar a Leo funcionamento correto
              otherProfessionalId: String(val.otherProfessionalId),
              email: type === 'email',
              whatsapp: type === 'phone',
            },
          );

          break;
        case 'companion':
          await makeRemoteInviteCompanionsAppointment().inviteCompanions({
            appointmentId: stateParams.appointmentId,
            companionId: String(val.companionId),
            email: type === 'email',
            whatsapp: type === 'phone',
          });

          break;
        default:
          break;
      }

      AlertMessage({
        type: 'success',
        message: intl.formatMessage({ id: 'Convite reenviado com sucesso!' }),
      });
    } catch {
      AlertMessage({
        type: 'danger',
        message: intl.formatMessage({ id: 'Falha ao reenviar convite!' }),
      });
    }
  }

  return (
    <Container>
      <TitleInvite>
        <PlusPerson />
        {translator('Adicionar convidado')}
      </TitleInvite>

      <Invite>
        <ContentMultiEmail>
          <Input
            id="addContact"
            // 'Digite o e-mail ou o número do celular para convidar',
            placeholder={translator('Digite o seu email')}
            name="email"
            register={() => register('email')}
            autoFocus
            onChange={e => {
              setEmail(e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ''));
              setValue(
                'email',
                e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
              );
            }}
            value={email}
            error={Boolean(errors?.email)}
            message={errors?.email ? errors?.email.message : ''}
          />
        </ContentMultiEmail>
        <Button
          id="btn_sendInvite"
          rounded
          size="medium"
          height="48px"
          onClick={() => handleAddValue()}
          disabled={
            email === '' || Boolean(data.find(item => item.contact === email))
          }
        >
          {translator('Enviar convite')}
        </Button>
      </Invite>
      <TitleEmail>
        <SharedEmail />
        {translator('Convites Enviados')}
      </TitleEmail>
      <TableContainer component={Paper} className={classes.container}>
        <Table
          className={classes.table}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {/* TODO */}
              {columns.map(item => {
                return (
                  <TableCell>
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isInitialized ? (
              <>
                {columns.map(item => {
                  return (
                    <TableRow>
                      <ListSkeleton columns={columns} />
                    </TableRow>
                  );
                })}
              </>
            ) : (
              data.map((item: iListInvite) => (
                <TableRow key={item.participant}>
                  {columns.map((columnProps: any) => {
                    return (
                      <TableCell
                        align={columnProps.align}
                        style={{
                          maxWidth: columnProps.maxWidth,
                          minWidth: columnProps.minWidth,
                          paddingLeft: columnProps.paddingLeft,
                        }}
                      >
                        <RenderComponents id={columnProps.id} item={item} />
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <InviteLink>
        <Button
          id="btn_openInviteLink"
          variant="secundary"
          size="medium"
          icon={IconShareBlue}
          rounded
          onClick={() =>
            makeReduxActiveMessage().active({
              active: 'copyConsultLink',
              actionOk: () => closeModal(),
              actionCancel: () => closeModal(),
              appointmentLinks: [linkAppointment],
            })
          }
        >
          {translator('Convidar por Link')}
        </Button>
      </InviteLink>
    </Container>
  );
};

export default Link;
