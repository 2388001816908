import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { DeleteShiftsById } from '~/domain/usecases/shifts/remote';

import {
  BadRequestError,
  Conflict,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteDeleteShiftsById implements DeleteShiftsById {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteShiftsById.Model>;

  constructor(url: string, httpClient: HttpClient<DeleteShiftsById.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  delete = async (
    params: DeleteShiftsById.Params,
  ): Promise<DeleteShiftsById.Model> => {
    const url = this.url.replace('{onCallId}', params.onCallId.toString());

    const httpResponse = await this.httpClient.request({
      url: `${url}/${params.shiftId}`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.conflict:
        throw new Conflict();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
