import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { InviteOtherProfessionalsAppointment as UsecaseRemoteInviteOtherProfessionalsAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteInviteOtherProfessionalsAppointment } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteInviteOtherProfessionalsAppointment =
  (): UsecaseRemoteInviteOtherProfessionalsAppointment =>
    new RemoteInviteOtherProfessionalsAppointment(
      makeApiUrl(
        '/appointments/{appointmentId}/otherProfessionals/{otherProfessionalId}/invite',
      ),
      makeHttpClient(),
    );
