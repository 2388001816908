import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
`;

export const Content = styled.div`
  width: 408px;
  /* height: 573px; */

  border-radius: 10px;
  border: 1px #dedede solid;
  background: #fff;
`;

export const Box = styled.div`
  padding: 24px;
`;

export const SelectLabel = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const Text = styled.div`
  color: #222529;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
`;

export const TopContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const ButtonClose = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dedede;
`;

export const Label = styled.div``;

export const InputContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

export const NormalText = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 400;
`;

export const ButtonView = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;

  padding: 1rem;
`;

export const MessageError = styled.label`
  all: unset;

  display: flex;
  position: relative;
  font-style: normal;
  font-weight: normal;
  margin-top: 0.5rem;
  font-size: 12px;
  color: #ea3e4f;
`;
