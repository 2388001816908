import { call, put } from 'redux-saga/effects';
import { iActionGetAllChatMessage } from '~/domain/interfaces/redux/chat/getAllMessage';
import { GetAllChatMessage } from '~/domain/usecases/chat/remote/GetAllChatMessage';
import { makeRemoteGetAllChatMessage } from '~/main/factories/usecases/chat/GetAllChatMessageFactory';
import {
  getAllChatMessageSuccess,
  getAllChatMessageFailed,
} from '~/data/store/reducer/chat/actions/getAllMessage';

export function* onGetAllMessage(action: iActionGetAllChatMessage) {
  const remoteGetAllChat = makeRemoteGetAllChatMessage();

  try {
    const response: GetAllChatMessage.Model = yield call(
      remoteGetAllChat.getAllMessage,
      action.payload,
    );

    yield put(getAllChatMessageSuccess(response));
  } catch (e) {
    yield put(getAllChatMessageFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllMessageSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllMessageFailed() {}
