import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/organizer/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/Organizer/getAll';

import { GetAllOrganizer } from '~/domain/usecases/organizer/redux';

export class ReduxGetAllOrganizer implements GetAllOrganizer {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllOrganizer.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
