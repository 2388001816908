import { call, put, select } from 'redux-saga/effects';
import { iActionGetOne } from '~/domain/interfaces/redux/users/getOne';
import {
  getOneSuccess,
  getOneFailed,
} from '~/data/store/reducer/users/actions/getOne';
import { updateUserInfo } from '~/data/store/reducer/auth/actions/updateUserInfo';
import { makeRemoteGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';
import { GetOneUser } from '~/domain/usecases/users/remote';

import { iStore } from '~/domain/interfaces/models';

export const getId = (store: iStore): number | undefined =>
  store.auth.info.user?.id;

export function* onGetOneRequest(action: iActionGetOne) {
  const remoteGetOneUser = makeRemoteGetOneUser();

  const myUserId: number | undefined = yield select(getId);

  try {
    const response: GetOneUser.Model = yield call(
      remoteGetOneUser.getOne,
      action.payload,
    );

    if (response.user.id === myUserId) yield put(updateUserInfo(response));

    yield put(getOneSuccess(response));
  } catch (e) {
    yield put(getOneFailed());
  }
}

export function* onGetOneSuccess() {
  yield 10;
}

export function* onGetOneFailed() {
  yield 10;
}
