import React from 'react';

import { Content, Message, Image } from './styles/StyledServiceCanceled';
import { CancelBig } from '~/presentation/base/icons';
import { translator } from '../../i18n';

const Canceled: React.FC = () => {
  return (
    <Content>
      <Image>
        <CancelBig />
      </Image>
      <Message>{translator('Atendimento cancelado')}</Message>
      {/* <Action>
        Deseja agendar um novo atendimento?
        <Button rounded size="medium">
          Novo atendimento
        </Button>
      </Action> */}
    </Content>
  );
};

export default Canceled;
