import React from 'react';
import {
  Container,
  Content,
  Text,
  Footer,
  ContainerModal,
} from './styles/StyledModalCancelReport';
import { Icon, Typography, Button } from '@wisecare/design-system-web';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { iMessage } from '~/domain/interfaces/models';

interface propsModalCancelReport {
  message: iMessage;
}

const ModalCancelReport: React.FC<propsModalCancelReport> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.cancelReport;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#FFEFEA',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="warning" color="orange-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Cancelar solicitação
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Você ainda não terminou de solicitar um laudo. Se sair agora,
                  todas as informações serão perdidas. Quer mesmo deixar a
                  página?
                </Typography>
              </Text>
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionOk}>
                Continuar editando
              </Button>
              <Button variant="destructive" onClick={actionCancel}>
                Sim, quero sair
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalCancelReport;
