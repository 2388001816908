import { call, put, select } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { iActionCreate } from '~/domain/interfaces/redux/supportDoc/create';
import { makeRemoteCreateSupportDocs } from '~/main/factories/usecases/supportDoc/CreateSupportDocsFactory';
import { CreateSupportDocs } from '~/domain/usecases/supportDoc/redux';
import {
  createSuccess,
  createFailed,
} from '~/data/store/reducer/supportDoc/actions/create';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onCreate(action: iActionCreate) {
  const supportDoc = makeRemoteCreateSupportDocs();
  try {
    const response: CreateSupportDocs.Model = yield call(
      supportDoc.create,
      action.payload,
    );

    yield put(createSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {}

export function onCreateFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao fazer upload do arquivo.' }),
  });
}
