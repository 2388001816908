import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadByCode as UseCaseRemoteDownloadByCodeSupportDocs } from '~/domain/usecases/supportDoc/remote';

import { RemoteDownloadByCodeSupportDocs } from '~/data/repository/supportDoc';

export const makeRemoteDownloadByCodeSupportDocs =
  (): UseCaseRemoteDownloadByCodeSupportDocs =>
    new RemoteDownloadByCodeSupportDocs(
      makeApiUrl('/supportDocs/'),
      makeHttpClient(),
    );
