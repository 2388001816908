import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllDocType as UsecaseRemoteGetAllDocType } from '~/domain/usecases/docType/remote';

import { RemoteGetAllDocType } from '~/data/repository/docType/RemoteGetAllDocType';

/**
 * send request via API.
 */
export const makeRemoteGetAllDocType = (): UsecaseRemoteGetAllDocType =>
  new RemoteGetAllDocType(makeApiUrl('/docTypes'), makeHttpClient());
