import React, { useEffect } from 'react';
import { Icon, Typography, Button } from '@wisecare/design-system-web';
import {
  BoxIcon,
  Container,
  Content,
  Header,
  Text,
  Professional,
  Footer,
  ContainerModal,
  Priority,
  priorityColorStyle,
} from './styles/StyledModalSuccessReport';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { iMessage, iStore } from '~/domain/interfaces/models';
import { IconFlag } from '~/presentation/base/icons';
import { priorityMap } from '~/utils/mapPriority';
import { useSelector } from 'react-redux';
import { History } from '~/main/routes';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { makeReduxGetAllProfession } from '~/main/factories/usecases/profession';

interface propsModalSuccessReport {
  message: iMessage;
}

const ModalSuccessReport: React.FC<propsModalSuccessReport> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const { results } = useSelector((store: iStore) => store.professions);

  const { results: specialty } = useSelector(
    (store: iStore) => store.specialty,
  );

  const { results: professionals } = useSelector(
    (store: iStore) => store.professional,
  );

  const msgName = MessageOptions.reportSuccess;

  const professionSelected = results?.find(
    item => item.profession?.id === Number(data?.profession),
  );

  const specialtySelected = specialty?.find(
    item => item.specialty?.id === Number(data?.specialty),
  );

  const professionalSelected = professionals?.find(
    item => item.professional?.id === Number(data?.professionalId),
  );

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <Header>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F4FDE9',
                    borderRadius: '9999px',
                    aspectRatio: '1',
                    width: '40px',
                  }}
                >
                  <Icon name="check_circle" color="green-7" size="h5" />
                </div>
                <BoxIcon
                  onClick={() => {
                    actionCancel?.();
                    History.getHistory().push('/appointment');
                  }}
                >
                  <Icon name="close" color="black-1" size="large" />
                </BoxIcon>
              </Header>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Solicitação de laudo enviada com sucesso!
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  As informações sobre a solicitação estão disponíveis abaixo. O
                  profissional também será informado sobre a prioridade de
                  resposta.
                </Typography>
              </Text>
            </Content>
            <Professional>
              {data?.professionalId && (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  <Typography
                    variant="t4_16medium"
                    style={{ fontFamily: 'Roboto' }}
                  >
                    Nome do profissional:
                  </Typography>
                  <Typography
                    variant="b1_16regular"
                    style={{ fontFamily: 'Roboto' }}
                  >
                    {`${professionalSelected?.user?.firstName} ${professionalSelected?.user?.lastName}`}
                  </Typography>
                </div>
              )}
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <Typography
                  variant="t4_16medium"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Profissão:
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{ fontFamily: 'Roboto' }}
                >
                  {professionSelected?.profession?.name}
                </Typography>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <Typography
                  variant="t4_16medium"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Especialidade:
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{ fontFamily: 'Roboto' }}
                >
                  {specialtySelected?.specialty?.name}
                </Typography>
              </div>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <Typography
                  variant="t4_16medium"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Prioridade:
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{ fontFamily: 'Roboto' }}
                >
                  <Priority priority={data?.priority}>
                    <IconFlag />
                    {priorityMap(data?.priority)}
                  </Priority>
                </Typography>
              </div>
            </Professional>
            <Footer>
              <Button size="md" onClick={actionOk} variant="primary">
                Entendi
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalSuccessReport;
