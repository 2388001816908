import { format } from 'date-fns';
import { iShifts } from '~/domain/interfaces/models/Shifts';
import { ShiftsTypes } from '~/domain/interfaces/redux/shifts/types';
import { ShiftsActions } from './actions';

export const initialState: iShifts = {
  loading: false,
  onCallId: 1,
  date: format(new Date(), 'yyyy-MM-dd'),
  shifts: [],
};

const reducer = (state = initialState, action: ShiftsActions): iShifts => {
  switch (action.type) {
    case ShiftsTypes.GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        shifts: [...action.payload.records],
      };

    case ShiftsTypes.SET_ON_CALL_ID:
      return { ...state, onCallId: action.payload };

    case ShiftsTypes.SET_DATE:
      return { ...state, date: action.payload };

    case ShiftsTypes.CREATE:
    case ShiftsTypes.DELETE:
    case ShiftsTypes.UPDATE:
      return { ...state, loading: true };

    case ShiftsTypes.GETALL:
    case ShiftsTypes.GET_BY_ID:
      return { ...state, loading: false };

    case ShiftsTypes.CREATE_SUCCESS:
    case ShiftsTypes.DELETE_SUCCESS:
    case ShiftsTypes.UPDATE_SUCCESS:
    case ShiftsTypes.CREATE_FAILED:
    case ShiftsTypes.DELETE_FAILED:
    case ShiftsTypes.UPDATE_FAILED:
    case ShiftsTypes.GET_BY_ID_FAILED:
    case ShiftsTypes.GETALL_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
