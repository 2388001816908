import React from 'react';
import { translator } from '../i18n';

import { BadgeOutcome } from './styles/StyledOutcome';

interface OutcomeProps {
  outcome:
    | 'PERMANENCE'
    | 'TRANSFER'
    | 'CLINICAL_DISCHARGE'
    | 'REFERRAL'
    | 'DEATH'
    | 'RETURN';
}

const OutcomeStatus: React.FC<OutcomeProps> = ({ outcome }) => {
  if (!outcome) return null;

  return <BadgeOutcome outcome={outcome}>{translator(outcome)}</BadgeOutcome>;
};
export default OutcomeStatus;
