import React from 'react';

import { Container, Icon } from './styles/StyledSidebar';

interface ownProps {
  message: string;
}

const SidebarEmpty: React.FC<ownProps> = ({ message }) => {
  return (
    <Container>
      <Icon />
      <text>{message}</text>
    </Container>
  );
};

export default SidebarEmpty;
