import { InviteOtherProfessionalsAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteInviteOtherProfessionalsAppointment
  implements InviteOtherProfessionalsAppointment
{
  private readonly url: string;

  private readonly httpClient: HttpClient<InviteOtherProfessionalsAppointment.Model>;

  constructor(
    url: string,
    httClient: HttpClient<InviteOtherProfessionalsAppointment.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  inviteOtherProfessionals = async (
    params: InviteOtherProfessionalsAppointment.Params,
  ): Promise<InviteOtherProfessionalsAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url
        .replace('{appointmentId}', params.appointmentId)
        .replace('{otherProfessionalId}', params.otherProfessionalId)}`,
      method: 'post',
      body: {
        ...params,
        appointmentId: undefined,
        otherProfessionalId: undefined,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
