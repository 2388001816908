import { call, put } from 'redux-saga/effects';
import { iActionSocketCreateMessage } from '~/domain/interfaces/redux/chat/socketCreateMessage';
import { CreateSocketChatMessage } from '~/domain/usecases/chat/socket/';
import {
  createSocketChatMessageSuccess,
  createSocketChatMessageFailed,
} from '~/data/store/reducer/chat/actions/socketCreateMessage';

import { makeSocketCreateChatMessage } from '~/main/factories/usecases/chat/CreateChatMessageFactory';

export function* onSocketCreateMessage(action: iActionSocketCreateMessage) {
  const remoteSocketCreateChat = makeSocketCreateChatMessage();

  try {
    const response: CreateSocketChatMessage.Model = yield call(
      remoteSocketCreateChat.createMessage,
      action.payload,
    );

    yield put(createSocketChatMessageSuccess(response));
  } catch (e) {
    yield put(createSocketChatMessageFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onSocketCreateMessageSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onSocketCreateMessageFailed() {}
