import styled from 'styled-components';

export const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dedede;
  background-color: #ffffff;
  width: 100%;
  padding: 16px 72px;
`;

export const FormContent = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  @media (max-width: 1366px) {
    margin-top: 20px;
  }
`;

export const ButtonsContainerPrimary = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const ButtonsContainerSecondary = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;
