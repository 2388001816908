import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllOrganizer as UsecaseRemoteGetAllOrganizer } from '~/domain/usecases/organizer/remote';
import { GetAllOrganizer as UsecaseReduxGetAllOrganizer } from '~/domain/usecases/organizer/redux/GetAllOrganizer';

import { RemoteGetAllOrganizer } from '~/data/repository/organizer/RemoteGetAllOrganizer';
import { ReduxGetAllOrganizer } from '~/data/store/reducer/organizer/usecases/ReduxGetAllOrganizer';

/**
 * send request via API.
 */
export const makeRemoteGetAllOrganizer = (): UsecaseRemoteGetAllOrganizer =>
  new RemoteGetAllOrganizer(makeApiUrl('/organizers/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllOrganizer = (): UsecaseReduxGetAllOrganizer =>
  new ReduxGetAllOrganizer();
