import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateClinicalDocsBySign as UsecaseRemoteUpdateClinicalDocsBySign } from '~/domain/usecases/clinicalDocs/remote';
import { UpdateClinicalDocsBySign as UsecaseReduxUpdateClinicalDocsBySign } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteUpdateClinicalDocsBySign } from '~/data/repository/clinicalDocs';
import { ReduxUpdateClinicalDocsBySign } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteUpdateClinicalDocsBySign =
  (): UsecaseRemoteUpdateClinicalDocsBySign =>
    new RemoteUpdateClinicalDocsBySign(
      makeApiUrl('/clinicalDocs'),
      makeHttpClient(),
    );

export const makeReduxUpdateClinicalDocsBySign =
  (): UsecaseReduxUpdateClinicalDocsBySign =>
    new ReduxUpdateClinicalDocsBySign();
