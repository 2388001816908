import storeDev from '~/data/store';
import { iProfessionalAuth, iStore } from '~/domain/interfaces/models';

export const getProfessionalInfo = (): iProfessionalAuth | undefined => {
  const store: iStore = storeDev.getState();

  const { info, selectUser } = store.auth;

  return info.professionals?.find(item => item.org.id === selectUser.orgId);
};
