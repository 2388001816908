import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateDiagnosisReport as UsecaseRemoteCreateDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteCreateDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */
export const makeRemoteCreateDiagnosisReport =
  (): UsecaseRemoteCreateDiagnosisReport =>
    new RemoteCreateDiagnosisReport(makeApiUrl('/reports'), makeHttpClient());
