import { CreateClinicalDocsByEmail } from '~/domain/usecases/clinicalDocs/redux';
import {
  iActionCreateByEmail,
  ClinicalDocsTypes,
  iActionCreateByEmailFailed,
  iActionCreateByEmailSuccess,
} from '~/domain/interfaces/redux/clinicalDocs/createByEmail';

export const createByEmailRequest = (
  payload: CreateClinicalDocsByEmail.Params,
): iActionCreateByEmail => ({
  type: ClinicalDocsTypes.CREATE_BY_EMAIL,
  payload,
});

export const createByEmailSucess = (
  params: CreateClinicalDocsByEmail.Model,
): iActionCreateByEmailSuccess => ({
  type: ClinicalDocsTypes.CREATE_BY_EMAIL_SUCESS,
  payload: params,
});

export const createByEmailFailed = (): iActionCreateByEmailFailed => ({
  type: ClinicalDocsTypes.CREATE_BY_EMAIL_FAILED,
});
