import styled from 'styled-components';

export const ContainerHeader = styled.div`
  width: 100%;
  padding: 20px;
`;

export const ContentsOrgUnit = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ContentLeft = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: flex-start;
`;

export const OrgLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 248px;
  height: 86px;
  padding: 10px;
  border-radius: 5px;
  background: white;
  object-fit: contain;
  overflow: hidden;
`;

export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const InformationOrg = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h3 {
    color: #004ba5;
    font-weight: bold;
  }
  text {
    color: #000000;
  }
`;

export const ContentRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  #button-secundary {
    color: #ec5c52;
    background: white;
    border: 2px solid #ec5c52;
  }
  #button-secundary:hover {
    color: white;
    background: #ec5c52;
  }
`;

export const Border = styled.div`
  width: 100%;
  border-bottom: 1px solid #bfc4ca;
  padding: 20px;
`;
