import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div``;
export const Signed = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${defaultTheme.primary};
  text {
    padding-left: 5px;
    font-weight: 600;
    font-size: 14px;
  }
`;
export const NotSigned = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: ${defaultTheme.statusNo};
  text {
    padding-left: 5px;
    font-weight: 600;
    font-size: 14px;
  }
`;
export const Processing = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  color: #f79824;
  text {
    padding-left: 5px;
    font-weight: 600;
    font-size: 14px;
  }
`;
