import React from 'react';
import { Container, ButtonColapse, Content } from './styles';
import { IconArrow, IconArrowSelect } from '~/presentation/base/icons';

interface ownProps {
  title: string;
  children: React.ReactNode;
}

const InfoColapse = ({ title, children }: ownProps) => {
  const [colapsed, setColapsed] = React.useState(true);

  return (
    <Container>
      <div className="title">
        <strong>{title}</strong>
        <ButtonColapse
          isColapsed={colapsed}
          onClick={() => {
            setColapsed(!colapsed);
          }}
        >
          <IconArrowSelect />
        </ButtonColapse>
      </div>
      {colapsed && <Content>{children}</Content>}
    </Container>
  );
};

export default InfoColapse;
