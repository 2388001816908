import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { makeRemoteGetClinicalDocsByDownload } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsByDownloadFactory';
import MediaError from './MediaError';
import { Content } from './styles/StyledMediaViewer';

interface Media {
  link?: string;
  id?: string;
  metadata?: {
    patientName?: string;
  };
}

const MediaViewer: React.FC<Media> = ({ link, id, metadata }) => {
  const [error, setError] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | undefined>();

  const requestfile = useCallback(() => {
    if (link !== undefined) {
      return axios.get(`${link}`, {
        headers: { 'Content-Type': 'application/octet-stream' },
        responseType: 'blob',
      });
    }

    return makeRemoteGetClinicalDocsByDownload().getByDownload({
      docId: parseInt(`${id}`, 10),
    });
  }, [id, link]);

  const fileTitle = useMemo(() => {
    const now = new Date();

    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    return `${year}${month}${day}.${hours}${minutes}.${metadata?.patientName}.pdf`;
  }, [metadata?.patientName]);

  const loadfile = useCallback(async () => {
    try {
      const data = await requestfile();

      const fileURL = URL.createObjectURL(data as any);

      setFileUrl(fileURL);
      setError(false);
    } catch (e) {
      setFileUrl(undefined);
      setError(true);
    }
  }, [requestfile]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator: () => {
          return fileTitle;
        },
      },
    },
  });

  useEffect(() => {
    loadfile();
  }, [link, id, loadfile]);

  return (
    <Content id="containerPDF">
      {!error && fileUrl && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/legacy/build/pdf.worker.js">
          <Viewer
            fileUrl={fileUrl}
            plugins={[defaultLayoutPluginInstance]}
            enableSmoothScroll
            defaultScale={1}
          />
        </Worker>
      )}

      {error && <MediaError />}
    </Content>
  );
};

export default MediaViewer;
