import { GetAllProfessional } from '~/domain/usecases/professionalList/redux/GetAllProfessional';
import {
  iActionGetAll,
  iActionGetAllSucess,
  iActionGetAllFailed,
  ProfessionalTypes,
} from '~/domain/interfaces/redux/professionalList/getAll';

export const getAllRequest = (
  payload: GetAllProfessional.Params,
): iActionGetAll => ({
  type: ProfessionalTypes.GETALL,
  payload,
});

export const getAllSucess = (
  params: GetAllProfessional.Model,
): iActionGetAllSucess => ({
  type: ProfessionalTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: ProfessionalTypes.GETALL_FAILED,
});
