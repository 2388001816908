import { Dispatch } from 'redux';
import store from '~/data/store';
import { AppointmentFiltered } from '~/domain/usecases/appointment/redux';
import { iActionAppointmentFiltered } from '~/domain/interfaces/redux/appointment/appointmentFiltered';
import { filterRequest } from '../actions/appointmentFiltered';

export class ReduxAppointmentFiltered implements AppointmentFiltered {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  post = (params: AppointmentFiltered.Params): iActionAppointmentFiltered =>
    this.send(filterRequest(params));
}
