import { enum as a, number, object, string } from 'zod';
import { validateCpf } from '~/utils/validCpf';

export const schemaJoinOnCall = object({
  name: string().nullable().optional(),
  specialty: number().nonnegative({ message: 'Especialidade inválida' }),
  age: number().optional(),
  sex: a(['NONE', 'M', 'F']).nullable().optional(),
  situation: string().nullable().optional(),
  susCard: string().optional(),
  organization: number().nonnegative({ message: 'Organização inválida' }),
  regType: string().nullable().optional(),
  regValue: string().nullable().optional(),
  uf: string().optional(),
  birthDate: string().nullable().optional(),
  city: string().optional(),
  priority: a(['LOW', 'NORMAL', 'EMERGENCY']).optional(),
}).refine(
  data => {
    if (
      data?.regType === 'CPF' &&
      data?.regValue &&
      !validateCpf(data?.regValue)
    ) {
      return false;
    }
    return true;
  },
  {
    message: 'CPF inválido',
    path: ['regValue'],
  },
);
