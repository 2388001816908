import styled from 'styled-components';

export const Footer = styled.div`
  margin-top: 20px;
  grid-area: footer;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .left {
    max-width: 150px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  .right {
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }

  #btn_prev_step {
    max-width: 150px;
    width: 100%;
  }

  #btn_next_step {
    max-width: 150px;
    width: 100%;
  }
`;
