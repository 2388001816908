import styled from 'styled-components';
import { BackgroundStyledProps } from '../interface';

export const Container = styled.div`
  max-height: 300px;
  min-width: 300px;
  border: 1px solid ${props => props.theme.typographyLightGray};
  overflow-y: hidden;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 10px;
  border-bottom: 1px solid ${props => props.theme.typographyDarkGray};
  svg {
    cursor: pointer;
  }
`;

export const Links = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${props => props.theme.highlight};
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  button {
    background: transparent;
    color: ${props => props.theme.highlight};
    border: none;
    cursor: pointer;
    font-weight: 500;
    :hover {
      color: ${props => props.theme.primaryLight};
    }
  }
`;

export const More = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: ${props => props.theme.highlight};
  font-size: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  button {
    background: transparent;
    color: ${props => props.theme.highlight};
    border: none;
    cursor: pointer;
    font-weight: 500;
    :hover {
      color: ${props => props.theme.primaryLight};
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  max-height: 200px;
  padding-left: 10px;
  padding-right: 10px;
  gap: 20px;
  overflow-y: scroll;
`;

export const Item = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const Icon = styled.div<BackgroundStyledProps>`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, color }) => theme[color]};
`;

export const Infos = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

export const Name = styled.span`
  font-weight: bold;
  font-size: 10px;
`;
export const Action = styled.span`
  font-size: 10px;
`;

export const Description = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Role = styled.span`
  font-size: 10px;
  color: ${props => props.theme.typographyDarkGray};
`;

export const NotificationDate = styled.span`
  font-size: 10px;
  color: ${props => props.theme.typographyDarkGray};
`;
