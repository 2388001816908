/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  CalendarAlternative,
  HandShake,
  ModalityInPerson,
  ModalityOnline,
  WarningAlternative,
  WarningAlternativeRed,
} from '~/presentation/base/icons';

// TODO: Update this component translation
import { translator } from '~/presentation/components/i18n';

import { GetAppointmentById } from '~/domain/usecases/appointment/remote';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory';
import { AvatarImage } from '../avatar-roles';
import { AlertMessage } from '../messages/AlertMessage';
import {
  Body,
  CheckBoxInput,
  ConsultInfo,
  ConsultInfoContainer,
  Container,
  Footer,
  FooterContent,
  Header,
  Message,
  MessagesContainer,
  MessagesScroll,
  ProfessionalInfoContainer,
  TextBold,
  TextNormal,
  TextNormalBlackFour,
} from './styles';

type CardWaitingRoomDetailsProps = {
  appointmentId: number;
  role: 'PRO' | 'CON';
};

const MockedMessages = [
  'Seja bem-vindo à sala de espera virtual. Sua consulta começará em breve e você será notificado quando chegar a sua vez.',
  'O profissional foi avisado que você está esperando. Aproveite este momento para ajustar sua câmera e microfone.',
];

const CardWaitingRoomDetails: React.FC<CardWaitingRoomDetailsProps> = ({
  appointmentId,
  role,
}) => {
  const [checkboxState, setCheckboxState] = useState<boolean>(false);
  const [modality, setModality] = useState<'REMOTE' | 'INPERSON'>('REMOTE');
  const [priority, setPriority] = useState<'high' | 'low'>('low');
  const [messages, setMessages] = useState<string[]>([]);

  const getLocale = () => {
    return localStorage.getItem('i18nextLng') ?? 'pt-BR';
  };

  const now = Intl.DateTimeFormat(getLocale(), {
    hour: '2-digit',
    minute: '2-digit',
  }).format(new Date());

  const [appointment, setAppointment] = useState<GetAppointmentById.Model>(
    {} as GetAppointmentById.Model,
  );

  const userName = useMemo(
    () =>
      role === 'CON'
        ? `${appointment?.professional?.firstName} 
              ${appointment?.professional?.lastName}`
        : `${appointment?.consultant?.firstName}
              ${appointment?.consultant?.lastName}`,
    [appointment, role],
  );

  const formattedScheduleDate = useMemo(() => {
    const hasScheduled = appointment?.appointment?.scheduled ?? false;

    return Intl.DateTimeFormat(getLocale(), {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
      .format(new Date(hasScheduled ? appointment?.appointment?.scheduled : 0))
      .replace(',', ', às');
  }, [appointment?.appointment?.scheduled]);

  const renderMessages = useMemo(() => {
    return (
      <MessagesScroll>
        {messages.map((message, index) => {
          return (
            <>
              <TextNormal>
                {translator('Mensagem automática')} - {now}
              </TextNormal>
              <Message key={message}>
                <TextNormalBlackFour>{message}</TextNormalBlackFour>
              </Message>
            </>
          );
        })}
      </MessagesScroll>
    );
  }, [messages, now]);

  const renderModality = useMemo(() => {
    if (appointment?.appointment?.modality === 'REMOTE') {
      return (
        <ConsultInfo>
          <ModalityOnline />
          <TextNormal>{translator('Modalidade on-line')}</TextNormal>
        </ConsultInfo>
      );
    }
    return (
      <ConsultInfo>
        <ModalityInPerson />
        <TextNormal>{translator('Modalidade presencial')}</TextNormal>
      </ConsultInfo>
    );
  }, [appointment?.appointment?.modality]);

  const renderPriority = useMemo(() => {
    if (appointment?.priority?.id) {
      return (
        <ConsultInfo>
          <WarningAlternativeRed />
          <TextNormal>{translator('Atendimento prioritário')}</TextNormal>
        </ConsultInfo>
      );
    }
    return (
      <ConsultInfo>
        <WarningAlternative />
        <TextNormal>{translator('Atendimento não-prioritário')}</TextNormal>
      </ConsultInfo>
    );
  }, [appointment?.priority?.id]);

  const getAppointmentById = useCallback(async () => {
    try {
      const response = await makeRemoteGetAppointmentById().getAppointmentById({
        id: appointmentId,
      });
      setAppointment(prevState => ({ ...prevState, ...response }));
    } catch (error) {
      AlertMessage({
        message: 'Erro ao carregar dados da consulta',
        type: 'danger',
      });
    }
  }, [appointmentId]);

  const handleSendFirstMessage = () => {
    setTimeout(() => {
      setMessages(prev => [...prev, MockedMessages[0]]);
    }, 1000);

    setTimeout(() => {
      setMessages(prev => [...prev, MockedMessages[1]]);
    }, 2000);
  };

  const handleCheckboxSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxState(e.target.checked);
  };

  useEffect(() => {
    getAppointmentById();

    if (role === 'CON') handleSendFirstMessage();
  }, [getAppointmentById, role]);

  return (
    <Container>
      <Header>
        <AvatarImage
          size="root"
          name={userName}
          src={
            role === 'PRO'
              ? appointment?.consultant?.avatar
              : appointment?.professional?.avatar
          }
        />
        <ProfessionalInfoContainer>
          <TextBold>{userName}</TextBold>
          {role === 'CON' && (
            <TextNormal>
              {appointment?.specialty?.name}, CRM{' '}
              {appointment?.professional?.docProf}
            </TextNormal>
          )}
        </ProfessionalInfoContainer>
      </Header>
      <Body>
        <ConsultInfoContainer>
          <TextBold>{translator('Informações da consulta')}</TextBold>
          <ConsultInfo>
            <CalendarAlternative />
            <TextNormal>{formattedScheduleDate}</TextNormal>
          </ConsultInfo>
          {appointment?.healthPlan?.id && (
            <ConsultInfo>
              <HandShake />
              <TextNormal>{translator('Convênio particular')}</TextNormal>
            </ConsultInfo>
          )}
          {renderModality}
          {renderPriority}
        </ConsultInfoContainer>
        <MessagesContainer>{renderMessages}</MessagesContainer>
      </Body>
      {/* {role === 'CON' && (
        <Footer>
          <FooterContent>
            <CheckBoxInput
              type="checkbox"
              checked={checkboxState}
              onChange={e => {
                handleCheckboxSelection(e);
              }}
            />
            <TextNormalBlackFour>
              {translator(
                'Quero ser notificado quando faltar apenas um paciente para o meu atendimento.',
              )}
            </TextNormalBlackFour>
          </FooterContent>
        </Footer>
      )} */}
    </Container>
  );
};

export default CardWaitingRoomDetails;
