import { ListOnCallRequest } from '~/domain/usecases/onCallRequest/redux/ListOnCallRequest';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  OnCallRequestTypes,
} from '~/domain/interfaces/redux/onCallRequest/list';

export const listRequest = (
  payload: ListOnCallRequest.Params,
): iActionList => ({
  type: OnCallRequestTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListOnCallRequest.Model,
): iActionListSuccess => ({
  type: OnCallRequestTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: OnCallRequestTypes.LIST_FAILED,
});
