import { Dispatch } from 'redux';
import store from '~/data/store';
import { iActionCreateShifts } from '~/domain/interfaces/redux/shifts/create';

import { CreateShifts } from '~/domain/usecases/shifts/redux';
import { createShiftsRequest } from '../actions/create';

export class ReduxCreateShifts implements CreateShifts {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateShifts.Params): iActionCreateShifts =>
    this.send(createShiftsRequest(params));
}
