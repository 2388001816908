import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models/Store';

import { WaitingRoom as WaitingRoomProfessional } from '~/presentation/roles/professional/pages';

import { PageNotFound } from '~/presentation/pages/errors';
import { MakeWaitingRoomParticipant } from './WaitingRoomParticipantFactory';

export const MakeWaitingRoom: React.FC = (): JSX.Element => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  switch (role) {
    case 'ORG':
      return <WaitingRoomProfessional role="PRO" />;
    case 'PRO':
      return <WaitingRoomProfessional role="PRO" />;
    case 'CON':
      return <MakeWaitingRoomParticipant />;
    default:
      return <PageNotFound />;
  }
};
