import styled from 'styled-components';

export const ContainerGuest = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  input[type='text'] {
    width: 475px;
    padding: 15px;
    border-radius: 5px;
    border: 2px solid #dcdcdc;
    font-size: 15px;
  }
  h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;
