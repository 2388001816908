import { GetACL } from '~/domain/usecases/permissions/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteGetACL implements GetACL {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetACL.Model>;

  constructor(url: string, httClient: HttpClient<GetACL.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (params: GetACL.Params): Promise<GetACL.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.id}/acl`,
      method: 'get',
      headers: {
        accept: 'application/json',
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
