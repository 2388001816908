import React, { useState } from 'react';

import { Container, Img, Initials } from './styles/StyledAvatarWithUploader';

import Uploader from '../uploader';

interface AvatarProps {
  src: string;
  size?: string;
  fullName: string;
}

const AvatarWithUploader: React.FC<AvatarProps> = ({ src, size, fullName }) => {
  const [error, setError] = useState(false);
  const formatName = fullName.toUpperCase().split(' ');

  const initials = `${formatName[0][0]}${formatName[1][0]}`;

  return (
    <Container>
      {error ? (
        <Initials size={size}>
          {formatName[1].length ? initials : formatName[0][0]}
        </Initials>
      ) : (
        <Img src={src} size={size} onError={e => setError(true)} />
      )}

      <div id="uploader">
        <Uploader setUpdate={setError} />
      </div>
    </Container>
  );
};

export default AvatarWithUploader;
