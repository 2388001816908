import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetDetailsInstantAppointment as UseCaseRemoteGetDetailsInstantAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteGetDetailsInstantAppointment } from '~/data/repository/appointment/RemoteGetDetailsInstantAppointment';

/**
 * send request via API.
 */
export const makeRemoteGetDetailsInstantAppointment =
  (): UseCaseRemoteGetDetailsInstantAppointment =>
    new RemoteGetDetailsInstantAppointment(
      makeApiUrl('/appointments/'),
      makeHttpClient(),
    );
