import { Dispatch } from 'redux';
import store from '~/data/store';
import { createRequest } from '~/data/store/reducer/appointment/actions/addAppointmentData';

import { iActionAddAppointmentData } from '~/domain/interfaces/redux/appointment/addApointmentData';

import { AddAppointmentData } from '~/domain/usecases/appointment/redux';

export class ReduxAddAppointmentData implements AddAppointmentData {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  add = (params: AddAppointmentData.Params): iActionAddAppointmentData =>
    this.send(createRequest(params));
}
