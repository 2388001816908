import { GetAllAppointmentPriorities } from '~/domain/usecases/appointmentPriorities/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteGetAllAppointmentPriorities
  implements GetAllAppointmentPriorities
{
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllAppointmentPriorities.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<GetAllAppointmentPriorities.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  list = async (
    params: GetAllAppointmentPriorities.Params,
  ): Promise<GetAllAppointmentPriorities.Model> => {
    let query = '';

    if (params.query)
      Object.entries(params.query).forEach(([key, value]) => {
        query += `${key}=${value}&`;
      });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
    });

    console.log('GetAllAppointmentPriorities request: ', httpResponse);

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
