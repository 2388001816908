import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  height: 46px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background: #f4f7fe;
  color: #004ba5;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
`;

export const Main = styled.main`
  width: 100%;
  height: 95%;
`;

export const ButtonStyle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Phases = styled.div`
  width: 100%;
  padding: 10px;
`;

export const ContentSignature = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
`;
