import React from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

import { Input } from '../UI';
import { Container, Form } from './styles/StyledGuest';
import { translator } from '../i18n';
import { iRegisterAppointment } from './interface';

import { Navigator } from './Navigator';

import { schemaGuest } from '~/validation/validators/appointment/CreateAppointmentValidator';

interface ownProps {
  next: (data: iRegisterAppointment) => any;
  back: (data: iRegisterAppointment) => any;
  state?: iRegisterAppointment;
}

const Guest: React.FC<ownProps> = ({ state, next, back }): JSX.Element => {
  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGuest),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  const onBack = () => back({ ...getValues() });

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          id="appointmentAddress"
          name="email"
          label={translator('E-mail')}
          placeholder={translator('Digite o(s) e-mail(s) para convidar')}
          onChange={e => setValue('email', e.target.value)}
          defaultValue={state?.email}
          register={() => register('email')}
          error={Boolean(errors.email)}
          // TODO: Adicionar i18n consertar renderização
          message={errors?.email?.message && 'E-mail inválido'}
          autoFocus
        />
        <Input
          id="appointmentName"
          width="410px"
          name="name"
          label={translator('Nome')}
          placeholder={translator('Digite o nome do convidado')}
          onChange={e => setValue('name', e.target.value)}
          defaultValue={state?.name}
          register={() => register('name')}
          error={Boolean(errors.name)}
          message={errors.name?.message ? translator(errors.name?.message) : ''}
        />
        <Navigator isFinish back={onBack} />
      </Form>
    </Container>
  );
};

export default Guest;
