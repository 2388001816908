import React from 'react';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  Body,
  Container,
  Content,
  FirstMessage,
  Header,
  Message,
} from './styles/StyledReadyMessages';

import { Button } from '../../UI';

import { ownProps } from '../interface';

import { translator } from '../../i18n';

const ReadyMessage: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.ready;

  const { active, actionCancel, actionOk } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>{translator('Pronto!')}</span>
            </Header>
            <Content>
              <FirstMessage>
                {translator('Você já pode participar do atendimento')}
              </FirstMessage>
              <Message>
                <span>{translator('Clique em entrar para iniciar')}</span>
              </Message>
            </Content>
            <Button
              rounded
              variant="primary"
              size="large"
              onClick={actionOk}
              autoFocus
            >
              {translator('Entrar')}
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default ReadyMessage;
