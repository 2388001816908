import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  background: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

const Text = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
`;

export const Name = styled(Text)`
  font-size: 16px;
  font-weight: bold;
`;

export const Roles = styled(Text)`
  font-size: 14px;
`;

export const Info = styled.div`
  margin: 0px 10px;
  text-align: end;
`;
