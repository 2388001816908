import { call, put } from 'redux-saga/effects';
import {
  iActionJoinWaitingRoom,
  iActionJoinSuccessWaitingRoom,
} from '~/domain/interfaces/redux/waitingroom/join';
import { JoinWaitingRoom } from '~/domain/usecases/waitingRoom/socket';

import {
  JoinSuccess,
  Joinfailed,
} from '~/data/store/reducer/waitingroom/action/join';

import { makeSocketJoinWaitingRoom } from '~/main/factories/usecases/waitingRoom/JoinWaitingRoomFactory';

export function* onJoin(action: iActionJoinWaitingRoom) {
  const waitingRoom = makeSocketJoinWaitingRoom();

  try {
    const response: JoinWaitingRoom.Model = yield call(
      waitingRoom.join,
      action.payload,
    );

    yield put(JoinSuccess(response));
  } catch (e) {
    console.error('error: ', e);
    yield put(Joinfailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onJoinSuccess(action: iActionJoinSuccessWaitingRoom) {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onJoinFailed() {}
