import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteGetCitiesByUf implements GetCitiesByUf {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetCitiesByUf.Model>;

  constructor(url: string, httpClient: HttpClient<GetCitiesByUf.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getCitiesByUf = async (
    params: GetCitiesByUf.Params,
  ): Promise<GetCitiesByUf.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url.replace('{stateId}', params.uf)}`,
      method: 'get',
    });

    console.log('GetCitiesByUf request: ', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
