import { GetAllAppointmentType } from '~/domain/usecases/appointmentType/redux/GetAllAppointmentType';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  AppointmentTypeTypes,
} from '~/domain/interfaces/redux/appointmentType/getAll';

export const getAllRequest = (
  payload: GetAllAppointmentType.Params,
): iActionGetAll => ({
  type: AppointmentTypeTypes.GET_ALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllAppointmentType.Model,
): iActionGetAllSuccess => ({
  type: AppointmentTypeTypes.GET_ALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: AppointmentTypeTypes.GET_ALL_FAILED,
});
