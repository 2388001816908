import { Dispatch } from 'redux';
import store from '~/data/store';
import { createRequest } from '~/data/store/reducer/Consultant/actions/create';

import { iActionCreate } from '~/domain/interfaces/redux/Consultant/create';

import { CreateConsultant } from '~/domain/usecases/consultant/redux';

export class ReduxCreateConsultant implements CreateConsultant {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateConsultant.Params): iActionCreate =>
    this.send(createRequest(params));
}
