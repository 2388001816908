import {
  iActionSetData,
  UsersTypes,
} from '~/domain/interfaces/redux/dataPagination/setData';

import { SetData } from '~/domain/usecases/dataPagination/redux/SetData';

export const setData = (params: SetData.Params): iActionSetData => ({
  type: UsersTypes.SET_DATA,
  payload: params,
});
