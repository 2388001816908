import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListOnCallSpecialist as UsecaseRemoteListOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
import { ListOnCallSpecialist as UsecaseReduxListOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemoteListOnCallSpecialist } from '~/data/repository/onCallSpecialist';
import { ReduxListOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOnCallSpecialist =
  (): UsecaseRemoteListOnCallSpecialist =>
    new RemoteListOnCallSpecialist(
      makeApiUrl('/oncall/{onCallId}/specialists/list'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListOnCallSpecialist =
  (): UsecaseReduxListOnCallSpecialist => new ReduxListOnCallSpecialist();
