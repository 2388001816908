import styled from 'styled-components';

export const Container = styled.form`
  display: flex;
  flex-direction: column;

  gap: 10px;
`;

export const PosologyContent = styled.div`
  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #444a51;
    font-family: 'Roboto', sans-serif;
  }
`;

export const PosologyInput = styled.textarea`
  width: 100%;
  height: 81px;

  background: #fff;
  border: 1px solid #bfc4ca;
  border-radius: 4px;

  color: #444a51;
  padding: 10px 0 30px 15px;
  margin-top: 4px;

  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;

  resize: none;

  ::placeholder {
    color: #bfc4ca;
    font-size: 16px;
    font-weight: normal;

    font-family: 'Roboto', sans-serif;
  }
`;

export const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px 0px 15px;

  @media (max-height: 720px) {
    width: 100%;
    overflow-y: auto;
    max-height: 500px;

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 15px 0px 15px;
  }
`;

export const InfoAddDrugs = styled.div``;

export const InfoAddDrugsText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #444a51;
  font-family: 'Roboto', sans-serif;
`;

export const ListDrugsTitle = styled.div`
  p {
    font-family: 'Arial', sans-serif;
    font-weight: normal;
    font-size: 12px;
    color: #444a51;
    font-family: 'Roboto', sans-serif;
  }
`;

export const ContainerListDrugs = styled.div`
  width: 100%;
  overflow-y: auto;
  max-height: 300px;

  /* background-color: #f4f7f3; */
`;

export const ContainerItemDrugs = styled.div`
  margin-bottom: 20px;
  max-width: 100%;
  min-width: 209px;
  min-height: 200px;
  background: #f4f7fe;
`;

export const ListDrugs = styled.div`
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: space-between;

  border-radius: 4px;
  border-bottom: 1px solid #d1e1fc;

  padding: 20px;
`;

export const DrugsAndDosage = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  gap: 15px;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    color: #1c1413;
  }

  h2 {
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #7a7a7a;
  }
`;

export const DosageAndOptions = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;

    color: #1756e8;
  }
`;

export const ButtonAdd = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
