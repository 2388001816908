import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdfdfd;

  @media (min-width: 390px) and (max-width: 430px) {
    padding: 1rem;
  }
`;

export const InviteOtherProfessionalContainer = styled.div`
  width: 100%;
  max-width: 40rem;
  border: 1px solid #dedede;
  border-radius: 1.6rem;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.4rem 0 2.4rem 0;
  border-bottom: 1px solid #dedede;

  @media (min-width: 1366px) and (max-width: 1919px) {
    padding: 1rem 0 1rem 0;
  }

  @media (min-width: 390px) and (max-width: 430px) {
    padding: 1.6rem 0 1.6rem 0;
  }
`;

export const TitleAndSubtitle = styled.div`
  padding: 1.2rem 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  border-bottom: 1px solid #dedede;

  @media (min-width: 1366px) and (max-width: 1919px) {
    padding: 1.2rem 1.4rem;
    gap: 1rem;
  }

  @media (min-width: 390px) and (max-width: 430px) {
    gap: 1rem;
    padding: 1rem 1.4rem;
  }
`;

export const Title = styled.strong``;

export const Description = styled.span`
  color: #62666c;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
  gap: 1.6rem;
  border-bottom: 1px solid #dedede;

  @media (min-width: 1366px) and (max-width: 1919px) {
    padding: 1.4rem;
    gap: 1rem;
  }

  @media (min-width: 390px) and (max-width: 430px) {
    gap: 1rem;
    padding: 1rem;
  }
`;

export const InlineInputs = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;

  @media (min-width: 1366px) and (max-width: 1919px) {
    gap: 1rem;
  }

  @media (min-width: 390px) and (max-width: 430px) {
    white-space: nowrap;
    gap: 1rem;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem 2.4rem;

  @media (min-width: 390px) and (max-width: 430px) {
    gap: 1rem;
    padding: 1rem 1.4rem;
  }
`;
