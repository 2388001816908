import { Dispatch } from 'redux';
import store from '~/data/store';
import { iActionUpdateShifts } from '~/domain/interfaces/redux/shifts/update';

import { UpdateShifts } from '~/domain/usecases/shifts/redux';
import { updateShiftsRequest } from '../actions/update';

export class ReduxUpdateShifts implements UpdateShifts {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateShifts.Params): iActionUpdateShifts =>
    this.send(updateShiftsRequest(params));
}
