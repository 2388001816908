import styled from 'styled-components';

export const ContainerTerms = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1038px;
  max-height: 700px;
  border-radius: 20px;
  background: #ffffff;
`;

export const Header = styled.div`
  width: 100%;
  height: 10em;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 4px;
  border-bottom: 2px solid #efefef;

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #000000;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const ButtonClose = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  padding-right: 30px;
`;

export const ContentTermsOfUse = styled.div`
  width: 98%;
  height: 50%;
  margin-top: 10px;
  padding-left: 30px;
  padding-bottom: 20px;
  overflow-y: auto;
`;

export const TextTerms = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  line-height: 1.3;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: space-between;
  gap: 15px;
`;

export const FooterColumnLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
`;

export const FooterColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const Text = styled.a`
  padding: 5px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-top: 2px;
  line-height: 24px;
  color: #7a7a7a;
`;
