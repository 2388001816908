import { call, put, select } from 'redux-saga/effects';
import { iActionList } from '~/domain/interfaces/redux/onCallRequester/list';
import { ListOnCallRequester } from '~/domain/usecases/onCallRequester/remote/ListOnCallRequester';
import { makeRemoteListOnCallRequester } from '~/main/factories/usecases/onCallRequester/ListOnCallRequesterFactory';
import {
  listSuccess,
  listFailed,
} from '~/data/store/reducer/onCallRequester/actions/list';

export function* onList(action: iActionList) {
  const remoteListOnCallRequester = makeRemoteListOnCallRequester();

  try {
    const response: ListOnCallRequester.Model = yield call(
      remoteListOnCallRequester.list,
      action.payload,
    );
    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
