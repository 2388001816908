import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { iProfessional } from '~/domain/interfaces/models/Professional';
import { translator } from '~/presentation/components/i18n';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { Input } from '~/presentation/components/UI';
import { schemaGeneralData } from '~/validation/validators/document/CreateClinicalDocSideBarValidator';
import { iStore } from '~/domain/interfaces/models';
import { iListConsultant, iValidation } from '../interface';
import Navigator from '../Navigator';
import { Container, Form } from '../styles/StyledGeneralData';
import _ from 'lodash';
import SearchSelect from '~/presentation/components/UI/searchSelect';

export interface GeneralDataProps {
  consultant?: iListConsultant[];
  professional: iProfessional;
  next: (data: iValidation) => void;
  state?: iValidation;
}

interface iStateParams {
  appointmentId: string;
  professionalId: number;
  consultantId: number;
}

const GeneralData: React.FC<GeneralDataProps> = ({
  professional,
  next,
  state,
}): JSX.Element => {
  const { info } = useSelector((store: iStore) => store.auth);
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  let stateParams = useLocation<iStateParams>().state;
  stateParams = {
    ...stateParams,
    professionalId:
      info.professionals?.find(prof => prof.org.id === orgId)?.id ??
      stateParams.professionalId,
  };

  const [professionalId, setProfId] = useState<number | undefined>(
    stateParams.professionalId,
  );

  const { errors, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      ...state,
      orgUnit: 1,
      org: 1,
    },
  });

  register('orgUnit');

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  useEffect(() => {
    register('professional');
    if (stateParams.professionalId) {
      setProfId(stateParams.professionalId);
      setValue('professional', stateParams.professionalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateProfessionalSelectOptions = useCallback(() => {
    return _.sortBy(
      professional?.results.flatMap(value => {
        return [
          {
            value: value.professional.id,
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [professional]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionalSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionalSelectOptions, watch]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          name="org"
          label={`${translator('Instituição')}`}
          onChange={e => setValue('org', Number(e.target.value))}
          register={() => register('org')}
          error={Boolean(errors.org)}
          message={errors.org?.message}
          placeholder={state?.orgUnitName}
          disabled={state && state.orgUnitName !== ''}
          autoFocus
          required
        />

        <SearchSelect
          name="professional"
          register={() => register('professional')}
          label={`${translator('Profissional')}`}
          placeholder={translator('Selecione um profissional')}
          onChange={e => setValue('professional', Number(e?.value))}
          error={Boolean(errors.professional)}
          message={
            errors.professional?.message
              ? translator(errors.professional?.message)
              : ''
          }
          isDisabled={stateParams && Number(stateParams.professionalId) !== -1}
          required
          options={generateProfessionalSelectOptions()}
          value={professionalMultiselectSelected}
        />

        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
          disabled
        />
        <div style={{ marginTop: '200px' }}>
          <Navigator />
        </div>
      </Form>
    </Container>
  );
};

export default GeneralData;
