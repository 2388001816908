import { call, put } from 'redux-saga/effects';
import {
  getProfessionalOnDutyFailed,
  getProfessionalOnDutySuccess,
} from '~/data/store/reducer/duty/actions/getProfessionalOnDuty';
import { iActionGetProfessionalOnDuty } from '~/domain/interfaces/redux/duty/getProfessionalOnDuty';
import { GetProfessionalOnDuty } from '~/domain/usecases/duty/remote/GetProfessionalOnDuty';

import { makeRemoteGetProfessionalOnDuty } from '~/main/factories/usecases/duty/GetProfessionalsOnDuty';

export function* onGetProfessionalOnDuty(action: iActionGetProfessionalOnDuty) {
  const remoteGetProfessionalOnDuty = makeRemoteGetProfessionalOnDuty();

  try {
    const response: GetProfessionalOnDuty.Model = yield call(
      remoteGetProfessionalOnDuty.get,
      action.payload,
    );

    yield put(getProfessionalOnDutySuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getProfessionalOnDutyFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetProfessionalOnDutySuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetProfessionalOnDutyFailed() {}
