import {
  AuditRecordTypes,
  iActionGetAuditRecord,
  iActionGetAuditRecordFail,
  iActionGetAuditRecordSuccess,
} from '~/domain/interfaces/redux/auditRecord/GetAuditRecord';

import { GetAuditRecord } from '~/domain/usecases/AuditRecord/redux';

export const getAuditRecord = (
  payload: GetAuditRecord.Params,
): iActionGetAuditRecord => ({
  type: AuditRecordTypes.GET_AUDIT_RECORD,
  payload,
});

export const getAuditRecordSuccess = (
  payload: GetAuditRecord.Model,
): iActionGetAuditRecordSuccess => ({
  type: AuditRecordTypes.GET_AUDIT_RECORD_SUCCESS,
  payload,
});

export const getAuditRecordFail = (): iActionGetAuditRecordFail => ({
  type: AuditRecordTypes.GET_AUDIT_RECORD_FAIL,
});
