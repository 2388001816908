import { Dispatch } from 'redux';
import store from '~/data/store';
import { StopAppointment } from '~/domain/usecases/appointment/redux';
import { iActionStop } from '~/domain/interfaces/redux/appointment/stop';
import { stopRequest } from '../actions/stop';

export class ReduxStopAppointment implements StopAppointment {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  stop = (params: StopAppointment.Params): iActionStop =>
    this.send(stopRequest(params));
}
