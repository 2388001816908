export enum ShiftsTypes {
  CREATE = '@shifts/CREATE',
  CREATE_SUCCESS = '@shifts/CREATE_SUCCESS',
  CREATE_FAILED = '@shifts/CREATE_FAILED',
  GET_BY_ID = '@shifts/GET_BY_ID',
  GET_BY_ID_SUCCESS = '@shifts/GET_BY_ID_SUCCESS',
  GET_BY_ID_FAILED = '@shifts/GET_BY_ID_FAILED',
  GETALL = '@shifts/GETALL',
  GETALL_SUCCESS = '@shifts/GETALL_SUCCESS',
  GETALL_FAILED = '@shifts/GETALL_FAILED',
  UPDATE = '@shifts/UPDATE',
  UPDATE_SUCCESS = '@shifts/UPDATE_SUCCESS',
  UPDATE_FAILED = '@shifts/UPDATE_FAILED',
  DELETE = '@shifts/DELETE',
  DELETE_SUCCESS = '@shifts/DELETE_SUCCESS',
  DELETE_FAILED = '@shifts/DELETE_FAILED',

  SET_ON_CALL_ID = '@shifts/SET_ON_CALL_ID',

  SET_DATE = '@shifts/SET_DATE',
}
