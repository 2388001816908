import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  UnexpectedError,
} from '~/domain/errors';
import { PatchPasswordRecovery } from '~/domain/usecases/password/remote';
import '~/infra/global/variables';
import { History } from '~/main/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemotePatchPasswordRecovery implements PatchPasswordRecovery {
  private readonly url: string;

  private readonly httpClient: HttpClient<PatchPasswordRecovery.Model>;

  constructor(url: string, httClient: HttpClient<PatchPasswordRecovery.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  patch = async (
    params: PatchPasswordRecovery.Params,
  ): Promise<PatchPasswordRecovery.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'patch',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        AlertMessage({
          message: intl.formatMessage({
            id: 'Recuperação de senha feita com sucesso!',
          }),
          type: 'success',
        });
        setTimeout(() => History.push('/'), 1200);
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
