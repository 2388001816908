import React, {
  ComponentType,
  createContext,
  useCallback,
  useContext,
  useMemo,
  JSX,
} from 'react';

import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

type AccessContextData = {
  setPermissions(permissions: string[]): void;
  hasAccess(resourcers: string[], actions: string[]): boolean;
};

const AccessContext = createContext<AccessContextData>({} as AccessContextData);

interface iACL {
  [key: string]: string[];
}

const AccessProvider: React.FC = ({ children }) => {
  // consulting redux.
  const RolesACL = useSelector((store: iStore) => store.auth.acl.resources);
  const { info } = useSelector((store: iStore) => store.auth);
  const accessActions: string[] = [];
  const accessResources: string[] = [];
  const ACL: iACL = {};

  RolesACL?.forEach(e => {
    ACL[e.name] = e.actions.map(action => action.name);
  });

  const setPermissions = useCallback((permissions: string[]) => {
    console.log(permissions);
  }, []);

  const hasAccess = useCallback(
    (resourcers: string[], actions: string[]): boolean => {
      if (info.user?.mainRole === 'ADM') return true;

      for (let i = 0; i < resourcers.length; i += 1) {
        for (let j = 0; j < actions.length; j += 1) {
          if (ACL[resourcers[i]]?.includes(actions[j])) return true;
        }
      }
      return false;
    },
    [accessResources, accessActions, info.user?.mainRole],
  );

  return (
    <AccessContext.Provider value={{ setPermissions, hasAccess }}>
      {children}
    </AccessContext.Provider>
  );
};

const useAccess = () => {
  const context = useContext(AccessContext);
  return context;
};

type withAccessProps = {
  resourcers?: string[];
  actions?: string[];
};

function withAccess<P extends object>(
  Component: ComponentType<P>,
): React.FC<P & withAccessProps> {
  const WithAccessComp: React.FC<P & withAccessProps> = ({
    resourcers,
    actions,
    ...rest
  }) => {
    const { hasAccess } = useAccess();

    const access = useMemo(() => {
      if (!resourcers || !actions) return true;

      return hasAccess(resourcers, actions);
    }, [resourcers, actions, hasAccess]);

    return access ? <Component {...(rest as P)} /> : <></>;
  };

  WithAccessComp.displayName = `withPermissions(${
    Component.displayName || Component.name
  })`;

  WithAccessComp.defaultProps = {
    resourcers: undefined,
    actions: undefined,
  } as any;

  return WithAccessComp;
}

export { AccessProvider as default, useAccess, withAccess };
