import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadRequestError,
  Forbidden,
  InvalidCredentialsError,
  UnexpectedError,
} from '~/domain/errors';
import { GetHealthPlan } from '~/domain/usecases/healthPlan/Remote';
import '~/infra/global/variables';

export class RemoteGetHealthPlan implements GetHealthPlan {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetHealthPlan.Model>;

  constructor(url: string, httClient: HttpClient<GetHealthPlan.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (params: GetHealthPlan.Params): Promise<GetHealthPlan.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
