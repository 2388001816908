import { CreateUser } from '~/domain/usecases/users/redux';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  UsersTypes,
} from '~/domain/interfaces/redux/users/create';

export const createRequest = (payload: CreateUser.Params): iActionCreate => ({
  type: UsersTypes.CREATE,
  payload,
});

export const createSuccess = (): iActionCreateSuccess => ({
  type: UsersTypes.CREATE_SUCCESS,
});

export const createFailed = (): iActionCreateFailed => ({
  type: UsersTypes.CREATE_FAILED,
});
