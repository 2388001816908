/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import { GetAllAppointment } from '~/domain/usecases/appointment/remote';
import { useMyAppointmentsFilter } from '~/presentation/hooks/filters/myAppointments';
import {
  Container,
  Text,
  PatientText,
  Info,
  CardBox,
  EmptyState,
} from './styles';
import MyAppointmentCard from '../MyAppointmentCard';

interface MyAgendaListProps {
  cards: GetAllAppointment.Model['results'];
  getAppointment: Function;
  currentAppointment: number | undefined;
}

const MyAgendaList: React.FC<MyAgendaListProps> = ({
  cards,
  getAppointment,
  currentAppointment,
}) => {
  const { hasAnyFilterSelected } = useMyAppointmentsFilter();

  return (
    <Container>
      <Info>
        <Text>Atendimentos</Text>
        <PatientText>{cards?.length} Pacientes</PatientText>
      </Info>
      <CardBox>
        {cards?.length ? (
          cards?.map(item => {
            return (
              <MyAppointmentCard
                card={item}
                current={currentAppointment}
                onClick={() => getAppointment(item)}
              />
            );
          })
        ) : (
          <EmptyState>
            {hasAnyFilterSelected ? (
              <span>Não há pacientes que atendem aos critérios do filtro.</span>
            ) : (
              <span>Não há atendimentos agendados para este dia.</span>
            )}
          </EmptyState>
        )}
      </CardBox>
    </Container>
  );
};

export default MyAgendaList;
