import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  iListProfessional,
  iListSpeciality,
  iListConsultant,
  iProfessional,
} from '../interface';
import { ownProps } from '../Professionals';
import { iStore, iAppointment } from '~/domain/interfaces/models';
import { makeReduxAddAppointmentData } from '~/main/factories/usecases/appointment/AddAppointmentDataFactory';

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { appointment, professional, specialty } = useSelector(
      (state: iStore) => state,
    );

    Component.defaultProps = {
      professional: _.orderBy(
        MapProfessional(professional.results),
        item => item.name,
        'asc',
      ),
      speciality: MapSpecialty(specialty.results),
      handlerState: makeReduxAddAppointmentData(),
      state: MapState(appointment),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

export const MapConsultant = (
  consultant: iListConsultant[],
): iListConsultant[] => {
  const arr: iListConsultant[] = [];

  consultant.forEach(item => {
    arr.push({
      id: item.id,
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      email: item.email,
      phone: item.phone,
    });
  });

  return arr;
};

export const MapSpecialty = (
  specialty: iListSpeciality[],
): iListSpeciality[] => {
  const arr: iListSpeciality[] = [];

  specialty.forEach(item => {
    arr.push({
      profession: item.profession,
    });
  });

  return arr;
};

export const MapProfessional = (
  professional: iListProfessional[],
): iProfessional[] => {
  const arr: iProfessional[] = [];
  professional.forEach(item => {
    arr.push({
      name: `${item.user.firstName} ${item.user.lastName}`,
      id: Number(item.professional.id),
      specialty: item.specialties[0].name,
      type: 'Profissional interno',
      email: item.user.email,
      phone: item.user.phone,
    });
  });

  return arr;
};

export const MapState = (professional: iAppointment): iProfessional[] => {
  const arr: iProfessional[] = [];

  professional.create.data.professionals?.forEach(item => {
    arr.push({
      name: item.name,
      id: item.id,
      email: item.email,
      phone: item.phone,
      specialty: item.specialty,
      type: 'external',
    });
  });

  return arr;
};
