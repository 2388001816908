import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { AppointmentDetails } from '~/presentation/roles/professional/pages/appointment';

import { iStore } from '~/domain/interfaces/models/Store';

import { ParticipantPages, Error } from '~/presentation/pages';
import { makeReduxGetAllClinicalDocs } from '../../usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { V4hSpin } from '~/presentation/components/spin';
import { makeReduxGetAllSpecialty } from '../../usecases/specialty/GetAllSpecialtyFactory';
import { makeReduxSetData } from '../../usecases/appointment/SetDataFactory';

interface iStateParams {
  appointmentId: string;
}

export const MakeAppointmentDetailPage: React.FC = () => {
  const stateParams = useLocation<iStateParams>().state;

  const { selected } = useSelector((state: iStore) => state.professions);
  const { orgId, role } = useSelector((state: iStore) => state.auth.selectUser);
  const { loading, date } = useSelector((store: iStore) => store.appointment);

  useEffect(() => {
    makeReduxGetAllClinicalDocs().getAll({
      filter: {
        appointment: Number(stateParams.appointmentId),
        org: orgId,
        enabled: true,
      },
    });

    // return () => {
    //   makeReduxUpdateAppointment().update({
    //     appointmentId: Number(id),
    //     note: window.localStorage.getItem('data')!,
    //   });
    // };
  }, []);

  useEffect(() => {
    makeReduxGetAllSpecialty().getAll({
      pageSize: 9999,
      filter: { hasProfessional: true, enabled: true, profession: selected },
    });
  }, [selected]);

  useEffect(() => {
    makeReduxSetData().set({
      date: date ? new Date(date).toISOString() : new Date().toISOString(),
    });
  }, []);

  // if (loading) return <V4hSpin fullScreen />;

  try {
    switch (role) {
      case 'ADM':
      case 'ORG':
      case 'PRO':
        return loading ? <V4hSpin fullScreen /> : <AppointmentDetails />;
      case 'CON':
        return loading ? (
          <V4hSpin fullScreen />
        ) : (
          <ParticipantPages.AppointmentDetails />
        );
      default:
        return <Error.ReportProblem />;
    }
  } catch (e) {
    return <Error.ReportProblem />;
  }
};
