import {
  addHours,
  addMinutes,
  differenceInMinutes,
  parseISO,
  set,
} from 'date-fns';

interface ownProps {
  start: Date;
  end: Date;
  duration: number;
}

/**
 * function to transform @type string in @type Date
 * @param timeStart start appointment
 * @param timeEnd end appointment
 * @param date schedule appointment
 * @param duration duration appointment
 */
export const makeDateAndDuration = (
  timeStart: string | Date,
  timeEnd: string | Date,
  date: string,
  duration?: number,
): ownProps => {
  const baseDate = typeof date === 'string' ? parseISO(date) : date;

  const parseTimeWithOffset = (time: string | Date) => {
    if (time instanceof Date) return time;

    let dateTime;
    if (time.includes('T')) {
      dateTime = parseISO(time);
    } else {
      const [hours, minutes] = time.split(':');
      dateTime = set(baseDate, {
        hours: Number(hours),
        minutes: Number(minutes),
      });
    }

    const timezoneOffsetMatch = time.match(/([+-]\d{2})(\d{2})Z$/);
    if (timezoneOffsetMatch) {
      const hoursOffset = parseInt(timezoneOffsetMatch[1], 10);
      const minutesOffset = parseInt(timezoneOffsetMatch[2], 10);
      dateTime = addHours(dateTime, hoursOffset);
      dateTime = addMinutes(dateTime, minutesOffset * Math.sign(hoursOffset));
    }

    return dateTime;
  };

  const start = parseTimeWithOffset(timeStart);
  const end = timeEnd
    ? parseTimeWithOffset(timeEnd)
    : addMinutes(start, duration || 0);

  const durationReturn = timeEnd
    ? differenceInMinutes(end, start)
    : duration || 0;

  const roundToNearest = 15;
  const roundMinutes =
    Math.round(durationReturn / roundToNearest) * roundToNearest;

  return { start, end, duration: roundMinutes };
};
