import React from 'react';
import * as dateFns from 'date-fns';
import {
  Container,
  Content,
  Icon,
  Time,
  DayWeek,
} from './styles/StyledPaginationByDate';
import { IconNext, IconPrevious } from '~/presentation/base/icons';
import { Button } from '../UI';
import { translator } from '../i18n';

import { SetData } from '~/domain/usecases/appointment/redux/SetData';

import { ConnectComponent } from './mapper/MapperPaginationByDate';

export interface ownProps {
  date: string;
  dateAction: SetData;
}

const PaginationByDate: React.FC<ownProps> = (props): JSX.Element => {
  const days = [
    translator('Domingo'),
    translator('Segunda-Feira'),
    translator('Terça-Feira'),
    translator('Quarta-Feira'),
    translator('Quinta-Feira'),
    translator('Sexta-Feira'),
    translator('Sábado'),
  ];
  const months = [
    translator(' de Janeiro'), // 31 [0]
    translator(' de Fevereiro'), // 28 [1]
    translator(' de Março'),
    translator(' de Abril'),
    translator(' de Maio'),
    translator(' de Junho'),
    translator(' de Julho'),
    translator(' de Agosto'),
    translator(' de Setembro'),
    translator(' de Outubro'),
    translator(' de Novembro'),
    translator(' de Dezembro'),
  ];

  const subDate = () => {
    props.dateAction.set({
      date: dateFns.sub(new Date(props.date), { days: 1 }).toISOString(),
    });
  };

  const addDate = () => {
    props.dateAction.set({
      date: dateFns.add(new Date(props.date), { days: 1 }).toISOString(),
    });
  };

  return (
    <Container>
      <Content>
        <Button
          size="small"
          className="btnAction"
          variant="secundary"
          // color="#dcdcdc"
          rounded
          onClick={() =>
            props.dateAction.set({ date: new Date().toISOString() })
          }
        >
          {translator('Hoje')}
        </Button>
      </Content>
      <Icon>
        <IconPrevious id="previous_page" className="Icons" onClick={subDate} />
        <IconNext id="next_page" className="Icons" onClick={addDate} />
      </Icon>
      <Time id="dateTime">
        <DayWeek>{days[new Date(props.date).getDay()]}</DayWeek>
        {new Date(props.date).getDate()}
        {months[new Date(props.date).getMonth()]}
      </Time>
    </Container>
  );
};

export default ConnectComponent(PaginationByDate);
