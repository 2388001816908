import { GetDiagnosisReportByID } from '~/domain/usecases/diagnosisReport/remote';
import { EditDiagnosisReport } from '~/validation/validators/editDiagnosisReport';

type Priority = 'LOW' | 'NORMAL' | 'HIGH';

const mapPriority: Record<Priority, { value: Priority; label: string }> = {
  LOW: {
    label: 'Baixa',
    value: 'LOW',
  },
  NORMAL: {
    label: 'Normal',
    value: 'NORMAL',
  },
  HIGH: {
    label: 'Alta',
    value: 'HIGH',
  },
};

const mapSex: Record<
  'MALE' | 'FEMALE',
  { label: string; value: 'MALE' | 'FEMALE' }
> = {
  MALE: {
    label: 'Masculino',
    value: 'MALE',
  },
  FEMALE: {
    label: 'Feminino',
    value: 'FEMALE',
  },
};

export const mapDiagnosisData = (
  data: GetDiagnosisReportByID.Model,
): Partial<EditDiagnosisReport> | any => {
  console.log('data', data);
  return {
    ...(data.consultantExternal
      ? {
          consultantExternal: {
            ...data.consultantExternal,
            sex: mapSex[data.consultantExternal?.sex ?? 'MALE'],
          },
        }
      : {
          consultant: {
            ...data.consultant,
            sex: mapSex[data.consultant?.sex ?? 'MALE'],
            firstname: data.consultant?.firstName,
            lastname: data.consultant?.lastName,
          },
        }),
    description: data.description,
    healthUnit: data.healthUnit,
    modality: data.modality,
    specialty: data.specialty,
    specialist: data.specialist,
    priority: mapPriority[data.priority],
    consultantInfo: data.consultantInfo,
  };
};
