/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-one-expression-per-line */
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { History } from '~/main/routes';
import { IconEye } from '~/presentation/base/icons';
import { TablePagination } from '~/presentation/components/tablePagination';
import { calculateAgeAndMonths } from '~/utils/calculateAgeAndMonths';
import { outcomeMap } from '~/utils/mapPriority';
import { finishedColumns, finishedColumnsProfessional } from './columns';
import {
  ActionButton,
  ActionButtonContainer,
  EmptyList,
  ListFooter,
  OutcomeBadgeStatus,
  ProfessionalBadge,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
  Table,
  Td,
  Th,
  Tr,
} from './styles';

type Row = iOnCallRequest['records'][0];

type SelectedRequest = Row | null;

type PatientDutyListProps = {
  selectedRequest: SelectedRequest;
  setSelectedRequest: (requester: SelectedRequest) => void;
  variant?: 'REQUESTER' | 'PROFESSIONAL';
  healthUnitId?: number;
};

export const Finished: React.FC<PatientDutyListProps> = ({
  setSelectedRequest,
  selectedRequest,
  variant,
  healthUnitId,
}) => {
  const { records } = useSelector((store: iStore) => store.onCallRequest);

  const recordsFiltered = records?.filter(item => {
    return (
      item.status === 'CLOSED' &&
      (!healthUnitId || item.healthUnit.id === healthUnitId)
    );
  });

  const hasRequests = recordsFiltered?.length;

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Table>
        <thead>
          <tr>
            {variant === 'REQUESTER' ? (
              <>
                {finishedColumns.map(column => (
                  <Th key={column.id}>{column.label}</Th>
                ))}
              </>
            ) : (
              <>
                {finishedColumnsProfessional.map(column => (
                  <Th key={column.id}>{column.label}</Th>
                ))}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {recordsFiltered?.map(row => (
            <TableRowItem
              key={row.id}
              row={row}
              active={row.id === selectedRequest?.id}
              onClick={() => setSelectedRequest(row)}
              variant={variant}
            />
          ))}
        </tbody>
      </Table>
      {!hasRequests ? (
        <EmptyList>Você não tem solicitações pendentes.</EmptyList>
      ) : (
        <ListFooter>
          <TablePagination
            itemPerPage={1}
            data={recordsFiltered}
            totalData={recordsFiltered.length}
          />
        </ListFooter>
      )}
    </div>
  );
};

type TableRowItemProps = {
  row: Row & { active?: boolean };
  active?: boolean;
  onClick: () => void;
  variant?: 'REQUESTER' | 'PROFESSIONAL';
};

const TableRowItem: React.FC<TableRowItemProps> = ({
  row,
  variant,
  ...rest
}) => {
  const { consultant, onCall, outcome } = row;

  const url = window.location.pathname;

  const updated = Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
    .format(new Date(row.updated))
    .replace(', ', ', às ');

  return (
    <Tr {...rest}>
      <Td>
        <ProfessionalBadge>
          <ProfessionalBadgeInfo>
            <ProfessionalBadgeName>
              {consultant?.fullname?.length
                ? consultant?.fullname
                : 'Nome não informado'}
            </ProfessionalBadgeName>
            <ProfessionalBadgeRole>
              {calculateAgeAndMonths(consultant?.birthdate)}
            </ProfessionalBadgeRole>
          </ProfessionalBadgeInfo>
        </ProfessionalBadge>
      </Td>
      <Td style={{ maxWidth: '200px' }}>
        <Tooltip title={onCall?.specialtyName} placement="top" arrow>
          <span>{onCall?.specialtyName}</span>
        </Tooltip>
      </Td>
      <Td>{updated}</Td>
      <Td>
        <OutcomeBadgeStatus status={row.outcome?.msgKey}>
          {outcomeMap(row.outcome?.msgKey)}
        </OutcomeBadgeStatus>
      </Td>
      <Td align="right">
        {!!row?.appointments?.length && (
          <ActionButtonContainer>
            <ActionButton
              onClick={() => {
                History.getHistory().push('/appointment/detail', {
                  appointmentId:
                    row?.appointments && row.appointments.length > 0
                      ? row.appointments[0].id
                      : undefined,
                  from: url.replaceAll('/', ''),
                });
              }}
            >
              <IconEye width={18} height={18} />
            </ActionButton>
          </ActionButtonContainer>
        )}
      </Td>
    </Tr>
  );
};
