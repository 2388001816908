import { InsertClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';
import { iActionInsert } from '~/domain/interfaces/redux/clinicalDocs/insert';
import { ClinicalDocsTypes } from '~/domain/interfaces/redux/clinicalDocs/types';

export const insertClinicalDocs = (
  payload: InsertClinicalDocs.Params,
): iActionInsert => ({
  type: ClinicalDocsTypes.INSERT_CLINICAL_DOC,
  payload,
});
