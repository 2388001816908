import React, { ComponentType, useState } from 'react';
import * as datefns from 'date-fns';
import { useSelector } from 'react-redux';
import { iAppointment, iStore } from '~/domain/interfaces/models';

import { makeReduxUpdateAppointment } from '~/main/factories/usecases/appointment/UpdateAppointmentFactory';

import { ownProps } from '../EditConsult';

import { iEditConsultInformation } from '../interface';

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const appointment: iAppointment = useSelector(
      (store: iStore) => store.appointment,
    );
    Component.defaultProps = {
      actionUpdate: makeReduxUpdateAppointment(),
      editConsult: (e: number) => mapEditConsult(e, appointment),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

export function makeTime(item: string, duration: number) {
  const time = new Date(item);
  const timeStart = new Date(time.getTime()).toLocaleTimeString('pt-BR', {
    hour12: false,
  });
  const timeEnd = new Date(
    time.getTime() + duration * 60000,
  ).toLocaleTimeString('pt-BR', { hour12: false });

  const startFormat = `${timeStart.split(':')[0]}:${timeStart.split(':')[1]}`;
  const endFormat = `${timeEnd.split(':')[0]}:${timeEnd.split(':')[1]}`;
  return { startFormat, endFormat };
}

const mapEditConsult = (
  appointmentId: number,
  appointments: iAppointment,
): iEditConsultInformation => {
  if (!appointments.date) return {} as iEditConsultInformation;
  console.log('### Appointment Date', appointments.date);
  const selectDate = datefns.format(
    appointments.date instanceof Date
      ? appointments.date
      : new Date(appointments.date),
    'yyyy-MM-dd',
  );

  let appointmentResults: any = appointments.resultsMap[selectDate].filter(
    item => item.appointment.id === appointmentId,
  );

  // Se não encontrar atendimento em busca filtrada, devido a atualização de data, buscar em todas as datas
  if (!appointmentResults.length)
    appointmentResults = Object.values(appointments.resultsMap)
      .map(item =>
        item.find(
          appointmentItem => appointmentItem.appointment.id === appointmentId,
        ),
      )
      .filter(Boolean);

  const { appointment, professional, specialty, consultant } =
    appointmentResults[0];
  const editConsult: iEditConsultInformation = {
    dateAndTime: {
      duration: appointment.duration,
      dateToAppointment: appointment.scheduled,
      hourStart: makeTime(appointment.scheduled, appointment.duration)
        .startFormat,
      hourFinish: makeTime(appointment.scheduled, appointment.duration)
        .endFormat,
    },
    generalData: {
      patient: consultant!.id,
      professional: professional!.id,
      speciality: specialty!.id,
      typeToAppointment: appointment!.type,
    },
  };
  return editConsult;
};
