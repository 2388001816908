import { takeLatest } from 'redux-saga/effects';
import { SelectedOrgTypes } from '~/domain/interfaces/redux/selectedOrg/types';
import { onGetAllRequest, onGetAllSuccess, onGetAllFailed } from './GetAll';

const tasks = [
  takeLatest(SelectedOrgTypes.GETALL, onGetAllRequest),
  takeLatest(SelectedOrgTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(SelectedOrgTypes.GETALL_FAILED, onGetAllFailed),
];

export default tasks;
