import React from 'react';
import { Link } from './styles';

interface LinktoProps {
  title: string;
  to?: string;
}

const Linkto: React.FC<LinktoProps> = ({ title, to }) => {
  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  return (
    <Link target="_blank" href={to}>
      {title}
    </Link>
  );
};

export default Linkto;
