import {
  iActionAddUserQueue,
  iActionRemoveUserQueue,
  waitingRoomTypes,
} from '~/domain/interfaces/redux/waitingroom/userQueue';
import { WaitingRoom } from '~/domain/interfaces/socketEvents/WaitingRoom';

export const AddUserQueue = (params: WaitingRoom): iActionAddUserQueue => ({
  type: waitingRoomTypes.ADD_USER_QUEUE,
  payload: params,
});

export const RemoveUserQueue = (
  params: WaitingRoom,
): iActionRemoveUserQueue => ({
  type: waitingRoomTypes.REMOVE_USER_QUEUE,
  payload: params,
});
