/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { AlertIconSuccess } from '~/presentation/base/icons';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeRemoteListOnCall } from '~/main/factories/usecases/duty/ListOnCall';
import { Button } from '../UI';
import {
  ContainerModal,
  Container,
  Content,
  Icon,
  Title,
  Information,
  Info,
  Footer,
} from './styles/styles';
import { AlertMessage } from '../messages/AlertMessage';

interface iPropsModalConsultationRequest {
  message: iMessage;
}

const ModalConsultationRequest: React.FC<iPropsModalConsultationRequest> = ({
  message,
}) => {
  const { active, actionCancel, appointmentSpecialty } = message;

  const [specialty, setSpecialty] = React.useState<string>();

  const msgName = MessageOptions.consultationRequestSuccess;

  useEffect(() => {
    if (active !== msgName) return;

    makeRemoteListOnCall()
      .get({})
      .then(res => {
        const selectedSpecialty = res?.records?.find(
          item => item?.id === Number(appointmentSpecialty),
        );
        setSpecialty(selectedSpecialty?.descr);
      })
      .catch(() => {
        AlertMessage({
          message: 'Falha ao carregar especialidades!',
          type: 'danger',
        });
      });
  }, [active, appointmentSpecialty, msgName]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <Icon>
                <AlertIconSuccess />
              </Icon>
              <Title>Sua solicitação foi enviada!</Title>
              <Information>
                <Info>
                  Sua solicitação de interconsulta com um profissional do
                  plantão de {`${specialty ?? ''}`} foi enviada com sucesso.
                </Info>
                <Info>
                  Lembre-se: não será necessário refazê-la se nenhum
                  profissional estiver disponível no momento.
                </Info>
              </Information>
            </Content>
            <Footer>
              <Button variant="primary" onClick={actionCancel}>
                Ok
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalConsultationRequest;
