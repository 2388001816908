import { GetClinicalDocsByDownload } from '~/domain/usecases/clinicalDocs/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
  Forbidden,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteGetClinicalDocsByDownload
  implements GetClinicalDocsByDownload
{
  private readonly url: string;

  private readonly httpClient: HttpClient<GetClinicalDocsByDownload.Model>;

  constructor(
    url: string,
    httClient: HttpClient<GetClinicalDocsByDownload.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  getByDownload = async (
    params: GetClinicalDocsByDownload.Params,
  ): Promise<GetClinicalDocsByDownload.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.docId}/download`,
      method: 'get',
      headers: { 'Content-Type': 'application/octet-stream' },
      responseType: 'blob',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
