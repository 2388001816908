import styled from 'styled-components';

export const Container = styled.div`
  max-width: 300px;
  width: 100%;
  height: fit-content;
  display: grid;
  grid-gap: 10px;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
