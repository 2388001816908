import { UpdateShifts as UsecaseReduxUpdateShifts } from '~/domain/usecases/shifts/redux/UpdateShifts';
import { UpdateShifts as UsecaseRemoteUpdateShifts } from '~/domain/usecases/shifts/remote';

import { makeApiUrl, makeHttpClient } from '../../http';

import { RemoteUpdateShifts } from '~/data/repository/shifts';
import { ReduxUpdateShifts } from '~/data/store/reducer/shifts/usecases';

export const makeRemoteUpdateShifts = (): UsecaseRemoteUpdateShifts =>
  new RemoteUpdateShifts(
    makeApiUrl('/oncall/{onCallId}/shifts'),
    makeHttpClient(),
  );

export const makeReduxUpdateShifts = (): UsecaseReduxUpdateShifts =>
  new ReduxUpdateShifts();
