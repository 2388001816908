import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import storeDev from '~/data/store';
import { ReduxLogout } from '~/data/store/reducer/auth/usecases/ReduxLogout';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDeleteUser } from '~/main/factories/usecases/users/DeleteUserFactory';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { History } from '~/main/routes';
import { Trash } from '~/presentation/base/icons';
import { translator } from '../i18n';
import { Icon } from '../icon';
import { Button } from '../UI/buttons';
import {
  Body,
  Container,
  Header,
  DeleAccountActions,
  CheckDiv,
} from './style/StyledDeleteAccount';

interface iParams {
  id: string;
}

export default function DeleteAccount() {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

  const info = useSelector((state: iStore) => state.auth.info);
  const { id } = useLocation<iParams>().state;

  const handleLogout = useCallback(() => {
    const logoutApp = new ReduxLogout();
    logoutApp.logout();
    History.push('/');
  }, []);

  const deleteAccount = () => {
    if (id !== undefined) {
      try {
        const response = makeReduxDeleteUser().delete({ id: Number(id) });
        // const allusers = makeReduxGetAllUsers().getAll({});
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const response = makeReduxDeleteUser().delete({
          id: Number(info!.user!.id!),
        });
        // const allUsers = makeReduxGetAllUsers().getAll({});
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Container>
      <Header>
        <Icon src={Trash} color="#656A6E" height="24px" />
        {translator('Excluir conta')}
      </Header>
      <Body>
        <div id="notice">{translator('Aviso importante')}</div>
        <div id="text">
          {translator(
            ' De acordo com a legislação, a remoção da sua conta removerá todos os seus dados exceto aqueles relacionados aos atendimentos médicos.',
          )}
        </div>
        <DeleAccountActions>
          <CheckDiv>
            <input
              type="checkbox"
              onChange={() => setConfirmDelete(!confirmDelete)}
            />
            <p>
              {translator(
                'Estou ciente que a exclusão dos dados é irreversível',
              )}
            </p>
          </CheckDiv>
          <Button
            actions={['DELETE_OWN']}
            id="action"
            type="button"
            color="red"
            variant="secundary"
            rounded
            onClick={() => deleteAccount()}
            disabled={!confirmDelete}
          >
            {translator('Excluir usuário')}
          </Button>
        </DeleAccountActions>
      </Body>
    </Container>
  );
}
