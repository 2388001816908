import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  Paper,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { translator } from '~/presentation/components/i18n';
import { makeReduxCancelAppointment } from '~/main/factories/usecases/appointment/CancelAppointmentFactory';
import { makeReduxSetupAppointment } from '~/main/factories/usecases/appointment/SetupAppointmentFactory';
import { makeReduxStopAppointment } from '~/main/factories/usecases/appointment/StopAppointmentFactory';
import { History } from '~/main/routes';
import { IconSort } from '~/presentation/base/icons';
import orderData from '~/utils/orderData';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteDownloadTranscription } from '~/main/factories/usecases/appointment/DownloadTranscriptionFactory';
import { ActionsList } from '../actionsList';
import ListSkeleton from '../skeleton/ListSkeleton';
import { iListAppointment } from './interface';
import { ConnectAppointment } from './mapper/MapperListHistoryAppointment';
import { Avatar } from '../ListParticipant/styles/StyledUserItem';
import { OutcomeStatus, ScheduleStatus } from '../statusList';
import { AvatarBadger } from '../avatar-roles';
import { AlertMessage } from '../messages/AlertMessage';

export interface ownProps {
  data: iListAppointment[];
  loading: boolean;
}

type LocationState = {
  user: any;
};

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
});

type iOrder =
  | 'participant'
  | 'professional'
  | 'specialty'
  | 'timeStart'
  | 'timeEnd'
  | 'typeIs'
  | 'status';

const ListHistoryAppointment: React.FC<ownProps> = ({
  data: dataList,
  loading,
}): JSX.Element => {
  const [order, setOrder] = useState<boolean>(true);
  const [data, setData] = useState<iListAppointment[]>(dataList);
  const role = useSelector((store: iStore) => store.auth.selectUser.role);
  const url = window.location.pathname;
  const { state } = useLocation() as { state: LocationState };
  const currentRole = state?.user?.role || role;

  const serviceVariant: { [key: string]: string } = {
    CONSULTATION: translator('Agendado'),
    ONCALL: translator('Plantão Virtual'),
    INSTANTANEOUS: translator('Interconsulta inst.'),
  };

  const classes = useStyles();
  const columns = [
    {
      id: 'time',
      label: translator('Data e hora'),
      minWidth: 100,
      maxWidth: 200,
      align: 'left',
    },
    {
      id: 'specialty',
      label: translator('Especialidade'),
      minWidth: 190,
      align: 'left',
      roles: ['PRO', 'CON'],
    },
    {
      id: 'participant',
      label: translator('Paciente'),
      minWidth: 200,
      align: 'left',
      roles: ['PRO'],
    },
    {
      id: 'healthUnit',
      label: 'Estabelecimento de Saúde',
      minWidth: 180,
      align: 'left',
      roles: ['ORG', 'ADM'],
    },
    {
      id: 'professional',
      label: translator('Professional'),
      minWidth: 200,
      align: 'left',
      roles: ['CON', 'ORG', 'ADM'],
    },
    {
      id: 'service',
      label: 'Serviço',
      minWidth: 10,
      align: 'left',
      roles: ['ORG', 'ADM'],
    },
    {
      id: 'modality',
      label: translator('Modalidade'),
      minWidth: 10,
      align: 'left',
      roles: ['CON', 'PRO'],
    },
    {
      id: 'typeIs',
      label: translator('Tipo'),
      minWidth: 80,
      align: 'left',
      roles: ['CON', 'PRO'],
    },
    {
      id: 'status',
      label: translator('Status'),
      minWidth: 100,
      maxWidth: 100,
      align: 'left',
    },
    {
      id: 'outcome',
      label: translator('Desfecho'),
      minWidth: 100,
      maxWidth: 160,
      align: 'left',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 20,
      align: 'left',
    },
  ]?.filter(item => {
    return item?.roles
      ? currentRole && item?.roles.includes(currentRole)
      : true;
  });

  function StopAppointment(appointment: any) {
    if (appointment.status === 'AVAILABLE') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
            stop: () => {
              makeReduxStopAppointment().stop({ id: appointment.id });
            },
            cancel: () => {
              makeReduxSetupAppointment().setup({
                appointmentId: appointment.id,
              });
              makeReduxCancelAppointment().cancel({
                appointmentId: appointment.id,
              });
            },
          }}
        />
      );
    }

    if (appointment.status === 'ENDED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
            downloadTranscription: appointment?.allowTranscription
              ? () => {
                  makeRemoteDownloadTranscription()
                    .download({
                      appointmentId: appointment.id,
                    })
                    .then(res => {
                      const blob = new Blob([res], { type: 'application/pdf' });
                      const urlToPdf = window.URL.createObjectURL(blob);

                      const a = document.createElement('a');
                      a.href = urlToPdf;
                      a.download = 'transcription.pdf';
                      a.style.display = 'none';
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);

                      AlertMessage({
                        type: 'success',
                        message: 'Transcrição baixada com sucesso',
                      });
                    })
                    .catch(err => {
                      // toast.error('Não foi possível realizar o download da gravação');
                    });
                }
              : undefined,
          }}
        />
      );
    }

    if (appointment.status === 'CANCELED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
          }}
        />
      );
    }

    if (appointment.status === 'SCHEDULED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
            cancel: () => {
              makeReduxSetupAppointment().setup({
                appointmentId: appointment.id,
              });
              makeReduxCancelAppointment().cancel({
                appointmentId: appointment.id,
              });
            },
          }}
        />
      );
    }

    if (appointment.status === 'CONFIRMED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professionalId,
                consultant: appointment.consultantUserId,
                consultantId: appointment.consultantId,
                from: url.replaceAll('/', ''),
              }),
            cancel: () => {
              makeReduxSetupAppointment().setup({
                appointmentId: appointment.id,
              });
              makeReduxCancelAppointment().cancel({
                appointmentId: appointment.id,
              });
            },
          }}
        />
      );
    }
    return (
      <ActionsList
        actions={{
          stop: () => {
            makeReduxStopAppointment().stop({ id: appointment.id });
          },
          cancel: () => {
            makeReduxSetupAppointment().setup({
              appointmentId: appointment.id,
            });
            makeReduxCancelAppointment().cancel({
              appointmentId: appointment.id,
            });
          },
        }}
      />
    );
  }

  const RenderComponents: React.FC<{
    id: any;
    appointment: any;
  }> = ({ id, appointment }) => {
    switch (id) {
      case 'actions':
        return StopAppointment(appointment)!;
      case 'status':
        return <ScheduleStatus status={appointment.status} />;
      case 'professional':
        return (
          <Avatar>
            {/* <AvatarBadger
              src={appointment.srcProfessional}
              name={appointment.professional}
              size="root"
              status={appointment.consultantStatus === 'ONLINE'}
            /> */}
            <text>{`${appointment.fullName}`}</text>
          </Avatar>
        );
      case 'participant':
        return (
          <Avatar>
            {/* <AvatarBadger
              src={appointment.srcParticipant}
              name={appointment.consultantFullName || ''}
              size="root"
              status={appointment.participantStatus === 'ONLINE'}
            /> */}
            <text>{`${appointment.consultantFullName}`}</text>
          </Avatar>
        );
      case 'time':
        return <div>{`${appointment.date} - ${appointment.timeStart}`}</div>;
      case 'service':
        return (
          <div>
            {appointment?.service
              ? serviceVariant[appointment.service as string]
              : '-'}
          </div>
        );
      case 'typeIs':
        return <div>{appointment[`${id}`]}</div>;
      case 'outcome':
        return <OutcomeStatus outcome={appointment?.outcome} />;
      default:
        return <div>{appointment[`${id}`]}</div>;
    }
  };

  useEffect(() => {
    setData([...dataList]);
  }, [dataList]);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns?.map(item => {
              return (
                <TableCell align="left">
                  {false ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div style={{ paddingLeft: 3 }}>
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      onClick={() => {
                        setOrder(prevState => !prevState);
                        /* setData([
                          ...orderData(dados.data, order, item.id as iOrder),
                        ]); */
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns?.map(item => {
                return (
                  <TableRow>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            data.map(item => (
              <TableRow>
                {columns?.map((columnProps: any) => {
                  return (
                    <TableCell
                      id={`${columnProps.id}-${item.id}`}
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        appointment={item}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListHistoryAppointment;
