import { GetVidasCertificate as UseCaseRemoteGetVidaasCertificate } from '~/domain/usecases/vidas/remote';
import { RemoteGetVidasCertificate } from '~/data/repository/vidas';
import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

/**
 * send request via API.
 */

export const makeRemoteGetVidasCertificate =
  (): UseCaseRemoteGetVidaasCertificate =>
    new RemoteGetVidasCertificate(
      makeApiUrl('/vidaas/certificates'),
      makeHttpClient(),
    );
