import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageTypes } from '~/domain/interfaces/redux/message/types';
import { MessageActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iMessage = {
  active: 'none',
  actionOk: () => '',
  actionCancel: () => '',
  data: {},
};

const reducer = (state = initialState, action: MessageActions): iMessage => {
  switch (action.type) {
    case MessageTypes.MESSAGE_ACTIVE: {
      const { active } = action.payload;
      return {
        ...state,
        ...action.payload,
        ...(active === 'none' && { data: {} }),
      };
    }
    case MessageTypes.SET_DATA:
      return { ...state, ...action.payload };
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
