import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { History } from '~/main/routes';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { translator } from '~/presentation/components/i18n';
import { iUser } from '~/presentation/components/specialPrescription/interface';
import { iStore } from '~/domain/interfaces/models';
import {
  Address,
  Age,
  Contact,
  Content,
  Email,
  Header,
  Infos,
  LinkProfile,
  Name,
  PhotoView,
  UserInfo,
} from './styles/StyledInfoUserCard';

interface User {
  user?: iUser;
}

const InfoUserCard: React.FC<User> = ({ user }) => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  return (
    <Content>
      <Header>{translator('Dados do paciente')}</Header>
      <Infos>
        <PhotoView>
          <AvatarImage size="large" name={user?.name} src={user?.src} />
          {role !== 'CON' && (
            <LinkProfile
              onClick={() => {
                const url = window.location.pathname;

                if (user?.user)
                  History.getHistory().push(`/profile/${user?.user}`, {
                    from: url.replaceAll('/', ''),
                  });
              }}
            >
              {translator('Ver perfil')}
            </LinkProfile>
          )}
        </PhotoView>
        <UserInfo>
          <Name>
            {`${translator('Nome')}:`}
            {!user?.name ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.name}</div>
            )}
          </Name>
          <Age>
            {`${translator('Idade')}:`}
            {!user?.age ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.age}</div>
            )}
          </Age>
          <Contact>
            {`${translator('Contato')}:`}
            {!user?.contact ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.contact}</div>
            )}
          </Contact>
          <Address>
            {`${translator('Endereço')}:`}
            {!user?.address ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.address}</div>
            )}
          </Address>
          <Email>
            {`${translator('E-mail')}:`}
            {!user?.email ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.email}</div>
            )}
          </Email>
        </UserInfo>
      </Infos>
    </Content>
  );
};

export default InfoUserCard;
