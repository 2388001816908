import { UpdateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/UpdateClinicalDocs';
import {
  ClinicalDocsTypes,
  iActionUpdate,
  iActionUpdateFailed,
  iActionUpdateSucess,
} from '~/domain/interfaces/redux/clinicalDocs/update';

export const UpdateRequest = (
  payload: UpdateClinicalDocs.Params,
): iActionUpdate => ({
  type: ClinicalDocsTypes.UPDATE,
  payload,
});

export const UpdateSuccess = (): iActionUpdateSucess => ({
  type: ClinicalDocsTypes.UPDATE_SUCCESS,
});

export const UpdateFailed = (): iActionUpdateFailed => ({
  type: ClinicalDocsTypes.UPDATE_FAILED,
});
