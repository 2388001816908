import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 14px 12px 19px;
  border: 1px solid #a7a7a7;
  width: 90%;
  /* background-color: whitesmoke; */
  /* margin: 16px 0px 32px 0px; */
  font-size: 12px;
  border-radius: 5px;

  /* .copy {
    max-width: 69px;
  } */
  /* 
  .send {
    max-width: 124px;
    min-width: 66px;
  } */

  button {
    margin-left: 5px;
    padding: 0px;
  }
`;

export const Link = styled.div`
  display: flex;
  justify-content: flex-start;
  background: #ffffff;
  align-items: center;
  color: #004ba5;
  background-color: white;
  padding: 10px 10px;
  text-overflow: ellipsis;
  width: 100%;

  /* max-width: calc(100% - 193px); */
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  /* min-width: 500px; */
  font-size: 16px;

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    color: #7a7a7a;
    cursor: default;
  }
`;
