import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 3em;

  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0.3px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
`;

export const Content = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const BlackBox = styled.div`
  width: 28em;
  height: 230px;
  background-color: black;
  border: black;
  border-radius: 2px;
  padding: 10px;
  margin: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OptionsContainer = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  gap: 40px;
`;

export const Circle = styled.div`
  height: 60px;
  width: 60px;
  padding-top: 10px;
  border: 3px solid #5c5c5c;
  border-radius: 501%;
  text-align: center;
  cursor: pointer;
`;

export const ConfirmCancel = styled.button`
  width: 300px;
  height: 48px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 2px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;
