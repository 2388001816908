import { UpdateDiagnosisReport as UsecaseRemoteGetDiagnosisReportByID } from '~/domain/usecases/diagnosisReport/remote';
import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { RemoteUpdateDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */
export const makeRemoteUpdateDiagnosisReport =
  (): UsecaseRemoteGetDiagnosisReportByID =>
    new RemoteUpdateDiagnosisReport(makeApiUrl('/reports'), makeHttpClient());
