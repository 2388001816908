import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background: rgba(0, 0, 0, 0.5);

  position: fixed;
  top: 0;
  left: 0;

  z-index: 2;

  overflow: hidden;
`;

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  background: #fff;
  overflow: hidden;

  animation: ${slideIn} 0.3s ease-in-out;
`;

export const ListHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-bottom: 1px solid #dedede;
`;

export const ListHeaderTitle = styled.h1`
  color: #222529;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ListHeaderCloseButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.5rem;
  height: 2.5rem;

  svg path {
    stroke: #222529;
    width: 0.875rem;
    height: 0.875rem;
  }
`;
