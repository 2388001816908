import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { SectorsEmpty } from '../componentEmpty';
import { translator } from '../i18n';
import ListSectors from '../ListSectors';

interface ownProps {
  selectUnit: Function;
}

const ListRegisteredSectors: React.FC<ownProps> = ({ selectUnit }) => {
  useEffect(() => {
    makeReduxDataPagination().set([]);
  }, []);

  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );

  return dataPagination.length === 0 ? (
    <SectorsEmpty
      message={translator(
        'Seus setores aparecerão aqui. No momento você não tem nenhum',
      )}
    />
  ) : (
    <ListSectors data={dataPagination} selectUnit={selectUnit} />
  );
};

export default ListRegisteredSectors;
