import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import LayOverLeftCard from '~/presentation/components/layOverLeftCard';
import { V4hSpin } from '~/presentation/components/spin';
import ProfessionalsLayoverTable from './List';
import { CardContainer, Container, TableContainer } from './styles';

const Shifts: React.FC = () => {
  const { loading } = useSelector((store: iStore) => store.shifts);

  const { orgId, orgUnits, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const getProfessionals = useCallback(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    makeReduxGetAllProfessionals().getAll({
      filter: {
        org: orgId,
        unit: role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
      },
    });
  }, [orgId, role, orgUnits]);

  useEffect(() => {
    getProfessionals();
  }, [getProfessionals]);

  return (
    <Container>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 15,
          }}
        >
          <V4hSpin fullScreen background="rgba(0, 0, 0, 0.5)" />
        </div>
      )}
      <CardContainer>
        <LayOverLeftCard />
      </CardContainer>
      <TableContainer>
        <ProfessionalsLayoverTable />
      </TableContainer>
    </Container>
  );
};

export default Shifts;
