export enum waitingRoomTypes {
  'JOIN' = '@waitingroom/JOIN',
  'JOIN_SUCCESS' = '@waitingroom/JOIN_SUCCESS',
  'JOIN_FAILED' = '@waitingroom/JOIN_FAILED',

  'LEFT' = '@waitingroom/LEFT',
  'LEFT_SUCCESS' = '@waitingroom/LEFT_SUCCESS',
  'LEFT_FAILED' = '@waitingroom/LEFT_FAILED',

  'ADD_USER_QUEUE' = '@waitingroom/ADD_USER_QUEUE',
  'REMOVE_USER_QUEUE' = '@waitingroom/REMOVE_USER_QUEUE',
}
