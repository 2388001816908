import { GetInvoiceService } from '~/domain/usecases/billing/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UnexpectedError, NotFound, Forbidden } from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteGetInvoiceService implements GetInvoiceService {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetInvoiceService.Model>;

  constructor(url: string, httClient: HttpClient<GetInvoiceService.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getBillingInvoiceService = async (
    params: GetInvoiceService.Params,
  ): Promise<GetInvoiceService.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.org}/invoices/${params.invoice}/services`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
