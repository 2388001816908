import React from 'react';

import { IconSignedDocuments } from '~/presentation/base/icons';

import { Button } from '~/presentation/components/UI';

import { MediaViewer } from '~/presentation/components/mediaViewer';
import { More } from '~/presentation/components/UI/popover';
import { OptionsClinicalDoc } from '~/presentation/components/optionsMore';
import { translator } from '~/presentation/components/i18n';
import {
  Container,
  Header,
  Left,
  Content,
  Right,
  Signed,
  SendPatient,
  Pdf,
} from './styles/StyledMedicalReportView';

interface ownProps {
  isSigned: string;
  name: string;
}

const MedicalReportView: React.FC<ownProps> = ({ isSigned, name }) => {
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <More
          content={<OptionsClinicalDoc />}
          positionY="top"
          positionX="right"
        />
      </div>
      <Content>
        <Header>
          <div>
            <h2>Paciente:</h2>
            <h1>Danilo</h1>
          </div>

          <Signed>
            <IconSignedDocuments />
            <p>Assinado</p>
          </Signed>
        </Header>
      </Content>
      <Left>
        <div>
          <h1>Criado em 03/11/2020 às 10:00</h1>
        </div>
        <SendPatient>
          {/* <p>Enviado ao paciente: 21/06/2020 </p> */}
        </SendPatient>
      </Left>
      <Pdf>
        <div style={{ width: '100%', height: '100vh' }}>
          {/* <MediaViewer link="https://homepages.dcc.ufmg.br/~fabricio/download/webmedia-short-course.pdf" /> */}
          <MediaViewer link="https://homepages.dcc.ufmg.br/~fabricio/download/webmedia-short-course.pdf" />
        </div>
      </Pdf>
    </Container>
  );
};

export default MedicalReportView;
