import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { MyAppointmentsFilterProvider } from '~/presentation/hooks/filters/myAppointments';
import MyAppointmentsDay from '~/presentation/pages/MyAppointments';
import { makeReduxGetAllAppointment } from '../../usecases/appointment/GetAllAppointmentFactory';

const MyAppointmentsFactory: React.FC = () => {
  const info = useSelector((store: iStore) => store.auth.info);
  const { orgId, orgUnits, role } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const { date } = useSelector((store: iStore) => store.appointment);

  const professionalId = info.professionals?.find(
    item => item.org.id === orgId,
  )?.id;

  const loadAppointmentsToday = useCallback(async () => {
    const from = new Date(date);
    from.setHours(-3, 1, 0, 0);

    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    const to = new Date(date);
    to.setHours(20, 59, 59, 999);
    makeReduxGetAllAppointment().getAll({
      filter: {
        scheduleFrom: new Date(from).toISOString(),
        scheduleTo: new Date(to).toISOString(),
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        professional: professionalId,
      },
      orderAttribute: 'scheduled',
    });
  }, [date, orgId, professionalId, role, orgUnits]);

  useEffect(() => {
    loadAppointmentsToday();
  }, [loadAppointmentsToday]);

  return (
    <MyAppointmentsFilterProvider>
      <div style={{ width: '100%', height: '100%' }}>
        <MyAppointmentsDay />
      </div>
    </MyAppointmentsFilterProvider>
  );
};

export default MyAppointmentsFactory;
