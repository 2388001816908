import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 430px;
  border-radius: 16px;
  border: 1px solid #dedede;
  background-color: #fff;
`;

export const Content = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.div`
  margin-bottom: 8px;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: #222529;
  margin-bottom: 8px;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Info = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: #656a6e;
`;

export const Footer = styled.div`
  border-top: 1px solid #dedede;
  padding: 24px;
`;
