import { createIntlCache, createIntl } from 'react-intl';
import { makeRemoteGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeRemoteGetOneConsultant } from '~/main/factories/usecases/consultant/GetOneConsultant';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { ClinicalDocDetails, iClinicalDocs, iUser } from '../interface';
import { differenceInMonths, differenceInYears } from 'date-fns';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export const MapConsultantDetails = async (
  consultant: number,
): Promise<ClinicalDocDetails> => {
  const patient = await makeRemoteGetClinicalDocs().get({
    docId: consultant,
  });

  if (patient?.patientExternal?.fullname || !patient?.patient?.id)
    return {
      user: {
        name:
          patient.patientExternal?.fullname ??
          `${patient?.patient?.firstName ?? '-'} ${
            patient?.patient?.lastName ?? ''
          }`,
        age: patient.patientExternal?.age
          ? `${patient.patientExternal?.age} anos`
          : '-',
        contact: '-',
        address: '-',
        email: '-',
      },
    };

  const detail = await makeRemoteGetOneConsultant().getOne({
    id: patient.patient?.id,
  });

  function countMonths() {
    if (!detail.user.birthdate) return '-';

    const item = detail.user.birthdate?.split('T')[0];
    const today = new Date();
    const birthDate = new Date(item);

    const age = differenceInYears(today, birthDate);
    const totalMonths = differenceInMonths(today, birthDate);
    const months = totalMonths - age * 12;

    return `${age} ${intl.formatMessage({
      id: 'anos e',
    })} ${months} ${intl.formatMessage({ id: 'meses' })}`;
  }

  const user: iUser = {
    id: Number(detail.consultant),
    name: `${detail.user.firstName} ${detail.user.lastName}`,
    src: patient.patient.avatar,
    age: countMonths(),
    contact: detail.user.phone,
    address: detail?.address?.street.length
      ? `${detail.address?.street}, ${detail.address?.neighborhood}, ${detail.address?.number} ${detail.address?.city}, ${detail.address?.uf}`
      : '-',
    email: detail.user.email,
    user: detail.user.id,
  };

  return { user };
};

export const MapClinicalDocuments = async (
  participant: number,
): Promise<iClinicalDocs[]> => {
  const docs = await makeRemoteGetAllClinicalDocs().getAll({
    filter: {
      patient: participant,
      enabled: true,
    },
  });

  const arr: iClinicalDocs[] = [];

  docs.results.forEach(item => {
    arr.push({
      document: {
        sent: item.document.sent,
      },
      type: item.type,
      signed: item.document.status === 'SIGNED',
      index: item.index.index,
      content: item.document.content, // TODO: Atualizar content
    });
  });

  return arr;
};
