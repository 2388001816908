import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListExamTypesDiagnosisReport as UsecaseRemoteListExamTypesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import { ListExamTypesDiagnosisReport as UsecaseReduxListExamTypesDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemoteListExamTypesDiagnosisReport } from '~/data/repository/diagnosisReport';
import { ReduxListExamTypesDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemoteListExamTypesDiagnosisReport =
  (): UsecaseRemoteListExamTypesDiagnosisReport =>
    new RemoteListExamTypesDiagnosisReport(
      makeApiUrl('/reports/examTypes/list'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListExamTypesDiagnosisReport =
  (): UsecaseReduxListExamTypesDiagnosisReport =>
    new ReduxListExamTypesDiagnosisReport();
