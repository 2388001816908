import styled, { css } from 'styled-components';
import { shade } from 'polished';
import { SessionInfoPropsStyled } from '../interface';

export const BaseContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 100%;
  min-width: max-content;
  height: 80px;
  gap: 10px;
  margin: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Date = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const fontVariantDate = css`
  text-align: center;
  font-weight: 700;
`;

export const Day = styled.h2`
  ${fontVariantDate};
  font-size: 18px;
`;

export const Mounth = styled.h2`
  ${fontVariantDate};
  font-size: 12px;
  text-transform: uppercase;
`;

export const Title = styled.h3`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  font-size: 12px;
  text-align: left;
`;

export const DateTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 20px;
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;

  p {
    font-size: 10px;
  }
`;

export const TitleStatus = styled.div<SessionInfoPropsStyled>`
  font-size: 12px;
  line-height: 20px;
  padding: 2px 12px 2px 12px;
  border-radius: 4px;
  color: ${({ theme, color }) => theme[color]};
  /* background: ${props => props.theme.primaryLight}; */
  background: ${({ theme, background }) => theme[background]};
`;
