import * as z from 'zod';

export const createShiftValidator = z.object({
  professional: z.number().nonnegative('Profissional é obrigatório'),
  begin: z
    .string()
    .nonempty('Período inicial inválido')
    .min(5, 'Período inicial inválido'),
  end: z
    .string()
    .nonempty('Período final inválido')
    .min(5, 'Período final inválido'),
  day: z.enum(['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']),
  recurrence: z.any().nullable(),
});

export const updateShiftValidator = z.object({
  professional: z.number().nonnegative('Profissional é obrigatório').nullable(),
  begin: z
    .string()
    .nonempty('Período inicial inválido')
    .min(5, 'Período inicial inválido'),
  end: z
    .string()
    .nonempty('Período final inválido')
    .min(5, 'Período final inválido'),
});
