import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PresignDiagnosisReport as UsecaseRemotePreSignDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
// import { PreSignDiagnosisReport as UsecaseReduxPreSignDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemotePresignDiagnosisReport } from '~/data/repository/diagnosisReport';
// import { ReduxPreSignDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemotePreSignDiagnosisReport =
  (): UsecaseRemotePreSignDiagnosisReport =>
    new RemotePresignDiagnosisReport(
      makeApiUrl('/reports/{report}/PRESIGN'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxPreSignDiagnosisReport =
  (): UsecaseReduxPreSignDiagnosisReport =>
    new ReduxPreSignDiagnosisReport(); */
