/* eslint-disable @typescript-eslint/no-empty-function */
import { call, put } from 'redux-saga/effects';

import { UpdateShifts } from '~/domain/usecases/shifts/remote';

import {
  updateShiftsFailed,
  updateShiftsSuccess,
} from '~/data/store/reducer/shifts/actions/update';

import { iActionUpdateShifts } from '~/domain/interfaces/redux/shifts/update';
import { makeRemoteUpdateShifts } from '~/main/factories/usecases/shifts';
import { closeModal } from '~/utils/closeModal';

export function* onUpdateShifts(action: iActionUpdateShifts) {
  const remoteUpdateShifts = makeRemoteUpdateShifts();

  try {
    const response: UpdateShifts.Model = yield call(
      remoteUpdateShifts.update,
      action.payload,
    );

    yield put(updateShiftsSuccess(response));
  } catch (e) {
    yield put(updateShiftsFailed());
  }
}

export function onUpdateShiftsSuccess() {
  // makeReduxListShifts().list({ onCallId: 1, body: {} });
  closeModal();
}

export function onUpdateShiftsFailed() {}
