import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAvailability as UsecaseRemoteGetAvailability } from '~/domain/usecases/professionalList/remote';

import { RemoteGetAvailability } from '~/data/repository/professionalList';

/**
 * send request via API.
 */
export const makeRemoteGetAvailability = (): UsecaseRemoteGetAvailability =>
  new RemoteGetAvailability(
    makeApiUrl('/professionals/appointments/availability'),
    makeHttpClient(),
  );
