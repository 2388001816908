import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateOnCallRequest as UsecaseRemoteUpdateOnCallRequest } from '~/domain/usecases/onCallRequest/remote';

import { RemoteUpdateOnCallRequest } from '~/data/repository/onCallRequest';

/**
 * send request via API.
 */
export const makeRemoteUpdateOnCallRequest =
  (): UsecaseRemoteUpdateOnCallRequest =>
    new RemoteUpdateOnCallRequest(
      makeApiUrl('/oncall/requests'),
      makeHttpClient(),
    );
