import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DeleteUser as UsecaseRemoteDeleteUser } from '~/domain/usecases/users/remote';
import { DeleteUser as UsecaseReduxDeleteUser } from '~/domain/usecases/users/redux';

import { RemoteDeleteUser } from '~/data/repository/users';
import { ReduxDeleteUser } from '~/data/store/reducer/users/usecases';

/**
 * send request via API.
 */
export const makeRemoteDeleteUser = (): UsecaseRemoteDeleteUser =>
  new RemoteDeleteUser(makeApiUrl('/users'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxDeleteUser = (): UsecaseReduxDeleteUser =>
  new ReduxDeleteUser();
