import { Avatar } from '@material-ui/core';
import React from 'react';
import { History } from '~/main/routes';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { translator } from '~/presentation/components/i18n';
import { iParticipants } from '~/presentation/roles/professional/pages/appointment/interface';
import { iUser } from './interface';

import {
  Content,
  Header,
  InfosVariant,
  UserInfo,
  PhotoView,
  LinkProfile,
  Name,
  Age,
  Contact,
  Address,
  Email,
  NameVariant,
} from './styles/StyledUserInformation';

interface PropsInfo {
  name?: string;
  age: string;
  src?: string;
  contact?: string;
  address?: string;
  email?: string;
}

interface User {
  participants?: iParticipants;
}

const InfoUserOrgView: React.FC<User> = ({ participants }) => {
  return (
    <Content>
      <Header>{translator('Participantes da Consulta')}</Header>
      <InfosVariant>
        <PhotoView>
          <AvatarImage
            size="large"
            name={participants?.consultant.name}
            src={participants?.consultant.src}
          />
          <NameVariant>
            <div className="info">{participants?.consultant.name}</div>
          </NameVariant>
          <NameVariant>{translator('Paciente')}</NameVariant>
          <LinkProfile
            id="btn_consultant_profile"
            onClick={() => {
              const url = window.location.pathname;
              History.getHistory().push(
                `/profile/${participants?.consultant.user}`,
                {
                  from: url.replaceAll('/', ''),
                },
              );
            }}
          >
            {translator('Ver perfil')}
          </LinkProfile>
        </PhotoView>
        <PhotoView>
          <AvatarImage
            size="large"
            name={participants?.professional.name}
            src={participants?.professional.src}
          />
          <NameVariant>
            <div className="info">{participants?.professional.name}</div>
          </NameVariant>
          <NameVariant>{translator('Profissional')}</NameVariant>
          <LinkProfile
            id="btn_professional_profile"
            onClick={() => {
              const url = window.location.pathname;
              History.getHistory().push(
                `/profile/${participants?.professional.user}`,
                {
                  from: url.replaceAll('/', ''),
                },
              );
            }}
          >
            {translator('Ver perfil')}
          </LinkProfile>
        </PhotoView>
      </InfosVariant>
    </Content>
  );
};

export default InfoUserOrgView;
