import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

export const Header = styled.div`
  width: 100%;
  height: 3.3em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #f4f7fe;
  border-radius: 4px;

  span {
    font-size: 1.4em;
    font-weight: bold;
    color: #004ba5;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 18px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 20em;
  max-height: 13em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const FirstMessage = styled.span`
  height: 100%;
  width: 11em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 45px;
  margin-left: 73px;

  color: black;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: center;
  text-overflow: ellipsis;
`;

export const Message = styled.div`
  width: 100%;
  height: 50%;

  display: flex;
  flex-direction: center;
  justify-content: center;

  span {
    color: #565757;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;
