import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iListSectors } from '../interface';
import { iDashboard, iOrgUnit, iStore } from '~/domain/interfaces/models';
import { makeReduxSetupDashboard } from '~/main/factories/usecases/dashboard/SetupDashboardFactory';

import { ownProps } from '../index';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { dashboard, orgUnit } = useSelector((store: iStore) => store);

    /* eslint no-param-reassign: "error" */
    Component.defaultProps = {
      data: MapSectorData(dashboard, orgUnit),
      selectUnit: (index: number) => {
        makeReduxSetupDashboard().setup({
          selectedUnit: index,
        });
      },
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

const MapSectorData = (
  dashboard: iDashboard,
  orgUnit: iOrgUnit,
): iListSectors[] => {
  const arr: iListSectors[] = [];

  const sectors = dashboard.units.results;

  // TODO: Avaliar solução mais eficiente para exibir apenas uma orgUnit mesmo que haja diversos meses
  sectors.forEach(item => {
    if (
      orgUnit.results.find(ouItem => ouItem.orgUnit.id === item.unit.id) &&
      !arr.find(arrItem => arrItem.sector === item.unit.name)
    )
      arr.push({
        id: item.unit.id,
        institution: item.org.shortname,
        sector: item.unit.name,
        totalPatient: item.users.consultants,
        totalProfessional: item.users.professionals,
        monthStorage: item.storage.used,
      });
  });

  return arr;
};
