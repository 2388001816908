import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { iClinicalDocs, iStore } from '~/domain/interfaces/models';
import { pageProps } from '~/presentation/pages/participant/clinicalDocument';
import { iListClinicalDocument } from '../interface';

/**
 * Mapping of the clinicalDocs list data.
 *
 * @param Component component to connect.
 */
export function ConnectClinicalDocs<P>(
  Component: ComponentType<P & pageProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const clinicalDocs = useSelector((store: iStore) => store.clinicalDocs);

    /* eslint no-param-reassign: "error" */

    Component.defaultProps = {
      data: clinicalDocs.loading
        ? []
        : _.orderBy(
            // Default descending order for date
            mapClinicalDocsData(clinicalDocs),
            item => {
              const splitDate = item.date.split('/');
              const internationalDate =
                splitDate.length < 3
                  ? item.date
                  : `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;

              return new Date(internationalDate).getTime();
            },
            ['desc'],
          ),
      loading: clinicalDocs.loading,
      ...(rest as P),
    };

    return <Component {...(rest as P & pageProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param clinicalDocs data.
 */
const mapClinicalDocsData = (
  clinicalDocs: iClinicalDocs,
): iListClinicalDocument[] => {
  const arr: iListClinicalDocument[] = [];
  clinicalDocs.results.forEach(item => {
    const date = item.document?.sent.split('T')[0].split('-');

    arr.push({
      avatar: item.professional.avatar,
      fullName: `${item.professional.firstName} ${item.professional.lastName}`,
      userId: item.professional.id,
      status: item.professional.status,
      specialty: item.specialty.name,
      date: `${date![2]}/${date![1]}/${date![0]}`,
      statusSignature: item!.document!.status,
      typeIs: item.type.name,
      origin: item!.document!.origin,
      id: item.document!.id.toString(),
    });
  });

  return arr;
};
