import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadReport as UsecaseRemoteDownloadReport } from '~/domain/usecases/appointment/remote';

import { RemoteDownloadReport } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteDownloadReport = (): UsecaseRemoteDownloadReport =>
  new RemoteDownloadReport(
    makeApiUrl('/appointments/{appointmentId}/report'),
    makeHttpClient(),
  );
