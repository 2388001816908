import styled, { css } from 'styled-components';

interface ownProps {
  checked: boolean;
}

export const Container = styled.div`
  width: 100%;
  background-color: #f4f7fe;
  gap: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;

    text-align: center;
  }

  #body {
    background-color: #f4f7fe;
    max-width: 900px;
    width: 100%;
    gap: 20px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

export const Content = styled.div`
  width: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    font-size: 18px;
    font-weight: bold;
  }
`;

const Signed = css<ownProps>`
  display: flex;
  align-items: center;
  padding: 5px 7px;
  gap: 5px;
  width: 100%;
  justify-content: flex-start;
  ${props =>
    !props.checked
      ? css`
          color: #a3a3a3;
        `
      : css`
          color: #004ba5;
        `}
`;
export const SignedNow = styled.div<ownProps>`
  ${Signed}
`;

export const SignedAfter = styled.div`
  ${Signed}
`;

export const MessageCertificate = styled.div<ownProps>`
  display: flex;
  width: 90%;
  ${props =>
    !props.checked &&
    css`
      color: #a3a3a3;
    `}
`;

export const ContentSignature = styled.div`
  max-width: 900px;
  width: 90%;
  gap: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #e3e3e3;
`;

export const ContentSignDoc = styled.div`
  max-width: 900px;
  width: 90%;
  gap: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
