import { Dispatch } from 'redux';
import store from '~/data/store';
import { setSpecialistStatusRequest } from '~/data/store/reducer/onCall/actions/setSpecialistStatus';

import { iActionSetSpecialistStatus } from '~/domain/interfaces/redux/onCall/setSpecialistStatus';

import { SetSpecialistStatusOnCall } from '~/domain/usecases/onCall/redux';

export class ReduxSetSpecialistStatusOnCall
  implements SetSpecialistStatusOnCall
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setSpecialistStatus = (
    params: SetSpecialistStatusOnCall.Params,
  ): iActionSetSpecialistStatus =>
    this.send(setSpecialistStatusRequest(params));
}
