import React from 'react';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '~/main/routes/PrivateRoute';

import {
  MakeAppointmentPage,
  MakeAppointmentDetailsPage,
  MakeNewAppointmentPage,
  MakeNewInterconsultation,
  MakeAppointmentDetailPage,
  MakeAppointmentConfirmationPage,
} from '~/main/factories/pages/appointment';
import {
  MakeWaitingRoom,
  MakeWaitingRoomLaoha,
} from '~/main/factories/pages/waitingRoom';

import { MakeCreateSimplePrescriptionPage } from '~/main/factories/pages/clinicalDocs';
import { UserLeaveSpecialty } from '~/presentation/components/messages/user';
import WaitingRoomCarousel from '~/presentation/components/WaitingRoomCarousel';
import MyAppointmentsFactory from '../factories/pages/appointment/MyAppointmentsFactory';
import MakeAppointmentHistoryPage from '../factories/pages/appointment/AppointmentHistory';

const RoutesAppointment = [
  <PrivateRoute exact path="/appointment" component={MakeAppointmentPage} />,
  <PrivateRoute
    exact
    path="/appointment/view"
    component={MakeAppointmentDetailsPage}
  />,
  <PrivateRoute
    exact
    path="/appointment/new"
    component={MakeNewAppointmentPage}
  />,
  <PrivateRoute
    exact
    path="/appointment/new/interconsult"
    component={MakeNewInterconsultation}
  />,
  // <Route path="/appointment/edit" component={MakeEditAppointmentPage} />,
  <PrivateRoute
    exact
    path="/appointment/detail"
    component={MakeAppointmentDetailPage}
  />,
  <PrivateRoute
    exact
    path="/appointment/me"
    component={MyAppointmentsFactory}
  />,
  <Route
    exact
    path="/appointment/confirmation"
    component={MakeAppointmentConfirmationPage}
  />,
  <PrivateRoute
    exact
    path="/appointment/waitingroom"
    component={MakeWaitingRoom}
  />,
  <PrivateRoute
    exact
    path="/appointment/clinicaldocs/create"
    component={MakeCreateSimplePrescriptionPage}
  />,
  <PrivateRoute
    exact
    path="/appointment/:language"
    component={MakeAppointmentPage}
  />,
  <PrivateRoute exact path="/history" component={MakeAppointmentHistoryPage} />,
];

export default RoutesAppointment;
