import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/appointment/getAll';
import { iStore } from '~/domain/interfaces/models';
import { GetAllAppointment } from '~/domain/usecases/appointment/remote/GetAllAppointment';
import { makeRemoteGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/appointment/actions/getAll';

import { MapTotalItems } from '~/data/mapper/appointment/totalItems';

export const getDate = (state: iStore): Date => state.appointment.date;

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllAppointment = makeRemoteGetAllAppointment();
  const date: Date | string = yield select(getDate);

  try {
    const response: GetAllAppointment.Model = yield call(
      remoteGetAllAppointment.getAll,
      action.payload,
    );
    yield put(
      getAllSuccess({
        ...response,
        totalItems: MapTotalItems(response, String(date)),
      }),
    );
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
