import React, { useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { IconCloseButtonModal } from '~/presentation/base/icons';
import { SendClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { makeRemoteSendClinicalDocs } from '~/main/factories/usecases/clinicalDocs/SendClinicalDocs';

import { schemaEmail } from '~/validation/validators/email/emaiValidator';
import storeDev from '~/data/store';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { AlertMessage } from '../AlertMessage';
import { Button, Input } from '../../UI';
import { translator } from '../../i18n';
import { Body, ButtonsDiv, Container, Header } from './styles/StyledSendMail';

interface ownProps {
  close: () => any;
  docId: number;
  initialValue?: string;
}

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export const SendMail: React.FC<ownProps> = ({
  close,
  docId,
  initialValue = '',
}) => {
  const [email, setEmail] = useState<string>();

  const { errors, handleSubmit, register, setValue } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaEmail),
    defaultValues: {
      email,
    },
  });

  const sendMail = handleSubmit(async data => {
    const dataToSend: SendClinicalDocs.Params = {
      docId: String(docId),
      email: [data.email],
    };
    try {
      await makeRemoteSendClinicalDocs().post(dataToSend);
      storeDev.dispatch({
        type: MessageOptions.clinicalDocsSendByEmailSuccess,
      });
    } catch (e) {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Não é possível enviar o documento antes de assinado!',
        }),
        type: 'danger',
      });
    }
    close();
  });

  return (
    <Container>
      <Body>
        <Header>
          <span>{translator('Enviar documento por e-mail')}</span>
          <IconCloseButtonModal
            onClick={close}
            style={{ marginLeft: '15px', cursor: 'pointer' }}
          />
        </Header>
        <Input
          name="email"
          label="Email"
          defaultValue={initialValue}
          onChange={e => setValue('email', e.target.value)}
          register={() => register('email')}
          placeholder={translator('Digite o seu email')}
          width="240px"
          error={Boolean(errors.email)}
          message={
            errors?.email?.message ? translator(errors?.email?.message) : ''
          }
          autoFocus
        />
        <ButtonsDiv>
          <Button
            variant="primary"
            name="email"
            size="large"
            onClick={() => sendMail()}
            rounded
          >
            {translator('Enviar')}
          </Button>
          <Button rounded variant="secundary" size="large" onClick={close}>
            {translator('Cancelar')}
          </Button>
        </ButtonsDiv>
      </Body>
    </Container>
  );
};
