import React, { useState } from 'react';
import {
  Container,
  Content,
  Footer,
  ContainerModal,
  Text,
  SelectContainer,
} from './styles/StyledModalSketchReporting';
import { Icon, Typography, Button } from '@wisecare/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import SearchSelect from '../UI/searchSelect';
import { Select } from '../UI';
import { makeRemotePreSignDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/PreSignDiagnosisReportFactory';
import { makeRemoteSignDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/SignDiagnosisReportFactory';

interface ownProps {
  message: iMessage;
}

const ModalSignReport: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const msgName = MessageOptions.signReport;

  const [selectedOption, setSelectedOption] = useState<string>('1');

  const options = [
    { value: '1', label: 'Selecione seu assinador' },
    // TODO: Implementar ledger
    // { value: '2', label: 'Ledger' },
    { value: '3', label: 'VIDaaS' },
  ];

  const handleSubmit = () => {
    makeRemotePreSignDiagnosisReport()
      .presign({
        id: data?.id,
        body: {
          signatureSource: 'VIDAAS',
        },
      })
      .then(res => {
        const url = res.dataToSign;
        localStorage.setItem('diagnosisReportId', data?.id);
        window.location.href = url;
      })
      .catch(err => {
        console.log('>>> Err 1: ', err);
      });
  };

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#E6F0FB',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="info" color="blue-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Assinar o laudo{' '}
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Selecione seu assinador e depois seu certificado digital para
                  finalizar a assinatura do documento.
                </Typography>
              </Text>
              <SelectContainer>
                <div className="select">
                  <strong>Assinador</strong>
                  <Select
                    value={selectedOption}
                    onChange={e => setSelectedOption(e.target.value)}
                  >
                    {options.map(item => (
                      <option value={item.value} key={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="select">
                  <strong>Certificado digital</strong>
                  <Select
                    disabled={selectedOption === '1' || selectedOption === '3'}
                  >
                    <option value="1">Selecione</option>
                  </Select>
                </div>
              </SelectContainer>
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel && closeModal}>
                Cancelar{' '}
              </Button>
              <Button variant="primary" onClick={handleSubmit}>
                Assinar{' '}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalSignReport;
