import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/Consultant/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/Consultant/getAll';

import { GetAllConsultant } from '~/domain/usecases/consultant/redux';

export class ReduxGetAllConsultant implements GetAllConsultant {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllConsultant.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
