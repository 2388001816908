import React from 'react';
import CloseButton from '~/presentation/base/icons/CloseButton.png';
import { ContainerHeader } from './styles/StyledHeaderChat';
import { AvatarRoles } from '../avatar-roles';

export interface propsHeader {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    mainRole: any;
  };
  close: Function;
}

const HeaderChat: React.FC<propsHeader> = ({ user, close }) => {
  /* Substituir por um loading */
  const { firstName, lastName, mainRole } =
    user === undefined ? { firstName: '', lastName: '', mainRole: '' } : user;

  return (
    <ContainerHeader>
      <AvatarRoles
        role="paciente"
        name={`${firstName} ${lastName}`}
        cargo={mainRole}
      />
      <button type="button" onClick={() => close()}>
        <img src={CloseButton} alt="Fechar" />
      </button>
    </ContainerHeader>
  );
};

export default HeaderChat;
