import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 12px 32px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 2px solid lightgray;
  border-radius: 8px;
  background: white;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 16px 0;
  gap: 12px;
`;
