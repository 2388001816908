import { GetShiftsById as UsecaseReduxGetShiftsById } from '~/domain/usecases/shifts/redux/GetShiftsById';
import { GetShiftsById as UsecaseRemoteGetShiftsById } from '~/domain/usecases/shifts/remote';

import { makeApiUrl, makeHttpClient } from '../../http';

import { RemoteGetShiftsById } from '~/data/repository/shifts';
import { ReduxGetShiftsById } from '~/data/store/reducer/shifts/usecases';

export const makeRemoteGetShiftsById = (): UsecaseRemoteGetShiftsById =>
  new RemoteGetShiftsById(
    makeApiUrl('/oncall/{onCallId}/shifts'),
    makeHttpClient(),
  );

export const makeReduxGetShiftsById = (): UsecaseReduxGetShiftsById =>
  new ReduxGetShiftsById();
