import React, { useEffect, useState } from 'react';
import zxcvbn from 'zxcvbn';
import Bar from './Bar';
import MessageSecurity from './Message';
import { Container } from './styles/StyledPasswordValidationBar';

interface wordScore {
  password: string;
  updateScore?: (score: number) => void;
}

const PasswordValidationBar: React.FC<wordScore> = ({
  password,
  updateScore,
}) => {
  const [num, setNum] = useState(0);
  const [score, setScore] = useState<number>(0);

  useEffect(() => {
    const testResult = zxcvbn(password);

    const doesPasswordHaveLengthGreaterThan8 = password.length >= 8;
    const doesPasswordHaveAtLeastOneUpperCaseLetter = /[A-Z]/.test(password);
    const doesPasswordHaveAtLeastOneLowerCaseLetter = /[a-z]/.test(password);
    const doesPasswordHaveAtLeastOneNumber = /[0-9]/.test(password);
    const doesPasswordHaveAtLeastOneSpecialCharacter = /[^A-Za-z0-9]/.test(
      password,
    );

    const howManyConditionsAreMet = [
      doesPasswordHaveLengthGreaterThan8,
      doesPasswordHaveAtLeastOneUpperCaseLetter,
      doesPasswordHaveAtLeastOneLowerCaseLetter,
      doesPasswordHaveAtLeastOneNumber,
      doesPasswordHaveAtLeastOneSpecialCharacter,
    ].filter(condition => condition).length;

    let temp = (howManyConditionsAreMet * 100) / 5;

    if (temp > 100) {
      temp = 100;
    }

    setNum(temp);
    setScore(howManyConditionsAreMet);
    updateScore?.(howManyConditionsAreMet);
  }, [password, updateScore]);

  return (
    <Container>
      <Bar score={score} num={num} />
      <MessageSecurity scores={score} />
    </Container>
  );
};

export default PasswordValidationBar;
