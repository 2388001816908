import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
`;

export const Popover = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
`;

export const Option = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 5px;
  margin: 10px;
`;

export const Text = styled.div`
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: #7a7a7a;
`;
