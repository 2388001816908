import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetOneConsultant as UsecaseRemoteGetOneConsultant } from '~/domain/usecases/consultant/remote';

import { RemoteGetOneConsultant } from '~/data/repository/consultant/RemoteGetOneConsultant';

/**
 * send request via API.
 */
export const makeRemoteGetOneConsultant = (): UsecaseRemoteGetOneConsultant =>
  new RemoteGetOneConsultant(makeApiUrl('/consultants'), makeHttpClient());
