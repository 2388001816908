import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-radius: 8px;
  background: transparent;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 470px;
  border: 1px solid #efefef;
  border-radius: 8px;
  gap: 20px;
`;

export const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  margin-left: 20px;
  color: #000000;
  font-size: 25px;
  font-weight: bold;
`;

export const OptionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;

export const Options = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  :hover {
    cursor: pointer;
  }

  text {
    font-size: 19px;
    color: #000000;
    padding: 7px;
  }
`;

interface iOption {
  selected: boolean;
}

export const OptionItem = styled.div<iOption>`
  width: 80%;
  padding: 10px;
  gap: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  border-bottom: 3px solid #000000;
  color: ${props => (props.selected ? '#004BA5' : '#000000')};
  border-bottom: 3px solid ${props => (props.selected ? '#004BA5' : '#000000')};
`;
