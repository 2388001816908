import { InsertAppointment } from '~/domain/usecases/appointment/redux';
import { iActionInsert } from '~/domain/interfaces/redux/appointment/insert';
import { AppointmentTypes } from '~/domain/interfaces/redux/appointment/types';

export const insertAppointment = (
  payload: InsertAppointment.Params,
): iActionInsert => ({
  type: AppointmentTypes.INSERT_APPOINTMENT,
  payload,
});
