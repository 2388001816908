import { Dispatch } from 'redux';
import store from '~/data/store';
import { CancelAppointment } from '~/domain/usecases/appointment/redux';
import { iActionCancel } from '~/domain/interfaces/redux/appointment/cancelAppointment';
import { cancelRequest } from '../actions/cancelAppointment';

export class ReduxCancelAppointment implements CancelAppointment {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  cancel = (params: CancelAppointment.Params): iActionCancel =>
    this.send(cancelRequest(params));
}
