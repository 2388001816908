import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 12px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: ${defaultTheme.typographyDarkGray};
  font-weight: bold;
  padding-bottom: 7px;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text {
      padding: 3px;
    }
  }
`;

export const ListRecording = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #7a7a7a;
`;
