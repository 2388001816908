import { Dispatch } from 'redux';
import store from '~/data/store';
import { iActionSetup } from '~/domain/interfaces/redux/appointment/setupAppointment';
import { SetupAppointment } from '~/domain/usecases/appointment/redux';
import { setupRequest } from '../actions/setupAppointment';

export class ReduxSetupAppointment implements SetupAppointment {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setup = (params: SetupAppointment.Params): iActionSetup =>
    this.send(setupRequest(params));
}
