import { takeLatest } from 'redux-saga/effects';
import { waitingRoomTypes } from '~/domain/interfaces/redux/waitingroom/types';

import * as Join from './Join';
import * as Left from './Left';

const tasks = [
  takeLatest(waitingRoomTypes.JOIN, Join.onJoin),
  takeLatest(waitingRoomTypes.JOIN_SUCCESS, Join.onJoinSuccess),
  takeLatest(waitingRoomTypes.JOIN_FAILED, Join.onJoinFailed),

  takeLatest(waitingRoomTypes.LEFT, Left.onLeft),
  takeLatest(waitingRoomTypes.LEFT_SUCCESS, Left.onLeftSuccess),
  takeLatest(waitingRoomTypes.LEFT_FAILED, Left.onLeftFailed),
];

export default tasks;
