/* eslint-disable spaced-comment */
import React, { useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { makeRemoteJoinSpecialistOnCall } from '~/main/factories/usecases/onCall/JoinSpecialistOnCallFactory';
import { iStore } from '~/domain/interfaces/models';
import { IconCardiology } from '~/presentation/base/icons';
import { MessageOptions } from '../Inactive/InactiveWarning/styles';
import {
  ContainerCards,
  ContentCards,
  Specialty,
  SpecialtyName,
} from './styles/StyledSpecialtyCard';

interface iSpecialtyCardProps {
  icon: string;
  name: string;
  disabled?: boolean;
  id: number;
}

const SpecialtyCard: React.FC<iSpecialtyCardProps> = ({
  icon,
  name,
  disabled,
  id,
}) => {
  const [error, setError] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();

  const handleClick = useCallback(() => {
    const isCreate = pathname.includes('create');

    if (!isCreate) {
      history.push(`/duty/${id}`, {
        specialty: name,
        id,
      });
      return;
    }

    if (disabled) return;

    makeReduxActiveMessage().active({
      active: 'requestConsultation',
      actionOk: () => {
        closeModal();
      },
      actionCancel: () => {
        closeModal();
      },
      data: {
        onCallId: id,
        specialty: name,
      },
    });
  }, [disabled, history, id, name, pathname]);

  return (
    <ContainerCards onClick={handleClick} disabled={disabled}>
      <ContentCards>
        <Specialty>
          {!error && icon ? (
            <img
              width={42}
              height={38}
              src={icon}
              alt="Icon"
              style={{
                objectFit: 'contain',
              }}
              onError={() => setError(true)}
            />
          ) : (
            <IconCardiology />
          )}
          <SpecialtyName>{name}</SpecialtyName>
        </Specialty>
      </ContentCards>
    </ContainerCards>
  );
};

export default SpecialtyCard;
