import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { RejectDiagnosisReport as UsecaseRemoteRejectDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteRejectDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */

export const makeRemoteRejectDiagnosisReport =
  (): UsecaseRemoteRejectDiagnosisReport =>
    new RemoteRejectDiagnosisReport(makeApiUrl('/reports'), makeHttpClient());
