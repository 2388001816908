import Avatar from '@material-ui/core/Avatar';
import { deepOrange } from '@material-ui/core/colors';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > *': {
        // margin: theme.spacing(1),
      },
    },
    mini: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      margin: theme.spacing(1),
    },
    small: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      margin: theme.spacing(1),
    },
    medium: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      margin: theme.spacing(1),
    },
    large: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      margin: theme.spacing(1),
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      margin: theme.spacing(1),
    },
  }),
);

interface PropsAvatarImage {
  src?: string;
  name?: string;
  size: 'orange' | 'mini' | 'small' | 'medium' | 'large' | 'root';
}

const AvatarImage: React.FC<PropsAvatarImage> = ({ src, name, size }) => {
  const classes = useStyles();
  const fallbackAvatar = (
    <Avatar src="/broken-image.jpg" className={classes[size]} />
  );
  const initials = (
    <Avatar alt={name} src="/broken-image.jpg" className={classes[size]} />
  );
  if (!name && !src) {
    return <div className={classes[size]}>{fallbackAvatar}</div>;
  }
  if (src) {
    return (
      <div className={classes.root}>
        <Avatar alt={name} src={src} className={classes[size]} />
      </div>
    );
  }
  return <div className={classes.root}>{initials}</div>;
};

export default AvatarImage;
