import styled from 'styled-components';

export const ContainerChooseDocuments = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 10px;
`;

export const ContentInputs = styled.div`
  display: grid;
  grid-template-areas: 'drug dosage' 'posology posology';
  gap: 20px;
  width: 100%;
`;

export const Drug = styled.div`
  grid-area: drug;
`;

export const Dosage = styled.div`
  grid-area: dosage;
`;

export const Posology = styled.div`
  grid-area: posology;
`;

export const ButtonAdd = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  width: 100%;
`;

export const ContainerLeft = styled.div`
  width: 100%;
`;

export const ContainerRight = styled.div`
  width: 100%;
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 13px;
  }
`;

export const ListPrescription = styled.div`
  padding: 20px;
  max-width: 100%;
  min-width: 209px;
  min-height: 255px;
  background: #f4f7fe;
`;
