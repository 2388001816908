import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const Body = styled.div`
  flex-direction: column;
  padding: 20px 72px 0px 73px;
  width: 100%;
  display: flex;
`;

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;
  justify-content: space-between;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
