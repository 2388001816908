import {
  DutyTypes,
  iActionGetProfessionalOnDuty,
  iActionGetProfessionalOnDutyFailed,
  iActionGetProfessionalOnDutySuccess,
} from '~/domain/interfaces/redux/duty/getProfessionalOnDuty';
import { GetProfessionalOnDuty } from '~/domain/usecases/duty/redux';

export const getProfessionalOnDuty = (
  payload: GetProfessionalOnDuty.Params,
): iActionGetProfessionalOnDuty => ({
  type: DutyTypes.GET_PROFESSIONAL_ON_DUTY,
  payload,
});

export const getProfessionalOnDutySuccess = (
  params: GetProfessionalOnDuty.Model,
): iActionGetProfessionalOnDutySuccess => ({
  type: DutyTypes.GET_PROFESSIONAL_ON_DUTY_SUCCESS,
  payload: params,
});

export const getProfessionalOnDutyFailed =
  (): iActionGetProfessionalOnDutyFailed => ({
    type: DutyTypes.GET_PROFESSIONAL_ON_DUTY_FAILED,
  });
