import React, { useState, useEffect } from 'react';

import { Check, Select } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';
import { AddAppointmentData } from '~/domain/usecases/appointment/redux';
import { iAppointment } from '~/domain/interfaces/models/Appointment';
import { Content } from './styles/styledGeneralData';
import { iListConsultant, iListSpeciality } from './interface';

import { ConnectComponent } from './mapper/MapperGeneralData';

export interface ownProps {
  appointment: iAppointment;
  handlerState: AddAppointmentData;
  specialty: iListSpeciality[];
  consultant: iListConsultant[];
}

const GeneralData: React.FC<ownProps> = ({
  specialty,
  consultant,
  handlerState,
  appointment,
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [state, setState] = useState<{
    consultantId?: number;
    specialityId?: number;
  }>({
    consultantId: appointment.create.data.consultant || 0,
    specialityId: appointment.create.data.specialty || 0,
  });

  useEffect(() => {
    return () => {
      handlerState.add({
        data: {
          consultant: state.consultantId,
          specialty: state.specialityId,
        },
      });
    };
  }, [state]);

  function handleCheck() {
    if (checked) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }

  return (
    <Content>
      <div>
        <Select
          name="consultant"
          onChange={e =>
            // eslint-disable-next-line prettier/prettier
            setState({ ...state, consultantId: Number(e.target.value) })
          }
          value={state.consultantId}
          label={translator('Paciente em questão')}
        >
          <option value="select">{translator('Selecione um paciente')}</option>
          {consultant.map(item => (
            <option value={Number(item.id)}>
              {`${item.firstName} ${item.lastName}`}
            </option>
          ))}
        </Select>
        <Check
          checked={checked}
          onClick={handleCheck}
          label={translator('Convidar paciente para participar')}
        />
      </div>
      <Select
        name="speciality"
        onChange={e =>
          // eslint-disable-next-line prettier/prettier
          setState({ ...state, specialityId: Number(e.target.value) })
        }
        value={state.specialityId}
        label={translator('Especialidade')}
      >
        <option value="select">
          {translator('Selecione uma especialidade')}
        </option>
        {specialty.map(item => (
          <option value={Number(item.specialty?.id)}>
            {item.specialty?.name}
          </option>
        ))}
      </Select>
    </Content>
  );
};

export default ConnectComponent(GeneralData);
