import * as _ from 'lodash';
import { iMenuNotification } from '~/domain/interfaces/components/menu';
import { iNotifications } from '~/domain/interfaces/models';

export const MapNotificationsData = (
  notifications: iNotifications,
): iMenuNotification[] => {
  const arr: iMenuNotification[] = [];

  notifications.notifications.results.forEach(item => {
    arr.push({
      index: item.index,
      id: item.id,
      message: item.message,
      notification: item.notification,
      channel: item.channel,
      read: item.read,
      viewed: item.viewed,
      user: item.user,
    });
  });

  notifications.notifications.results = _.sortBy(
    notifications.notifications.results,
    item => item.id,
  );

  console.log(notifications);

  return arr;
};
