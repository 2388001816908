import React from 'react';

import { IconBag, IconSuccessIcon, IconUser } from '~/presentation/base/icons';
import {
  Body,
  Content,
  LinkContainer,
  LinkHeader,
  LinkItem,
} from './styles/StyledAppointmentSuccessfullyCreated';
import { Container } from '../Background';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import GenerateLink from '~/presentation/components/link/GenerateLink';

import { ownProps } from '../interface';

import { Button } from '../../UI';
import { translator } from '../../i18n';

const AppointmentCreatedSuccessfully: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.appointmentCreate;

  const { active, actionCancel, actionOk, appointmentLinks } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Content>
              <IconSuccessIcon width="60px" height="60px" />
              <p>{translator('Atendimento agendado com sucesso!')}</p>
            </Content>
            <LinkContainer>
              <LinkItem>
                <LinkHeader>
                  <IconBag />
                  <p>{translator('Link para o profissional')}</p>
                </LinkHeader>
                <GenerateLink
                  link={
                    appointmentLinks?.length
                      ? appointmentLinks[0]
                      : 'http://localhost:3000/appointment/detail'
                  }
                />
              </LinkItem>
              <LinkItem>
                <LinkHeader>
                  <IconUser />
                  <p>{translator('Link para o paciente')}</p>
                </LinkHeader>
                <GenerateLink
                  link={
                    appointmentLinks?.length
                      ? appointmentLinks[1]
                      : 'http://localhost:3000/appointment/detail'
                  }
                />
              </LinkItem>
            </LinkContainer>
            <Button
              id="btn_confirm"
              rounded
              variant="primary"
              size="large"
              onClick={actionOk}
              autoFocus
              style={{ height: '52px' }}
            >
              {translator('OK')}
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default AppointmentCreatedSuccessfully;
