import { Dispatch } from 'redux';
import store from '~/data/store';
import { createRequest } from '~/data/store/reducer/Professional/actions/create';

import { iActionCreate } from '~/domain/interfaces/redux/professionalList/create';

import { CreateProfessional } from '~/domain/usecases/professionalList/redux';

export class ReduxCreateProfessional implements CreateProfessional {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateProfessional.Params): iActionCreate =>
    this.send(createRequest(params));
}
