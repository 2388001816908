import { Refresh as UseCaseReduxRefresh } from '~/domain/usecases/auth/redux';
import { Refresh as UseCaseRemoteRefresh } from '~/domain/usecases/auth/remote';

import { ReduxRefresh } from '~/data/store/reducer/auth/usecases/ReduxRefresh';
import { RemoteAuthRefresh } from '~/data/repository/auth';
import { makeApiUrl, makeHttpClient } from '../../http';

/**
 * make login via API.
 */
export const makeRemoteRefresh = (): UseCaseRemoteRefresh =>
  new RemoteAuthRefresh(makeApiUrl('/auth/refresh'), makeHttpClient());

/**
 * make login via SAGA.
 */
export const makeReduxRefresh = (): UseCaseReduxRefresh => new ReduxRefresh();
