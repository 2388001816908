import { DeleteUser } from '~/domain/usecases/users/redux';
import {
  iActionDelete,
  iActionDeleteFailed,
  iActionDeleteSuccess,
  UsersTypes,
} from '~/domain/interfaces/redux/users/delete';

export const deleteRequest = (payload: DeleteUser.Params): iActionDelete => ({
  type: UsersTypes.DELETE,
  payload,
});

export const deleteSuccess = (
  payload: DeleteUser.Params,
): iActionDeleteSuccess => ({
  type: UsersTypes.DELETE_SUCCESS,
  payload,
});

export const deleteFailed = (): iActionDeleteFailed => ({
  type: UsersTypes.DELETE_FAILED,
});
