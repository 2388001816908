import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { SetupDashboard as UsecaseReduxSetupDashboard } from '~/domain/usecases/dashboard/redux';

import { ReduxSetupDashboard } from '~/data/store/reducer/dashboard/usecases';

/**
 * send request via REDUX.
 */
export const makeReduxSetupDashboard = (): UsecaseReduxSetupDashboard =>
  new ReduxSetupDashboard();
