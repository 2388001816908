import styled from 'styled-components';
import { shade } from 'polished';

export const Link = styled.a`
  font-size: 12px;
  color: #64696f;
  cursor: pointer;
  font-weight: bold;
  :hover {
    color: ${shade(0.25, '#0000ff')};
  }
`;
