import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9000;
`;

export const Header = styled.div`
  width: 100%;
  height: 3.2em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-bottom: 2px solid #efefef;
  color: #7a7a7a;

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #eb5757;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 24em;
  max-height: 32em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div`
  justify-content: center;
  align-items: center;
  padding-top: 60px;

  h3 {
    margin-top: 30px;
    align-items: center;
    text-align: center;
  }

  p {
    color: #565757;
    font-size: 15px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const AvatarContainer = styled.div`
  width: 60%;
  margin-top: 87px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
`;

export const TimeBox = styled.div`
  align-items: center;
  text-align: center;
  margin-right: 15px;

  span {
    display: inline;
    color: #565757;
    font-size: 13px;
    text-align: center;
    text-overflow: ellipsis;
    margin-left: 15px;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
`;

export const ConfirmCancel = styled.button`
  width: 240px;
  height: 38px;
  margin-top: 11px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 6px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;
