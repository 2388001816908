import { compareAsc } from 'date-fns';

/**
 * Compare the two dates and return 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.
 * @param date1 first Date.
 * @param date2 second Date.
 * @return boolean
 */
export const validDate = (date1: Date, date2: Date): boolean => {
  return (
    compareAsc(
      new Date(date1.getFullYear(), date1.getMonth(), date1.getDate()),
      new Date(date2.getFullYear(), date2.getMonth(), date2.getDate()),
    ) === 0
  );
};
