import styled, { css } from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px;
  margin-left: 100px;
  font-size: 18px;
`;

export const Infos = styled.div`
  width: 100%;
  gap: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PhotoView = styled.div`
  gap: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const LinkProfile = styled.a`
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  color: ${props => props.theme.primary};

  :hover {
    color: ${props => props.theme.primaryDark};
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  gap: 15px;
`;

const Variant = css`
  font-size: 14px;
  text-align: left;
  display: flex;
  gap: 5px;
  justify-content: left;
  align-items: flex-start;
  color: ${props => props.theme.typographyDarkGray};
  .info {
    font-weight: bold;
    display: block;
    color: black;
  }
`;

export const Name = styled.div`
  ${Variant}
`;

export const Address = styled.div`
  ${Variant}
`;

export const Contact = styled.div`
  ${Variant}
`;

export const Email = styled.div`
  ${Variant}
`;

export const Age = styled.div`
  ${Variant}
`;
