/* eslint-disable react/react-in-jsx-scope */
import { format } from 'date-fns';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { ListOnCall } from '~/domain/usecases/duty/remote';
import { makeRemoteListOnCall } from '~/main/factories/usecases/duty/ListOnCall';
import { makeReduxListShifts } from '~/main/factories/usecases/shifts';
import Translator from '~/presentation/components/i18n/Translator';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

import { getLocale } from '~/utils/getLocale';

type Labels = {
  label: string;
  subtitle: string;
};

type WeekInterval = {
  start: Date;
  end: Date;
};

type ShiftsContextData = {
  labels: Labels[];
  dayActive: number;
  weekLabel: string;
  onCalls: ListOnCall.Model['records'] | [];
  setDayActive: React.Dispatch<React.SetStateAction<number>>;
  goToNextWeek: () => void;
  goToPreviousWeek: () => void;
  getWeekShiftsByDate: (date?: Date) => void;
};

const daysOfWeek: Record<number, string> = {
  0: 'Domingo',
  1: 'Segunda',
  2: 'Terça',
  3: 'Quarta',
  4: 'Quinta',
  5: 'Sexta',
  6: 'Sábado',
};

const ShiftsContext = createContext<ShiftsContextData>({} as ShiftsContextData);

export const ShiftsProvider: React.FC = ({ children }) => {
  const [labels, setLabels] = useState<Labels[]>([]);
  const [dayActive, setDayActive] = useState<number>(0);

  const [onCalls, setOnCalls] = useState<ListOnCall.Model['records'] | []>([]);

  const [weekInterval, setWeekInterval] = useState<WeekInterval>(() => {
    const start = new Date();
    const actualDay = start.getDay();
    const firstDayOfWeek = 1; // Segunda-feira

    const diffInDay = actualDay - firstDayOfWeek;
    start.setDate(start.getDate() - diffInDay);
    const end = new Date();
    end.setDate(start.getDate() + 6);

    return {
      start,
      end,
    };
  });

  const { onCallId } = useSelector((store: iStore) => store.shifts);

  const weekLabel = useMemo(() => {
    const localeConfig: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    const isTheSameMonth =
      weekInterval.start.getMonth() === weekInterval.end.getMonth();

    const start = isTheSameMonth
      ? weekInterval.start.getDate()
      : weekInterval.start.toLocaleString(getLocale(), localeConfig);

    const end = weekInterval.end.toLocaleString(getLocale(), localeConfig);

    return `${start} ${Translator('a')} ${end}`;
  }, [weekInterval]);

  const createLabels = useCallback(() => {
    const actualDate = new Date(weekInterval.start);
    const actualDay = actualDate.getDay();
    const firstDayOfWeek = 1; // Segunda-feira

    const diffInDay = actualDay - firstDayOfWeek;
    actualDate.setDate(actualDate.getDate() - diffInDay);

    setLabels(
      Array.from({ length: 7 }, (_, i) => {
        const date = new Date(actualDate);
        date.setDate(date.getDate() + i);
        return {
          label: daysOfWeek[date.getDay()],
          subtitle: date.toLocaleDateString(getLocale()),
        };
      }),
    );
  }, [weekInterval]);

  const goToPreviousWeek = () => {
    setWeekInterval(prevState => {
      const startOfPreviousWeek = new Date(prevState.start);
      startOfPreviousWeek.setDate(startOfPreviousWeek.getDate() - 7);

      const endOfPreviousWeek = new Date(startOfPreviousWeek);
      endOfPreviousWeek.setDate(endOfPreviousWeek.getDate() + 6);

      return {
        start: startOfPreviousWeek,
        end: endOfPreviousWeek,
      };
    });
  };

  const goToNextWeek = () => {
    setWeekInterval(prevState => {
      const startOfNextWeek = new Date(prevState.end);
      startOfNextWeek.setDate(startOfNextWeek.getDate() + 1);

      const endOfNextWeek = new Date(startOfNextWeek);
      endOfNextWeek.setDate(endOfNextWeek.getDate() + 6);

      return {
        start: startOfNextWeek,
        end: endOfNextWeek,
      };
    });
  };

  const getWeekShiftsByDate = useCallback(
    (date: Date = weekInterval.start) => {
      const shifts = makeReduxListShifts();

      const dateSelected = new Date(weekInterval.start);
      dateSelected.setDate(dateSelected.getDate() + dayActive);

      const updatePayloadControl = makeReduxListShifts();

      try {
        const dateFormatted = format(dateSelected, 'yyyy-MM-dd');

        shifts.list({
          onCallId,
          body: {
            dataControl: {
              limit: 999,
              offset: 0,
              paging: false,
            },

            date: dateFormatted,
          },
        });
        updatePayloadControl.setDate(dateFormatted);
      } catch {
        AlertMessage({
          message: 'Não foi possível carregar os dados das escalas.',
          type: 'danger',
        });
      }
    },
    [dayActive, onCallId, weekInterval.start],
  );

  const getOnCall = useCallback(async () => {
    const onCallSpecialty = makeRemoteListOnCall();

    try {
      const response = await onCallSpecialty.get({});
      setOnCalls(response.records);
      makeReduxListShifts().setOnCallId(response.records[0].id);
    } catch {
      AlertMessage({
        message: 'Não foi possível carregar os dados das escalas.',
        type: 'danger',
      });
    }
  }, []);

  useEffect(() => {
    getOnCall();
  }, [getOnCall]);

  useEffect(() => {
    getWeekShiftsByDate();
  }, [getWeekShiftsByDate]);

  useEffect(() => {
    createLabels();
  }, [createLabels, weekInterval]);

  useEffect(() => {
    setDayActive(0);
  }, [weekInterval]);

  return (
    <ShiftsContext.Provider
      value={{
        labels,
        dayActive,
        weekLabel,
        onCalls,
        setDayActive,
        goToNextWeek,
        goToPreviousWeek,
        getWeekShiftsByDate,
      }}
    >
      {children}
    </ShiftsContext.Provider>
  );
};

export const useShifts = (): ShiftsContextData => {
  const context = useContext(ShiftsContext);

  if (!context) {
    throw new Error('useShifts must be used within an ShiftsProvider');
  }

  return context;
};
