import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  background: #fbfbfb;
  border: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 50px;
  color: #656a6e;

  svg {
    margin-right: 10px;
  }
`;

export const Body = styled.div`
  position: relative;
  padding-top: 20px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  #notice {
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px;
  }

  #text {
    max-width: 420px;
    text-align: center;
    margin: 30px 0px;
  }

  #action {
    max-width: 200px;
  }
`;

export const DeleAccountActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-direction: column;
`;

export const CheckDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;
