import {
  iActionLogin,
  iActionLoginSuccess,
  iActionLoginFailed,
  AuthTypes,
} from '~/domain/interfaces/redux/auth/Login';

import { Login } from '~/domain/usecases/auth/redux';

export const login = (payload: Login.Params): iActionLogin => ({
  type: AuthTypes.LOGIN,
  payload,
});

export const loginSuccess = (
  payload: Login.ModelToRedux,
): iActionLoginSuccess => ({
  type: AuthTypes.LOGIN_SUCCESS,
  payload,
});

export const loginFailed = (): iActionLoginFailed => ({
  type: AuthTypes.LOGIN_FAILED,
});
