import { Dispatch } from 'redux';
import store from '~/data/store';
import { listModalitiesRequest } from '~/data/store/reducer/diagnosisReport/actions/listModalities';

import { iActionListModalities } from '~/domain/interfaces/redux/diagnosisReport/listModalities';

import { ListModalitiesDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

export class ReduxListModalitiesDiagnosisReport
  implements ListModalitiesDiagnosisReport
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  listModalities = (
    params: ListModalitiesDiagnosisReport.Params,
  ): iActionListModalities => this.send(listModalitiesRequest(params));
}
