import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import {
  CreateSupportDocs,
  GetAllSupportDocs,
} from '~/domain/usecases/supportDoc/remote';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { iStore } from '~/domain/interfaces/models';
import { DocsEmpty } from '~/presentation/components/componentEmpty';
import DocumentsList from './DocumentsList';
import { iGuestData } from '~/domain/interfaces/models/GuestData';

import {
  Container,
  List,
  StyleInput,
  ContainerAdd,
} from './styles/StyledSupportingDocs';
import { makeRemoteGetAllSupportDocs } from '~/main/factories/usecases/supportDoc/GetAllSupportDocsFactory';
import { makeRemoteCreateSupportDocs } from '~/main/factories/usecases/supportDoc/CreateSupportDocsFactory';
import { V4hSpin } from '../../spin';
import { makeRemoteGetAllSupportDocsByCode } from '~/main/factories/usecases/supportDoc/GetAllSupportDocsByCodeFactory';
import { AlertMessage } from '../../messages/AlertMessage';

interface ownProps {
  isSigned?: string;
  name?: string;
}

export interface ParamsState {
  appointmentId: string;
  guestData: iGuestData;
}

const SupportingDocs: React.FC<ownProps> = ({ isSigned, name }) => {
  let userID = useSelector((store: iStore) => store.auth.info.user?.id);
  const message = translator(
    'Você não tem nenhum documento associado ao atendimento.',
  );
  const errorUploadingDoc = translator(
    'Erro ao enviar documento, tente novamente mais tarde.',
  );
  const [docsup, setDocs] = useState<GetAllSupportDocs.Model>();
  const [loading, setLoading] = useState<boolean>(false);

  const { appointmentId, guestData } = useLocation<ParamsState>().state;

  const getAllDocs = useCallback(() => {
    const code = guestData?.guest?.identification?.code;

    if (code) {
      makeRemoteGetAllSupportDocsByCode()
        .getAll({
          code,
          pageSize: 9999,
        })
        .then((response: GetAllSupportDocs.Model) => {
          setDocs(response);
        })
        .catch(er => {
          console.error(er);
          AlertMessage({
            message,
            type: 'info',
          });
        });

      return;
    }

    makeRemoteGetAllSupportDocs()
      .getAll({
        appointment: appointmentId
          ? Number(appointmentId)
          : guestData?.appointment?.id,
      })
      .then((response: GetAllSupportDocs.Model) => {
        setDocs(response);
      })
      .catch(er => {
        console.error(er);
        AlertMessage({
          message,
          type: 'info',
        });
      });
  }, [appointmentId, guestData, message]);

  useEffect(() => {
    getAllDocs();

    const interval = setInterval(() => {
      getAllDocs();
    }, 1000 * 15);

    return () => clearInterval(interval);
  }, [getAllDocs]);

  const onChange = (data: any) => {
    const formData = new FormData();
    formData.append('filename', data.target.files[0]);
    if (userID) formData.append('sender', String(userID ?? ''));
    formData.append(
      'appointment',
      String(appointmentId ?? guestData?.appointment?.id),
    );
    formData.append('type', '1'); // TODO: Dados mockados alterar eventualmente

    setLoading(true);

    makeRemoteCreateSupportDocs()
      .create({ data: formData })
      .then((response: CreateSupportDocs.Model) => {
        getAllDocs();
      })
      .catch(er => {
        AlertMessage({
          message: errorUploadingDoc,
          type: 'danger',
        });
        console.error(er);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Container>
      <div className="docs">
        <div className="add">
          <ContainerAdd disabled={loading}>
            <StyleInput disabled={loading}>
              {loading ? (
                <V4hSpin fullScreen={false} size="24px" />
              ) : (
                <>
                  {translator('Adicionar')}
                  <input
                    type="file"
                    width="400vw"
                    id="upload"
                    name="upload"
                    accept=".pdf, .jpg, .jpeg, .png, .bmp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .odt, .odp, .ods, .mp4, .exe, .dvg"
                    onChange={onChange}
                    style={{ display: 'none' }}
                  />
                </>
              )}
            </StyleInput>
          </ContainerAdd>
          <div className="info">
            {translator(
              'Adicione imagens, áudios, vídeos, ou documentos. Tamanho máximo: 500 MB.',
            )}
          </div>
        </div>
        <List>
          <div className="title">
            {translator('Lista de documentos de apoio do atendimento')}
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: '20px',
            }}
          >
            {Boolean(docsup?.results.length) && (
              <DocumentsList type="supporting-documents" docsSup={docsup} />
            )}
          </div>
        </List>
      </div>
    </Container>
  );
};

export default SupportingDocs;
