import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 16px;

  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #dedede;
  border-radius: 4px;
`;

export const Info = styled.div`
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

export const Action = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #b4b7b9;
  border-radius: 6px;
`;
