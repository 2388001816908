import {
  OrganizerAppointmentReport,
  OrganizerDownloadAppointmentReport,
} from '~/domain/usecases/organizerList/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
  Forbidden,
} from '~/domain/errors';

export class RemoteOrganizerAppointmentReportPdfOrg
  implements OrganizerDownloadAppointmentReport
{
  private readonly url: string;

  private readonly httpClient: HttpClient<OrganizerAppointmentReport.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<OrganizerAppointmentReport.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  download = async (
    params: OrganizerAppointmentReport.Params,
  ): Promise<OrganizerAppointmentReport.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      responseType: 'blob',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.serverError:
        throw new UnexpectedError();
      default:
        throw new UnexpectedError();
    }
  };
}
