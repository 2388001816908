import {
  MessageTypes,
  iActionSetData,
} from '~/domain/interfaces/redux/message/setData';

import { SetData } from '~/domain/usecases/message/redux';

export const setData = (payload: SetData.Params): iActionSetData => ({
  type: MessageTypes.SET_DATA,
  payload,
});
