import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateSupportDocs as UseCaseRemoteCreateSupportDocs } from '~/domain/usecases/supportDoc/remote';
import { CreateSupportDocs as UseCaseReduxCreateSupportDocs } from '~/domain/usecases/supportDoc/redux';
import { RemoteCreateSupportDocs } from '~/data/repository/supportDoc';
import { ReduxCreateSupportDoc } from '~/data/store/reducer/supportDoc/usecases/ReduxCreateSupportDocs';

export const makeRemoteCreateSupportDocs = (): UseCaseRemoteCreateSupportDocs =>
  new RemoteCreateSupportDocs(makeApiUrl('/supportDocs'), makeHttpClient());

export const makeReduxCreateSupportDocs = (): UseCaseReduxCreateSupportDocs =>
  new ReduxCreateSupportDoc();
