import { GetOneUser } from '~/domain/usecases/users/redux';
import {
  iActionGetOne,
  iActionGetOneFailed,
  iActionGetOneSuccess,
  UsersTypes,
} from '~/domain/interfaces/redux/users/getOne';

export const getOneRequest = (payload: GetOneUser.Params): iActionGetOne => ({
  type: UsersTypes.GETONE,
  payload,
});

export const getOneSuccess = (
  params: GetOneUser.Model,
): iActionGetOneSuccess => ({
  type: UsersTypes.GETONE_SUCCESS,
  payload: params,
});

export const getOneFailed = (): iActionGetOneFailed => ({
  type: UsersTypes.GETONE_FAILED,
});
