import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const Header = styled.div`
  background: #fbfbfb;
  border: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 50px;
  color: #656a6e;

  svg {
    margin-right: 10px;
  }
`;

export const Body = styled.div`
  position: relative;
  padding-top: 20px;
  font-size: 16px;

  #select {
    margin: 20px 0px;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 21px;
  font-weight: bold;
`;
export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  #action {
    width: 200px;
  }
`;
