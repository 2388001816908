import { theme } from '@wisecare/design-system-web';
import styled from 'styled-components';

export const ChipsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  padding-bottom: 1.5rem;
`;

export const ScrollChipsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto;

  width: 100%;

  scroll-snap-align: center end;
  scroll-snap-type: x mandatory;

  scroll-behavior: smooth;

  gap: 0.5rem;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const SectionInfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  gap: 1rem;
`;

export const InfoCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 1rem;
`;

export const DataContainer = styled.div<{ spacingY?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  & > :last-of-type {
    margin-top: ${({ spacingY }) => spacingY ?? '0.5rem'};
  }
`;

export const StatusInfoContainer = styled.div``;

export const Data = styled.span`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.2rem;

  & > :first-of-type {
    color: ${theme.colors.black[4]};
  }
`;

export const PatientInfoContainer = styled.div``;

export const CheckboxRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const FilesContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
`;

export const FileCardContainer = styled.div``;

export const FileCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

type TagStatusProps = {
  status: 'pending' | 'resolved';
};

export const TagStatus = styled.div<TagStatusProps>`
  display: flex;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ status }) =>
    status === 'pending' ? theme.colors.red[1] : theme.colors.green[1]};
  color: ${({ status }) =>
    status === 'pending' ? theme.colors.red[7] : theme.colors.green[7]};
`;
