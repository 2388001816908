import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { TableCell } from '@material-ui/core';

interface ownProps {
  columns: any[];
}

const ListSkeleton: React.FC<ownProps> = ({ columns }) => {
  return (
    <>
      {columns.map(item => {
        return (
          <TableCell align="center">
            <Skeleton animation="wave" variant="text" width="120px" />
          </TableCell>
        );
      })}
    </>
  );
};

export default ListSkeleton;
