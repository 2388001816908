import React from 'react';
import { IconAppointmentEmpty } from '~/presentation/base/icons';

import { Container, Icon } from './styles/Styled';

interface ownProps {
  message?: string;
}

const AppointmentEmpty: React.FC<ownProps> = ({ message }) => {
  return (
    <Container>
      <Icon>
        <IconAppointmentEmpty />
      </Icon>
      <text>{message}</text>
    </Container>
  );
};
export default AppointmentEmpty;
