import styled from 'styled-components';

interface Avatar {
  variant?: 'circle' | 'rounded' | 'square';
  size?: 'small' | 'medium' | 'big' | string;
}

export const Container = styled.div<Avatar>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: ${props => {
    switch (props.size) {
      case 'small':
        return '40px';
      case 'medium':
        return '100px';
      case 'big':
        return '200px';
      default:
        return '50px';
    }
  }};
  height: ${props => {
    switch (props.size) {
      case 'small':
        return '40px';
      case 'medium':
        return '100px';
      case 'big':
        return '200px';
      default:
        return '50px';
    }
  }};
  border-radius: ${props => {
    switch (props.variant) {
      case 'circle':
        return '100%';
      case 'rounded':
        return '5px';
      case 'square':
        return 'none';
      default:
        return '100%';
    }
  }};
  font-size: ${props => {
    switch (props.size) {
      case 'small':
        return '32px';
      case 'medium':
        return '40px';
      case 'big':
        return '60px';
      default:
        return '32px';
    }
  }};
  background: ${props => props.theme.typographyLightGray};
`;

export const AvatarIcon = styled.img<Avatar>`
  width: ${props => {
    switch (props.size) {
      case 'small':
        return '40px';
      case 'medium':
        return '100px';
      case 'big':
        return '200px';
      default:
        return '40px';
    }
  }};
  height: ${props => {
    switch (props.size) {
      case 'small':
        return '40px';
      case 'medium':
        return '100px';
      case 'big':
        return '200px';
      default:
        return '40px';
    }
  }};
  border-radius: ${props => {
    switch (props.variant) {
      case 'circle':
        return '100%';
      case 'rounded':
        return '5px';
      case 'square':
        return 'none';
      default:
        return '100%';
    }
  }};
  object-fit: cover;
`;
