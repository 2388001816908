import { call, put, select } from 'redux-saga/effects';
import { iActionList } from '~/domain/interfaces/redux/appointment/list';
import { ListAppointment } from '~/domain/usecases/appointment/remote/ListAppointment';
import { makeRemoteListAppointment } from '~/main/factories/usecases/appointment/ListAppointmentFactory';
import {
  listSuccess,
  listFailed,
} from '~/data/store/reducer/appointment/actions/list';

export function* onList(action: iActionList) {
  const remoteListAppointment = makeRemoteListAppointment();

  try {
    const response: ListAppointment.Model = yield call(
      remoteListAppointment.list,
      action.payload,
    );
    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
