import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { UpdateAppointment as UseCaseReduxUpdateAppointment } from '~/domain/usecases/appointment/redux';
import { UpdateAppointment as UseCaseRemoteUpdateAppointment } from '~/domain/usecases/appointment/remote';

import { ReduxUpdateAppointment } from '~/data/store/reducer/appointment/usecases';
import { RemoteUpdateAppointment } from '~/data/repository/appointment';

export const makeReduxUpdateAppointment = (): UseCaseReduxUpdateAppointment =>
  new ReduxUpdateAppointment();

export const makeRemoteUpdateAppointment = (): UseCaseRemoteUpdateAppointment =>
  new RemoteUpdateAppointment(makeApiUrl('/appointments'), makeHttpClient());
