import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #f4f7fe;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  div {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  padding-left: 20px;
  padding-right: 20px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  h1 {
    color: #000000;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    text-align: center;
    padding-left: 5px;
  }
  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;

    text-align: center;
    color: #7a7a7a;
  }
`;

export const Left = styled.div`
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding-left: 10px;
  h1 {
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    text-align: center;
  }
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 15px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Signed = styled.div`
  gap: 10px;
  p {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 150%;

    color: #004ba5;
  }
`;

export const SendPatient = styled.div`
  padding-right: 10px;
  p {
    color: #7a7a7a;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
  }
`;

export const Pdf = styled.div`
  width: 100%;
  height: 110vh;
  display: flex;
  justify-content: center;
`;
