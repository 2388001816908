import React from 'react';

import { Container, Header, CloseButton } from './styles';
import { Body, Content } from './styles/StyledUserAlreadyExists';

import { Button } from '../../UI';

import { ownProps } from '../interface';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { translator } from '../../i18n';

const UserEditDataError: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.userEditDataError;

  const { active, actionCancel, actionOk } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span style={{ color: '#EC5C52' }}>{translator('Atenção')}</span>
              <CloseButton onClick={actionCancel}>X</CloseButton>
            </Header>
            <Content>
              <span>
                {translator(
                  'Não foi possível atualizar especialidades, tente novamente',
                )}
              </span>
            </Content>
            <Button
              id="button_confirm"
              rounded
              variant="primary"
              size="large"
              onClick={actionOk}
              autoFocus
            >
              {translator('OK')}
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default UserEditDataError;
