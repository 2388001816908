import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetUfs as UsecaseRemoteGetUfs } from '~/domain/usecases/externalServices/remote';

import { RemoteGetUfs } from '~/data/repository/externalServices/RemoteGetUfs';

/**
 * send request via API.
 */
export const makeRemoteGetUfs = (): UsecaseRemoteGetUfs =>
  new RemoteGetUfs(makeApiUrl('/external/IBGE/states/'), makeHttpClient());
