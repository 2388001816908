/* eslint-disable react/react-in-jsx-scope */
import { createRef, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { makeRemoteAvailableOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AvailableOnCallSpecialistFactory';
import { makeRemotePausedOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/PausedOnCallSpecialistFactory';
import { IconCamBlue } from '~/presentation/base/icons';
import { DutyRequestDetailsCard } from '~/presentation/components/DutyRequestDetailsCard';
import { Button } from '~/presentation/components/UI';
import Switch from '~/presentation/components/UI/Switch';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import PatientDutyList from './Lists';
import { SwitchButtonLabel } from './Lists/styles';
import { ActionsContainer, Content, SwitchContainer, Wrapper } from './styles';

const DutyPage: React.FC = () => {
  const [selectedRequest, setSelectedRequest] = useState<
    iOnCallRequest['records'][0] | null
  >(null);

  const { records } = useSelector((store: iStore) => store.onCallRequest);
  const { available } = useSelector((store: iStore) => store.onCall);

  const { state } = useLocation<{
    specialty: string;
    id: string;
    leaveConf: boolean;
  }>();

  const switchRef = createRef<HTMLInputElement>();
  // const { records } = useSelector((store: iStore) => store.duty);

  const professional = getProfessionalInfo();

  const handleStatus = (status?: boolean) => {
    if (professional)
      if (!status)
        makeRemoteAvailableOnCallSpecialist()
          .available({
            onCallId: Number(state?.id),
            specialistId: professional?.id,
          })
          .then(() =>
            makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
              available: !status,
            }),
          );
      else
        makeRemotePausedOnCallSpecialist()
          .paused({
            onCallId: Number(state?.id),
            specialistId: professional?.id,
          })
          .then(() =>
            makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
              available: !status,
            }),
          )
          .catch(err => {
            console.log('Error pausing: ', err);
          });
  };

  const info = useMemo(() => {
    const requester = records?.find(
      requesterItem => requesterItem.id === selectedRequest?.id,
    );

    if (requester) {
      return requester.requester;
    }
    return undefined;
  }, [records, selectedRequest]);

  return (
    <Wrapper>
      <ActionsContainer>
        <SwitchContainer>
          <Switch
            id="switch__available"
            ref={switchRef}
            checked={available}
            onChange={() => {
              handleStatus(available);
            }}
          />
          <SwitchButtonLabel htmlFor="switch__available">
            {`${
              available ? 'Estou disponível' : 'Estou indisponível'
            } para receber solicitações`}
          </SwitchButtonLabel>
        </SwitchContainer>
        <Button
          variant="secundary"
          size="bigger"
          icon={IconCamBlue}
          style={{ width: '20%', height: 48 }}
          onClick={() => {
            makeReduxActiveMessage().active({
              active: 'instantConsultation',
              isEmergencyConsult: true,
            });
          }}
        >
          Criar interconsulta emergencial
        </Button>
      </ActionsContainer>
      <Content>
        <PatientDutyList
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          variant="REQUESTER"
        />
        <DutyRequestDetailsCard
          info={selectedRequest}
          selectedRequester={!!selectedRequest}
          variant="SPECIALIST"
          setSelectedRequest={setSelectedRequest}
        />
      </Content>
    </Wrapper>
  );
};

export default DutyPage;
