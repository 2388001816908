import React, { useMemo } from 'react';

import {
  TextNormal,
  ConsultInfo,
  TextBoldSmall,
  Loader,
} from './styles/styles';
import { translator } from '../i18n';

interface ConsultDetailFragmentProps {
  label?: string;
  content?: string;
  isLoading?: boolean;
}

const ConsultDetailFragment: React.FC<ConsultDetailFragmentProps> = ({
  label = '',
  content = '',
  isLoading = false,
}) => {
  return (
    <ConsultInfo>
      <TextNormal>
        <TextBoldSmall>{translator(`${label}: `)}</TextBoldSmall>
        {!isLoading && (content !== '' ? content : 'Sem dados')}
      </TextNormal>
      {isLoading && <Loader />}
    </ConsultInfo>
  );
};
export default ConsultDetailFragment;
