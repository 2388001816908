import { call, put } from 'redux-saga/effects';
import { iActionGetAllByCode } from '~/domain/interfaces/redux/clinicalDocs/getAllByCode';
import { GetAllClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/remote/GetAllClinicalDocsByCode';
import { makeRemoteGetAllClinicalDocsByCode } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsByCodeFactory';
import {
  getAllByCodeSuccess,
  getAllByCodeFailed,
} from '~/data/store/reducer/clinicalDocs/actions/getAllByCode';

export function* onGetAllByCode(action: iActionGetAllByCode) {
  const remoteGetAllClinicalDocsByCode = makeRemoteGetAllClinicalDocsByCode();

  try {
    const response: GetAllClinicalDocsByCode.Model = yield call(
      remoteGetAllClinicalDocsByCode.getAll,
      action.payload,
    );

    yield put(getAllByCodeSuccess(response));
  } catch (e) {
    yield put(getAllByCodeFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllByCodeSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllByCodeFailed() {}
