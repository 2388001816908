import { takeLatest } from 'redux-saga/effects';
import { onStopRequest, onStopSuccess, onStopFailed } from './Stop';
import { OnCallTypes } from '~/domain/interfaces/redux/onCall/types';

const tasks = [
  takeLatest(OnCallTypes.STOP_ONCALL, onStopRequest),
  takeLatest(OnCallTypes.STOP_ONCALL_SUCCESS, onStopSuccess),
  takeLatest(OnCallTypes.STOP_ONCALL_FAILED, onStopFailed),
];

export default tasks;
