import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { History } from '~/main/routes';
import {
  refreshSuccess,
  refreshFailed,
} from '~/data/store/reducer/auth/actions/refresh';

import { makeRemoteRefresh } from '~/main/factories/usecases/auth/RefreshFactory';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

import {
  iActionRefresh,
  iActionRefreshSuccess,
} from '~/domain/interfaces/redux/auth/Refresh';
import { UserInfoDTO } from '~/domain/dtos/auth/UserInfo';

import { Refresh } from '~/domain/usecases/auth/redux';
import { ReduxLogout } from '../../reducer/auth/usecases/ReduxLogout';
import { makeReduxSelectUser } from '~/main/factories/usecases/auth/SelectUserFactory';
import storeDev from '../..';
import { iStore } from '~/domain/interfaces/models';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onRefresh(action: iActionRefresh) {
  const user = makeRemoteRefresh();

  try {
    const responseRefresh: Refresh.Model = yield call(
      user.refresh,
      action.payload,
    );

    const mapToRedux: Refresh.ModelToRedux = {
      access: responseRefresh.access,
      refresh: responseRefresh.refresh,
      acl: responseRefresh.permissions,
      info: UserInfoDTO(responseRefresh.user),
      orgs: responseRefresh.orgs,
    };

    yield put(refreshSuccess(mapToRedux));
  } catch (e) {
    yield put(refreshFailed());
  }
}

export function onRefreshSuccess(action: iActionRefreshSuccess) {
  const { info } = action.payload;
  const store: iStore = storeDev.getState();

  const { roleId } = store.auth.selectUser;

  if (info) {
    const { consultants, professionals, organizers, administrators } = info;

    let role: 'CON' | 'PRO' | 'ORG' | 'ADM' = 'CON';

    if (consultants?.length) role = 'CON';
    if (professionals?.length) role = 'PRO';
    if (organizers?.length) role = 'ORG';
    if (administrators?.length) role = 'ADM';

    makeReduxSelectUser().update({
      role,
      roleId: roleId || 1,
    });
  }

  // History.push('/appointment');
}

export function onRefreshFailed() {
  AlertMessage({
    message: intl.formatMessage({
      id: 'Token expirado! Efetue o login novamente para trocar papéis.',
    }),
    type: 'danger',
  });

  const logout = new ReduxLogout();
  logout.logout();
  History.getHistory().push('/');
}
