import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Forbidden,
  NotFound,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

import { GetDetailsInstantAppointment } from '~/domain/usecases/appointment/remote';

export class RemoteGetDetailsInstantAppointment
  implements GetDetailsInstantAppointment
{
  private readonly url: string;

  private readonly httpClient: HttpClient<GetDetailsInstantAppointment.Model>;

  constructor(
    url: string,
    httClient: HttpClient<GetDetailsInstantAppointment.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  getDetailsInstantAppointment = async (
    params: GetDetailsInstantAppointment.Params,
  ): Promise<GetDetailsInstantAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}details/${params.code}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
