export enum UsersTypes {
  CREATE = '@users/CREATE',
  CREATE_SUCCESS = '@users/CREATE_SUCCESS',
  CREATE_FAILED = '@users/CREATE_FAILED',
  UPDATE = '@users/UPDATE',
  UPDATE_SUCCESS = '@users/UPDATE_SUCCESS',
  UPDATE_FAILED = '@users/UPDATE_FAILED',
  DELETE = '@users/DELETE',
  DELETE_SUCCESS = '@users/DELETE_SUCCESS',
  DELETE_FAILED = '@users/DELETE_FAILED',
  GETALL = '@users/GETALL',
  GETALL_SUCCESS = '@users/GETALL_SUCCESS',
  GETALL_FAILED = '@users/GETALL_FAILED',
  GETONE = '@users/GETONE',
  GETONE_SUCCESS = '@users/GETONE_SUCCESS',
  GETONE_FAILED = '@users/GETONE_FAILED',
  SET_DATA = '@users/SET_DATA',
  LEAVE_SPECIALTY = '@users/LEAVE_SPECIALTY',
  GET_EMAIL = '@users/GET_EMAIL',
  UPDATE_AVATAR = '@users/UPDATE_AVATAR',
  UPDATE_AVATAR_SUCCESS = '@users/UPDATE_AVATAR_SUCCESS',
  UPDATE_AVATAR_FAILED = '@users/UPDATE_AVATAR_FAILED',
}
