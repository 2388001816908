import { GuestAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGuestAppointment implements GuestAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<GuestAppointment.Model>;

  constructor(url: string, httClient: HttpClient<GuestAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  guest = async (
    params: GuestAppointment.Params,
  ): Promise<GuestAppointment.Model> => {
    let query = '';
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.code}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
