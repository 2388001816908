import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { makeReduxAddAppointmentData } from '~/main/factories/usecases/appointment/AddAppointmentDataFactory';

import {
  iListProfessional,
  iListSpeciality,
  iListConsultant,
  iProfessional,
} from '../interface';
import { ownProps } from '../generalData';
import { iConsultant, iStore } from '~/domain/interfaces/models';

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { consultant, professional, specialty, appointment } = useSelector(
      (state: iStore) => state,
    );

    Component.defaultProps = {
      consultant: _.orderBy(
        MapConsultant(consultant),
        item => item.firstName,
        'asc',
      ),
      specialty: specialty.results,
      professional: _.orderBy(
        MapProfessional(professional.results),
        item => item.name,
        'asc',
      ),
      appointment,
      handlerState: makeReduxAddAppointmentData(),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

export const MapConsultant = (consultant: iConsultant): iListConsultant[] => {
  const arr: iListConsultant[] = [];

  consultant.results.forEach(item => {
    arr.push({
      id: Number(item.consultant.id),
      userId: item.user.id,
      firstName: item.user.firstName,
      lastName: item.user.lastName,
      email: item.user.email,
      phone: item.user.phone,
    });
  });

  return arr;
};

export const MapSpecialty = (
  specialty: iListSpeciality[],
): iListSpeciality[] => {
  const arr: iListSpeciality[] = [];

  specialty.forEach(item => {
    arr.push({
      specialty: item.specialty,
      profession: item.profession,
    });
  });

  return arr;
};

export const MapProfessional = (
  professional: iListProfessional[],
): iProfessional[] => {
  const arr: iProfessional[] = [];

  professional.forEach(item => {
    arr.push({
      name: `${item.user.firstName} ${item.user.lastName}`,
      id: Number(item.professional),
      specialty: item.specialties[0].name,
      email: item.user.email,
      phone: item.user.phone,
      type: 'Profissional interno',
    });
  });

  return arr;
};
