import { SetupProfession } from '~/domain/usecases/profession/redux';
import {
  iActionSetup,
  ProfessionTypes,
} from '~/domain/interfaces/redux/profession/setupProfession';

export const setupProfession = (
  payload: SetupProfession.Params,
): iActionSetup => ({
  type: ProfessionTypes.SETUP_PROFESSION,
  payload,
});
