import React from 'react';
import styled, { CSSObject, css } from 'styled-components';

interface ItemProps {
  active?: boolean;
}

export const Content = styled.div<{
  style?: React.CSSProperties & { itemWidth?: string };
}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  ${({ style }) => style && css(style as CSSObject)}
  ${({ style }) =>
    style &&
    style.itemWidth &&
    css`
      & > div {
        width: ${style.itemWidth};
      }
    `};
`;

export const Item = styled.div<ItemProps>`
  border: none;
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border-bottom: 3px solid transparent;
  ${props =>
    props.active &&
    css`
      border-color: ${props.theme.primary};
    `}

  cursor: pointer;

  transition: border 0.2s ease;

  :hover {
    background: rgba(0, 0, 0, 0.06);
  }
`;

export const Label = styled.div`
  color: ${props => props.theme.primary};
  font-size: 16px;
  font-weight: 700;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 1rem;
`;
