import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/specialty/getAll';

import { UpdateSpecialty } from '~/domain/usecases/specialty/remote';

import {
  updateSuccess,
  updateFailed,
} from '~/data/store/reducer/specialty/actions/update';
import { makeRemoteUpdateSpecialty } from '~/main/factories/usecases/specialty/UpdateSpecialtyFactory';
import { iActionUpdate } from '~/domain/interfaces/redux/specialty/update';

export function* onUpdateSpecialty(action: iActionUpdate) {
  const remoteUpdateSpecialty = makeRemoteUpdateSpecialty();

  try {
    const response: UpdateSpecialty.Model = yield call(
      remoteUpdateSpecialty.update,
      action.payload,
    );

    yield put(updateSuccess());
  } catch (e) {
    yield put(updateFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateFailed(e: any) {}
