import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllConsultant as UsecaseRemoteGetAllConsultant } from '~/domain/usecases/consultant/remote';
import { GetAllConsultant as UsecaseReduxGetAllConsultant } from '~/domain/usecases/consultant/redux/GetAllConsultant';

import { RemoteGetAllConsultant } from '~/data/repository/consultant/RemoteGetAllConsultant';
import { ReduxGetAllConsultant } from '~/data/store/reducer/Consultant/usecases/ReduxGetAllConsultant';

/**
 * send request via API.
 */
export const makeRemoteGetAllConsultant = (): UsecaseRemoteGetAllConsultant =>
  new RemoteGetAllConsultant(makeApiUrl('/consultants/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllConsultant = (): UsecaseReduxGetAllConsultant =>
  new ReduxGetAllConsultant();
