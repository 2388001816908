/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import {
  Container,
  Body,
  ContentHeader,
  ContentBody,
  ContentCards,
  CardLeft,
  CardRight,
  HeaderRight,
  SelectContainer,
  StorageContainer,
  StorageTitle,
  StorageInfo,
  Legend,
  StorageDetails,
  Divider,
  RefreshContainer,
} from './styles/StyledOrgUnitDetail';

import { Menu } from '~/presentation/components/menu';
import { PageHeader } from '~/presentation/components/header';
import { HeaderOrgUnit } from '~/presentation/components/headerOrgUnit';
import { TesteLogo, CloudUpload, RefreshIcon } from '~/presentation/base/icons';
import {
  OrgUnitDetail,
  OrgUnitGrid,
  OrgUnitProfessional,
} from '~/presentation/components/OrgUnitDetail';
import { iDashboardUnits, iStore } from '~/domain/interfaces/models';
import { makeReduxGetDashboardUnits } from '~/main/factories/usecases/dashboard/GetDashboardUnitsFactory';
import { Button, Select } from '~/presentation/components/UI';
import { History } from '~/main/routes';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeRemoteGetDashboardUnit } from '~/main/factories/usecases/dashboard/GetDashboardUnitFactory';
import { GetDashboardUnit } from '~/domain/usecases/dashboard/remote';
import { translator } from '~/presentation/components/i18n';

interface iStateParams {
  orgUnitId: number;
  orgUnitName: string;
  orgName: string;
}

interface iFilter {
  begin?: string;
  end?: string;
}

const DEFAULT = -1;
const TODAY = 0;
const LAST_WEEK = 1;
const LAST_MONTH = 2;
const LAST_YEAR = 3;

const OrgUnitDetailFactory: React.FC = () => {
  const stateParams = useLocation<iStateParams>().state;

  const [refresh, setRefresh] = useState(false);
  const [filter, setFilter] = useState<iFilter>({} as iFilter);
  const [dashboardUnit, setDashboardUnit] = useState<GetDashboardUnit.Model>({
    appointments: {
      canceled: 0,
      ended: 0,
      scheduled: 0,
      total: 0,
      canceledDiff: 0,
      endedDiff: 0,
      scheduledDiff: 0,
      totalDiff: 0,
    },
    storage: {
      available: 0,
      used: 0,
      usedToPrescription: 0,
      usedToVideo: 0,
    },
    unit: { id: 0, descr: '', logo: '', name: '', fullname: '' },
    users: {
      professionals: 0,
    },
    begin: '',
    end: '',
    prescriptions: { diff: 0, total: 0 },
    recordings: { diff: 0, total: 0 },
  });

  const { orgId } = useSelector((state: iStore) => state.auth.selectUser);

  if (!stateParams?.orgUnitId) {
    History.getHistory().push('/administrator/sectors');
  }

  const data = {
    options: {
      colors: ['#004BA5'],
      plotOptions: {
        radialBar: {
          hollow: {
            size: '50%',
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: 16,
              fontWeight: '700',
              fontSize: '14px',
            },
            value: {
              offsetY: -16,
              color: '#004BA5',
              fontWeight: 'normal',
            },
          },
        },
      },
      labels: ['Usado'],
    },
    series: [dashboardUnit?.storage?.used],
  };

  useEffect(() => {
    if (!orgId) return;

    makeRemoteGetDashboardUnit()
      .get({
        org: orgId,
        unit: stateParams?.orgUnitId,
        begin: filter.begin,
        end: filter.end,
      })
      .then(res => {
        setDashboardUnit(res);
      })
      .catch(err => {
        console.log('Get Dashboard Unit err: ', err);
      });
  }, [orgId, stateParams?.orgUnitId, filter.begin, filter.end, refresh]);

  const handleListProfessionalClick = () => {
    History.getHistory().push('/professional', {
      unit: stateParams?.orgUnitId,
    });
  };

  const handleFilter = (e: string) => {
    if (!Number.isNaN(e)) {
      const today = new Date();
      const todayMidnight = new Date();
      todayMidnight.setHours(0, 0, 0, 0);

      switch (Number(e)) {
        case DEFAULT:
          setFilter({ begin: undefined, end: undefined });
          break;
        case TODAY:
          setFilter({
            begin: todayMidnight.toISOString(),
            end: today.toISOString(),
          });
          break;
        case LAST_WEEK:
          const lastWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

          setFilter({
            begin: lastWeek.toISOString(),
            end: today.toISOString(),
          });
          break;
        case LAST_MONTH:
          const lastMonth = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

          setFilter({
            begin: lastMonth.toISOString(),
            end: today.toISOString(),
          });
          break;
        case LAST_YEAR:
          const lastYear = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);

          setFilter({
            begin: lastYear.toISOString(),
            end: today.toISOString(),
          });
          break;
        default:
          break;
      }
    }
  };

  return (
    <Container>
      <Menu />
      {/* to do translation */}
      <PageHeader
        title={`Informações sobre o setor de ${dashboardUnit?.unit?.name} do ${stateParams?.orgName}`}
        backDescription={translator('Voltar para a listagem de setores')}
      />
      <ContentBody>
        <Body>
          <ContentHeader>
            <HeaderOrgUnit
              Icon={dashboardUnit?.unit?.logo}
              sector={dashboardUnit?.unit?.name}
              org={stateParams?.orgName}
              orgUnitId={dashboardUnit?.unit?.id}
              orgUnitName={dashboardUnit?.unit?.name}
            />
          </ContentHeader>
          <ContentCards>
            <CardLeft>
              <StorageContainer>
                <StorageTitle>
                  <CloudUpload />
                  <StorageInfo>
                    <div className="title">Armazenamento</div>
                    <div className="value">
                      Total:
                      <span>{`${dashboardUnit?.storage?.available} GB`}</span>
                    </div>
                  </StorageInfo>
                </StorageTitle>
                <Chart
                  options={data.options}
                  series={data.series}
                  type="radialBar"
                  width="250"
                />
                <Legend>
                  <div className="inUse">
                    <div className="color" />
                    <div className="text">Em uso</div>
                    {`${dashboardUnit?.storage?.used} GB`}
                  </div>
                  <div className="free">
                    <div className="color" />
                    <div className="text">Livre</div>
                    {`${dashboardUnit?.storage?.available} GB`}
                  </div>
                </Legend>
                <StorageDetails>
                  <div className="storageType">
                    Documentos e Prescrições
                    <div className="value">
                      {`${dashboardUnit?.storage?.usedToPrescription} GB`}
                    </div>
                  </div>
                  <div className="storageType">
                    <div className="title">Gravações</div>
                    <div className="value">
                      {`${dashboardUnit?.storage?.usedToVideo} GB`}
                    </div>
                  </div>
                </StorageDetails>
              </StorageContainer>
              <Divider />
              <OrgUnitProfessional
                value={dashboardUnit?.users?.professionals}
                title="Total de profissionais"
                subtitle="Visualizar profissionais"
                onClick={handleListProfessionalClick}
              />
            </CardLeft>
            <CardRight>
              <HeaderRight>
                <SelectContainer>
                  <Select
                    id="select_period"
                    name="period"
                    label="Período"
                    onChange={e => handleFilter(e.target.value)}
                  >
                    <option id="default" value={DEFAULT}>
                      Qualquer período
                    </option>
                    <option id="today" value={TODAY}>
                      Hoje
                    </option>
                    <option id="last_week" value={LAST_WEEK}>
                      Última semana
                    </option>
                    <option id="last_month" value={LAST_MONTH}>
                      Último mês
                    </option>
                    <option id="last_year" value={LAST_YEAR}>
                      Último ano
                    </option>
                  </Select>
                </SelectContainer>
                <RefreshContainer>
                  <Button
                    id="button-secundary"
                    icon={RefreshIcon}
                    size="small"
                    variant="secundary"
                    rounded
                    onClick={() => setRefresh(state => !state)}
                  >
                    Atualizar
                  </Button>
                </RefreshContainer>
              </HeaderRight>
              <OrgUnitGrid columns={3}>
                <OrgUnitDetail
                  title="Total de atendimentos"
                  value={dashboardUnit?.appointments?.total}
                  percentValue={dashboardUnit?.appointments?.totalDiff}
                />
                <OrgUnitDetail
                  title="Agendados"
                  value={dashboardUnit?.appointments?.scheduled}
                  percentValue={dashboardUnit?.appointments?.scheduledDiff}
                />
                <OrgUnitDetail
                  title="Finalizados"
                  value={dashboardUnit?.appointments?.ended}
                  percentValue={dashboardUnit?.appointments?.endedDiff}
                />
                <OrgUnitDetail
                  title="Cancelados"
                  value={dashboardUnit?.appointments?.canceled}
                  percentValue={dashboardUnit?.appointments?.canceledDiff}
                />
                <OrgUnitDetail
                  title="Documentos e Prescrições"
                  value={dashboardUnit?.storage?.usedToPrescription}
                  percentValue={2.15}
                />
                <OrgUnitDetail
                  title="Gravações"
                  value={dashboardUnit?.storage?.usedToVideo}
                  percentValue={2.15}
                />
              </OrgUnitGrid>
            </CardRight>
          </ContentCards>
        </Body>
      </ContentBody>
    </Container>
  );
};

export default OrgUnitDetailFactory;
