import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { OrganizerDownloadAppointmentReport as UsecaseRemoteOrganizerDownloadAppointmentReport } from '~/domain/usecases/organizerList/remote';

import { RemoteOrganizerAppointmentReportPdfOrg } from '~/data/repository/organizer';

/**
 * send request via API.
 */
export const makeRemoteDownloadAppointmentReportPdf =
  (): UsecaseRemoteOrganizerDownloadAppointmentReport =>
    new RemoteOrganizerAppointmentReportPdfOrg(
      makeApiUrl('/reports/appointments/history/pdf'),
      makeHttpClient(),
    );
