import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.h3`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin-bottom: 32px;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 40px;
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 42px;
  align-items: center;
  justify-content: center;
  margin-bottom: 43px;
`;

export const OrgUnitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 226px;
  height: 247px;
  background-color: #fbfbfb;
  border-radius: 5px;
  padding: 8px 0;
`;

export const OrgUnitHeader = styled.div`
  width: 100%;
  height: 15%;
  padding: 0 15px 8px 15px;
  border-bottom: 1px solid #efefef;
`;

export const OrgUnitHeaderText = styled.text`
  font-size: 14px;
  color: #7a7a7a;
  margin: 0;
`;

export const OrgUnitList = styled.div`
  width: 100%;
  height: 70%;
  padding: 8px 0;
  overflow-y: auto;
`;

export const OrgUnitItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-bottom: 1px solid #efefef;
`;

export const OrgUnitItemText = styled.text`
  font-size: 14px;
  padding: 8px 0 8px 15px;
  margin: 0;
  text-transform: capitalize;
`;

export const OrgUnitFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 15%;
  padding: 9px 25px;
`;

export const OrgUnitFooterText = styled.text`
  font-size: 12px;
  line-height: 13px;
  color: #1756e8;
  cursor: pointer;
`;

export const SelectedOrgUnitsContainer = styled.div`
  width: 226px;
  height: 247px;
  background-color: #f4f7fe;
  border-radius: 5px;
  padding: 8px 0;
`;

export const SelectedOrgUnitsHeader = styled.div`
  width: 100%;
  height: 15%;
  padding: 0 15px 8px 15px;
  border-bottom: 1px solid #efefef;
`;

export const SelectedOrgUnitsHeaderText = styled.text`
  font-size: 14px;
  color: #7a7a7a;
  margin: 0;
`;

export const SelectedOrgUnitList = styled.div`
  width: 100%;
  height: 70%;
  padding: 8px 0;
  overflow-y: auto;
`;

export const SelectedOrgUnitItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  border-bottom: 1px solid #efefef;
`;

export const SelectedOrgUnitItemText = styled.text`
  font-size: 14px;
  padding: 8px 0 8px 15px;
  margin: 0;
  text-transform: capitalize;
`;

export const SelectedOrgUnitFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 15%;
  padding: 9px 25px;
`;

export const SelectedOrgUnitFooterText = styled.text`
  font-size: 12px;
  line-height: 13px;
  color: #ec5c52;
  cursor: pointer;
`;

export const EmptyList = styled.div`
  display: flex;
  height: 85%;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
`;
