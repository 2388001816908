import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
`;

export const Text = styled.div`
  margin-bottom: 10px;
  textarea {
    width: 100%;
    max-width: 950px;
    min-height: 252px;
    resize: none;
    padding: 10px;
    border: 1px solid #c7cbd1;
    box-sizing: border-box;
    border-radius: 3px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    color: #444a51;
    padding-bottom: 10px;
  }
`;

export const Message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ea3e4f;
`;
