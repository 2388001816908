import { Dispatch } from 'redux';
import store from '~/data/store';
import { listRequest } from '~/data/store/reducer/appointment/actions/list';

import { iActionList } from '~/domain/interfaces/redux/appointment/list';

import { ListAppointment } from '~/domain/usecases/appointment/redux';

export class ReduxListAppointment implements ListAppointment {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: ListAppointment.Params): iActionList =>
    this.send(listRequest(params));
}
