import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ResendEmail as UsecaseRemoteResendEmail } from '~/domain/usecases/users/remote';

import { RemoteResendEmail } from '~/data/repository/users';

/**
 * send request via API.
 */
export const makeRemoteResendEmail = (): UsecaseRemoteResendEmail =>
  new RemoteResendEmail(makeApiUrl('/users/email/resend'), makeHttpClient());
