import { call, put } from 'redux-saga/effects';
import {
  AuthGetACLFailed,
  AuthGetACLSuccess,
} from '~/data/store/reducer/auth/actions/getACL';

import { makeRemoteGetACL } from '~/main/factories/usecases/permissions/GetACL';
import {
  iActionAuthGetACL,
  iActionAuthGetACLSuccess,
} from '~/domain/interfaces/redux/auth/Acl';

import { GetACL } from '~/domain/usecases/permissions/redux';

export function* onAuthGetACL(action: iActionAuthGetACL) {
  const ACL = makeRemoteGetACL();

  try {
    const response: GetACL.Model = yield call(ACL.get, action.payload);
    yield put(AuthGetACLSuccess(response));
  } catch (e) {
    yield put(AuthGetACLFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onAuthGetACLSuccess(action: iActionAuthGetACLSuccess) {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onAuthGetACLFailed() {}
