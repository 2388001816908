import { CreateChatMessage } from '~/domain/usecases/chat/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteCreateChatMessage implements CreateChatMessage {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateChatMessage.Model>;

  constructor(url: string, httClient: HttpClient<CreateChatMessage.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  createMessage = async (
    params: CreateChatMessage.Params,
  ): Promise<CreateChatMessage.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{chatId}', `${params.id}`),
      method: 'post',
      body: {
        type: params.type,
        content: params.content,
        emitter: params.emitter,
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
