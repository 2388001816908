export enum OrgUnitTypes {
  LIST = '@orgUnit/LIST',
  LIST_SUCCESS = '@orgUnit/LIST_SUCCESS',
  LIST_FAILED = '@orgUnit/LIST_FAILED',
  CREATE = '@orgUnit/CREATE',
  CREATE_SUCCESS = '@orgUnit/CREATE_SUCCESS',
  CREATE_FAILED = '@orgUnit/CREATE_FAILED',
  EDIT = '@orgUnit/EDIT',
  EDIT_SUCCESS = '@orgUnit/EDIT_SUCCESS',
  EDIT_FAILED = '@orgUnit/EDIT_FAILED',
}
