import styled from 'styled-components';

export const Container = styled.form`
  width: 100%;
`;

export const Profession = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 600px;
`;

export const Body = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
`;

export const Message = styled.span`
  color: #c4c4c4;
  font-size: 14px;
  padding-top: 20px;
`;
