import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

interface TableProps {
  columns: string[];
  rows: object[];
  bold?: string[];
  boldBlack?: string[];
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    // paddingLeft: '20px',
    // paddingRight: '20px',
  },
  container: {
    maxHeight: 400,
  },
  title: {
    color: 'gray',
    fontSize: '14px',
  },
  body: {
    fontSize: '14px',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#000000',
  },
  // alignLeft: {
  //   alignItems: 'right',
  // },
});

const StickyHeadTable: React.FC<TableProps> = ({ columns, rows, bold }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map(column => (
                <TableCell className={classes.title}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  {Object.entries(row).map(([key, value]) => (
                    <TableCell
                      className={
                        bold !== undefined && bold.includes(key)
                          ? `${classes.bold}`
                          : `${classes.body}`
                      }
                      key={key}
                      align="left"
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StickyHeadTable;
