import { iHealthUnit } from '~/domain/interfaces/models/healthUnits';
import { HealthUnitsTypes } from '~/domain/interfaces/redux/healthUnits/types';
import { HealthUnitsActions } from './actions';

export const initialState: iHealthUnit = {
  metadata: {
    limit: 0,
    offset: 0,
    total: 0,
  },
  records: [],
  loading: false,
};

const reducer = (
  state = initialState,
  action: HealthUnitsActions,
): iHealthUnit => {
  switch (action.type) {
    case HealthUnitsTypes.GETALL:
      return { ...state, loading: true };
    case HealthUnitsTypes.GETALL_SUCCESS:
      return { ...state, records: action.payload.records, loading: false };
    case HealthUnitsTypes.GETALL_FAILED:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
