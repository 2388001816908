import styled from 'styled-components';

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column-reverse;
  width: 300px;
  height: 324px;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  overflow-y: scroll;
`;

export const ContainerData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  h1 {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    line-height: 9px;
    text-indent: center;
    color: #bdbdbd;
  }
`;

interface alignProps {
  align: string;
  side: 'row' | 'row-reverse';
}

export const ContainerMessage = styled.div<alignProps>`
  max-width: 90%;
  display: flex;
  align-items: ${props => props.align};
  flex-direction: column;
  width: fit-content;
  height: auto;
  margin-top: 20px;
  background: #fbfbfb;
  padding: 5px;
  border-radius: 5px;
  h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #bdbdbd;
  }
  .top-msg {
    gap: 5px;
    padding: 5px;
    display: flex;
    flex-direction: ${props => props.side};
    justify-content: space-between;
    width: 100%;
  }
  h2 {
    padding: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    text-align: left;
    color: #7a7a7a;
  }
`;
