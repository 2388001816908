import styled from 'styled-components';

interface iImage {
  size?: string;
}

export const Container = styled.div`
  position: relative;

  #uploader {
    position: absolute;
    bottom: 10px;
    right: 0px;
  }
`;

export const Img = styled.img<iImage>`
  border-radius: 100%;
  width: ${props => props.size || '100px'};
  height: ${props => props.size || '100px'};
  object-fit: contain;
`;

export const Initials = styled.div<iImage>`
  width: ${props => props.size || '100px'};
  height: ${props => props.size || '100px'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  font-size: 60px;
  background: ${props => props.theme.typographyLightGray};
`;
