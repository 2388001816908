/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState, useCallback } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import { translator } from '~/presentation/components/i18n';
import { schemaSectors } from '~/validation/validators/user/CreateUserValidator';
import {
  PlusSectorIcon,
  ArrowRightSectorIcon,
  MinusSectorIcon,
} from '~/presentation/base/icons';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { AlertMessage } from '../messages/AlertMessage';
import { iRegisterProfessional } from './interface';
import {
  Container,
  Form,
  Content,
  Header,
  CardsContainer,
  OrgUnitsContainer,
  OrgUnitHeader,
  OrgUnitHeaderText,
  OrgUnitList,
  OrgUnitItem,
  OrgUnitItemText,
  OrgUnitFooter,
  OrgUnitFooterText,
  SelectedOrgUnitsContainer,
  SelectedOrgUnitsHeader,
  SelectedOrgUnitsHeaderText,
  SelectedOrgUnitList,
  SelectedOrgUnitItem,
  SelectedOrgUnitItemText,
  SelectedOrgUnitFooter,
  SelectedOrgUnitFooterText,
  EmptyList,
} from './style/StyledSectors';
import { Navigator } from './Navigator';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);
interface ownProps {
  next: (data: iRegisterProfessional) => any;
  back: (data: iRegisterProfessional) => any;
  state?: iRegisterProfessional;
}

interface OrgUnit {
  id: number;
  name: string;
}

const Sectors: React.FC<ownProps> = ({ next, back, state }): JSX.Element => {
  const orgs = useSelector((store: iStore) => store.auth.orgs);
  const { orgId, role } = useSelector((store: iStore) => store.auth.selectUser);
  const { results: adminOrg } = useSelector((store: iStore) => store.orgUnit);

  const [orgUnits, setOrgUnits] = useState<OrgUnit[]>([]);
  const [ready, setReady] = useState(false);
  const [selectedOrgUnits, setSelectedOrgUnits] = useState<OrgUnit[]>([]);

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaSectors),
    defaultValues: {
      sectors: state?.sectors,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ sectors: selectedOrgUnits });
  });

  const onBack = () => back({ sectors: selectedOrgUnits });

  const handleAddOrgUnit = useCallback(
    (orgUnit: OrgUnit) => {
      const newOrgUnits = orgUnits.filter(ou => ou.id !== orgUnit.id);

      register('sectors');
      setOrgUnits(newOrgUnits);
      setSelectedOrgUnits([...selectedOrgUnits, orgUnit]);
      setValue('sectors', [...selectedOrgUnits, orgUnit]);
    },
    [orgUnits, register, selectedOrgUnits, setValue],
  );

  const handleAddAllOrgUnits = useCallback(() => {
    register('sectors');
    setOrgUnits([]);
    setSelectedOrgUnits([...selectedOrgUnits, ...orgUnits]);
    setValue('sectors', [...selectedOrgUnits, ...orgUnits]);
  }, [orgUnits, register, selectedOrgUnits, setValue]);

  const handleRemoveOrgUnit = useCallback(
    (orgUnit: OrgUnit) => {
      const newOrgUnits = selectedOrgUnits.filter(ou => ou.id !== orgUnit.id);

      register('sectors');
      setSelectedOrgUnits(newOrgUnits);
      setValue('sectors', newOrgUnits);
      setOrgUnits([...orgUnits, orgUnit]);
    },
    [orgUnits, register, selectedOrgUnits, setValue],
  );

  const handleRemoveAllOrgUnits = useCallback(() => {
    register('sectors');
    setSelectedOrgUnits([]);
    setValue('sectors', []);
    setOrgUnits([...orgUnits, ...selectedOrgUnits]);
  }, [orgUnits, register, selectedOrgUnits, setValue]);

  useEffect(() => {
    if (orgId) {
      const org = orgs.find(o => o.id === orgId);

      console.log('state: ', state?.alreadyRegisteredSectors);

      if (org) {
        if (role === 'ADM') {
          setOrgUnits(
            adminOrg
              .map(item => {
                if (
                  !state?.alreadyRegisteredSectors?.includes(item.orgUnit.id)
                ) {
                  return {
                    name: item.orgUnit.name,
                    id: item.orgUnit.id,
                  };
                }

                return null;
              })
              .filter(Boolean) as OrgUnit[],
          );
        } else {
          setOrgUnits(
            org.orgUnits.filter(
              item => !state?.alreadyRegisteredSectors?.includes(item.id),
            ),
          );
        }

        setReady(true);
      }
    }
  }, [orgs, orgId, state?.alreadyRegisteredSectors, role, adminOrg]);

  useEffect(() => {
    register('sectors');
    if (ready && getValues()?.sectors?.length) {
      const filteredSectors = getValues().sectors.filter(orgUnit =>
        orgUnits.find(ou => ou.id === orgUnit.id),
      );

      setSelectedOrgUnits(filteredSectors);
      setOrgUnits(
        orgUnits.filter(ou => !filteredSectors.find(s => s.id === ou.id)),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ready]);

  useEffect(() => {
    if (Object.keys(errors).length) {
      AlertMessage({
        message: intl.formatMessage({
          id: 'É necessário que pelo menos um setor seja selecionado.',
        }),
        type: 'danger',
      });
    }
  }, [errors]);

  console.log('getValues: ', getValues());

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Content>
          <Header>
            Para selecionar os setores de atuação do profissional, clique no
            <PlusSectorIcon style={{ margin: '0 5px' }} />
            nos setores disponíveis.
          </Header>
          <CardsContainer>
            <OrgUnitsContainer>
              <OrgUnitHeader>
                <OrgUnitHeaderText>Setores disponíveis</OrgUnitHeaderText>
              </OrgUnitHeader>
              {orgUnits.length > 0 ? (
                <>
                  <OrgUnitList>
                    {orgUnits.map(orgUnit => (
                      <OrgUnitItem id={`orgUnit_${orgUnit.id}`}>
                        <OrgUnitItemText>{orgUnit.name}</OrgUnitItemText>
                        <PlusSectorIcon
                          id={`addOrgUnit_${orgUnit.id}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleAddOrgUnit(orgUnit)}
                        />
                      </OrgUnitItem>
                    ))}
                  </OrgUnitList>
                  <OrgUnitFooter>
                    <OrgUnitFooterText
                      id="addAllOrgUnit"
                      onClick={handleAddAllOrgUnits}
                    >
                      Adicionar todos
                    </OrgUnitFooterText>
                  </OrgUnitFooter>
                </>
              ) : (
                <EmptyList>
                  <OrgUnitHeaderText style={{ textAlign: 'center' }}>
                    Você já selecionou todos os setores disponíveis!
                  </OrgUnitHeaderText>
                </EmptyList>
              )}
            </OrgUnitsContainer>
            <ArrowRightSectorIcon />
            <SelectedOrgUnitsContainer>
              <SelectedOrgUnitsHeader>
                <SelectedOrgUnitsHeaderText>
                  Setores selecionados
                </SelectedOrgUnitsHeaderText>
              </SelectedOrgUnitsHeader>
              {selectedOrgUnits.length > 0 ? (
                <>
                  <SelectedOrgUnitList>
                    {selectedOrgUnits.map(orgUnit => (
                      <SelectedOrgUnitItem id={`orgUnit_${orgUnit.id}`}>
                        <SelectedOrgUnitItemText>
                          {orgUnit.name}
                        </SelectedOrgUnitItemText>
                        <MinusSectorIcon
                          id={`removeOrgUnit_${orgUnit.id}`}
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleRemoveOrgUnit(orgUnit)}
                        />
                      </SelectedOrgUnitItem>
                    ))}
                  </SelectedOrgUnitList>
                  <SelectedOrgUnitFooter>
                    <SelectedOrgUnitFooterText
                      id="removeAllOrgUnit"
                      onClick={handleRemoveAllOrgUnits}
                    >
                      Retirar todos
                    </SelectedOrgUnitFooterText>
                  </SelectedOrgUnitFooter>
                </>
              ) : (
                <EmptyList>
                  <OrgUnitHeaderText style={{ textAlign: 'center' }}>
                    Ainda não há setores selecionados.
                  </OrgUnitHeaderText>
                </EmptyList>
              )}
            </SelectedOrgUnitsContainer>
          </CardsContainer>
        </Content>
        <Navigator back={onBack} isFinish={false} />
      </Form>
    </Container>
  );
};

export default Sectors;
