import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  background: ${props => props.theme.background};
  overflow: auto;
  position: relative;
`;

export const Body = styled.div`
  padding-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;

  #container {
    width: 100%;
    /* height: 100%; */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;
