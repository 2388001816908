import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { makeRemoteRemovedOnCallRequester } from '../factories/usecases/onCallRequester/RemovedOnCallRequesterFactory';
import { makeRemoteRemovedOnCallSpecialist } from '../factories/usecases/onCallSpecialist/RemovedOnCallSpecialistFactory';

interface ownProps {
  children: React.ReactNode;
}

const useRouteChange = (
  callback: (
    currentLocation: Location,
    previousLocation: Location,
    professionalId?: number,
  ) => void,
) => {
  const location = useLocation() as any;
  const previousLocationRef = useRef<Location | null>(null);
  const isChanged = useRef(false);
  const [previousLocation, setPreviousLocation] = useState<Location | null>(
    null,
  );
  const professional = getProfessionalInfo();

  useEffect(() => {
    if (previousLocationRef.current) {
      isChanged.current = true;
    }
  }, [previousLocation]);

  useEffect(() => {
    if (previousLocationRef.current) {
      setPreviousLocation(previousLocationRef.current);
    }
    previousLocationRef.current = location;
  }, [location]);

  useEffect(() => {
    console.log(
      '>>>Rota Debug',
      location,
      previousLocation,
      previousLocationRef.current,
    );
    if (previousLocation && isChanged.current) {
      callback(location, previousLocation, professional?.id);
      isChanged.current = false;
    }
  }, [location, previousLocation, callback, professional]);
};

const RouterChange: React.FC<ownProps> = ({ children }) => {
  useRouteChange((currentLocation, previousLocation, professionalId) => {
    if (
      previousLocation.pathname === '/duty/me' &&
      currentLocation.pathname !== '/conf'
    ) {
      if (professionalId)
        makeRemoteRemovedOnCallRequester()
          .removed({
            requesterId: professionalId,
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('Erro ao sair do plantão', err);
          });
    }

    if (
      /duty\/\d+/.test(previousLocation.pathname) &&
      currentLocation.pathname !== '/conf'
    ) {
      if (professionalId)
        makeRemoteRemovedOnCallSpecialist()
          .removed({
            onCallId: Number(previousLocation.pathname.split('/')[2]),
            specialistId: professionalId,
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('Erro ao sair do plantão', err);
          });
    }
  });
  return <>{children}</>;
};

export default RouterChange;
