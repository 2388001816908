import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 40px;
`;

export const TitleLink = styled.div`
  display: flex;
  align-items: center;
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  svg {
    margin-right: 8px;
  }
`;

export const TitleInvite = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  font-family: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  svg {
    margin-right: 8px;
  }
`;

export const TitleEmail = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-family: inherit;
  font-style: normal;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 16px;
  svg {
    margin-right: 8px;
  }
`;

export const Invite = styled.div`
  width: 100%;
  display: flex;
  gap: 5px;
  margin-top: 26px;
  margin-bottom: 39px;

  input {
    font-size: 16px;
  }
`;

export const ContainerEmails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 100px;
  border: 1px solid #efefef;
  border-radius: 5px;
  span {
    padding: 10px;
    color: #656a6e;
    font-size: 16px;
  }
`;

export const ContentMultiEmail = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .multi-email {
    width: 100%;
    max-height: 80px;
    overflow-y: auto;
  }
  #guest {
    color: #444a51;
    margin-bottom: 2px;
  }
  #each-email {
    margin-right: 5px;
  }
`;

export const ResendEmail = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding-right: 15px;
  justify-content: space-between;
  #button_resend_email {
    cursor: pointer;
  }
`;

export const InviteLink = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
  justify-content: flex-start;
`;
