import styled from 'styled-components';

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 425px;
  max-height: 277px;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-overflow: ellipsis;

  padding-top: 30px;
  width: 90%;
  height: 70%;
`;
