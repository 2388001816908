/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-comment-textnodes */
// eslint-disable-next-line react/jsx-one-expression-per-line
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as datefns from 'date-fns';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxLogin } from '~/main/factories/usecases/auth/LoginFactory';
import { makeReduxSelectUser } from '~/main/factories/usecases/auth/SelectUserFactory';
import { makeReduxRefresh } from '~/main/factories/usecases/auth/RefreshFactory';
import { History } from '~/main/routes';
import { GuestAppointment } from '~/domain/usecases/appointment/remote/GuestAppointment';

import { Icon } from '~/presentation/components/icon';

import {
  IconV4Hatendimento,
  IconPadlock,
  IconEmail,
} from '~/presentation/base/icons';
import { Input, Button } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';
import {
  Body,
  Container,
  Content,
  Forgotpass,
  Header,
  Image,
  Information,
} from './styles/StyledInviteProfessional';

interface ownProps {
  data?: GuestAppointment.Model;
}

const InviteProfessional: React.FC<ownProps> = ({ data }) => {
  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  const [password, setPassword] = useState<string>('');

  const auth = useSelector((store: iStore) => store.auth);

  const emailMask = () => {
    if (data?.professional?.email !== undefined) {
      const maskedEmail = data?.professional?.email
        .replace(/([^@.])/g, '*')
        .split('');
      let previous = '';
      for (let i = 0; i < maskedEmail.length; i += 1) {
        if (i <= 5 || previous === '.' || previous === '@') {
          maskedEmail[i] = data?.professional?.email[i];
        }
        previous = data?.professional?.email[i];
      }

      return maskedEmail.join('');
    }

    return '';
  };

  useEffect(() => {
    if (
      auth.access.token &&
      data?.appointment?.id !== undefined &&
      auth?.info?.professionals &&
      auth?.info?.user?.id === data?.professional?.user
    ) {
      auth.orgs.forEach(org => {
        if (org.id === data?.appointment.org) {
          org.orgUnits.forEach(orgUnit => {
            if (orgUnit.id === data?.appointment.orgUnit) {
              makeReduxRefresh().refresh({
                user: auth.info.user?.id,
                org: org.id,
                refreshToken: auth.refresh.token,
              });
              makeReduxSelectUser().update({
                orgUnitId: data?.appointment.orgUnit,
                orgId: data?.appointment.org,
                orgUnits: org.orgUnits,
              });
            }
          });
        }
      });

      if (
        ['APPTYPE_INTERCONSULTATION', 'APPTYPE_CLINIC_DISCUSSION'].includes(
          data?.appointmentType.msgKey,
        )
      ) {
        History.getHistory().push(`/conf`, {
          appointmentId: data?.appointment?.id,
          consultantId: data?.consultant.id,
          professionalId: data?.professional.id,
        });
      } else {
        History.getHistory().push(`/appointment/waitingroom`, {
          appointmentId: data?.appointment?.id,
          consultantId: data?.consultant.id,
          professionalId: data?.professional.id,
        });
      }
    }

    console.log('...', data);
  }, [auth, data?.appointment?.id]);

  const onSubmit = () => {
    const login = undefined;

    makeReduxLogin().login({
      email: login,
      login: data?.professional?.email,
      password,
    });
  };

  return (
    <Container>
      <Header>
        {logoUrl ? (
          <Image alt="Logo" src={logoUrl} width="200px" />
        ) : (
          <Icon src={IconV4Hatendimento} width="200px" height="100px" />
        )}
      </Header>
      <Information>
        <p id="text-one">
          {translator('Bem-vindo, doutor(a)')}{' '}
          <strong>{`${data?.professional?.firstName} ${data?.professional?.lastName}`}</strong>
          !
        </p>
        <p>{translator('Realize o login para iniciar o atendimento')}</p>
        <p id="text-three">
          {translator('do paciente')}{' '}
          <strong id="name_patient">{`${data?.consultant?.firstName} ${data?.consultant?.lastName}`}</strong>{' '}
          {translator('marcado para as')}{' '}
          <strong id="hour">
            {data?.appointment?.scheduled &&
              datefns.format(
                new Date(`${data?.appointment?.scheduled}`),
                'hh:mm',
              )}
          </strong>
          .
        </p>
      </Information>
      <Body>
        <Content>
          <Input
            id="loginInput"
            name="email"
            type="text"
            width="300px"
            label={translator('E-mail')}
            icon={IconEmail}
            value={emailMask()}
            disabled
          />

          <Input
            id="loginPassword"
            type="password"
            name="password"
            label={translator('Senha')}
            onChange={e => {
              setPassword(e.target.value);
            }}
            icon={IconPadlock}
            placeholder={translator('Digite sua senha')}
          />

          <Button id="loginSubmit" rounded height="48px" onClick={onSubmit}>
            {translator('Entrar')}
          </Button>
        </Content>
      </Body>
      <Forgotpass id="loginForgotPass" href="/password/recovery">
        {translator('Esqueceu sua senha?')}
      </Forgotpass>
    </Container>
  );
};

export default InviteProfessional;
