import styled from 'styled-components';

export const ContainerHeader = styled.div`
  width: 300px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #004ba5;
  border-radius: 4px 4px 0px 0px;
  button {
    background-color: transparent;
    border: 0;
  }
  button img {
    height: 14px;
    width: 14px;
    margin-right: 10px;
    cursor: pointer;
  }
`;
