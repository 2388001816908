import { UpdateAppointment } from '~/domain/usecases/appointment/redux/UpdateAppointment';
import {
  iActionUpdate,
  iActionUpdateFailed,
  iActionUpdateSuccess,
  AppointmentTypes,
} from '~/domain/interfaces/redux/appointment/update';

export const UpdateRequest = (
  payload: UpdateAppointment.Params,
): iActionUpdate => ({
  type: AppointmentTypes.UPDATE,
  payload,
});

export const UpdateSuccess = (): iActionUpdateSuccess => ({
  type: AppointmentTypes.UPDATE_SUCCESS,
});

export const UpdateFailed = (): iActionUpdateFailed => ({
  type: AppointmentTypes.UPDATE_FAILED,
});
