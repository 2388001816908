import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface ContainerProps {
  open: boolean;
}

export const Container = styled.div<ContainerProps>`
  #btn_filter {
    color: ${props => (props.open ? 'black' : '#7A7A7A')};
    background-color: ${props => (props.open ? '#eeeeee' : 'transparent')};
    width: 50px;
    margin-right: 5px;
  }
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    outline: none;
    border: 0 solid;
    margin: 5px 20px;
    font-size: 16px;
    background-color: ${defaultTheme.background};
    color: ${defaultTheme.highlight};
  }
  button:hover {
    cursor: pointer;
  }
`;

export const Img = styled.img`
  min-width: 25px;
  background-color: ${defaultTheme.secundary};
`;
