import { CreateProfessionalSpecialty } from '~/domain/usecases/professionalList/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
  Conflict,
} from '~/domain/errors';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

export class RemoteCreateProfessionalSpecialty
  implements CreateProfessionalSpecialty
{
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateProfessionalSpecialty.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<CreateProfessionalSpecialty.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  create = async (
    params: CreateProfessionalSpecialty.Params,
  ): Promise<CreateProfessionalSpecialty.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.professionalId}/specialties/${params.specialtyId}`,
      body: params.specialty,
      method: 'post',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
