import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  align-items: flex-start;
  justify-content: space-around;
`;
