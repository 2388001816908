export const columns = [
  {
    id: 'professional',
    label: 'Profissional',
  },
  {
    id: 'start',
    label: 'Início',
  },
  {
    id: 'end',
    label: 'Término',
  },
  {
    id: 'range',
    label: 'Período',
  },
  {
    id: 'actions',
    label: 'Ações',
  },
];
