import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #ffffff;

  .title {
    width: 100%;
    height: 64px;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

interface ButtonColapseProps {
  isColapsed: boolean;
}

export const ButtonColapse = styled.button<ButtonColapseProps>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #b4b7b9;
  border-radius: 6px;

  svg {
    transform: ${props =>
      props.isColapsed ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

export const Content = styled.div`
  padding: 16px 24px;
  border-top: 1px solid #dedede;
`;
