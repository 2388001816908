import React, { useCallback, useEffect } from 'react';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iMessageOptions } from '~/domain/interfaces/redux/message';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { Button } from '~/presentation/components/UI';
import { closeModal } from '~/utils/closeModal';

interface ModalsProps {
  active: iMessageOptions;
}

interface ToastProps {
  type: 'success' | 'info' | 'danger' | 'warning';
}

const ModalsTeste: React.FC<ModalsProps> = ({ active }) => {
  const handleClick = useCallback((type: ToastProps) => {
    AlertMessage({
      message: 'teste',
      type: type.type,
    });
  }, []);

  useEffect(() => {
    makeReduxActiveMessage().active({
      active,
      actionCancel: () => closeModal(),
      actionOk: () => closeModal(),
      content: 'teste',
      appointmentLinks: ['teste', 'teste'],
      handleIncrementCardId: () => console.log('teste'),
      handleUpdateValue: () => console.log('teste'),
      handleZodUpdateValue: () => console.log('teste'),
      incrementCardId: -1,
      mimeType: 'IMAGE',
      type: 'ONBOARDING',
      url: 'teste',
      title: 'teste',
    });
  }, [active]);

  return (
    <div
      style={{
        display: 'flex',
        gap: '10px',
      }}
    >
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'danger' });
        }}
      >
        Toast error
      </Button>
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'info' });
        }}
      >
        Toast info
      </Button>
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'success' });
        }}
      >
        Toast success
      </Button>
      <Button
        data-testid="test"
        size="medium"
        rounded
        onClick={() => {
          handleClick({ type: 'warning' });
        }}
      >
        Toast warn
      </Button>
    </div>
  );
};

export default ModalsTeste;
