import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const selectStyles = () => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '46px',
    border: '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 2,
  }),
});

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};
  overflow: hidden;
`;

export const Content = styled.div`
  /* display: flex; */
  width: 100%;
`;

export const Left = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Right = styled.div`
  display: flex;
`;

export const Body = styled.div`
  display: flex;
  padding: 40px 72px 0;
  justify-content: space-between;
  gap: 40px;
`;

interface TextProps {
  size: number;
  weight: number;
  color?: string;
}

export const Text = styled.div<TextProps>`
  color: ${({ color }) => color || '#222529'};
  font-family: Roboto;
  font-size: ${({ size }) => size}px;
  font-style: normal;
  font-weight: ${({ weight }) => weight};
  line-height: normal;
`;

// Health unit

export const HealthUnitCard = styled.div`
  /* height: 300px; */
  width: 438px;

  border-radius: 10px;
  border: 1px solid #dedede;
`;
