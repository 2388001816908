import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { CreateProfessional } from '~/domain/usecases/professionalList/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
  Conflict,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemoteCreateProfessional implements CreateProfessional {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateProfessional.Model>;

  constructor(url: string, httpClient: HttpClient<CreateProfessional.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  create = async (
    params: CreateProfessional.Params,
  ): Promise<CreateProfessional.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Falha ao registrar usuário.' }),
        });
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Falha ao registrar usuário.' }),
        });
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Falha ao registrar usuário.' }),
        });
        throw new NotFound();
      case HttpStatusCode.conflict:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'E-mail ou CPF já utilizado.' }),
        });
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
