import styled, { css } from 'styled-components';

const gridComponent = css`
  display: grid;

  grid-template-columns: 0.6fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr;
  /* gap: 40px; */

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`;

export const GridHeader = styled.div`
  width: 100%;
  padding: 16px 60px 0 16px;
`;

export const Container = styled.div`
  width: 100%;
  padding-right: 8px;

  ${gridComponent};

  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;

  ${gridComponent}

  text-align: right;

  font-size: 12px;
  line-height: 14px;

  color: #7a7a7a;

  p:nth-child(1) {
    text-align: left;
  }
`;

export const GridInfoBilling = styled.div`
  width: 100%;
  min-width: 650px;

  ${gridComponent}
  grid-template-columns: 0.6fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 44px;

  align-items: center;
  justify-content: center;

  text-align: right;

  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;

  div:nth-child(1) {
    text-align: left;
  }

  color: #000000;
`;

export const TableBorder = styled.div`
  width: 100%;
  padding-bottom: 8px;
  border-top: 1px solid #efefef;
  padding-top: 8px;
`;
