import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

interface StyledProps {
  isSigned?: Boolean;
}

export const Container = styled.div`
  display: flex;
  border: 1px solid ${defaultTheme.statusNone};
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 4px;
  height: 64px;

  -webkit-box-shadow: 4px 1px 12px 0px ${props => props.theme.statusNone};
  -moz-box-shadow: 4px 1px 12px 0px ${props => props.theme.statusNone};
  box-shadow: 4px 1px 12px 0px ${props => props.theme.statusNone};
`;

export const Prefix = styled.div`
  display: flex;
  width: 20%;
  border-right: 1px solid ${defaultTheme.statusNone};
`;

export const Wrapper = styled.div<StyledProps>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  width: 70%;

  padding-left: 17px;
`;

export const Delete = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;

  svg {
    cursor: pointer;
  }
`;

export const Title = styled.text<StyledProps>`
  font-weight: bold;
  font-size: 14px;
  color: ${({ isSigned }) =>
    isSigned ? defaultTheme.primary : defaultTheme.statusNo};
`;

export const SubTitle = styled.text`
  font-size: 12px;
  padding-top: 6px;
  color: ${defaultTheme.typographyDarkGray};
`;
