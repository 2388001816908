import { CreateUser, DeleteUser } from '~/domain/usecases/users/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteDeleteUser implements DeleteUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteUser.Model>;

  constructor(url: string, httClient: HttpClient<DeleteUser.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete = async (params: DeleteUser.Params): Promise<DeleteUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.id}`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
