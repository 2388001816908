import styled from 'styled-components';

interface statusProps {
  status?: 'opened' | 'paid out' | 'overdue';
}

interface collapseProps {
  colorStatus?: boolean;
}

export const ContainerPayment = styled.div`
  width: 98%;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid #efefef;
  height: 100%; // Mudar o css aqui
`;

export const ContentHeaders = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const HeaderRightPayment = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  text {
    font-size: 22px;
    color: #000000;
    font-weight: 500;
  }
`;

export const HeaderLeftPayment = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  padding-right: 15px;
`;

export const ContentStatusPayment = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

export const RightStatusPayment = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const StatusPayment = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 5px;
  line-height: 18px;
  p {
    color: #7a7a7a;
    font-size: 16px;
  }
`;

export const DownloadInvoice = styled.div`
  display: flex;
  margin-top: 50px;
  justify-content: flex-end;
  padding-right: 15px;
  gap: 15px;
  text {
    margin-top: 20px;
    color: #7a7a7a;
    font-weight: 500;
  }
`;

export const IconButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
`;

export const TotalPayment = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
  padding-right: 50px;
  font-size: 24px;
  justify-content: space-between;
  border-bottom: 1px solid #bfc4ca;
  text {
    font-size: 22px;
    font-weight: 500;
    color: #000000;
  }
`;

export const Information = styled.div`
  width: 100%;
  padding-top: 20px;
`;

export const TitleItensPayment = styled.div<collapseProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 10px;
  padding-right: 20px;
  border-bottom: 1px solid #bfc4ca;
  text {
    font-size: 22px;
    font-weight: 500;
    color: #000000;
  }
  #link {
    color: ${props => (props.colorStatus ? '#1756e8' : '#bfc4ca')};
    cursor: pointer;
  }
`;

export const ContainerItensPayment = styled.div`
  width: 100%;
  height: 100%;
  max-height: 300px;
`;

export const ItensPayment = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  padding-right: 50px;
`;

export const Status = styled.strong<statusProps>`
  color: ${props => {
    switch (props.status) {
      case 'paid out':
        return '#5BC766';
      case 'overdue':
        return '#EC5C52';
      case 'opened':
        return '#004BA5';
      default:
        return 'none';
    }
  }};
`;

export const ShowDetails = styled.div`
  font-size: 12px;
  line-height: 24px;
  text-align: center;

  color: #1756e8;
`;
