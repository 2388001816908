import { GetAllHealthUnits } from '~/domain/usecases/healthUnits/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteGetAllHealthUnits implements GetAllHealthUnits {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllHealthUnits.Model>;

  constructor(url: string, httpClient: HttpClient<GetAllHealthUnits.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getAllHealthUnits = async (
    params: GetAllHealthUnits.Params,
  ): Promise<GetAllHealthUnits.Model> => {
    let query = '';

    Object.entries(params).forEach(value => {
      if (value?.[1]) query += `${value[0]}=${value[1]}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
    });

    console.log('GetAll healthunits request: ', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
