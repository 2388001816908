import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { iResults, iStore } from '~/domain/interfaces/models';
import { GetAllAppointment } from '~/domain/usecases/appointment/remote';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import MyAgendaList from '~/presentation/components/MyAgendaList';
import MyAppointmentInfoHeader from '~/presentation/components/MyAppointmentInfoHeader';
import { PageHeader } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { Menu } from '~/presentation/components/menu';
import { PatientDetailsCard } from '~/presentation/components/patientDetailsCard';
import { useMyAppointmentsFilter } from '~/presentation/hooks/filters/myAppointments';
import { makeReduxListAppointment } from '~/main/factories/usecases/appointment/ListAppointmentFactory';
import { Container } from './styles';

const MyAppointmentsDay: React.FC = () => {
  const [currentStatus, setCurrentStatus] = useState('');
  const [currentCard, setCurrentCard] = useState<
    GetAllAppointment.Model['results'][0] | undefined
  >({} as GetAllAppointment.Model['results'][0]);
  const info = useSelector((store: iStore) => store.auth.info);

  const { orgId, role, orgUnits } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const { results, date } = useSelector((store: iStore) => store.appointment);

  const { currentDay, setCurrentDay, filtersBody } = useMyAppointmentsFilter();

  const filteredCards: iResults[] = useMemo(
    () =>
      results?.filter(item => {
        if (item?.appointment?.status === currentStatus || currentStatus === '')
          return item;

        return null;
      }) ?? [],
    [results, currentStatus],
  );

  const professionalId = info.professionals?.find(
    item => item.org.id === orgId,
  )?.id;

  useEffect(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    const from = new Date(currentDay);
    from.setHours(-3, 1, 0, 0);

    const to = new Date(currentDay);
    to.setHours(20, 59, 59, 999);

    makeReduxGetAllAppointment().getAll({
      filter: {
        scheduleFrom: from.toISOString(),
        scheduleTo: to.toISOString(),
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        professional: professionalId,
        ...filtersBody,
      },
      orderAttribute: 'scheduled',
    });
    setCurrentCard(undefined);
  }, [currentDay, orgId, professionalId, role, orgUnits]);

  useEffect(() => {
    if (filteredCards?.length) {
      setCurrentCard(filteredCards[0]);
    } else {
      setCurrentCard(undefined);
    }
  }, [currentDay, filteredCards]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <div style={{ width: '100%' }}>
        <Menu />
        <PageHeader title={translator('Agenda do dia')} />
      </div>
      <div style={{ width: '100%', padding: '24px 73px 0' }}>
        <MyAppointmentInfoHeader
          getStatus={setCurrentStatus}
          getCurrentDay={setCurrentDay}
        />
      </div>
      <Container>
        <MyAgendaList
          cards={filteredCards}
          getAppointment={setCurrentCard}
          currentAppointment={currentCard?.appointment?.id}
        />
        <PatientDetailsCard appointment={currentCard} />
      </Container>
    </div>
  );
};

export default MyAppointmentsDay;
