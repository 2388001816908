import { GetAllChat } from '~/domain/usecases/chat/redux/GetAllChat';
import {
  ChatTypes,
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
} from '~/domain/interfaces/redux/chat/getAll';

export const getAllRequest = (payload: GetAllChat.Params): iActionGetAll => ({
  type: ChatTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllChat.Model,
): iActionGetAllSuccess => ({
  type: ChatTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: ChatTypes.GETALL_FAILED,
});
