import React, { useState } from 'react';
import {
  IconNotSignedDocument,
  IconSignedDocuments,
  IconProcessingDocument,
} from '~/presentation/base/icons';
import { translator } from '../i18n';

import {
  Container,
  Signed,
  NotSigned,
  Processing,
} from './styles/StyledDocument';

interface ExampleProps {
  status?: 'SIGNED' | 'SUBMITTED' | 'NOTREQUESTED';
}

const DocumentStatus: React.FC<ExampleProps> = ({ status }) => {
  return (
    <Container>
      {status === 'SIGNED' && (
        <Signed>
          <IconSignedDocuments />
          <text>{translator('Assinado')}</text>
        </Signed>
      )}
      {/* {status === 'SUBMITTED' && (
        <Processing>
          <IconProcessingDocument />
          <text>{translator('Processando')}</text>
        </Processing>
      )} */}
      {status !== 'SIGNED' && (
        // status !== 'SUBMITTED' &&
        <NotSigned>
          <IconNotSignedDocument />
          <text>{translator('Pendente')}</text>
        </NotSigned>
      )}
    </Container>
  );
};
export default DocumentStatus;
