/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { useSelector } from 'react-redux';

import { History } from '~/main/routes';
import { translator } from '~/presentation/components/i18n';
// import { IconBack } from '~/presentation/base/icons';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { makeReduxSetData } from '~/main/factories/usecases/appointment/SetDataFactory';
import { Icon } from '../icon';
import { BackButton } from '.';
import { Container, Title } from './style/StylePageHeader';

interface ownProps {
  title?: string;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  backDescription?: string;
  onBack?: () => void;
  getCurrentDay?: Function;
  confirmChanges?: boolean;
  buttonActionRight?: React.ReactNode;
}

interface iStateParams {
  from: string;
}

interface namePages {
  [key: string]: string;
}

type Props = ownProps;

const PageHeader: React.FC<Props> = ({
  title,
  icon,
  backDescription,
  getCurrentDay,
  confirmChanges = false,
  buttonActionRight,
  onBack,
}): JSX.Element => {
  const { appointment, profile, specialty } = useSelector(
    (store: iStore) => store.showModal,
  );

  const Icone = icon;

  // const h = MemoryRouter.toString();
  const from = useLocation<iStateParams>().state?.from;

  const namePages: namePages = {
    clinicaldocs: translator('Documentos clínicos'),
    appointment: translator('Agenda de atendimentos'),
    profile: translator('Perfil do usuário'),
    appointmentdetail: translator('Detalhes do atendimento'),
    clinicaldocdetail: translator('Documento clínico'),
    participant: translator('Pacientes cadastrados'),
    professional: translator('Profissionais cadastrados'),
    manager: translator('Organizadores cadastrados'),
    appointmentnew: translator('Agendar novo atendimento'),
    clinicaldocnew: translator('Cadastrar novo documento clínico'),
    participantregister: translator('Cadastrar novo participante'),
    professionalregister: translator('Cadastrar novo profissional'),
    managerregister: translator('Cadastrar novo organizador'),
    administrator: translator('Administradores cadastrados'),
    appointmentme: translator('Agenda do dia'),
  };

  const namePageBack = namePages[`${from}`]
    ? translator(namePages[`${from}`])
    : translator('Voltar');

  const backD = backDescription !== undefined ? backDescription : namePageBack;

  const appointmentDate = useSelector(
    (store: iStore) => store.appointment.date,
  );

  const getBackToSelectedDay = () => {
    getCurrentDay?.(new Date(appointmentDate));
  };

  const leavePage = () => {
    return makeReduxActiveMessage().active({
      active: 'leaveSpecialty',
      actionOk: () => {
        History.getHistory().goBack();
        closeModal();
      },
      actionCancel: closeModal,
    });
  };

  const history = History.getHistory();

  const doesAnyHistoryEntryExist = useMemo(() => {
    return history.length > 1;
  }, [history.length]);

  return (
    <Container hasButtonBack={doesAnyHistoryEntryExist}>
      {doesAnyHistoryEntryExist && (
        <div id="left">
          <BackButton
            onBack={() => {
              if (confirmChanges || profile || specialty || appointment)
                leavePage();
              else if (onBack) onBack();
              else History.getHistory().goBack();
            }}
            backDescription={backD}
            id="backButton"
          />
        </div>
      )}
      <div id="center">
        <Title>{title}</Title>
        {Icone && <Icon src={Icone} width="16px" height="16px" />}
      </div>
      <div id="right">{buttonActionRight}</div>
    </Container>
  );
};

export default PageHeader;
