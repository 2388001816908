import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ListManager } from '~/presentation/roles/manager/pages';
import { makeReduxGetAllOrganizer } from '../../usecases/organizer/GetAllOrganizer';
import { PageNotFound, Unauthorized } from '~/presentation/pages/errors';
import { iStore } from '~/domain/interfaces/models';

export const MakeListManagePage: React.FC = () => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const { orgId, orgUnitId } = useSelector(
    (state: iStore) => state.auth.selectUser,
  );

  useEffect(() => {
    makeReduxGetAllOrganizer().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit: orgUnitId ?? undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [orgId, orgUnitId]);

  switch (role) {
    case 'ADM':
      return <ListManager />;
    case 'ORG':
      return <ListManager />;
    case 'PRO':
      return <Unauthorized />;
    case 'CON':
      return <Unauthorized />;
    default:
      return <PageNotFound />;
  }
};
