import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { GetAllRegionalCountries as UsecaseRemoteGetAllRegionalCountries } from '~/domain/usecases/regionaldoc/remote';

import { RemoteGetAllRegionalCountries } from '~/data/repository/regionaldoc';

/**
 * send request via API.
 */
export const makeRemoteGetAllRegionalCountries =
  (): UsecaseRemoteGetAllRegionalCountries =>
    new RemoteGetAllRegionalCountries(
      makeApiUrl('/regionalDocs/countries'),
      makeHttpClient(),
    );
