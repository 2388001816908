import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Container, Name, Role, Actions, Exit } from './style/ProfileNavigator';

import { iUserInformation } from '~/presentation/components/formPerfilData';
import { iStore } from '~/domain/interfaces/models';
import { Button } from '~/presentation/components/UI';
import { I18n, translator } from '~/presentation/components/i18n';
import { AvatarWithUploader } from '~/presentation/components/UI/avatar';

import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

interface OwnProps {
  nav: Function;
  active: number;
}

interface iProps {
  userId: string;
}
const ProfileNavigator: React.FC<OwnProps> = ({ nav, active }) => {
  const { userId } = useParams<iProps>();
  const role = useSelector(
    (states: iStore) => states.users.selected.user.mainRole,
  );

  const showModal = useSelector((states: iStore) => states.showModal);

  const { administrators, consultants, organizers, professionals, user } =
    useSelector((states: iStore) => states.users.selected);

  const navigateToPage = (page: number) => {
    if (
      (active === 3 && showModal.specialty) ||
      (active === 1 && showModal.profile)
    ) {
      return makeReduxActiveMessage().active({
        active: 'leaveSpecialty',
        actionOk: () => {
          nav(page);
          closeModal();
        },
        actionCancel: closeModal,
      });
    }

    return nav(page);
  };

  function profileData() {
    return (
      <Button
        id="button_profileData"
        rounded
        variant="secundary"
        onClick={() => navigateToPage(1)}
        active={active === 1}
      >
        {translator('Dados perfil')}
      </Button>
    );
  }

  function changePassword() {
    return (
      <Button
        id="button_changePassword"
        rounded
        variant="secundary"
        onClick={() => navigateToPage(2)}
        active={active === 2}
      >
        {translator('Mudar senha')}
      </Button>
    );
  }

  function professional() {
    return (
      <Button
        id="button_professional"
        rounded
        variant="secundary"
        onClick={() => navigateToPage(3)}
        active={active === 3}
      >
        {translator('Profissional')}
      </Button>
    );
  }

  function deleteAccount() {
    return (
      <Button
        id="button_deleteAccount"
        rounded
        variant="secundary"
        onClick={() => navigateToPage(4)}
        active={active === 4}
      >
        {translator('Excluir conta')}
      </Button>
    );
  }

  return (
    <Container>
      <AvatarWithUploader
        src={user.avatar!}
        fullName={`${user.firstName} ${user.lastName}`}
        size="158px"
      />
      <Name>{`${user.firstName} ${user.lastName}`}</Name>
      <Role>{role}</Role>
      <Actions>
        {profileData()}
        {changePassword()}
        {professional()}
        {deleteAccount()}
      </Actions>
    </Container>
  );
};

export default ProfileNavigator;
