import { ComponentClass, FunctionComponent, ReactNode } from 'react';
import { Store as notification } from 'react-notifications-component';

export interface iAlertMessage {
  title?: string;
  message: string;
  type: 'success' | 'info' | 'danger' | 'warning';
  position?:
    | 'top-right'
    | 'top-left'
    | 'top-center'
    | 'center'
    | 'bottom-left'
    | 'bottom-right'
    | 'bottom-center';
  content?: ComponentClass | FunctionComponent | ReactNode;
}

export const AlertMessage = ({
  message,
  title,
  type,
  position = 'top-right',
  content = undefined,
}: iAlertMessage): void => {
  notification.addNotification({
    title,
    message,
    content: content as any,
    type,
    id: `message-${type}`,
    insert: 'top',
    container: position,
    animationIn: ['animated', 'tada'],
    animationOut: ['animated', 'zoomOutRight'],
    dismiss: {
      duration: 5000,
      onScreen: true,
      pauseOnHover: true,
    },
  });
};
