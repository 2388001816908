import styled from 'styled-components';

interface ownProps {
  fixedHeight?: boolean;
  height?: string;
  color?: string;
  error?: boolean;
}

export const DropContainer = styled.label<ownProps>`
  width: 100%;
`;

export const DropInput = styled.input``;

export const DropText = styled.div`
  margin-top: 17px;
  text-align: center;
  text-overflow: ellipsis;

  p {
    color: #bfc4ca;
    font-size: 14px;
    cursor: pointer;
    margin: 0;
  }
`;

export const ContentFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 29.5vh;
  max-height: 29.5vh;
`;

export const PreviewImage = styled.img`
  width: 100%;
  height: 29.4vh;
  object-fit: cover;
  max-height: 29.4vh;
`;
