import React from 'react';
import { Container, Text } from './styles/StyledReport';

const Report: React.FC = () => {
  return (
    <Container>
      <Text>
        <p>Relatório</p>
        <textarea placeholder="Situação do paciente." />
      </Text>
    </Container>
  );
};

export default Report;
