import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 20px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

export const Header = styled.header`
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${props => props.theme.primaryDark};
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.primaryDark};
  max-width: 100%;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  width: 100%;
  gap: 10px;
`;

export const ContentCancel = styled.div`
  max-width: 440px;
  border-radius: 8px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${props => props.theme.statusCancel};
  background: #f5c1c1;
`;

export const InfoCancel = styled.div`
  width: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
`;

export const Message = styled.span`
  text-align: center;
`;

export const Date = styled.p`
  text-align: center;
  color: ${({ theme }) => theme.typographyDarkGray};
  font-size: 12px;
  font-weight: 700;
`;
