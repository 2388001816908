import { call, put } from 'redux-saga/effects';
import { iActionGetDashboardUnits } from '~/domain/interfaces/redux/dashboard/getDashboardUnits';
import { GetDashboardUnits } from '~/domain/usecases/dashboard/remote/GetDashboardUnits';
import {
  getFailed,
  getSuccess,
} from '~/data/store/reducer/dashboard/actions/getDashboardUnits';

import { makeRemoteGetDashboardUnits } from '~/main/factories/usecases/dashboard/GetDashboardUnitsFactory';

export function* onGetDashboardUnits(action: iActionGetDashboardUnits) {
  const remoteGetDashboardUnits = makeRemoteGetDashboardUnits();

  try {
    const response: GetDashboardUnits.Model = yield call(
      remoteGetDashboardUnits.get,
      action.payload,
    );

    yield put(getSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDashboardUnitsSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDashboardUnitsFailed() {}
