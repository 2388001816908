import { Dispatch } from 'redux';
import { GetAllSupportDocs } from '~/domain/usecases/supportDoc/redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/supportDoc/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/supportDoc/getAll';

export class ReduxGetAllSupportDocs implements GetAllSupportDocs {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllSupportDocs.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
