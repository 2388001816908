import { SendFilesDignosisReport } from '~/domain/usecases/diagnosisReport/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';
import { Content } from '~/presentation/components/UI/modal/style/StyledModal';

export class RemoteSendFilesDiagnosisReport implements SendFilesDignosisReport {
  private readonly url: string;

  private readonly httpClient: HttpClient<SendFilesDignosisReport.Model>;

  constructor(
    url: string,
    httClient: HttpClient<SendFilesDignosisReport.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  send = async (
    params: SendFilesDignosisReport.Params,
  ): Promise<SendFilesDignosisReport.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      body: params.body,
      method: 'post',
      headers: {
        ContentType: 'multipart/form-data',
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
