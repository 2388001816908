import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { EvaluateDiagnosisReport as UsecaseRemoteEvaluateDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
// import { EvaluateDiagnosisReport as UsecaseReduxEvaluateDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemoteEvaluateDiagnosisReport } from '~/data/repository/diagnosisReport';
// import { ReduxEvaluateDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemoteEvaluateDiagnosisReport =
  (): UsecaseRemoteEvaluateDiagnosisReport =>
    new RemoteEvaluateDiagnosisReport(
      makeApiUrl('/reports/{report}/EVALUATE'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxEvaluateDiagnosisReport =
  (): UsecaseReduxEvaluateDiagnosisReport =>
    new ReduxEvaluateDiagnosisReport(); */
