import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListOnCallRequest as UsecaseRemoteListOnCallRequest } from '~/domain/usecases/onCallRequest/remote';
import { ListOnCallRequest as UsecaseReduxListOnCallRequest } from '~/domain/usecases/onCallRequest/redux';

import { RemoteListOnCallRequest } from '~/data/repository/onCallRequest';
import { ReduxListOnCallRequest } from '~/data/store/reducer/onCallRequest/usecases';

/**
 * send request via API.
 */
export const makeRemoteListOnCallRequest = (): UsecaseRemoteListOnCallRequest =>
  new RemoteListOnCallRequest(
    makeApiUrl('/oncall/requests/list'),
    makeHttpClient(),
  );

/**
 * send request via REDUX.
 */
export const makeReduxListOnCallRequest = (): UsecaseReduxListOnCallRequest =>
  new ReduxListOnCallRequest();
