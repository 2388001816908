import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { RefuseAppointmentOnCall as UsecaseRemoteRefuseAppointmentOnCall } from '~/domain/usecases/onCall/remote';
// import { RefuseAppointmentOnCall as UsecaseReduxRefuseAppointmentOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteRefuseAppointmentOnCall } from '~/data/repository/onCall';
// import { ReduxRefuseAppointmentOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteRefuseAppointmentOnCall =
  (): UsecaseRemoteRefuseAppointmentOnCall =>
    new RemoteRefuseAppointmentOnCall(
      makeApiUrl(
        '/oncall/{onCallId}/specialists/{specialistId}/attend/{requesterId}/REFUSE',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxRefuseAppointmentOnCall =
  (): UsecaseReduxRefuseAppointmentOnCall =>
    new ReduxRefuseAppointmentOnCall(); */
