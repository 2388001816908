/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import {
  IconCrossBlue,
  IconCrossRed,
  IconNotSignedDocument,
  IconSignedDocuments,
} from '~/presentation/base/icons';
import {
  Content,
  HeaderContent,
  BodyColumnLeft,
  BodyColumnRight,
  CreatedAt,
  Patient,
  Signed,
  Title,
  Body,
  DateStyle,
  Moree,
  HeaderDivision,
} from './styles/StyledHeader';
import { OptionsClinicalDoc } from '~/presentation/components/optionsMore';

import { More } from '~/presentation/components/UI/popover';
import { iUser } from '../specialPrescription/interface';
import { translator } from '../i18n';
import { iClinicalDocsResults, iStore } from '~/domain/interfaces/models';
import { getLocale } from '~/utils/getLocale';
import { BackButton } from '../header';
import { makeReduxSelectOne } from '~/main/factories/usecases/clinicalDocs/SelectOneFactory';
import { SendMail } from '../messages/email/SendMail';

interface HeaderProps {
  isSigned: boolean;
  type?: string;
  user?: iUser | undefined;
  clinicalDoc?: iClinicalDocsResults;
  hideMoreOptions?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  user,
  isSigned,
  type = 'Receita Especial',
  clinicalDoc,
  hideMoreOptions = false,
}) => {
  const { role } = useSelector((states: iStore) => states.auth.selectUser);
  const [sendingEmail, setSendingEmail] = useState(false);
  const locale = getLocale();

  const formattedDate = (date: string, dateType: 'date' | 'date-hour') => {
    const dateJavascript = new Date(date);

    if (!(dateJavascript instanceof Date && !Number.isNaN(dateJavascript)))
      return date;

    return dateType === 'date-hour'
      ? `${dateJavascript.toLocaleDateString(locale)} ${translator(
          'às',
        )} ${dateJavascript.toLocaleTimeString(locale)}`
      : `${dateJavascript.toLocaleDateString(locale)}`;
  };

  return (
    <>
      <Content isOk={isSigned}>
        <HeaderContent>
          <BackButton
            onBack={() =>
              makeReduxSelectOne().select({
                id: 0,
              })
            }
          />
          <HeaderDivision>
            <Title id="document_type" isOk={isSigned}>
              {isSigned ? <IconCrossBlue /> : <IconCrossRed />}
              {type}
            </Title>
          </HeaderDivision>
          <Moree>
            {hideMoreOptions || (!isSigned && role === 'CON') ? null : (
              <More
                content={
                  <OptionsClinicalDoc
                    id={String(clinicalDoc?.document?.id)}
                    status={clinicalDoc?.document?.status === 'SIGNED'}
                    openResendEmail={() => setSendingEmail(true)}
                  />
                }
                positionY="top"
                positionX="right"
              />
            )}
          </Moree>
        </HeaderContent>
        <Body>
          <BodyColumnLeft>
            <Patient>
              {`${translator('Paciente')}:`}
              <div className="patient-name">{user?.name}</div>
            </Patient>
            <CreatedAt>
              {`${translator('Criado em')}:${
                clinicalDoc?.document?.sent
                  ? formattedDate(clinicalDoc?.document?.sent, 'date-hour')
                  : '03/11/2020 às 10:00'
              }`}
            </CreatedAt>
          </BodyColumnLeft>
          <BodyColumnRight>
            <Signed isOk={isSigned}>
              {isSigned ? <IconSignedDocuments /> : <IconNotSignedDocument />}
              {isSigned ? translator('Assinado') : translator('Pendente')}
            </Signed>
            <DateStyle isOk={isSigned}>
              {isSigned
                ? `${translator('Enviado ao paciente:')} ${
                    clinicalDoc?.document?.sent
                      ? formattedDate(clinicalDoc?.document?.sent, 'date')
                      : '03/11/2020 às 10:00'
                  }`
                : `${translator('Documento não enviado')}`}
            </DateStyle>
          </BodyColumnRight>
        </Body>
      </Content>
      {sendingEmail && clinicalDoc?.document?.id && (
        <SendMail
          docId={clinicalDoc?.document?.id}
          close={() => setSendingEmail(false)}
        />
      )}
    </>
  );
};

export default Header;
