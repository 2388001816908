import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  BadRequestError,
  InvalidCredentialsError,
  UnexpectedError,
} from '~/domain/errors';
import { ListOnCall } from '~/domain/usecases/duty/remote';
import '~/infra/global/variables';
import { makeReduxListShifts } from '~/main/factories/usecases/shifts';

export class RemoteListOnCall implements ListOnCall {
  private readonly url: string;

  private readonly httpClient: HttpClient<ListOnCall.Model>;

  constructor(url: string, httClient: HttpClient<ListOnCall.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (): Promise<ListOnCall.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });

    console.log('httpResponse: ', httpResponse);

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        makeReduxListShifts().setOnCallId(httpResponse.body.records[0].id);
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
