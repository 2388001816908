import { Dispatch } from 'redux';
import store from '~/data/store';
import { iActionDeleteShifts } from '~/domain/interfaces/redux/shifts/delete';

import { DeleteShifts } from '~/domain/usecases/shifts/redux';
import { deleteShiftsByIdRequest } from '../actions/delete';

export class ReduxDeleteShiftsById implements DeleteShifts {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  delete = (params: DeleteShifts.Params): iActionDeleteShifts =>
    this.send(deleteShiftsByIdRequest(params));
}
