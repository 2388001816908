import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetBillingDetailed as UsecaseRemoteGetBillingDetailed } from '~/domain/usecases/billing/remote';
import { GetBillingDetailed as UseCaseReduxGetBillingDetailed } from '~/domain/usecases/billing/redux';

import { RemoteGetBillingDetailed } from '~/data/repository/billing';
import { ReduxGetBillingDetailed } from '~/data/store/reducer/billing/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetBillingDetailed =
  (): UsecaseRemoteGetBillingDetailed =>
    new RemoteGetBillingDetailed(makeApiUrl('/orgs'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetBillingDetailed = (): UseCaseReduxGetBillingDetailed =>
  new ReduxGetBillingDetailed();
