/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-one-expression-per-line */
// eslint-disable-next-line react/jsx-one-expression-per-line
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  iInvoiceResultsItem,
  iInvoiceServiceResults,
  iStore,
} from '~/domain/interfaces/models';
import { ConnectComponent } from './mapper/invoiceMapper';
import { InvoiceIcon } from '~/presentation/base/icons';
import { Select } from '~/presentation/components/UI';
import { BillingAcordeon } from '../BillingAcordeon';
import { InvoiceSelectOptions, ownProps } from './interface';
import {
  ContainerItensPayment,
  ContainerPayment,
  ContentHeaders,
  ContentStatusPayment,
  DownloadInvoice,
  HeaderLeftPayment,
  HeaderRightPayment,
  Information,
  RightStatusPayment,
  Status,
  StatusPayment,
  TitleItensPayment,
  TotalPayment,
} from './styles/StyledInvoiceAndPayments';
import { translator } from '../i18n';
import { makeReduxGetBillingService } from '~/main/factories/usecases/billing/GetBillingServiceFactory';
import { getLocale } from '~/utils/getLocale';

const InvoiceAndPayments: React.FC<ownProps> = ({ invoiceMonths }) => {
  const [checked, setChecked] = useState(false);
  const [invoiceMonthSelected, setInvoiceMonthSelected] = useState<
    InvoiceSelectOptions['value']
  >({
    invoiceId: -1,
  } as InvoiceSelectOptions['value']);
  const [billingData, setBillingData] = useState<iInvoiceResultsItem>(
    {} as iInvoiceResultsItem,
  );
  const [invoiceServiceItems, setInvoiceServiceItems] =
    useState<iInvoiceServiceResults>({} as iInvoiceServiceResults);

  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);
  const { invoiceItems, serviceItems } = useSelector(
    (store: iStore) => store.billing,
  );

  const locale = getLocale();

  useEffect(() => {
    if (orgId) {
      const initialDate = new Date('1950-10-10');
      const today = new Date();

      makeReduxGetBillingService().get({
        orgId,
        query: {
          limit: 9999,
          begin: initialDate.toISOString(),
          end: today.toISOString(),
        },
      });
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId) {
      const now = new Date();

      const begin = invoiceMonthSelected?.year
        ? `${invoiceMonthSelected?.year}-${invoiceMonthSelected?.month}-01`
        : `1950-10-10`;
      const end = invoiceMonthSelected?.year
        ? `${invoiceMonthSelected?.year}-${invoiceMonthSelected?.month}-31`
        : `${now.getFullYear()}-${now.getMonth() + 1}-31`;

      makeReduxGetBillingService().get({
        orgId,
        query: {
          limit: 9999,
          begin,
          end,
        },
      });

      setChecked(false);
    }
  }, [invoiceMonthSelected]);

  useEffect(() => {
    if (invoiceItems?.records && invoiceItems?.records?.length) {
      const found = invoiceItems?.records.find(
        item => item.id === invoiceMonthSelected?.invoiceId,
      );
      setBillingData(found ?? invoiceItems.records?.[0]);
    }
  }, [invoiceItems, invoiceMonthSelected]);

  useEffect(() => {
    setInvoiceServiceItems(serviceItems);
  }, [serviceItems, invoiceMonthSelected]);

  function handleCollapseDetails() {
    setChecked(false);
  }

  const displayData = useMemo(() => {
    const end = serviceItems?.endTime
      ? new Date(`${serviceItems?.endTime}`).toLocaleDateString(locale)
      : '-';

    const vigence =
      serviceItems?.startTime && serviceItems?.endTime
        ? `${new Date(`${serviceItems?.startTime}`).toLocaleDateString(
            locale,
          )} | ${end}`
        : '-';

    const value = serviceItems?.services?.length
      ? new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: serviceItems?.currency ?? 'BRL',
        }).format(
          serviceItems?.services?.reduce((acc, item) => acc + item.total, 0),
        )
      : '-';

    return {
      end,
      vigence,
      value,
    };
  }, [
    locale,
    serviceItems?.currency,
    serviceItems?.endTime,
    serviceItems?.services,
    serviceItems?.startTime,
  ]);

  return (
    <ContainerPayment>
      <ContentHeaders>
        <HeaderRightPayment id="receiptsHeader">
          <InvoiceIcon />
          <text>{translator('Faturas e pagamentos')}</text>
        </HeaderRightPayment>
        <HeaderLeftPayment>
          {/* TODO: Aguardando implementação do back-end para reativar funcionalidades */}
          {/* <CsvIcon />
          <Impress /> */}
        </HeaderLeftPayment>
      </ContentHeaders>
      <ContentStatusPayment>
        <StatusPayment>
          <RightStatusPayment>
            <Select
              label={translator('Mês')}
              width="250px"
              onChange={e => {
                const value = JSON.parse(
                  e.target.value,
                ) as InvoiceSelectOptions['value'];

                setInvoiceMonthSelected(value);
              }}
              value={JSON.stringify(invoiceMonthSelected)}
            >
              <option value={-1}>{translator('Selecione um mês')}</option>
              {invoiceMonths.map(item => (
                <option value={JSON.stringify(item.value)}>{item.label}</option>
              ))}
            </Select>
            <StatusPayment>
              <p>
                {translator('Status de pagamento')}:
                <Status status={billingData?.paid ? 'paid out' : 'opened'}>
                  {billingData?.paid
                    ? `${translator('Pago')}`
                    : `${translator('Em aberto')}`}
                </Status>
              </p>
              <p>
                <Status>
                  {`${translator('Vencimento')}: ${displayData.end}`}
                </Status>
              </p>
            </StatusPayment>
          </RightStatusPayment>
        </StatusPayment>
        <DownloadInvoice>
          <text>{`${translator('Vigência')}: ${displayData.vigence}`}</text>
          {/* TODO: Aguardando implementação do back-end para reativar funcionalidades */}
          {/* {billingData?.invoice?.paid ? (
            <Button rounded size="medium" variant="secundary">
              <IconButton>
                <InvoiceDownload />
                Baixar comprovante
              </IconButton>
            </Button>
          ) : (
            <Button rounded size="medium" variant="primary">
              <IconButton>
                <InvoiceDownload />
                Baixar fatura
              </IconButton>
            </Button>
          )} */}
        </DownloadInvoice>
      </ContentStatusPayment>
      <TotalPayment>
        <text>{translator('Total estimado')}</text>
        <Status status={billingData?.paid ? 'paid out' : 'opened'}>
          {displayData.value}
        </Status>
      </TotalPayment>
      <Information>
        <p>
          {translator(
            'O valor total faturado será exibido depois que a fatura completa for fechada.',
          )}
        </p>
      </Information>
      <TitleItensPayment colorStatus={checked}>
        <text>{translator('Itens da fatura')}</text>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div
          id="link"
          onClick={() => {
            handleCollapseDetails();
          }}
        >
          {translator('Ocultar todos os detalhes')}
        </div>
      </TitleItensPayment>
      <ContainerItensPayment>
        <BillingAcordeon
          invoiceServiceItems={invoiceServiceItems}
          show={checked}
          setShow={setChecked}
          invoiceId={billingData?.id}
        />
      </ContainerItensPayment>
    </ContainerPayment>
  );
};

export default ConnectComponent(InvoiceAndPayments);
