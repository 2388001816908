import { ChatMessage } from '~/domain/usecases/chat/redux';
import {
  ChatTypes,
  iActionChatMessage,
  iActionChatMessageFailed,
  iActionChatMessageSuccess,
} from '~/domain/interfaces/redux/chat/chatMessage';

export const chatMessageRequest = (
  payload: ChatMessage.Params,
): iActionChatMessage => ({
  type: ChatTypes.CHAT_MESSAGE,
  payload,
});

export const chatMessageSuccess = (
  params: ChatMessage.Model,
): iActionChatMessageSuccess => ({
  type: ChatTypes.CHAT_MESSAGE_SUCCESS,
  payload: params,
});

export const chatMessageFailed = (): iActionChatMessageFailed => ({
  type: ChatTypes.CHAT_MESSAGE_FAILED,
});
