import { Dispatch } from 'redux';
import store from '~/data/store';
import { getRequest } from '~/data/store/reducer/billing/actions/getBillingService';

import { iActionGetBillingService } from '~/domain/interfaces/redux/billing/getBillingService';

import { GetBillingService } from '~/domain/usecases/billing/redux';

export class ReduxGetBillingService implements GetBillingService {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetBillingService.Params): iActionGetBillingService =>
    this.send(getRequest(params));
}
