import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { V4hSpin } from '~/presentation/components/spin';
import { History } from '~/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeRemoteSignDiagnosisReport } from '../../usecases/diagnosisReport/SignDiagnosisReportFactory';

const DiagnosisVidaasSignFactory: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  useEffect(() => {
    const diagnosisReportId = localStorage.getItem('diagnosisReportId');

    if (code && diagnosisReportId) {
      setLoading(true);

      makeRemoteSignDiagnosisReport()
        .sign({
          id: Number(diagnosisReportId),
          body: {
            signatureSource: 'VIDAAS',
            signatureValue: code,
          },
        })
        .then(() => {
          AlertMessage({
            message: 'Documento assinado com sucesso!',
            type: 'success',
          });

          window.location.href = '/diagnosis';
        })
        .catch(err => {
          console.log('>>> Err 2: ', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [code, History]);

  return <>{loading && <V4hSpin fullScreen />}</>;
};

export default DiagnosisVidaasSignFactory;
