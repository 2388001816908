import { CircularProgress, CircularProgressLabel } from '@chakra-ui/react';
import { is } from 'date-fns/locale';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { GetUserDataWaitingRoom } from '~/domain/usecases/waitingRoom/remote';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { History } from '~/main/routes';
import {
  FlowerPot,
  IconShareLink,
  IconConfirmedShareLink,
  RecepicionistaIcon,
} from '~/presentation/base/icons';
import Chairs from '~/presentation/base/icons/chairs.svg';
import Desk from '~/presentation/base/images/deskImage2.png';
import backgroundImage from '~/presentation/base/images/LAOHA 4.png';
import { Button } from '~/presentation/components/UI';
import ViewMedia from '~/presentation/components/UI/modals/viewMedia';
import WaitingRoomCarousel from '~/presentation/components/WaitingRoomCarousel';
import { closeModal } from '~/utils/closeModal';
import {
  BackgroundImage,
  CarouselDiv,
  ChairsDiv,
  Container,
  DeskDiv,
  FlowerPotDiv,
  Header,
  Media,
  MediasDiv,
  NotificationBalloon,
  NotificationDiv,
  StSection,
} from './styles/NewWaitingRoom';

interface WaitingRoomProps {
  left: () => void;
  isStart: boolean;
  infoNotification: () => void;
  startNotification: () => void;
  startConference: () => void;
  closeAllToasts: () => void;
  medias: GetUserDataWaitingRoom.Model['medias'];
  appointmentId: number;
  waitingRoomUserId?: number;
}

/* eslint-disable react/react-in-jsx-scope */
const NewWaitingRoom: React.FC<WaitingRoomProps> = ({
  left,
  isStart,
  startNotification,
  infoNotification,
  startConference,
  closeAllToasts,
  medias,
  appointmentId,
  waitingRoomUserId,
}) => {
  const [openedMedia, setOpenedMedia] = useState(false);
  const [progress, setProgress] = useState(100);
  const seconds = useRef(10);

  const intervalRef = useRef<NodeJS.Timeout>();

  const { active } = useSelector((state: iStore) => state.message);

  const isActive = active === MessageOptions.professionalReady;

  const handleOpenModal = (
    media: any,
    title: string,
    mimeType: 'IMAGE' | 'VIDEO' | 'FORM',
    link?: string,
  ) => {
    makeReduxActiveMessage().active({
      active: MessageOptions.viewMediaModal,
      url: media,
      title,
      mimeType,
      link,
    });
    setOpenedMedia(true);
  };

  useEffect(() => {
    return () => {
      closeAllToasts();
      makeReduxActiveMessage().active({ active: 'none' });
    };
  }, []);

  useEffect(() => {
    console.log('isStart: ', isStart);

    if (isStart) {
      let time = 10;
      intervalRef.current = setInterval(() => {
        time -= 1;
        seconds.current -= 1;

        const progressWidth = (time / 10) * 100;

        if (time > 0) {
          setProgress(progressWidth);
        } else {
          if (intervalRef.current) clearInterval(intervalRef.current);
          setProgress(0);
          // redirect to conf page
          startConference();
          closeModal();
        }
      }, 1000);
    } else if (intervalRef.current) clearInterval(intervalRef.current);
  }, [isStart]);

  console.log('medias: ', medias);

  const imageMedias = medias.filter(media => media.mimeType === 'IMAGE');
  const videoMedias = medias.filter(media => media.mimeType === 'VIDEO');
  const formMedias = medias.filter(media => media.mimeType === 'FORM');

  return (
    <>
      {isStart && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            backgroundColor: '#0000006f',
            top: 0,
            left: 0,
            zIndex: 999,
          }}
        >
          <div
            className="content"
            style={{
              background: 'white',
              padding: '30px 34px',
              borderRadius: '1rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '0 auto',
            }}
          >
            <>
              <CircularProgress
                value={progress}
                valueText={`${progress}`}
                size={70}
                thickness="3px"
                color="#df2c26"
              >
                <CircularProgressLabel>
                  <p
                    style={{
                      fontSize: '20px',
                      color: '#df2c26',
                      fontWeight: 700,
                    }}
                  >
                    {seconds.current}
                  </p>
                </CircularProgressLabel>
              </CircularProgress>
            </>
            <div style={{ textAlign: 'center', marginTop: '15px' }}>
              <span style={{ fontWeight: 700 }}>
                O profissional está pronto
              </span>
              <br />
              <span style={{ fontWeight: 500 }}>
                Você será redirecionado para a tela
                <br />
                de pré-consulta
              </span>
            </div>
          </div>
        </div>
      )}
      <Container>
        <StSection>
          <Header onClick={left}>Voltar</Header>
          <BackgroundImage
            src={backgroundImage}
            alt="Background image to the waiting room"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              width: '100%',
            }}
          >
            <CarouselDiv className="carousel">
              <WaitingRoomCarousel medias={videoMedias} />
            </CarouselDiv>

            <MediasDiv className="box-medias">
              {imageMedias.length === 0 && (
                <>
                  <Media
                    src="https://i.im.ge/2022/07/15/FoCL9C.png"
                    onClick={() =>
                      handleOpenModal(
                        'https://i.im.ge/2022/07/15/FoCL9C.png',
                        'Colgate 1',
                        'IMAGE',
                        'https://i.im.ge/2022/07/15/FoCL9C.png',
                      )
                    }
                  />
                  <Media
                    src="https://i.im.ge/2022/07/15/FoCL9C.png"
                    onClick={() =>
                      handleOpenModal(
                        'https://i.im.ge/2022/07/15/FoCL9C.png',
                        'Colgate 1',
                        'IMAGE',
                        'https://i.im.ge/2022/07/15/FoCL9C.png',
                      )
                    }
                  />
                </>
              )}

              {imageMedias.length > 0 &&
                imageMedias.map((media, index) => {
                  if (index < 2) {
                    return (
                      <Media
                        key={media.id}
                        src={media.path}
                        onClick={() =>
                          handleOpenModal(
                            media?.path ||
                              'https://i.im.ge/2022/07/15/FoCL9C.png',
                            media?.descr,
                            'IMAGE',
                            media?.link ||
                              media?.path ||
                              'https://i.im.ge/2022/07/15/FoCL9C.png',
                          )
                        }
                      />
                    );
                  }
                  return null;
                })}
            </MediasDiv>
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <NotificationDiv className="notification">
              <NotificationBalloon>
                <div
                  className="avatar"
                  style={{
                    width: '4.25rem',
                    aspectRatio: '1/1',
                    borderRadius: '50px',
                    objectFit: 'cover',
                    color: '#fff',
                    border: '1px solid #c7ced4',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'end',
                    justifyContent: 'end',
                  }}
                >
                  <RecepicionistaIcon
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </div>
                <p>
                  <h3 className="title">
                    Seu atendimento se iniciará em breve.
                  </h3>
                  <p className="description">
                    Enquanto isso, você pode interagir com os conteúdos da sala
                    de espera.
                  </p>
                </p>
              </NotificationBalloon>
            </NotificationDiv>

            <DeskDiv>
              <img src={Desk} alt="desk" />
            </DeskDiv>
          </div>
          <ChairsDiv>
            <img src={Chairs} alt="chairs" />
          </ChairsDiv>
          <FlowerPotDiv>
            <FlowerPot className="flowerPot" />
            {/* <img src={FlowerPot} alt="flower pot" /> */}
          </FlowerPotDiv>
        </StSection>
      </Container>
      <ViewMedia
        closeCallback={() => {
          closeAllToasts();
          makeReduxActiveMessage().active({ active: 'none' });
        }}
      />
    </>
  );
};

export default NewWaitingRoom;
