import { Record } from './type';

type logType = {
  action: {
    changeData: boolean;
    descr: string;
    groupName: string;
    level: string;
    name: string;
  };
  detail: object;
  id: number;
  timestamp: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    fullName: string;
    login: string;
  };
};

export const mapLog = (log: logType): Omit<Record, 'isTable'>[] => [
  {
    label: 'Data e hora',
    value: new Date(log?.timestamp).toLocaleString(),
  },
  {
    label: 'Nível',
    value: log?.action?.level,
  },
  {
    label: 'Grupo',
    value: log?.action?.groupName,
  },
  {
    label: 'Evento',
    value: log?.action?.descr,
  },
  {
    label: 'Usuário',
    value: log?.user?.fullName,
  },
  {
    label: 'Objeto da ação',
    value: JSON.stringify(log?.detail, null, 2),
    action: true,
  },
];

export const mapLogUser = (log: logType): Record[] => {
  return [
    {
      label: 'ID',
      value: log?.user?.id,
    },
    {
      label: 'Login',
      value: log?.user?.login,
    },
    {
      label: 'Nome',
      value: log?.user?.fullName,
    },
    // {
    //   label: 'Cadastrado',
    //   value: '*05/07/2023 às 11:14',
    // },
    // {
    //   label: 'Organizações',
    //   isTable: true,
    //   value: [
    //     {
    //       name: '*CODATA (DITEC)',
    //       role: '*Admin',
    //     },
    //     {
    //       name: '*Wisecare (DEV)',
    //       role: '*User',
    //     },
    //     {
    //       name: '*Wisecare (DEV)',
    //       role: '*User',
    //     },
    //     {
    //       name: '*Wisecare (DEV)',
    //       role: '*User',
    //     },
    //     {
    //       name: '*CODATA (DITEC)',
    //       role: '*Admin',
    //     },
    //   ],
    // },
    // {
    //   label: 'Último acesso',
    //   value: '*02/12/2022 às 14:31:23',
    // },
  ];
};
