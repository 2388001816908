import { Refresh } from '~/domain/usecases/auth/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UnexpectedError, BadRequestError, NotFound } from '~/domain/errors';

export class RemoteAuthRefresh implements Refresh {
  private readonly url: string;

  private readonly httpClient: HttpClient<Refresh.Model>;

  constructor(url: string, httpClient: HttpClient<Refresh.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  refresh = async (params: Refresh.Params): Promise<Refresh.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
