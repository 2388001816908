import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/selectedOrg/getAll';

import { GetAll } from '~/domain/usecases/selectedOrg/remote';
import { makeRemoteGetAllSelectedOrg } from '~/main/factories/usecases/selectedOrg/GetAll';

import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/selectedOrg/actions/getAll';

export function* onGetAllRequest(action: iActionGetAll) {
  const remoteGetAllSelectedOrg = makeRemoteGetAllSelectedOrg();

  try {
    const response: GetAll.Model = yield call(
      remoteGetAllSelectedOrg.getAll,
      action.payload,
    );

    yield put(getAllSuccess(response));
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
