import styled from 'styled-components';

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
`;

export const Profession = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
`;
