import { iSelectedOrg } from '~/domain/interfaces/models/selectedOrg';
import { SelectedOrgTypes } from '~/domain/interfaces/redux/selectedOrg/types';
import { SelectedOrgActions } from './actions';

export const initialState: iSelectedOrg = {
  pageSize: 0,
  totalItems: 0,
  results: [],
};

const reducer = (
  state = initialState,
  action: SelectedOrgActions,
): iSelectedOrg => {
  switch (action.type) {
    case SelectedOrgTypes.GETALL:
      return state;
    case SelectedOrgTypes.GETALL_SUCCESS:
      return { ...state, results: action.payload.results };
    case SelectedOrgTypes.GETALL_FAILED:
      return state;
    default:
      return state;
  }
};

export default reducer;
