import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAppointmentOutcome as UsecaseRemoteGetAppointmentOutcome } from '~/domain/usecases/appointment/remote';

import { RemoteGetAppointmentOutcome } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteGetAppointmentOutcome =
  (): UsecaseRemoteGetAppointmentOutcome =>
    new RemoteGetAppointmentOutcome(
      makeApiUrl('/appointmentOutcomes'),
      makeHttpClient(),
    );
