import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { translator } from '~/presentation/components/i18n';
import { ScheduleStatus } from '../statusList';
import { SessionInfoProps } from './interface';
import {
  Content,
  Date,
  DateTitle,
  Day,
  Mounth,
  Status,
  Title,
} from './styles/StyledSessionInformation';

interface Session {
  session?: SessionInfoProps;
}

const SessionInfo: React.FC<Session> = ({ session }) => {
  return (
    <Content>
      {!session ? (
        <Skeleton animation="wave" width="150px" height="50px" />
      ) : (
        <DateTitle>
          <Date>
            <Day>{session?.day}</Day>
            <Mounth>{session?.mounth}</Mounth>
          </Date>
          <Title>
            {session?.title}
            <br />
            {session?.timeInterval}
          </Title>
        </DateTitle>
      )}
      <Status>
        <p>{translator('Status do atendimento')}</p>
        <ScheduleStatus status={session?.status} />
      </Status>
    </Content>
  );
};

export default SessionInfo;
