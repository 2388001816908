import { call, put } from 'redux-saga/effects';
import { iSelectOne } from '~/domain/interfaces/redux/clinicalDocs/selectOne';

import { selectOneRequestSuccess } from '~/data/store/reducer/clinicalDocs/actions/selectOne';

export function* onSelectOne(action: iSelectOne) {
  try {
    yield put(selectOneRequestSuccess(action.payload));
  } catch (e) {
    console.log(e);
  }
}
