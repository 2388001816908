import { takeLatest } from 'redux-saga/effects';
import { DutyTypes } from '~/domain/interfaces/redux/duty/types';
import {
  onGetDutyRequesters,
  onGetDutyRequestersFailed,
  onGetDutyRequestersSuccess,
} from './GetDutyRequesters';

import {
  onGetProfessionalOnDuty,
  onGetProfessionalOnDutyFailed,
  onGetProfessionalOnDutySuccess,
} from './GetProfessionalOnDuty';

const tasks = [
  takeLatest(DutyTypes.GET_DUTY_REQUESTERS, onGetDutyRequesters),
  takeLatest(DutyTypes.GET_DUTY_REQUESTERS_SUCCESS, onGetDutyRequestersSuccess),
  takeLatest(DutyTypes.GET_DUTY_REQUESTERS_FAILED, onGetDutyRequestersFailed),
  takeLatest(DutyTypes.GET_PROFESSIONAL_ON_DUTY, onGetProfessionalOnDuty),
  takeLatest(
    DutyTypes.GET_PROFESSIONAL_ON_DUTY_SUCCESS,
    onGetProfessionalOnDutySuccess,
  ),
  takeLatest(
    DutyTypes.GET_PROFESSIONAL_ON_DUTY_FAILED,
    onGetProfessionalOnDutyFailed,
  ),
];

export default tasks;
