import * as datefns from 'date-fns';
import { useSelector } from 'react-redux';
import { date } from 'zod';
import { iChat } from '~/domain/interfaces/models/Chat';
import { iStore } from '~/domain/interfaces/models/Store';
import { ChatTypes } from '~/domain/interfaces/redux/chat/types';
import { Chat } from '~/presentation/components/chat';
import { ChatActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iChat = {
  loading: false,
  visible: false,
  mainUser: {},
  chatSelect: undefined,
  chats: [],
};

const reducer = (state = initialState, action: ChatActions): iChat => {
  switch (action.type) {
    case ChatTypes.CHAT_SETUP:
      return { ...state, ...action.payload };
    case ChatTypes.GET:
      return {
        ...state,
        visible: true,
        loading: true,
        chatSelect: action.payload.id,
      };
    case ChatTypes.GET_SUCCESS:
      return { ...state, loading: false };
    case ChatTypes.GETALL_SUCCESS:
      state.chats = [];

      action.payload.forEach(el => {
        state.chats.push({
          id: el.id,
          name: el.name,
          logo: el.logo,
          participants: el.participants,
          messages: [],
        });
      });

      return { ...state, chats: state.chats };
    case ChatTypes.GETALL_MESSAGE_SUCCESS: {
      const { chats, chatSelect } = state;

      const idx = chats.findIndex(el => el.id === chatSelect);
      chats[idx].messages = [];

      if (idx !== undefined) {
        action.payload.results.forEach(el => {
          chats[idx].messages.push({
            user: {
              id: el.emitter.id,
              firstName: el.emitter.firstName,
              lastName: el.emitter.lastName,
            },
            message: {
              content: el.message.content,
              date: el.message.sent,
            },
          });
        });
      }

      return { ...state, chats };
    }
    case ChatTypes.SOCKET_CREATE_MESSAGE: {
      const data = action.payload;
      const { chats, mainUser } = state;

      const idx = chats.findIndex(el => el.id === data.chatId);
      // const user = chats[idx].participants.find(el => el.id === userLogged);

      chats[idx].messages.unshift({
        user: {
          id: mainUser.id,
          firstName: mainUser.firstName,
          lastName: mainUser.lastName,
        },
        message: {
          content: data.message,
          date: datefns.formatISO(new Date()),
        },
      });

      return { ...state, chats };
    }
    case ChatTypes.SOCKET_CREATE_MESSAGE_SUCCESS: {
      return state;
    }
    case ChatTypes.CHAT_MESSAGE: {
      const data = action.payload;
      const { chats } = state;

      const idx = chats.findIndex(el => el.id === data.chat);
      const user = chats[idx].participants.find(el => el.id === data.emitter);

      chats[idx].messages.unshift({
        user: {
          id: user?.id,
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        message: {
          content: data.content,
          date: data.sent,
        },
      });

      return { ...state, chats };
    }
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
