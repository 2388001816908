import React from 'react';
import { Route } from 'react-router-dom';

import {
  MakeAccessCodeFactory,
  MakeIdentificationFactory,
} from '~/main/factories/pages/guest';
import PreConf from '~/presentation/roles/guest/pages/PreConf/PreConf';
import RateOurService from '~/presentation/roles/guest/pages/RateOurService/RateOurService';
import WaitingRoom from '~/presentation/roles/guest/pages/WaitingRoomGuest/index';

const RoutesGuest = [
  <Route
    exact
    path="/guest/identification"
    component={MakeIdentificationFactory}
  />,
  <Route exact path="/guest/preconference" component={PreConf} />,
  <Route exact path="/guest/rateus" component={RateOurService} />,
  <Route path="/invite" component={MakeAccessCodeFactory} />,
  <Route path="/guestWaitingRoom" component={WaitingRoom} />,
];

export default RoutesGuest;
