import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateClinicalDocsByEmail as UsecaseRemoteCreateClinicalDocsByEmail } from '~/domain/usecases/clinicalDocs/remote';
import { CreateClinicalDocsByEmail as UsecaseReduxCreateClinicalDocsByEmail } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteCreateClinicalDocsByEmail } from '~/data/repository/clinicalDocs';
import { ReduxCreateClinicalDocsByEmail } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteCreateClinicalDocsByEmail =
  (): UsecaseRemoteCreateClinicalDocsByEmail =>
    new RemoteCreateClinicalDocsByEmail(
      makeApiUrl('/clinicalDocs'),
      makeHttpClient(),
    );

export const makeReduxCreateClinicalDocsByEmail =
  (): UsecaseReduxCreateClinicalDocsByEmail =>
    new ReduxCreateClinicalDocsByEmail();
