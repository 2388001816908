import {
  AuthTypes,
  iActionAuthGetACL,
  iActionAuthGetACLFailed,
  iActionAuthGetACLSuccess,
} from '~/domain/interfaces/redux/auth/Acl';

import { GetACL } from '~/domain/usecases/permissions/redux/GetACL';

export const AuthGetACL = (params: GetACL.Params): iActionAuthGetACL => ({
  type: AuthTypes.GET_ACL,
  payload: params,
});

export const AuthGetACLSuccess = (
  params: GetACL.Model,
): iActionAuthGetACLSuccess => ({
  type: AuthTypes.GET_ACL_SUCCESS,
  payload: params,
});

export const AuthGetACLFailed = (): iActionAuthGetACLFailed => ({
  type: AuthTypes.GET_ACL_FAILED,
});
