import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { iStore } from '~/domain/interfaces/models';
import { ClinicalDocument } from './Lists';
import { DocsEmpty } from '../componentEmpty';
import { translator } from '../i18n';

const ListClinicalDocument: React.FC = () => {
  useEffect(() => {
    makeReduxDataPagination().set([]);
    // return () => {
    //   makeReduxDataPagination().set([]);
    // };
  }, []);
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);

  // if (dataPagination.length === 0 && loading) {
  //   return <ClinicalDocuments dataList={dataPagination} />;
  // }
  // if (dataPagination.length === 0 && !loading) {
  //   return (
  //     <DocsEmpty
  //       message="Seus documentos clínicos
  //     (receitas, atestados etc)
  //     aparecerão aqui. No momento
  //     você não tem nenhum"
  //     />
  //   );
  // }
  return dataPagination.length === 0 && !loading ? (
    <DocsEmpty
      message={translator(
        'Seus documentos clínicos (receitas, atestados etc) aparecerão aqui. No momento você não tem nenhum',
      )}
    />
  ) : (
    <ClinicalDocument dataList={dataPagination} />
  );

  // return dataPagination.length ? (
  //   <ClinicalDocuments dataList={dataPagination} />
  // ) : null;

  // (
  //   <DocsEmpty
  //     message="Seus documentos clínicos
  //     (receitas, atestados etc)
  //     aparecerão aqui. No momento
  //     você não tem nenhum"
  //   />
  // );
};

export default ListClinicalDocument;
