import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { Button } from '../UI';

import { Container, Link as Clink } from './styles/StyledGenerateLink';
import { translator } from '../i18n';

interface ownProps {
  link: string;
}

const GenerateLink: React.FC<ownProps> = ({ link }) => {
  const copyMessage = translator('Copiar');
  const copiedMessage = translator('Copiado!');

  const [copy, setCopy] = useState<string>(copyMessage);

  function copyText() {
    setCopy(copiedMessage);
    document.execCommand('copy', true, link);
    navigator.clipboard.writeText(link);

    setTimeout(() => {
      setCopy(copyMessage);
    }, 2000);
  }

  return (
    <Container>
      <Clink>
        <Tooltip title={link} placement="top" arrow>
          <span id="textInput">{link}</span>
        </Tooltip>
      </Clink>
      <Button
        id="copy"
        className="copy"
        variant={copy === copyMessage ? 'secundary' : 'primary'}
        rounded
        height="40px"
        size="small"
        onClick={copyText}
      >
        {copy}
      </Button>
      {/* <Button
        className="send"
        variant="secundary"
        rounded
        height="48px"
        size="medium"
      >
        Enviar por e-mail
      </Button> */}
    </Container>
  );
};

export default GenerateLink;
