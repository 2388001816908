import React from 'react';
import { useLocation } from 'react-router';
import { iGuestData } from '~/domain/interfaces/models/GuestData';
import {
  Content,
  Title,
  Container,
  InformationGuest,
  MessageGuest,
  GetOut,
} from './styles/StyledReadGuest';

import { History } from '~/main/routes';
import { Button } from '~/presentation/components/UI';

interface locationParams {
  isMicOn: boolean;
  isCamOn: boolean;
  guestData: iGuestData;
}

const ReadyGuest: React.FC = () => {
  const { guestData, isMicOn, isCamOn } = useLocation<locationParams>().state;

  console.log('ison?: ', isCamOn, isMicOn);

  function pushTo(path: string, state?: any) {
    return state
      ? History.getHistory().push(path, state)
      : History.getHistory().push(path);
  }

  return (
    <Container>
      <Content>
        <Title>
          <h1>Pronto!</h1>
        </Title>
        <InformationGuest>
          <h3>Você já pode participar do atendimento</h3>
        </InformationGuest>
        <MessageGuest>
          <text>Clique em entrar para iniciar</text>
        </MessageGuest>
        <GetOut>
          <Button
            variant="primary"
            rounded
            size="large"
            onClick={() => {
              window.onpopstate = null;
              pushTo('/conf', {
                appointmentId: guestData.appointment.id,
                guestData,
                isCamOn,
                isMicOn,
              });
            }}
          >
            Entrar
          </Button>
        </GetOut>
      </Content>
    </Container>
  );
};

export default ReadyGuest;
