import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import context from '~/infra/context';
import { Container, Name, Role, Actions, Exit } from './style/ProfileNavigator';

// import { I18n, translator } from '../i18n';
// import { iUserInformation } from '../formPerfilData';
import { iStore } from '~/domain/interfaces/models';
import { I18n, translator } from '~/presentation/components/i18n';
import { iUserInformation } from '~/presentation/components/formPerfilData';
import { Button } from '~/presentation/components/UI';
import { AvatarWithUploader } from '~/presentation/components/UI/avatar';

interface OwnProps {
  nav: Function;
  active: number;
}

interface iProps {
  userId: string;
}

const PerfilNavigator: React.FC<OwnProps> = ({ nav, active }) => {
  const { mainRole } = useSelector(
    (states: iStore) => states.users.selected.user,
  );
  const idAuth = useSelector((states: iStore) => states.auth.info.user?.id);
  const { administrators, organizers, professionals, user } = useSelector(
    (states: iStore) => states.users.selected,
  );

  const ProfileData: React.FC = () => {
    return (
      <Button
        id="button_profileData"
        rounded
        variant="secundary"
        onClick={() => nav(1)}
        active={active === 1}
      >
        {translator('Dados perfil')}
      </Button>
    );
  };

  const ChangePassword: React.FC = () => {
    return (
      <Button
        id="button_changePassword"
        rounded
        variant="secundary"
        onClick={() => nav(2)}
        active={active === 2}
        // TODO: Temporário enquanto não possibilita atualizar senha de outros usuários sem senha atual
        disabled
      >
        {translator('Mudar senha')}
      </Button>
    );
  };

  const ProfessionalData: React.FC = () => {
    if (professionals.length > 0) {
      return (
        <Button
          id="button_professional"
          rounded
          variant="secundary"
          onClick={() => nav(3)}
          active={active === 3}
        >
          {translator('Profissional')}
        </Button>
      );
    }
    return <div />;
  };

  // TODO: Criar componente de Permissões e adicioná-lo ao Profile Navigator para Organizador e Administrador
  // const Permission: React.FC = () => {
  //   if (organizers.length > 0) {
  //     return (
  //       <Button
  //         id="button_permission"
  //         rounded
  //         variant="secundary"
  //         onClick={() => nav(5)}
  //         active={active === 5}
  //       >
  //         {translator('Permissão')}
  //       </Button>
  //     );
  //   }
  //   if (professionals.length > 0) {
  //     return (
  //       <Button
  //         id="button_permission"
  //         rounded
  //         variant="secundary"
  //         onClick={() => nav(5)}
  //         active={active === 5}
  //       >
  //         {translator('Permissão')}
  //       </Button>
  //     );
  //   }
  //   return <div />;
  // };

  const DeleteAccount: React.FC = () => {
    if (!(administrators.length > 0)) {
      return (
        <Button
          id="button_deleteAccount"
          rounded
          variant="secundary"
          onClick={() => nav(4)}
          active={active === 4}
        >
          {translator('Excluir conta')}
        </Button>
      );
    }
    return <div />;
  };

  return (
    <Container>
      <AvatarWithUploader
        fullName={`${user.firstName} ${user.lastName}`}
        src={user.avatar!}
        size="158px"
      />
      <Name>{`${user.firstName} ${user.lastName}`}</Name>
      <Role>{mainRole}</Role>
      <Actions>
        <ProfileData />
        <ChangePassword />
        <ProfessionalData />
        {/* <Permission /> */}
        <DeleteAccount />
      </Actions>
    </Container>
  );
};

export default PerfilNavigator;
