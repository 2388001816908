import { DownloadTranscription } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteDownloadTranscription implements DownloadTranscription {
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadTranscription.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<DownloadTranscription.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  download = async (
    params: DownloadTranscription.Params,
  ): Promise<DownloadTranscription.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url.replace('{appointmentId}', params.appointmentId)}`,
      method: 'get',
      responseType: 'blob',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
