import { DashboardTypes } from '~/domain/interfaces/redux/dashboard/types';
import { iDashboard } from '~/domain/interfaces/models';
import { DashboardActions } from './actions';

export const initialState: iDashboard = {
  selectedUnit: -1,
  products: {
    totalItems: '0',
    active: false,
    start: new Date(),
    end: new Date(),
    month: [0],
    year: [0],
    appointments: {
      active: false,
      available: 0,
      used: 0,
      diff: 0,
    },
    biometry: {
      active: false,
      available: 0,
      used: 0,
      diff: 0,
    },
    blockchain: {
      active: false,
      available: 0,
      used: 0,
      diff: 0,
    },
    prescriptions: {
      active: false,
      available: 0,
      used: 0,
      diff: 0,
    },
    preservation: {
      active: false,
      available: 0,
      used: 0,
      diff: 0,
    },
    storage: {
      active: false,
      available: 0,
      used: 0,
      diff: 0,
    },
  },
  units: {
    results: [],
    totalItems: '',
  },
};

const reducer = (
  state = initialState,
  action: DashboardActions,
): iDashboard => {
  switch (action.type) {
    case DashboardTypes.GET_PRODUCTS:
      return { ...state };
    case DashboardTypes.GET_PRODUCTS_SUCCESS:
      return { ...state, products: { ...state.products, ...action.payload } };
    case DashboardTypes.GET_PRODUCTS_FAILED:
      return { ...state };
    case DashboardTypes.GET_UNITS:
      return { ...state };
    case DashboardTypes.GET_UNITS_SUCCESS:
      return { ...state, units: { ...state.units, ...action.payload } };
    case DashboardTypes.GET_UNITS_FAILED:
      return { ...state };
    case DashboardTypes.SETUP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default reducer;
