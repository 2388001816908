import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { RegisteredProfessional } from './Lists';
import { iListRegisteredProfessional } from './interface';
import { ConnectProfessional } from './mapper/MapperListProfessional';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { ListEmpty } from '../componentEmpty';
import { iStore } from '~/domain/interfaces/models';
import { translator } from '../i18n';

export interface pageProps {
  data?: iListRegisteredProfessional[];
}

const ListRegisteredProfessional: React.FC<pageProps> = ({ data }) => {
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );
  useEffect(() => {
    makeReduxDataPagination().set([]);
    // return () => {
    // };
  }, [data]);

  const { loading } = useSelector((store: iStore) => store.users);

  return dataPagination.length === 0 && !loading ? (
    <ListEmpty
      message={translator(
        'Os profissionais cadastrados aparecerão aqui. No momento não há nenhum',
      )}
    />
  ) : (
    <RegisteredProfessional dataList={dataPagination} />
  );
};

export default ListRegisteredProfessional;
