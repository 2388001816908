import { DeleteProfessionalSpecialty } from '~/domain/usecases/professionalList/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
  Conflict,
} from '~/domain/errors';

export class RemoteDeleteProfessionalSpecialty
  implements DeleteProfessionalSpecialty
{
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteProfessionalSpecialty.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<DeleteProfessionalSpecialty.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  delete = async (
    params: DeleteProfessionalSpecialty.Params,
  ): Promise<DeleteProfessionalSpecialty.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.professionalId}/specialties/${params.specialtyId}`,
      method: 'delete',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
      case HttpStatusCode.forbidden:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.conflict:
        throw new Conflict();
      default:
        throw new UnexpectedError();
    }
  };
}
