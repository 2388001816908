import React from 'react';

import { Status } from './styles';

interface BarStatusAppointmentProps {
  status: string;
}

const BarStatusAppointment: React.FC<BarStatusAppointmentProps> = ({
  status,
}) => {
  const statusTranslations: Record<string, string> = {
    INPROGRESS: 'Em andamento',
    CANCELED: 'Cancelado',
    WAITING: 'Aguardando',
    SCHEDULED: 'Agendado',
    ENDED: 'Finalizado',
  };

  return <Status status={status}>{statusTranslations[status]}</Status>;
};

export default BarStatusAppointment;
