import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { GetAppointmentIdRecording } from '~/domain/usecases/appointment/remote';
import { makeRemoteGetAppointmentIdRecording } from '~/main/factories/usecases/appointment/GetAppointmentIdRecording';
import { makeRemoteGetAppointmentRecordingDownload } from '~/main/factories/usecases/appointment/GetAppointmentRecordingDownload';
import { Container, Header, ListRecording } from './styles/StyledMediaView';
import { Button } from '~/presentation/components/UI';
import MediaViewItem from './MediaViewItem';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { DocsEmpty } from '~/presentation/components/componentEmpty';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface iParamsTypes {
  appointmentId: string;
}

const Media: React.FC = () => {
  const [recordings, setRecordings] =
    useState<GetAppointmentIdRecording.Model>();

  const [recordingDownload, setRecordingDownload] = useState<number>(0);

  const { appointmentId } = useLocation<iParamsTypes>().state;
  useEffect(() => {
    const getRecording = async () => {
      const response = await makeRemoteGetAppointmentIdRecording()
        .getRecordings({
          appointmentId: Number(appointmentId),
        })
        .catch(e => {
          console.error('error on get recordings', e);
        });

      if (response) setRecordings(response);
    };
    getRecording();
  }, [appointmentId]);

  const emptyRecording = translator('Você não tem nenhuma gravação.');

  const download = async (recording: number) => {
    console.log('Valor do recordingId: ', recording);

    try {
      const { url } =
        await makeRemoteGetAppointmentRecordingDownload().getRecordingsDownload(
          {
            appointmentId: Number(appointmentId),
            recordingId: recording,
          },
        );

      const link = document.createElement('a');
      link.href = url;
      link.click();

      AlertMessage({
        message: intl.formatMessage({ id: 'Seu vídeo está sendo baixado!' }),
        type: 'success',
      });
    } catch (e) {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Erro ao fazer download!',
        }),
        type: 'danger',
      });
      console.error(e);
    }
  };

  function renderVideos() {
    return recordings?.recordings.map((item, index) => (
      <MediaViewItem
        id={item.id}
        fileName={item.token}
        size={item.size}
        date={new Date().toLocaleDateString(getLocale())}
        download={download}
      />
    ));
  }

  return (
    <>
      {recordings?.recordings.length ? (
        <>
          <Container>
            <Header>
              <ListRecording>Lista de gravações:</ListRecording>
            </Header>
            <div>{renderVideos()}</div>
          </Container>
        </>
      ) : (
        <DocsEmpty message={emptyRecording} />
      )}
    </>
  );
};

export default Media;
