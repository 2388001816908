import { ColumnDef } from '@tanstack/react-table';
import { SortButton, Typography } from '@wisecare/design-system-web';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import {
  DiagnosisReportListRecords,
  Status,
} from '~/domain/usecases/diagnosisReport/remote';
import { IconFlag } from '~/presentation/base/icons';
import { Popover } from '~/presentation/components/UI/popover';
import { priorityMap } from '~/utils/mapPriority';
import {
  calculateAgeLaudo,
  formatISODateTimeLaudo,
} from '~/utils/tableDateLaudo';
import Actions from './actions';
import { Information, Priority } from './styles';
import { findLastOccurrence } from '~/utils/laudoLastOccurrence';

export const columns: ColumnDef<DiagnosisReportListRecords>[] = [
  {
    accessorKey: 'consultant',
    header: ({ column }) => <SortButton column={column}>Paciente</SortButton>,
    cell: ({ row }) => {
      const data = row.getValue(
        'consultant',
      ) as DiagnosisReportListRecords['consultant'];

      const value = row.original;

      const consultantName = data ? `${data?.firstName} ${data?.lastName}` : '';

      const consultantExternalName = value?.consultantExternal
        ? `${value?.consultantExternal?.fullname}`
        : '';

      return (
        <Information>
          <Typography variant="b4_14regular">
            {consultantName.length > 0
              ? consultantName
              : consultantExternalName}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {calculateAgeLaudo(
              data?.birthdate ?? value?.consultantExternal?.birthdate,
            )}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'modality',
    header: ({ column }) => {
      return <SortButton column={column}>Modalidade do laudo</SortButton>;
    },
    cell: ({ row }) => {
      const modality = row.getValue(
        'modality',
      ) as DiagnosisReportListRecords['modality'];

      return <Typography variant="b4_14regular">{modality}</Typography>;
    },
  },
  {
    accessorKey: 'requester',
    header: ({ column }) => (
      <SortButton column={column}>Solicitante</SortButton>
    ),
    cell: ({ row }) => {
      const requester = row.getValue(
        'requester',
      ) as DiagnosisReportListRecords['requester'];

      const rowOriginal = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">{requester?.fullname}</Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {rowOriginal.healthUnit.name}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'specialist',
    header: ({ column }) => (
      <SortButton column={column}>Designado para</SortButton>
    ),
    cell: ({ row }) => {
      const specialist = row.getValue(
        'specialist',
      ) as DiagnosisReportListRecords['specialist'];

      const rowOriginal = row.original;

      return (
        <Information>
          <Typography variant="b4_14regular">
            {specialist?.fullname ?? '-'}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {rowOriginal?.specialty.name}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'history',
    header: ({ column }) => (
      <SortButton column={column}>Solicitado em</SortButton>
    ),
    cell: ({ row }) => {
      const history = row.getValue(
        'history',
      ) as DiagnosisReportListRecords['history'];

      const lastOccurence = findLastOccurrence(history, 'SUBMITTED');

      const lastOccurrenceDate = formatISODateTimeLaudo(
        lastOccurence?.timestamp,
      );

      return (
        <Information>
          <Typography variant="b4_14regular">
            {lastOccurrenceDate.date}
          </Typography>
          <Typography variant="b4_14regular" style={{ color: '#656A6E' }}>
            {lastOccurrenceDate.hour}
          </Typography>
        </Information>
      );
    },
  },
  {
    accessorKey: 'priority',
    header: ({ column }) => <SortButton column={column}>Prioridade</SortButton>,
    cell: ({ row }) => {
      const priority = row.getValue(
        'priority',
      ) as DiagnosisReportListRecords['priority'];

      return (
        <Priority priority={priority}>
          <IconFlag />
          {priorityMap(priority)}
        </Priority>
      );
    },
  },
  {
    id: 'actions',
    enableHiding: true,
    cell: ({ row }) => {
      const value = row.original;

      // const professional_id = getProfessionalInfo();

      // const handleGoToDetails = (id: number, edit = false) => {
      //   if (edit) {
      //     history.push(`/diagnosis/${id}`, {
      //       edit,
      //     });
      //     return;
      //   }

      //   history.push(`/diagnosis/${id}`);
      // };

      const store: iStore = storeDev.getState();
      const { role } = store.auth.selectUser;

      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          {role !== 'CON' ? (
            <Actions documentId={value.id} actions={value.actions} />
          ) : (
            <Actions
              documentId={value.id}
              actions={{
                download: true,
              }}
            />
          )}
        </div>
      );
    },
  },
];
