import { ListOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux/ListOnCallSpecialist';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  OnCallSpecialistTypes,
} from '~/domain/interfaces/redux/onCallSpecialist/list';

export const listRequest = (
  payload: ListOnCallSpecialist.Params,
): iActionList => ({
  type: OnCallSpecialistTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListOnCallSpecialist.Model,
): iActionListSuccess => ({
  type: OnCallSpecialistTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: OnCallSpecialistTypes.LIST_FAILED,
});
