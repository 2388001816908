import {
  AppointmentTypes,
  iActionDeleteFailed,
  iActionStop,
  iActionStopSuccess,
} from '~/domain/interfaces/redux/appointment/stop';

import { StopAppointment } from '../../../../../domain/usecases/appointment/redux';

export const stopRequest = (params: StopAppointment.Params): iActionStop => ({
  type: AppointmentTypes.STOP,
  payload: params,
});

export const stopSuccess = (): iActionStopSuccess => ({
  type: AppointmentTypes.STOP_SUCCESS,
});

export const stopFailed = (): iActionDeleteFailed => ({
  type: AppointmentTypes.STOP_FAILED,
});
