import { Dispatch } from 'redux';
import { GetAllUsers } from '~/domain/usecases/users/redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/users/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/users/getAll';

export class ReduxGetAllUsers implements GetAllUsers {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllUsers.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
