import React from 'react';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Body,
  ButtonDiv,
  Container,
  FirstMessage,
  Header,
  RadioBox,
  Left,
  Right,
} from './styles/RateOurServiceStyled';

import {
  IconSignedDocuments,
  RateUsOne,
  RateUsTwo,
} from '~/presentation/base/icons/index';
import { Button } from '~/presentation/components/UI';

const RateOurService: React.FC = () => {
  return (
    <Container>
      <Left>
        <RateUsOne />
      </Left>
      <Body>
        <Header>
          <IconSignedDocuments width="45px" height="45px" />
          <span>Atendimento finalizado</span>
        </Header>
        <FirstMessage>Como você avalia seu atendimento?</FirstMessage>
        <RadioBox>
          <RadioGroup row>
            <FormControlLabel
              value="muitoRuim"
              control={<Radio size="small" color="primary" />}
              label="Muito ruim"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="ruim"
              control={<Radio size="small" color="primary" />}
              label="Ruim"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="normal"
              control={<Radio size="small" color="primary" />}
              label="Normal"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="bom"
              control={<Radio size="small" color="primary" />}
              label="Bom"
              labelPlacement="bottom"
            />
            <FormControlLabel
              value="muitoBom"
              control={<Radio size="small" color="primary" />}
              label="Muito bom"
              labelPlacement="bottom"
            />
          </RadioGroup>
        </RadioBox>
        <ButtonDiv>
          <Button rounded variant="secundary" size="bigger" height="50px">
            Finalizar
          </Button>
        </ButtonDiv>
      </Body>
      <Right>
        <RateUsTwo width="200px" height="310px" />
      </Right>
    </Container>
  );
};

export default RateOurService;
