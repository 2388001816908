import { GetAllProfession } from '~/domain/usecases/profession/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  NotFound,
  InvalidCredentialsError,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteGetAllProfession implements GetAllProfession {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllProfession.Model>;

  constructor(url: string, httpClient: HttpClient<GetAllProfession.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getAll = async (
    params: GetAllProfession.Params,
  ): Promise<GetAllProfession.Model> => {
    let query = '';
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
