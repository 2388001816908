import { iGetAuditRecord } from '~/domain/interfaces/models/AuditRecord';
import { AuditRecordTypes } from '~/domain/interfaces/redux/auditRecord';
import { AuditRecordActions } from './actions';

export const initialState: iGetAuditRecord = {
  loading: false,
  data: {} as iGetAuditRecord['data'],
};

const reducer = (
  state = initialState,
  action: AuditRecordActions,
): iGetAuditRecord => {
  switch (action.type) {
    case AuditRecordTypes.GET_AUDIT_RECORD:
      return { ...state, loading: true };
    case AuditRecordTypes.GET_AUDIT_RECORD_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case AuditRecordTypes.GET_AUDIT_RECORD_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
