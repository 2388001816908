/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import { IconCheck } from '~/presentation/base/icons';
import { useMyAppointmentsFilter } from '~/presentation/hooks/filters/myAppointments';
import { Button } from '../UI';
import {
  Body,
  Container,
  FilterChips,
  FilterChipsContainer,
  Footer,
  RestoreFilters,
  SectionFilter,
  SectionFilterLabel,
} from './styles/StyledFilterMyAppointments';

const Chip: React.FC<{ onClick?: () => void; active?: boolean }> = ({
  active,
  children,
  onClick,
}) => {
  return (
    <FilterChips onClick={onClick} active={active}>
      {active && <IconCheck />}
      {children}
    </FilterChips>
  );
};

const FilterMyAppointments: React.FC = () => {
  const {
    filters,
    healthPlans,
    hasAnyFilterSelected,
    isFilterActive,
    setFilters,
    handleApplyFilters,
    handleResetFilters,
    handleSelectAllHealthPlans,
  } = useMyAppointmentsFilter();

  return (
    <Container>
      <Body>
        <SectionFilter>
          <SectionFilterLabel>Tipos de Consulta</SectionFilterLabel>
          <FilterChipsContainer>
            <Chip
              active={filters.modality.includes('REMOTE')}
              onClick={() => setFilters('modality', 'REMOTE')}
            >
              Teleatendimento
            </Chip>
            <Chip
              active={filters.modality.includes('INPERSON')}
              onClick={() => setFilters('modality', 'INPERSON')}
            >
              Presencial
            </Chip>
          </FilterChipsContainer>
        </SectionFilter>
        <SectionFilter>
          <SectionFilterLabel>Prioridade de atendimento</SectionFilterLabel>
          <FilterChipsContainer>
            <Chip
              active={filters.priority.includes('NORMAL')}
              onClick={() => setFilters('priority', 'NORMAL')}
            >
              Normal
            </Chip>
            <Chip
              active={filters.priority.includes('PRIORITY')}
              onClick={() => setFilters('priority', 'PRIORITY')}
            >
              Prioridade
            </Chip>
          </FilterChipsContainer>
        </SectionFilter>
        <SectionFilter>
          <SectionFilterLabel>Status</SectionFilterLabel>
          <FilterChipsContainer>
            <Chip
              active={filters.status.includes('SCHEDULED')}
              onClick={() => setFilters('status', 'SCHEDULED')}
            >
              Agendado
            </Chip>
            <Chip
              active={filters.status.includes('INPROGRESS')}
              onClick={() => setFilters('status', 'INPROGRESS')}
            >
              Em andamento
            </Chip>
            <Chip
              active={filters.status.includes('PENDING')}
              onClick={() => setFilters('status', 'PENDING')}
            >
              Aguardando
            </Chip>
            <Chip
              active={filters.status.includes('ENDED')}
              onClick={() => setFilters('status', 'ENDED')}
            >
              Finalizado
            </Chip>
            <Chip
              active={filters.status.includes('CANCELED')}
              onClick={() => setFilters('status', 'CANCELED')}
            >
              Cancelado
            </Chip>
          </FilterChipsContainer>
        </SectionFilter>
        <SectionFilter>
          <SectionFilterLabel>Convênio</SectionFilterLabel>
          <FilterChipsContainer>
            <Chip
              active={filters.healthPlan.length === healthPlans.length}
              onClick={handleSelectAllHealthPlans}
            >
              Todos
            </Chip>
            {healthPlans.map(healthPlan => (
              <Chip
                key={healthPlan.id}
                active={filters.healthPlan.includes(healthPlan.id)}
                onClick={() => setFilters('healthPlan', healthPlan.id)}
              >
                {healthPlan.fullname}
              </Chip>
            ))}
          </FilterChipsContainer>
        </SectionFilter>
      </Body>
      <Footer>
        <RestoreFilters
          onClick={handleResetFilters}
          size="medium"
          variant="secundary"
        >
          Restaurar padrões
        </RestoreFilters>
        <Button size="small" onClick={handleApplyFilters}>
          Aplicar filtros
        </Button>
      </Footer>
    </Container>
  );
};

export default FilterMyAppointments;
