import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CancelAppointment as UseCaseRemoteCancelAppointment } from '~/domain/usecases/appointment/remote';
import { CancelAppointment as UseCaseReduxCancelAppointment } from '~/domain/usecases/appointment/redux';

import { RemoteCancelAppointment } from '~/data/repository/appointment/RemoteCancelAppointment';
import { ReduxCancelAppointment } from '~/data/store/reducer/appointment/usecases/ReduxCancelAppointment';

/**
 * send request via API.
 */
export const makeRemoteCancelAppointment = (): UseCaseRemoteCancelAppointment =>
  new RemoteCancelAppointment(makeApiUrl('/appointments'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCancelAppointment = (): UseCaseReduxCancelAppointment =>
  new ReduxCancelAppointment();
