import styled from 'styled-components';
import { FolderIcon } from '~/presentation/base/icons';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-height: 240px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text {
    max-width: 300px;
    text-align: center;

    color: #b7b7b7;
    margin-top: 8px;
  }
`;

export const Icon = styled(FolderIcon)`
  width: 100%;
  height: 220px;
`;
