import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/healthUnits/getAll';

import { GetAllHealthUnits } from '~/domain/usecases/healthUnits/remote';
import { makeRemoteGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';

import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/healthUnits/actions/getAll';

export function* onGetAllRequest(action: iActionGetAll) {
  const remoteGetAllHealthUnits = makeRemoteGetAllHealthUnits();

  try {
    const response: GetAllHealthUnits.Model = yield call(
      remoteGetAllHealthUnits.getAllHealthUnits,
      action.payload,
    );

    yield put(getAllSuccess(response));
  } catch (e) {
    yield put(getAllFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllFailed() {}
