import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: #f8f8f8;
`;

export const ButtonToOpenSideSheet = styled.button`
  width: 2.5rem;
  height: 2.5rem;

  border: none;
  border-radius: 0.31rem;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: #ecedee;
  }
`;
