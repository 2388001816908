import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Information = styled.div`
  width: 100%;
  color: #7a7a7a;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  strong {
    color: black;
  }
  #text-one {
    margin-bottom: 20px;
  }

  #text-three {
    margin-bottom: 20px;
  }

  #name_patient {
    color: #004ba5;
  }

  #hour {
    color: #004ba5;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
  margin: 20px;
  gap: 20px;

  .links {
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 12px;
  }
`;

export const Image = styled.img`
  margin-right: 2px;
`;

export const Content = styled.form`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const SizeVariant = css`
  text-decoration: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  :hover {
    color: ${shade(0.2, '#1756E8')};
  }
`;

export const Forgotpass = styled.a`
  ${SizeVariant};
  font-size: 16px;
  margin-top: 10px;
`;
