import React, { useEffect, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { History } from '~/main/routes';
import Input from '~/presentation/components/UI/input/index';
import { translator } from '~/presentation/components/i18n';
import { Button } from '~/presentation/components/UI';
import { Icon } from '~/presentation/components/icon';
import { schemaGuest } from '~/validation/validators/guest/Guest';
import {
  GuestV4H,
  IconPadlock,
  IconLogoRNP,
  IconLogoNasNuvens,
} from '~/presentation/base/icons';
import { UpdateAppointmentCompanion as iCompanionCode } from '~/domain/usecases/appointmentCompanion/remote';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { V4hSpin } from '~/presentation/components/spin';
import { FooterImgs } from '../LoginPage/styles/LoginPageStyled';
import {
  Container,
  Header,
  Footer,
  Body,
  Title,
  Message,
} from './styles/StyledGuestPage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface ownProps {
  authenticationCode: iCompanionCode;
  code: string;
}

const Guest: React.FC<ownProps> = (props): JSX.Element => {
  const [code, setCode] = useState({
    code: '',
  });
  const [loading, setLoading] = useState<boolean>(false);
  // TODO: Atualizar urlCode, URL exemplo abaixo
  // "atendimento2.dev.v4h.cloud/invite/professional/hrMEVqIb"
  const urlCode = props.code;
  const [error, setError] = useState<boolean>(false);

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGuest),
    defaultValues: {
      companionCode: '',
    },
  });

  const footerImages = [
    {
      icon: IconLogoRNP,
      name: 'rnp-icon',
    },
    {
      icon: IconLogoNasNuvens,
      name: 'nasnuvens-icon',
    },
  ];

  const authenticationCode = async () => {
    const dataToSend: iCompanionCode.Params = {
      companionCode: urlCode || code.code,
    };
    setLoading(true);
    try {
      const validate = schemaGuest.parse(dataToSend);
      console.log('###validate: ', validate);

      await props.authenticationCode
        .get({
          companionCode: dataToSend.companionCode,
        })
        .then(response => {
          setLoading(false);
          History.getHistory().push('/guest/identification', {
            schedule: response,
            code: dataToSend.companionCode,
          });
        })
        .catch(err => {
          setLoading(false);
          AlertMessage({
            message: intl.formatMessage({ id: 'Código não encontrado.' }),
            type: 'info',
          });
          setError(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setCode({ ...code, [event.target.name]: event.target.value });
    setValue('companionCode', event.target.value);
  }

  const onSubmit = handleSubmit(data => {
    setCode({ ...code, ...data });
  });

  useEffect(() => {
    if (urlCode && urlCode !== '') {
      setError(true);
      setCode({ code: urlCode });
      setTimeout(() => {
        authenticationCode();
      }, 1000);
    }
  }, []);

  return (
    <Container onSubmit={onSubmit}>
      <Header>
        <Icon src={GuestV4H} width="300px" height="120px" />
        <Title>Acesso para convidado</Title>
        <Message>
          <span>
            Insira o Código de atendimento que você recebeu por e-mail.
          </span>
        </Message>
      </Header>
      <Body>
        <Input
          disabled={error}
          name="code"
          autoFocus
          width="300px"
          label={translator('Código')}
          placeholder={translator('Digite o código do atendimento')}
          value={urlCode || code.code}
          onChange={e => handleChange(e)}
          register={() => register('companionCode')}
          error={Boolean(errors.companionCode)}
          message={errors.companionCode?.message}
          required
          icon={IconPadlock}
        />
        {loading ? (
          <V4hSpin fullScreen={false} size="40px" />
        ) : (
          <Button
            // style={{ width: '15%' }}
            rounded
            size="bigger"
            height="48px"
            type="submit"
            onClick={() => authenticationCode()}
          >
            Entrar
          </Button>
        )}
      </Body>
      <Footer>
        <FooterImgs>
          {footerImages.map(image => (
            <image.icon data-testid={image.name} />
          ))}
        </FooterImgs>
      </Footer>
    </Container>
  );
};

export default Guest;
