import { takeLatest } from 'redux-saga/effects';
import {
  onGetInvoiceByOrg,
  onGetInvoiceByOrgFailed,
  onGetInvoiceByOrgSuccess,
} from './GetInvoiceByOrg';

import {
  onGetInvoiceService,
  onGetInvoiceServiceSuccess,
  onGetInvoiceServiceFailed,
} from './GetInvoiceService';

import {
  onGetBillingDetailed,
  onGetBillingDetailedSuccess,
  onGetBillingDetailedFailed,
} from './GetBillingDetailed';

import {
  onGetBillingService,
  onGetBillingServiceSuccess,
  onGetBillingServiceFailed,
} from './GetBillingService';

import { BillingTypes } from '~/domain/interfaces/redux/billing/types';

const tasks = [
  takeLatest(BillingTypes.GET_INVOICE_BY_ORG, onGetInvoiceByOrg),
  takeLatest(BillingTypes.GET_INVOICE_BY_ORG_SUCCESS, onGetInvoiceByOrgSuccess),
  takeLatest(BillingTypes.GET_INVOICE_BY_ORG_FAILED, onGetInvoiceByOrgFailed),

  takeLatest(BillingTypes.GET_INVOICE_SERVICE, onGetInvoiceService),
  takeLatest(
    BillingTypes.GET_INVOICE_SERVICE_SUCCESS,
    onGetInvoiceServiceSuccess,
  ),
  takeLatest(
    BillingTypes.GET_INVOICE_SERVICE_FAILED,
    onGetInvoiceServiceFailed,
  ),

  takeLatest(BillingTypes.GET_BILLING_DETAILED, onGetBillingDetailed),
  takeLatest(
    BillingTypes.GET_BILLING_DETAILED_SUCCESS,
    onGetBillingDetailedSuccess,
  ),
  takeLatest(
    BillingTypes.GET_BILLING_DETAILED_FAILED,
    onGetBillingDetailedFailed,
  ),

  takeLatest(BillingTypes.GET_BILLING_SERVICE, onGetBillingService),
  takeLatest(
    BillingTypes.GET_BILLING_SERVICE_SUCCESS,
    onGetBillingServiceSuccess,
  ),
  takeLatest(
    BillingTypes.GET_BILLING_SERVICE_FAILED,
    onGetBillingServiceFailed,
  ),
];

export default tasks;
