import { GetAllClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/redux/GetAllClinicalDocsByCode';
import {
  ClinicalDocsTypes,
  iActionGetAllByCode,
  iActionGetAllByCodeFailed,
  iActionGetAllByCodeSuccess,
} from '~/domain/interfaces/redux/clinicalDocs/getAllByCode';

export const getAllByCodeRequest = (
  payload: GetAllClinicalDocsByCode.Params,
): iActionGetAllByCode => ({
  type: ClinicalDocsTypes.GETALL_BY_CODE,
  payload,
});

export const getAllByCodeSuccess = (
  params: GetAllClinicalDocsByCode.Model,
): iActionGetAllByCodeSuccess => ({
  type: ClinicalDocsTypes.GETALL_BY_CODE_SUCCESS,
  payload: params,
});

export const getAllByCodeFailed = (): iActionGetAllByCodeFailed => ({
  type: ClinicalDocsTypes.GETALL_BY_CODE_FAILED,
});
