import { call, put, select } from 'redux-saga/effects';
import { iActionCreate } from '~/domain/interfaces/redux/users/create';

import {
  createFailed,
  createSuccess,
} from '~/data/store/reducer/users/actions/create';

import { makeRemoteCreateUser } from '~/main/factories/usecases/users/CreateUserFactory';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

export function* onCreate(action: iActionCreate) {
  const user = makeRemoteCreateUser();

  try {
    yield call(user.create, action.payload);
    yield put(createSuccess());
  } catch (e) {
    yield put(createFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateFailed() {}
