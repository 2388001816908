import styled from 'styled-components';

interface iContainer {
  width?: string;
  height?: string;
  color?: string;
}

export const Container = styled.div<iContainer>`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: ${props => props.width || '26px'};
    height: ${props => props.height || '26px'};
  }

  path {
    fill: ${props => props.color};
  }

  rect {
    stroke: ${props => props.color};
  }

  circle {
    fill: ${props => props.color};
  }

  line {
    fill: ${props => props.color};
    stroke: ${props => props.color};
  }

  polygon {
    fill: ${props => props.color};
  }
`;
