import { iOnCallRequester } from '~/domain/interfaces/models/OnCallRequester';
import { OnCallRequesterTypes } from '~/domain/interfaces/redux/onCallRequester/types';
import { OnCallRequesterActions } from './actions';

export const initialState: iOnCallRequester = {
  loading: false,
  records: [],
  metadata: '',
};

const reducer = (
  state = initialState,
  action: OnCallRequesterActions,
): iOnCallRequester => {
  switch (action.type) {
    case OnCallRequesterTypes.LIST:
      return { ...state, loading: true };
    case OnCallRequesterTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OnCallRequesterTypes.LIST_SUCCESS: {
      return { ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
