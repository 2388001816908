import { SocketClient } from '~/data/protocols/socket';
import { JoinWaitingRoom } from '~/domain/usecases/waitingRoom/socket';

export class SocketJoinWaitingRoom implements JoinWaitingRoom {
  private readonly event: string;

  private readonly SocketClient: SocketClient<JoinWaitingRoom.Model>;

  constructor(
    event: string,
    socketClient: SocketClient<JoinWaitingRoom.Model>,
  ) {
    this.event = event;
    this.SocketClient = socketClient;
  }

  join = async (
    params: JoinWaitingRoom.Params,
    fallback?: Function,
  ): Promise<JoinWaitingRoom.Model> => {
    const SocketResponse = await this.SocketClient.emit({
      event: this.event,
      body: params,
    });
    if (fallback) fallback(SocketResponse.body);
    return SocketResponse.body;
  };
}
