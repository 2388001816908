import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { translator } from '~/presentation/components/i18n';
import { ItemPrescription } from '~/presentation/components/simplePrescription';
import { Button, Input } from '~/presentation/components/UI';
import { schemaPrescription } from '~/validation/validators/document/BasicPrescriptionContentValidator';
import { Navigator } from '../register/Navigator';
import { iPrescription, iValidation } from './interface';
import {
  ButtonAdd,
  ContainerButton,
  ContainerChooseDocuments,
  ContainerLeft,
  ContainerRight,
  ContentInputs,
  Dosage,
  Drug,
  ListPrescription,
  MainContainer,
  Posology,
} from './styles/StyledPrescription';

export interface ownProps {
  items: iPrescription[];
  setItems: Function;
  next: (data: iValidation) => any;
  back: (data: iValidation) => any;
  arrId: number;
  setArrId: Function;
  state?: iValidation;
}

const Prescription: React.FC<ownProps> = props => {
  const { errors, handleSubmit, register, setValue, getValues, unregister } =
    useForm({
      mode: 'onChange',
      shouldFocusError: true,
      resolver: zodResolver(schemaPrescription),
      defaultValues: {
        dosage: props.state?.dosage || '',
        name: props.state?.name || '',
        posology: props.state?.posology || '',
      },
    });

  const [arrPrescription, setArrPrescription] = useState<iPrescription>({
    name: '',
    dosage: '',
    posology: '',
  });

  const [updateButton, setUpdateButton] = useState(false);
  const [validation, setValidation] = useState<boolean>(true);
  const [updatePrescriptionId, setUpdatePrescriptionId] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  function clearFields() {
    props.setArrId((prevState: number) => prevState + 1);

    setArrPrescription({ name: '', dosage: '', posology: '' });

    unregister('name');
    unregister('dosage');
    unregister('posology');
  }

  const addPrescription = handleSubmit(data => {
    props.setItems([...props.items, { ...data, id: props.arrId }]);

    clearFields();
  });

  const onSubmit = () => {
    props.next({ drugs: props.items });
  };

  function setPrescription(id: number) {
    const result = props.items.filter(item => item.id === id);
    setUpdatePrescriptionId(id);
    setArrPrescription({
      name: result[0].name,
      dosage: result[0].dosage,
      posology: result[0].posology,
    });
    setValue('name', result[0].name);
    setValue('dosage', result[0].dosage);
    setValue('posology', result[0].posology);
  }

  function updatePrescription() {
    setUpdateButton(false);
    const result = props.items.filter(item => item.id === props.arrId);

    props.setItems((prevState: any) =>
      prevState.map((item: any) => {
        if (item.id === updatePrescriptionId) {
          return {
            ...item,
            ...{
              name: arrPrescription.name,
              dosage: arrPrescription.dosage,
              posology: arrPrescription.posology,
            },
          };
        }
        return item;
      }),
    );

    clearFields();
  }

  useEffect(() => {
    console.log(inputRef.current);
  }, [inputRef]);

  const handleInputFocus = useCallback(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  const onBack = () => props.back({ drugs: props.items });

  return (
    <MainContainer onSubmit={onSubmit}>
      <ContainerChooseDocuments>
        <ContainerLeft>
          <ContentInputs>
            <Drug>
              <Input
                name="name"
                label={`${translator('Droga')}`}
                placeholder={`${translator('Insira o nome da droga')}`}
                onChange={e => {
                  setValue('name', e.target.value);
                  setArrPrescription({
                    ...arrPrescription,
                    [e.target.name]: e.target.value,
                  });
                }}
                register={() => register('name')}
                value={arrPrescription.name}
                error={Boolean(errors.name)}
                message={
                  errors.name?.message ? translator(errors.name?.message) : ''
                }
                ref={inputRef}
                autoFocus
                required
              />
            </Drug>
            <Dosage>
              <Input
                name="dosage"
                label={`${translator('Dosagem')}`}
                placeholder={`${translator('Insira a dosagem')}`}
                onChange={e => {
                  setValue('dosage', e.target.value);
                  setArrPrescription({
                    ...arrPrescription,
                    [e.target.name]: e.target.value,
                  });
                }}
                register={() => register('dosage')}
                error={Boolean(errors.dosage)}
                value={arrPrescription.dosage}
                message={
                  errors.dosage?.message
                    ? translator(errors.dosage?.message)
                    : ''
                }
                required
              />
            </Dosage>
            <Posology>
              <Input
                name="posology"
                label={`${translator('Posologia')}`}
                placeholder={`${translator('Insira a posologia da medicação')}`}
                onChange={e => {
                  setValue('posology', e.target.value);
                  setArrPrescription({
                    ...arrPrescription,
                    [e.target.name]: e.target.value,
                  });
                }}
                register={() => register('posology')}
                value={arrPrescription.posology}
                error={Boolean(errors.posology)}
                message={
                  errors.posology?.message
                    ? translator(errors.posology?.message)
                    : ''
                }
                required
              />
            </Posology>
          </ContentInputs>
          <ButtonAdd>
            {updateButton ? (
              <>
                <p>
                  {`${translator(
                    'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita',
                  )}`}
                </p>
                <Button
                  id="btn_update"
                  size="medium"
                  rounded
                  variant="secundary"
                  onClick={updatePrescription}
                >
                  {'Atualizar >>'}
                </Button>
              </>
            ) : (
              <>
                <p>
                  {`${translator(
                    'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita',
                  )}`}
                </p>
                <Button
                  id="btn_addDrug"
                  size="medium"
                  rounded
                  variant="secundary"
                  onClick={() => {
                    handleInputFocus();
                    addPrescription();
                  }}
                >
                  {`${translator('Adicionar >>')}`}
                </Button>
              </>
            )}
          </ButtonAdd>
        </ContainerLeft>
        <ContainerRight>
          <h2>{`${translator('Drogas Inseridas')}`}</h2>
          <ListPrescription>
            <ItemPrescription
              setContent={props.setItems}
              content={props.items}
              updatePrescription={setPrescription}
              setUpdateButton={setUpdateButton}
            />
          </ListPrescription>
        </ContainerRight>
      </ContainerChooseDocuments>
      <ContainerButton>
        <Navigator
          back={onBack}
          isDisable={props.items.length !== 0 ? !validation : validation}
        />
      </ContainerButton>
    </MainContainer>
  );
};

export default Prescription;
