import { ProfessionList } from '~/domain/usecases/profession/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteProfessionList implements ProfessionList {
  private readonly url: string;

  private readonly httpClient: HttpClient<ProfessionList.Model>;

  constructor(url: string, httpClient: HttpClient<ProfessionList.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  post = async (
    params: ProfessionList.Params,
  ): Promise<ProfessionList.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/list`,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
