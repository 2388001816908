import React from 'react';
import * as datefns from 'date-fns';
import {
  ContainerBody,
  ContainerData,
  ContainerMessage,
} from './styles/StyledBodyChat';

interface contentMessage {
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
  message: {
    content: string;
    date: string;
  };
}

interface propsMessage {
  content: contentMessage[];
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
  userLogged: number;
}

const BodyChat: React.FC<propsMessage> = ({ content, user, userLogged }) => {
  //
  let currentDate = new Date();

  if (content[0] !== undefined) {
    currentDate = new Date(
      datefns.format(new Date(content[0].message.date), 'yyyy/MM/dd'),
    );
  }

  const startDate = (date: Date) => {
    return datefns.format(new Date(date), 'dd/MM/yyyy');
  };

  return (
    <ContainerBody>
      {content?.slice(0).map(item => {
        const newDate = new Date(
          datefns.format(new Date(item.message.date), 'yyyy/MM/dd'),
        );

        const isSame = datefns.compareAsc(currentDate, newDate);

        currentDate = isSame === 0 ? currentDate : newDate;
        return (
          <>
            {isSame !== 0 && (
              <ContainerData>
                <h1>{datefns.format(new Date(currentDate), 'yyyy/MM/dd')}</h1>
              </ContainerData>
            )}
            <div
              style={{
                width: '100%',
                padding: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: `${
                  item.user.id === userLogged ? 'flex-end' : 'flex-start'
                }`,
              }}
            >
              <ContainerMessage
                align={`${
                  item.user.id === userLogged ? 'flex-end' : 'flex-start'
                }`}
                side={item.user.id === userLogged ? 'row-reverse' : 'row'}
              >
                <div className="top-msg">
                  <h1>
                    {`${
                      item.user.id === userLogged ? 'Você' : item.user.firstName
                    }`}
                  </h1>
                  <h1>
                    {datefns.format(new Date(item.message.date), 'HH:mm')}
                  </h1>
                </div>
                <h2>{item.message.content}</h2>
              </ContainerMessage>
            </div>
          </>
        );
      })}
      {content[0] !== undefined && (
        <ContainerData>
          <h1>{startDate(currentDate)}</h1>
        </ContainerData>
      )}
    </ContainerBody>
  );
};

export default BodyChat;
