import { Typography, theme } from '@wisecare/design-system-web';
import styled, { css } from 'styled-components';

export const SendedByContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 11.25rem;

  overflow: hidden;
`;

const textOverflowStyle = css`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SendedByName = styled(Typography)`
  ${textOverflowStyle}
`;
export const SendedBySector = styled(Typography)`
  ${textOverflowStyle};
  text-transform: uppercase;
  color: ${theme.colors.black[4]};
`;
