import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { makeReduxSetData } from '~/main/factories/usecases/appointment/SetDataFactory';
import { iStore } from '~/domain/interfaces/models';

import { ownProps } from '../index';

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { date } = useSelector((state: iStore) => state.appointment);

    if (!date) makeReduxSetData().set({ date: String(new Date()) });

    Component.defaultProps = {
      date: date || new Date(),
      set: (d: string) => {
        makeReduxSetData().set({ date: d });
      },
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}
