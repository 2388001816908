import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AcceptOnCallSpecialist as UsecaseRemoteAcceptOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
// import { AcceptOnCallSpecialist as UsecaseReduxAcceptOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemoteAcceptOnCallSpecialist } from '~/data/repository/onCallSpecialist';
// import { ReduxAcceptOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteAcceptOnCallSpecialist =
  (): UsecaseRemoteAcceptOnCallSpecialist =>
    new RemoteAcceptOnCallSpecialist(
      makeApiUrl(
        '/oncall/{onCallId}/specialists/{specialistId}/requests/{requestId}/ACCEPT',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxAcceptOnCallSpecialist =
  (): UsecaseReduxAcceptOnCallSpecialist =>
    new ReduxAcceptOnCallSpecialist(); */
