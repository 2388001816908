import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/appointmentPriorities/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/appointmentPriorities/getAll';

import { GetAllAppointmentPriorities } from '~/domain/usecases/appointmentPriorities/redux';

export class ReduxGetAllAppointmentPriorities
  implements GetAllAppointmentPriorities
{
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: GetAllAppointmentPriorities.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
