import { GetAll } from '~/domain/usecases/selectedOrg/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteGetAll implements GetAll {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAll.Model>;

  constructor(url: string, httpClient: HttpClient<GetAll.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getAll = async (params: GetAll.Params): Promise<GetAll.Model> => {
    console.log('url request: ', params);
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: params,
    });
    console.log('GetAll orgs request: ', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
