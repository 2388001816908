import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import context from '~/infra/context';
import { Container, Name, Role, Actions, Exit } from './style/ProfileNavigator';

import { iStore } from '~/domain/interfaces/models';
import { I18n, translator } from '~/presentation/components/i18n';
import { iUserInformation } from '~/presentation/components/formPerfilData';
import { Button } from '~/presentation/components/UI';
import { AvatarWithUploader } from '~/presentation/components/UI/avatar';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';

interface OwnProps {
  nav: Function;
  active: number;
}

interface iProps {
  userId: string;
}
const ProfileNavigator: React.FC<OwnProps> = ({ nav, active }) => {
  const { userId } = useParams<iProps>();
  const { id, mainRole } = useSelector(
    (states: iStore) => states.users.selected.user,
  );
  const idAuth = useSelector((states: iStore) => states.auth.info.user?.id);
  const { showModal } = useSelector((store: iStore) => store);
  const { administrators, consultants, organizers, professionals, user } =
    useSelector((states: iStore) => states.users.selected);

  const navigateToPage = (page: number) => {
    if (
      (active === 3 && showModal.specialty) ||
      (active === 1 && showModal.profile)
    ) {
      return makeReduxActiveMessage().active({
        active: 'leaveSpecialty',
        actionOk: () => {
          nav(page);
          closeModal();
        },
        actionCancel: closeModal,
      });
    }

    return nav(page);
  };

  function profileData() {
    return (
      <Button
        id="button_profileData"
        rounded
        variant="secundary"
        onClick={() => navigateToPage(1)}
        active={active === 1}
      >
        {translator('Dados perfil')}
      </Button>
    );
  }

  function professionalData() {
    return (
      professionals.length > 0 && (
        <Button
          id="button_professionalData"
          rounded
          variant="secundary"
          onClick={() => navigateToPage(3)}
          active={active === 3}
        >
          {translator('Profissional')}
        </Button>
      )
    );
  }

  function permission() {
    if (organizers.length > 0) {
      return (
        <Button
          id="button_permission"
          rounded
          variant="secundary"
          onClick={() => navigateToPage(5)}
          active={active === 5}
        >
          {translator('Permissão')}
        </Button>
      );
    }
    if (professionals.length > 0) {
      return (
        <Button
          id="button_permission"
          rounded
          variant="secundary"
          onClick={() => navigateToPage(5)}
          active={active === 5}
        >
          {translator('Permissão')}
        </Button>
      );
    }
    return null;
  }

  function deleteAccount() {
    return (
      <Button
        id="button_deleteAccount"
        rounded
        variant="secundary"
        onClick={() => navigateToPage(4)}
        active={active === 4}
      >
        {translator('Excluir conta')}
      </Button>
    );
  }

  return (
    <Container>
      <AvatarWithUploader
        fullName={`${user.firstName} ${user.lastName}`}
        src={user.avatar!}
        size="158px"
      />
      <Name>{`${user.firstName} ${user.lastName}`}</Name>
      <Role>{mainRole}</Role>
      <Actions>
        {profileData()}
        {/* {professionalData()}
        {permission()}
        {deleteAccount()} */}
      </Actions>
    </Container>
  );
};

export default ProfileNavigator;
