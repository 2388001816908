import React, { useEffect, useState } from 'react';
import { ContainerView, IconHeader, Body, FooterButton } from './styles/style';
import { EyeIcon } from '~/presentation/base/icons';
import { Button } from '../UI';

interface ownProps {
  title: string;
  subtitle: string;
  value: number;
  onClick?: () => void;
}

const OrgUnitProfessional: React.FC<ownProps> = ({
  value,
  title,
  subtitle,
  onClick,
}) => {
  const renderIcons = () => {
    return <IconHeader>{title}</IconHeader>;
  };

  const [v, setV] = useState<number>(0);

  useEffect(() => {
    setV(value);

    // TODO: Desabilitado temporariamente para evitar inconsistências
    // if (v !== value) setV(v + 1);
  }, [v, value]);

  const renderBody = () => {
    return (
      <>
        <Body>
          <div className="value">{v}</div>
        </Body>

        <FooterButton>
          <Button
            variant="secundary"
            icon={EyeIcon}
            color="#005BC8"
            onClick={onClick}
            rounded
          >
            {subtitle}
          </Button>
        </FooterButton>
      </>
    );
  };

  return (
    <ContainerView>
      {renderIcons()}
      {renderBody()}
    </ContainerView>
  );
};

export default OrgUnitProfessional;
