/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-comment-textnodes */
// eslint-disable-next-line react/jsx-one-expression-per-line
import React, { useState } from 'react';
import { Menu, Services } from '~/presentation/components/menu';
import { HeaderSimples } from '~/presentation/components/header';

import { InvoiceIconLighter } from '~/presentation/base/icons';

import { InvoiceAndPayments } from '~/presentation/components/invoiceAndPayments';
import { ServicesAdmin } from '~/presentation/components/ServicesAdmin';
import { AppointmentsReport } from '~/presentation/components/appointmentsReport';
import { translator } from '~/presentation/components/i18n';
import {
  Container,
  Body,
  ContentLeft,
  ContentRight,
  ContainerRight,
  LockScroll,
} from './styles/StyledBilling';

const Billing: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<number>(0);

  const menuOptions = [
    `${translator('Serviços')}`,
    `${translator('Faturas e pagamentos')}`,
    `Relatório de atendimentos`,
  ];

  return (
    <Container>
      <LockScroll>
        <Menu />
        <HeaderSimples
          title={translator('Serviços e faturamentos')}
          icon={InvoiceIconLighter}
        />
      </LockScroll>
      <Body>
        <ContentLeft>
          <Services
            setState={setActiveMenu}
            options={menuOptions}
            active={activeMenu}
          />
        </ContentLeft>
        {activeMenu === 0 && (
          <ContainerRight>
            <ServicesAdmin />
          </ContainerRight>
        )}
        {activeMenu === 1 && (
          <ContentRight>
            <InvoiceAndPayments />
          </ContentRight>
        )}
        {activeMenu === 2 && (
          <ContentRight>
            <AppointmentsReport />
          </ContentRight>
        )}
      </Body>
    </Container>
  );
};

export default Billing;
