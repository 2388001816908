import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { Button } from '~/presentation/components/UI';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { ChangeRoles } from '~/presentation/components/changeRoles';
import { IconCloseButtonModal } from '~/presentation/base/icons';
import { Container } from '../Background';
import { ConfirmCancel } from './styles';

import {
  Body,
  Title,
  ButtonClose,
  Actions,
  Content,
} from './styles/StyledChangeRole';

import { ownProps } from '../interface';
import { translator } from '../../i18n';
import { makeReduxClearConsultant } from '~/main/factories/usecases/consultant/ClearConsultantFactory';

const UserChangeRole: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.userChangeRole;

  const { active, actionOk, actionCancel } = message;

  const { data } = useSelector((store: iStore) => store.message);
  const dataS = useSelector((store: iStore) => store.auth.selectUser);

  const isDisabled = () => {
    return data.orgId === undefined || data.orgId === dataS.orgId;
  };

  const handleActionOk = () => {
    actionOk?.();

    makeReduxClearConsultant().clear();
  };

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Title>
              {translator('Organizações')}
              <ButtonClose id="btn_close" type="button" onClick={actionCancel}>
                <IconCloseButtonModal />
              </ButtonClose>
            </Title>
            <Content>
              <ChangeRoles />
              <Actions>
                <Button
                  id="btn_cancel"
                  rounded
                  variant="secundary"
                  size="small"
                  color="#EC5C52"
                  onClick={actionCancel}
                >
                  {translator('Cancelar')}
                </Button>
                <Button
                  id="btn_confirm"
                  rounded
                  disabled={isDisabled()}
                  size="small"
                  onClick={handleActionOk}
                >
                  {translator('Confirmar')}
                </Button>
              </Actions>
            </Content>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default UserChangeRole;
