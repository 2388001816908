export enum DashboardTypes {
  GET_UNITS = '@dashboard/GET_UNITS',
  GET_UNITS_SUCCESS = '@dashboard/GET_UNITS_SUCCESS',
  GET_UNITS_FAILED = '@dashboard/GET_UNITS_FAILED',

  GET_PRODUCTS = '@dashboard/GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS = '@dashboard/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILED = '@dashboard/GET_PRODUCTS_FAILED',

  SETUP = '@dashboard/SETUP',
}
