import { takeLatest } from 'redux-saga/effects';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import {
  onCreateByEmail,
  onCreateByEmailFailed,
  onCreateByEmailSuccess,
} from './CreateByEmail';
import {
  onGetByDownload,
  onGetDownloadByIdFailed,
  onGetDownloadByIdSuccess,
} from './GetByDownload';
import { onGetById, onGetByIdFailed, onGetByIdSuccess } from './GetById';
import {
  onUpdateSign,
  onUpdateSignFailed,
  onUpdateSignSuccess,
} from './UpdateBySign';
import { onDeleteRequest, onDeleteSuccess, onDeleteFailed } from './Delete';
import { onGetAll, onGetAllFailed, onGetAllSuccess } from './GetAll';
import {
  onGetAllByCode,
  onGetAllByCodeFailed,
  onGetAllByCodeSuccess,
} from './GetAllByCode';
import { onUpdate, onUpdateFailed, onUpdateSuccess } from './Update';
import { onSelectOne } from './SelectOne';
import { ClinicalDocsTypes } from '~/domain/interfaces/redux/clinicalDocs/types';

import { onSign, onSignFailed, onSignSuccess } from './Sign';

const tasks = [
  takeLatest(ClinicalDocsTypes.CREATE, onCreate),
  takeLatest(ClinicalDocsTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(ClinicalDocsTypes.CREATE_FAILED, onCreateFailed),

  takeLatest(ClinicalDocsTypes.CREATE_BY_EMAIL, onCreateByEmail),
  takeLatest(ClinicalDocsTypes.CREATE_BY_EMAIL_SUCESS, onCreateByEmailSuccess),
  takeLatest(ClinicalDocsTypes.CREATE_BY_EMAIL_FAILED, onCreateByEmailFailed),

  takeLatest(ClinicalDocsTypes.DELETE, onDeleteRequest),
  takeLatest(ClinicalDocsTypes.DELETE_SUCCESS, onDeleteSuccess),
  takeLatest(ClinicalDocsTypes.DELETE_FAILED, onDeleteFailed),

  takeLatest(ClinicalDocsTypes.GETALL, onGetAll),
  takeLatest(ClinicalDocsTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(ClinicalDocsTypes.GETALL_FAILED, onGetAllFailed),

  takeLatest(ClinicalDocsTypes.GETALL_BY_CODE, onGetAllByCode),
  takeLatest(ClinicalDocsTypes.GETALL_BY_CODE_SUCCESS, onGetAllByCodeSuccess),
  takeLatest(ClinicalDocsTypes.GETALL_BY_CODE_FAILED, onGetAllByCodeFailed),

  takeLatest(ClinicalDocsTypes.SIGN, onSign),
  takeLatest(ClinicalDocsTypes.SIGN_SUCCESS, onSignSuccess),
  takeLatest(ClinicalDocsTypes.SIGN_FAILED, onSignFailed),

  takeLatest(ClinicalDocsTypes.GET_BY_DOWNLOAD, onGetByDownload),
  takeLatest(
    ClinicalDocsTypes.GET_BY_DOWNLOAD_SUCESS,
    onGetDownloadByIdSuccess,
  ),
  takeLatest(ClinicalDocsTypes.GET_BY_DOWNLOAD_FAILED, onGetDownloadByIdFailed),

  takeLatest(ClinicalDocsTypes.GET_BY_ID, onGetById),
  takeLatest(ClinicalDocsTypes.GET_BY_ID_SUCCESS, onGetByIdSuccess),
  takeLatest(ClinicalDocsTypes.GET_BY_ID_FAILED, onGetByIdFailed),

  takeLatest(ClinicalDocsTypes.UPDATE_BY_SIGN, onUpdateSign),
  takeLatest(ClinicalDocsTypes.UPDATE_BY_SIGN_SUCESS, onUpdateSignSuccess),
  takeLatest(ClinicalDocsTypes.UPDATE_BY_SIGN_FAILED, onUpdateSignFailed),

  takeLatest(ClinicalDocsTypes.UPDATE, onUpdate),
  takeLatest(ClinicalDocsTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(ClinicalDocsTypes.UPDATE_FAILED, onUpdateFailed),

  takeLatest(ClinicalDocsTypes.SELECT_ONE, onSelectOne),
];

export default tasks;
