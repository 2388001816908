import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;

  padding: 1.75rem 4.5rem;
  gap: 1.5rem;
`;

export const CardContainer = styled.div`
  width: auto;
`;

export const TableContainer = styled.div`
  width: 100%;
`;

export const ProfessionalBadge = styled.span`
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.75rem;

  & #profile {
    margin: 0;
  }
`;

export const ProfessionalBadgeAvatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;

  background-color: #dedede;
`;

export const ProfessionalBadgeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ProfessionalBadgeName = styled.span`
  color: #3d4349;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ProfessionalBadgeRole = styled.span`
  color: #62666c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
