import { array, object, string } from 'zod';

export const schemaObservation = object({
  indicacaoClinica: string().nonempty({
    message: 'Insira uma indicação clínica.',
  }),
  exam: string().nonempty({ message: 'Insira um exame.' }),
});

const schema = object({
  indicacaoClinica: string().nonempty({
    message: 'Insira uma indicação clínica.',
  }),
  solicitacao: array(
    object({
      exam: string(),
    }),
  ),
});

export default schema;
