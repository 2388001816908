import { makeHttpClient, makeApiUrl } from '~/main/factories/http';
import { CreateFreemium as UsecaseRemoteCreateFreemium } from '~/domain/usecases/selectedOrg/remote';

import { RemoteCreateFreemium } from '~/data/repository/selectedOrg/';

/**
 * send request via API.
 */
export const makeRemoteCreateFreemium = (): UsecaseRemoteCreateFreemium =>
  new RemoteCreateFreemium(makeApiUrl('/orgs/freemium'), makeHttpClient());
