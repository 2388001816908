import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/appointment/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/appointment/getAll';

import { GetAllAppointment } from '~/domain/usecases/appointment/redux';

export class ReduxGetAllAppointment implements GetAllAppointment {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllAppointment.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
