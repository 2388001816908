import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllAppointmentPriorities as UsecaseRemoteGetAllAppointmentPriorities } from '~/domain/usecases/appointmentPriorities/remote';
import { GetAllAppointmentPriorities as UsecaseReduxGetAllAppointmentPriorities } from '~/domain/usecases/appointmentPriorities/redux';

import { RemoteGetAllAppointmentPriorities } from '~/data/repository/appointmentPriorities/RemoteGetAppointmentPriorities';
import { ReduxGetAllAppointmentPriorities } from '~/data/store/reducer/appointmentPriorities/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllAppointmentPriorities =
  (): UsecaseRemoteGetAllAppointmentPriorities =>
    new RemoteGetAllAppointmentPriorities(
      makeApiUrl('/appointmentPriorities/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxGetAllAppointmentPriorities =
  (): UsecaseReduxGetAllAppointmentPriorities =>
    new ReduxGetAllAppointmentPriorities();
