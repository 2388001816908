import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { DownloadTranscription as UsecaseRemoteDownloadTranscription } from '~/domain/usecases/appointment/remote';

import { RemoteDownloadTranscription } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteDownloadTranscription =
  (): UsecaseRemoteDownloadTranscription =>
    new RemoteDownloadTranscription(
      makeApiUrl('/appointments/{appointmentId}/transcriptions/download'),
      makeHttpClient(),
    );
