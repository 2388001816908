import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { TableSortLabel } from '@material-ui/core';
import { translator } from '~/presentation/components/i18n';

import { History } from '~/main/routes';
import { makeReduxStopAppointment } from '~/main/factories/usecases/appointment/StopAppointmentFactory';
import { IconSort } from '~/presentation/base/icons';
import orderData from '~/utils/orderData';
import { makeReduxCancelAppointment } from '~/main/factories/usecases/appointment/CancelAppointmentFactory';
import { makeReduxSetupAppointment } from '~/main/factories/usecases/appointment/SetupAppointmentFactory';
import { iStore } from '~/domain/interfaces/models';
import { makeRemoteDownloadTranscription } from '~/main/factories/usecases/appointment/DownloadTranscriptionFactory';
import ListSkeleton from '../../skeleton/ListSkeleton';
import { iListAppointment } from '../interface';
import { OutcomeStatus, ScheduleStatus } from '../../statusList';
import ActionsList from '../../actionsList/ActionsList';
import { AvatarBadger } from '../../avatar-roles';
import { Avatar } from '../styles/StyledUserItem';
import { AlertMessage } from '../../messages/AlertMessage';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
});

interface ownProps {
  dataList: iListAppointment[];
}

type iColumns =
  | 'fullName'
  | 'timeStart'
  | 'timeEnd'
  | 'typeIs'
  | 'specialty'
  | 'status'
  | 'actions'
  | 'outcome';

type iOrder =
  | 'fullName'
  | 'timeStart'
  | 'timeEnd'
  | 'typeIs'
  | 'specialty'
  | 'status';
const Appointment: React.FC<ownProps> = props => {
  const [order, setOrder] = useState<boolean>(true);
  const [dataList, setData] = useState<iListAppointment[]>(props.dataList);
  const { loading } = useSelector((store: iStore) => store.appointment);
  const url = window.location.pathname;
  const arrToCompare = [
    'fullName',
    'timeStart',
    'timeEnd',
    'typeIs',
    'specialty',
    'status',
  ];
  const classes = useStyles();
  const columns = [
    {
      id: 'healthUnit',
      label: translator('Unidade de saúde'),
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'fullName',
      label: translator('Paciente'),
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'timeStart',
      label: translator('Início'),
      minWidth: 20,
      align: 'left',
    },
    {
      id: 'timeEnd',
      label: translator('Fim'),
      minWidth: 20,
      align: 'left',
    },
    {
      id: 'typeIs',
      label: translator('Tipo'),
      minWidth: 30,
      align: 'left',
    },

    {
      id: 'specialty',
      label: translator('Especialidade'),
      minWidth: 190,
      align: 'left',
    },
    {
      id: 'status',
      label: translator('Status'),
      minWidth: 100,
      maxWidth: 160,
      align: 'left',
    },
    {
      id: 'outcome',
      label: translator('Desfecho'),
      minWidth: 100,
      maxWidth: 160,
      align: 'left',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 50,
      align: 'left',
    },
  ];

  function StopAppointment(appointment: any) {
    console.log('Appointment', appointment);
    if (appointment.status === 'AVAILABLE') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professional,
                consultant: appointment.patient.userId,
                consultantId: appointment.patient.id,
                from: url.replaceAll('/', ''),
              }),
            stop: () => {
              makeReduxStopAppointment().stop({ id: appointment.id });
            },
          }}
        />
      );
    }
    if (appointment.status === 'INPROGRESS') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professional,
                consultant: appointment.patient.userId,
                consultantId: appointment.patient.id,
                from: url.replaceAll('/', ''),
              }),
            start: () =>
              appointment.skipWaitingRoom
                ? History.getHistory().push(`/conf`, {
                    appointmentId: appointment.id,
                    consultantId: appointment.patient.id,
                    professionalId: appointment.professional,
                  })
                : History.getHistory().push(`/appointment/waitingroom`, {
                    appointmentId: appointment.id,
                    consultantId: appointment.patient.id,
                    professionalId: appointment.professional,
                  }),
            stop: () => {
              makeReduxStopAppointment().stop({ id: appointment.id });
            },
          }}
        />
      );
    }

    if (appointment.status === 'ENDED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professional,
                consultant: appointment.patient.userId,
                consultantId: appointment.patient.id,
                from: url.replaceAll('/', ''),
              }),
            downloadTranscription: appointment?.allowTranscription
              ? () => {
                  makeRemoteDownloadTranscription()
                    .download({
                      appointmentId: appointment.id,
                    })
                    .then(res => {
                      const blob = new Blob([res], { type: 'application/pdf' });
                      const urlToPdf = window.URL.createObjectURL(blob);

                      const a = document.createElement('a');
                      a.href = urlToPdf;
                      a.download = 'transcription.pdf';
                      a.style.display = 'none';
                      document.body.appendChild(a);
                      a.click();
                      document.body.removeChild(a);

                      AlertMessage({
                        type: 'success',
                        message: 'Transcrição baixada com sucesso',
                      });
                    })
                    .catch(err => {
                      // toast.error('Não foi possível realizar o download da gravação');
                    });
                }
              : undefined,
          }}
        />
      );
    }

    if (appointment.status === 'CANCELED') {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professional,
                consultant: appointment.patient.userId,
                consultantId: appointment.patient.id,
                from: url.replaceAll('/', ''),
              }),
          }}
        />
      );
    }

    if (
      appointment.status === 'SCHEDULED' ||
      appointment.status === 'CONFIRMED'
    ) {
      return (
        <ActionsList
          actions={{
            details: () =>
              History.getHistory().push('/appointment/detail', {
                appointmentId: appointment.id,
                professional: appointment.professional,
                consultant: appointment.patient.userId,
                consultantId: appointment.patient.id,
                from: url.replaceAll('/', ''),
              }),
            start: () =>
              appointment.skipWaitingRoom
                ? History.getHistory().push(`/conf`, {
                    appointmentId: appointment.id,
                    consultantId: appointment.patient.id,
                    professionalId: appointment.professional,
                  })
                : History.getHistory().push(`/appointment/waitingroom`, {
                    appointmentId: appointment.id,
                    consultantId: appointment.patient.id,
                    professionalId: appointment.professional,
                  }),
            cancel: () => {
              makeReduxSetupAppointment().setup({
                appointmentId: appointment.id,
              });
              makeReduxCancelAppointment().cancel({
                appointmentId: appointment.id,
              });
            },
          }}
        />
      );
    }

    return (
      <ActionsList
        actions={{
          stop: () => {
            makeReduxStopAppointment().stop({ id: appointment.id });
          },
          cancel: () => {
            makeReduxSetupAppointment().setup({
              appointmentId: appointment.id,
            });
            makeReduxCancelAppointment().cancel({
              appointmentId: appointment.id,
            });
          },
        }}
      />
    );
  }

  useEffect(() => {
    setOrder(false);
    setData([...orderData(props.dataList, true, 'timeStart')]);
  }, [props.dataList]);

  const RenderComponents: React.FC<{ id: iColumns; appointment: any }> = ({
    id,
    appointment,
  }) => {
    switch (id) {
      case 'actions':
        return StopAppointment(appointment)!;
      case 'status':
        return <ScheduleStatus status={appointment.status} />;
      case 'fullName':
        return (
          <Avatar>
            {appointment.patient.userId && (
              <AvatarBadger
                src={appointment.patient.avatar}
                name={appointment.patient.name}
                size="root"
                status={appointment.consultantStatus === 'ONLINE'}
              />
            )}
            <text>{`${appointment.fullName}`}</text>
          </Avatar>
        );
      case 'typeIs':
        return <div>{appointment[`${id}`]}</div>;
      case 'outcome':
        return <OutcomeStatus outcome={appointment?.outcome} />;
      default:
        return <div>{appointment[`${id}`]}</div>;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell align="left">
                  {arrToCompare.includes(item.id) ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div style={{ paddingLeft: 3 }}>
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      onClick={() => {
                        setOrder(prevState => !prevState);
                        setData([
                          ...orderData(
                            props.dataList,
                            order,
                            item.id as iOrder,
                          ),
                        ]);
                      }}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            props.dataList.map((appointment: iListAppointment) => (
              <TableRow key={appointment.id}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        appointment={appointment}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Appointment;
