import { rgba } from 'polished';
import styled from 'styled-components';
import { ClinicalDocIcon, IconCrossRed } from '~/presentation/base/icons';

export const Container = styled.div`
  width: 100%;
`;

interface HeaderProps {
  isSigned?: boolean;
}

export const Header = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  background-color: ${props =>
    props.isSigned ? props.theme.hover : rgba(236, 92, 82, 0.2)};

  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: ${props =>
    props.isSigned ? props.theme.secundaryDark : props.theme.statusNo};
`;

export const IconHeader = styled(ClinicalDocIcon)`
  width: 24px;
  height: 24px;

  margin-right: 12px;
`;

export const IconHeaderRed = styled(IconCrossRed)`
  width: 24px;
  height: 24px;

  margin-right: 12px;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
`;

export const ContentHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 120px;

  padding: 7px 14px 14px 14px;
  border-bottom: 1px solid ${props => props.theme.statusNone};
`;

export const ContentHeaderInfoText = styled.text`
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;

  color: #7a7a7a;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 307px;

  padding: 16px;
  border-radius: 5px;
  background-color: #fbfcfe;
`;

export const ContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;

  padding: 11px 16px 8px 16px;
  border-bottom: 1px solid ${props => props.theme.statusNone};
`;

interface ContentHeaderProps {
  marginBottom?: boolean;
}

export const ContentHeaderText = styled.text<ContentHeaderProps>`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: ${props => props.theme.typography};
  margin-bottom: ${props => (props.marginBottom ? '8px' : '0px')};
`;

export const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 182px;
  max-height: 273px;

  overflow: auto;

  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;

interface ItemProps {
  last?: boolean;
}

export const Item = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;

  padding: 16px 16px 19px 16px;
  border-bottom: ${props =>
    props.last ? 'none' : `1px solid ${props.theme.statusNone}`};
`;

export const ItemHeader = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin-bottom: 16px;
`;

export const DrugName = styled.text`
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;

  color: ${props => props.theme.typographyDark};
`;

export const Posology = styled.text`
  font-weight: bold;
  font-size: 16px;
  line-height: 14px;

  color: ${props => props.theme.header};
`;

export const PosologyContent = styled.text`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
`;

interface TextProps {
  bold?: boolean;
  padding?: boolean;
}

export const ContentBodyText = styled.text<TextProps>`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 16px;
  line-height: 22px;
  padding-top: ${props => (props.padding ? '12px' : '0')};

  color: #000000;
`;

export const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  padding: 0 16px;
  margin-top: 8px;
`;

interface SignedProps {
  isSigned?: boolean;
}

export const Signed = styled.div<SignedProps>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props =>
    props.isSigned ? props.theme.secundaryDark : props.theme.statusNo};
  font-size: 14px;
`;
