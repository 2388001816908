import { Dispatch } from 'redux';
import store from '~/data/store';
import { insertClinicalDocs } from '~/data/store/reducer/clinicalDocs/actions/insert';

import { iActionInsert } from '~/domain/interfaces/redux/clinicalDocs/insert';

import { InsertClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

export class ReduxInsertClinicalDocs implements InsertClinicalDocs {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  insert = (params: InsertClinicalDocs.Params): iActionInsert =>
    this.send(insertClinicalDocs(params));
}
