import { call, put } from 'redux-saga/effects';
import {
  getAuditRecordFail,
  getAuditRecordSuccess,
} from '~/data/store/reducer/AuditRecord/actions/get';

import { makeRemoteAuditRecord } from '~/main/factories/usecases/AuditRecord/GetAuditRecordFactory';

import {
  iActionGetAuditRecord,
  iActionGetAuditRecordSuccess,
} from '~/domain/interfaces/redux/auditRecord/GetAuditRecord';

import { GetAuditRecord } from '~/domain/usecases/AuditRecord/remote';

export function* onGetAuditRecord(action: iActionGetAuditRecord) {
  const auditRecord = makeRemoteAuditRecord();

  try {
    const responseGetAuditRecord: GetAuditRecord.Model = yield call(
      auditRecord.list,
      action.payload,
    );

    yield put(getAuditRecordSuccess(responseGetAuditRecord));
  } catch (e) {
    yield put(getAuditRecordFail());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAuditRecordSuccess(action: iActionGetAuditRecordSuccess) {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAuditRecordFailed() {}
