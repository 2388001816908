import React, {
  KeyboardEvent,
  ReactElement,
  cloneElement,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { IconFilter, IconSearch } from '~/presentation/base/icons';
import Dropdown from '../drop-down';
import { translator } from '../i18n';
import { Container } from './styles/StyledSearchList';

interface SearchProps {
  name?: string;
  children: ReactElement;
  className?: string;
  icon?: JSX.Element;
}

const SearchListClinicalDocs: React.FC<SearchProps> = ({
  children,
  className,
}) => {
  const [consultant, setConsultant] = useState('');
  const [professional, setProfessional] = useState('');

  const { auth } = useSelector((store: iStore) => store);
  const { orgId, role } = useSelector((store: iStore) => store.auth.selectUser);

  const userType = auth.selectUser.role;

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === auth.selectUser?.orgId,
  )?.id;

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit?.id === auth.selectUser?.orgUnitId,
  )?.id;

  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (userType) {
      case 'PRO':
      case 'ADM':
        if (e.key === 'Enter') {
          makeReduxGetAllClinicalDocs().getAll({
            filter: {
              professional: role === 'PRO' ? professionalId : undefined,
              patientName: consultant,
              org: orgId,
              enabled: true,
            },
          });
        }
        break;
      case 'CON':
        if (e.key === 'Enter') {
          makeReduxGetAllClinicalDocs().getAll({
            filter: {
              patient: role === 'CON' ? consultantId : undefined,
              professionalName: professional,
              org: orgId,
              enabled: true,
            },
          });
        }
        break;
      default:
        break;
    }
  };

  return (
    <Container className={className}>
      <IconSearch id="btn_searchFilter" />
      <input
        id="input_search"
        placeholder={`${translator('Buscar por...')}`}
        value={consultant}
        onChange={e => {
          setProfessional(e.target.value);
          setConsultant(e.target.value);
        }}
        onKeyPressCapture={handleSubmit}
      />
      <Dropdown label="Filtros" icon={<IconFilter />}>
        {cloneElement(children, {
          setValue: setConsultant,
        })}
      </Dropdown>
    </Container>
  );
};
export default SearchListClinicalDocs;
