import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  height: 3.3em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #f4f7fe;
  border-radius: 4px;

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #004ba5;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  position: fixed;
  background: #f4f7fe;
  padding: 0px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 28em;
  max-height: 40em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div`
  justify-content: center;
  align-items: center;
  padding-top: 52px;

  h3 {
    margin-top: 27px;
    align-items: center;
    text-align: center;
  }

  p {
    color: #7a7a7a;
    font-size: 13px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const TextConditions = styled.span`
  font-size: 13px;
  font-weight: bold;
  color: #7a7a7a;
  text-align: center;
  text-overflow: ellipsis;
  margin-left: 25px;
`;

export const BlackBox = styled.div`
  width: 21em;
  height: 12em;
  background-color: black;
  border: 2px solid #004ba5;
  border-radius: 5px;
  padding: 10px;
  margin-top: 25px;
  margin-left: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

export const Circle = styled.div`
  height: 44px;
  width: 44px;
  background-color: white;
  padding-top: 9px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  text-align: center;
  cursor: pointer;

  p {
    margin-top: 10px;
    color: #004ba5;
  }
`;

export const ConfirmCancel = styled.button`
  width: 240px;
  height: 38px;
  margin-top: 11px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 2px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;

export const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
  gap: 40px;
`;

export const CheckboxContainer = styled.div`
  margin-top: 40px;
  margin-left: 20px;
  width: 100%;
  height: 10%;

  span {
    font-size: 13px;
    color: black;
    text-align: start;
    text-overflow: ellipsis;
  }
`;
