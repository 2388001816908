import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 700px;
  justify-content: space-between;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 15px 0px 15px;
`;

type NavigationProps = {
  justifyEnd?: boolean;
};

export const Navigation = styled.div<NavigationProps>`
  display: flex;
  justify-content: ${props =>
    props.justifyEnd ? 'flex-end' : 'space-between'};
  width: 100%;
  padding: 0px 15px 0px 15px;
`;
