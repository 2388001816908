import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 5px;
  gap: 10px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: ${defaultTheme.typographyDarkGray};
  font-weight: bold;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    text {
      padding: 3px;
    }
  }
`;
