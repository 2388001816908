import styled from 'styled-components';
import ExcludeIcon from '~/presentation/base/icons/deleteOutline.svg';
import ExcludeIconHover from '~/presentation/base/icons/deleteHover.svg';

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #efefef;
`;

export const InfoProfessional = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentType = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

export const Img = styled.div`
  width: 28px;
  border-radius: 50%;
  align-self: center;
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 20px;
`;

export const Name = styled.h3`
  font-size: 12px;
  color: black;
`;

export const Speciality = styled.span`
  font-size: 12px;
  color: ${props => props.theme.typographyDarkGray};
`;

export const Type = styled.div`
  color: ${props => props.theme.typographyDarkGray};
  font-size: 12px;
`;

export const Delete = styled.div`
  background-image: url(${ExcludeIcon});
  width: 26px;
  height: 26px;
  cursor: pointer;
  :hover {
    background-image: url(${ExcludeIconHover});
  }
`;
