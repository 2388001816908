/* eslint-disable react/react-in-jsx-scope */
import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconDuties, IconPerson } from '~/presentation/base/icons';
import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import DutyRequesterPage from '~/presentation/pages/DutyRequester';
import { History } from '~/main/routes';
import { iStore } from '~/domain/interfaces/models';
import { JoinOnCallBody } from '~/presentation/components/modalRequestConsultation';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { closeModal } from '~/utils/closeModal';
import { makeReduxGetDuties } from '../../usecases/duty/GetDuties';
import { makeReduxActiveMessage } from '../../usecases/message/Update';
import { ButtonToOpenSideSheet, Container } from './styles';
import { makeRemoteLeftRequesterOnCall } from '../../usecases/onCall/LeftRequesterOnCallFactory';
import { makeRemoteJoinOnCall } from '../../usecases/duty/JoinOnCall';
import { makeRemoteRemovedOnCallRequester } from '../../usecases/onCallRequester/RemovedOnCallRequesterFactory';

const DutyRequesterFactory: React.FC = () => {
  const auth = useSelector((store: iStore) => store.auth.info);
  const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);
  const professional = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const { state } = useLocation<{
    specialty: string;
    id: number;
    formValues: JoinOnCallBody;
  }>();

  const { specialty, id } = state;

  // make the redux to get the professionals on duty
  // const handleLoadDutyRequesters = useCallback(() => {
  //   makeReduxGetDuties().get({
  //     onCallId: Number(id),
  //   });

  //   if (professional)
  //     makeRemoteJoinOnCall()
  //       .create({
  //         onCallId: id,
  //         requesterId: professional?.id,
  //         ...state.formValues,
  //         sex:
  //           state.formValues.sex === 'NONE' ? undefined : state.formValues.sex,
  //       })
  //       .then(() => {
  //         makeReduxActiveMessage().active({
  //           active: 'consultationRequestSuccess',
  //           appointmentSpecialty: state?.specialty,
  //         });
  //       })
  //       .catch(() => {
  //         AlertMessage({
  //           message: 'Falha ao enviar solicitação!',
  //           type: 'danger',
  //         });
  //         History.getHistory().goBack();
  //       });
  // }, []);

  // useEffect(() => {
  //   handleLoadDutyRequesters();
  // }, [handleLoadDutyRequesters]);

  return (
    <Container>
      <Menu />
      <PageHeader
        backDescription="Escolher uma unidade de saúde"
        title="Plantão Virtual"
        onBack={() =>
          makeReduxActiveMessage().active({
            active: 'leaveInterconsult',
            actionOk: () => {
              if (professional?.id)
                makeRemoteRemovedOnCallRequester()
                  .removed({
                    requesterId: professional.id,
                  })
                  .catch(() => {
                    console.log('error');
                  });
              History.getHistory().goBack();
              closeModal();
            },
            actionCancel: closeModal,
            data: { specialty, type: 'requester' },
          })
        }
      />
      <DutyRequesterPage onCallId={Number(id)} />
    </Container>
  );
};

const Button: React.FC<{ onCallId: number }> = ({ onCallId }) => {
  const handleOpenDrawer = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.professionalsOnDuty,
      data: {
        onCallId,
      },
    });
  }, [onCallId]);

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <ButtonToOpenSideSheet type="button" onClick={handleOpenDrawer}>
      <IconDuties />
    </ButtonToOpenSideSheet>
  );
};

export default DutyRequesterFactory;
