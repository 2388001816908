import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AppointmentReportByOrgUnit as UsecaseRemoteAppointmentReportByOrgUnit } from '~/domain/usecases/report/remote';

import { RemoteAppointmentReportByOrgUnit } from '~/data/repository/report';

/**
 * send request via API.
 */
export const makeRemoteAppointmentReportByOrgUnit =
  (): UsecaseRemoteAppointmentReportByOrgUnit =>
    new RemoteAppointmentReportByOrgUnit(
      makeApiUrl('/reports/appointments/orgUnits'),
      makeHttpClient(),
    );
