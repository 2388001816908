import { UpdateAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteUpdateAppointment implements UpdateAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateAppointment.Model>;

  constructor(url: string, httClient: HttpClient<UpdateAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (
    params: UpdateAppointment.Params,
  ): Promise<UpdateAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.body.appointmentId}`,
      method: 'patch',
      body: {
        professional: params.body.professional,
        specialty: params.body.specialty,
        consultant: params.body.consultant,
        organizer: params.body.organizer,
        healthPlan: params.body.healthPlan,
        obs: params.body.obs,
        priority: params.body.priority,
        type: params.body.type,
        status: params.body.status,
        allowInvite: params.body.allowInvite,
        date: params.body.date,
        duration: params.body.duration,
        note: params.body.note,
        companions: params.body.companions,
        professionals: params.body.professionals,
        title: params.body.title,
        info: params.body.info,
        clinicalCondition: params.body.clinicalCondition,
        forwarding: params.body.forwarding,
        healthUnit: params.body.healthUnit,
        soapSubjective: params.body.soapSubjective,
        soapObjective: params.body.soapObjective,
        soapEvaluation: params.body.soapEvaluation,
        soapPlan: params.body.soapPlan,
      },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
