import { call, put, select } from 'redux-saga/effects';
import { iActionGetAllByCode } from '~/domain/interfaces/redux/supportDoc/getAllByCode';
import { makeRemoteGetAllSupportDocsByCode } from '~/main/factories/usecases/supportDoc/GetAllSupportDocsByCodeFactory';
import { GetAllSupportDocsByCode } from '~/domain/usecases/supportDoc/redux';
import {
  getAllByCodeFailed,
  getAllByCodeSuccess,
} from '~/data/store/reducer/supportDoc/actions/getAllByCode';

export function* onGetAllByCode(action: iActionGetAllByCode) {
  const supportDoc = makeRemoteGetAllSupportDocsByCode();
  try {
    const response: GetAllSupportDocsByCode.Model = yield call(
      supportDoc.getAll,
      action.payload,
    );

    yield put(getAllByCodeSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(getAllByCodeFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllByCodeSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetAllByCodeFailed() {}
