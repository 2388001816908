import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateOrganizer as UsecaseRemoteCreateOrganizer } from '~/domain/usecases/organizer/remote';
import { CreateOrganizer as UsecaseReduxCreateOrganizer } from '~/domain/usecases/organizer/redux';

import { RemoteCreateOrganizer } from '~/data/repository/organizer';
import { ReduxCreateOrganizer } from '~/data/store/reducer/organizer/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateOrganizer = (): UsecaseRemoteCreateOrganizer =>
  new RemoteCreateOrganizer(makeApiUrl('/organizers'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCreateOrganizer = (): UsecaseReduxCreateOrganizer =>
  new ReduxCreateOrganizer();
