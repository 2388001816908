export enum SpecialtyTypes {
  CREATE = '@specialty/CREATE',
  CREATE_SUCCESS = '@specialty/CREATE_SUCCESS',
  CREATE_FAILED = '@specialty/CREATE_FAILED',
  GETALL = '@specialty/GETALL',
  GETALL_SUCCESS = '@specialty/GETALL_SUCCESS',
  GETALL_FAILED = '@specialty/GETALL_FAILED',
  GET = '@specialty/GET',
  GET_SUCCESS = '@specialty/GET_SUCCESS',
  GET_FAILED = '@specialty/GET_FAILED',
  UPDATE = '@specialty/UPDATE',
  UPDATE_SUCCESS = '@specialty/UPDATE_SUCCESS',
  UPDATE_FAILED = '@specialty/UPDATE_FAILED',
  DELETE = '@specialty/DELETE',
  DELETE_SUCCESS = '@specialty/DELETE_SUCCESS',
  DELETE_FAILED = '@specialty/DELETE_FAILED',
  REACTIVATE = '@specialty/REACTIVATE',
  REACTIVATE_SUCCESS = '@specialty/REACTIVATE_SUCCESS',
  REACTIVATE_FAILED = '@specialty/REACTIVATE_FAILED',
}
