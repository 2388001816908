import { iActionGetClinicalDocsByDownload } from '~/domain/interfaces/redux/clinicalDocs/getByDownload';

export function* onGetByDownload(
  action: iActionGetClinicalDocsByDownload,
): Generator {
  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDownloadByIdSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDownloadByIdFailed() {}
