import { InviteAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteInviteAppointment implements InviteAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<InviteAppointment.Model>;

  constructor(url: string, httClient: HttpClient<InviteAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  invite = async (
    params: InviteAppointment.Params,
  ): Promise<InviteAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url.replace('{appointmentId}', params.appointmentId)}`,
      method: 'post',
      body: { ...params, appointmentId: undefined },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
