import { put, call } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { iActionStopOnCall } from '~/domain/interfaces/redux/onCall/stopOnCall';

import { getAllRequest } from '~/data/store/reducer/appointment/actions/getAll';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import {
  stopFailed,
  stopSuccess,
} from '../../reducer/onCall/actions/stopOnCall';
import { iStore } from '~/domain/interfaces/models';
import storeDev from '../..';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onStopRequest(action: iActionStopOnCall) {
  try {
    yield put(stopSuccess());
  } catch (e) {
    yield put(stopFailed());
  }
}

export function* onStopSuccess() {
  const store: iStore = storeDev.getState();
  const { orgId, role, orgUnitId } = store.auth.selectUser;
  const { auth } = store;

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  yield put(
    getAllRequest({
      filter: {
        org: orgId,
        professional: role === 'PRO' ? professionalId : undefined,
        consultant: role === 'CON' ? consultantId : undefined,
      },
    }),
  );
}

export function onStopFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao remover agendamento.' }),
  });
}
