import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { StopAppointment as UseCaseRemoteStopAppointment } from '~/domain/usecases/appointment/remote';
import { StopAppointment as UsecaseReduxStopAppointment } from '~/domain/usecases/appointment/redux';
import { RemoteStopAppointment } from '~/data/repository/appointment/RemoteStopAppointment';
import { ReduxStopAppointment } from '../../../../data/store/reducer/appointment/usecases/ReduxStopAppointment';

/**
 * send request via API.
 */
export const makeRemoteStopAppointment = (): UseCaseRemoteStopAppointment =>
  new RemoteStopAppointment(makeApiUrl('/appointments'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxStopAppointment = (): UsecaseReduxStopAppointment =>
  new ReduxStopAppointment();
