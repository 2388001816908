import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { RefuseOnCallSpecialist as UsecaseRemoteRefuseOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
// import { RefuseOnCallSpecialist as UsecaseReduxRefuseOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemoteRefuseOnCallSpecialist } from '~/data/repository/onCallSpecialist';
// import { ReduxRefuseOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteRefuseOnCallSpecialist =
  (): UsecaseRemoteRefuseOnCallSpecialist =>
    new RemoteRefuseOnCallSpecialist(
      makeApiUrl(
        '/oncall/{onCallId}/specialists/{specialistId}/requests/{requestId}/REFUSE',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxRefuseOnCallSpecialist =
  (): UsecaseReduxRefuseOnCallSpecialist =>
    new ReduxRefuseOnCallSpecialist(); */
