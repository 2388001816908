/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import Modal from '../modal';
import { formattedUrl } from '~/utils/formattedUrl';
import { IconShareLink } from '~/presentation/base/icons';
import { Button } from '../..';
import { Footer } from './styles';

interface ownProps {
  closeCallback?: () => void;
}

export const ViewMedia: React.FC<ownProps> = ({ closeCallback }) => {
  const { active, actionOk, actionCancel, url, title, mimeType, link } =
    useSelector((store: iStore) => store.message);

  const modalName = MessageOptions.viewMediaModal;
  const isOpen = active === modalName;

  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    if (url) navigator.clipboard.writeText(url);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <>
      <Modal
        open={isOpen}
        closeCallback={closeCallback}
        title={`${title}`}
        hasFooter
      >
        {mimeType === 'VIDEO' && url && (
          <iframe
            title={title}
            src={formattedUrl(url)}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ width: '100%', height: '100%' }}
          />
        )}
        {mimeType === 'IMAGE' && <img src={url} alt={title} />}
      </Modal>
      {isOpen && link && (
        <Footer>
          <div className="footer-text">
            <IconShareLink
              width="70px"
              height="70px"
              style={{ alignSelf: 'left' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <h3>Copiar ou receber link por e-mail</h3>
              <span>
                Deseja receber este conteúdo pelo seu e-mail? Você também pode
                copiá-lo para a área de transferência.
              </span>
            </div>
          </div>
          {/* <div className="footer-text">
              <IconConfirmShareLink
                width="70px"
                height="70px"
                style={{ alignSelf: 'left' }}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h3>Conteúdo enviado para {user.email}</h3>
                <span>
                  Verifique sua caixa de <p>spam</p> caso não encontre-o na principal. Se não tiver recebido, você pode enviar novamente.
                </span>
              </div>
            </div> */}
          <div className="footer-button">
            <Button
              type="button"
              size="medium"
              variant="secundary"
              height="45px"
              onClick={() => handleCopyLink()}
              disabled={copied}
              rounded
            >
              {copied ? 'Copiado!' : 'Copiar link'}
            </Button>
            <Button
              type="button"
              size="medium"
              variant="primary"
              height="45px"
              rounded
              disabled
            >
              Receber por e-mail
            </Button>
          </div>
        </Footer>
      )}
    </>
  );
};

export default ViewMedia;
