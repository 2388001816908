import React from 'react';
import { Content } from './styles/StyledMoreOptions';
import { IconCancel, IconPen } from '~/presentation/base/icons';

const MoreOptions: React.FC = () => {
  return (
    <Content>
      <IconPen id="btn_edit" />
      <IconCancel id="btn_delete" />
    </Content>
  );
};

export default MoreOptions;
