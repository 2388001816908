import {
  iActionJoinWaitingRoom,
  iActionJoinFailedWaitingRoom,
  iActionJoinSuccessWaitingRoom,
  waitingRoomTypes,
} from '~/domain/interfaces/redux/waitingroom/join';

import { JoinWaitingRoom } from '~/domain/usecases/waitingRoom/socket';

export const JoinRequest = (
  params: JoinWaitingRoom.Params,
): iActionJoinWaitingRoom => ({
  type: waitingRoomTypes.JOIN,
  payload: params,
});

export const JoinSuccess = (
  params: JoinWaitingRoom.Model,
): iActionJoinSuccessWaitingRoom => ({
  type: waitingRoomTypes.JOIN_SUCCESS,
  payload: params,
});

export const Joinfailed = (): iActionJoinFailedWaitingRoom => ({
  type: waitingRoomTypes.JOIN_FAILED,
});
