import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`;

export const Name = styled.div`
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Role = styled.div`
  font-size: 12px;
  display: flex;
  color: ${defaultTheme.typographyDarkGray};
  align-items: center;
  justify-content: center;
`;
