import { useEffect } from 'react';
import { ReduxLogout } from '~/data/store/reducer/auth/usecases/ReduxLogout';

const LogoutHandler: React.FC = () => {
  useEffect(() => {
    const handleBeforeLoad = (event: BeforeUnloadEvent) => {
      if (!sessionStorage.getItem('@atendimento/loggedIn')) {
        event.preventDefault();
        new ReduxLogout().logout();
      }
    };

    window.addEventListener('load', handleBeforeLoad);

    return () => {
      window.removeEventListener('load', handleBeforeLoad);
    };
  }, []);

  return null;
};

export default LogoutHandler;
