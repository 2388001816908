import React from 'react';
import { More } from '~/presentation/components/UI/popover';
import { iAntiMicroPresctiption } from './interface';
import {
  Container,
  Content,
  DrugDosage,
  DosageAndButton,
  Information,
  Word,
  Result,
} from './styles/StyledItemAntiMicroPrescription';
import MoreOptions from './MoreOptions';

interface ownProps {
  ArrPrescription: Array<iAntiMicroPresctiption>;
}

const ItemAntiMicroPrescription: React.FC<ownProps> = ({ ArrPrescription }) => {
  return (
    <Container>
      {ArrPrescription.map(item => (
        <Content>
          <DrugDosage>
            <h1>{item.drug}</h1>
            <h2>{item.posology}</h2>
            <Information>
              <Word>{`Forma: ${item.form}`}</Word>
              <Word>{`Via: ${item.via}`}</Word>
              <Word>{`Quantidade: ${item.quantity}`}</Word>
              <Word>{`Duração: ${item.duration}`}</Word>
            </Information>
          </DrugDosage>
          <DosageAndButton>
            <h1>{item.dosage}</h1>
            <More content={<MoreOptions />} positionY="top" positionX="left" />
          </DosageAndButton>
        </Content>
      ))}
    </Container>
  );
};

export default ItemAntiMicroPrescription;
