import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { V4hSpin } from '~/presentation/components/spin';
import { Unauthorized } from '~/presentation/pages/errors';
import Billing from '~/presentation/roles/adm/pages/billing';
import { makeReduxGetInvoiceByOrg } from '../../usecases/billing/GetInvoiceByOrgFactory';

export const BillingFactory: React.FC = () => {
  const loading = useSelector((state: iStore) => state.clinicalDocs.loading);
  const { role, orgId } = useSelector((store: iStore) => store.auth.selectUser);

  useEffect(() => {
    if (orgId) makeReduxGetInvoiceByOrg().get({ org: orgId });
  }, [orgId]);

  if (loading) return <V4hSpin fullScreen background="white" />;

  switch (role) {
    case 'ADM':
      return <Billing />;
    default:
      return <Unauthorized />;
  }
};
