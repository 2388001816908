import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllSupportDocs as UseCaseReduxGetAllSupportDocs } from '~/domain/usecases/supportDoc/redux';
import { GetAllSupportDocs as UseCaseRemoteGetAllSupportDocs } from '~/domain/usecases/supportDoc/remote';

import { ReduxGetAllSupportDocs } from '~/data/store/reducer/supportDoc/usecases/ReduxGetAllSupportDocs';
import { RemoteGetAllSupportDocs } from '~/data/repository/supportDoc';

export const makeRemoteGetAllSupportDocs = (): UseCaseRemoteGetAllSupportDocs =>
  new RemoteGetAllSupportDocs(makeApiUrl('/supportDocs'), makeHttpClient());

export const makeReduxGetAllSupportDocs = (): UseCaseReduxGetAllSupportDocs =>
  new ReduxGetAllSupportDocs();
