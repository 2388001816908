import { Dispatch } from 'redux';
import store from '~/data/store';
import { setupRequest } from '~/data/store/reducer/dashboard/actions/setup';

import { iActionSetup } from '~/domain/interfaces/redux/dashboard/setup';

import { SetupDashboard } from '~/domain/usecases/dashboard/redux';

export class ReduxSetupDashboard implements SetupDashboard {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setup = (params: SetupDashboard.Params): iActionSetup =>
    this.send(setupRequest(params));
}
