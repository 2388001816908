import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Input, Select, Button } from '~/presentation/components/UI';
import InputKeyBoardDate from '~/presentation/components/inputKeyboardDate';
import {
  ContainerButtons,
  ContainerForm,
  FormItem,
  Container,
  Options,
} from './styles/StyledFilterClinicalDocs';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';

import { translator } from '~/presentation/components/i18n';
import { iStore } from '~/domain/interfaces/models';

interface filterParams {
  appointment?: number;
  specialty?: number;
  org?: number;
  patient?: number;
  patientName?: string;
  professional?: number;
  professionalName?: string;
  origin?: string;
  type?: number;
  begin?: string;
  end?: string;
  enabled?: boolean;
}

interface FilterClinicalDocsProps {
  setValue?: (value: string) => void;
}

const FilterClinicalDocs: React.FC<FilterClinicalDocsProps> = ({
  setValue,
}) => {
  const [consultant, setConsultant] = useState('');
  const [professional, setProfessional] = useState('');
  const [dated, setDated] = useState<Date | null>(null);
  const [origin, setOrigin] = useState('DEFAULT');
  const [docType, setDocType] = useState(0);

  const { auth } = useSelector((store: iStore) => store);

  const { orgId, role, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const handleFilter = () => {
    const filterObject: filterParams = {
      patientName: consultant,
      professionalName: professional,
      professional: role === 'PRO' ? professionalId : undefined,
      patient: role === 'CON' ? consultantId : undefined,
      origin: origin === 'DEFAULT' ? undefined : origin,
      type: docType === 0 ? undefined : docType,
      org: orgId,
      enabled: true,
    };

    // if (origin !== 'DEFAULT') {
    //   let date: Date | undefined;

    //   switch (origin) {
    //     case 'TODAY':
    //       date = new Date();
    //       break;
    //     case 'YESTERDAY':
    //       date = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
    //       break;
    //     case '7DAYS':
    //       date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    //       break;
    //     case '30DAYS':
    //       date = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
    //       break;
    //     case '90DAYS':
    //       date = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000);
    //       break;
    //     default:
    //       break;
    //   }

    //   if (date) {
    //     filterObject.begin = `${date.getUTCFullYear()}-${`0${
    //       date.getUTCMonth() + 1
    //     }`.slice(-2)}-${`0${date.getUTCDate()}`.slice(-2)}`;
    //     filterObject.end = `${now.getUTCFullYear()}-${`0${
    //       now.getUTCMonth() + 1
    //     }`.slice(-2)}-${`0${now.getUTCDate()}`.slice(-2)}`;
    //   }
    // }

    if (dated) {
      const dayBefore = new Date(dated.getTime() + 1 * 24 * 60 * 60 * 1000);

      filterObject.begin = `${dated.getUTCFullYear()}-${`0${
        dated && dated.getUTCMonth() + 1
      }`.slice(-2)}-${`0${dated.getUTCDate()}`.slice(-2)}`;
      filterObject.end = `${dayBefore.getUTCFullYear()}-${`0${
        dayBefore.getUTCMonth() + 1
      }`.slice(-2)}-${`0${dayBefore.getUTCDate()}`.slice(-2)}`;
    }

    makeReduxGetAllClinicalDocs().getAll({
      filter: filterObject,
    });

    document.getElementById('close_filter')?.click();
  };

  const resetFilter = () => {
    setConsultant('');
    setProfessional('');
    setDated(null);
    setOrigin('DEFAULT');
    setDocType(0);
    if (setValue) setValue('');

    makeReduxGetAllClinicalDocs().getAll({
      filter: {
        professional: role === 'PRO' ? professionalId : undefined,
        patient: role === 'CON' ? consultantId : undefined,
        org: orgId,
        enabled: true,
      },
    });
  };

  return (
    <Container>
      <ContainerForm>
        {role === 'CON' && (
          <FormItem>
            <p>{`${translator('Profissional')}`}</p>
            <Input
              id="input_professional"
              placeholder={`${translator('Digite o nome do profissional')}`}
              className="hover-input"
              value={professional}
              onChange={e => setProfessional(e.target.value)}
            />
          </FormItem>
        )}
        {role === 'PRO' && (
          <FormItem>
            <p>{`${translator('Paciente')}`}</p>
            <Input
              id="input_patient"
              placeholder={`${translator('Digite o nome do paciente')}`}
              className="hover-input"
              value={consultant}
              onChange={e => setConsultant(e.target.value)}
            />
          </FormItem>
        )}
        {role === 'ADM' && (
          <>
            <FormItem>
              <p>{`${translator('Paciente')}`}</p>
              <Input
                id="input_patient"
                placeholder={`${translator('Digite o nome do paciente')}`}
                className="hover-input"
                value={consultant}
                onChange={e => setConsultant(e.target.value)}
              />
            </FormItem>
            <FormItem>
              <p>{`${translator('Profissional')}`}</p>
              <Input
                id="input_professional"
                placeholder={`${translator('Digite o nome do profissional')}`}
                className="hover-input"
                value={professional}
                onChange={e => setProfessional(e.target.value)}
              />
            </FormItem>
          </>
        )}
        <FormItem>
          <p>{`${translator('Data')}`}</p>
          <InputKeyBoardDate
            id="input_date"
            state={dated}
            setState={setDated}
            placeholder={`${translator('Selecione uma data')}`}
          />
        </FormItem>
        <FormItem>
          <p>{`${translator('Origem')}`}</p>
          <Select
            id="select_type"
            placeholder={`${translator('Qualquer')}`}
            value={origin}
            onChange={e => setOrigin(e.target.value)}
          >
            <Options value="DEFAULT">{`${translator('Qualquer')}`}</Options>
            <Options value="DETACHED">{`${translator('Avulso')}`}</Options>
            <Options value="APPOINTMENT">
              {`${translator('Atendimento')}`}
            </Options>
          </Select>
        </FormItem>
        <FormItem>
          <p>{`${translator('Tipo')}`}</p>
          <Select
            id="select_status"
            placeholder={`${translator('Tipo')}`}
            value={docType}
            onChange={e => setDocType(Number(e.target.value))}
          >
            <Options id="type_0" value={0}>
              {`${translator('Qualquer')}`}
            </Options>
            <Options id="type_1" value={1}>
              {`${translator('Receita Simples')}`}
            </Options>
            {/* <Options value="Receita Especial">Receita Especial</Options> */}
            <Options id="type_2" value={2}>
              {`${translator('Atestado Médico')}`}
            </Options>
            <Options id="type_3" value={3}>
              {`${translator('Requisição de Exames')}`}
            </Options>
            <Options id="type_7" value={7}>
              {`${translator('Solicitação/Autorização de Procedimento Ambulatorial')}`}
            </Options>
          </Select>
        </FormItem>
      </ContainerForm>
      <ContainerButtons>
        <Button
          id="btn_reset"
          onClick={() => resetFilter()}
          rounded
          variant="secundary"
          size="medium"
        >
          {`${translator('Redefinir')}`}
        </Button>
        <Button
          id="btn_search"
          // resourcers={['CLINICAL_DOC']}
          // actions={['READ_ORG']}
          rounded
          variant="primary"
          size="medium"
          onClick={() => handleFilter()}
          autoFocus
        >
          {`${translator('Pesquisar')}`}
        </Button>
      </ContainerButtons>
    </Container>
  );
};

export default FilterClinicalDocs;
