import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
// import { SetSpecialistStatusOnCall as UsecaseRemoteSetSpecialistStatusOnCall } from '~/domain/usecases/onCall/remote';
import { SetSpecialistStatusOnCall as UsecaseReduxSetSpecialistStatusOnCall } from '~/domain/usecases/onCall/redux';

// import { RemoteSetSpecialistStatusOnCall } from '~/data/repository/onCall';
import { ReduxSetSpecialistStatusOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
/* export const makeRemoteSetSpecialistStatusOnCall =
  (): UsecaseRemoteSetSpecialistStatusOnCall =>
    new RemoteSetSpecialistStatusOnCall(
      makeApiUrl(''),
      makeHttpClient()
    ); */

/**
 * send request via REDUX.
 */
export const makeReduxSetSpecialistStatusOnCall =
  (): UsecaseReduxSetSpecialistStatusOnCall =>
    new ReduxSetSpecialistStatusOnCall();
