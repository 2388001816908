import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { GetAllRegionalDoc as UsecaseRemoteGetAllRegionalDoc } from '~/domain/usecases/regionaldoc/remote';

import { RemoteGetAllRegionalDocs } from '~/data/repository/regionaldoc';

/**
 * send request via API.
 */
export const makeRemoteGetAllRegionalDocs =
  (): UsecaseRemoteGetAllRegionalDoc =>
    new RemoteGetAllRegionalDocs(makeApiUrl('/regionalDocs'), makeHttpClient());
