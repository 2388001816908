import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { History } from '~/main/routes';

import { iClinicalDocsResults, iStore } from '~/domain/interfaces/models';
import { makeReduxUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';
import { translator } from '../i18n';
import { AlertMessage } from '../messages/AlertMessage';
import { Signature } from '../signature';
import { ClinicalDocDetails, iUser } from '../specialPrescription/interface';
import { MapConsultantDetails } from '../specialPrescription/mapper/map';
import { StickyHeadTable } from '../Table';
import Header from './Header';
import {
  ChooseCertificate,
  TextAreaDoc,
  NavContent,
} from './styles/StyledClinicalDocumentViewer';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface ownProps {
  id?: number;
  appointmentId?: number;
  closeFunction?: Function;
}

interface certificateProps {
  certificateId: string;
  certificate: string;
  signatureSource?: string;
}

interface documentContent {
  drug?: string;
  posology?: string;
  dosage?: string;
}

interface Map {
  [key: string]: string | undefined;
}

const ClinicalDocumentViewer: React.FC<ownProps> = ({ id, appointmentId }) => {
  const results = useSelector((store: iStore) => store.clinicalDocs.results);
  const [certificate, setCertificate] = useState<certificateProps>(
    {} as certificateProps,
  );

  const [info, setInfo] = useState<ClinicalDocDetails>();
  const [clinicalDoc, setClinicalDoc] = useState<iClinicalDocsResults>();
  const [isSigned, setIsSigned] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [content, setContent] = useState<documentContent[]>([]);

  const clinicalDocs = useSelector((store: iStore) => store.clinicalDocs);
  const select = clinicalDocs.results.filter(item => item.document?.id === id);
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const columns: string[] = ['Droga', 'Posologia', 'Dosagem'];

  const typeDoc: Map = {
    BASIC: translator('Receita simples'),
    MEDICALCERTIFICATE: translator('Atestado Médico'),
    EXAMREQUEST: translator('Requisição de exames'),
    APAC_REPORT: translator(
      'Solicitação/Autorização de Procedimento Ambulatorial',
    ),
  };

  useEffect(() => {
    handleGetInfo(Number(id));
  }, [clinicalDocs, clinicalDoc]);

  useEffect(() => {
    if (results.length) {
      const tmp = results.filter(item => {
        return item.document?.id === Number(id);
      });
      setClinicalDoc(tmp[0]);
    }
  }, [results]);

  useEffect(() => {
    setIsSigned(clinicalDoc?.document?.status === 'SIGNED');

    setTimeout(() => {
      setIsReady(true);
    }, 1000);
  }, [clinicalDoc]);

  const handleGetInfo = async (data: number) => {
    if (clinicalDoc?.document?.content?.drugs) {
      setContent(
        clinicalDoc.document.content.drugs.map(item => ({
          drug: item.name,
          posology: item.posology,
          dosage: item.dosage,
        })),
      );
    }

    MapConsultantDetails(data)
      .then(result => {
        setInfo(result);
      })
      .catch(err => {
        if (err.name === 'NotFound') History.getHistory().push('/clinicaldocs');

        AlertMessage({
          message: intl.formatMessage({
            id: 'Não foi possível obter informações do documento clínico, tente novamente',
          }),
          type: 'danger',
        });
      });
  };

  // TODO: Consertar tipagem
  let dataToSend: any = {};

  const clinicalDocType = () => {
    const selectType = (type: number) => {
      if (type === 2) {
        return 'MEDICALCERTIFICATE';
      }
      if (type === 3) {
        return 'EXAMREQUEST';
      }

      return 'BASIC';
    };

    dataToSend = {
      appointment: select[0].appointment.id,
      docId: id,
      type: selectType(select[0].type.id),
      content: {
        drugs: select[0].document?.content.drugs ?? [],
      },
      certificate:
        certificate?.signatureSource === 'VIDAAS'
          ? undefined
          : certificate.certificate,
      certificateId:
        certificate?.signatureSource === 'VIDAAS'
          ? undefined
          : certificate.certificateId,
      signatureSource: certificate?.signatureSource ?? undefined,
    };

    if (select[0].type.id === 2) {
      dataToSend.content = {
        observacoes: select[0].document?.content.observacoes,
        periodo: select[0].document?.content.periodo,
      };
    }
    if (select[0].type.id === 3) {
      dataToSend.content = {
        solicitacao: select[0].document?.content.solicitacao || [],
        indicacaoClinica: select[0].document?.content.indicacaoClinica || [],
      };
    }

    if (select[0].type.id === 7) {
      dataToSend.content = undefined;
    }

    try {
      makeReduxUpdateClinicalDocs().update(dataToSend);
    } catch (e) {
      console.error(e);
      AlertMessage({
        message: intl.formatMessage({
          id: 'Não foi possível assinar o documento.',
        }),
        type: 'danger',
      });
    }
  };

  return (
    <NavContent>
      <Header
        user={
          {
            name: info?.user.name,
          } as iUser
        }
        type={clinicalDoc ? typeDoc[clinicalDoc?.type?.name] : undefined}
        isSigned={isSigned}
        clinicalDoc={select[0]}
      />
      {isReady && !isSigned && role === 'PRO' && (
        <ChooseCertificate>
          <div id="certificate">
            <Signature
              docId={id}
              appointmentId={appointmentId}
              changeSelected={(e: {
                certificateId: string;
                certificate: string;
                signatureSource?: string;
              }) =>
                setCertificate({
                  ...certificate,
                  certificate: e.certificate,
                  certificateId: e.certificateId,
                  signatureSource: e.signatureSource,
                })
              }
              onSignSubmit={clinicalDocType}
              isSigned={isSigned}
            />
          </div>
        </ChooseCertificate>
      )}
      {clinicalDoc?.type.id === 1 && (
        <StickyHeadTable
          columns={columns}
          rows={content ?? []}
          bold={['drug', 'dosage']}
        />
      )}
      {clinicalDoc?.type.id === 2 && (
        <TextAreaDoc>
          Atesto que o(a) Sr(a).
          <strong>{`${info?.user.name} `}</strong>
          encontra-se sob meus cuidados profissionais necessitando de
          afastamento de suas atividades pelo período de
          {` ${clinicalDoc?.document?.content.periodo}.`}
          <div id="obs" className="observacoes">
            Observações:
          </div>
          {`${clinicalDoc.document?.content.observacoes}`}
        </TextAreaDoc>
      )}
      {clinicalDoc?.type.id === 3 && (
        <TextAreaDoc>
          <strong>Indicação clínica:</strong>
          {` ${clinicalDoc?.document?.content.indicacaoClinica}`}
          <div id="obs" className="observacoes">
            Solicito:
          </div>
          Requisito o exame
          {` ${
            clinicalDoc.document?.content.solicitacao &&
            clinicalDoc.document.content.solicitacao[0].exam
          } `}
          para o paciente pois o mesmo está com sintomas.
        </TextAreaDoc>
      )}
    </NavContent>
  );
};

export default ClinicalDocumentViewer;
