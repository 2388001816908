import { iUsers } from '~/domain/interfaces/models/';
import { UsersTypes } from '~/domain/interfaces/redux/users/types';
import { UsersActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iUsers = {
  loading: false,
  getEmail: '',
  selected: Object(),
  users: {
    pageSize: 0,
    totalItems: 0,
    results: [],
  },
  dataPagination: [],
};

const reducer = (state = initialState, action: UsersActions): iUsers => {
  switch (action.type) {
    case UsersTypes.CREATE:
      return { ...state, loading: true };
    case UsersTypes.CREATE_SUCCESS:
      return { ...state, loading: false };
    case UsersTypes.CREATE_FAILED:
      return { ...state, loading: false };

    case UsersTypes.GETALL:
      return { ...state, loading: true };
    case UsersTypes.GETALL_SUCCESS:
      return { ...state, users: { ...action.payload }, loading: false };
    case UsersTypes.GETALL_FAILED:
      return { ...state, loading: false };

    case UsersTypes.GETONE:
      return { ...state, loading: true };
    case UsersTypes.GETONE_SUCCESS:
      return { ...state, selected: { ...action.payload }, loading: false };
    case UsersTypes.GETONE_FAILED:
      return { ...state, loading: false };

    case UsersTypes.UPDATE:
      return { ...state, loading: true };
    case UsersTypes.UPDATE_SUCCESS:
      return { ...state, loading: false };
    case UsersTypes.UPDATE_FAILED:
      return { ...state, loading: false };

    case UsersTypes.GET_EMAIL:
      return { ...state, getEmail: action.payload.email };

    case UsersTypes.UPDATE_AVATAR:
      return { ...state, loading: true };
    case UsersTypes.UPDATE_AVATAR_SUCCESS:
      return { ...state, loading: false };
    case UsersTypes.UPDATE_AVATAR_FAILED:
      return { ...state, loading: false };

    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
