import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import CreatableSelect from 'react-select/creatable';

import { Container, ContainerMain } from './styles/StyledGeneralData';
import { Select } from '../UI';
import { translator } from '../i18n';
import {
  iListSpeciality,
  iListConsultant,
  iListProfessional,
  iUpdateAppointment,
} from './interface';

import { makeReduxSetupProfession } from '~/main/factories/usecases/profession';

import { ConnectComponent } from './mapper/MapperGeneralData';

import { iStore } from '~/domain/interfaces/models';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import { makeReduxGetAllAppointmentType } from '~/main/factories/usecases/appointmentType/GetAppointmentType';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { Label } from '~/presentation/components/UI/select/styles';
import {
  Control,
  DropdownIndicator,
  Option,
} from '~/presentation/components/filter/styles/StyledFilterAppointmentHistory';

import { selectStyles } from '~/presentation/components/modalRequestConsultation/styles/StyledModalRequestConsultation';
import SearchSelect from '../UI/searchSelect';

export interface externalProps {
  handleChangeEditConsult: Function;
  setValue: Function;
  register: Function;
  watch: Function;
  getValues: Function;
  errors: any;
  state?: iUpdateAppointment;
}

export interface ownProps {
  specialty: iListSpeciality[];
  consultant: iListConsultant[];
  filterProfessional: (id: number | undefined) => iListProfessional[];
}

const GeneralData: React.FC<ownProps & externalProps> = ({
  watch,
  state,
  handleChangeEditConsult,
  specialty,
  consultant,
  filterProfessional,
}): JSX.Element => {
  const [professionId, setProfessionId] = useState(state?.serviceArea);
  const { results } = useSelector((store: iStore) => store.professions);

  const { role } = useSelector((states: iStore) => states.auth.selectUser);
  const { appointment } = useSelector((store: iStore) => store.showModal);
  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { data: appointmentTypes } = useSelector(
    (store: iStore) => store.appointmentType,
  );

  const {
    register,
    formState: { errors },
  } = useFormContext();

  const watchSpecialty = watch('specialty');

  const handleChange = (e: any, isNumber = false) => {
    const { name, value } = e.target;
    const valueWithCast = isNumber ? Number(value) : value;

    if (!appointment) makeReduxSetShowModal().set({ appointment: true });

    handleChangeEditConsult({
      key: name,
      value: valueWithCast,
    });
  };

  const generateHealthUnitSelectOptions = useCallback(() => {
    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            value: value.id,
            label: value.name,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const generateConsultantSelectOptions = useCallback(() => {
    return _.sortBy(
      consultant?.flatMap(value => {
        return [
          {
            value: value.id,
            label: `${value.firstName} ${value.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [consultant]);

  const generateProfessionalSelectOptions = useCallback(() => {
    return _.sortBy(
      filterProfessional(watchSpecialty)?.flatMap(value => {
        return [
          {
            value: value.professional.id,
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [watchSpecialty]);

  const healthUnitMultiselectSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(
      option => option.value === watch('healthUnit'),
    );
  }, [generateHealthUnitSelectOptions, watch]);

  const consultantMultiselectSelected = useMemo(() => {
    return generateConsultantSelectOptions().find(
      option => option.value === watch('consultant'),
    );
  }, [generateConsultantSelectOptions, watch]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionalSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionalSelectOptions, watch]);

  useEffect(() => {
    setProfessionId(Number(state?.serviceArea));
  }, [state]);

  useEffect(() => {
    makeReduxSetupProfession().setup({
      professionId: Number(state?.serviceArea),
    });
    if (state?.serviceArea) {
      handleChangeEditConsult({
        key: 'serviceArea',
        value: state.serviceArea,
      });
    }
  }, [state?.serviceArea]);

  useEffect(() => {
    makeReduxGetAllAppointmentType().list({
      client: 'SAUDEMEET',
    });

    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
    });

    register('healthUnit');
  }, [register]);

  return (
    <ContainerMain>
      <Container>
        <SearchSelect
          id="select_appointmentPatient"
          name="consultant"
          register={() => register('consultant')}
          label={`${translator('Paciente')}`}
          placeholder={translator('Selecione um paciente')}
          onChange={e => {
            handleChangeEditConsult({
              key: 'consultant',
              value: Number(e?.value),
            });
          }}
          error={Boolean(errors.consultant)}
          message={
            errors?.consultant?.message
              ? translator(errors?.consultant?.message)
              : ''
          }
          autoFocus
          required
          options={generateConsultantSelectOptions()}
          value={consultantMultiselectSelected}
          isDisabled={role === 'CON'}
        />

        <Select
          name="serviceArea"
          id="attendanceArea"
          label={translator('Área do atendimento')}
          placeholder={translator('Selecione a área de atendimento')}
          // defaultValue={professionId}
          register={() => register('serviceArea')}
          value={professionId}
          disabled={role === 'PRO' || role === 'CON'}
          onChange={e => {
            makeReduxSetupProfession().setup({
              professionId: Number(e.target.value),
            });
            handleChange(e, true);
            setProfessionId(Number(e.target.value));
            // setValue('specialty', -1);
          }}
          required
          error={Boolean(errors.serviceArea)}
          message={
            errors?.serviceArea?.message
              ? translator(errors?.serviceArea?.message)
              : ''
          }
        >
          <option value={-1}>
            {translator('Selecione a área de atendimento')}
          </option>
          {results.map(item => (
            <option value={Number(item.profession.id)}>
              {item.profession.name}
            </option>
          ))}
        </Select>
        <Select
          id="select_appointmentSpeciality"
          name="specialty"
          value={state?.specialty}
          onChange={e => handleChange(e, true)}
          register={() => register('specialty')}
          label={`${translator('Especialidade')}`}
          error={Boolean(errors.specialty)}
          message={
            errors?.specialty?.message
              ? translator(errors?.specialty?.message)
              : ''
          }
          required
          disabled={role === 'CON' || role === 'PRO'}
        >
          <option id="option_speciality" value={-1}>
            {translator('Selecione uma especialidade')}
          </option>
          {specialty.map((item, index) => (
            <option id={`option_${index}`} value={Number(item.specialty?.id)}>
              {item.specialty?.name}
            </option>
          ))}
        </Select>
        <SearchSelect
          id="select_appointmentProfessional"
          name="professional"
          register={() => register('professional')}
          label={`${translator('Profissional')}*`}
          placeholder={translator('Selecione um profissional')}
          onChange={e => {
            handleChangeEditConsult({
              key: 'professional',
              value: Number(e?.value),
            });
          }}
          error={Boolean(errors.professional)}
          message={
            errors?.professional?.message
              ? translator(errors?.professional?.message)
              : ''
          }
          isDisabled={role === 'CON' || role === 'PRO'}
          options={generateProfessionalSelectOptions()}
          value={professionalMultiselectSelected}
        />
        <Select
          id="select_appointmentType"
          name="type"
          defaultValue={state?.type}
          value={state?.type}
          label={`${translator('Tipo de Atendimento')}*`}
          onChange={e => handleChange(e, true)}
          register={() => register('type')}
          error={Boolean(errors.type)}
          message={errors.type?.message && 'Selecione um tipo de atendimento'}
          disabled={role === 'CON'}
        >
          <option id="option_type" value={-1}>
            {translator('Selecione um tipo')}
          </option>

          {appointmentTypes?.records?.map((item, index) => (
            <option id={`option_${index}`} value={item.id}>
              {translator(item.msgKey)}
            </option>
          ))}
        </Select>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Label style={{ marginBottom: '6px' }}>
            {translator('Unidade de saúde')}
          </Label>
          <CreatableSelect
            className="select"
            components={{
              Control,
              DropdownIndicator,
              IndicatorSeparator: () => null,
              Option,
            }}
            controlShouldRenderValue
            placeholder="Selecione a unidade"
            formatCreateLabel={(label: string) => `Buscar por ${label}`}
            options={generateHealthUnitSelectOptions()}
            value={healthUnitMultiselectSelected}
            onChange={e => {
              handleChangeEditConsult({
                key: 'healthUnit',
                value: Number(e?.value),
              });
            }}
            styles={selectStyles()}
            isValidNewOption={() => false}
            noOptionsMessage={() => 'Nenhum resultado encontrado'}
            isDisabled={role === 'CON'}
          />
        </div>
      </Container>
      {/* {role === 'CON' ? (
        <ButtonSave action={onSubmit} disable />
      ) : (
        <ButtonSave action={onSubmit} />
      )} */}
    </ContainerMain>
  );
};

export default ConnectComponent(GeneralData);
