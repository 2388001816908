import styled from 'styled-components';

export const WarningContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7a7a7a;
  margin-top: 20px;

  span {
    font-size: 22px;
    font-weight: bold;
    color: #000;
  }
`;

export const Escop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  background: white;
  padding: 0px;
  width: 35em;
  height: 23em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 55%;

  p {
    color: black;
    font-size: 17px;
    text-align: center;
    text-overflow: ellipsis;

    width: 26em;
  }
`;

export const ConfirmCancel = styled.button`
  max-width: 10.7em;
  max-height: 2.5em;
  width: 100%;
  height: 100%;
  margin-bottom: 11px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #ec5c52;
  color: white;
  border: 1px solid #eb5757;
  border-radius: 2px;
  padding: 14px;
  font-size: 17px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #bf2b2b;
    transition: 0.7s;
  }
`;
