import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { Sign } from '~/domain/usecases/deamon/remote/Sign';
import { UnexpectedError } from '~/domain/errors';

export class RemoteSignDocument implements Sign {
  private readonly url: string;

  private readonly httpClient: HttpClient<Sign.Model>;

  constructor(url: string, httpClient: HttpClient<Sign.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  sign = async (params: Sign.Params): Promise<Sign.Model> => {
    const response = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (response.statusCode) {
      case HttpStatusCode.ok:
        return response.body;
      default:
        throw new UnexpectedError();
    }
  };
}
