import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { RegisterProfessional } from '~/presentation/roles/manager/pages';
import { makeReduxGetAllProfession } from '../../usecases/profession';
import { makeReduxGetAllSpecialty } from '../../usecases/specialty/GetAllSpecialtyFactory';
import { makeReduxProfessionList } from '../../usecases/profession/ProfessionListFactory';

export const MakeRegisterProfessionalPage: React.FC = () => {
  const { selected } = useSelector((state: iStore) => state.professions);

  useEffect(() => {
    makeReduxProfessionList().post({
      // filter: { enabled: true, hasProfessional: true }, // TODO: Uncomment this line when the backend is ready
    });
    makeReduxGetAllSpecialty().getAll({
      pageSize: 9999,
    });
  }, [selected]);
  return <RegisterProfessional />;
};
