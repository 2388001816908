import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 45em;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #fff;

  border: 1px solid #dedede;
  overflow: hidden;
`;

export const ListHeader = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-bottom: 1px solid #dedede;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Th = styled.th`
  color: #3d4349;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 0.8125rem 1rem;

  align-items: center;
  gap: 0.5rem;

  background: #f8f8f8;
  border-bottom: 1px solid #dedede;

  cursor: pointer;

  &:hover {
    background-color: #ecedee;
  }
`;

export const Td = styled.td`
  color: #3d4349;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #dedede;
`;

type TrProps = {
  active?: boolean;
};

export const Tr = styled.tr.attrs({
  tabIndex: 0,
})<TrProps>`
  background-color: #fdfdfd;
  transition: background-color 0.2s ease;
  position: relative;

  &:hover {
    background: #ecedee;
  }

  &:focus-visible {
    outline: 2px solid #3d94fd;

    box-shadow: 0px 0px 12px 0px rgba(58, 138, 234, 0.5);

    z-index: 1;
  }

  ${({ active }) =>
    active &&
    css`
      background: #f2f8ff;

      &:hover {
        background: #eef6ff;
      }

      &:focus-visible {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.25rem;
        height: 100%;
        background: #3d94fd;
      }
    `}
`;
