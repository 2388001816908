import { SocketJoinWaitingRoom } from '~/data/repository/socket/waitingRoom';
import { ReduxJoin } from '~/data/store/reducer/waitingroom/usecases';
import { makeSocket } from '~/main/factories/socket';
import { JoinWaitingRoom as UseCaseSocketJoinWaitingRoom } from '~/domain/usecases/waitingRoom/socket';
import { JoinWaitingRoom as UseCaseReduxJoinWaitingRoom } from '~/domain/usecases/waitingRoom/redux';

/**
 * Join at conference by SocketIO.
 */
export const makeSocketJoinWaitingRoom = (): UseCaseSocketJoinWaitingRoom =>
  new SocketJoinWaitingRoom('joinWaitingRoom', makeSocket());

/**
 * Join at conference by SocketIO.
 */
export const makeReduxJoinWaitingRoom = (): UseCaseReduxJoinWaitingRoom =>
  new ReduxJoin();
