import React from 'react';

import { IconCancel } from '~/presentation/base/icons';
import { Content, Message } from './styles/StyledMediaError';

import { translator } from '../i18n';

const MediaError: React.FC = () => {
  return (
    <Content>
      <IconCancel width="30px" height="30px" />
      <Message>{translator('Não foi possível carregar o arquivo')}</Message>
    </Content>
  );
};

export default MediaError;
