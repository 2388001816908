import { iOnCall } from '~/domain/interfaces/models/OnCall';
import { OnCallTypes } from '~/domain/interfaces/redux/onCall/types';
import { OnCallActions } from './actions';

export const initialState: iOnCall = {
  available: false,
  loading: false,
};

const reducer = (state = initialState, action: OnCallActions): iOnCall => {
  switch (action.type) {
    case OnCallTypes.SETSPECIALISTSTATUS:
      return action.payload;

    case OnCallTypes.STOP_ONCALL:
      return { ...state, loading: true };
    case OnCallTypes.STOP_ONCALL_FAILED:
      return { ...state, loading: false };
    case OnCallTypes.STOP_ONCALL_SUCCESS:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
