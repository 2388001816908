import React, { useEffect, useState } from 'react';
import { Container, IconHeader, Body, Footer } from './styles/style';
import { ArrowUpGreen, ArrowDownRed } from '~/presentation/base/icons';

interface ownProps {
  title: string;
  value: number;
  percentValue: number;
  since?: string;
}

const OrgUnitDetail: React.FC<ownProps> = ({
  value,
  percentValue,
  title,
  since,
}) => {
  const [v, setV] = useState<number>(0);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setV(value);
  }, [v, value]);

  // TODO: Desabilitado temporariamente para evitar inconsistências
  // useEffect(() => {
  //   setV(0);
  //   setUpdate(prev => !prev);
  // }, [value]);

  // useEffect(() => {
  //   if (v !== value) setV(prev => prev + 1);
  // }, [v, update]);

  const renderIcons = () => {
    return <IconHeader>{title}</IconHeader>;
  };

  const renderBody = () => {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Body>
          <div className="value">{v}</div>
        </Body>

        <Footer value={percentValue}>
          <div>
            {percentValue >= 0 ? <ArrowUpGreen /> : <ArrowDownRed />}
            {`${percentValue ? percentValue.toString().replace('-', '') : 0}%`}
          </div>
          <div className="text">{since ?? 'desde o último mês'}</div>
        </Footer>
      </div>
    );
  };

  return (
    <Container>
      {renderIcons()}
      {renderBody()}
    </Container>
  );
};

export default OrgUnitDetail;
