import React from 'react';
import {
  ListItem,
  DrugAndDosage,
  DosageAndButton,
  ContainerItemPrescription,
} from './styles/StyledItemPrescription';

import { More } from '~/presentation/components/UI/popover';
import { AvatarBadger } from '~/presentation/components/avatar-roles';
import { iPrescription } from './interface';

interface ownProps {
  content: Array<iPrescription>;
}

const ItemPrescription: React.FC<ownProps> = ({ content }) => {
  return (
    <ContainerItemPrescription>
      {content.map(item => (
        <ListItem>
          <DrugAndDosage>
            <h1>{item.drug}</h1>
            <h2>{item.posology}</h2>
          </DrugAndDosage>
          <DosageAndButton>
            <h1>{item.dosage}</h1>
            <More content={<div>Hello World</div>} />
          </DosageAndButton>
        </ListItem>
      ))}
    </ContainerItemPrescription>
  );
};

export default ItemPrescription;
