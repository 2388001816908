import { Dispatch } from 'redux';
import store from '~/data/store';
import { LeftWaitingRoom } from '~/domain/usecases/waitingRoom/redux';

import { iActionLeftWaitingRoom } from '~/domain/interfaces/redux/waitingroom/left';

import { LeftRequest } from '~/data/store/reducer/waitingroom/action/left';

export class ReduxLeft implements LeftWaitingRoom {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  left = (params: LeftWaitingRoom.Params): iActionLeftWaitingRoom =>
    this.send(LeftRequest(params));
}
