import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 1.5rem 3.125rem;

  gap: 1rem;
  overflow: hidden;
`;

export const Content = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
`;

export const ActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 16px;
  gap: 16px;

  border-radius: 8px;
  border: 1px solid #b4b7b9;

  background-color: #fff;
`;
