import { Dispatch } from 'redux';
import { Refresh } from '~/domain/usecases/auth/redux';
import { iActionRefresh } from '~/domain/interfaces/redux/auth/Refresh';
import { refresh } from '~/data/store/reducer/auth/actions/refresh';
import store from '~/data/store';

export class ReduxRefresh implements Refresh {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  refresh = (params: Refresh.Params): iActionRefresh =>
    this.send(refresh(params));
}
