import styled from 'styled-components';

export const Container = styled.button`
  width: fit-content;
  border: none;
  background: transparent;
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  color: ${props => props.theme.primaryDark};
  font-weight: bold;
  :hover {
    cursor: pointer;
  }
`;
