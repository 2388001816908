import {
  DutyTypes,
  iActionGetDutyRequesters,
  iActionGetDutyRequestersFailed,
  iActionGetDutyRequestersSuccess,
} from '~/domain/interfaces/redux/duty/getDutyRequesters';
import { GetDutyRequesters } from '~/domain/usecases/duty/redux';

export const getDutyRequesters = (
  payload: GetDutyRequesters.Params,
): iActionGetDutyRequesters => ({
  type: DutyTypes.GET_DUTY_REQUESTERS,
  payload,
});

export const getDutyRequestersSuccess = (
  params: GetDutyRequesters.Model,
): iActionGetDutyRequestersSuccess => ({
  type: DutyTypes.GET_DUTY_REQUESTERS_SUCCESS,
  payload: params,
});

export const getDutyRequestersFailed = (): iActionGetDutyRequestersFailed => ({
  type: DutyTypes.GET_DUTY_REQUESTERS_FAILED,
});
