import { GetAllAppointment } from '~/domain/usecases/appointment/redux/GetAllAppointment';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  AppointmentTypes,
} from '~/domain/interfaces/redux/appointment/getAll';

export const getAllRequest = (
  payload: GetAllAppointment.Params,
): iActionGetAll => ({
  type: AppointmentTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllAppointment.Model,
): iActionGetAllSuccess => ({
  type: AppointmentTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: AppointmentTypes.GETALL_FAILED,
});
