import { call, put } from 'redux-saga/effects';
import { iActionAppointmentFiltered } from '~/domain/interfaces/redux/appointment/appointmentFiltered';
import { AppointmentFiltered } from '~/domain/usecases/appointment/remote/AppointmentFiltered';
import { makeRemoteAppointmentFiltered } from '~/main/factories/usecases/appointment/AppointmentFiltered';
import {
  filterSuccess,
  filterFailed,
} from '~/data/store/reducer/appointment/actions/appointmentFiltered';

export function* onFilter(action: iActionAppointmentFiltered) {
  const remoteListAppointment = makeRemoteAppointmentFiltered();

  try {
    const response: AppointmentFiltered.Model = yield call(
      remoteListAppointment.post,
      action.payload,
    );
    yield put(filterSuccess(response));
  } catch (e) {
    yield put(filterFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onFilterSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onFilterFailed() {}
