import { GetDutyRequesters as UseCaseReduxGetDutiesRequesters } from '~/domain/usecases/duty/redux';
import { GetDutyRequesters as UsecaseRemoteGetDutiesRequesters } from '~/domain/usecases/duty/remote';
import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { RemoteGetDutyRequesters } from '~/data/repository/duty';
import { ReduxGetDutyRequesters } from '~/data/store/reducer/duty/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetDuties = (): UsecaseRemoteGetDutiesRequesters =>
  new RemoteGetDutyRequesters(makeApiUrl('/oncall'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetDuties = (): UseCaseReduxGetDutiesRequesters =>
  new ReduxGetDutyRequesters();
