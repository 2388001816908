import { Dispatch } from 'redux';
import store from '~/data/store';
import { updateRequest } from '~/data/store/reducer/specialty/actions/update';

import { iActionUpdate } from '~/domain/interfaces/redux/specialty/update';

import { UpdateSpecialty } from '~/domain/usecases/specialty/redux';

export class ReduxUpdateSpecialty implements UpdateSpecialty {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateSpecialty.Params): iActionUpdate =>
    this.send(updateRequest(params));
}
