import { Dispatch } from 'redux';
import store from '~/data/store';
import { UpdateRequest } from '~/data/store/reducer/clinicalDocs/actions/update';

import { iActionUpdate } from '~/domain/interfaces/redux/clinicalDocs/update';

import { UpdateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

export class ReduxUpdateClinicalDocs implements UpdateClinicalDocs {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  update = (params: UpdateClinicalDocs.Params): iActionUpdate =>
    this.send(UpdateRequest(params));
}
