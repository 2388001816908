import styled from 'styled-components';

interface ItemProps {
  expanded?: boolean;
  isEditing?: boolean;
  overflow?: boolean;
}

export const Container = styled.div<ItemProps>`
  display: flex;
  width: ${props => (props.expanded ? '70%' : '35%')};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.theme.statusProgress};

  overflow: ${props => props.overflow && 'auto'};

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 61px;
  align-items: center;
  flex-direction: row;
`;

export const HeaderTitle = styled.text`
  width: 100%;
  align-self: center;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.header};
`;

export const Body = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  padding: 24px;
  gap: 30px;

  .send-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  margin-left: 5px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Card = styled.div<ItemProps>`
  display: flex;
  flex-direction: column;
  min-width: 348px;
  width: 100%;
  height: fit-content;
  border: 2px solid ${props => props.theme.typographyLight};
  border-radius: 8px;

  .gap-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    justify-content: flex-start;
    margin: 8px;
  }
`;

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7px;
  font-size: 16px;
  font-weight: 500;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  gap: 4px;
`;

export const NotesDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
  font-weight: 500;
`;

export const NotesCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 345px;
  width: 100%;
  max-height: 10em;
  height: 100%;
  border: 2px solid ${props => props.theme.typographyLight};
  border-radius: 8px;
`;

export const InterconsultTextArea = styled.textarea`
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding: 6px;
  resize: none;
  border-radius: 8px;

  textarea::placeholder {
    font-size: 14px;
    margin-left: 8px;
  }
`;
