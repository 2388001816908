import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { PageHeader } from '../../components/header';
import { Menu } from '../../components/menu';

import { Container, Wrapper } from './styles/Styled';
import { SupportDocView } from '~/presentation/components/specialPrescription';
import { translator } from '~/presentation/components/i18n';

interface iStateParams {
  docId: string;
}
const SupportDocDetails: React.FC = () => {
  const { docId } = useLocation<iStateParams>().state;

  /* verificar back */
  return (
    <Container>
      <Menu />
      <PageHeader
        backDescription="Documentos de apoio"
        title={translator('Documento')}
      />
      <Wrapper>
        <SupportDocView id={docId} />
      </Wrapper>
    </Container>
  );
};
export default SupportDocDetails;
