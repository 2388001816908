import styled, { css } from 'styled-components';
import { NewIconCheck } from '~/presentation/base/icons';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #fff;

  border: 1px solid #dedede;
  overflow: hidden;
`;

export const ListHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;

  border-bottom: 1px solid #dedede;
`;

export const ListHeaderTitle = styled.h1`
  color: #222529;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const SwitchButtonLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #222529;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 0;

  user-select: none;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  overflow: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Th = styled.th`
  color: #3d4349;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  padding: 0.8125rem 1rem;

  align-items: center;
  gap: 0.5rem;

  background: #f8f8f8;
  border-bottom: 1px solid #dedede;

  cursor: pointer;

  &:hover {
    background-color: #ecedee;
  }
`;

export const Td = styled.td`
  color: #222529;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 0.75rem 1rem;

  border-bottom: 1px solid #dedede;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: ${({ align }) => align || 'left'};
`;

type TrProps = {
  active?: boolean;
};

export const Tr = styled.tr.attrs({
  tabIndex: 0,
})<TrProps>`
  width: 100%;
  background-color: #fdfdfd;
  transition: background-color 0.2s ease;
  position: relative;

  &:hover {
    background: #ecedee;
  }

  &:focus-visible {
    outline: 2px solid #3d94fd;

    box-shadow: 0px 0px 12px 0px rgba(58, 138, 234, 0.5);

    z-index: 1;
  }

  ${({ active }) =>
    active &&
    css`
      background: #f2f8ff;

      &:hover {
        background: #eef6ff;
      }

      &:focus-visible {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0.25rem;
        height: 100%;
        background: #3d94fd;
      }
    `}
`;

export const ProfessionalBadge = styled.span`
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.75rem;

  & #profile {
    margin: 0;
  }
`;

export const ProfessionalBadgeAvatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;

  background-color: #dedede;
`;

export const ProfessionalBadgeInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

export const ProfessionalBadgeName = styled.span`
  color: #3d4349;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const ProfessionalBadgeRole = styled.span`
  color: #62666c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

type ProfessionalBadgeStatusProps = {
  status: 'WAITING' | 'AVAILABLE' | 'UNAVAILABLE' | 'ATTENDING';
};

export const ProfessionalBadgeStatus = styled.div<ProfessionalBadgeStatusProps>`
  color: #62666c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: fit-content;

  border-radius: 0.38rem;
  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ status }) => {
    switch (status) {
      case 'ATTENDING':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #3d94fd;
          background: #f2f8ff;
          color: #3d94fd;
        `;

      case 'AVAILABLE':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #458108;
          background: #f4fde9;
          color: #458108;
        `;

      case 'UNAVAILABLE':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #dd0404;
          background: #ffefef;
          color: #dd0404;
        `;

      case 'WAITING':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #818385;
          background: #f8f8f8;
          color: #818385;
        `;

      default:
        return css`
          border-radius: 0.375rem;
          border: 1px solid #818385;
          background: #f8f8f8;
        `;
    }
  }}
`;

type OutcomeBadgeStatusProps = {
  status:
    | 'PERMANENCE'
    | 'REFERRAL'
    | 'RETURN'
    | 'DEATH'
    | 'CLINICAL_DISCHARGE'
    | 'TRANSFER';
};

export const OutcomeBadgeStatus = styled.div<OutcomeBadgeStatusProps>`
  color: #62666c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: fit-content;

  border-radius: 0.38rem;
  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.375rem;
  border: 1px solid #818385;
  background: #f8f8f8;

  font-weight: 500;

  ${({ status }) => {
    switch (status) {
      case 'CLINICAL_DISCHARGE':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #0565d9;
          background: #e6f0fb;
          color: #0565d9;
        `;

      case 'REFERRAL':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #723ab2;
          background: #f4edfc;
          color: #723ab2;
        `;

      case 'RETURN':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #a31775;
          background: #fae8f4;
          color: #a31775;
        `;

      case 'DEATH':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #818385;
          background: #f8f8f8;
          color: #818385;
        `;

      case 'PERMANENCE':
        return css`
          border-radius: 0.375rem;
          border: 1px solid #458108;
          background: #f4fde9;
          color: #458108;
        `;

      default:
        return css`
          border-radius: 0.375rem;
          border: 1px solid #0565d9;
          background: #e6f0fb;
          color: #0565d9;
        `;
    }
  }}
`;

interface PriorityProps {
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW';
}

export const priorityColorStyle = (
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW',
) => {
  switch (priority) {
    case 'EMERGENCY':
      return css`
        svg path {
          fill: #dd0404;
        }
      `;

    case 'NORMAL':
      return css`
        svg path {
          fill: #fe632f;
        }
      `;

    case 'LOW':
      return css`
        svg path {
          fill: #458108;
        }
      `;

    default:
      return css`
        svg path {
          fill: #458108;
        }
      `;
  }
};

export const Priority = styled.div<PriorityProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ priority }) => priorityColorStyle(priority)}
`;

export const ListFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-top: 1px solid #dedede;

  padding: 16px 0;
`;

export const EmptyList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #818385;
  font-size: 14px;
  line-height: 16.41px;
`;

// action buttons

export const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`;

export const ActionButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  width: 32px;
  aspect-ratio: 1;

  border: 1px solid #b4b7b9;
  background-color: #fdfdfd;
`;

export const ContainerPopover = styled.div`
  background: #fff;
  display: flex;
  gap: 8px;
  height: 48px;
  border: 1px solid #dedede;
  box-shadow: 0px 2px 20px 0px rgba(34, 37, 41, 0.2);
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
`;

export const Title = styled.span`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
`;

type CheckIcon = {
  status?: 'WAITING' | 'CHECK' | 'DISABLED';
};

export const CheckIcon = styled(NewIconCheck)<CheckIcon>`
  path {
    fill: ${({ status }) => {
      switch (status) {
        case 'WAITING':
          return '#222529';
        case 'CHECK':
          return '#458108';
        case 'DISABLED':
          return '#818385';
        default:
          return '#222529';
      }
    }};
  }
`;
