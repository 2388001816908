/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { ReduxCreateClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';
import { iStore } from '~/domain/interfaces/models';
import { CreateClinicalDocs as RemoteCreateClinicalDocs } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocs';
import { makeReduxCreateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsFactory';
import { translator } from '~/presentation/components/i18n';
import { Signature } from '~/presentation/components/signature';
import { Button } from '~/presentation/components/UI';
import { schema } from '~/validation/validators/document/CreateClinicalDocValidator';
import { makeRemoteGetClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeRemoteCreateForeignClinicalDocs } from '~/main/factories/usecases/clinicalDocs/CreateForeignClinicalDocsFactory';
import { CreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { iPrescription, iValidation } from './interface';
import GeneralData, { GeneralDataProps } from './phases/GeneralData';
import { ConnectComponent } from './mapper/MapperSimplePrescription';
import Medication from './phases/Medication';
import PacientData from './phases/PacientData';
import {
  ButtonStyle,
  Content,
  Header,
  Main,
} from './styles/StyledSimplePrescription';

export interface externalProps {
  basicPrescription?: RemoteCreateClinicalDocs | ReduxCreateClinicalDocs;
  goBackToListDocument: () => void;
}

export type ownProps = GeneralDataProps;

interface iStateParams {
  appointmentId: string;
  professional: string;
  consultant: string;
}

const SimplePrescription: React.FC<ownProps & externalProps> = props => {
  const [phases, setPhases] = useState<number>(1);
  const [drugs, setDrugs] = useState<iPrescription[]>([]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [updateId, setUpdateId] = useState<number>(0);

  const stateParams = useLocation<iStateParams>().state;

  const { auth } = useSelector((store: iStore) => store);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const { role, orgUnitId, orgId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const orgUnitName = orgUnitId
    ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
        ?.orgUnit.name
    : '';

  const specialty =
    role === 'PRO'
      ? auth.info.professionals?.find(item => item.orgUnit.id === orgUnitId)
          ?.professions.specialties[0].id
      : -1;

  const [basicPrescription, setBasicPrescription] = useState<iValidation>({
    professional:
      stateParams && stateParams.professional
        ? Number(stateParams.professional)
        : -1,
    patient: {
      id:
        stateParams && stateParams?.consultant
          ? Number(stateParams?.consultant)
          : undefined,
      fullname: props.state?.patient?.fullname ?? '',
      gender: props.state?.patient?.gender ?? '',
      age: props.state?.patient?.age ?? -1,
    },
    drugs: [],
    orgUnitName,
    situation: props.state?.situation ?? 'REGISTERED',
  });

  const { goBackToListDocument } = props;

  function handleBar(number: number) {
    if (phases + number > 5 || phases + number < 1) {
      setPhases(phases);
      createBasicPrescription();
    } else {
      setPhases(phases + number);
    }
  }

  let dataToSend: RemoteCreateClinicalDocs.Params = {};

  const createBasicPrescription = () => {
    setDrugs(prevState =>
      prevState.map(item => {
        delete item.id;
        return item;
      }),
    );
    // VINCULADO A UM ATENDIMENTO
    if (stateParams && stateParams.appointmentId !== undefined) {
      dataToSend = {
        appointment: Number(stateParams.appointmentId),
        type: 'BASIC',
        content: {
          drugs,
        },
        signatureSource: basicPrescription?.signatureSource ?? undefined,
        certificate:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription?.certificate,
        certificateId:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription.certificateId,
        sidebar: true,
        patient:
          stateParams && stateParams?.consultant
            ? Number(stateParams?.consultant)
            : basicPrescription?.patient?.id,
      };

      try {
        if (basicPrescription?.signatureSource === 'VIDAAS') {
          if (basicPrescription.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  appointment: Number(stateParams.appointmentId),
                  type: 1,
                  certificate:
                    basicPrescription?.signatureSource === 'VIDAAS'
                      ? undefined
                      : basicPrescription.signatureSource,
                  content: {
                    drugs,
                  },
                  patient: {
                    age: Number(basicPrescription?.patient?.age),
                    fullname: basicPrescription?.patient?.fullname!,
                    gender: basicPrescription?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource:
                    basicPrescription.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: basicPrescription.professional,
                  specialty,
                },
              })
              .then(response => {
                console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    window.open(url, '_blank');
                    // window.location.href = url;
                  })
                  .catch(e => console.log(e));

                goBackToListDocument?.();
              })
              .catch(e => {
                console.log('##error: ', e);
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  window.open(url, '_blank');
                  // window.location.href = url;
                })
                .catch(e => console.log(e));

              goBackToListDocument?.();
            },
          });
        } else {
          if (basicPrescription.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  appointment: Number(stateParams.appointmentId),
                  type: 1,
                  certificate:
                    basicPrescription?.signatureSource === 'VIDAAS'
                      ? undefined
                      : basicPrescription.signatureSource,
                  content: {
                    drugs,
                  },
                  patient: {
                    age: Number(basicPrescription?.patient?.age),
                    fullname: basicPrescription?.patient?.fullname!,
                    gender: basicPrescription?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: basicPrescription.professional,
                  specialty,
                },
              })
              .then(res => {
                console.log('##res: ', res);
                AlertMessage({
                  message: 'Documento criado com sucesso',
                  type: 'success',
                });
                goBackToListDocument?.();
              })
              .catch(err => {
                console.error(err);
              });
            return;
          }
          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            goBackToListDocument,
          });
        }
      } catch (e) {
        console.error(e);
      }
      // NÃO VINCULADO A UM ATENDIMENTO
    } else {
      dataToSend = {
        org: orgId,
        orgUnit: orgUnitId,
        patient: {
          fullname: basicPrescription?.patient?.fullname as string,
          gender: basicPrescription?.patient?.gender as string,
          age: Number(basicPrescription?.patient?.age),
        },
        professional: basicPrescription.professional,
        type: 'BASIC',
        content: {
          drugs,
        },
        certificate:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription.certificate,
        certificateId:
          basicPrescription?.signatureSource === 'VIDAAS'
            ? undefined
            : basicPrescription.certificateId,
        specialty,
        sidebar: true,
        signatureSource: basicPrescription.signatureSource ?? undefined,
      };

      try {
        if (basicPrescription.signatureSource === 'VIDAAS') {
          if (basicPrescription.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 1,
                  certificate:
                    basicPrescription?.signatureSource === 'VIDAAS'
                      ? undefined
                      : basicPrescription.signatureSource,
                  content: {
                    drugs,
                  },
                  patient: {
                    age: Number(basicPrescription?.patient?.age),
                    fullname: basicPrescription?.patient?.fullname as string,
                    gender: basicPrescription?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource:
                    basicPrescription.signatureSource ?? undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: basicPrescription.professional,
                  specialty,
                },
              })
              .then(response => {
                // console.log('##response: ', response);
                makeRemoteGetClinicalDocs()
                  .get({ docId: Number(response?.id) })
                  .then(res => {
                    const url = res?.dataToSign;
                    localStorage.setItem('clinicalDocId', String(response?.id));
                    window.open(url, '_blank');
                    // window.location.href = url;
                  })
                  .catch(e => console.log(e));

                goBackToListDocument?.();
              })
              .catch(e => {
                console.log('##error: ', e);
              });
            return;
          }

          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            signDocWithVidaas: (id: number) => {
              makeRemoteGetClinicalDocs()
                .get({ docId: id })
                .then(res => {
                  const url = res?.dataToSign;
                  localStorage.setItem('clinicalDocId', String(id));
                  window.open(url, '_blank');
                  // window.location.href = url;
                })
                .catch(e => console.log(e));

              goBackToListDocument?.();
            },
          });
        } else {
          if (basicPrescription.situation === 'NOT-REGISTERED') {
            makeRemoteCreateForeignClinicalDocs()
              .createForeign({
                body: {
                  type: 1,
                  certificate:
                    basicPrescription?.signatureSource === 'VIDAAS'
                      ? undefined
                      : basicPrescription.signatureSource,
                  content: {
                    drugs,
                  },
                  patient: {
                    age: Number(basicPrescription?.patient?.age),
                    fullname: basicPrescription?.patient?.fullname as string,
                    gender: basicPrescription?.patient
                      ?.gender as CreateForeignClinicalDocs.Params['body']['patient']['gender'],
                  },
                  signatureSource: undefined,
                  org: orgId,
                  orgUnit: orgUnitId ?? undefined,
                  professional: basicPrescription.professional,
                  specialty,
                },
              })
              .then(res => {
                goBackToListDocument?.();
              })
              .catch(err => {
                console.error(err);
              });

            return;
          }

          makeReduxCreateClinicalDocs().create({
            ...dataToSend,
            goBackToListDocument,
          });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  function next(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(1);
  }

  function back(dados: iValidation) {
    setBasicPrescription({ ...basicPrescription, ...dados });
    handleBar(-1);
  }

  const phaseStatus = useMemo(() => {
    const phaseMap: { [key: number]: string } = {
      1: `${translator('Informações gerais')}`,
      2: `${translator('Dados do Paciente')}`,
      3: `${translator('Prescrição')}`,
      4: `${translator('Assinatura do documento')}`,
    };
    return `${translator('Etapa')} ${phases} ${translator('de')} ${
      Object.entries(phaseMap).length
    }: ${phaseMap[Number(phases)]}`;
  }, [phases]);

  return (
    <>
      <Header>Receita Simples</Header>
      <Content>
        <Main>
          <p style={{ marginBottom: '20px' }}>{phaseStatus}</p>
          {phases === 1 && (
            <GeneralData
              professional={props?.professional}
              consultant={props?.consultant}
              next={next}
              state={basicPrescription}
            />
          )}
          {phases === 2 && (
            <PacientData
              next={next}
              back={back}
              state={basicPrescription}
              consultant={props?.consultant || []}
            />
          )}
          {phases === 3 && (
            <Medication
              arrId={updateId}
              setArrId={setUpdateId}
              next={next}
              back={back}
              state={basicPrescription}
              items={drugs}
              setItems={setDrugs}
            />
          )}
          {phases === 4 && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
              }}
            >
              <Signature
                changeSelected={(e: {
                  certificateId: string;
                  certificate: string;
                  signatureSource: string;
                }) =>
                  setBasicPrescription({
                    ...basicPrescription,
                    certificate: e.certificate,
                    certificateId: e.certificateId,
                    signatureSource: e.signatureSource,
                  })
                }
                changeDisabled={(state: boolean) => setDisableButton(state)}
                onSignSubmit={createBasicPrescription}
              />
            </div>
          )}
        </Main>

        {phases === 4 ? (
          <ButtonStyle>
            <Button
              id="button_back"
              rounded
              variant="secundary"
              onClick={() => handleBar(-1)}
              size="small"
            >
              {`${translator('Voltar')}`}
            </Button>
            <Button
              id="button_conclude"
              rounded
              size="small"
              onClick={createBasicPrescription}
              disabled={loading || disableButton}
            >
              {`${translator('Concluir')}`}
            </Button>
          </ButtonStyle>
        ) : null}
      </Content>
    </>
  );
};

export default ConnectComponent(SimplePrescription);
