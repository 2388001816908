import { CompanionsAppointment } from '~/domain/usecases/appointment/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteCompanionsAppointment implements CompanionsAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<CompanionsAppointment.Model>;

  constructor(url: string, httClient: HttpClient<CompanionsAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  companions = async (
    params: CompanionsAppointment.Params,
  ): Promise<CompanionsAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url.replace('{appointmentId}', params.appointmentId)}`,
      method: 'post',
      body: {
        ...params,
        appointmentId: undefined,
      },
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
