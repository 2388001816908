/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useMemo, useState } from 'react';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { IconSuccessIcon } from '~/presentation/base/icons';
import {
  ContainerModal,
  Body,
  Container,
  Footer,
  Header,
  HeaderTitle,
  RadioContainer,
} from './styles/StyledModalFinishInterconsult';
import { Button } from '../UI';
import { translator } from '../i18n';

interface ownProps {
  message: iMessage;
}

const ModalFinishInterconsult: React.FC<ownProps> = ({ message }) => {
  const { active, actionOk, actionCancel, data } = message;
  const msgName = MessageOptions.finishInterconsult;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <IconSuccessIcon />
              <HeaderTitle>
                <div className="title">Seu atendimento foi concluído.</div>
                <div className="text">
                  Para encerrar, selecione o motivo de desfecho:
                </div>
              </HeaderTitle>
            </Header>
            <Body>
              <RadioContainer>
                <input type="radio" name="option1" id="option1" />
                Alta clínica (Paciente liberado)
              </RadioContainer>
              <RadioContainer>
                <input type="radio" name="option2" id="option2" />
                Permanência (Paciente permanece na unidade)
              </RadioContainer>
              <RadioContainer>
                <input type="radio" name="option3" id="option3" />
                Encaminhamento (Encaminhado para outra unidade)
              </RadioContainer>
              <RadioContainer>
                <input type="radio" name="option3" id="option3" />
                Transferência (Recomendação de transferência)
              </RadioContainer>
              <RadioContainer>
                <input type="radio" name="option3" id="option3" />
                Retorno (Tratamento não hospitalar)
              </RadioContainer>
              <RadioContainer>
                <input type="radio" name="option3" id="option3" />
                Óbito (Falecimento do paciente)
              </RadioContainer>
            </Body>
            <Footer>
              <Button variant="secundary" size="bigger">
                {translator('Cancelar')}
              </Button>
              <Button variant="primary" size="bigger">
                {translator('Salvar desfecho')}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalFinishInterconsult;
