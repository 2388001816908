import { Dispatch } from 'redux';
import store from '~/data/store';
import { set } from '~/data/store/reducer/showModal/actions/set';

import { iActionSet } from '~/domain/interfaces/redux/showModal/set';

import { ShowModal } from '~/domain/usecases/showModal/redux';

export class ReduxSetShowModal implements ShowModal {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  set = (params: ShowModal.Params): iActionSet => this.send(set(params));
}
