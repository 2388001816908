import { CreateProfessional } from '~/domain/usecases/professionalList/redux/CreateProfessional';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  ProfessionalTypes,
} from '~/domain/interfaces/redux/professionalList/create';

export const createRequest = (
  payload: CreateProfessional.Params,
): iActionCreate => ({
  type: ProfessionalTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateProfessional.Model,
): iActionCreateSuccess => ({
  type: ProfessionalTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: ProfessionalTypes.CREATE_FAILED,
});
