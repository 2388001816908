/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';

import {
  Body,
  Container,
  Content,
  Footer,
  Header,
  RadioButtonBox,
  RadioButtonBoxInput,
  RadioButtonLabel,
} from './styles/StyledAppointmentSOAP';

import { Button } from '../../UI';

import { ownProps } from '../interface';

import { IconAlert } from '~/presentation/base/icons';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { iAppointmentOutcome } from '~/domain/usecases/appointment/remote';
import { makeRemoteGetAppointmentOutcome } from '~/main/factories/usecases/appointment/GetAppointmentOutcomeFactory';
import { makeRemoteStopAppointment } from '~/main/factories/usecases/appointment/StopAppointmentFactory';
import { translator } from '../../i18n';
import { History } from '~/main/routes';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { makeRemoteStopAppointmentOnCall } from '~/main/factories/usecases/onCall/StopAppointmentOnCallFactory';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';

const AppointmentSOAP: React.FC<ownProps> = ({ message }) => {
  const [appointmentOutcomes, setAppointmentOutcomes] = useState<
    iAppointmentOutcome[]
  >([]);
  const [selectedOutcome, setSelectedOutcome] = useState<iAppointmentOutcome>(
    {} as iAppointmentOutcome,
  );
  const [isLoading, setIsLoading] = useState(true);

  const msgName = MessageOptions.appointmentSOAP;
  const { active, actionCancel, actionOk, data } = message;
  const isModalActive = active === msgName;

  // TODO: Verificar traduções

  useEffect(() => {
    if (isModalActive) {
      console.log('>>> data leave interconsult', data.onCall);
      makeRemoteGetAppointmentOutcome()
        .getRecordings({})
        .then(response => {
          setAppointmentOutcomes(response);
          setIsLoading(false);
        });
    }
  }, [isModalActive]);

  function handleActionOk() {
    if (data?.type === 'onCall') {
      makeRemoteStopAppointmentOnCall()
        .stopAppointment({
          onCallId: Number(data?.id),
          requesterId: Number(data?.requester),
          specialistId: Number(data?.specialist),
          outcome: selectedOutcome.id,
        })
        .then(() => {
          makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
            available: false,
          });
          setSelectedOutcome({} as iAppointmentOutcome);
        })
        .finally(() => {
          actionOk?.();
          setIsLoading(true);
        });

      History.getHistory().replace(`/duty/${data?.name?.toLocaleLowerCase()}`, {
        specialty: data?.name,
        id: data?.id,
        leaveConf: true,
      });

      return;
    }

    makeRemoteStopAppointment()
      .stop({
        id: data.id,
        outcome: selectedOutcome.id,
      })
      .then(() => {
        setSelectedOutcome({} as iAppointmentOutcome);
      })
      .finally(() => {
        actionOk?.();
        setIsLoading(true);
        if (data.onCall && data.onCall.leaveConf) {
          makeReduxActiveMessage().active({
            active: 'isProfessionalAvailable',
            actionOk: () => {
              closeModal();
            },
            data: {
              leaveConf: data.onCall?.leaveConf,
              specialty: data.onCall?.specialty,
              id: data.onCall?.id,
            },
            actionCancel: () => {
              closeModal();
            },
          });
        }
      });
  }

  function handleCancel() {
    actionCancel?.();
    setSelectedOutcome({} as iAppointmentOutcome);
  }

  return (
    <>
      {isModalActive ? (
        <Container>
          <Body>
            <Header>
              <IconAlert />
              <h2>{translator('Seu atendimento foi concluído.')}</h2>
              <h3>
                {translator('Para encerrar, selecione o motivo de desfecho:')}
              </h3>
            </Header>
            <Content>
              {appointmentOutcomes.map(outcome => (
                <RadioButtonBox
                  isSelected={selectedOutcome.id === outcome.id}
                  key={outcome.id}
                >
                  <RadioButtonBoxInput
                    type="radio"
                    value={outcome.id}
                    checked={selectedOutcome.id === outcome.id}
                    onChange={() => setSelectedOutcome(outcome)}
                  />
                  <RadioButtonLabel>
                    <b>{outcome.name}</b>
                    (
                    {outcome.descr}
                    )
                  </RadioButtonLabel>
                </RadioButtonBox>
              ))}
            </Content>
            <Footer>
              <Button
                rounded
                variant="secundary"
                size="large"
                style={{ width: '225px' }} // TODO: custom size as DS button still not available
                onClick={handleCancel}
                autoFocus
              >
                {translator('Cancelar')}
              </Button>
              <Button
                rounded
                variant="primary"
                size="large"
                disabled={selectedOutcome.id === undefined || isLoading}
                style={{ width: '225px' }}
                onClick={handleActionOk}
                autoFocus
              >
                {translator('Salvar desfecho')}
              </Button>
            </Footer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default AppointmentSOAP;
