import { iUser } from '~/domain/interfaces/models/Auth';
import { Login } from '~/domain/usecases/auth/remote';

export const UserInfoDTO = (rawData: Login.Model['user']): iUser => {
  const formattedProfessionals: iUser['professionals'] =
    rawData.professionals?.map(professional => ({
      id: professional.id,
      org: {
        id: professional.org.id,
        shortname: professional.org.shortname,
        logo: professional.org.logo,
        name: professional.org.name,
      },
      orgUnit: {
        id: professional.orgUnit.id,
        logo: professional.orgUnit.logo,
        name: professional.orgUnit.name,
      },
      professions: {
        id: professional.profession,
        name: professional.professionName,
        specialties:
          professional.specialties?.map(specialty => ({
            id: specialty.id,
            name: specialty.name,
            docProf: specialty.docProf,
            docProfUF: specialty.docProfUF,
            docProfIssued: specialty.docProfIssued,
          })) || [],
      },
    }));

  return {
    address: rawData.address,
    administrators: rawData.administrators,
    consultants: rawData.consultants,
    organizers: rawData.organizers,
    professionals: formattedProfessionals,
    user: {
      avatar: rawData.avatar,
      birthdate: rawData.birthdate,
      email: rawData.email,
      firstName: rawData.firstName,
      id: rawData.id,
      lastName: rawData.lastName,
      login: rawData.login,
      mainRole: rawData.role,
      motherName: rawData.motherName,
      phone: rawData.phone,
      phone2: rawData.phone2,
      phone3: rawData.phone3,
      documents: rawData.documents,
    },
  };
};
