import React, { useState } from 'react';
import { ContainerFooter } from './styles/StyledFooterChat';
import SendMessage from '~/presentation/base/icons/SendMessage.png';

interface ownProps {
  sendMessage: Function;
  emiter: number;
  chatId: number;
}

const FooterChat = (props: ownProps) => {
  const { sendMessage, emiter, chatId } = props;

  const [text, setText] = useState('');

  const submitMessage = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(
      'socket',
      sendMessage().createMessage({ chatId, message: text }),
    );
    // console.log('# Send Message for', chatId);
    setText('');
  };

  return (
    <ContainerFooter onSubmit={submitMessage}>
      <input
        type="text"
        placeholder="Escreva sua mensagem"
        value={text}
        onChange={e => {
          setText(e.target.value);
        }}
      />
      <button type="submit">
        <img src={SendMessage} alt="Enviar Mensagem" />
      </button>
    </ContainerFooter>
  );
};

export default FooterChat;
