import {
  ClinicalDocsTypes,
  iSelectOne,
  iSelectOneSuccess,
} from '~/domain/interfaces/redux/clinicalDocs/selectOne';

import { SelectOne } from '~/domain/usecases/clinicalDocs/redux/SelectOne';

export const selectOneRequest = (params: SelectOne.Params): iSelectOne => ({
  type: ClinicalDocsTypes.SELECT_ONE,
  payload: params,
});

export const selectOneRequestSuccess = (
  params: SelectOne.Params,
): iSelectOneSuccess => ({
  type: ClinicalDocsTypes.SELECT_ONE_SUCCESS,
  payload: params.id,
});
