import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDataPagination } from '~/main/factories/usecases/dataPagination/SetDataFactory';
import { History } from '~/main/routes';
import { DocCrossInverse, DocumentCross } from '~/presentation/base/icons';
import { FilterClinicalDocs } from '~/presentation/components/filter';

import { HeaderSimples } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { ConnectClinicalDocs } from '~/presentation/components/ListProfessional/mapper/MapperListClinicalDocument';
import { Menu } from '~/presentation/components/menu';
import { SearchListClinicalDocs } from '~/presentation/components/search-list';
import { TablePagination } from '~/presentation/components/tablePagination';
import { Button } from '~/presentation/components/UI';
import {
  iListClinicalDocument,
  ListClinicalDocument,
} from '../../../../components/ListProfessional';

import {
  Container,
  Body,
  HeaderList,
  Wrapper,
  Attendiment,
} from './StyledSchedules';
import { LockScroll } from '~/presentation/pages/participant/clinicalDocument/StyledSchedules';

export interface pageProps {
  data: iListClinicalDocument[];
}

interface iState {
  appointmentId: string;
  professional: string;
  consultant: string;
  consultantId: string;
}

const ClinicalDocuments: React.FC<pageProps> = ({ data }): JSX.Element => {
  const auth = useSelector((store: iStore) => store.auth.info);
  const { role, orgUnitId } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const stateParams = useLocation<iState>().state;

  const [state, setState] = useState(data);

  const professionalId = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  useEffect(() => {
    if (data.length === 0) {
      makeReduxDataPagination().set([]);
    }
    setState(data);
  }, [data]);

  return (
    <Container>
      <LockScroll>
        <Menu />
        <HeaderSimples
          title={`${translator('Documentos clínicos')}`}
          value={state.length}
          valueDescription="documentos"
          icon={DocumentCross}
        />
        <HeaderList>
          {state.length > 0 && state !== undefined ? (
            <TablePagination data={state} itemPerPage={10} />
          ) : (
            <div />
          )}
          <Wrapper>
            <div>
              <SearchListClinicalDocs>
                <FilterClinicalDocs />
              </SearchListClinicalDocs>
            </div>
            <Button
              id="new_clinicalDocs"
              size="large"
              resourcers={['CLINICAL_DOC']}
              actions={[
                'CREATE_OWN',
                'CREATE_ORG',
                'CREATE_UNIT',
                'CREATE_ANY',
              ]}
              onClick={() =>
                History.getHistory().push('/clinicaldoc/new', {
                  consultant: stateParams?.consultantId,
                  professional: role === 'PRO' && professionalId,
                })
              }
              rounded
            >
              <Attendiment>
                <DocCrossInverse style={{ width: '45px', height: '45px' }} />
              </Attendiment>
              <text>{translator('Novo documento')}</text>
            </Button>
          </Wrapper>
        </HeaderList>
      </LockScroll>

      <Body>
        <ListClinicalDocument />
      </Body>
    </Container>
  );
};

export default ConnectClinicalDocs(ClinicalDocuments);
