import { RemovedOnCallRequester } from '~/domain/usecases/onCallRequester/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteRemovedOnCallRequester implements RemovedOnCallRequester {
  private readonly url: string;

  private readonly httpClient: HttpClient<RemovedOnCallRequester.Model>;

  constructor(
    url: string,
    httClient: HttpClient<RemovedOnCallRequester.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  removed = async (
    params: RemovedOnCallRequester.Params,
  ): Promise<RemovedOnCallRequester.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{requesterId}', params.requesterId.toString()),
      method: 'post',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
