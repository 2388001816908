import { Dispatch } from 'redux';
import store from '~/data/store';
import { getRequest } from '~/data/store/reducer/dashboard/actions/getDashboardUnits';

import { iActionGetDashboardUnits } from '~/domain/interfaces/redux/dashboard/getDashboardUnits';

import { GetDashboardUnits } from '~/domain/usecases/dashboard/redux';

export class ReduxGetDashboardUnits implements GetDashboardUnits {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetDashboardUnits.Params): iActionGetDashboardUnits =>
    this.send(getRequest(params));
}
