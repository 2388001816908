import styled, { css } from 'styled-components';
import { shade } from 'polished';
import BannerV4H from '~/presentation/base/images/BannerV4H.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  background-image: url(${BannerV4H});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* gap: 136px;
  margin: 0 auto; */
`;

export const Left = styled.div`
  width: 38%;
  height: 100%;
  display: grid;
  grid-gap: 48px;
  grid-template-rows: 1fr 1fr 1fr;
  flex-direction: column;
  justify-content: center;
  background: white;
  /* gap: 48px; */
  padding-top: 40px;
  left: 0;

  .text {
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    color: #7a7a7a;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-width: 460px;
  padding: 20px 30px;
  gap: 16px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  text-align: start;
  margin-bottom: 20px;
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
`;

export const Right = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  position: relative;

  .middle-line {
    height: 8px;
    width: 85px;
    border: 1px solid white;
    border-radius: 4px;
    background: white;
  }

  span {
    font-size: 18px;
    width: 37%;
    color: white;
    text-overflow: ellipsis;
    text-align: center;
    justify-content: center;
  }
`;

export const LinkContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${props => props.theme.primary};
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 30%;
  height: 100%;

  @media (max-width: 1580px) {
    width: 50%;
  }

  @media (max-width: 1300px) {
    width: 70%;
    min-width: 430px;
  }

  @media (max-width: 700px) {
    width: 100%;
    min-width: 300px;

    #logoV4H {
      width: 100%;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #7a7a7a;
  }
`;

export const VersionContentText = styled.a`
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a6a6a6;
  text-align: right;
  align-self: flex-end;

  @media (min-width: 1580px) {
    display: none;
  }
`;

export const ForgotPasswordText = styled.a`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #a6a6a6;

  cursor: pointer;

  &:hover {
    color: #000000;
  }
`;

// export const Header = styled.header`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   /*   height: auto; */
// `;

export const FooterLeft = styled.footer`
  height: 50px;
  position: relative;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #64696f;
  padding-left: 30px;

  gap: 10px;

  a {
    color: inherit;
  }
`;

export const FooterRight = styled.footer`
  position: absolute;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  line-height: 140%;
  color: white;
  margin: 0;
  padding: 0;
  bottom: 7%;

  span {
    font-size: 14px;
  }

  a {
    font-weight: 500;
    margin: 5px;
    color: white;
    text-align: center;
    text-decoration: underline;
    gap: 20px;
  }
`;

export const FooterVersion = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  cursor: pointer;

  a {
    font-size: 10px;
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
`;

export const FooterImgs = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Image = styled.img`
  margin-right: 2px;
`;

const SizeVariant = css`
  text-decoration: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  :hover {
    color: ${shade(0.2, '#1756E8')};
  }
`;

export const SignUp = styled.a`
  ${SizeVariant};
  font-weight: bold;
  font-size: 18px;
`;

export const Forgotpass = styled.a`
  ${SizeVariant};
  text-decoration: underline;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2489f3;
`;

export const RemindMe = styled.div`
  display: flex;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #7a7a7a;
`;
