import {
  parseISO,
  format,
  formatRelative,
  formatDistance,
  getMonth,
} from 'date-fns';

export const makeTime = (map: any) => {
  if (map !== undefined && map.appointment.scheduled !== '') {
    const item = map.appointment.scheduled;
    const time = new Date(item);
    const timeStart = new Date(time.getTime()).toLocaleTimeString('pt-BR', {
      hour12: false,
    });

    const { duration } = map.appointment;
    const timeEnd = new Date(
      time.getTime() + duration * 60000,
    ).toLocaleTimeString('pt-BR', { hour12: false });

    const startFormat = `${timeStart.split(':')[0]}:${timeStart.split(':')[1]}`;
    const endFormat = `${timeEnd.split(':')[0]}:${timeEnd.split(':')[1]}`;

    return `${startFormat} - ${endFormat}`;
  }
  return '';
};

export const getDay = (map: any) => {
  if (map !== undefined && map.appointment.scheduled) {
    const firstDate = parseISO(map.appointment.scheduled);
    const monthname = getMonth(firstDate);
    const formattedDate = format(firstDate, "dd 'de' MMMM 'de' yyyy");

    return new Date(map.appointment.scheduled).toLocaleString('pt-BR', {
      year: 'numeric',
      day: 'numeric',
      month: 'long',
    });
  }

  return '';
};
