import { call, put } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/Organizer/getAll';
import { GetAllOrganizer } from '~/domain/usecases/organizer/redux/GetAllOrganizer';
import { makeRemoteGetAllOrganizer } from '~/main/factories/usecases/organizer/GetAllOrganizer';

import {
  getAllSuccess,
  getAllFailed,
} from '~/data/store/reducer/organizer/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllOrganizer = makeRemoteGetAllOrganizer();

  try {
    const response: GetAllOrganizer.Model = yield call(
      remoteGetAllOrganizer.getAll,
      action.payload,
    );
    yield put(getAllSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getAllFailed());
  }
}

export function* onGetAllSuccess() {
  yield 10;
}

export function* onGetAllFailed() {
  yield 10;
}
