import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

import { V4hSpin } from '~/presentation/components/spin';
import { Unauthorized } from '~/presentation/pages/errors';

import InviteProfessional from '~/presentation/pages/inviteProfessional';

export const InviteProfessionalFactory: React.FC = () => {
  const loading = useSelector((state: iStore) => state.clinicalDocs.loading);
  const { role } = useSelector((state: iStore) => state.auth.selectUser);

  if (loading) return <V4hSpin fullScreen background="white" />;

  switch (role) {
    case 'PRO':
      return <InviteProfessional />;
    default:
      return <Unauthorized />;
  }
};
