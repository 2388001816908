import React from 'react';

import { Body, Content, Text } from './styles/StyledLeaveSpecialty';
import { ConfirmCancel } from './styles';
import { Container } from '../Background';

import { Button } from '~/presentation/components/UI';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { ownProps } from '../interface';
import { translator } from '../../i18n';

const UserLeaveSpecialty: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.leaveSpecialty;

  const { active, actionOk, actionCancel } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Content>
              <p>{`${translator('Sair sem salvar')}?`}</p>
            </Content>
            <Text>
              {translator('Se você sair agora, as alterações feitas')}
              <strong>{` ${translator('não serão salvas.')}`}</strong>
            </Text>
            <span>
              {translator(
                'Para salvá-las, clique no botão Salvar antes de sair.',
              )}
            </span>
            <Button
              rounded
              id="btn_cancel"
              variant="primary"
              size="large"
              onClick={actionCancel}
              style={{ marginTop: '28px' }}
              autoFocus
            >
              {translator('Voltar para edição')}
            </Button>
            <ConfirmCancel
              id="btn_leave"
              onClick={actionOk}
              style={{ marginTop: '12px' }}
            >
              {translator('Sair sem salvar')}
            </ConfirmCancel>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default UserLeaveSpecialty;
