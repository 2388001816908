import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { Shift } from '~/domain/interfaces/models/Shifts';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxDeleteShifts } from '~/main/factories/usecases/shifts';
import {
  Container,
  ListHeader,
  Table,
  TableContainer,
  Td,
  Th,
  Tr,
} from '~/presentation/components/UI/table/styles';
import { ActionsList } from '~/presentation/components/actionsList';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { ContainerImage } from '~/presentation/components/avatar-roles/styles/styled';
import { NavigationBar } from '~/presentation/components/navigationBar';
import { useShifts } from '~/presentation/hooks/shifts';
import { closeModal } from '~/utils/closeModal';
import {
  ProfessionalBadge,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
} from '../styles';
import { columns } from './columns';

type Row = Shift;

const ProfessionalsLayoverTable: React.FC = () => {
  const shifts = useSelector((store: iStore) => store.shifts);
  const { labels, getWeekShiftsByDate, dayActive, setDayActive } = useShifts();

  const handleGetWeekShiftsByDate = useCallback(
    (newDay: number) => {
      setDayActive(newDay);
      getWeekShiftsByDate();
    },
    [getWeekShiftsByDate, setDayActive],
  );

  console.log('>>> shifts: ', shifts);

  return (
    <Container>
      <ListHeader style={{ padding: 0 }}>
        <NavigationBar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            itemWidth: '100%',
          }}
          hasSubtitle
          active={dayActive}
          clickLabel={handleGetWeekShiftsByDate}
          labels={labels}
        />
      </ListHeader>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              {columns.map(column => (
                <Th
                  style={
                    column.id === 'actions'
                      ? {
                          textAlign: 'right',
                        }
                      : {}
                  }
                >
                  {column.label}
                </Th>
              ))}
            </tr>
          </thead>
          <tbody>
            {shifts.shifts.map(shift => (
              <TableRowItem onClick={() => console.log('click')} row={shift} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};

type TableRowItemProps = {
  row: Row & { active?: boolean };
  active?: boolean;
  onClick: () => void;
};

const TableRowItem: React.FC<TableRowItemProps> = ({ row, ...rest }) => {
  const { user, professionals } = useSelector(
    (store: iStore) => store.auth.info,
  );
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const { shift } = row;

  const { getWeekShiftsByDate } = useShifts();

  const canMadeActions = useMemo(() => {
    if (user?.mainRole === 'ORG') return true;
    if (!professionals) return false;

    const professional = professionals.find(prof => prof.org.id === orgId);

    console.log('>>> professional: ', professional);

    return professional?.id === shift.professional.id;
  }, [orgId, professionals, shift.professional.id, user?.mainRole]);
  console.log('>>> professionals: ', professionals);

  const handleDeleteShift = useCallback(() => {
    makeReduxDeleteShifts().delete({
      onCallId: shift.onCall.id,
      shiftId: shift.id,
    });
    closeModal();
  }, [shift]);

  const deleteShift = useCallback(() => {
    const professionalName = shift.professional.name;

    makeReduxActiveMessage().active({
      active: MessageOptions.delete,
      title: 'Excluir escala',
      content: `Você tem certeza que deseja excluir esta escala do profissional ${professionalName}? Será impossível desfazer a ação após a exclusão.`,
      actionCancel: () => closeModal(),
      actionOk: () => handleDeleteShift(),
    });
  }, [handleDeleteShift, shift]);

  const handleEditShift = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.editShifts,
      data: shift,
      actionOk: () => getWeekShiftsByDate(),
    });
  }, [getWeekShiftsByDate, shift]);

  const { active } = useSelector((store: iStore) => store.message);

  useEffect(() => {
    setTimeout(() => {
      getWeekShiftsByDate();
    }, 250);
  }, [active, getWeekShiftsByDate]);

  return (
    <Tr {...rest}>
      <Td>
        <ProfessionalBadge>
          <ContainerImage id="profile">
            <AvatarImage
              size="root"
              name={shift.professional.name}
              src={shift.professional.avatar}
            />
          </ContainerImage>
          <ProfessionalBadgeInfo>
            <ProfessionalBadgeName>
              {shift.professional.name}
            </ProfessionalBadgeName>
            <ProfessionalBadgeRole>
              {shift.professional.profession.name}
            </ProfessionalBadgeRole>
          </ProfessionalBadgeInfo>
        </ProfessionalBadge>
      </Td>
      <Td>{shift.begin}</Td>
      <Td>{shift.end}</Td>
      <Td>
        {Intl.DateTimeFormat('pt-BR', {
          dateStyle: 'short',
        }).format(new Date(shift.date))}
      </Td>
      <Td>
        <ActionsList
          actions={{
            ...(canMadeActions && {
              deleteOnCall: () => deleteShift(),
              editOnCall: () => handleEditShift(),
            }),
          }}
        />
      </Td>
    </Tr>
  );
};

export default ProfessionalsLayoverTable;
