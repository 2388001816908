import styled from 'styled-components';

import { ExpandIcon } from '~/presentation/base/icons';

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

interface TextProps {
  bold?: boolean;
  clickable?: boolean;
}

export const PatientInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ContentHeaderTitle = styled.text<TextProps>`
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 14px;
  line-height: 16px;
  color: ${props => (props.clickable ? '#1756E8' : '#656a6e')};
`;

export const Expand = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;

export const Icon = styled(ExpandIcon)`
  width: 13px;
  height: 13px;

  margin-left: 8px;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;

  margin-top: 33px;
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;

  margin-top: 8px;
  margin-bottom: 26px;
`;

export const FooterText = styled.text`
  font-weight: normal;
  font-size: 12px;
  color: #bfc4ca;
`;

export const Pagination = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
