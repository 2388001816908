import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { iListRegisteredParticipant } from '../interface';
import { iConsultant, iStore } from '~/domain/interfaces/models';
import { pageProps } from '~/presentation/roles/manager/pages/ListParticipant/';
import { getLocale } from '~/utils/getLocale';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectParticipant<P>(
  Component: ComponentType<P & pageProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const data = useSelector((store: iStore) => store.consultant);
    /* eslint no-param-reassign: "error" */

    Component.defaultProps = {
      // Default descending order for date
      data: _.orderBy(
        MapParticipantData(data),
        item => {
          const splitDate = item.date.split('/');
          const internationalDate =
            splitDate.length < 3
              ? item.date
              : `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;

          return new Date(internationalDate).getTime();
        },
        ['desc'],
      ),
      ...(rest as P),
    };

    return <Component {...(rest as P & pageProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
const MapParticipantData = (
  data: iConsultant,
): iListRegisteredParticipant[] => {
  const arr: iListRegisteredParticipant[] = [];

  if (!(data.results.length > 0)) return arr;
  data.results.forEach(item => {
    const date = item.consultant.created.split('T')[0].split('-');
    const dateFormated = new Date(
      `${date[1]}-${date[2]}-${date[0]}`,
    ).toLocaleDateString(getLocale());

    if (item.consultant.situation === 'ACTIVE')
      arr.push({
        date: dateFormated,
        fullName: `${item.user.firstName} ${item.user.lastName}`,
        age: item.user.birthdate,
        phone: item.user.phone,
        email: item.user.email,
        avatar: item.user.avatar,
        cod: item.consultant.id.toString(),
        userId: item.user.id,
        status: item.consultant.situation,
      });
  });

  return arr;
};
