import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${props => props.theme.background};
  overflow: auto;
  max-height: 100vh;
`;

export const ContentBody = styled.div`
  height: 80%;
  padding: 0 20px;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 25px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 9px;
`;

export const ContentHeader = styled.div`
  width: 100%;
  height: 20%;
`;

export const ContentCards = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 40px;
  padding: 40px 20px 30px 20px;
`;

export const CardLeft = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const Divider = styled.div`
  width: 100%;
  margin: 10px 0;
  height: 2px;
  background: #efefef;
`;

export const CardRight = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: #fff;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 30%;

  padding: 20px 25px 20px 20px;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
`;

export const RefreshContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 30%;
  height: 100%;
`;

export const StorageContainer = styled.div`
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 329px;
`;

export const StorageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StorageInfo = styled.div`
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    text-align: end;

    color: #a7a7a7;
    span {
      color: black;
    }
  }
`;

export const Legend = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  text-align: center;

  .text {
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    text-align: center;
    color: #a7a7a7;
  }

  .inUse {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 13px;
    .color {
      width: 9px;
      height: 9px;
      background-color: #004ba5;
      border-radius: 50%;
    }
  }

  .free {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 13px;
    .color {
      width: 9px;
      height: 9px;
      background-color: #e3e3e3;
      border-radius: 50%;
    }
  }
`;

export const StorageDetails = styled.div`
  width: 100%;

  .storageType {
    display: flex;
    align-items: center;
    height: 38px;
    justify-content: space-between;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #252733;
    border-top: 1px solid #f8f9fa;
    .value {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      color: #a7a7a7;
    }
  }
`;
