import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iSignClinicalDocs } from '~/domain/interfaces/redux/clinicalDocs/sign';

import {
  GetClinicalDocs,
  GetAllClinicalDocs,
} from '~/domain/usecases/clinicalDocs/remote';
import { Sign } from '~/domain/usecases/deamon/remote';
import { SignClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/SignClinicalDocs';

import {
  SignFailed,
  SignSuccess,
} from '~/data/store/reducer/clinicalDocs/actions/sign';

import { makeRemoteUpdateClinicalDocs } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsFactory';
import {
  makeReduxGetClinicalDocs,
  makeRemoteGetClinicalDocs,
} from '~/main/factories/usecases/clinicalDocs/GetClinicalDocsFactory';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { makeRemoteUpdateClinicalDocsBySign } from '~/main/factories/usecases/clinicalDocs/UpdateClinicalDocsBySignFactory';
import { makeRemoteSignDocument } from '~/main/factories/usecases/daemon/Sign';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onSign(action: iSignClinicalDocs) {
  try {
    yield SignDoc(action.payload);

    // yield UpdateCertificateBackend(action.payload);

    // const response: Sign.Model = yield SignDocumentWithLedger(action.payload);

    // yield SendSignatureValueBackend(action.payload, response);

    // yield makeReduxGetAllClinicalDocs().getAll({});
  } catch (e) {
    yield put(SignFailed());
  }
}

function* UpdateCertificateBackend(payload: SignClinicalDocs.Params) {
  const remoteCreateClinicalDocs = makeRemoteUpdateClinicalDocs();

  if (!payload.docId) throw new Error();

  const response: SignClinicalDocs.Model = yield call(
    remoteCreateClinicalDocs.patch,
    {
      certificate: payload.certificate,
      docId: payload.docId,
    },
  );

  makeReduxGetClinicalDocs().getById({ docId: payload.docId });
}

function* SignDocumentWithLedger(payload: SignClinicalDocs.Params) {
  const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();
  const remoteSignClinicalDocs = makeRemoteSignDocument();

  if (!payload.docId) throw new Error();

  const response: GetClinicalDocs.Model = yield call(
    remoteGetClinicalDocs.get,
    {
      docId: payload.docId,
    },
  );

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    {
      certId: payload.certificateId,
      algorithm: 'SHA256',
      dataToSignArray: [
        {
          dataToSign: response.dataToSign,
          key: 1,
        },
      ],
    },
  );

  return responseSignDocument;
}

function* SendSignatureValueBackend(
  payload: SignClinicalDocs.Params,
  data: Sign.Model,
) {
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  if (!payload.docId) throw new Error();

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: data[0].signature,
  });
}

export function onSignSuccess() {
  AlertMessage({
    type: 'success',
    message: intl.formatMessage({ id: 'Documento assinado com sucesso!' }),
  });
}

export function onSignFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao assinar documento clínico.' }),
  });
}

function* SignDoc(payload: SignClinicalDocs.Params) {
  const remoteCreateClinicalDocs = makeRemoteUpdateClinicalDocs();
  const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();
  const remoteSignClinicalDocs = makeRemoteSignDocument();
  const remoteSendSignatureValueBackend = makeRemoteUpdateClinicalDocsBySign();

  if (!payload.docId || !payload.appointmentId) throw new Error();

  // const response: SignClinicalDocs.Model = yield call(
  //   remoteCreateClinicalDocs.patch,
  //   {
  //     certificate: payload.certificate,
  //     docId: payload.docId,
  //   },
  // );
  // console.log('1. response sign ClinicalDocs BACKEND', response, {
  //   certificate: payload.certificate,
  //   docId: payload.docId,
  // });

  // 2 passo //

  const responseGetClinicalDoc: GetClinicalDocs.Model = yield call(
    getDocDelay,
    {
      docId: payload.docId,
    },
  );

  const responseSignDocument: Sign.Model = yield call(
    remoteSignClinicalDocs.sign,
    {
      certId: payload.certificateId,
      algorithm: 'SHA256',
      dataToSignArray: [
        {
          dataToSign: responseGetClinicalDoc.dataToSign,
          key: 1,
        },
      ],
    },
  );

  yield call(remoteSendSignatureValueBackend.patchBySign, {
    docId: payload.docId,
    signatureValue: responseSignDocument[0].signature,
  });

  // 4 passo //
  // yield call(getAllDocDelay, {
  //   filter: {
  //     appointment: payload.appointmentId,
  //   },
  // });

  AlertMessage({
    message: intl.formatMessage({ id: 'Documento assinado com sucesso!' }),
    type: 'success',
  });
}

async function getDocDelay(
  data: GetClinicalDocs.Params,
): Promise<GetClinicalDocs.Model> {
  const remoteGetClinicalDocs = makeRemoteGetClinicalDocs();

  const response: GetClinicalDocs.Model = await new Promise(
    (resolve, reject) => {
      setTimeout(async () => {
        remoteGetClinicalDocs
          .get(data)
          .then(result => resolve(result))
          .catch(e => reject(e));
      }, 6000);
    },
  );

  return response;
}

async function getAllDocDelay(data: GetAllClinicalDocs.Params): Promise<void> {
  const reduxGetAllClinicalDocs = makeReduxGetAllClinicalDocs();

  await new Promise<void>((resolve, reject) => {
    setTimeout(async () => {
      reduxGetAllClinicalDocs.getAll(data);
      resolve();
    }, 6000);
  });
}
