import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
`;

export const Left = styled.div`
  width: 150px;
  margin-left: 32em;
  margin-bottom: 2.5em;
`;

export const Right = styled.div`
  margin-left: 34em;
`;

export const Header = styled.div`
  width: 100%;
  height: 7em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 4px;

  span {
    font-size: 1.3em;
    margin-left: 10px;
    font-weight: bold;
    color: #004ba5;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  position: absolute;
  padding: 0px;
  padding-top: 18px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 30em;
  max-height: 26em;
  width: 100%;
  height: 100%;
  top: 40%;
  left: 50%;
  background-color: #f4f7fe;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const FirstMessage = styled.span`
  width: 100%;
  height: 3em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;

  color: black;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: center;
  text-overflow: ellipsis;
`;

export const RadioBox = styled.div`
  display: inline;
  height: 8em;
  padding-top: 20px;
  border-bottom: 1px solid #efefef;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
`;
