import React from 'react';
import { IconAppointmentEmpty } from '~/presentation/base/icons';
import { translator } from '~/presentation/components/i18n';

import { Container, Icon } from './styles/Styled';
import InstantAppointmentModal from '../instantAppointmentModal';
import InstantAppointmentSuccessModal from '../InstantAppointmentSuccessModal';

interface ExampleProps {
  props?: string;
}

const AppointmentEmpty: React.FC<ExampleProps> = () => {
  return (
    <Container>
      <Icon>
        <IconAppointmentEmpty />
      </Icon>
      <text>
        {`${translator(
          'Seus agendamentos aparecerão aqui. Não há itens a serem exibidos no momento.',
        )}`}
      </text>
    </Container>
  );
};
export default AppointmentEmpty;
