import { DownloadByCode } from '~/domain/usecases/supportDoc/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

export class RemoteDownloadByCodeSupportDocs implements DownloadByCode {
  private readonly url: string;

  private readonly httpClient: HttpClient<DownloadByCode.Model>;

  constructor(url: string, httClient: HttpClient<DownloadByCode.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: DownloadByCode.Params,
  ): Promise<DownloadByCode.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/details/${params.code}/download/${params.docId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
