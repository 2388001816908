import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

export const Header = styled.div`
  width: 100%;
  height: 3.3em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-bottom: 2px solid #efefef;
  color: #7a7a7a;

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #000;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 33em;
  max-height: 24em;
  width: 100%;
  height: 100%;

  position: fixed;
  background: white;
  padding: 0px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const CancelBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 20em;
  max-height: 12em;
  width: 100%;
  height: 100%;

  position: fixed;
  background: white;
  padding-top: 50px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 50%;

  p {
    color: black;
    font-size: 1em;
    text-align: center;
    text-overflow: ellipsis;

    width: 480px;
    margin-left: 18px;
  }
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 19px;
`;

export const CloseButton = styled.div`
  padding: 10px 18px 5px 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #c9c9c9;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
`;

export const ConfirmCancel = styled.button`
  width: 240px;
  height: 38px;
  margin-top: 11px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 2px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;
