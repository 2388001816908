import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  min-height: 10vh;
  height: 100%;
  background: #e5e5e5;
  overflow: auto;
  padding-bottom: 20px;
`;

export const Header = styled.header`
  width: 100%;
  height: 50px;
  background: ${props => props.theme.primary};
`;

export const Line = styled.div`
  width: 100%;
  min-height: 10vh;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2em;
`;

export const Body = styled.div`
  display: grid;
  margin: 100px 100px 0px 100px;
  grid-template-areas: 'session navi' 'events navi';
  grid-template-columns: 40% 60%;
  gap: 20px;
`;

export const Session = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  justify-content: flex-start;
  grid-area: session;
`;

export const Events = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  grid-area: events;
`;

export const NavContent = styled.div`
  width: 100%;
  grid-area: navi;
  height: 100%;
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  .spin {
    position: relative;
    top: 50%;
    left: 50%;
  }
`;

export const Nav = styled.div`
  border-radius: 8px;
  background: #f4f7fe;
`;

export const ContentComponents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .spin {
    position: relative;
    top: 50%;
  }
`;
