import React, { useEffect } from 'react';
import { UploadFiles } from '~/presentation/components/uploadFiles';
import { makeReduxGetAllSupportDocs } from '~/main/factories/usecases/supportDoc/GetAllSupportDocsFactory';

export const MakeAppointmentDetailsPage: React.FC = () => {
  const supportDoc = makeReduxGetAllSupportDocs();

  useEffect(() => {
    supportDoc.getAll({});
  }, []);

  return <UploadFiles />;
};
