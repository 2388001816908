import { SocketClient } from '~/data/protocols/socket';
import { CreateSocketChatMessage } from '~/domain/usecases/chat/socket';

export class SocketCreateChatMessage implements CreateSocketChatMessage {
  private readonly event: string;

  private readonly SocketClient: SocketClient<any>;

  constructor(event: string, socketClient: SocketClient<any>) {
    this.event = event;
    this.SocketClient = socketClient;
  }

  createMessage = async (
    params: CreateSocketChatMessage.Params,
    fallback?: Function,
  ): Promise<CreateSocketChatMessage.Model> => {
    const SocketResponse = await this.SocketClient.emit({
      event: this.event,
      body: params,
    });

    if (fallback) fallback(SocketResponse.body);
    return SocketResponse.body;
  };
}
