import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { CreateUser } from '~/domain/usecases/users/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export class RemoteCreateUser implements CreateUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateUser.Model>;

  constructor(url: string, httClient: HttpClient<CreateUser.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (params: CreateUser.Params): Promise<CreateUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    console.log('Resposta do servidor ao criar um usuário:', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Falha ao registrar usuário.' }),
        });
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Falha ao registrar usuário.' }),
        });
        throw new InvalidCredentialsError();
      case HttpStatusCode.conflict:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'E-mail ou CPF já utilizado.' }),
        });
        throw new InvalidCredentialsError();
      default:
        AlertMessage({
          type: 'danger',
          message: intl.formatMessage({ id: 'Falha ao registrar usuário.' }),
        });
        throw new UnexpectedError();
    }
  };
}
