import React from 'react';
import {
  Container,
  Header,
  Title,
  Content,
  Footer,
} from './styles/StyledModalInterconsult';
import { Button } from '../UI';
import { translator } from '../i18n';

interface ownProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  titleOk: string;
  onOk: () => void;
  titleCancel: string;
  onCancel: () => void;
}

const ModalInterconsult = ({
  icon,
  title,
  description,
  titleOk,
  onOk,
  titleCancel,
  onCancel,
}: ownProps): JSX.Element => {
  return (
    <Container>
      <Header>{icon}</Header>
      <Title>{title}</Title>
      <Content>{description}</Content>
      <Footer>
        <Button variant="primary" size="bigger" onClick={onOk}>
          {titleOk}
        </Button>
        <Button variant="secundary" size="bigger" onClick={onCancel}>
          {titleCancel}
        </Button>
      </Footer>
    </Container>
  );
};

export default ModalInterconsult;
