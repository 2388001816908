import { AppointmentReportPdf } from '~/domain/usecases/report/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteAppointmentReportPdf implements AppointmentReportPdf {
  private readonly url: string;

  private readonly httpClient: HttpClient<AppointmentReportPdf.Model>;

  constructor(url: string, httClient: HttpClient<AppointmentReportPdf.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  download = async (
    params: AppointmentReportPdf.Params,
  ): Promise<AppointmentReportPdf.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
      responseType: 'blob',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
