import React from 'react';

import { Link } from 'react-router-dom';

import { Container } from './styles/StyledReportProblem';

export const ReportProblem: React.FC = (): JSX.Element => {
  return (
    <Container>
      <text>
        Ops... algo de inesperado aconteceu. Por favor, tente novamente mais
        tarde.
      </text>

      <Link to="/appointment">
        <button type="button">Voltar para tela inicial</button>
      </Link>
    </Container>
  );
};
