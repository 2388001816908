import {
  iActionSetData,
  AppointmentTypes,
} from '~/domain/interfaces/redux/appointment/setData';

import { SetData } from '~/domain/usecases/appointment/redux/SetData';

export const setData = (params: SetData.Params): iActionSetData => ({
  type: AppointmentTypes.SET_DATA,
  payload: params,
});
