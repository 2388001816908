import styled from 'styled-components';

interface iBar {
  active: boolean;
  current: boolean;
}

interface iContent {
  active: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div<iContent>`
  position: absolute;
  white-space: nowrap;
  top: -30px;
  color: ${props =>
    props.active ? props.theme.primaryDark : props.theme.typographyLightGray};
`;

export const Point = styled.div<iBar>`
  border-radius: 100%;
  width: ${props => (props.current ? '20px' : '10px')};
  height: ${props => (props.current ? '20px' : '10px')};
  background: ${props =>
    props.active ? props.theme.primaryDark : props.theme.typographyLightGray};
`;
