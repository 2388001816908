import styled from 'styled-components';

export const Header = styled.div`
  width: 65%;
  margin-top: 50px;
  /* margin: 50px auto 0px auto; */
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 20px;
  justify-content: center;
  flex-direction: column;
`;

export const Body = styled.div`
  padding: 15px;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 250px;
`;

export const Footer = styled.div`
  width: 65%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 0.160976px;

    color: #004ba5;
  }
`;

export const Text = styled.div`
  textarea {
    min-width: 596px;
    min-height: 184px;
    resize: none;
    padding: 10px;
    border: 1px solid #c7cbd1;
    box-sizing: border-box;
    border-radius: 3px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #444a51;
    padding-bottom: 10px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 65%;
  gap: 10px;
`;
