import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 360px;
  /* max-width: 360px; */
  min-height: 610px;

  height: 100%;

  background-color: #fdfdfd;
  border: 1px solid #dedede;
  border-radius: 10px;

  justify-content: space-between;
`;

// Start Header Components
export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  column-gap: 10px;
  padding: 12px 16px 15px;

  border-bottom: 1px solid #dedede;
`;

export const ProfessionalInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const UserImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  border-bottom: 1px solid #dedede;

  height: 100%;
`;

export const ConsultInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 8px;
  margin-bottom: 10px;
  width: 100%;

  padding: 12px 16px 16px;
  border-bottom: 1px solid #dedede;
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;

  svg {
    min-width: 18px;
    max-height: 16px;
    margin-bottom: 2px;
  }
`;

export const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  width: 100%;
  height: 100%;
  min-height: 330px;

  padding: 16px;
`;

export const MessagesScroll = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  width: 100%;
  height: 100%;

  row-gap: 10px;

  overflow-y: auto;
  overflow-x: hidden;
`;

export const Message = styled.div`
  display: flex;
  place-items: center;

  overflow-wrap: anywhere;
  text-align: left;

  width: 100%;

  padding: 16px;
  border-radius: 3px;

  background-color: #ecedee;
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
`;

export const CheckBoxInput = styled.input`
  display: flex;
  width: 25px;
  height: 20px;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #818385;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: #62666c;

  word-wrap: unset;
`;
// End Text Components
