import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateProfessional as UsecaseRemoteCreateProfessional } from '~/domain/usecases/professionalList/remote';
import { CreateProfessional as UsecaseReduxCreateProfessional } from '~/domain/usecases/professionalList/redux';

import { RemoteCreateProfessional } from '~/data/repository/professionalList';
import { ReduxCreateProfessional } from '~/data/store/reducer/Professional/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateProfessional =
  (): UsecaseRemoteCreateProfessional =>
    new RemoteCreateProfessional(
      makeApiUrl('/professionals'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxCreateProfessional = (): UsecaseReduxCreateProfessional =>
  new ReduxCreateProfessional();
