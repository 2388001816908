/* eslint-disable prefer-const */
import { differenceInMonths, differenceInYears } from 'date-fns';

export const calculateAgeAndMonths = (birthdate: string): string => {
  let parts = birthdate?.split('/');

  if (!parts || parts?.length <= 2) return 'Idade não informada';

  let day = parseInt(parts?.[0], 10);
  let month = parseInt(parts?.[1], 10) - 1;
  let year = parseInt(parts?.[2], 10);

  const date = new Date(year, month, day);

  const today = new Date();
  const age = differenceInYears(today, date);
  const months = differenceInMonths(today, date) % 12;

  return `${birthdate} (${age} anos e ${months} meses)`;
};
