/* eslint-disable react/react-in-jsx-scope */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { IconCamPlus } from '~/presentation/base/icons';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetProfessionalOnDuty } from '~/main/factories/usecases/duty/GetProfessionalsOnDuty';
import { DutyRequestDetailsCard } from '~/presentation/components/DutyRequestDetailsCard';
import { Button } from '~/presentation/components/UI';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import PatientDutyList from '../Duty/Lists';
import { Wrapper, Box, Title, TitleText, Content } from './styles';
import ProfessionalDutyList from './List';

interface ownProps {
  onCallId: number;
}

interface Params {
  specialty: string;
  healthUnitId: string;
  healthUnitName: string;
  cnes: string;
  city: string;
}

const DutyRequesterPage: React.FC<ownProps> = ({ onCallId }) => {
  const { records } = useSelector((store: iStore) => store.onCallRequest);

  const [selectedRequest, setSelectedRequest] = useState<
    iOnCallRequest['records'][0] | null
  >(null);

  const { state } = useLocation<Params>();

  const info = useMemo(() => {
    const dutyRequesterInfo = records?.find(
      dutyRequester => Number(dutyRequester.id) === Number(selectedRequest?.id),
    );

    return {
      name: dutyRequesterInfo?.consultant?.fullname,
      sex: dutyRequesterInfo?.consultant.sex,
      age: dutyRequesterInfo?.consultant?.birthdate,
      priority: dutyRequesterInfo?.priority,
      specialty: dutyRequesterInfo?.onCall.specialtyName,
      subject: dutyRequesterInfo?.description,
      docValue: dutyRequesterInfo?.consultant?.docValue,
      docType: dutyRequesterInfo?.consultant?.docType,
      requestId: dutyRequesterInfo?.id,
      healthUnitId: dutyRequesterInfo?.healthUnit?.id,
      status: dutyRequesterInfo?.status,
    };
  }, [selectedRequest, records]);

  const openModalRequestConsultation = () => {
    makeReduxActiveMessage().active({
      active: 'requestConsultation',
      data: {
        healthUnitId: state.healthUnitId,
        healthUnitName: state.healthUnitName,
      },
      actionCancel: () => {
        closeModal();
      },
    });
  };

  return (
    <Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          gap: '10px',
        }}
      >
        <Title>
          <TitleText>{`${state.cnes} - ${state.healthUnitName}`}</TitleText>
        </Title>
        <Button
          style={{ marginBottom: 24, width: '25%', height: 48 }}
          onClick={openModalRequestConsultation}
          icon={IconCamPlus}
        >
          Nova solicitação de interconsulta
        </Button>
      </div>
      <Wrapper>
        <PatientDutyList
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          variant="PROFESSIONAL"
          healthUnitId={Number(state.healthUnitId)}
        />
        <DutyRequestDetailsCard
          info={selectedRequest}
          selectedRequester={!!selectedRequest}
          variant="REQUESTER"
          setSelectedRequest={setSelectedRequest}
        />
      </Wrapper>
    </Box>
  );
};

export default DutyRequesterPage;
