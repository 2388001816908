import { CreateChatMessage } from '~/domain/usecases/chat/redux';
import {
  ChatTypes,
  iActionCreateMessage,
  iActionCreateMessageFailed,
  iActionCreateMessageSuccess,
} from '~/domain/interfaces/redux/chat/createMessage';

export const createChatMessageRequest = (
  payload: CreateChatMessage.Params,
): iActionCreateMessage => ({
  type: ChatTypes.CREATE_MESSAGE,
  payload,
});

export const createChatMessageSuccess = (
  params: CreateChatMessage.Model,
): iActionCreateMessageSuccess => ({
  type: ChatTypes.CREATE_MESSAGE_SUCCESS,
  payload: params,
});

export const createChatMessageFailed = (): iActionCreateMessageFailed => ({
  type: ChatTypes.CREATE_MESSAGE_FAILED,
});
