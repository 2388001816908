import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllChatMessage as UsecaseRemoteGetAllChatMessage } from '~/domain/usecases/chat/remote';
import { GetAllChatMessage as UsecaseReduxGetAllChatMessage } from '~/domain/usecases/chat/redux';

import { RemoteGetAllChatMessage } from '~/data/repository/chat';
import { ReduxGetAllChatMessage } from '~/data/store/reducer/chat/usecases';

export const makeRemoteGetAllChatMessage = (): UsecaseRemoteGetAllChatMessage =>
  new RemoteGetAllChatMessage(
    makeApiUrl('/chats/{chatId}/messages'),
    makeHttpClient(),
  );

export const makeReduxGetAllChatMessage = (): UsecaseReduxGetAllChatMessage =>
  new ReduxGetAllChatMessage();
