import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListParticipant } from '~/presentation/roles/manager/pages';
import { makeReduxGetAllUsers } from '~/main/factories/usecases/users/GetAllUsersFactory';
import { iStore } from '~/domain/interfaces/models';
import { RegisteredParticipant } from '~/presentation/roles/professional/pages';

import { PageNotFound, Unauthorized } from '~/presentation/pages/errors';
import { V4hSpin } from '~/presentation/components/spin';
import { makeReduxGetAllProfessionals } from '../../usecases/professional/GetAllProfessionalFactory';
import { makeReduxGetAllConsultant } from '../../usecases/consultant/GetAllConsultant';

export const MakeListParticipantPage: React.FC = () => {
  const { orgId, role, orgUnitId, orgUnits } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  useEffect(() => {
    const orgUnitsFormatted =
      (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

    // makeReduxGetAllUsers().getAll({ page: 0, limit: 0 });
    makeReduxGetAllConsultant().getAll({
      pageSize: 9999,
      filter: {
        org: orgId,
        orgUnit:
          role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
        situation: ['ACTIVE', '2FAWAIT'],
      },
    });
  }, [orgId, role, orgUnits]);

  switch (role) {
    case 'ADM':
      return <ListParticipant />;
    case 'ORG':
      return <ListParticipant />;
    case 'PRO':
      return <RegisteredParticipant />;
    case 'CON':
      return <Unauthorized />;
    default:
      return <PageNotFound />;
  }
};
