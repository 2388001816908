import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeReduxGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';

import { iStore } from '~/domain/interfaces/models';
import { ProfileManager } from '~/presentation/roles/manager/pages/profile';
import { ParticipantPages } from '~/presentation/pages';
import { ProfileProfessional } from '~/presentation/roles/professional/pages/profile';
import { PageNotFound } from '~/presentation/pages/errors';
import { V4hSpin } from '~/presentation/components/spin';

const MakeProfileFactories: React.FC = () => {
  const { selected } = useSelector((state: iStore) => state.users);
  const { role } = useSelector((state: iStore) => state.auth.selectUser);
  const id = useSelector((state: iStore) => state.auth.info.user?.id);

  useEffect(() => {
    makeReduxGetOneUser().getOne({ userId: id! });
  }, []);

  if (!selected.user) return <V4hSpin fullScreen background="white" />;

  switch (role) {
    case 'ORG':
      return <ProfileManager />;
    case 'CON':
      return <ParticipantPages.Profile />;
    case 'PRO':
      return <ProfileProfessional />;
    case 'ADM':
      return <ProfileManager />;

    default:
      return <PageNotFound />;
  }
};

export default MakeProfileFactories;
