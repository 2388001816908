import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
`;

export const Container = styled.div`
  max-width: 900px;
  width: 100%;
`;

export const Form = styled.div`
  display: grid;
  grid-template-columns: 50% 20% 20%;
  grid-template-rows: 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
