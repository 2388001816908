import { AddAppointmentData } from '~/domain/usecases/appointment/redux';
import {
  iActionAddAppointmentData,
  AppointmentTypes,
} from '~/domain/interfaces/redux/appointment/addApointmentData';

export const createRequest = (
  payload: AddAppointmentData.Params,
): iActionAddAppointmentData => ({
  type: AppointmentTypes.ADD_APPOINTMENT_DATA,
  payload,
});
