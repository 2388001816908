import { format } from 'date-fns';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router';
import { GetDiagnosisReportByID } from '~/domain/usecases/diagnosisReport/remote';
import { makeRemoteGetDiagnosisReportByID } from '~/main/factories/usecases/diagnosisReport/GetDiagnosisReportByIDFactory';
import { makeReduxSetupProfession } from '~/main/factories/usecases/profession';
import { useUF } from '../useUF';
import { mapDiagnosisData } from './mapDiagnosisData';

interface GetDiagnosisContextData {
  loading: boolean;
  diagnosisData: GetDiagnosisReportByID.Model | undefined;
  loadDiagnosisData: () => void;
}

const GetDiagnosisContext = createContext<GetDiagnosisContextData | undefined>(
  undefined,
);

export const useGetDiagnosisContext = (): GetDiagnosisContextData => {
  const context = useContext(GetDiagnosisContext);
  if (!context) {
    throw new Error(
      'useGetDiagnosisContext must be used within a MyContextProvider',
    );
  }
  return context;
};

export const GetDiagnosisContextProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [diagnosisData, setDiagnosisData] = useState<
    GetDiagnosisReportByID.Model | undefined
  >(undefined);

  const { setValue } = useFormContext();
  const { handleSelectCity } = useUF();

  const { id } = useParams<{ id: string }>();

  const setValues = async (data: GetDiagnosisReportByID.Model) => {
    const birthDateWithThreeHours = new Date(
      data[data?.consultantExternal ? 'consultantExternal' : 'consultant']
        ?.birthdate ?? new Date(),
    );
    birthDateWithThreeHours.setHours(birthDateWithThreeHours.getHours() + 3);

    const birthDateFormatted = format(birthDateWithThreeHours, 'dd/MM/yyyy');

    // Medical report
    setValue('healthUnit', data?.healthUnit);
    setValue('modality', data?.modality);
    setValue('priority', data?.priority);
    setValue('description', data?.description);
    handleSelectCity({
      id: Number(data?.healthUnit?.city?.codeFull),
      nome: data?.healthUnit?.city?.name,
    });

    // Responsible
    setValue('specialty', data?.specialty);
    setValue('specialist', data?.specialist);
    makeReduxSetupProfession().setup({
      professionId: data?.specialty?.profession?.id,
    });
    setValue('consultantInfo', data?.consultantInfo);

    // Patient
    if (data.consultantExternal && data.consultantExternal !== null) {
      setValue(
        'consultantExternal.fullname',
        data?.consultantExternal?.fullname,
      );
      setValue('consultantExternal', { ...data?.consultantExternal, id: null });
      setValue('consultantExternal.sex', data?.consultantExternal?.sex);
      setValue('consultantExternal.birthdate', birthDateFormatted);
      setValue('consultantExternal.docType', {
        name: data?.consultantExternal?.docType,
      });
      setValue(
        'consultantExternal.docValue',
        data?.consultantExternal?.docValue,
      );
      setValue(
        'consultantExternal.mothername',
        data?.consultantExternal?.mothername,
      );
      setValue('consultantExternal.phone', data?.consultantExternal?.phone);
    } else {
      setValue('consultantExternal', {
        id: data?.consultant?.id,
        fullname: `${data?.consultant?.firstName} ${data?.consultant?.lastName}`,
      });
      setValue('consultantExternal.mothername', data?.consultant?.motherName);
      setValue('consultantExternal.phone', data?.consultant?.phone);
      setValue('consultantExternal.sex', data?.consultant?.sex);
      setValue('consultantExternal.birthdate', birthDateFormatted);
      setValue('consultantExternal.docType', {
        name: data?.consultant?.docType,
      });
      setValue('consultantExternal.docValue', data?.consultant?.docValue);
    }
  };

  const loadDiagnosisData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await makeRemoteGetDiagnosisReportByID().get({
        id: Number(id),
      });

      const responseMapped = mapDiagnosisData(response);

      setDiagnosisData(response);
      setValues(responseMapped);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [id]);

  const contextValue: GetDiagnosisContextData = {
    loading,
    diagnosisData,
    loadDiagnosisData,
  };

  return (
    <GetDiagnosisContext.Provider value={contextValue}>
      {children}
    </GetDiagnosisContext.Provider>
  );
};
