import { call, put } from 'redux-saga/effects';
import { History } from '~/main/routes';
import {
  loginSuccess,
  loginFailed,
} from '~/data/store/reducer/auth/actions/login';

import { makeRemoteLogin } from '~/main/factories/usecases/auth/LoginFactory';

import {
  iActionLogin,
  iActionLoginSuccess,
} from '~/domain/interfaces/redux/auth/Login';

import { Login } from '~/domain/usecases/auth/redux';
import { UserInfoDTO } from '~/domain/dtos/auth/UserInfo';

export function* onLogin(action: iActionLogin) {
  const user = makeRemoteLogin();

  try {
    const responseLogin: Login.Model = yield call(user.login, action.payload);

    const mapToRedux: Login.ModelToRedux = {
      access: responseLogin.access,
      refresh: responseLogin.refresh,
      acl: responseLogin.permissions,
      info: UserInfoDTO(responseLogin.user),
      orgs: responseLogin.orgs,
    };

    yield put(loginSuccess(mapToRedux));
  } catch (e) {
    yield put(loginFailed());
  }
}

export function onLoginSuccess(action: iActionLoginSuccess) {
  sessionStorage.setItem('@atendimento/loggedIn', 'true');
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onLoginFailed() {}
