import styled from 'styled-components';

export const Container = styled.div`
  background: white;
  width: 100%;
  position: fixed;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  /* max-width: 1920px; */
  align-items: center;
  justify-content: space-between;
  /* margin: 4px auto 20px auto; */
`;

export const BaseDrawer = styled.div`
  display: flex;
  flex-direction: column;
  width: 508px;
  height: 100%;
  padding: 20px 30px;
`;

export const HeaderTitle = styled.p`
  font-size: 36px;
  font-weight: 700;
  color: #000000;
`;

export const HeaderSubtitle = styled.p`
  font-size: 18px;
  font-weight: 400;
  color: #000000;
`;

interface TextProps {
  bold?: boolean;
  lighter?: boolean;
}

export const HeaderText = styled.p<TextProps>`
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  color: #000000;
`;

export const DownloadText = styled.p<TextProps>`
  font-size: 18px;
  font-weight: ${({ lighter }) => (lighter ? 400 : 300)};
  color: ${({ lighter }) => (lighter ? '#c4c4c4' : '#000000')};
`;

export const SpacedRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
`;

interface StatusProps {
  status: 'scheduled' | 'in progress' | 'not recorded' | 'finished';
}

export const Status = styled.div<StatusProps>`
  height: 28px;
  padding: 6px 12px;
  border-radius: 10px;

  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: ${({ status }) => {
    switch (status) {
      case 'scheduled':
        return '#45B4F2';
      case 'in progress':
        return '#45F275';
      case 'finished':
        return '#FF5B5B';
      default:
        return '#45B4F2';
    }
  }};
`;

export const CenteredDivider = styled.div`
  width: 100%;
  height: 1px;
  background: #c4c4c4;
`;

export const ContentDivider = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 0px;
  padding-left: 20px;
`;
