import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PausedOnCallSpecialist as UsecaseRemotePausedOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
// import { PausedOnCallSpecialist as UsecaseReduxPausedOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemotePausedOnCallSpecialist } from '~/data/repository/onCallSpecialist';
// import { ReduxPausedOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemotePausedOnCallSpecialist =
  (): UsecaseRemotePausedOnCallSpecialist =>
    new RemotePausedOnCallSpecialist(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}/PAUSED'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxPausedOnCallSpecialist =
  (): UsecaseReduxPausedOnCallSpecialist =>
    new ReduxPausedOnCallSpecialist(); */
