import { call, put } from 'redux-saga/effects';
import { iActionGetDashboardProducts } from '~/domain/interfaces/redux/dashboard/getDashboardProducts';
import { GetDashboardProducts } from '~/domain/usecases/dashboard/remote/GetDashboardProducts';
import {
  getFailed,
  getSuccess,
} from '~/data/store/reducer/dashboard/actions/getDashboardProducts';

import { makeRemoteGetDashboardProducts } from '~/main/factories/usecases/dashboard/GetDashboardProductsFactory';

export function* onGetDashboardProducts(action: iActionGetDashboardProducts) {
  const remoteGetDashboardProducts = makeRemoteGetDashboardProducts();

  try {
    const response: GetDashboardProducts.Model = yield call(
      remoteGetDashboardProducts.get,
      action.payload,
    );

    yield put(getSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDashboardProductsSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDashboardProductsFailed() {}
