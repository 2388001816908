import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: calc(100% - 112px);
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding: 20px;
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: 16px 72px;
  border-top: 1px solid #dedede;
  background: #ffffff;
`;

export const Row = styled.div`
  width: 100%;
  max-width: 886px;
  border: 1px solid #dedede;
  border-radius: 8px;
  background: #ffffff;
`;

interface TitleProps {
  withBorder?: boolean;
}

export const Title = styled.div<TitleProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
  border-bottom: ${({ withBorder }) =>
    withBorder ? '1px solid #dedede' : 'none'};

  .descr {
    color: #656a6e;
  }
`;

export const PatientePendings = styled.div`
  .checkbox-row {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 24px;
  }

  .justify-row {
    padding: 16px 24px 24px 24px;
  }
`;

export const Checkbox = styled.div`
  display: flex;
  gap: 8px;
  font-weight: 500;
  color: #222529;

  input {
    width: 18px;
  }
`;

export const TextAreaContainer = styled.div`
  .label {
    font-weight: 500;
    color: #222529;
    margin-bottom: 12px;
  }

  textarea {
    width: 50%;
    height: 120px;
    padding: 8px;
    border: 1px solid #b4b7b9;
    color: #656a6e;
    border-radius: 6px;
    background: #fdfdfd;
    resize: none;
  }
`;

export const ListFilesContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  padding: 24px;

  .side {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

//Wrong File

export const WrongFileContainer = styled.li`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #dedede;
  border-radius: 4px;
`;

export const WrongFileInfoContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 16px;

  input {
    width: 18px;
    height: 18px;
  }
`;

export const FileInfo = styled.div`
  width: calc(100% - 82px);
  display: flex;
  flex-direction: column;

  .name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    color: #222529;
  }

  .type {
    color: #656a6e;
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 8px;
`;

export const Action = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid #b4b7b9;
  border-radius: 6px;
`;

export const JustifyContent = styled.div`
  height: 120px;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 10px 16px;

  textarea {
    width: 100%;
    height: 100%;
    color: #656a6e;
    resize: none;
  }
`;
