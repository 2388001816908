import React from 'react';

import { IconBack } from '~/presentation/base/icons';
import { Container } from './style/StyledBackButton';

import { Icon } from '../icon';

interface ownProps {
  backDescription?: string;
  onBack: Function;
  id?: string;
}

type Props = ownProps;

const BackButton: React.FC<Props> = ({
  backDescription,
  onBack,
  id,
}): JSX.Element => {
  return (
    <Container
      id={id}
      type="button"
      onClick={() => {
        onBack();
      }}
    >
      <Icon src={IconBack} width="16px" height="16px" />
      {backDescription}
    </Container>
  );
};

export default BackButton;
