import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetByDownloadSupportDocs as UseCaseRemoteGetByDownloadSupportDocs } from '~/domain/usecases/supportDoc/remote';

import { RemoteGetByDownloadSupportDocs } from '~/data/repository/supportDoc';

export const makeRemoteGetByDownloadSupportDocs =
  (): UseCaseRemoteGetByDownloadSupportDocs =>
    new RemoteGetByDownloadSupportDocs(
      makeApiUrl('/supportDocs/'),
      makeHttpClient(),
    );
