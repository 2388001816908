import React from 'react';

import { Body, Container, Message } from './styles/StyledUnauthorized';
import { Unauth } from '~/presentation/base/icons';
import { Button } from '~/presentation/components/UI';
import { History } from '~/main/routes';
import { translator } from '~/presentation/components/i18n';

const Unauthorized: React.FC = () => {
  function goBack() {
    History.back();
  }

  return (
    <Container>
      <Body>
        <Unauth width="430px" height="430px" />
        <Message>
          Você não tem permissão para acessar esta página. Caso contrário,
          reporte a administração.
        </Message>
        <Button
          variant="secundary"
          size="large"
          height="45px"
          onClick={goBack}
          rounded
          autoFocus
        >
          {translator('Voltar')}
        </Button>
      </Body>
    </Container>
  );
};

export default Unauthorized;
