import { Dispatch } from 'redux';
import store from '~/data/store';
import { getRequest } from '~/data/store/reducer/dashboard/actions/getDashboardProducts';

import { iActionGetDashboardProducts } from '~/domain/interfaces/redux/dashboard/getDashboardProducts';

import { GetDashboardProducts } from '~/domain/usecases/dashboard/redux';

export class ReduxGetDashboardProducts implements GetDashboardProducts {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetDashboardProducts.Params): iActionGetDashboardProducts =>
    this.send(getRequest(params));
}
