import { put, call } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { iActionCancel } from '~/domain/interfaces/redux/appointment/cancelAppointment';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

import { getAllRequest } from '~/data/store/reducer/appointment/actions/getAll';

import { makeRemoteCancelAppointment } from '~/main/factories/usecases/appointment/CancelAppointmentFactory';
import { CancelAppointment } from '~/domain/usecases/appointment/redux';
import {
  cancelFailed,
  cancelSuccess,
} from '../../reducer/appointment/actions/cancelAppointment';
import { iStore } from '~/domain/interfaces/models';
import storeDev from '../..';
import { GetAllAppointment } from '~/domain/usecases/appointment/remote';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onCancelRequest(action: iActionCancel) {
  const remoteCancelAppointment = makeRemoteCancelAppointment();

  try {
    const response: CancelAppointment.Model = yield call(
      remoteCancelAppointment.cancel,
      action.payload,
    );
    yield put(cancelSuccess());
  } catch (e) {
    yield put(cancelFailed());
  }
}

export function* onCancelSuccess() {
  const store: iStore = storeDev.getState();

  const { selectUser, info } = store.auth;

  let filter: GetAllAppointment.Params['filter'] = {
    org: selectUser.orgId,
    enabled: true,
  };

  const professionalId = info.professionals?.find(
    item => item.orgUnit.id === selectUser.orgUnitId,
  )?.id;

  if (selectUser.role === 'PRO' && info.professionals?.length) {
    filter = { ...filter, professional: professionalId };
  }

  yield put(getAllRequest({ filter }));
}

export function onCancelFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao cancelar o atendimento.' }),
  });
}
