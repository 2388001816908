import { put } from 'redux-saga/effects';
import {
  deleteFailed,
  deleteSuccess,
} from '~/data/store/reducer/administrator/actions/delete';

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onDelete() {}

export function* onDeleteSuccess() {
  yield 10;
}

export function* onDeleteFailed() {
  yield 10;
}
