import { Dispatch } from 'redux';
import { GetOneUser } from '~/domain/usecases/users/redux';
import store from '~/data/store';
import { getOneRequest } from '~/data/store/reducer/users/actions/getOne';

import { iActionGetOne } from '~/domain/interfaces/redux/users/getOne';

export class ReduxGetOneUser implements GetOneUser {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getOne = (params: GetOneUser.Params): iActionGetOne =>
    this.send(getOneRequest(params));
}
