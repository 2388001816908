import { AppointmentReportByOrgUnit } from '~/domain/usecases/report/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteAppointmentReportByOrgUnit
  implements AppointmentReportByOrgUnit
{
  private readonly url: string;

  private readonly httpClient: HttpClient<AppointmentReportByOrgUnit.Model>;

  constructor(
    url: string,
    httClient: HttpClient<AppointmentReportByOrgUnit.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: AppointmentReportByOrgUnit.Params,
  ): Promise<AppointmentReportByOrgUnit.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
