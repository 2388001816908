export enum AuthTypes {
  LOGIN = '@auth/LOGIN',
  LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
  LOGIN_FAILED = '@auth/LOGIN_FAILED',
  REFRESH = '@auth/REFRESH',
  REFRESH_SUCCESS = '@auth/REFRESH_SUCCESS',
  REFRESH_FAILED = '@auth/REFRESH_FAILED',
  LOGOUT = '@auth/LOGOUT',
  UPDATE_USER_INFO = '@auth/UPDATE_USER_INFO',
  GET_ACL = '@auth/GET_ACL',
  GET_ACL_SUCCESS = '@auth/GET_ACL_SUCCESS',
  GET_ACL_FAILED = '@auth/GET_ACL_FAILED',
  SELECT_USER = '@auth/SELECT_USER',
}
