import styled, { css } from 'styled-components';

type BadgeProps = {
  outcome:
    | 'PERMANENCE'
    | 'TRANSFER'
    | 'CLINICAL_DISCHARGE'
    | 'REFERRAL'
    | 'DEATH'
    | 'RETURN';
};

export const BadgeOutcome = styled.div<BadgeProps>`
  color: #62666c;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  width: fit-content;

  border-radius: 0.38rem;
  padding: 0.5rem 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.375rem;
  border: 1px solid #818385;
  background: #f8f8f8;

  ${({ outcome }) => {
    switch (outcome) {
      case 'PERMANENCE':
        return css`
          color: #0565d9;
          border-color: #0565d9;
          background: #e6f0fb;
        `;
      case 'TRANSFER':
        return css`
          color: #818385;
          border-color: #818385;
          background: #f8f8f8;
        `;
      case 'CLINICAL_DISCHARGE':
        return css`
          color: #458108;
          border-color: #458108;
          background: #f4fde9;
        `;
      case 'REFERRAL':
        return css`
          color: #723ab2;
          border-color: #723ab2;
          background: #f4edfc;
        `;
      case 'DEATH':
        return css`
          color: #818385;
          border-color: #818385;
          background: #f8f8f8;
        `;
      case 'RETURN':
        return css`
          color: #a31775;
          border-color: #a31775;
          background: #fae8f4;
        `;
      default:
        return css`
          color: #818385;
          border-color: #818385;
          background: #f8f8f8;
        `;
    }
  }}
`;
