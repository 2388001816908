import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  text {
    display: flex;
    font-weight: 600;
    align-items: center;
    text-align: center;
    flex-wrap: nowrap;
    font-size: 14px;
  }
`;
export const Finished = styled.div`
  border-radius: 4px;
  padding: 4px 12px;
  background-color: ${defaultTheme.stautsFinishedBack};
  color: ${defaultTheme.statusFinished};
`;

export const Confirmed = styled.div`
  border-radius: 4px;
  padding: 4px 12px;
  background-color: #48c1f9;
  color: #fff;
`;

export const Available = styled.div`
  border-radius: 4px;
  padding: 4px 12px;
  background-color: ${defaultTheme.statusSucessBack};
  color: ${defaultTheme.statusSucess};
`;
export const Canceled = styled.div`
  border-radius: 4px;
  padding: 4px 12px;
  background-color: ${defaultTheme.statusCancelBack};
  color: ${defaultTheme.statusCancel};
`;
export const Progress = styled.div`
  border-radius: 4px;
  padding: 4px 12px;
  background-color: ${defaultTheme.statusProgressBack};
  color: ${defaultTheme.statusProgress};
`;

export const Scheduled = styled.div`
  border-radius: 4px;
  padding: 4px 12px;
  background-color: ${defaultTheme.hover};

  color: ${defaultTheme.primary};
`;
