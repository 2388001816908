import styled from 'styled-components';

export const ContainerGeneralData = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
`;

export const ContainerColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const ContainerRow = styled.div`
  width: 215px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
