/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useMemo, useState } from 'react';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '~/presentation/components/UI';

import { makeRemoteSoapAiAppointment } from '~/main/factories/usecases/appointment/SoapAiAppointmentFactory';

import { translator } from '../i18n'; // TODO: check this component translation

import {
  Body,
  ConsultInfoContainer,
  ConsultInfoMessage,
  Container,
  ContainerModal,
  Footer,
  Header,
  ScrollContainer,
  TextBold,
  TextBoldTitle,
  TextNormalSubtitle,
} from './styles/styles';
import ConsultDetailFragment from './ConsultDetailFragment';
import { SoapAiAppointment } from '~/domain/usecases/appointment/remote';
import { closeModal } from '~/utils/closeModal';
import { Icon } from '@wisecare/design-system-web';

interface propsModalSoapAi {
  message: iMessage;
}

const ModalSoapAi: React.FC<propsModalSoapAi> = ({ message }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [appointmentSOAP, setAppointmentSOAP] =
    useState<SoapAiAppointment.Model>();

  const { active, actionOk, actionCancel, data } = message;

  const isModalActive = active === MessageOptions.soapAi;

  useEffect(() => {
    if (isModalActive) {
      makeRemoteSoapAiAppointment()
        .soapAi({
          appointmentId: data?.appointmentId,
        })
        .then(response => {
          setAppointmentSOAP(response);
          setIsLoading(false);
        });
    }
  }, [isModalActive]);

  const handleCancel = () => {
    actionCancel?.();
    closeModal();
    setIsLoading(true);
    setAppointmentSOAP(undefined);
  };

  const onSubmit = () => {
    actionOk?.();
  };

  const renderTitle = useMemo(() => {
    return 'SOAP AI';
  }, []);

  const renderSubtitle = useMemo(() => {
    return 'Gerado por Inteligência Artificial';
  }, []);

  const msgName = MessageOptions.soapAi;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <Icon name="cadence" color="blue-7" size="h3" />
            </Header>
            <Body>
              <ConsultInfoMessage>
                <TextBoldTitle>{renderTitle}</TextBoldTitle>
                <TextNormalSubtitle>{renderSubtitle}</TextNormalSubtitle>
              </ConsultInfoMessage>
              <ConsultInfoContainer>
                {/* <TextBold>{translator('Ficha do paciente')}</TextBold> */}
                <ScrollContainer>
                  <ConsultDetailFragment
                    label="Subjetivo"
                    content={appointmentSOAP?.subjetivo}
                    isLoading={isLoading}
                  />
                  <ConsultDetailFragment
                    label="Objetivo"
                    content={appointmentSOAP?.objetivo}
                    isLoading={isLoading}
                  />
                  <ConsultDetailFragment
                    label="Avaliação"
                    content={appointmentSOAP?.avaliacao}
                    isLoading={isLoading}
                  />
                  <ConsultDetailFragment
                    label="Plano"
                    content={appointmentSOAP?.plano}
                    isLoading={isLoading}
                  />
                  <ConsultDetailFragment
                    label="Alergias"
                    content={appointmentSOAP?.alergias}
                    isLoading={isLoading}
                  />
                  <ConsultDetailFragment
                    label="Medicamentos"
                    content={appointmentSOAP?.medicamentos}
                    isLoading={isLoading}
                  />
                  <ConsultDetailFragment
                    label="Fator RH"
                    content={appointmentSOAP?.fatorRh}
                    isLoading={isLoading}
                  />
                </ScrollContainer>
              </ConsultInfoContainer>
            </Body>
            <Footer>
              {/* <Button variant="primary" onClick={onSubmit}>
                {translator('Entrar na consulta')}
              </Button> */}
              <Button
                variant="secundary"
                onClick={() => {
                  handleCancel();
                }}
              >
                {translator('Dispensar')}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalSoapAi;
