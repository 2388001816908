/**
 * component data mapper
 * @param appointment data.
 */
export function makeTime(item: string, duration: number) {
  const time = new Date(item);
  const timeStart = new Date(time.getTime()).toLocaleTimeString('pt-BR', {
    hour12: false,
  });
  const timeEnd = new Date(
    time.getTime() + duration * 60000,
  ).toLocaleTimeString('pt-BR', { hour12: false });

  const startFormat = `${timeStart.split(':')[0]}:${timeStart.split(':')[1]}`;
  const endFormat = `${timeEnd.split(':')[0]}:${timeEnd.split(':')[1]}`;
  return { startFormat, endFormat };
}
