import React from 'react';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Share } from '~/presentation/base/icons';
import { Button } from '../../UI';
import { ownProps } from '../interface';
import {
  Body,
  ButtonsDiv,
  CloseButton,
  Container,
  Header,
  Footer,
  Text,
} from './styles/StyledEditConsultCopyLink';
import GenerateLink from '~/presentation/components/link/GenerateLink';
import { translator } from '../../i18n';

const EditConsultCopyLink: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.copyConsultLink;

  const { active, actionCancel, actionOk, appointmentLinks } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <Share />
              <span>{translator('Convidar por Link')}</span>
              <CloseButton onClick={actionCancel}>X</CloseButton>
            </Header>
            <Footer>
              <GenerateLink
                link={
                  appointmentLinks?.length
                    ? appointmentLinks[0]
                    : 'http://localhost:3000/appointment/detail'
                }
              />
              <ButtonsDiv>
                <Button
                  id="btn_confirm"
                  rounded
                  size="large"
                  onClick={actionOk}
                >
                  {translator('OK')}
                </Button>
              </ButtonsDiv>
            </Footer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default EditConsultCopyLink;
