import { CreateClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  ClinicalDocsTypes,
} from '~/domain/interfaces/redux/clinicalDocs/create';

interface CreateSuccessParams extends CreateClinicalDocs.Model {
  sidebar?: boolean;
  goBackToListDocument?: () => void;
  signDocWithVidaas?: (id: number) => void;
}

export const createRequest = (
  payload: CreateClinicalDocs.Params,
): iActionCreate => ({
  type: ClinicalDocsTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateSuccessParams,
): iActionCreateSuccess => ({
  type: ClinicalDocsTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: ClinicalDocsTypes.CREATE_FAILED,
});
