import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 385px;
  min-height: 36px;
  border-radius: 4px;
  padding-left: 5px;
  border: 1px solid #efefef;
  margin-right: 10px;
  input {
    max-width: 385px;
    padding: 3px;
    text-decoration: none;
    border: none;
  }
  :hover {
    border-color: ${defaultTheme.primary};
  }
`;
