import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { iListRegisteredParticipant } from '../interface';
import { iConsultant, iStore } from '~/domain/interfaces/models';
import { pageProps } from '~/presentation/roles/professional/pages/registeredParticipant';
import { getLocale } from '~/utils/getLocale';

/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectParticipant<P>(
  Component: ComponentType<P & pageProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const users = useSelector((store: iStore) => store.consultant);

    Component.defaultProps = {
      data: _.orderBy(
        // Default descending order for date
        mapParticipantData(users),
        item => {
          const splitDate = item.date.split('/');
          const internationalDate =
            splitDate.length < 3
              ? item.date
              : `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;

          return new Date(internationalDate).getTime();
        },
        ['desc'],
      ),
      ...(rest as P),
    };

    return <Component {...(rest as P & pageProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
const mapParticipantData = (
  participant: iConsultant,
): iListRegisteredParticipant[] => {
  const arr: iListRegisteredParticipant[] = [];
  participant.results.forEach(item => {
    const date = item.consultant.created.split('T')[0].split('-');
    const dateFormated = new Date(
      `${date[1]}-${date[2]}-${date[0]}`,
    ).toLocaleDateString(getLocale());

    arr.push({
      date: dateFormated,
      fullName: `${item.user.firstName} ${item.user.lastName}`,
      avatar: item.user.avatar,
      age: item.user.birthdate,
      phone: item.user.phone,
      email: item.user.email,
      cod: item.consultant.id.toString(),
      userId: item.user.id,
      status: item.consultant.situation,
    });
  });

  return arr;
};
