import React from 'react';

import { useSelector } from 'react-redux';

import { Container, Name, Role, Actions } from './style/ProfileNavigator';
import { iStore } from '~/domain/interfaces/models';
import { Button } from '~/presentation/components/UI';
import { translator } from '~/presentation/components/i18n';
import { AvatarWithUploader } from '~/presentation/components/UI/avatar';

interface OwnProps {
  nav: Function;
  active: number;
}

interface iProps {
  userId: string;
}
const ProfileNavigator: React.FC<OwnProps> = ({ nav, active }) => {
  const role = useSelector(
    (states: iStore) => states.users.selected.user.mainRole,
  );

  const { user } = useSelector((states: iStore) => states.users.selected);

  const ProfileData: React.FC = () => {
    return (
      <Button
        id="button_profileData"
        rounded
        variant="secundary"
        onClick={() => nav(1)}
        active={active === 1}
      >
        {translator('Dados perfil')}
      </Button>
    );
  };

  const ChangePassword: React.FC = () => {
    return (
      <Button
        id="button_changePassword"
        rounded
        variant="secundary"
        onClick={() => nav(2)}
        active={active === 2}
      >
        {translator('Mudar senha')}
      </Button>
    );
  };

  const Permission: React.FC = () => {
    return (
      <Button
        id="button_permission"
        rounded
        variant="secundary"
        onClick={() => nav(3)}
        active={active === 3}
      >
        {translator('Permissão')}
      </Button>
    );
  };

  const DeleteAccount: React.FC = () => {
    return (
      <Button
        id="button_deleteAccount"
        rounded
        variant="secundary"
        onClick={() => nav(4)}
        active={active === 4}
      >
        {translator('Excluir conta')}
      </Button>
    );
  };

  return (
    <Container>
      <AvatarWithUploader
        fullName={`${user.firstName} ${user.lastName}`}
        src={user.avatar!}
        size="158px"
      />
      <Name>{`${user.firstName} ${user.lastName}`}</Name>
      <Role>{role}</Role>
      <Actions>
        <ProfileData />
        <ChangePassword />
        {/* <Permission /> */}
        <DeleteAccount />
      </Actions>
    </Container>
  );
};

export default ProfileNavigator;
