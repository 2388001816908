import { iProfessional } from '~/domain/interfaces/models/Professional';
import { ProfessionalTypes } from '~/domain/interfaces/redux/professionalList/types';
import { ProfessionalActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iProfessional = {
  pageSize: 0,
  totalItems: 0,
  results: [],
};

const reducer = (
  state = initialState,
  action: ProfessionalActions,
): iProfessional => {
  switch (action.type) {
    case ProfessionalTypes.GETALL:
      return state;
    case ProfessionalTypes.GETALL_SUCCESS:
      return { ...state, results: [...action.payload.results] };
    case AuthTypes.LOGOUT:
      return initialState;
    case ProfessionalTypes.CREATE:
      return state;
    case ProfessionalTypes.CREATE_FAILED:
      return state;
    case ProfessionalTypes.CREATE_SUCCESS: {
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
