export const waitingColumns = [
  {
    id: 'professional',
    label: 'Profissional solicitante',
  },
  {
    id: 'org',
    label: 'Unidade de Saúde',
  },
  {
    id: 'priority',
    label: 'Prioridade',
  },
  {
    id: 'date',
    label: 'Solicitação',
  },
  {
    id: 'actions',
    label: '',
  },
];

export const waitingColumnsProfessional = [
  {
    id: 'patient',
    label: 'Paciente',
  },

  {
    id: 'specialty',
    label: 'Especialidade',
  },
  {
    id: 'priority',
    label: 'Prioridade',
  },
  {
    id: 'solicitacion',
    label: 'Solicitação',
  },
  {
    id: 'actions',
    label: '',
  },
];

export const followUpColumns = [
  {
    id: 'patient',
    label: 'Paciente',
  },
  {
    id: 'org',
    label: 'Unidade de Saúde',
  },
  {
    id: 'last_update',
    label: 'Última atualização',
  },
  {
    id: 'outcome',
    label: 'Desfecho',
  },
  {
    id: 'actions',
    label: '',
  },
];

export const followUpColumnsProfessional = [
  {
    id: 'patient',
    label: 'Paciente',
  },

  {
    id: 'specialty',
    label: 'Especialidade',
  },
  {
    id: 'update',
    label: 'Última atualização',
  },
  {
    id: 'outcome',
    label: 'Desfecho',
  },
  {
    id: 'actions',
    label: '',
  },
];

export const finishedColumns = [
  {
    id: 'patient',
    label: 'Paciente',
  },
  {
    id: 'org',
    label: 'Unidade de Saúde',
  },
  {
    id: 'last_update',
    label: 'Última atualização',
  },
  {
    id: 'outcome',
    label: 'Desfecho',
  },
  {
    id: 'actions',
    label: '',
  },
];

export const finishedColumnsProfessional = [
  {
    id: 'patient',
    label: 'Paciente',
  },
  {
    id: 'specialty',
    label: 'Especialidade',
  },
  {
    id: 'last_update',
    label: 'Última atualização',
  },
  {
    id: 'outcome',
    label: 'Desfecho',
  },
  {
    id: 'actions',
    label: '',
  },
];
