import { GetAllNotificationUser } from '~/domain/usecases/Notifications/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  BadRequestError,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteGetAllNotificationUser implements GetAllNotificationUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllNotificationUser.Model>;

  constructor(
    url: string,
    httpClient: HttpClient<GetAllNotificationUser.Model>,
  ) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getAll = async (
    params: GetAllNotificationUser.Params,
  ): Promise<GetAllNotificationUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
