import { CreateOrganizer } from '~/domain/usecases/organizer/redux/CreateOrganizer';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  OrganizerTypes,
} from '~/domain/interfaces/redux/Organizer/create';

export const createRequest = (
  payload: CreateOrganizer.Params,
): iActionCreate => ({
  type: OrganizerTypes.CREATE,
  payload,
});

export const createSuccess = (
  params: CreateOrganizer.Model,
): iActionCreateSuccess => ({
  type: OrganizerTypes.CREATE_SUCCESS,
  payload: params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: OrganizerTypes.CREATE_FAILED,
});
