import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9000;
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 27em;
  max-height: 15em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 120px;
  text-align: center;

  p {
    color: black;
    font-size: 18px;
    font-weight: bold;

    width: 230px;
    margin-left: 18px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;
