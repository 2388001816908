import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 90px;

  color: #004ba5;
  font-size: 2.5em;
`;

export const SubTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  color: #004ba5;
  font-size: 2.4em;
  font-weight: bold;
`;

export const Message = styled.div`
  width: 20.9%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 35px;

  color: #7a7a7a;
  font-size: 1.3em;
  text-align: center;
  text-overflow: ellipsis;
`;
