import { Dispatch } from 'redux';
import { UpdateUserAvatar } from '~/domain/usecases/users/redux';
import store from '~/data/store';
import { updateRequest } from '~/data/store/reducer/users/actions/updateUserAvatar';

import { iActionUpdateUserAvatar } from '~/domain/interfaces/redux/users/updateUserAvatar';

export class ReduxUpdateUserAvatar implements UpdateUserAvatar {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  updateAvatar = (params: UpdateUserAvatar.Params): iActionUpdateUserAvatar =>
    this.send(updateRequest(params));
}
