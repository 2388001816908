import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { RemovedOnCallRequester as UsecaseRemoteRemovedOnCallRequester } from '~/domain/usecases/onCallRequester/remote';
// import { RemovedOnCallRequester as UsecaseReduxRemovedOnCallRequester } from '~/domain/usecases/onCallRequester/redux';

import { RemoteRemovedOnCallRequester } from '~/data/repository/onCallRequester';
// import { ReduxRemovedOnCallRequester } from '~/data/store/reducer/onCallRequester/usecases';

/**
 * send request via API.
 */
export const makeRemoteRemovedOnCallRequester =
  (): UsecaseRemoteRemovedOnCallRequester =>
    new RemoteRemovedOnCallRequester(
      makeApiUrl('/oncall/requesters/{requesterId}/REMOVED'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxRemovedOnCallRequester =
  (): UsecaseReduxRemovedOnCallRequester =>
    new ReduxRemovedOnCallRequester(); */
