/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import * as datefns from 'date-fns';

import { Controller, useFormContext } from 'react-hook-form';

import { useSelector } from 'react-redux';
import { add, format, isAfter, isBefore, isEqual, parseISO } from 'date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Container, ContainerMain } from './styles/StyledDateHour';

import InputKeyboardDate from '../inputKeyboardDate';
import { translator } from '../i18n';
import { iUpdateAppointment } from './interface';

import { iStore } from '~/domain/interfaces/models';
import { makeDuration } from '~/utils/makeDurationAndTime';
import { Select } from '../UI';
import { makeReduxSetShowModal } from '~/main/factories/usecases/showModal/Set';
import { makeRemoteGetAvailability } from '~/main/factories/usecases/professional/GetAvailabilityFactory';

interface ownProps {
  handleChangeEditConsult: Function;
  setValue: Function;
  handleSubmit: Function;
  register: Function;
  errors: any;
  control: any;
  state: iUpdateAppointment;
  appointmentId: string;
}
interface typeArrayTime {
  time: string;
  timeFormat: string;
  disabled: boolean;
}

const DateHour: React.FC<ownProps> = ({
  state,
  control,
  // errors,
  // register,
  setValue,
  handleChangeEditConsult,
  appointmentId,
}): JSX.Element => {
  const [times, setTimes] = useState<typeArrayTime[]>([]);
  const [timesEnd, setTimesEnd] = useState<typeArrayTime[]>([]);
  const [defaultStart, setStart] = useState(state.hourStart);
  const [defaultEnd, setEnd] = useState(state.hourEnd);
  const [dateIsoString, setDate] = useState<string>(state?.date || '');

  const labelTimeEnd = translator('Hora fim');
  const labelTimeStart = translator('Hora início');

  const { role } = useSelector((store: iStore) => store.auth.selectUser);
  const { appointment } = useSelector((store: iStore) => store.showModal);

  const formatIsoDate = (
    datetime: string | Date,
    withoutTimezone?: boolean,
  ) => {
    let formattedDate = new Date(datetime);

    if (withoutTimezone)
      formattedDate = datefns.add(new Date(datetime), {
        minutes: new Date(datetime).getTimezoneOffset(),
      });

    const date = format(formattedDate, 'yyyy-MM-dd');
    const time = format(formattedDate, 'HH:mm');

    return `${date}T${time}:00.000Z`;
  };

  const handleChange = (event: MaterialUiPickersDate) => {
    if (!appointment) makeReduxSetShowModal().set({ appointment: true });

    setDate(event!.toISOString());
    handleChangeEditConsult({
      key: 'date',
      value: event!.toISOString(),
    });
  };

  useEffect(() => {
    if (state.hourStart === '') {
      setStart('');
      setEnd('');
    }
  }, [state]);

  useEffect(() => {
    setUpDefaultValues();
  }, []);

  const setUpDefaultValues = () => {
    const timeStart = makeDuration(
      dateIsoString,
      state.duration!,
    ).dateStartIsoString;
    const timeEnd = makeDuration(
      dateIsoString,
      state.duration!,
    ).dateEndIsoString;

    setStart(formatIsoDate(timeStart, true));
    setEnd(formatIsoDate(timeEnd, true));
  };

  const onChangeTimesStart = (event: string) => {
    const timeStart = new Date(event);
    const professionals = state.otherProfessionals
      ? [...state.otherProfessionals]
      : [];

    const formattedDate = datefns.add(timeStart, {
      minutes: timeStart.getTimezoneOffset(),
    });

    if (state.professional) professionals.push(state.professional);

    if (timeStart)
      makeRemoteGetAvailability()
        .get({
          timestamp: {
            begin: timeStart.toISOString(),
            end: datefns.endOfDay(formattedDate).toISOString(),
          },
          professionals,
          appointmentExceptionId: Number(appointmentId),
        })
        .then(res => {
          let disableAllNext = false;

          const formatTimes = res.availability
            ?.map(item => {
              if (item.end === timeStart.toISOString()) return;

              const utcEnd = parseISO(item.end);

              if (
                !disableAllNext &&
                datefns.isAfter(utcEnd, timeStart) &&
                !item.available
              ) {
                disableAllNext = true;
              }

              return {
                time: formatIsoDate(item.end, true),
                timeFormat: format(utcEnd, 'HH:mm'),
                disabled:
                  disableAllNext ||
                  !item.available ||
                  item.end === timeStart.toISOString(),
              };
            })
            .filter(Boolean) as typeArrayTime[];

          if (state.date) setTimesEnd(formatTimes);
        })
        .catch(err => {
          console.log('>>> Err: ', err);
        });
  };

  const {
    register,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    console.debug('>>> Times: ', times);
    console.debug('>>> TimesEnd: ', timesEnd);
  }, [times, timesEnd]);

  useEffect(() => {
    const professionals = state.otherProfessionals
      ? [...state.otherProfessionals]
      : [];

    if (state.professional) professionals.push(state.professional);

    if (state.date)
      makeRemoteGetAvailability()
        .get({
          timestamp: {
            begin: datefns.startOfDay(new Date(state.date)).toISOString(),
            end: datefns.endOfDay(new Date(state.date)).toISOString(),
          },
          professionals,
          appointmentExceptionId: Number(appointmentId),
        })
        .then(res => {
          const formatTimes = res.availability
            ?.map(item => {
              if (isBefore(new Date(item.begin), new Date())) return;

              const utcBegin = parseISO(item.begin);

              return {
                time: formatIsoDate(item.begin, true),
                timeFormat: format(utcBegin, 'HH:mm'),
                disabled: !item.available,
              };
            })
            .filter(Boolean) as typeArrayTime[];

          setTimes(formatTimes);
          if (onChangeTimesStart && state.date) {
            onChangeTimesStart(new Date(state.date).toISOString());
          }
        })
        .catch(err => {
          console.log('>>> Err: ', err);
        });
  }, [state.date]);

  return (
    <ContainerMain>
      <Container>
        <Controller
          render={({ value, onChange }) => (
            <InputKeyboardDate
              state={value}
              setState={(date: Date) => {
                if (dateIsoString !== date.toISOString()) {
                  handleChange(date);
                  onChange(date?.toISOString());
                }
              }}
              name="date"
              label={translator('Data do atendimento')}
              // error={Boolean(errors.date)}
              defaultValue={state.date}
              message={errors.date?.message}
              minDate={new Date()}
              disabled={role === 'CON'}
            />
          )}
          name="date"
          control={control}
          // ref={() => register('date')}
          // defaultValue={state.date}
          // minDate={new Date()}
        />

        <Select
          id="input_hourStart"
          width="140px"
          value={defaultStart}
          onChange={e => {
            if (!appointment)
              makeReduxSetShowModal().set({ appointment: true });

            onChangeTimesStart(e.target.value);
            setStart(e.target.value);
            setValue('hourEnd', '');
            setEnd('');
            handleChangeEditConsult({
              key: 'hourEnd',
              value: '',
            });
            handleChangeEditConsult({
              key: e.target.name,
              value: e.target.value,
            });
          }}
          register={() => register('hourStart')}
          error={Boolean(errors.hourStart)}
          message={
            errors?.hourStart?.message
              ? translator(errors?.hourStart?.message)
              : ''
          }
          label={labelTimeStart}
          required
          disabled={role === 'CON'}
          name="hourStart"
        >
          <option id="option_0" value="">
            {labelTimeStart}
          </option>
          {times.map((item: typeArrayTime, index) => (
            <option
              id={`option_${index}`}
              disabled={item.disabled}
              style={{
                backgroundColor: item.disabled ? '#C9C9C9' : '#fff',
              }}
              value={item.time}
            >
              {item.timeFormat}
            </option>
          ))}
        </Select>

        <Select
          label={labelTimeEnd}
          value={defaultEnd}
          width="140px"
          id="input_hourFinish"
          onChange={e => {
            if (!appointment)
              makeReduxSetShowModal().set({ appointment: true });

            setEnd(e.target.value);
            handleChangeEditConsult({
              key: e.target.name,
              value: e.target.value,
            });
          }}
          register={() => register('hourEnd')}
          name="hourEnd"
          error={Boolean(errors.hourEnd)}
          disabled={role === 'CON'}
          message={
            errors?.hourEnd?.message ? translator(errors?.hourEnd?.message) : ''
          }
          required
        >
          <option id="option_0" value="">
            {labelTimeEnd}
          </option>
          {timesEnd.map((item: typeArrayTime, index) => (
            <option
              id={`option_${index}`}
              disabled={item.disabled}
              style={{
                backgroundColor: item.disabled ? '#C9C9C9' : '#fff',
              }}
              value={item.time}
            >
              {item.timeFormat}
            </option>
          ))}
        </Select>
      </Container>
      {/* <ButtonSave
        action={() => console.log('teste')}
        disable={role === 'CON'}
      /> */}
    </ContainerMain>
  );
};

export default DateHour;
