import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { V4hSpin } from '~/presentation/components/spin';
import { History } from '~/routes';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeRemoteUpdateClinicalDocsBySign } from '../../usecases/clinicalDocs/UpdateClinicalDocsBySignFactory';

const VidaasSignFactory: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const query = new URLSearchParams(useLocation().search);
  const code = query.get('code');

  const history = useHistory();

  useEffect(() => {
    const docId = localStorage.getItem('clinicalDocId');

    if (code && docId) {
      setLoading(true);

      makeRemoteUpdateClinicalDocsBySign()
        .patchBySign({
          signatureValue: code,
          docId: Number(docId),
          signatureSource: 'VIDAAS',
        })
        .then(() => {
          AlertMessage({
            message: 'Documento assinado com sucesso!',
            type: 'success',
          });
        })
        .catch(e => console.log(e))
        .finally(() => {
          history.replace('/clinicaldoc/detail', {
            docId: Number(docId),
          });
          // history.replace('/clinicaldocs');
          setLoading(false);
        });
    }
  }, [code, history]);

  return <>{loading && <V4hSpin fullScreen />}</>;
};

export default VidaasSignFactory;
