import { type } from 'os';
import {
  iActionGetACL,
  iActionGetACLFailed,
  iActionGetACLSuccess,
  PermissionTypes,
} from '~/domain/interfaces/redux/permissions/GetACL';
import { GetACL } from '~/domain/usecases/permissions/redux/GetACL';

export const GetACLRequest = (params: GetACL.Params): iActionGetACL => ({
  type: PermissionTypes.GET_ACL,
  payload: params,
});

export const GetACLSuccess = (params: GetACL.Model): iActionGetACLSuccess => ({
  type: PermissionTypes.GET_ACL_SUCCESS,
  payload: params,
});

export const GetACLFailed = (): iActionGetACLFailed => ({
  type: PermissionTypes.GET_ACL_FAILED,
});
