import { call, put } from 'redux-saga/effects';
import { iActionCreateByEmail } from '~/domain/interfaces/redux/clinicalDocs/createByEmail';
import { CreateClinicalDocsByEmail } from '~/domain/usecases/clinicalDocs/remote/CreateClinicalDocsByEmail';
import {
  createByEmailSucess,
  createByEmailFailed,
} from '~/data/store/reducer/clinicalDocs/actions/createByEmail';

import { makeRemoteCreateClinicalDocsByEmail } from '~/main/factories/usecases/clinicalDocs/CreateClinicalDocsByEmailFactory';

export function* onCreateByEmail(action: iActionCreateByEmail) {
  const remoteCreateClinicalDocsByEmail = makeRemoteCreateClinicalDocsByEmail();

  try {
    const response: CreateClinicalDocsByEmail.Model = yield call(
      remoteCreateClinicalDocsByEmail.createByEmail,
      action.payload,
    );

    yield put(createByEmailSucess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(createByEmailFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateByEmailSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateByEmailFailed() {}
