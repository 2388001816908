import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 390px;
  max-height: 100px;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  text {
    margin: 3px;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  text {
    margin: 3px;
    color: ${defaultTheme.typographyLightGray};
  }
`;

export const Icon = styled.div`
  margin: 5px;
  background-color: ${defaultTheme.primary};
  border-radius: 50%;
  width: 60px;
  height: 60px;
`;
