import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/administrator/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/administrator/getAll';

import { GetAllAdministrator } from '~/domain/usecases/administrator/redux';

export class ReduxGetAllAdministrator implements GetAllAdministrator {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllAdministrator.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
