import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { IconDelete } from '~/presentation/base/icons';
import { ActionsList } from '../actionsList';
import {
  makeReduxGetAllSpecialty,
  makeRemoteGetAllSpecialty,
} from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';
import { iStore } from '~/domain/interfaces/models';

interface TableProps {
  columns: string[];
  rows: object[];
  bold?: string[];
  deleteFunction: Function;
  editFunticion: Function;
  specialty: any[];
  disabled: boolean;
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  container: {
    maxHeight: 400,
    height: '100%',
  },
  title: {
    color: 'gray',
    fontSize: '14px',
  },
  body: {
    fontSize: '14px',
  },
  bold: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#004ba5',
  },
});

const StickyHeadTableSpecialty: React.FC<TableProps> = ({
  columns,
  rows,
  bold,
  deleteFunction,
  editFunticion,
  specialty,
  disabled,
}) => {
  const classes = useStyles();

  const { results } = useSelector((store: iStore) => store.specialty);

  const getIdValue = (value: any) => {
    const filterResults = results.filter(
      result => result.specialty?.id === Number(value),
    );

    return filterResults.length
      ? filterResults[0].specialty?.name
      : 'Especialidade não encontrada';
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={index} className={classes.title}>
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1}>
                  {Object.entries(row).map(([key, value]) => (
                    <TableCell
                      className={
                        bold !== undefined && bold.includes(key)
                          ? `${classes.bold}`
                          : `${classes.body}`
                      }
                      key={key}
                      align="left"
                      width={key === 'id' ? '300px' : undefined}
                    >
                      {key === 'id' ? getIdValue(value) : value}
                    </TableCell>
                  ))}
                  <TableCell>
                    {!disabled && (
                      <ActionsList
                        actions={{
                          deleteSpecialty: () => {
                            deleteFunction(rows.indexOf(row));
                          },
                          editSpecialty: () => {
                            editFunticion(rows.indexOf(row));
                          },
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default StickyHeadTableSpecialty;
