import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AppointmentReportBySpecialty as UsecaseRemoteAppointmentReportBySpecialty } from '~/domain/usecases/report/remote';

import { RemoteAppointmentReportBySpecialty } from '~/data/repository/report';

/**
 * send request via API.
 */
export const makeRemoteAppointmentReportBySpecialty =
  (): UsecaseRemoteAppointmentReportBySpecialty =>
    new RemoteAppointmentReportBySpecialty(
      makeApiUrl('/reports/appointments/specialties'),
      makeHttpClient(),
    );
