export enum ChatTypes {
  CHAT_SETUP = '@chat/CHAT_SETUP',

  GET = '@chat/GET',
  GET_SUCCESS = '@chat/GET_SUCCESS',
  GET_FAILED = '@chat/GET_FAILED',

  GETALL = '@chat/GETALL',
  GETALL_SUCCESS = '@chat/GETALL_SUCCESS',
  GETALL_FAILED = '@chat/GETALL_FAILED',

  GETALL_MESSAGE = '@chat/GETALL_MESSAGE',
  GETALL_MESSAGE_SUCCESS = '@chat/GETALL_MESSAGE_SUCCESS',
  GETALL_MESSAGE_FAILED = '@chat/GETALL_MESSAGE_FAILED',

  CREATE_MESSAGE = '@chat/CREATE_MESSAGE',
  CREATE_MESSAGE_SUCCESS = '@chat/CREATE_MESSAGE_SUCCESS',
  CREATE_MESSAGE_FAILED = '@chat/CREATE_MESSAGE_FAILED',

  SOCKET_CREATE_MESSAGE = '@chat/SOCKET_CREATE_MESSAGE',
  SOCKET_CREATE_MESSAGE_SUCCESS = '@chat/SOCKET_CREATE_MESSAGE_SUCCESS',
  SOCKET_CREATE_MESSAGE_FAILED = '@chat/SOCKET_CREATE_MESSAGE_FAILED',

  CHAT_MESSAGE = '@chat/CHAT_MESSAGE',
  CHAT_MESSAGE_SUCCESS = '@chat/CHAT_MESSAGE_SUCCESS',
  CHAT_MESSAGE_FAILED = '@chat/CHAT_MESSAGE_FAILED',
}
