import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CancelDiagnosisReport as UsecaseRemoteCancelDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { RemoteCancelDiagnosisReport } from '~/data/repository/diagnosisReport';

/**
 * send request via API.
 */

export const makeRemoteCancelDiagnosisReport =
  (): UsecaseRemoteCancelDiagnosisReport =>
    new RemoteCancelDiagnosisReport(makeApiUrl('/reports'), makeHttpClient());
