import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetOneUser as UsecaseRemoteGetOneUser } from '~/domain/usecases/users/remote';
import { GetOneUser as UsecaseReduxGetOneUser } from '~/domain/usecases/users/redux';

import { RemoteGetOneUser } from '~/data/repository/users';
import { ReduxGetOneUser } from '~/data/store/reducer/users/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetOneUser = (): UsecaseRemoteGetOneUser =>
  new RemoteGetOneUser(makeApiUrl('/users'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetOneUser = (): UsecaseReduxGetOneUser =>
  new ReduxGetOneUser();
