import { active as activeMessage } from '~/data/store/reducer/message/actions/active';
import { UsersTypes } from '~/domain/interfaces/redux/users/types';
import { AppointmentTypes } from '~/domain/interfaces/redux/appointment/types';
import { ClinicalDocsTypes } from '~/domain/interfaces/redux/clinicalDocs/types';
import { OnCallTypes } from '~/domain/interfaces/redux/onCall/types';
import { History } from '~/main/routes';

import { UserNotifications } from './User';
import { AppointmentNotifications } from './Appointment';
import { ClinicalDocsNotifications } from './ClinicalDocs';
import { OnCallNotifications } from './OnCall';

export const MessageMiddleware = () => {
  // Socket param is the client. We'll show how to set this up later.
  return ({ dispatch, getState }: any) =>
    (next: any) =>
    (action: any) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const closeNotification = activeMessage({
        active: 'none',
      });

      const { type } = action;

      // Make sure the action is user type.
      if (Object.values(UsersTypes).includes(type))
        return UserNotifications(action, next, closeNotification);

      // Make sure the action is appointment type.
      if (Object.values(AppointmentTypes).includes(type))
        return AppointmentNotifications(action, next, closeNotification);

      // Make sure the action is appointment type.
      if (Object.values(ClinicalDocsTypes).includes(type))
        return ClinicalDocsNotifications(action, next, closeNotification);

      // Make sure the action is onCall type.
      if (Object.values(OnCallTypes).includes(type))
        return OnCallNotifications(action, next, closeNotification);

      return next(action);
    };
};
