import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { iListRegisteredProfessional } from '../interface';
import { iStore, iProfessional, iUsers } from '~/domain/interfaces/models';
import { pageProps } from '~/presentation/roles/manager/pages/ListProfessional';
/**
 * Mapping of the appointment list data.
 *
 * @param Component component to connect.
 */
export function ConnectProfessional<P>(
  Component: ComponentType<P & pageProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const professional = useSelector((store: iStore) => store.professional);

    Component.defaultProps = {
      // Default descending order for date
      data: _.orderBy(
        MapProfessionalData(professional),
        item => {
          const splitDate = item.date.split('/');
          const internationalDate =
            splitDate.length < 3
              ? item.date
              : `${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`;

          return new Date(internationalDate).getTime();
        },
        ['desc'],
      ),
      ...(rest as P),
    };

    return <Component {...(rest as P & pageProps)} />;
  };

  return Render;
}

/**
 * component data mapper
 * @param appointment data.
 */
interface iProf {
  id: number;
  professions?: {
    id: number;
    name: string;
    specialties: {
      id: number;
      name: string;
      docProf: string;
      docProfUF: string;
      docProfIssued: string;
    }[];
  };
  org: {
    id: number;
    shortname: string;
    name: string;
    logo: string;
  };
  orgUnit: {
    id: number;
    name: string;
    logo: string;
  };
}

function makeSpecialty(item: iProfessional['results'][0]['specialties']) {
  let specialty = '';
  if (item.length <= 0) return specialty;
  if (item !== undefined && item[0].name !== undefined) {
    specialty = item[0].name;
  }

  return specialty;
}

const MapProfessionalData = (
  user: iProfessional,
): iListRegisteredProfessional[] => {
  const arr: iListRegisteredProfessional[] = [];

  if (!(user.results.length > 0)) return arr;

  user.results.forEach(item => {
    const date = item.user.created.split('T')[0].split('-');
    const dateFormated = `${date[2]}/${date[1]}/${date[0]}`;

    arr.push({
      avatar: item.user.avatar,
      cod: item.professional.id.toString(),
      date: dateFormated,
      fullName: `${item.user.firstName} ${item.user.lastName}`,
      register: item.specialties[0]?.docProf,
      phone: item.user.phone,
      email: item.user.email,
      specialty: makeSpecialty(item.specialties),
      userId: item.user.id,
      status: item.professional.situation,
    });
  });

  return arr;
};
