import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const ButtonToOpenSideSheet = styled.button`
  width: 2.5rem;
  height: 2.5rem;

  border: none;
  border-radius: 0.31rem;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  &:hover {
    background-color: #ecedee;
  }
`;
