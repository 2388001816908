import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { RemovedOnCallSpecialist as UsecaseRemoteRemovedOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/remote';
// import { RemovedOnCallSpecialist as UsecaseReduxRemovedOnCallSpecialist } from '~/domain/usecases/onCallSpecialist/redux';

import { RemoteRemovedOnCallSpecialist } from '~/data/repository/onCallSpecialist';
// import { ReduxRemovedOnCallSpecialist } from '~/data/store/reducer/onCallSpecialist/usecases';

/**
 * send request via API.
 */
export const makeRemoteRemovedOnCallSpecialist =
  (): UsecaseRemoteRemovedOnCallSpecialist =>
    new RemoteRemovedOnCallSpecialist(
      makeApiUrl('/oncall/{onCallId}/specialists/{specialistId}/REMOVED'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxRemovedOnCallSpecialist =
  (): UsecaseReduxRemovedOnCallSpecialist =>
    new ReduxRemovedOnCallSpecialist(); */
