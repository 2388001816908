import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.form`
  width: 100%;
  row-gap: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;

  #header {
    width: 100%;
    align-items: center;
    padding: 0px 40px;
  }
`;

export const Body = styled.div`
  width: 100%;
`;
export const WrapperButton = styled.div`
  margin: 15px 0;
`;

export const Title = styled.h4`
  margin: 15px 0;
  color: ${defaultTheme.typographyLightGray};
`;
