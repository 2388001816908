import { AppointmentConfirmation } from '~/domain/usecases/appointment/redux';
import {
  iActionAppointmentConfirm,
  AppointmentTypes,
  iActionAppointmentConfirmFailed,
  iActionAppointmentConfirmSucess,
} from '~/domain/interfaces/redux/appointment/appointmentConfirmation';

export const confirmRequest = (
  payload: AppointmentConfirmation.Params,
): iActionAppointmentConfirm => ({
  type: AppointmentTypes.APPOINTMENTS_CONFIRMATION,
  payload,
});

export const confirmSuccess = (): iActionAppointmentConfirmSucess => ({
  type: AppointmentTypes.APPOINTMENTS_CONFIRMATION_SUCCESS,
});

export const confirmFailed = (): iActionAppointmentConfirmFailed => ({
  type: AppointmentTypes.APPOINTMENTS_CONFIRMATION_FAILED,
});
