import {
  MessageTypes,
  iActionActive,
} from '~/domain/interfaces/redux/message/active';

import { ActiveMessage } from '~/domain/usecases/message/redux';

export const active = (payload: ActiveMessage.Params): iActionActive => ({
  type: MessageTypes.MESSAGE_ACTIVE,
  payload,
});
