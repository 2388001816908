import React, { useState } from 'react';
import { Container } from './styles/StyledClinicalDoc';
import { ExamRequest } from '~/presentation/components/examRequest';
import { SimpleRecipe } from '~/presentation/components/simplePrescription';
import { SpecialPrescription } from '~/presentation/components/specialPrescription';
import { MedicalCertificate } from '~/presentation/components/medicalCertificate';
import { MedicalReport } from '~/presentation/components/medicalReport';
import { APAC } from '~/presentation/components/apac';
import { AntiMicrobial } from '~/presentation/components/antimicrobial';
import ChooseDoc from './ChooseDoc';

export interface iStateParams {
  patient?: string;
  appointment?: string;
}

const ClinicalDoc: React.FC = () => {
  const [docType, setDocType] = useState<number>(0);

  return (
    <Container>
      {docType === 0 && <ChooseDoc setDocType={setDocType} />}
      {docType === 1 && <MedicalCertificate />}
      {docType === 2 && <SimpleRecipe />}
      {docType === 3 && <SpecialPrescription />}
      {docType === 4 && <AntiMicrobial />}
      {docType === 5 && <ExamRequest />}
      {docType === 6 && <MedicalReport />}
      {docType === 7 && <APAC />}
    </Container>
  );
};

export default ClinicalDoc;
