import styled, { css, keyframes } from 'styled-components';
import media from '~/utils/mediaqueries';
import WaitingRoomImage from '../../../base/icons/backgroundWaitingRoom.svg';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow: hidden; */
`;

export const HeaderBack = styled.header`
  width: 100%;
  height: 54px;
  max-height: 54px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 0px 36px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #df2c26;

  .back {
    color: #7a7a7a;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .title {
    width: 100%;
    height: 100%;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 37px;
  }
`;

interface MainProps {
  isHint?: boolean;
}

export const Main = styled.div<MainProps>`
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 28px;

  background: url(${WaitingRoomImage}) no-repeat center center;
  background-size: cover;
  backdrop-filter: blur(1rem);
  /*  ${({ isHint }) =>
    isHint &&
    css`
      backdrop-filter: blur(1rem);
    `} */
`;

interface ContentFormProps {
  isHint?: boolean;
}

const pulseButton = keyframes`
  0% {
    box-shadow: 0 0 0 0 #DF2C26;
  }

  70% {
    box-shadow: 0 0 0 5px rgba(1, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(1, 0, 0, 0);
  }
`;

export const ContentForm = styled.div<ContentFormProps>`
  width: 100%;
  display: flex;
  align-items: center;
  height: fit-content;
  flex-direction: column;

  .tag {
    ${({ isHint }) =>
      isHint &&
      css`
        animation: ${pulseButton} 2s infinite;
        z-index: 3;
      `}
  }
`;

export const ContentTags = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  gap: 12px;
  cursor: pointer;

  /*Resolução fullHD em diante */
  ${media.greaterThan('xxl')} {
    max-width: 650px;
  }

  /*Apenas Resolução FullHD */
  ${media.lessThan('xxl')} {
    max-width: 650px;
  }

  /* Resolução HD */
  ${media.lessThan('lg')} {
    max-width: 500px;
  }

  /* Resolução 1440px */
  ${media.lessThan('xl')} {
    max-width: 500px;
  }

  /* Celulares com Largura entre 360px e 480px */
  ${media.lessThan('xs')} {
    max-width: 350px;
  }

  /* Celular com 375px de Largura */
  @media (max-width: 375px) {
    max-width: 320px;
  }

  @media (max-height: 910px) {
    max-width: 515px;
  }
`;

interface TagProps {
  idx: number;
}

export const Tag = styled.div.attrs({
  className: 'tag',
})<TagProps>`
  display: flex;
  align-items: center;
  text-align: left;
  height: 65px;
  padding: 11px 0px 9px 23px;
  gap: 23px;
  border: 1px solid #c7ced4;
  box-shadow: 0px 2.43px 6.49px rgba(149, 153, 242, 0.2);
  border-radius: 8px;
  background: white;

  &:hover {
    border: 1px solid #df2c26;
  }

  ${({ idx }) => {
    switch (idx) {
      case 0:
        return css`
          .light {
            fill: #3a8252;
          }

          .dark {
            fill: #4faa6d;
          }
        `;
      case 1:
        return css`
          .light {
            fill: #ff656f;
          }
          .dark {
            fill: #cc5159;
          }
        `;
      case 2:
        return css`
          .light {
            fill: #58a8c9;
          }
          .dark {
            fill: #61899a;
          }
        `;
      default:
        return css``;
    }
  }}
`;

export const TagText = styled.div`
  width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.div`
  margin-top: 330px;
  position: relative;
  z-index: -1;

  /*Resolução fullHD em diante */
  ${media.greaterThan('xxl')} {
    #icon_desk {
      width: 650px;
      height: 600px;
    }
  }

  /*Apenas Resolução FullHD */
  ${media.lessThan('xxl')} {
    #icon_desk {
      width: 650px;
      height: 600px;
    }
  }

  /* Resolução HD */
  ${media.lessThan('lg')} {
    #icon_desk {
      width: 500px;
      height: 400px;
    }
  }

  /* Resolução 1440px */
  ${media.lessThan('xl')} {
    #icon_desk {
      width: 500px;
      height: 400px;
    }
  }

  /* Celulares com Largura entre 360px e 480px */
  ${media.lessThan('xs')} {
    #icon_desk {
      width: 350px;
      height: 400px;
    }
  }

  @media (max-height: 910px) {
    #icon_desk {
      width: 515px;
      height: 415px;
    }
  }
`;

export const Logos = styled.div`
  position: absolute;
  width: 100%;
  bottom: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 26px;

  ${media.lessThan('xxs')} {
    justify-content: center;
  }

  @media screen and (min-width: 280px) and (max-width: 280px) {
    justify-content: center;
  }
`;

interface BackDropProps {
  isHint?: boolean;
}

export const BackDrop = styled.div<BackDropProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 86px;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  top: 0;
  left: 0;
  z-index: 1;
  backdrop-filter: ${({ isHint }) => (isHint ? 'blur(1rem)' : 'none')};
`;

export const ModalTest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 4rem;
  background: #fff;
  border-radius: 8px;
  max-width: 30rem;

  box-shadow: 0px 3px 8px rgba(25, 30, 159, 0.1);

  p {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 400;
    text-wrap: wrap;
    margin-bottom: 1.5rem;
  }
`;

export const BodyToast = styled.div`
  display: 'flex';
  align-items: 'center';
  height: '60px';
  font-size: '16px';
  color: '#1C1413';
  //margin-right: '19px';
  //width: '100%';
`;
