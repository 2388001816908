import { call, put } from 'redux-saga/effects';
import {
  getDutyRequestersFailed,
  getDutyRequestersSuccess,
} from '~/data/store/reducer/duty/actions/getDutyRequesters';
import { iActionGetDutyRequesters } from '~/domain/interfaces/redux/duty/getDutyRequesters';
import { GetDutyRequesters } from '~/domain/usecases/duty/remote/GetDutyRequesters';

import { makeRemoteGetDuties } from '~/main/factories/usecases/duty/GetDuties';

export function* onGetDutyRequesters(action: iActionGetDutyRequesters) {
  const remoteGetDuties = makeRemoteGetDuties();

  try {
    const response: GetDutyRequesters.Model = yield call(
      remoteGetDuties.get,
      action.payload,
    );

    yield put(getDutyRequestersSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getDutyRequestersFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDutyRequestersSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetDutyRequestersFailed() {}
