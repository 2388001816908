import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { PageHeader } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';

import { GetAppointmentById } from '~/domain/usecases/appointment/remote';
import { makeRemoteGetAppointmentById } from '~/main/factories/usecases/appointment/GetAppointmentbyIdFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { History } from '~/main/routes';
import WaitingRoomContent from '~/presentation/components/WaitingRoomContent';
import CardWaitingRoomDetails from '~/presentation/components/cardWaitingRoomDetails';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { closeModal } from '~/utils/closeModal';
import { Body, Container, Left, Right } from './styles/StyledNewWaitingRoom';
import { translator } from '~/presentation/components/i18n';

export interface ownProps {
  role: 'PRO' | 'CON';
  ready: boolean;
  left: () => any;
  appointmentId?: string;
  consultantId?: number;
  professionalId?: number;
  waitingRoomUserId?: number;
}

const NewWaitingRoom: React.FC<ownProps> = ({
  role,
  ready,
  left,
  appointmentId,
  consultantId,
  professionalId,
  waitingRoomUserId,
}): JSX.Element => {
  const [appointment, setAppointment] = useState<GetAppointmentById.Model>(
    {} as GetAppointmentById.Model,
  );
  const [isMicOn, setIsMicOn] = useState<boolean>(false);
  const [isCamOn, setIsCamOn] = useState<boolean>(false);

  const acceptedTerms =
    role === 'PRO'
      ? 'accepted'
      : localStorage.getItem('waitingRoomAcceptedTerms') ?? null;

  const professionalName = useMemo(
    () =>
      appointment
        ? `${appointment?.professional?.firstName} ${appointment?.professional?.lastName}`
        : undefined,
    [appointment],
  );

  const getAppointmentById = useCallback(async () => {
    try {
      const response = await makeRemoteGetAppointmentById().getAppointmentById({
        id: Number(appointmentId),
      });
      setAppointment(prevState => ({ ...prevState, ...response }));
    } catch (error) {
      AlertMessage({
        message: 'Erro ao carregar dados da consulta',
        type: 'danger',
      });
    }
  }, [appointmentId]);

  useEffect(() => {
    if (acceptedTerms !== 'accepted') {
      makeReduxActiveMessage().active({
        active: 'acceptTermsWaitingRoom',
        actionOk: () => {
          localStorage.setItem('waitingRoomAcceptedTerms', 'accepted');
          closeModal();
        },
        actionCancel: () => {
          closeModal();
          History.getHistory().push('/home');
        },
        data: {
          acceptTermsWaitingRoom: {
            professionalName: professionalName ?? '',
          },
        },
      });
    }
  }, [acceptedTerms, professionalName]);

  useEffect(() => {
    if (ready && acceptedTerms === 'accepted') {
      makeReduxActiveMessage().active({
        active: 'readyWaitingRoom',
        actionOk: () => {
          History.getHistory().push('/conf', {
            appointmentId,
            waitingRoomUserId,
            professionalId,
            consultantId,
            isMicOn,
            isCamOn,
          });
          closeModal();
        },
        actionCancel: () => {
          History.getHistory().push('/home');
          closeModal();
        },
        data: {
          readyWaitingRoom: {
            role,
          },
        },
      });
    }

    if (!ready) {
      closeModal();
    }
  }, [
    left,
    ready,
    acceptedTerms,
    role,
    waitingRoomUserId,
    professionalId,
    consultantId,
    appointmentId,
    isMicOn,
    isCamOn,
  ]);

  const startCamera = () => {
    setTimeout(() => {
      const video: HTMLVideoElement | null = document.querySelector('#webcam');

      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: 'user' } })
          .then(function (stream) {
            window.localStream = stream;

            if (!video) return;

            video.srcObject = stream;

            video.setAttribute('autoplay', '');
            video.setAttribute('muted', '');
            video.setAttribute('playsinline', '');
          })
          .catch(function (error) {
            // this.setState({ camStatus: false});
          });
      }
    }, 300);
  };

  const stopCamera = () => {
    try {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      AlertMessage({
        message: 'Não foi possível desativar a câmera.',
        type: 'warning',
      });
    }
  };

  const toggleWebcam = () => {
    if (isCamOn) {
      stopCamera();
    } else {
      startCamera();
    }

    setIsCamOn(prevState => !prevState);
  };

  const stopMic = () => {
    try {
      window.localStream.getTracks().forEach((track: any) => {
        track.stop();
      });
    } catch {
      console.error('Não foi possível desativar o microfone.');
    }
  };

  const toggleMic = () => {
    try {
      if (isMicOn) {
        stopMic();
      }
      setIsMicOn(prevState => !prevState);
    } catch {
      AlertMessage({
        message: 'Não foi possível desativar o microfone.',
        type: 'warning',
      });
    }
  };

  useEffect(() => {
    if (appointmentId) getAppointmentById();
  }, [appointmentId, getAppointmentById]);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);

  return (
    <Container>
      <Menu />
      <PageHeader title={translator('Sala de Espera Virtual')} />

      <Body>
        <Left>
          <WaitingRoomContent
            isMicOn={isMicOn}
            isCamOn={isCamOn}
            startCamera={startCamera}
            stopCamera={stopCamera}
            toggleWebcam={toggleWebcam}
            stopMic={stopMic}
            toggleMic={toggleMic}
          />
        </Left>
        <Right>
          <CardWaitingRoomDetails
            appointmentId={Number(appointmentId)}
            role={role}
          />
        </Right>
      </Body>
    </Container>
  );
};

export default NewWaitingRoom;
