import { CreateSupportDocs } from '~/domain/usecases/supportDoc/redux';
import {
  iActionCreate,
  iActionCreateFailed,
  iActionCreateSuccess,
  SupportTypes,
} from '~/domain/interfaces/redux/supportDoc/create';

export const createRequest = (
  payload: CreateSupportDocs.Params,
): iActionCreate => ({
  type: SupportTypes.CREATE,
  payload,
});

export const createSuccess = (
  Params: CreateSupportDocs.Model,
): iActionCreateSuccess => ({
  type: SupportTypes.CREATE_SUCCESS,
  payload: Params,
});

export const createFailed = (): iActionCreateFailed => ({
  type: SupportTypes.CREATE_FAILED,
});
