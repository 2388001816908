import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAuditRecord } from '~/data/store/reducer/AuditRecord/actions/get';
import { iActionGetAuditRecord } from '~/domain/interfaces/redux/auditRecord/GetAuditRecord';
import { GetAuditRecord } from '~/domain/usecases/AuditRecord/redux';

export class ReduxGetAuditRecord implements GetAuditRecord {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: GetAuditRecord.Params): iActionGetAuditRecord =>
    this.send(getAuditRecord(params));
}
