import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

export const Header = styled.div`
  width: 100%;
  height: 3.3em;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-bottom: 2px solid #efefef;
  color: #7a7a7a;

  span {
    font-size: 1.2em;
    font-weight: bold;
    color: #000;
    text-align: center;
    text-overflow: ellipsis;
    margin-left: 30px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  max-width: 22em;
  max-height: 19em;
  width: 100%;
  height: 100%;

  position: fixed;
  background: white;
  padding-top: 32px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  gap: 15px;
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 20px;
  gap: 13px;
`;
