import { GetAllSpecialty } from '~/domain/usecases/specialty/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteGetAllSpecialty implements GetAllSpecialty {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAllSpecialty.Model>;

  constructor(url: string, httClient: HttpClient<GetAllSpecialty.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getAll = async (
    params: GetAllSpecialty.Params,
  ): Promise<GetAllSpecialty.Model> => {
    let query = '';
    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      body: params,
      url: `${this.url}`,
      method: 'post',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
