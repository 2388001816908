import styled from 'styled-components';
import iconCancel from '~/presentation/base/icons/cancel.svg';
import iconCancelOutline from '~/presentation/base/icons/cancelOutline.svg';
import iconDetails from '~/presentation/base/icons/details.svg';
import iconDetailsOutline from '~/presentation/base/icons/detailsOutline.svg';
import iconPerson from '~/presentation/base/icons/person.svg';
import iconPersonOutline from '~/presentation/base/icons/personOutline.svg';
import iconDelete from '~/presentation/base/icons/delete.svg';
import iconDeleteOutline from '~/presentation/base/icons/deleteOutline.svg';

interface StyledProps {
  list?: string;
}
export const Container = styled.div<StyledProps>`
  display: grid;
  grid-gap: 10px;
  /* height: 100%; */
  grid-template-columns: ${({ list }) => {
    switch (list) {
      case 'registered-schedules':
        return '2fr 2fr 1fr 0.6fr 0.5fr 1fr 1fr 1fr';
      case 'registered-professional':
        return '0.5fr 1.3fr 2fr 1fr 1.5fr 1fr 2fr 2fr';
      case 'registered-participant':
        return '0.5fr 1fr 2fr 0.5fr 1fr 2fr 1fr';
      case 'registered-manager':
        return '60px 1fr 2fr 1fr 1fr 2fr';
      default:
        return 'auto';
    }
  }};
  /* grid-template-columns: repeat(auto,1fr); */
  /* grid-template-rows: auto; */
  align-items: center;
  justify-items: start;
  /* grid-template-rows: auto;
  grid-template-columns: auto; */
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  width: 100%;
`;

export const Avatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  text {
    margin-left: 5px;
  }
`;
export const Status = styled.div``;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Cancel = styled.div`
  background-image: url(${iconCancelOutline});
  margin: 0px 3px;
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconCancel});
  }
`;

export const Details = styled.div`
  margin: 0px 3px;
  background-image: url(${iconDetailsOutline});
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconDetails});
  }
`;

export const Delete = styled.div`
  margin: 0px 3px;
  background-image: url(${iconDeleteOutline});
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconDelete});
  }
`;

export const Person = styled.div`
  margin: 0px 3px;
  background-image: url(${iconPersonOutline});
  width: 26px;
  height: 26px;
  :hover {
    background-image: url(${iconPerson});
  }
`;

export const Img = styled.div`
  border-radius: 50px;
  width: 40px;
  background-color: #000;
  height: 40px;
`;
