import { call, put } from 'redux-saga/effects';
import { iActionGet } from '~/domain/interfaces/redux/chat/get';
import { GetChat } from '~/domain/usecases/chat/remote/GetChat';
import { makeRemoteGetChat } from '~/main/factories/usecases/chat/GetChatFactory';
import { getSuccess, getFailed } from '~/data/store/reducer/chat/actions/get';

export function* onGet(action: iActionGet) {
  const remoteGetChat = makeRemoteGetChat();

  try {
    const response: GetChat.Model = yield call(
      remoteGetChat.get,
      action.payload,
    );

    yield put(getSuccess(response));
  } catch (e) {
    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetFailed() {}
