/* eslint-disable @typescript-eslint/no-empty-function */
import { call, put } from 'redux-saga/effects';
import { iActionGetShiftsById } from '~/domain/interfaces/redux/shifts/getById';

import { GetShiftsById } from '~/domain/usecases/shifts/remote';

import {
  getShiftsByIdFailed,
  getShiftsByIdSuccess,
} from '~/data/store/reducer/shifts/actions/getById';

import { makeRemoteGetShiftsById } from '~/main/factories/usecases/shifts';

export function* onGetShiftsById(action: iActionGetShiftsById) {
  const remoteGetShiftsById = makeRemoteGetShiftsById();

  try {
    const response: GetShiftsById.Model = yield call(
      remoteGetShiftsById.getById,
      action.payload,
    );

    yield put(getShiftsByIdSuccess(response));
  } catch (e) {
    yield put(getShiftsByIdFailed());
  }
}

export function onGetShiftsByIdSuccess() {}

export function onGetShiftsByIdFailed() {}
