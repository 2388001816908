import styled from 'styled-components';

interface AddProfessionalButonStatus {
  status: boolean;
}

export const Container = styled.form`
  width: 100%;
`;

export const ContentSelects = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContainerButtonsGrid = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

export const ContentButtonsGrid = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const StyleButton = styled.button<AddProfessionalButonStatus>`
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: ${props => (props.status ? '#ffffff' : '#c9c9c9')};
  cursor: pointer;
  background: ${props => (props.status ? '#1756e8' : '#efefef')};
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;

  span {
    font-size: 35px;
    line-height: 35px;
    font-weight: bold;
  }

  &:hover {
    background-color: ${props => (props.status ? '#006ef2' : '')};
    color: ${props => (props.status ? '#ffffff' : '#c9c9c9')};
    transition: all 0.3s;
    cursor: ${props => (props.status ? 'pointer' : 'not-allowed')};
  }
  &:active {
    background-color: ${props => (props.status ? '#004ba5' : '#efefef')};
    transition: 0.2s;
    color: ${props => (props.status ? '#ffffff' : '#c9c9c9')};
  }
`;

export const StyleAdd = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #004ba5;
`;

export const SelectAndButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
