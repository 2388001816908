import {
  ShowModalTypes,
  iActionSet,
} from '~/domain/interfaces/redux/showModal/set';

import { ShowModal } from '~/domain/usecases/showModal/redux';

export const set = (payload: ShowModal.Params): iActionSet => ({
  type: ShowModalTypes.SET,
  payload,
});
