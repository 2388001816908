import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { InviteAppointment as UsecaseRemoteInviteAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteInviteAppointment } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteInviteAppointment = (): UsecaseRemoteInviteAppointment =>
  new RemoteInviteAppointment(
    makeApiUrl('/appointments/{appointmentId}/invite'),
    makeHttpClient(),
  );
