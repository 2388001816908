import { makeHttpClient, makeApiUrl } from '~/main/factories/http';
import { GetAllHealthUnits as UsecaseRemoteGetAllHealthUnits } from '~/domain/usecases/healthUnits/remote';
import { GetAllHealthUnits as UseCaseReduxGetAllHealthUnits } from '~/domain/usecases/healthUnits/redux';

import { RemoteGetAllHealthUnits } from '~/data/repository/healthUnits/RemoteGetAllHealthUnits';
import { ReduxGetAllHealthUnits } from '~/data/store/reducer/healthUnits/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllHealthUnits = (): UsecaseRemoteGetAllHealthUnits =>
  new RemoteGetAllHealthUnits(
    makeApiUrl('/healthUnits/list'),
    makeHttpClient(),
  );

/**
 * send request via Redux.
 */
export const makeReduxGetAllHealthUnits = (): UseCaseReduxGetAllHealthUnits =>
  new ReduxGetAllHealthUnits();
