import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateSpecialty as UsecaseReduxUpdateSpecialty } from '~/domain/usecases/specialty/redux';
import { UpdateSpecialty as UsecaseRemoteUpdateSpecialty } from '~/domain/usecases/specialty/remote';

import { RemoteUpdateSpecialty } from '~/data/repository/specialty';
import { ReduxUpdateSpecialty } from '~/data/store/reducer/specialty/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateSpecialty = (): UsecaseRemoteUpdateSpecialty =>
  new RemoteUpdateSpecialty(makeApiUrl('/professionals'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxUpdateSpecialty = (): UsecaseReduxUpdateSpecialty =>
  new ReduxUpdateSpecialty();
