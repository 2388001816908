export enum AdministratorTypes {
  CREATE = '@administrator/CREATE',
  CREATE_SUCCESS = '@administrator/CREATE_SUCCESS',
  CREATE_FAILED = '@administrator/CREATE_FAILED',
  UPDATE = '@administrator/UPDATE',
  UPDATE_SUCCESS = '@administrator/UPDATE_SUCCESS',
  UPDATE_FAILED = '@administrator/UPDATE_FAILED',
  DELETE = '@administrator/DELETE',
  DELETE_SUCCESS = '@administrator/DELETE_SUCCESS',
  DELETE_FAILED = '@administrator/DELETE_FAILED',
  GETALL = '@administrator/GETALL',
  GETALL_SUCCESS = '@administrator/GETALL_SUCCESS',
  GETALL_FAILED = '@administrator/GETALL_FAILED',
  GET = '@administrator/GET',
  GET_SUCCESS = '@administrator/GET_SUCCESS',
  GET_FAILED = '@administrator/GET_FAILED',
}
