import { SetupDashboard } from '~/domain/usecases/dashboard/redux/SetupDashboard';
import {
  iActionSetup,
  DashboardTypes,
} from '~/domain/interfaces/redux/dashboard/setup';

export const setupRequest = (payload: SetupDashboard.Params): iActionSetup => ({
  type: DashboardTypes.SETUP,
  payload,
});
