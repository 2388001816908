import { validDate } from '~/utils/CompareDate';
import { GetAllAppointment } from '~/domain/usecases/appointment/remote/GetAllAppointment';

export const MapTotalItems = (data: GetAllAppointment.Model, date: string) => {
  let totalItems = 0;

  data.results.forEach(e => {
    if (validDate(new Date(date), new Date(e.appointment.scheduled)))
      totalItems += 1;
  });

  return totalItems;
};
