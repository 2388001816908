import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { AvatarImage } from '.';
import {
  ContainerAvatars,
  ContainerImage,
  Name,
  Roles,
  ContainerInfo,
} from './styles/styled';

import { translator } from '../i18n';

interface propsFunction {
  role: 'organizer' | 'paciente' | undefined;
  name: string;
  cargo: 'ORG' | 'PRO' | 'CON' | 'ADM';
  src?: string;
  size?: 'small' | 'orange' | 'large' | 'medium' | 'root' | 'mini';
}

const AvatarRoles: React.FC<propsFunction> = ({
  role,
  name,
  cargo,
  src,
  size = 'root',
}) => {
  const userType = {
    ORG: translator('Organizador'),
    PRO: translator('Profissional'),
    CON: translator('Paciente'),
    ADM: translator('Administrador'),
  };

  const { loading } = useSelector((store: iStore) => store.auth);

  return (
    <ContainerAvatars>
      <div className={role}>
        <ContainerInfo>
          <Name>{name}</Name>
          <Roles>
            {loading ? (
              <Skeleton width="100px" height="20px" />
            ) : (
              userType[cargo]
            )}
          </Roles>
        </ContainerInfo>
        <ContainerImage id="profile">
          <AvatarImage size={size} name={name} src={src} />
        </ContainerImage>
      </div>
    </ContainerAvatars>
  );
};

export default AvatarRoles;
