import { EvaluateDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import {
  NotFound,
  BadRequestError,
  Forbidden,
  UnexpectedError,
  BadGateway,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteEvaluateDiagnosisReport implements EvaluateDiagnosisReport {
  private readonly url: string;

  private readonly httpClient: HttpClient<EvaluateDiagnosisReport.Model>;

  constructor(
    url: string,
    httClient: HttpClient<EvaluateDiagnosisReport.Model>,
  ) {
    this.url = url;
    this.httpClient = httClient;
  }

  evaluate = async (
    params: EvaluateDiagnosisReport.Params,
  ): Promise<EvaluateDiagnosisReport.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{report}', params.id.toString()),
      body: params.body,
      method: 'post',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
