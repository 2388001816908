import { zodResolver } from '@hookform/resolvers/zod';

import React from 'react';
import { useForm } from 'react-hook-form';
import { translator } from '~/presentation/components/i18n';
import { schemaProcedure } from '~/validation/validators/document/BasicPrescriptionContentValidator';
import { Navigator } from '../register/Navigator';
import { Input } from '../UI';
import { iValidation } from './interface';
import {
  Container,
  Form,
  Row,
  RowBiggerCenter,
} from './styles/StyledPrescription';

export interface ownProps {
  next: (data: iValidation) => any;
  back: (data: iValidation) => any;
  state?: iValidation;
}

const Prescription: React.FC<ownProps> = props => {
  const { errors, handleSubmit, register, setValue, getValues, watch } =
    useForm({
      mode: 'onChange',
      shouldFocusError: true,
      resolver: zodResolver(schemaProcedure),
      defaultValues: {
        ...props.state?.contentRaw,
      },
    });

  const onBack = () => props.back({ ...props.state });

  const onSubmit = handleSubmit(data => {
    props.next({
      ...props.state,
      contentRaw: data,
    });
  });

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <RowBiggerCenter>
          <Input
            label="Código do procedimento"
            name="primaryProcedure.code"
            defaultValue={watch('primaryProcedure.code')}
            register={() => register('primaryProcedure.code')}
            error={Boolean(errors?.primaryProcedure?.code)}
            message={
              errors?.primaryProcedure?.code?.message
                ? translator(errors?.primaryProcedure?.code?.message)
                : ''
            }
            onChange={e => {
              setValue('primaryProcedure.code', e.target.value);
            }}
          />

          <Input
            label="Nome do procedimento principal"
            name="primaryProcedure.name"
            defaultValue={watch('primaryProcedure.name')}
            register={() => register('primaryProcedure.name')}
            error={Boolean(errors?.primaryProcedure?.name)}
            message={
              errors?.primaryProcedure?.name?.message
                ? translator(errors?.primaryProcedure?.name?.message)
                : ''
            }
            onChange={e => {
              setValue('primaryProcedure.name', e.target.value);
            }}
          />

          <Input
            label="Quantidade"
            name="primaryProcedure.quantity"
            defaultValue={watch('primaryProcedure.quantity')}
            register={() => register('primaryProcedure.quantity')}
            error={Boolean(errors?.primaryProcedure?.quantity)}
            message={
              errors?.primaryProcedure?.quantity?.message
                ? translator(errors?.primaryProcedure?.quantity?.message)
                : ''
            }
            onChange={e => {
              setValue('primaryProcedure.quantity', Number(e.target.value));
            }}
          />
        </RowBiggerCenter>
        <RowBiggerCenter>
          <Input
            label="Código do procedimento"
            name="secundaryProcedures1.code"
            defaultValue={watch('secundaryProcedures1.code')}
            register={() => register('secundaryProcedures1.code')}
            error={Boolean(errors?.secundaryProcedures1?.code)}
            message={
              errors?.secundaryProcedures1?.code?.message
                ? translator(errors?.secundaryProcedures1?.code?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures1.code', e.target.value);
            }}
          />

          <Input
            label="Nome do procedimento secundário"
            name="secundaryProcedures1.name"
            defaultValue={watch('secundaryProcedures1.name')}
            register={() => register('secundaryProcedures1.name')}
            error={Boolean(errors?.secundaryProcedures1?.name)}
            message={
              errors?.secundaryProcedures1?.name?.message
                ? translator(errors?.secundaryProcedures1?.name?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures1.name', e.target.value);
            }}
          />

          <Input
            label="Quantidade"
            name="secundaryProcedures1.quantity"
            defaultValue={watch('secundaryProcedures1.quantity')}
            register={() => register('secundaryProcedures1.quantity')}
            error={Boolean(errors?.secundaryProcedures1?.quantity)}
            message={
              errors?.secundaryProcedures1?.quantity?.message
                ? translator(errors?.secundaryProcedures1?.quantity?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures1.quantity', Number(e.target.value));
            }}
          />
        </RowBiggerCenter>
        <RowBiggerCenter>
          <Input
            label="Código do procedimento"
            name="secundaryProcedures2.code"
            defaultValue={watch('secundaryProcedures2.code')}
            register={() => register('secundaryProcedures2.code')}
            error={Boolean(errors?.secundaryProcedures2?.code)}
            message={
              errors?.secundaryProcedures2?.code?.message
                ? translator(errors?.secundaryProcedures2?.code?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures2.code', e.target.value);
            }}
          />

          <Input
            label="Nome do procedimento secundário"
            name="secundaryProcedures2.name"
            defaultValue={watch('secundaryProcedures2.name')}
            register={() => register('secundaryProcedures2.name')}
            error={Boolean(errors?.secundaryProcedures2?.name)}
            message={
              errors?.secundaryProcedures2?.name?.message
                ? translator(errors?.secundaryProcedures2?.name?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures2.name', e.target.value);
            }}
          />

          <Input
            label="Quantidade"
            name="secundaryProcedures2.quantity"
            defaultValue={watch('secundaryProcedures2.quantity')}
            register={() => register('secundaryProcedures2.quantity')}
            error={Boolean(errors?.secundaryProcedures2?.quantity)}
            message={
              errors?.secundaryProcedures2?.quantity?.message
                ? translator(errors?.secundaryProcedures2?.quantity?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures2.quantity', Number(e.target.value));
            }}
          />
        </RowBiggerCenter>
        <RowBiggerCenter>
          <Input
            label="Código do procedimento"
            name="secundaryProcedures3.code"
            defaultValue={watch('secundaryProcedures3.code')}
            register={() => register('secundaryProcedures3.code')}
            error={Boolean(errors?.secundaryProcedures3?.code)}
            message={
              errors?.secundaryProcedures3?.code?.message
                ? translator(errors?.secundaryProcedures3?.code?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures3.code', e.target.value);
            }}
          />

          <Input
            label="Nome do procedimento secundário"
            name="secundaryProcedures3.name"
            defaultValue={watch('secundaryProcedures3.name')}
            register={() => register('secundaryProcedures3.name')}
            error={Boolean(errors?.secundaryProcedures3?.name)}
            message={
              errors?.secundaryProcedures3?.name?.message
                ? translator(errors?.secundaryProcedures3?.name?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures3.name', e.target.value);
            }}
          />

          <Input
            label="Quantidade"
            name="secundaryProcedures3.quantity"
            defaultValue={watch('secundaryProcedures3.quantity')}
            register={() => register('secundaryProcedures3.quantity')}
            error={Boolean(errors?.secundaryProcedures3?.quantity)}
            message={
              errors?.secundaryProcedures3?.quantity?.message
                ? translator(errors?.secundaryProcedures3?.quantity?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures3.quantity', Number(e.target.value));
            }}
          />
        </RowBiggerCenter>
        <RowBiggerCenter>
          <Input
            label="Código do procedimento"
            name="secundaryProcedures4.code"
            defaultValue={watch('secundaryProcedures4.code')}
            register={() => register('secundaryProcedures4.code')}
            error={Boolean(errors?.secundaryProcedures4?.code)}
            message={
              errors?.secundaryProcedures4?.code?.message
                ? translator(errors?.secundaryProcedures4?.code?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures4.code', e.target.value);
            }}
          />

          <Input
            label="Nome do procedimento secundário"
            name="secundaryProcedures4.name"
            defaultValue={watch('secundaryProcedures4.name')}
            register={() => register('secundaryProcedures4.name')}
            error={Boolean(errors?.secundaryProcedures4?.name)}
            message={
              errors?.secundaryProcedures4?.name?.message
                ? translator(errors?.secundaryProcedures4?.name?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures4.name', e.target.value);
            }}
          />

          <Input
            label="Quantidade"
            name="secundaryProcedures4.quantity"
            defaultValue={watch('secundaryProcedures4.quantity')}
            register={() => register('secundaryProcedures4.quantity')}
            error={Boolean(errors?.secundaryProcedures4?.quantity)}
            message={
              errors?.secundaryProcedures4?.quantity?.message
                ? translator(errors?.secundaryProcedures4?.quantity?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures4.quantity', Number(e.target.value));
            }}
          />
        </RowBiggerCenter>
        <RowBiggerCenter>
          <Input
            label="Código do procedimento"
            name="secundaryProcedures5.code"
            defaultValue={watch('secundaryProcedures5.code')}
            register={() => register('secundaryProcedures5.code')}
            error={Boolean(errors?.secundaryProcedures5?.code)}
            message={
              errors?.secundaryProcedures5?.code?.message
                ? translator(errors?.secundaryProcedures5?.code?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures5.code', e.target.value);
            }}
          />

          <Input
            label="Nome do procedimento secundário"
            name="secundaryProcedures5.name"
            defaultValue={watch('secundaryProcedures5.name')}
            register={() => register('secundaryProcedures5.name')}
            error={Boolean(errors?.secundaryProcedures5?.name)}
            message={
              errors?.secundaryProcedures5?.name?.message
                ? translator(errors?.secundaryProcedures5?.name?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures5.name', e.target.value);
            }}
          />

          <Input
            label="Quantidade"
            name="secundaryProcedures5.quantity"
            defaultValue={watch('secundaryProcedures5.quantity')}
            register={() => register('secundaryProcedures5.quantity')}
            error={Boolean(errors?.secundaryProcedures5?.quantity)}
            message={
              errors?.secundaryProcedures5?.quantity?.message
                ? translator(errors?.secundaryProcedures5?.quantity?.message)
                : ''
            }
            onChange={e => {
              setValue('secundaryProcedures5.quantity', Number(e.target.value));
            }}
          />
        </RowBiggerCenter>

        <Input
          label="Descrição do diagnóstico"
          name="diagnosis.description"
          defaultValue={watch('diagnosis.description')}
          register={() => register('diagnosis.description')}
          error={Boolean(errors?.diagnosis?.description)}
          message={
            errors?.diagnosis?.description?.message
              ? translator(errors?.diagnosis?.description?.message)
              : ''
          }
          onChange={e => {
            setValue('diagnosis.description', e.target.value);
          }}
        />

        <Row>
          <Input
            label="CID10 Principal"
            name="diagnosis.primaryCID"
            defaultValue={watch('diagnosis.primaryCID')}
            register={() => register('diagnosis.primaryCID')}
            error={Boolean(errors?.diagnosis?.primaryCID)}
            message={
              errors?.diagnosis?.primaryCID?.message
                ? translator(errors?.diagnosis?.primaryCID?.message)
                : ''
            }
            onChange={e => {
              setValue('diagnosis.primaryCID', e.target.value);
            }}
          />

          <Input
            label="CID10 Secundário"
            name="diagnosis.secundaryCID"
            defaultValue={watch('diagnosis.secundaryCID')}
            register={() => register('diagnosis.secundaryCID')}
            error={Boolean(errors?.diagnosis?.secundaryCID)}
            message={
              errors?.diagnosis?.secundaryCID?.message
                ? translator(errors?.diagnosis?.secundaryCID?.message)
                : ''
            }
            onChange={e => {
              setValue('diagnosis.secundaryCID', e.target.value);
            }}
          />

          <Input
            label="CID10 Causas associadas"
            name="diagnosis.associatedCID"
            defaultValue={watch('diagnosis.associatedCID')}
            register={() => register('diagnosis.associatedCID')}
            error={Boolean(errors?.diagnosis?.associatedCID)}
            message={
              errors?.diagnosis?.associatedCID?.message
                ? translator(errors?.diagnosis?.associatedCID?.message)
                : ''
            }
            onChange={e => {
              setValue('diagnosis.associatedCID', e.target.value);
            }}
          />
        </Row>

        <Navigator back={onBack} />
      </Form>
    </Container>
  );
};

export default Prescription;
