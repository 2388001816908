import { call, put, select } from 'redux-saga/effects';
import { iActionList } from '~/domain/interfaces/redux/onCallRequest/list';
import { ListOnCallRequest } from '~/domain/usecases/onCallRequest/remote/ListOnCallRequest';
import { makeRemoteListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import {
  listSuccess,
  listFailed,
} from '~/data/store/reducer/onCallRequest/actions/list';

export function* onList(action: iActionList) {
  const remoteListOnCallRequest = makeRemoteListOnCallRequest();

  try {
    const response: ListOnCallRequest.Model = yield call(
      remoteListOnCallRequest.list,
      action.payload,
    );
    yield put(
      listSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
