import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iActionUpdate } from '~/domain/interfaces/redux/appointment/update';
import {
  UpdateFailed,
  UpdateSuccess,
} from '~/data/store/reducer/appointment/actions/update';
import { makeRemoteUpdateAppointment } from '~/main/factories/usecases/appointment/UpdateAppointmentFactory';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { getAllRequest } from '../../reducer/appointment/actions/getAll';
import { translator } from '~/presentation/components/i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onUpdate(action: iActionUpdate) {
  const remoteUpdateAppointment = makeRemoteUpdateAppointment();

  try {
    yield call(remoteUpdateAppointment.update, action.payload);

    action.payload?.onSuccess?.();

    yield put(UpdateSuccess());
  } catch (e) {
    yield put(UpdateFailed());
  }
}

export function* onUpdateSuccess() {
  yield put(getAllRequest({}));
}

export function onUpdateFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({
      id: 'Falha ao tentar atualizar agendamento.',
    }),
  });
}
