import styled from 'styled-components';

export const Container = styled.label`
  position: relative;
  display: inline-block;
  width: 2.25rem;
  height: 1.125rem;

  margin: 0;

  cursor: pointer;

  transition: all 0.2s ease-in-out;
  &:focus-visible.container {
    outline: none;
    border-radius: 0.625rem;
    box-shadow: 0 0 2px #3d94fd;
    border: 2px solid #3d94fd;
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  user-select: none;

  &:hover + .track {
    background: #b4b7b9;
  }

  &:checked:hover + .track {
    background: #3d94fd;
  }

  &:checked + .track {
    background: #0565d9;
  }

  &:checked + .track .thumb {
    transform: translateX(0.5rem);

    &:focus-visible + .track {
      box-sizing: border-box;
      padding: 0.9rem;
      background: #3d94fd;
      border: 2px solid #3d94fd;
    }
  }

  &:focus-visible + .track {
    border-radius: 0.625rem;
    box-shadow: 0 0 1rem #3d94fd;
    outline: 2px solid #3d94fd;
  }

  &:focus-visible:checked + .track {
    border-radius: 0.625rem;
    box-shadow: 0 0 1rem #3d94fd;
    background: #3d94fd;
    outline: 2px solid #fdfdfd;
  }
  &:disabled + .track {
    cursor: default;
    background: #b4b7b9;

    & .thumb {
      background: #dedede;
    }
  }
`;

export const Track = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.625rem;

  background: #dedede;
`;

export const Thumb = styled.div`
  position: relative;
  /* top: 2px; */
  width: 0.75rem;
  height: 0.75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: translateX(-0.5rem);

  border-radius: 50%;
  background: #fdfdfd;

  transition: 0.2s;
`;
