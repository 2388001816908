import { call, put, select } from 'redux-saga/effects';
import { iActionList } from '~/domain/interfaces/redux/orgUnit/list';
import { iStore } from '~/domain/interfaces/models';
import { OrgUnitList } from '~/domain/usecases/orgUnit/remote/OrgUnitList';
import { makeRemoteOrgUnitList } from '~/main/factories/usecases/orgUnit/OrgUnitListFactory';
import {
  listSuccess,
  listFailed,
} from '~/data/store/reducer/orgUnit/actions/list';

export function* onList(action: iActionList) {
  const remoteOrgUnitList = makeRemoteOrgUnitList();

  try {
    const response: OrgUnitList.Model = yield call(
      remoteOrgUnitList.list,
      action.payload,
    );
    yield put(listSuccess(response));
  } catch (e) {
    yield put(listFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListFailed() {}
