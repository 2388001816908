import { call, put } from 'redux-saga/effects';
import { iActionGetInvoiceByOrg } from '~/domain/interfaces/redux/billing/getInvoiceByOrg';
import { GetInvoiceByOrg } from '~/domain/usecases/billing/remote/GetInvoiceByOrg';
import {
  getFailed,
  getSuccess,
} from '~/data/store/reducer/billing/actions/getInvoiceByOrg';

import { makeRemoteGetInvoiceByOrg } from '~/main/factories/usecases/billing/GetInvoiceByOrgFactory';

export function* onGetInvoiceByOrg(action: iActionGetInvoiceByOrg) {
  const remoteGetInvoiceByOrg = makeRemoteGetInvoiceByOrg();

  try {
    const response: GetInvoiceByOrg.Model = yield call(
      remoteGetInvoiceByOrg.get,
      action.payload,
    );

    yield put(getSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetInvoiceByOrgSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetInvoiceByOrgFailed() {}
