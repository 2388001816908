import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListModalitiesDiagnosisReport as UsecaseRemoteListModalitiesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import { ListModalitiesDiagnosisReport as UsecaseReduxListModalitiesDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemoteListModalitiesDiagnosisReport } from '~/data/repository/diagnosisReport';
import { ReduxListModalitiesDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemoteListModalitiesDiagnosisReport =
  (): UsecaseRemoteListModalitiesDiagnosisReport =>
    new RemoteListModalitiesDiagnosisReport(
      makeApiUrl('/reports/modality/list'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListModalitiesDiagnosisReport =
  (): UsecaseReduxListModalitiesDiagnosisReport =>
    new ReduxListModalitiesDiagnosisReport();
