import { takeLatest } from 'redux-saga/effects';
import { ShiftsTypes } from '~/domain/interfaces/redux/shifts/types';
import {
  onCreateShifts,
  onCreateShiftsFailed,
  onCreateShiftsSuccess,
} from './Create';
import {
  onDeleteShifts,
  onDeleteShiftsFailed,
  onDeleteShiftsSuccess,
} from './Delete';
import {
  onGetShiftsById,
  onGetShiftsByIdFailed,
  onGetShiftsByIdSuccess,
} from './GetById';
import { onListShifts, onListShiftsFailed, onListShiftsSuccess } from './List';
import {
  onUpdateShifts,
  onUpdateShiftsFailed,
  onUpdateShiftsSuccess,
} from './Update';

const tasks = [
  takeLatest(ShiftsTypes.CREATE, onCreateShifts),
  takeLatest(ShiftsTypes.CREATE_SUCCESS, onCreateShiftsSuccess),
  takeLatest(ShiftsTypes.CREATE_FAILED, onCreateShiftsFailed),

  takeLatest(ShiftsTypes.DELETE, onDeleteShifts),
  takeLatest(ShiftsTypes.DELETE_SUCCESS, onDeleteShiftsSuccess),
  takeLatest(ShiftsTypes.DELETE_FAILED, onDeleteShiftsFailed),

  takeLatest(ShiftsTypes.GET_BY_ID, onGetShiftsById),
  takeLatest(ShiftsTypes.GET_BY_ID_SUCCESS, onGetShiftsByIdSuccess),
  takeLatest(ShiftsTypes.GET_BY_ID_FAILED, onGetShiftsByIdFailed),

  takeLatest(ShiftsTypes.GETALL, onListShifts),
  takeLatest(ShiftsTypes.GETALL_SUCCESS, onListShiftsSuccess),
  takeLatest(ShiftsTypes.GETALL_FAILED, onListShiftsFailed),

  takeLatest(ShiftsTypes.UPDATE, onUpdateShifts),
  takeLatest(ShiftsTypes.UPDATE_SUCCESS, onUpdateShiftsSuccess),
  takeLatest(ShiftsTypes.UPDATE_FAILED, onUpdateShiftsFailed),
];

export default tasks;
