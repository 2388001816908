import { UpdateShifts } from '~/domain/usecases/shifts/redux';

import {
  ShiftsTypes,
  iActionUpdateShifts,
  iActionUpdateShiftsFailed,
  iActionUpdateShiftsSuccess,
} from '~/domain/interfaces/redux/shifts/update';

export const updateShiftsRequest = (
  payload: UpdateShifts.Params,
): iActionUpdateShifts => ({
  type: ShiftsTypes.UPDATE,
  payload,
});

export const updateShiftsSuccess = (
  params: UpdateShifts.Model,
): iActionUpdateShiftsSuccess => ({
  type: ShiftsTypes.UPDATE_SUCCESS,
  payload: params,
});

export const updateShiftsFailed = (): iActionUpdateShiftsFailed => ({
  type: ShiftsTypes.UPDATE_FAILED,
});
