/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Alert } from '@material-ui/lab';
import { AlertFreemium } from './styles/styledHeaderExpiration';

interface HeaderExpirationProps {
  daysUse: string;
}

const HeaderExpiration: React.FC<HeaderExpirationProps> = ({ daysUse }) => {
  return (
    <AlertFreemium>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#e5eff9',
        }}
      >
        <Alert variant="filled" severity="info">
          <span>
            Seu período de testes se encerra em {daysUse}. Que tal fazer uma
            assinatura?{' '}
          </span>
          <a href="text" target="_blank">
            Conheça nossos planos.
          </a>
        </Alert>
      </div>
    </AlertFreemium>
  );
};

export default HeaderExpiration;
