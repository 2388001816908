import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ContentSelects = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ContainerButtonsGrid = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
`;

export const ContentButtonsGrid = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
`;

export const StyleButton = styled.button`
  display: flex;
  margin-top: 20px;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  color: #c4c4c4;
  cursor: pointer;
  background: #1756e8;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  margin-left: 10px;
  font-size: 35px;
  &:hover {
    background-color: #004ba5;
    color: white;
    transition: all 0.3s;
  }
  &:active {
    background-color: #006ef2;
    transition: 0.2s;
  }
`;

export const StyleAdd = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #004ba5;
`;
