import styled, { css } from 'styled-components';

interface ContainerProps {
  currentActive: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 72px;
  /* border-radius: 8px; */
  padding: 16px;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;

  ${({ currentActive }) =>
    currentActive &&
    css`
      & ${Hour} {
        color: #1756e8;
      }
      &::before {
        position: absolute;
        content: '';
        width: 6px;
        height: 80%;
        background: #1756e8;
        left: 0;
      }
    `}

  &:hover {
    background-color: #f8f8f8;
  }

  cursor: pointer;
`;

export const Place = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-right: 24px;
`;

export const PlaceText = styled.div`
  color: #818385;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const Hour = styled.div`
  color: #656565;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  margin-right: 36px;
`;

export const InfosContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const UserName = styled.div`
  color: #222529;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  flex-direction: column;
`;

export const YearsUnit = styled.div`
  color: #818385;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  display: flex;

  align-items: center;

  & .dot-separate {
    display: flex;
    align-items: center;
  }

  & .dot-separate + .dot-separate::before {
    content: '';
    float: right;
    margin: 0 4px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #818385;
  }
`;

export const Priority = styled.div`
  color: #fd3939;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
`;
