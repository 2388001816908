import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import {
  Container,
  HeaderDiv,
  Left,
  Content,
  Signed,
  SendPatient,
} from './styles/StyledSpecialPrescriptionView';

import { MediaViewer } from '~/presentation/components/mediaViewer';
import { More } from '~/presentation/components/UI/popover';
import { OptionsClinicalDoc } from '~/presentation/components/optionsMore';
import { translator } from '../i18n';
import { makeRemoteGetByDownloadSupportDocs } from '~/main/factories/usecases/supportDoc/GetByDownloadSupportDocsFactory';
import { V4hSpin } from '../spin';

interface ownProps {
  id: string;
}

const SupportDocView: React.FC<ownProps> = ({ id }) => {
  // const [date, setDate] = useState<string>();
  const [url, setUrl] = useState<string>();
  useEffect(() => {
    makeRemoteGetByDownloadSupportDocs()
      .get({
        docId: Number(id),
      })
      .then(response => setUrl(response.url))
      .catch(e => console.log('ERROR IN GETURL', e));
  }, [url]);

  return url ? (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <More
          content={<OptionsClinicalDoc />}
          positionY="top"
          positionX="right"
        />
      </div>
      {/* <Pdf>
        <MediaViewer link={url} />
      </Pdf> */}
    </Container>
  ) : (
    <V4hSpin fullScreen background="white" />
  );
};

export default SupportDocView;
