import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { InviteCompanionsAppointment as UsecaseRemoteInviteCompanionsAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteInviteCompanionsAppointment } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteInviteCompanionsAppointment =
  (): UsecaseRemoteInviteCompanionsAppointment =>
    new RemoteInviteCompanionsAppointment(
      makeApiUrl(
        '/appointments/{appointmentId}/companions/{companionId}/invite',
      ),
      makeHttpClient(),
    );
