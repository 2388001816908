import styled from 'styled-components';

export const Container = styled.div`
  width: 365px;
  /* height: 331px; */
  /* height: 100%; */

  border-radius: 10px;
  border: 1px #dedede solid;
`;

export const Top = styled.div`
  padding: 24px 24px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SelectLabel = styled.div`
  color: #222529;
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dedede;
  margin: 20px 0px;
`;

export const Content = styled.div`
  padding-bottom: 1rem;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 24px;

  #logo-escala {
    width: 1.8rem;
    aspect-ratio: 1/1;
  }
`;

export const Type = styled.div`
  color: #222529;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  margin-left: 6px;
`;

export const CurrentWeek = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
  padding: 0 24px 0;
`;

export const DaysOfWeek = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 12px;
  text-align: center;
`;

export const Text = styled.div`
  color: #222529;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 600;
`;
export const Box = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 6px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;

  cursor: pointer;
`;

export const TextDays = styled.div`
  color: #222529;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 400;
`;

export const ButtonView = styled.div`
  padding: 0 24px 0;
`;
