import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateForeignClinicalDocs as UsecaseRemoteCreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
// import { CreateForeignClinicalDocs as UsecaseReduxCreateForeignClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteCreateForeignClinicalDocs } from '~/data/repository/clinicalDocs';
// import { ReduxCreateForeignClinicalDocs } from '~/data/store/reducer/clinicalDocs/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateForeignClinicalDocs =
  (): UsecaseRemoteCreateForeignClinicalDocs =>
    new RemoteCreateForeignClinicalDocs(
      makeApiUrl('/clinicalDocs/foreign'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxCreateForeignClinicalDocs =
  (): UsecaseReduxCreateForeignClinicalDocs =>
    new ReduxCreateForeignClinicalDocs(); */
