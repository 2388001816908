/* eslint-disable no-case-declarations */
import { BillingTypes } from '~/domain/interfaces/redux/billing/types';
import {
  iBilling,
  iInvoiceResults,
  iInvoiceServiceResults,
} from '~/domain/interfaces/models';
import { BillingActions } from './actions';

export const initialState: iBilling = {
  loading: false,
  invoiceItems: {} as iInvoiceResults,
  serviceItems: {} as iInvoiceServiceResults,
};

const reducer = (state = initialState, action: BillingActions): iBilling => {
  switch (action.type) {
    case BillingTypes.GET_INVOICE_BY_ORG:
      return { ...state, loading: true };
    case BillingTypes.GET_INVOICE_BY_ORG_SUCCESS:
      return { ...state, loading: false, invoiceItems: action.payload };
    case BillingTypes.GET_INVOICE_BY_ORG_FAILED:
      return { ...state, loading: false };

    case BillingTypes.GET_INVOICE_SERVICE:
      return { ...state, loading: true };
    case BillingTypes.GET_INVOICE_SERVICE_SUCCESS:
      return { ...state, loading: false };
    case BillingTypes.GET_INVOICE_SERVICE_FAILED:
      return { ...state, loading: false };

    case BillingTypes.GET_INVOICE_SERVICE_BILLING:
      return { ...state, loading: true };
    case BillingTypes.GET_INVOICE_SERVICE_BILLING_SUCCESS:
      return { ...state, loading: false };
    case BillingTypes.GET_INVOICE_SERVICE_BILLING_FAILED:
      return { ...state, loading: false };

    case BillingTypes.GET_BILLING_DETAILED:
      return { ...state, loading: true };
    case BillingTypes.GET_BILLING_DETAILED_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case BillingTypes.GET_BILLING_DETAILED_FAILED:
      return { ...state, loading: false };

    case BillingTypes.GET_BILLING_SERVICE:
      return { ...state, loading: true };
    case BillingTypes.GET_BILLING_SERVICE_SUCCESS:
      return {
        ...state,
        loading: false,
        serviceItems: action.payload,
      };
    case BillingTypes.GET_BILLING_SERVICE_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
