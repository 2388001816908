import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Form, Row } from './styles/StyledGeneralData';
import { translator } from '~/presentation/components/i18n';

import { iListConsultant, iValidation } from './interface';

import { iProfessional } from '~/domain/interfaces/models/Professional';
import { Navigator } from '../register/Navigator';
import { schemaGeneralDataApac } from '~/validation/validators/document/CreateClinicalDocValidator';
import SearchSelect from '../UI/searchSelect';
import _ from 'lodash';
import { selectStyles } from '../instantAppointmentModal/styles';
import { useSelector } from 'react-redux';
import { iProfession, iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';
import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { makeReduxSetupProfession } from '~/main/factories/usecases/profession';
import { makeReduxGetAllSpecialty } from '~/main/factories/usecases/specialty/GetAllSpecialtyFactory';

export interface ownProps {
  consultant: iListConsultant[];
  professional: iProfessional;
  next: (data: iValidation) => any;
  state?: iValidation;
  disabled?: boolean;
  hasInitialHealthUnit?: boolean;
}

interface iStateParams {
  professional: string;
  consultant: string;
}

const GeneralData: React.FC<ownProps> = ({
  hasInitialHealthUnit,
  professional,
  next,
  state,
}): JSX.Element => {
  const stateParams = useLocation<iStateParams>().state;

  const { control, errors, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralDataApac),
    defaultValues: {
      ...state,
      state: 'PB',
    },
  });

  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const { results: professions, selected } = useSelector(
    (store: iStore) => store.professions,
  );

  const { selectUser } = useSelector((store: iStore) => store.auth);

  const { results: specialty, loading: loadingSpecialty } = useSelector(
    (store: iStore) => store.specialty,
  );

  const [cities, setCities] = React.useState<GetCitiesByUf.Model>([]);

  const specialties = specialty.map(specialty => ({
    ...specialty,
    id: specialty.specialty?.id,
    name: specialty.specialty?.name,
  }));

  const specialtiesSelected = useMemo(() => {
    return specialties.find(
      specialty => specialty.id === Number(watch('specialty')),
    );
  }, [specialties, watch('specialty')]);

  const generateCitySelectOptions = () => {
    return _.sortBy(
      cities?.flatMap(value => {
        return [
          {
            label: value.nome,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  };

  const cityMultiselectSelected = useMemo(() => {
    return generateCitySelectOptions().find(
      option => option?.value === Number(watch('county') ?? state?.county),
    );
  }, [generateCitySelectOptions, watch('county'), state?.county]);

  const generateProfessionalSelectOptions = useCallback(() => {
    return _.sortBy(
      professional?.results.flatMap(value => {
        return [
          {
            value: value.professional.id,
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [professional]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionalSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionalSelectOptions, watch('professional')]);

  const generateHealthUnitSelectOptions = useCallback(() => {
    return _.sortBy(
      healthUnits?.flatMap(value => {
        return [
          {
            label: `${value.cnes} - ${value.name}`,
            value: value.id,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [healthUnits]);

  const healthUnitSelected = useMemo(() => {
    return generateHealthUnitSelectOptions().find(
      option => option?.value === watch('healthUnit'),
    );
  }, [generateHealthUnitSelectOptions, watch('healthUnit')]);

  const generateStateSelectOptions = () => {
    return [
      {
        label: 'Paraíba',
        value: 'PB',
      },
    ];
  };

  const stateMultiselectSelected = useMemo(() => {
    return generateStateSelectOptions().find(
      option => option?.value === watch('state'),
    );
  }, [generateStateSelectOptions]);

  const isDisabled = useMemo(() => {
    return (
      selectUser.role === 'PRO' &&
      stateParams &&
      Number(stateParams.professional) !== -1 &&
      watch('professional') !== undefined
    );
  }, [selectUser.role, stateParams, watch('professional')]);

  const handleSelectProfession = useCallback(
    (profession: iProfession['results'][0]) => {
      makeReduxSetupProfession().setup({
        professionId: profession.profession.id,
      });

      makeReduxGetAllSpecialty().getAll({
        filter: {
          profession: profession.profession.id,
          hasProfessional: true,
        },
      });
    },
    [],
  );

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  useEffect(() => {
    makeRemoteGetCitiesByUf()
      .getCitiesByUf({ uf: 'PB' })
      .then(res => {
        setCities(res);
      });
  }, []);

  useEffect(() => {
    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
      city: watch('county'),
    });
  }, [watch('county')]);

  useEffect(() => {
    const professionalData = professional?.results.find(
      value => value.professional.id === Number(stateParams?.professional),
    );

    if (professionalData?.profession.id)
      makeReduxSetupProfession().setup({
        professionId: professionalData?.profession.id,
      });
  }, [stateParams, professional]);

  useEffect(() => {
    if (state?.healthUnit && !watch('healthUnit'))
      setValue('healthUnit', state?.healthUnit, {
        shouldValidate: true,
      });
  }, [state]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Row>
          <SearchSelect
            {...(register('state') as any)}
            className="select"
            controlShouldRenderValue
            options={generateStateSelectOptions()}
            value={stateMultiselectSelected}
            onChange={(e: { value: string }) => {
              if (!e) return;
              setValue('state', e?.value);
              setValue('county', '');
              setValue('healthUnit', '');
            }}
            isDisabled
            placeholder="Selecione"
            formatCreateLabel={(label: string) => ({
              label: `Buscar por ${label}`,
            })}
            isValidNewOption={() => false}
            required
            label="Estado"
            error={errors?.state}
            message={errors?.state?.message}
            noOptionsMessage={() => 'Nenhum resultado encontrado'}
          />

          <Controller
            name="county"
            control={control}
            render={field => (
              <SearchSelect
                className="select"
                {...(register('county') as any)}
                options={generateCitySelectOptions()}
                controlShouldRenderValue
                defaultValue={watch('county')}
                placeholder="Selecione"
                formatCreateLabel={(label: string) => `Buscar por ${label}`}
                label="Município"
                error={!!errors?.county}
                message={errors?.county?.message}
                isValidNewOption={() => false}
                noOptionsMessage={() => 'Nenhum resultado encontrado'}
                {...field}
                value={cityMultiselectSelected}
                onChange={option => {
                  field.onChange(String(option.value));
                  setValue('healthUnit', undefined, {
                    shouldValidate: true,
                  });
                }}
                isDisabled={isDisabled && hasInitialHealthUnit}
              />
            )}
          />
        </Row>

        <Controller
          name="healthUnit"
          control={control}
          render={field => (
            <SearchSelect
              {...(register('healthUnit') as any)}
              label={`${translator('Unidade de Saúde')}`}
              placeholder="Selecione"
              options={generateHealthUnitSelectOptions()}
              formatCreateLabel={(label: string) => `Buscar por ${label}`}
              styles={selectStyles(false)}
              isValidNewOption={() => false}
              noOptionsMessage={() => 'Nenhum resultado encontrado'}
              error={!!errors?.healthUnit}
              message={errors?.healthUnit?.message}
              required
              {...field}
              value={healthUnitSelected}
              onChange={option => field.onChange(option.value)}
              isDisabled={isDisabled && hasInitialHealthUnit}
            />
          )}
        />

        <Row>
          <SearchSelect
            options={professions}
            placeholder="Selecione uma profissão"
            label="Profissão"
            width="200px"
            getOptionLabel={option => option.profession.name}
            getOptionValue={option => option.profession.id}
            value={
              professions.find(
                profession => profession.profession.id === selected,
              ) ?? undefined
            }
            onChange={profession => handleSelectProfession(profession)}
            isDisabled={isDisabled}
          />
          <Controller
            name="specialty"
            control={control}
            render={field => (
              <SearchSelect
                {...(register('specialty') as any)}
                isDisabled={loadingSpecialty || isDisabled}
                options={specialties}
                isLoading={loadingSpecialty}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                placeholder="Selecione uma especialidade"
                label="Especialidade"
                width="100%"
                error={!!errors?.specialty}
                message={errors?.specialty?.message}
                {...field}
                required
                value={specialtiesSelected}
                onChange={option => {
                  const orgUnitsFormatted =
                    (selectUser?.orgUnits
                      ?.map(item => item.id)
                      .filter(Boolean) as number[]) ?? [];

                  field.onChange(option.id);
                  makeReduxGetAllProfessionals().getAll({
                    filter: {
                      specialty: option.id,
                      org: selectUser.orgId,
                      unit:
                        selectUser?.role === 'ORG' || selectUser?.role === 'PRO'
                          ? orgUnitsFormatted
                          : undefined,
                    },
                  });
                }}
              />
            )}
          />
          <Controller
            name="professional"
            control={control}
            render={field => {
              console.log('field', field);
              return (
                <SearchSelect
                  placeholder="Selecione um profissional"
                  options={generateProfessionalSelectOptions()}
                  label="Profissional"
                  error={!!errors?.professional}
                  message={errors?.professional?.message}
                  noOptionsMessage={() => 'Nenhum resultado encontrado'}
                  required
                  {...field}
                  value={professionalMultiselectSelected}
                  onChange={option => field.onChange(option?.value)}
                  isDisabled={isDisabled}
                />
              );
            }}
          />
        </Row>
        <Navigator />
      </Form>
    </Container>
  );
};

export default GeneralData;
