import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { UpdateUser as UsecaseRemoteUpdateUser } from '~/domain/usecases/users/remote';
import { UpdateUser as UsecaseReduxUpdateUser } from '~/domain/usecases/users/redux';

import { RemoteUpdateUser } from '~/data/repository/users';
import { ReduxUpdateUser } from '~/data/store/reducer/users/usecases';

/**
 * send request via API.
 */
export const makeRemoteUpdateUser = (): UsecaseRemoteUpdateUser =>
  new RemoteUpdateUser(makeApiUrl('/users'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxUpdateUser = (): UsecaseReduxUpdateUser =>
  new ReduxUpdateUser();
