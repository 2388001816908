import styled from 'styled-components';

interface ownProps {
  value: number;
}

interface ContainerProps {
  active?: boolean;
  width?: string;
  height?: string;
}

export const Container = styled.div<ContainerProps>`
  min-width: 272px;
  min-height: 165px;
  width: ${props => props.width ?? 'initial'};
  height: ${props => props.height ?? 'initial'};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: ${props => (props.active ? '#ffffff' : '#D4DFEC')};
  border: 1px solid #efefef;
  border-radius: 3px;
  padding: ${props => (props.active ? '16px 24px' : '42px 46px')};
`;

export const IconHeader = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => (props.active ? '25px' : '20px')};

  font-weight: bold;
  font-size: ${props => (props.active ? 'inherit' : '14px')};
  line-height: ${props => (props.active ? 'inherit' : '16px')};
  text-align: center;

  color: ${props => (props.active ? 'inherit' : '#004ba5')};
`;

export const Body = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props =>
    props.active ? props.theme.typographyDarkGray : '#004BA5'};
  font-size: 12px;
  .value {
    font-size: 36px;
    color: ${props => props.theme.primaryDark};
    font-weight: bold;
  }
`;

export const Footer = styled.div<ownProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: ${props =>
    props.value >= 0 ? props.theme.statusSucess : props.theme.statusCancel};
  font-size: 12px;

  .text {
    color: ${props => props.theme.typographyDarkGray};
  }
`;
