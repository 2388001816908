import { Dispatch } from 'redux';
import store from '~/data/store';
import { insertAppointment } from '~/data/store/reducer/appointment/actions/insert';

import { iActionInsert } from '~/domain/interfaces/redux/appointment/insert';

import { InsertAppointment } from '~/domain/usecases/appointment/redux';

export class ReduxInsertAppointment implements InsertAppointment {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  insert = (params: InsertAppointment.Params): iActionInsert =>
    this.send(insertAppointment(params));
}
