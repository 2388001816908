import React, { useEffect } from 'react';
import * as datefns from 'date-fns';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import AppointmentHistory from '~/presentation/pages/AppointmentHistory';
import { makeReduxListAppointment } from '../../usecases/appointment/ListAppointmentFactory';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxDataPagination } from '../../usecases/dataPagination/SetDataFactory';

type LocationState = {
  user: any;
};

export const MakeAppointmentHistoryPage: React.FC = () => {
  const { orgId, role, orgUnitId, orgUnits } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );
  const userLogged = useSelector((store: iStore) => store.auth.info);

  const location = useLocation();

  const professionalId = userLogged.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const consultantId = userLogged.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  const organizerId = userLogged.organizers?.find(
    item => item.org?.id === orgId,
  )?.id;

  const dateInit = new Date();
  const scheduleFrom = datefns.format(dateInit, 'yyyy-MM-dd');
  const scheduleTo = datefns.format(datefns.addDays(dateInit, 2), 'yyyy-MM-dd');

  const orgUnitsFormatted =
    (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

  const filter = {
    scheduleTo,
    org: orgId,
  };

  useEffect(() => {
    switch (role) {
      case 'CON':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              org: orgId,
              consultant: consultantId,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;

      case 'PRO':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              ...filter,
              org: orgId,
              orgUnit: orgUnitsFormatted,
              professional: professionalId,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;

      case 'ORG':
        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              ...filter,
              org: orgId,
              orgUnit: orgUnitsFormatted,
              status: ['ENDED', 'CANCELED'],
            },
          },
        });
        break;

      case 'ADM': {
        const locationState = location?.state as LocationState;
        const user = locationState?.user;

        makeReduxListAppointment().list({
          body: {
            orderAttribute: 'scheduled',
            orderType: 'DESC',
            filter: {
              status: ['ENDED', 'CANCELED'],
              ...(user?.role === 'CON' &&
                user?.cod && { consultant: Number(user.cod) }),
              ...(user?.role === 'PRO' &&
                user?.cod && { professional: Number(user.cod) }),
              ...filter,
            },
          },
        });
        break;
      }
      default:
        break;
    }
  });

  useEffect(() => {
    makeReduxDataPagination().set([]);
    // return () => {
    // };
  }, []);

  return <AppointmentHistory />;
};

export default MakeAppointmentHistoryPage;
