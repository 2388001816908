import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ValidateCaptcha as UsecaseRemoteValidateCaptcha } from '~/domain/usecases/externalServices/remote';

import { RemoteValidateCaptcha } from '~/data/repository/externalServices/RemoteValidateCaptcha';

/**
 * send request via API.
 */
export const makeRemoteValidateCaptcha = (): UsecaseRemoteValidateCaptcha =>
  new RemoteValidateCaptcha(makeApiUrl('/external/captcha/'), makeHttpClient());
