import { takeLatest } from 'redux-saga/effects';
import { onCreate, onCreateFailed, onCreateSuccess } from './Create';
import { onGetAllFailed, onGetAllSuccess, onGetAll } from './GetAll';
import { onUpdateFailed, onUpdateSuccess, onUpdate } from './Update';
import { onDelete, onDeleteFailed, onDeleteSuccess } from './Delete';
import { AdministratorTypes } from '~/domain/interfaces/redux/administrator/types';

const tasks = [
  takeLatest(AdministratorTypes.CREATE, onCreate),
  takeLatest(AdministratorTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(AdministratorTypes.CREATE_FAILED, onCreateFailed),

  takeLatest(AdministratorTypes.DELETE, onDelete),
  takeLatest(AdministratorTypes.DELETE_SUCCESS, onDeleteSuccess),
  takeLatest(AdministratorTypes.DELETE_FAILED, onDeleteFailed),

  takeLatest(AdministratorTypes.GETALL, onGetAll),
  takeLatest(AdministratorTypes.GET_SUCCESS, onGetAllSuccess),
  takeLatest(AdministratorTypes.GET_FAILED, onGetAllFailed),

  takeLatest(AdministratorTypes.UPDATE, onUpdate),
  takeLatest(AdministratorTypes.UPDATE_SUCCESS, onUpdateSuccess),
  takeLatest(AdministratorTypes.UPDATE_FAILED, onUpdateFailed),
];

export default tasks;
