import { call, put } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { iActionUpdate } from '~/domain/interfaces/redux/users/update';
import {
  updateSuccess,
  updateFailed,
} from '~/data/store/reducer/users/actions/update';
import { makeRemoteUpdateUser } from '~/main/factories/usecases/users/UpdateUserFactory';
import { makeReduxGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { translator } from '~/presentation/components/i18n';
import { active as activeMessage } from '~/data/store/reducer/message/actions/active';
import { MessageOptions } from '~/domain/interfaces/redux/message';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onUpdateRequest(action: iActionUpdate) {
  const remoteUpdate = makeRemoteUpdateUser();
  const reduxGetOneUser = makeReduxGetOneUser();

  const { id } = action.payload;
  try {
    yield call(remoteUpdate.update, action.payload);

    yield reduxGetOneUser.getOne({ userId: id! });
    yield put(updateSuccess());
  } catch (error) {
    yield put(updateFailed());
  }
}

export function onUpdateSuccess() {
  // AlertMessage({
  //   message: 'Usuário atualizado com sucesso',
  //   type: 'success',
  // });
}

export function onUpdateFailed() {
  // AlertMessage({
  //   message: intl.formatMessage({
  //     id: 'Não foi possível atualizar o usuário. Você digitou os campos corretamente?',
  //   }),
  //   type: 'danger',
  // });
  // activeMessage({
  //   active: MessageOptions.error,
  //   content: intl.formatMessage({
  //     id: 'Não foi possível atualizar o usuário. Você digitou os campos corretamente?',
  //   }),
  // });
}
