import { GetDashboardUnits } from '~/domain/usecases/dashboard/redux';
import {
  iActionGetDashboardUnits,
  iActionGetDashboardUnitsFailed,
  iActionGetDashboardUnitsSuccess,
  DashboardTypes,
} from '~/domain/interfaces/redux/dashboard/getDashboardUnits';

export const getRequest = (
  payload: GetDashboardUnits.Params,
): iActionGetDashboardUnits => ({
  type: DashboardTypes.GET_UNITS,
  payload,
});

export const getSuccess = (
  params: GetDashboardUnits.Model,
): iActionGetDashboardUnitsSuccess => ({
  type: DashboardTypes.GET_UNITS_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetDashboardUnitsFailed => ({
  type: DashboardTypes.GET_UNITS_FAILED,
});
