import styled from 'styled-components';

export const Content = styled.div`
  width: 88px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  left: 0;
  top: 0;
`;

export const Pen = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #d5d8dc;
  }
`;

export const Cancel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: #d5d8dc;
  }
`;
