import styled, { css } from 'styled-components';
import { NewIconCheck } from '~/presentation/base/icons';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-width: 360px;
  max-width: 360px;
  /* max-height: 493px; */
  height: 100%;

  background-color: #fdfdfd;
  border: 1px solid #dedede;
  border-radius: 10px;
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  column-gap: 10px;
  padding: 12px 16px 15px;
  border-bottom: 1px solid #dedede;
  font-size: 18px;
  font-weight: 600;
`;

export const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const UserImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 100%;
`;
// End Header Components

// Start Body Components
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;

  /* border-bottom: 1px solid #DEDEDE; */
`;

interface ConsultInfoContainerProps {
  withBorder?: boolean;
}

export const ConsultInfoContainer = styled.div<ConsultInfoContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 8px;
  /* margin-bottom: 10px; */
  width: 100%;

  padding: 12px 16px 16px;
  border-bottom: ${({ withBorder }) =>
    withBorder ? '1px solid #dedede' : 'none'};
`;

export const ConsultInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ConsultInfoItemGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  /* flex-wrap: wrap; */
`;

export const ConsultInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;

  svg {
    max-height: 14px;
    margin-bottom: 2px;
  }
`;
// End Body Components

// Start Footer Components
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;
// End Footer Components

// Start Text Components
export const TextBold = styled.p`
  font-weight: 500;
  font-size: 14px;
  color: #222529;
`;

export const TextNormal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #222529;
  text-transform: capitalize;
`;

export const TextNormalBlackFour = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #62666c;
`;

export const ContainerSubject = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleSubject = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  color: #222529;
`;

export const ActionButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 6px;
  width: 40px;
  height: 40px;
  aspect-ratio: 1;

  border: 1px solid #b4b7b9;
  background-color: #fdfdfd;
`;

export const ContainerPopover = styled.div`
  background: #fff;
  display: flex;
  gap: 8px;
  height: 48px;
  border: 1px solid #dedede;
  box-shadow: 0px 2px 20px 0px rgba(34, 37, 41, 0.2);
  align-items: center;
  border-radius: 4px;
  padding: 16px;
  cursor: pointer;
`;

export const Title = styled.span`
  color: #222529;
  font-size: 14px;
  font-weight: 500;
`;

export const Options = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface PriorityProps {
  priority?: 'EMERGENCY' | 'NORMAL' | 'LOW';
}

export const priorityColorStyle = (
  priority: 'EMERGENCY' | 'NORMAL' | 'LOW',
) => {
  switch (priority) {
    case 'EMERGENCY':
      return css`
        svg path {
          fill: #dd0404;
        }
      `;

    case 'NORMAL':
      return css`
        svg path {
          fill: #fe632f;
        }
      `;

    case 'LOW':
      return css`
        svg path {
          fill: #458108;
        }
      `;

    default:
      return css`
        svg path {
          fill: #458108;
        }
      `;
  }
};

export const Priority = styled.div<PriorityProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ priority }) =>
    priorityColorStyle(priority as 'EMERGENCY' | 'NORMAL' | 'LOW')}
`;

export const EmptyCard = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #818385;
`;

export const CheckIcon = styled(NewIconCheck)`
  path {
    fill: #458108;
  }
`;
