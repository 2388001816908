import io, { Socket } from 'socket.io-client';
import { makeRemoteRefuseAppointmentOnCall } from '~/main/factories/usecases/onCall/RefuseAppointmentOnCallFactory';
import {
  SocketClient,
  SocketRequest,
  SocketResponse,
} from '~/data/protocols/socket';

import { SocketEvents } from '~/domain/interfaces/socketEvents';

import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { makeRemoteStartAppointmentOnCall } from '~/main/factories/usecases/onCall/StartAppointmentOnCallFactory';
import { makeRemoteJoinAppointmentOnCall } from '~/main/factories/usecases/onCall/JoinAppointmentOnCallFactory';
import { closeModal } from '~/utils/closeModal';
import { History } from '~/main/routes';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetDuties } from '~/main/factories/usecases/duty/GetDuties';
import { makeReduxGetProfessionalOnDuty } from '~/main/factories/usecases/duty/GetProfessionalsOnDuty';
import storeDev from '~/data/store';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { makeRemoteJoinOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/JoinOnCallSpecialistFactory';
import { makeRemoteRefuseOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/RefuseOnCallSpecialistFactory';
import { makeRemoteJoinOnCallRequester } from '~/main/factories/usecases/onCallRequester/JoinOnCallRequesterFactory';
import { makeRemoteRefuseOnCallRequester } from '~/main/factories/usecases/onCallRequester/RefuseOnCallRequesterFactory';
import { makeReduxListOnCallRequest } from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { ChatEvents } from './ChatEvents';
import { HandlerEvents } from './HandlerEvents';

class SocketIO implements SocketClient {
  private static Instance: SocketIO;

  private static Socket: typeof Socket;

  public static getInstance(): SocketIO {
    if (!SocketIO.Instance) {
      SocketIO.Instance = new SocketIO();
    }

    return SocketIO.Instance;
  }

  public getSocket = (): typeof Socket => {
    return SocketIO.Socket;
  };

  /**
   * connect to the server.
   */
  public connect = (token: string): void => {
    console.log('>>> Connect token: ', token);
    SocketIO.Socket = io(window.config.connection.backendUrl, {
      query: {
        token,
      },
    });

    this.listening();
  };

  /**
   * Disconnect to the server.
   */
  public disconnect = (): void => {
    SocketIO.Socket.disconnect();
  };

  /**
   * Emit event.
   */
  public emit = (data: SocketRequest): Promise<SocketResponse> => {
    console.log('>>> Emit event socket: ', data);
    return new Promise((resolve, reject) => {
      SocketIO.Socket.emit(
        data.event,
        data.body,
        (err: any, dataResponse: any) => {
          console.log('error: ', err);
          console.log('data: ', dataResponse);
          if (dataResponse) resolve({ body: dataResponse });
          if (err) reject(err);
        },
      );
    });
  };

  private listening = (): void => {
    console.log('>>> Listening socket: ', SocketIO.Socket);

    SocketIO.Socket.on('connect', function (socket: any) {
      console.log('>>> User socket connected');
    });

    SocketIO.Socket.on('connect_error', (error: any) => {
      console.log('>>> Error connect Socket', error);
    });

    SocketIO.Socket.on('error', (error: any) => {
      console.log('>>> Error Socket', error);
    });

    SocketIO.Socket.on('notification', (data: SocketEvents) => {
      console.log('>>> Receive: ', data);

      HandlerEvents(data);
    });

    SocketIO.Socket.on('ONCALL_REQUEST', () => {
      console.log('>>> ONCALL_REQUEST', window.location.pathname);

      const path = window.location.pathname;
      const professional = getProfessionalInfo();

      makeReduxListOnCallRequest().list({
        body: {
          dataControl: {
            limit: 9999,
          },
          filters: {
            requester: path === '/duty/me' ? professional?.id : undefined,
            onCall:
              path === '/duty/me' ? undefined : Number(path.split('/').pop()),
          },
        },
      });
    });

    SocketIO.Socket.on('ONCALL_REQUESTER_JOINED', () => {
      console.log('>>> ONCALL_REQUESTER_JOINED');
    });

    SocketIO.Socket.on('ONCALL_SPECIALIST_STATUS', (data: any) => {
      console.log('>>> ONCALL_SPECIALIST_STATUS: ', data);
      /*  makeReduxGetProfessionalOnDuty().get({
        onCallId: data.onCall?.id,
      });

      const store: iStore = storeDev.getState();
      const auth = store.auth.info;
      const { orgUnitId } = store.auth.selectUser;
      const professional = auth.professionals?.find(
        item => item.orgUnit.id === orgUnitId,
      );

      if (
        window.location.pathname === '/conf' &&
        data.specialist?.status === 'PAUSED' &&
        data.specialist?.professionalId === Number(professional?.id)
      ) {
        History.getHistory().replace(
          `/duty/${data.onCall?.descr?.toLocaleLowerCase()}`,
          {
            specialty: data.onCall?.descr,
            id: data.onCall?.id,
            leaveConf: true,
          },
        );

        AlertMessage({
          type: 'warning',
          message: 'O solicitante recusou a interconsulta.',
        });
      } */
    });

    SocketIO.Socket.on('ONCALL_REQUESTER_STATUS', (data: any) => {
      console.log('>>> ONCALL_REQUESTER_STATUS: ', data);
      /* makeReduxGetDuties().get({
        onCallId: data.onCall?.id,
      }); */

      /* Verificar em reunião */
      /* if (data.requester?.status === 'DISCONNECTED') {
        const message = useSelector((state: iStore) => state.message);
        if (
          message.active === 'newAvailableInterconsult' &&
          message.data?.requester === data.requester?.professionalId
        )
          closeModal();
      } */
    });

    SocketIO.Socket.on('ONCALL_SPECIALIST_CALL', (data: any) => {
      console.log('>>> ONCALL_SPECIALIST_CALL:', data);
      makeReduxActiveMessage().active({
        active: MessageOptions.newAvailableInterconsult,
        actionOk: () => {
          makeRemoteJoinOnCallSpecialist()
            .join({
              onCallId: data.onCall?.id,
              specialistId: data.specialist?.professionalId,
              requestId: data.request?.id,
            })
            .then(response => {
              History.getHistory().push('/conf', {
                appointmentId: response.appointmentId,
                onCall: {
                  sessionName: response.sessionName,
                  id: data.onCall?.id,
                  name: data.onCall?.descr,
                  specialist: data.specialist?.professionalId,
                },
              });
            })
            .catch(err => {
              console.log('>>> Error Join Specialist: ', err);
              makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                available: false,
              });
            });
          closeModal();
        },
        actionCancel: () => {
          makeRemoteRefuseOnCallSpecialist()
            .refuse({
              onCallId: data.onCall?.id,
              specialistId: data.specialist?.professionalId,
              requestId: data.request?.id,
            })
            .catch(() => {
              console.log('>>> Error Refuse Specialist: ');
            });
          closeModal();
        },
        data: {
          type: 'SPECIALIST',
          specialist: `${data.specialist?.firstName} ${data.specialist?.lastName}`,
          requester: data.requester?.professionalId,
          onCall: data.onCall,
          requestId: data?.request?.id,
        },
      });
    });

    SocketIO.Socket.on('ONCALL_REQUESTER_CALL', (data: any) => {
      console.log('>>> ONCALL_REQUESTER_CALL: ', data);
      makeReduxActiveMessage().active({
        active: MessageOptions.newAvailableInterconsult,
        actionOk: () => {
          makeRemoteJoinOnCallRequester()
            .join({
              onCallId: data.onCall?.id,
              requesterId: data.requester?.professionalId,
              requestId: data.request?.id,
            })
            .then(response => {
              History.getHistory().push('/conf', {
                appointmentId: response.appointmentId,
                onCall: {
                  sessionName: response.sessionName,
                  id: data.onCall?.id,
                  requester: data.requester?.professionalId,
                },
                healthUnit: data?.healthUnit,
              });
            })
            .catch(err => {
              console.log('>>> Error Join Requester: ', err);
            });
          closeModal();
        },
        actionCancel: () => {
          makeRemoteRefuseOnCallRequester().refuse({
            onCallId: data.onCall?.id,
            requesterId: data.requester?.professionalId,
            requestId: data.request?.id,
          });
          closeModal();
        },
        data: {
          type: 'REQUESTER',
          specialist: `${data.specialist?.firstName} ${data.specialist?.lastName}`,
          requester: data.requester?.professionalId,
          onCall: data.onCall,
          requestId: data?.request?.id,
        },
      });
    });

    SocketIO.Socket.on('ONCALL_PROFESISONAL_JOINED', (data: any) => {
      console.log('ONCALL_PROFESISONAL_JOINED: ', data);
    });

    SocketIO.Socket.on('ONCALL_REQUESTER_JOINED', (data: any) => {
      console.log('ONCALL_REQUESTER_JOINED: ', data);
    });

    SocketIO.Socket.on('chatMessage', (data: SocketEvents) => {
      console.log('>>> Receive: ', data);
      ChatEvents(data);
      // HandlerEvents(data);
    });
  };
}

export default SocketIO.getInstance();
