import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ListDiagnosisReport as UsecaseRemoteListDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import { ListDiagnosisReport as UsecaseReduxListDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemoteListDiagnosisReport } from '~/data/repository/diagnosisReport';
import { ReduxListDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemoteListDiagnosisReport =
  (): UsecaseRemoteListDiagnosisReport =>
    new RemoteListDiagnosisReport(
      makeApiUrl('/reports/list'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxListDiagnosisReport =
  (): UsecaseReduxListDiagnosisReport => new ReduxListDiagnosisReport();
