import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { DocumentView } from '~/presentation/components/specialPrescription';

interface iStateParams {
  docId: string;
  from: string;
}
const DocumentViewPage: React.FC = () => {
  const { search } = useLocation<iStateParams>();
  const query = new URLSearchParams(search);
  const secondaryDocId = query.get('docId') || '';

  const docId = useLocation<iStateParams>().state?.docId;
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  useEffect(() => {
    makeReduxGetAllClinicalDocs().getAll({
      filter: {
        org: orgId,
        enabled: true,
      },
    });
  });

  return <DocumentView id={docId || secondaryDocId} />;
};

export default DocumentViewPage;
