import { GetAllHealthUnits } from '~/domain/usecases/healthUnits/redux';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  HealthUnitsTypes,
} from '~/domain/interfaces/redux/healthUnits/getAll';

export const getAllRequest = (
  payload: GetAllHealthUnits.Params,
): iActionGetAll => ({
  type: HealthUnitsTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllHealthUnits.Model,
): iActionGetAllSuccess => ({
  type: HealthUnitsTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: HealthUnitsTypes.GETALL_FAILED,
});
