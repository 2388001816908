import { UpdateUserAvatar } from '~/domain/usecases/users/redux';
import {
  iActionUpdateUserAvatar,
  iActionUpdateUserAvatarFailed,
  UsersTypes,
  iActionUpdateUserAvatarSuccess,
} from '~/domain/interfaces/redux/users/updateUserAvatar';

export const updateRequest = (
  payload: UpdateUserAvatar.Params,
): iActionUpdateUserAvatar => ({
  type: UsersTypes.UPDATE_AVATAR,
  payload,
});

export const updateSuccess = (): iActionUpdateUserAvatarSuccess => ({
  type: UsersTypes.UPDATE_AVATAR_SUCCESS,
});

export const updateFailed = (): iActionUpdateUserAvatarFailed => ({
  type: UsersTypes.UPDATE_AVATAR_FAILED,
});
