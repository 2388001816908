import { ListShifts as UsecaseReduxListShifts } from '~/domain/usecases/shifts/redux/ListShifts';
import { ListShifts as UsecaseRemoteListShifts } from '~/domain/usecases/shifts/remote';

import { makeApiUrl, makeHttpClient } from '../../http';

import { RemoteListShifts } from '~/data/repository/shifts';
import { ReduxListShifts } from '~/data/store/reducer/shifts/usecases';

export const makeRemoteListShifts = (): UsecaseRemoteListShifts =>
  new RemoteListShifts(
    makeApiUrl('/oncall/{onCallId}/shifts/list'),
    makeHttpClient(),
  );

export const makeReduxListShifts = (): UsecaseReduxListShifts =>
  new ReduxListShifts();
