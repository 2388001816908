import { GetBillingDetailed } from '~/domain/usecases/billing/redux';
import {
  BillingTypes,
  iActionGetBillingDetailed,
  iActionGetBillingDetailedFailed,
  iActionGetBillingDetailedSuccess,
} from '~/domain/interfaces/redux/billing/getBillingDetailed';

export const getRequest = (
  payload: GetBillingDetailed.Params,
): iActionGetBillingDetailed => ({
  type: BillingTypes.GET_BILLING_DETAILED,
  payload,
});

export const getSuccess = (
  params: GetBillingDetailed.Model,
): iActionGetBillingDetailedSuccess => ({
  type: BillingTypes.GET_BILLING_DETAILED_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetBillingDetailedFailed => ({
  type: BillingTypes.GET_BILLING_DETAILED_FAILED,
});
