import { GetChat } from '~/domain/usecases/chat/remote';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  NotFound,
} from '~/domain/errors';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import '~/infra/global/variables';

export class RemoteGetChat implements GetChat {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetChat.Model>;

  constructor(url: string, httClient: HttpClient<GetChat.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (params: GetChat.Params): Promise<GetChat.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{chatId}', `${params.id}`),
      method: 'get',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
