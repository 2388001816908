import { iSpecialty } from '~/domain/interfaces/models/Specialty';
import { SpecialtyTypes } from '~/domain/interfaces/redux/specialty/types';
import { SpecialtyActions } from './actions';
import { AuthTypes } from '~/domain/interfaces/redux/auth/types';

export const initialState: iSpecialty = {
  loading: false,
  totalItems: 0,
  selected: 0,
  results: [{}],
};

const reducer = (
  state = initialState,
  action: SpecialtyActions,
): iSpecialty => {
  switch (action.type) {
    case SpecialtyTypes.CREATE:
      return state;
    case SpecialtyTypes.GETALL:
      return state;
    case SpecialtyTypes.GETALL_SUCCESS:
      return { ...state, ...action.payload };
    case SpecialtyTypes.GET:
      return state;

    case SpecialtyTypes.UPDATE:
      return { ...state, loading: true };
    case SpecialtyTypes.UPDATE_SUCCESS:
      return { ...state, loading: false };
    case SpecialtyTypes.UPDATE_FAILED:
      return { ...state, loading: false };
    case AuthTypes.LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
