import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TitleDoc = styled.div`
  width: 100%;
  display: flex;
  height: 46px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  background: #f4f7fe;
  color: #004ba5;
`;

export const Body = styled.div`
  width: 100%;
`;

export const ContainerButton = styled.div`
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding: 0px 15px 0px 15px;
  margin-bottom: 8px;
`;

export const Phases = styled.div`
  width: 100%;
  padding: 15px;
`;
