import { GetAllConsultant } from '~/domain/usecases/consultant/redux';
import {
  iActionGetAll,
  iActionGetAllSuccess,
  iActionGetAllFailed,
  ConsultantTypes,
} from '~/domain/interfaces/redux/Consultant/getAll';

export const getAllRequest = (
  payload: GetAllConsultant.Params,
): iActionGetAll => ({
  type: ConsultantTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllConsultant.Model,
): iActionGetAllSuccess => ({
  type: ConsultantTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: ConsultantTypes.GETALL_FAILED,
});
