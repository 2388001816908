import React, { useState, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { IconFilter, IconSearch } from '~/presentation/base/icons';
import { makeReduxGetAllProfessionals } from '~/main/factories/usecases/professional/GetAllProfessionalFactory';
import { iStore } from '~/domain/interfaces/models';
import { Container } from './styles/StyledSearchList';
import Dropdown from '../drop-down';
import { translator } from '../i18n';

interface SearchProps {
  name?: string;
  children: JSX.Element[] | JSX.Element;
  icon?: JSX.Element;
}

const SearchListProfessional: React.FC<SearchProps> = ({ children }) => {
  const [professional, setProfessional] = useState('');

  const { orgId, orgUnitId, role, orgUnits } = useSelector(
    (store: iStore) => store.auth.selectUser,
  );

  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const orgUnitsFormatted =
        (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

      makeReduxGetAllProfessionals().getAll({
        pageSize: 9999,
        filter: {
          org: orgId,
          unit:
            role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
          name: professional,
          situation: ['ACTIVE', '2FAWAIT'],
        },
      });
    }
  };

  return (
    <Container onSubmit={() => handleSubmit}>
      <IconSearch id="btn_searchFilter" />
      <input
        id="input_search"
        placeholder={`${translator('Buscar por...')}`}
        value={professional}
        onChange={e => setProfessional(e.target.value)}
        onKeyPressCapture={handleSubmit}
      />
      <Dropdown label="Filtros" icon={<IconFilter />}>
        {children}
      </Dropdown>
    </Container>
  );
};
export default SearchListProfessional;
