import { GetInvoiceService } from '~/domain/usecases/billing/redux';
import {
  BillingTypes,
  iActionGetInvoiceService,
  iActionGetInvoiceServiceFailed,
  iActionGetInvoiceServiceSuccess,
} from '~/domain/interfaces/redux/billing/getInvoiceService';

export const getInvoiceServiceRequest = (
  payload: GetInvoiceService.Params,
): iActionGetInvoiceService => ({
  type: BillingTypes.GET_INVOICE_SERVICE,
  payload,
});

export const getInvoiceServiceSuccess = (
  params: GetInvoiceService.Model,
): iActionGetInvoiceServiceSuccess => ({
  type: BillingTypes.GET_INVOICE_SERVICE_SUCCESS,
  payload: params,
});

export const getInvoiceServiceFailed = (): iActionGetInvoiceServiceFailed => ({
  type: BillingTypes.GET_INVOICE_SERVICE_FAILED,
});
