import React from 'react';
import {
  Container,
  PatientePendings,
  Row,
  Title,
  Checkbox,
  TextAreaContainer,
  ListFilesContainer,
  WrongFileContainer,
  FileInfo,
  Action,
  Actions,
  WrongFileInfoContainer,
  JustifyContent,
  Body,
  Footer,
} from './styles';
import { Button, Icon } from '@wisecare/design-system-web';
import { ListDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
import { makeRemotePenDenCyDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/PendencyDiagnosisReportFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { makeApiUrl } from '~/main/factories/http';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';

interface iWrongFile {
  reportId?: number;
  file: ListDiagnosisReport.Model['records'][0]['files'][0];
  filesChecked: { id: number; justification: string }[];
  setFilesChecked: (value: { id: number; justification: string }[]) => void;
}

const WrongFile: React.FC<iWrongFile> = ({
  reportId,
  file,
  filesChecked,
  setFilesChecked,
}) => {
  const { token } = useSelector((store: iStore) => store.auth.access);

  return (
    <WrongFileContainer>
      <WrongFileInfoContainer>
        <input
          type="checkbox"
          checked={filesChecked.findIndex(item => item.id === file.id) > -1}
          onChange={e => {
            if (e.target.checked) {
              setFilesChecked([
                ...filesChecked,
                { id: file.id, justification: '' },
              ]);
            } else {
              setFilesChecked(filesChecked.filter(item => item.id !== file.id));
            }
          }}
        />
        <FileInfo>
          <div className="name">{file.filename}</div>
          <div className="type">{file.examType || 'Não informado'}</div>
        </FileInfo>
        <Actions>
          <Action
            onClick={() => {
              reportId &&
                axios
                  .get(
                    makeApiUrl(
                      `/reports/${reportId}/files/${file.id}/DOWNLOAD`,
                    ),
                    {
                      responseType: 'blob',
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    },
                  )
                  .then(response => {
                    const file = new Blob([response.data], {
                      type: response.data.type,
                    });
                    const fileURL = URL.createObjectURL(file);

                    // Abre o arquivo em uma nova guia
                    window.open(fileURL);
                  })
                  .catch(error => {
                    AlertMessage({
                      message: 'Erro ao abrir arquivo',
                      type: 'danger',
                    });
                  });
            }}
          >
            <Icon name="visibility" size="small" color="black-1" />
          </Action>
        </Actions>
      </WrongFileInfoContainer>
      {filesChecked.findIndex(item => item.id === file.id) > -1 && (
        <JustifyContent>
          <textarea
            placeholder="Escreva aqui"
            onChange={event => {
              setFilesChecked(
                filesChecked.map(item =>
                  item.id === file.id
                    ? { id: item.id, justification: event.target.value }
                    : item,
                ),
              );
            }}
          />
        </JustifyContent>
      )}
    </WrongFileContainer>
  );
};

interface ownProps {
  id?: number;
  setOpenPendings: (value: boolean) => void;
  files?: ListDiagnosisReport.Model['records'][0]['files'];
}

const DeclarePendings: React.FC<ownProps> = ({
  id,
  setOpenPendings,
  files,
}) => {
  const [problemContent, setProblemContent] = React.useState(false);
  const [problemDescription, setProblemDescription] = React.useState(false);
  const [justification, setJustification] = React.useState('');
  const [filesChecked, setFilesChecked] = React.useState<
    { id: number; justification: string }[]
  >([]);

  return (
    <Container>
      <Body>
        <Row>
          <Title>
            <strong>Motivos para a devolução da solicitação do laudo</strong>
            <div className="descr">
              Informe para o solicitante por que o laudo está sendo devolvido
              para ele, podendo selecionar sobre as informações do paciente e
              arquivos enviados.
            </div>
          </Title>
        </Row>
        <Row>
          <Title withBorder>
            <strong>Informações do paciente</strong>
            <div className="descr">
              Selecione um ou mais motivos e escreva sua justificativa para o
              solicitante.
            </div>
          </Title>
          <PatientePendings>
            <div className="checkbox-row">
              <Checkbox>
                <input
                  type="checkbox"
                  checked={problemContent}
                  onChange={event => {
                    setProblemContent(event.target.checked);
                  }}
                />
                Ausência ou inconsistência nos dados do paciente
              </Checkbox>
              <Checkbox>
                <input
                  type="checkbox"
                  checked={problemDescription}
                  onChange={event =>
                    setProblemDescription(event.target.checked)
                  }
                />
                Problemas no subjetivo e objetivo
              </Checkbox>
            </div>
            <div className="justify-row">
              <TextAreaContainer>
                <div className="label">Justificativa</div>
                <textarea
                  placeholder="Escreva aqui"
                  onChange={event => setJustification(event.target.value)}
                />
              </TextAreaContainer>
            </div>
          </PatientePendings>
        </Row>

        <Row>
          <Title withBorder>
            <strong>Arquivos</strong>
            <div className="descr">
              Selecione o arquivo que você deseja e escreva o motivo da
              devolução.
            </div>
          </Title>
          <ListFilesContainer>
            <div className="side">
              {files?.map(
                (item, index) =>
                  index % 2 === 0 && (
                    <WrongFile
                      reportId={id}
                      filesChecked={filesChecked}
                      setFilesChecked={setFilesChecked}
                      file={item}
                    />
                  ),
              )}
            </div>
            <div className="side">
              {files?.map(
                (item, index) =>
                  index % 2 !== 0 && (
                    <WrongFile
                      reportId={id}
                      filesChecked={filesChecked}
                      setFilesChecked={setFilesChecked}
                      file={item}
                    />
                  ),
              )}
            </div>
          </ListFilesContainer>
        </Row>
      </Body>
      <Footer>
        <Button
          size="sm"
          variant="outlinedDestructive"
          onClick={() => setOpenPendings(false)}
        >
          Cancelar
        </Button>

        <Button
          size="sm"
          variant="primary"
          onClick={() => {
            console.log('>>>submmit', filesChecked);
            id &&
              makeRemotePenDenCyDiagnosisReport()
                .pendency({
                  id: id,
                  body: {
                    problemCon: problemContent,
                    problemDescr: problemDescription,
                    justification,
                    files: filesChecked.map(item => ({
                      id: item.id,
                      justification: item.justification,
                    })),
                  },
                })
                .then(() => {
                  AlertMessage({
                    message: 'Pendência declarada com sucesso!',
                    type: 'success',
                  });
                  setOpenPendings(false);
                });
          }}
        >
          Enviar
        </Button>
      </Footer>
    </Container>
  );
};

export default DeclarePendings;
