import { ProfessionList } from '~/domain/usecases/profession/redux';
import {
  iActionProfessionList,
  iActionProfessionListFailed,
  iActionProfessionListSuccess,
  ProfessionTypes,
} from '~/domain/interfaces/redux/profession/professionList';

export const professionListRequest = (
  payload: ProfessionList.Params,
): iActionProfessionList => ({
  type: ProfessionTypes.PROFESSION_LIST,
  payload,
});

export const professionListSuccess = (
  params: ProfessionList.Model,
): iActionProfessionListSuccess => ({
  type: ProfessionTypes.PROFESSION_LIST_SUCCESS,
  payload: params,
});

export const professionListFailed = (): iActionProfessionListFailed => ({
  type: ProfessionTypes.PROFESSION_LIST_FAILED,
});
