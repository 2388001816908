import { call, put, select } from 'redux-saga/effects';
import { iActionGetAll } from '~/domain/interfaces/redux/administrator/getAll';
import { GetAllAdministrator } from '~/domain/usecases/administrator/redux/GetAllAdministrator';
import { makeRemoteGetAllAdministrator } from '~/main/factories/usecases/administrator/GetAllAdministratorFactory';

import {
  getAllFailed,
  getAllSuccess,
} from '~/data/store/reducer/administrator/actions/getAll';

export function* onGetAll(action: iActionGetAll) {
  const remoteGetAllAdministrator = makeRemoteGetAllAdministrator();

  try {
    const response: GetAllAdministrator.Model = yield call(
      remoteGetAllAdministrator.getAll,
      action.payload,
    );

    yield put(getAllSuccess(response));
  } catch (e) {
    console.error('error: ', e);

    yield put(getAllFailed());
  }
}

export function* onGetAllSuccess() {
  yield 10;
}

export function* onGetAllFailed() {
  yield 10;
}
