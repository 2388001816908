import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const SignedDoc = styled.div`
  width: 100%;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f7fe;
  border-radius: 3px;
  gap: 5px;
  color: #1c1413;
  font-weight: bold;
`;

export const ActionsUser = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 30px 15px 0px 15px;

  #button_exclude {
    background: #ffffff;
    border: 2px solid #ec5c52;
    color: #ec5c52;
    :hover {
      background: #ec5c52;
      color: white;
      transition: opacity 0.2s;
    }
  }
`;

export const DocumentView = styled.div`
  width: 100%;
  margin-top: 15px;
  max-height: 725px;
`;
