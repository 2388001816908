import { iActionUpdateBySign } from '~/domain/interfaces/redux/clinicalDocs/updateBySign';

export function* onUpdateSign(action: iActionUpdateBySign): Generator {
  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSignSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onUpdateSignFailed() {}
