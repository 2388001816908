/* eslint-disable react/react-in-jsx-scope */
import { useSelector } from 'react-redux';
import Auditoria from '~/presentation/components/ListAudit';
import { HeaderSimples } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';
import { Menu } from '~/presentation/components/menu';

import { iStore } from '~/domain/interfaces/models';
import { V4hSpinIcon } from '~/presentation/base/icons';
import { Spin } from '~/presentation/components/spin/V4hSpin/styles/styled';
import { TablePagination } from '~/presentation/components/tablePagination';
import { Body, Container, LockScroll, Wrapper } from './styles';

const AuditRecordPage: React.FC = () => {
  const { data: audit, loading } = useSelector(
    (store: iStore) => store.auditRecord,
  );
  const { dataPagination } = useSelector(
    (store: iStore) => store.dataPagination,
  );
  const { records, metadata } = audit;

  return (
    <Container>
      <LockScroll>
        <Menu />
        <HeaderSimples title={`${translator('Auditoria de ações')}`} />
      </LockScroll>

      <Body>
        <Wrapper>
          {metadata?.total > 0 && metadata?.total !== undefined && (
            <TablePagination
              totalData={metadata?.total}
              data={records}
              itemPerPage={10}
            />
          )}
          <div>
            {/* <SearchListClinicalDocs className="input__search-actions">
              <FilterClinicalDocs />
            </SearchListClinicalDocs> */}
          </div>
        </Wrapper>
        {loading ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spin src={V4hSpinIcon} size="20" />
          </div>
        ) : (
          <Auditoria dataList={dataPagination ?? []} />
        )}
      </Body>
    </Container>
  );
};

export default AuditRecordPage;
