import { GetOneUser } from '~/domain/usecases/users/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteGetOneUser implements GetOneUser {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetOneUser.Model>;

  constructor(url: string, httClient: HttpClient<GetOneUser.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  getOne = async (params: GetOneUser.Params): Promise<GetOneUser.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.userId}`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
