import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  color: #b7b7b7;
  flex-direction: column;
  p {
    font-weight: bold;
    font-size: 36px;
  }
  text {
    max-width: 300px;
    text-align: center;
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 55px;
  width: 350px;
  height: 350px;
`;

export const Message = styled.div`
  display: flex;
`;
