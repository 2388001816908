import { UpdateDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';

import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  BadGateway,
  BadRequestError,
  Forbidden,
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteUpdateDiagnosisReport implements UpdateDiagnosisReport {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateDiagnosisReport.Model>;

  constructor(url: string, httClient: HttpClient<UpdateDiagnosisReport.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (
    params: UpdateDiagnosisReport.Params,
  ): Promise<UpdateDiagnosisReport.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.query.id}`,
      method: 'patch',
      body: params.body,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
      case HttpStatusCode.accepted:
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.unauthorized:
        throw new Forbidden();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
