import React, { useState } from 'react';

import { AppointmentDetail } from '~/presentation/roles/professional/pages/appointment/interface';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import {
  Body,
  Container,
  Content,
  Header,
  Message,
  Name,
} from './styles/StyledWaitingMessage';

import { Button } from '../../UI';

import { ownProps } from '../interface';
import { translator } from '../../i18n';

const WaitingMessage: React.FC<ownProps> = ({ message }) => {
  const [infos, setInfos] = useState<AppointmentDetail>();

  const msgName = MessageOptions.userWaitingRoomMessage;

  const { active, actionCancel } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>{translator('Aguarde')}</span>
            </Header>
            <Content>
              <Name>{infos && infos.user}</Name>
              <Message>
                <span>
                  {translator('será atendido(a) em alguns instantes')}
                </span>
              </Message>
              <p>
                {translator(
                  'O atendimento será iniciado assim que o médico e paciente estiverem na sala.',
                )}
              </p>
            </Content>
            <Button
              rounded
              variant="secundary"
              size="large"
              onClick={actionCancel}
              autoFocus
            >
              {translator('Sair')}
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default WaitingMessage;
