import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.div`
  width: 100%;
  textarea {
    min-width: 596px;
    min-height: 252px;
    resize: none;
    padding: 10px;
    border: 1px solid #c7cbd1;
    box-sizing: border-box;
    border-radius: 3px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    color: #444a51;
    padding-bottom: 10px;
  }
`;
