import { takeLatest } from 'redux-saga/effects';

import { onGetACL, onGetACLFailed, onGetACLSuccess } from './GetACL';
import { PermissionTypes } from '~/domain/interfaces/redux/permissions/types';

const tasks = [
  takeLatest(PermissionTypes.GET_ACL, onGetACL),
  takeLatest(PermissionTypes.GET_ACL_SUCCESS, onGetACLSuccess),
  takeLatest(PermissionTypes.GET_ACL_FAILED, onGetACLFailed),
];

export default tasks;
