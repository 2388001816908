import React, { useEffect, useState } from 'react';
import IconPerson from '~/presentation/base/images/person.png';
import { Icon } from '../../icon';

import { Container, AvatarIcon } from './styles/StyledAvatar';

import { iAvatar } from './interface';

const Avatar: React.FC<iAvatar> = ({ src, name, variant, size }) => {
  const [error, setError] = useState(false);

  const fullName = name?.split(' ');
  let initials = '';

  try {
    if (fullName !== undefined) {
      initials += fullName[0][0].toUpperCase();
      initials += fullName[1][0].toUpperCase();
    }
  } catch {
    console.error();
  }

  useEffect(() => {
    setError(false);
  }, [src]);

  if (!error && !name && !src) {
    return (
      <AvatarIcon alt="avatar" src={IconPerson} variant={variant} size={size} />
    );
  }

  if (!error && src) {
    return (
      <AvatarIcon
        alt="avatar"
        src={src}
        variant={variant}
        size={size}
        onError={() => setError(true)}
      />
    );
  }

  return (
    <Container variant={variant} size={size}>
      {initials}
    </Container>
  );
};

export default Avatar;
