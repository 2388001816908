import { OnCallTypes } from '~/domain/interfaces/redux/onCall/types';
import { active as activeMessage } from '~/data/store/reducer/message/actions/active';
import { MessageOptions } from '~/domain/interfaces/redux/message/types';
import { History } from '~/main/routes';

/**
 * Middleware to handler the user message.
 * @param action action received by redux.
 * @param next function to pass the action.
 */
export const OnCallNotifications = (
  action: any,
  next: any,
  closeNotification: any,
) => {
  const { type } = action;

  switch (type) {
    case OnCallTypes.STOP_ONCALL:
      return next(
        activeMessage({
          active: MessageOptions.appointmentSOAP,
          actionOk: () => {
            next(closeNotification);
            next(action);
          },
          actionCancel: () => {
            next(closeNotification);
          },
          data: {
            type: action.payload?.type,
            id: action.payload?.id,
            requester: Number(action.payload?.requester),
            specialist: Number(action.payload?.specialist),
            name: action.payload?.name,
          },
        }),
      );
    default:
      break;
  }
  return next(action);
};
