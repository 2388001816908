import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

interface FormattedDateTime {
  date: string;
  hour: string;
}

export function formatISODateTimeLaudo(timestamp?: string): FormattedDateTime {
  if (!timestamp) return { date: '-', hour: '-' };
  const date = new Date(timestamp);

  const formattedDate = format(date, 'dd/MM/yyyy', { locale: ptBR });
  const formattedHour = format(date, 'HH:mm', { locale: ptBR });

  return {
    date: formattedDate,
    hour: formattedHour,
  };
}

import { differenceInYears, differenceInMonths } from 'date-fns';

export function calculateAgeLaudo(birthdate: string): string {
  if (!birthdate) return '-';

  const birthDate = new Date(birthdate);
  const now = new Date();

  const years = differenceInYears(now, birthDate);
  const months = differenceInMonths(now, birthDate) % 12;

  const formattedDate = format(birthDate, 'dd/MM/yyyy', { locale: ptBR });

  return `${formattedDate} (${years} anos e ${months} meses)`;
}
