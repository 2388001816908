import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 530px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #7a7a7a;
  border-bottom: 1px solid #dedede;
  padding: 16px 24px;

  h2 {
    color: #222529; // --Text-Primary-Default
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  span {
    font-size: 1.3em;
    font-weight: bold;
    color: #ec5c52;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

interface RadioButtonBoxProps {
  isSelected?: boolean;
}

export const RadioButtonBox = styled.div<RadioButtonBoxProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: ${(props: RadioButtonBoxProps) =>
    props.isSelected ? '#EBF4FF' : 'transparent'};
  padding: 16px 24px;

  p {
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
  }
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 0px;
  font-size: 16px;
  line-height: 16px;

  b {
    font-weight: 500;
    margin: 0 8px;
  }
`;

export const RadioButtonBoxInput = styled.input`
  width: 25px;
  height: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
  min-height: 312px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #dedede;
  background: #f8f8f8;
  width: 100%;
  padding: 24px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const ConfirmCancel = styled.button`
  width: 240px;
  height: 38px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: transparent;
  color: #eb5757;
  border: 2px solid #eb5757;
  border-radius: 6px;
  padding: 14px;
  font-size: 15px;
  font-weight: bolder;
  line-height: 25px;
  cursor: pointer;

  :hover {
    background-color: #eb5757;
    color: white;
    transition: 0.7s;
  }
`;

export const CloseButton = styled.div`
  padding: 10px 18px 5px 5px;
  background: transparent;
  border: none;
  font-size: 20px;
  font-weight: bold;
  color: #c9c9c9;
  cursor: pointer;
`;

export const Text = styled.p`
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 19px;
`;
