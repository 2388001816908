import styled from 'styled-components';

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #d1e1fc;
  padding-top: 10px;
`;

export const DrugAndDosage = styled.div`
  padding-left: 5px;
  display: flex;
  gap: 15px;
  align-items: flex-start;
  flex-direction: column;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    color: #1c1413;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 100%;
    color: #7a7a7a;
  }
`;

export const DosageAndButton = styled.div`
  padding-right: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;

    color: #1756e8;
  }
`;

export const ContainerItemPrescription = styled.div`
  width: 100%;
  overflow-y: scroll;
  max-height: 400px;
`;
