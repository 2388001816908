import { iDiagnosisReport } from '~/domain/interfaces/models/DiagnosisReport';
import { DiagnosisReportTypes } from '~/domain/interfaces/redux/diagnosisReport/types';
import { DiagnosisReportActions } from './actions';

export const initialState: iDiagnosisReport = {
  loading: false,
  modalities: {
    records: [],
    metadata: {
      total: 0,
      offset: 0,
      limit: 0,
    },
  },
  examTypes: {
    records: [],
    metadata: {
      total: 0,
      offset: 0,
      limit: 0,
    },
  },
};

const reducer = (
  state = initialState,
  action: DiagnosisReportActions,
): iDiagnosisReport => {
  switch (action.type) {
    case DiagnosisReportTypes.LISTMODALITIES:
      return { ...state, loading: true };
    case DiagnosisReportTypes.LISTMODALITIES_FAILED:
      return { ...state, loading: false };
    case DiagnosisReportTypes.LISTMODALITIES_SUCCESS: {
      return { ...state, loading: false, modalities: action.payload };
    }
    case DiagnosisReportTypes.LIST:
      return { ...state, loading: true };
    case DiagnosisReportTypes.LIST_FAILED:
      return { ...state, loading: false };
    case DiagnosisReportTypes.LIST_SUCCESS: {
      return { ...state, loading: false, list: action.payload };
    }
    case DiagnosisReportTypes.LIST_EXAM_TYPES:
      return { ...state, loading: true };
    case DiagnosisReportTypes.LIST_EXAM_TYPES_FAILED:
      return { ...state, loading: false };
    case DiagnosisReportTypes.LIST_EXAM_TYPES_SUCCESS: {
      return { ...state, loading: false, examTypes: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
