/* eslint-disable no-case-declarations */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { createIntl, createIntlCache } from 'react-intl';

import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { TableSortLabel } from '@material-ui/core';
import _ from 'lodash';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import { translator } from '~/presentation/components/i18n';

import { History } from '~/main/routes';

import { SendMail } from '~/presentation/components/messages/email/SendMail';
import { IconSort } from '~/presentation/base/icons';
import orderData from '~/utils/orderData';
import { iStore } from '~/domain/interfaces/models';
import {
  makeRemoteDeleteClinicalDocs,
  makeReduxDeleteClinicalDocs,
} from '~/main/factories/usecases/clinicalDocs/DeleteClinicalDocsFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import { AlertMessage } from '../../messages/AlertMessage';
import ListSkeleton from '../../skeleton/ListSkeleton';
import { iListClinicalDocument } from '../interface';
import { DocumentStatus } from '../../statusList';
import ActionsList from '../../actionsList/ActionsList';
import { AvatarBadger } from '../../avatar-roles';
import { Avatar } from '../styles/StyledUserItem';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
});

interface iTranslate {
  [key: string]: string;
}

interface ownProps {
  dataList: iListClinicalDocument[];
}

type iOrder =
  | 'date'
  | 'patient'
  | 'professional'
  | 'statusSignature'
  | 'specialty'
  | 'time'
  | 'origin'
  | 'clinicalDocument';

const ClinicalDocuments: React.FC<ownProps> = props => {
  const [order, setOrder] = useState<boolean>(true);
  const [categoryToOrder, setCategoryToOrder] = useState(true);
  const [dataList, setData] = useState<iListClinicalDocument[]>([
    ...props.dataList,
  ]);
  const { loading } = useSelector((store: iStore) => store.clinicalDocs);
  const clinicalDocs = useSelector((store: iStore) => store.clinicalDocs);
  const { results } = useSelector((store: iStore) => store.clinicalDocs);

  const pending = translator('Excluir documento');
  const confirmExclude = translator('Você deseja excluir o documento?');

  const nameOrigin: iTranslate = {
    APPOINTMENT: 'Atendimento',
    DETACHED: 'Avulso',
  };

  const nameTypeIs: iTranslate = {
    BASIC: 'Receita Simples',
    MEDICALCERTIFICATE: 'Atestado Médico',
    EXAMREQUEST: 'Requisição de exame',
    APAC_REPORT: 'Solicitação/Autorização de Procedimento Ambulatorial',
  };

  const arrToCompare = [
    'date',
    'patient',
    'professional',
    'statusSignature',
    'specialty',
    'time',
    'origin',
    'clinicalDocument',
  ];
  const [sendingEmail, setSendingEmail] = useState<{
    visible: boolean;
    docId: number;
    email?: string;
  }>({ visible: false, docId: 0 });

  const classes = useStyles();
  const columns = [
    {
      id: 'date',
      label: translator('Data'),
      minWidth: 120,
      align: 'left',
    },
    {
      id: 'patient',
      label: translator('Paciente'),
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'professional',
      label: translator('Profissional'),
      minWidth: 190,
      maxWidth: 300,
      align: 'left',
    },
    {
      id: 'statusSignature',
      label: translator('Assinatura'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'specialty',
      label: translator('Especialidade'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'time',
      label: translator('Hora'),
      minWidth: 100,
      align: 'left',
    },

    {
      id: 'origin',
      label: translator('Origem'),
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'clinicalDocument',
      label: translator('Documento clínico'),
      minWidth: 100,
      maxWidth: 150,
      align: 'left',
    },
    {
      id: 'actions',
      label: translator('Ações'),
      minWidth: 100,
      align: 'left',
    },
  ];

  const deleteDoc = (clinicaldoc: any) => {
    try {
      makeReduxDeleteClinicalDocs().delete({
        docId: clinicaldoc.id,
      });
    } catch (e) {
      AlertMessage({
        message: intl.formatMessage({
          id: 'Ocorreu um erro ao excluir o documento clínico',
        }),
        type: 'danger',
      });
    }
    closeModal();
  };

  function ActionsClinicalDoc(clinicaldoc: any) {
    if (clinicaldoc.statusSignature) {
      return (
        <ActionsList
          actions={{
            shareEmail: () =>
              setSendingEmail({
                visible: true,
                docId: clinicaldoc.id,
                email: clinicaldoc?.email ?? '',
              }),
            details: () => {
              const url = window.location.pathname;

              History.getHistory().push('/clinicaldoc/detail', {
                docId: clinicaldoc.id,
                from: url.replaceAll('/', ''),
              });
            },
          }}
        />
      );
    }

    return (
      <ActionsList
        actions={{
          details: () => {
            const url = window.location.pathname;

            History.getHistory().push('/clinicaldoc/detail', {
              docId: clinicaldoc.id,
              from: url.replaceAll('/', ''),
            });
          },
          deleteClinicalDocument: () => {
            makeReduxActiveMessage().active({
              active: 'delete',
              title: pending,
              content: confirmExclude,
              actionOk: () => deleteDoc(clinicaldoc),
              actionCancel: closeModal,
            });
          },
        }}
      />
    );
  }

  const RenderComponents: React.FC<{
    id: any;
    clinicaldoc: any;
  }> = ({ id, clinicaldoc }) => {
    switch (id) {
      case 'actions':
        return ActionsClinicalDoc(clinicaldoc);
      case 'statusSignature':
        return <DocumentStatus status={clinicaldoc.statusSignature} />;
      case 'patient':
        return (
          <Avatar>
            <AvatarBadger
              src={clinicaldoc?.avatar}
              name={`${clinicaldoc?.patient}`}
              size="root"
              status={clinicaldoc?.status === 'ONLINE'}
            />
            <text>{`${clinicaldoc?.patient}`}</text>
          </Avatar>
        );
      case 'professional':
        return (
          <Avatar>
            <AvatarBadger
              src={clinicaldoc?.avatar}
              name={`${clinicaldoc?.professional}`}
              size="root"
              status={clinicaldoc?.status === 'ONLINE'}
            />
            <text>{`${clinicaldoc?.professional}`}</text>
          </Avatar>
        );
      case 'origin':
        const translation = nameOrigin[clinicaldoc?.origin]
          ? translator(nameOrigin[clinicaldoc?.origin])
          : '-';

        return <div>{translation}</div>;
      case 'clinicalDocument':
      case 'typeIs':
        const key = clinicaldoc?.clinicalDocument ?? clinicaldoc?.typeIs;
        const translationTypeIs = nameTypeIs[key]
          ? translator(nameTypeIs[key])
          : '-';

        return <div>{translationTypeIs}</div>;
      default:
        return <div>{clinicaldoc[`${id}`]}</div>;
    }
  };

  function handleSortByCategory(type: iOrder) {
    switch (type) {
      case 'date':
        const orderStatus = categoryToOrder ? 'asc' : 'desc';

        setData([
          ..._.orderBy(dataList, dateSort => dateSort.date, `${orderStatus}`),
        ]);
        return setCategoryToOrder(!categoryToOrder);

      default:
        setOrder(prevState => !prevState);
        return setData([...orderData(dataList, order, type as iOrder)]);
    }
  }

  useEffect(() => {
    setOrder(false);
    setData([...orderData(props.dataList, true, 'date')]);
  }, [props.dataList]);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map((item, index) => {
              return (
                <TableCell align="left" key={index}>
                  {arrToCompare.includes(item.id) ? (
                    <TableSortLabel
                      active
                      IconComponent={() => (
                        <div style={{ paddingLeft: 3 }}>
                          <IconSort id={`sort_${item.id}`} />
                        </div>
                      )}
                      style={{ color: '#8A8A8A', fontWeight: 'normal' }}
                      onClick={() => handleSortByCategory(item.id as iOrder)}
                    >
                      {item.label}
                    </TableSortLabel>
                  ) : (
                    <div style={{ color: '#8A8A8A', fontWeight: 'normal' }}>
                      {item.label}
                    </div>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <>
              {columns.map(item => {
                return (
                  <TableRow>
                    <ListSkeleton columns={columns} />
                  </TableRow>
                );
              })}
            </>
          ) : (
            dataList.map((item: any) => (
              <TableRow key={item.id}>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      key={columnProps.id}
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        clinicaldoc={item}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      {sendingEmail.visible && (
        <SendMail
          docId={sendingEmail.docId}
          close={() => setSendingEmail({ visible: false, docId: 0 })}
          initialValue={sendingEmail.email ?? ''}
        />
      )}
    </TableContainer>
  );
};

export default ClinicalDocuments;
