import { GetAllDocType } from '~/domain/usecases/docType/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
} from '~/domain/errors';

export class RemoteGetAllDocType implements GetAllDocType {
  private readonly url: string;

  private readonly httpCLient: HttpClient<GetAllDocType.Model>;

  constructor(url: string, httpClient: HttpClient<GetAllDocType.Model>) {
    this.url = url;
    this.httpCLient = httpClient;
  }

  getAll = async (
    params: GetAllDocType.Params,
  ): Promise<GetAllDocType.Model> => {
    let query = '';

    Object.entries(params).forEach(([key, value]) => {
      if (value) query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpCLient.request({
      url: `${this.url}?${query}`,
      method: 'get',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
