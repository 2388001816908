import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Skeleton } from '@material-ui/lab';

import { useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import { ExtraInfo as iExtraInfo } from '~/presentation/roles/professional/pages/appointment/interface';
import { Button } from '~/presentation/components/UI/buttons';
import { IconDownload } from '~/presentation/base/icons';
import { makeRemoteDownloadReport } from '~/main/factories/usecases/appointment/DownloadReportFactory';
import { Actions, InfoUser, InfoUserVariant, SessionInformation } from '.';
import { AvatarImage } from '../avatar-roles';
import { translator } from '../i18n';
import { SessionData, iParticipants } from './interface';
import { ButtonsContainer, Container } from './styles/StyledCard';
import {
  Address,
  Age,
  Contact,
  Email,
  Infos,
  Name,
  UserInfo,
} from './styles/StyledInfoUserCard';
import { Header } from './styles/StyledUserInformation';
import { AlertMessage } from '../messages/AlertMessage';
import { BaseContent } from './styles/StyledSessionInformation';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { MessageOptions } from '~/domain/interfaces/redux/message/types';

type iInfoUser = {
  age: number;
  city: string;
  name: string;
  organization: string;
  sex: string;
  situation: string;
  susCard: string;
  uf: string;
};

interface ownProps {
  user?: iParticipants;
  session?: SessionData;
  extraInfo?: iExtraInfo;
  isInterconsultation?: boolean;
}

interface iStateParams {
  appointmentId: string;
}

const Card: React.FC<ownProps> = ({
  user,
  session,
  isInterconsultation,
  extraInfo,
}) => {
  const { role } = useSelector((store: iStore) => store.auth.selectUser);

  const stateParams = useLocation<iStateParams>().state;

  // TODO: Ajustar dados do paciente
  const renderInfo = useMemo(() => {
    if (role === 'ORG') {
      return <InfoUserVariant participants={user} />;
    }

    if (isInterconsultation && extraInfo) {
      return <ExtraInfo extraInfo={extraInfo} />;
    }

    return <InfoUser user={user} />;
  }, [extraInfo, isInterconsultation, role, user]);

  const handleDownload = useCallback(() => {
    makeRemoteDownloadReport()
      .download({
        appointmentId: stateParams.appointmentId,
      })
      .then(res => {
        const blob = new Blob([res], { type: 'application/pdf' });
        const urlToPdf = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = urlToPdf;
        a.download = 'report.pdf';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        AlertMessage({
          type: 'success',
          message: 'Relatório baixado com sucesso',
        });
      })
      .catch(err => {
        AlertMessage({
          type: 'danger',
          message:
            'Não foi possível efetuar download do relatório. Tente novamente.',
        });
      });
  }, [stateParams.appointmentId]);

  const openSOAPAIModal = useCallback(() => {
    makeReduxActiveMessage().active({
      active: MessageOptions.soapAi,
      data: {
        appointmentId: stateParams.appointmentId,
      },
    });
  }, []);

  return (
    <Container>
      <SessionInformation session={session} />
      {renderInfo}
      {/* {role === 'ORG' ? (
        <InfoUserVariant participants={user} />
      ) : isInterconsultation ? (
        <ExtraInfo extraInfo={extraInfo} />
      ) : (
        <InfoUser user={user} />
      )} */}
      {session?.status === 'ENDED' ? (
        <ButtonsContainer>
          <Button
            id="downloadReport"
            variant="secundary"
            size="large"
            icon={IconDownload}
            height="40px"
            onClick={handleDownload}
          >
            Registro do atendimento
          </Button>
          <Button
            id="soapAI"
            variant="secundary"
            size="large"
            height="40px"
            onClick={openSOAPAIModal}
          >
            SOAP AI
          </Button>
        </ButtonsContainer>
      ) : (
        <Actions status={session?.status} cancelDate={session?.cancelDate} />
      )}
    </Container>
  );
};

const ExtraInfo: React.FC<{ extraInfo: iExtraInfo }> = ({ extraInfo }) => {
  const mapExtraInfo = useMemo(
    () => ({
      name: extraInfo?.name ?? 'Não informado',
      age: extraInfo?.age ?? 'Não informado',
      city: extraInfo?.city ?? 'Não informada',
      organization: extraInfo?.organization ?? 'Não informada',
      sex: extraInfo?.sex ?? 'Não informado',
      susCard: extraInfo?.susCard ?? 'Não informado',
      uf: extraInfo?.uf ?? 'Não informada',
    }),
    [extraInfo],
  );

  return (
    <BaseContent>
      <Header>{translator('Informações adicionais')}</Header>
      <Infos>
        <div>
          <AvatarImage size="large" name={mapExtraInfo?.name} />
        </div>
        <UserInfo>
          <Name>
            {`${translator('Nome')}:`}
            {!mapExtraInfo?.name ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{mapExtraInfo?.name}</div>
            )}
          </Name>
          <Age>
            Idade (anos ou meses):
            {!mapExtraInfo?.age ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{mapExtraInfo?.age}</div>
            )}
          </Age>
          <Contact>
            {`${translator('Sexo')}:`}
            {!mapExtraInfo?.sex ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{mapExtraInfo?.sex}</div>
            )}
          </Contact>
          <Address>
            {`${translator('Organização')}:`}
            {!mapExtraInfo?.organization ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{mapExtraInfo?.organization}</div>
            )}
          </Address>
          <Email>
            {`${translator('Cartão do SUS')}:`}
            {!mapExtraInfo?.susCard ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{mapExtraInfo?.susCard}</div>
            )}
          </Email>
        </UserInfo>
      </Infos>
    </BaseContent>
  );
};

export default Card;
