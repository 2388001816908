import React, { useCallback, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Container, Form } from './styles/StyledGeneralData';

import { Input } from '~/presentation/components/UI';
import InputKeyboardDate from '~/presentation/components/inputKeyboardDate';
import { translator } from '~/presentation/components/i18n';

import { iListConsultant, iCreateMedicalCertificate } from './interface';

import { Navigator } from './Navigator';

import { schemaGeneralData } from '~/validation/validators/document/CreateClinicalDocValidator';

import { iProfessional } from '~/domain/interfaces/models/Professional';

import SearchSelect from '../UI/searchSelect';
import _ from 'lodash';

export interface ownProps {
  consultant: iListConsultant[];
  next: (data: iCreateMedicalCertificate) => any;
  professional: iProfessional;
  state?: iCreateMedicalCertificate;
  disabled?: boolean;
}

const GeneralData: React.FC<ownProps> = ({
  next,
  professional,
  state,
  disabled,
}): JSX.Element => {
  const { errors, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaGeneralData),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = handleSubmit(data => {
    next({ ...data });
  });

  const generateProfessionalSelectOptions = useCallback(() => {
    return _.sortBy(
      professional?.results.flatMap(value => {
        return [
          {
            value: value.professional.id,
            label: `${value.user.firstName} ${value.user.lastName}`,
          },
        ];
      }),
      item => item?.label.replace(/[^a-zA-Z0-9]/g, '').toLocaleLowerCase(),
    );
  }, [professional]);

  const professionalMultiselectSelected = useMemo(() => {
    return generateProfessionalSelectOptions().find(
      option => option.value === watch('professional'),
    );
  }, [generateProfessionalSelectOptions, watch]);

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Input
          id="input_instituition"
          label={`${translator('Instituição')}`}
          onChange={e => setValue('org', Number(e.target.value))}
          name="org"
          register={() => register('org')}
          error={Boolean(errors.org)}
          message={errors?.org?.message}
          placeholder={state?.orgUnitName}
          disabled={disabled || (state && state.orgUnitName !== '')}
          autoFocus
        />

        <SearchSelect
          key={state?.professional}
          id="select_professional"
          name="professional"
          register={() => register('professional')}
          label={`${translator('Profissional')}`}
          placeholder={translator('Selecione um profissional')}
          onChange={e => {
            setValue('professional', Number(e?.value));
          }}
          error={Boolean(errors.professional)}
          message={
            errors?.professional?.message
              ? translator(errors?.professional?.message)
              : ''
          }
          isDisabled={disabled || (state && state.professional !== -1)}
          options={generateProfessionalSelectOptions()}
          value={professionalMultiselectSelected}
        />

        <InputKeyboardDate
          state={new Date()}
          setState={() => ''}
          label={`${translator('Data de emissão')}`}
          placeholder="DD/MM/AAAA"
          disabled
        />

        <Navigator />
      </Form>
    </Container>
  );
};

export default GeneralData;
