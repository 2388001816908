import {
  iActionRefresh,
  iActionRefreshSuccess,
  iActionRefreshFailed,
  AuthTypes,
} from '~/domain/interfaces/redux/auth/Refresh';

import { Refresh } from '~/domain/usecases/auth/redux';

export const refresh = (payload: Refresh.Params): iActionRefresh => ({
  type: AuthTypes.REFRESH,
  payload,
});

export const refreshSuccess = (
  payload: Refresh.ModelToRedux,
): iActionRefreshSuccess => ({
  type: AuthTypes.REFRESH_SUCCESS,
  payload,
});

export const refreshFailed = (): iActionRefreshFailed => ({
  type: AuthTypes.REFRESH_FAILED,
});
