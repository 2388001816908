import React from 'react';

import { Menu } from '~/presentation/components/menu';

import { RegisterProfessional as RegisterUserForm } from '../../../../components/registerProfessional';

import { Container, Body, LockScroll } from './StyledRegisterProfessional';

import { PageHeader } from '~/presentation/components/header';
import { translator } from '~/presentation/components/i18n';

const RegisterProfessional: React.FC = (): JSX.Element => {
  return (
    <Container>
      <LockScroll>
        <Menu />
        <PageHeader
          title={translator('Cadastrar novo profissional')}
          confirmChanges
        />
      </LockScroll>
      <Body>
        <RegisterUserForm />
      </Body>
    </Container>
  );
};

export default RegisterProfessional;
