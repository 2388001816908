import { GetInvoiceByOrg } from '~/domain/usecases/billing/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import { UnexpectedError, NotFound, Forbidden } from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteGetInvoiceByOrg implements GetInvoiceByOrg {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetInvoiceByOrg.Model>;

  constructor(url: string, httClient: HttpClient<GetInvoiceByOrg.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  get = async (
    params: GetInvoiceByOrg.Params,
  ): Promise<GetInvoiceByOrg.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.org}/invoices`,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new UnexpectedError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
