import { GetUfs } from '~/domain/usecases/externalServices/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  UnexpectedError,
  InvalidCredentialsError,
  NotFound,
  BadRequestError,
} from '~/domain/errors';

export class RemoteGetUfs implements GetUfs {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetUfs.Model>;

  constructor(url: string, httpClient: HttpClient<GetUfs.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  getUfs = async (params: GetUfs.Params): Promise<GetUfs.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });

    console.log('GetUfs request: ', httpResponse);
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
