import React from 'react';
import {
  Content,
  Body,
  Prefix,
  SubTitle,
  Suffix,
  Title,
  Wrapper,
  Text,
} from './styles/StyledMediaViewItem';
import { IconCam, IconCloudDownload } from '~/presentation/base/icons';
import { Button } from '~/presentation/components/UI';

interface MediaViewProps {
  id?: number;
  fileName: string;
  date?: string;
  size?: number;
  download?: (num: number) => any;
}

const MediaViewItem: React.FC<MediaViewProps> = props => {
  function prefixList() {
    return (
      <Wrapper>
        <IconCam />
        <Text>
          <text>{props.date}</text>
        </Text>
      </Wrapper>
    );
  }

  function sufixList() {
    return (
      <Wrapper>
        <span>
          <text>Tamanho</text>
          <text>
            <b>{`${Number(props.size! / 1e6).toPrecision(2)} MB`}</b>
          </text>
        </span>
      </Wrapper>
    );
  }

  return (
    <Content>
      <Prefix>{prefixList()}</Prefix>
      <Body>
        <Title>{props.fileName}</Title>
      </Body>
      <Suffix>
        {sufixList()}
        <Button rounded size="small" onClick={() => props.download!(props.id!)}>
          <div style={{ display: 'flex', gap: '10px' }}>
            <IconCloudDownload />
            Download
          </div>
        </Button>
      </Suffix>
    </Content>
  );
};

export default MediaViewItem;
