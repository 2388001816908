import React, { useState, KeyboardEvent } from 'react';
import { useSelector } from 'react-redux';
import { addDays, subYears } from 'date-fns';
import { IconFilter, IconSearch } from '~/presentation/base/icons';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxAppointmentFiltered } from '~/main/factories/usecases/appointment/AppointmentFiltered';
import { Container } from './styles/StyledSearchList';
import Dropdown from '../drop-down';

import { translator } from '../i18n';

interface SearchProps {
  name?: string;
  children: JSX.Element[] | JSX.Element;
  icon?: JSX.Element;
}

const SearchListAppointmentHistory: React.FC<SearchProps> = ({ children }) => {
  const { auth } = useSelector((store: iStore) => store);

  const [search, setSearch] = useState('');
  const { orgId } = useSelector((store: iStore) => store.auth.selectUser);

  const userType = auth.selectUser.role;

  const consultantId = auth.info.consultants?.find(
    item => item.org?.id === auth.selectUser?.orgId,
  )?.id;

  const professionalId = auth.info.professionals?.find(
    item => item.orgUnit?.id === auth.selectUser?.orgUnitId,
  )?.id;

  const handleSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const now = new Date();
    const begin = subYears(now, 20);
    const end = addDays(now, 2);

    switch (userType) {
      case 'PRO':
        if (e.key === 'Enter') {
          makeReduxAppointmentFiltered().post({
            dataControl: {
              limit: 9999,
            },
            timestamp: {
              begin: begin.toISOString(),
              end: end.toISOString(),
            },
            orgId,
            status: ['ENDED', 'CANCELED'],
            professional: professionalId,
            textMatch: search?.length > 0 ? search : undefined,
          });
        }
        break;
      case 'CON':
        if (e.key === 'Enter') {
          makeReduxAppointmentFiltered().post({
            dataControl: {
              limit: 9999,
            },
            timestamp: {
              begin: begin.toISOString(),
              end: end.toISOString(),
            },
            orgId,
            status: ['ENDED', 'CANCELED'],
            consultant: consultantId,
            textMatch: search?.length > 0 ? search : undefined,
          });
        }
        break;
      default:
        if (e.key === 'Enter') {
          makeReduxAppointmentFiltered().post({
            dataControl: {
              limit: 9999,
            },
            timestamp: {
              begin: begin.toISOString(),
              end: end.toISOString(),
            },
            orgId,
            status: ['ENDED', 'CANCELED'],
            textMatch: search?.length > 0 ? search : undefined,
          });
        }
        break;
    }
  };

  return (
    <Container onSubmit={() => handleSubmit}>
      <IconSearch id="btn_searchFilter" />
      <input
        id="input_search"
        placeholder={`${translator('Buscar por...')}`}
        value={search}
        onChange={e => {
          setSearch(e.target.value);
        }}
        onKeyPressCapture={handleSubmit}
      />
      <Dropdown icon={<IconFilter />}>{children}</Dropdown>
    </Container>
  );
};

export default SearchListAppointmentHistory;
