import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { makeReduxGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';

import { iStore } from '~/domain/interfaces/models';
import { PerfilManager } from '~/presentation/roles/manager/pages/profile';
import { PerfilProfessional } from '~/presentation/roles/professional/pages/profile';
import { PageNotFound } from '~/presentation/pages/errors';
import { V4hSpin } from '~/presentation/components/spin';

interface iParamsTypes {
  userId: string;
}

const MakePerfilFactories: React.FC = () => {
  const { userId } = useParams<iParamsTypes>();
  const { selected } = useSelector((state: iStore) => state.users);
  const { role } = useSelector((state: iStore) => state.auth.selectUser);

  useEffect(() => {
    makeReduxGetOneUser().getOne({ userId: Number(userId) });
  }, []);

  if (!selected.user) return <V4hSpin fullScreen background="white" />;

  switch (role) {
    case 'ORG':
      return <PerfilManager />;
    case 'PRO':
      // TODO: ISSUE #195
      // case 'CON':
      return <PerfilProfessional />;
    case 'ADM':
      return <PerfilManager />;

    default:
      return <PageNotFound />;
  }
};
export default MakePerfilFactories;
