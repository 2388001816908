import {
  iActionLeftWaitingRoom,
  iActionLeftFailedWaitingRoom,
  iActionLeftSuccessWaitingRoom,
  waitingRoomTypes,
} from '~/domain/interfaces/redux/waitingroom/left';

import { LeftWaitingRoom } from '~/domain/usecases/waitingRoom/socket';

export const LeftRequest = (
  params: LeftWaitingRoom.Params,
): iActionLeftWaitingRoom => ({
  type: waitingRoomTypes.LEFT,
  payload: params,
});

export const LeftSuccess = (): iActionLeftSuccessWaitingRoom => ({
  type: waitingRoomTypes.LEFT_SUCCESS,
});

export const Leftfailed = (): iActionLeftFailedWaitingRoom => ({
  type: waitingRoomTypes.LEFT_FAILED,
});
