import { Dispatch } from 'redux';
import store from '~/data/store';
import { getRequest } from '~/data/store/reducer/billing/actions/getInvoiceByOrg';

import { iActionGetInvoiceByOrg } from '~/domain/interfaces/redux/billing/getInvoiceByOrg';

import { GetInvoiceByOrg } from '~/domain/usecases/billing/redux';

export class ReduxGetInvoiceByOrg implements GetInvoiceByOrg {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  get = (params: GetInvoiceByOrg.Params): iActionGetInvoiceByOrg =>
    this.send(getRequest(params));
}
