/* eslint-disable consistent-return */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';

import ReCAPTCHA from 'react-google-recaptcha';
import { useHistory } from 'react-router';
import {
  IconEmail,
  IconPadlock,
  IconSaudeMeetHorizontalLogos,
  IconSaudeMeetWelcome,
  IconCodata,
  IconVisibility,
  IconV4HAtendimentoWelcome,
  IconV4HLogoHorizontalAlternative,
} from '~/presentation/base/icons';
import { Button } from '~/presentation/components/UI';
import Input from '~/presentation/components/UI/input/index';
import { translator } from '~/presentation/components/i18n';
import { Icon } from '~/presentation/components/icon';

import { iStore } from '~/domain/interfaces/models';
import { V4hSpin } from '~/presentation/components/spin';

import { makeReduxLogin } from '~/main/factories/usecases/auth/LoginFactory';
import { makeRemoteValidateCaptcha } from '~/main/factories/usecases/externalServices/ValidateCaptcha';
import { makeReduxGetOneUser } from '~/main/factories/usecases/users/GetOneUserFactory';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { schema } from '~/validation/validators/auth/LoginValidator';
import {
  Body,
  ButtonsContainer,
  Container,
  FlexContainer,
  FooterLeft,
  FooterRight,
  Forgotpass,
  Form,
  Image,
  ImageContainer,
  Left,
  RemindMe,
  Right,
  Title,
} from './styles/LoginPageStyled';

const Login: React.FC = (): JSX.Element => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  console.log('-');

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const [logoUrl, setLogoUrl] = useState<string | null>(null);
  // const [showPassword, setShowPassword] = useState(false);

  // const inputRef = useRef(null);

  const logIn = useSelector((store: iStore) => store.auth.loading);
  const auth = useSelector((store: iStore) => store.auth);
  const results = useSelector((store: iStore) => store.selectedOrg.results);

  const { push } = useHistory();

  const today = new Date();

  useEffect(() => {
    if (results.length > 0 && results[0].org?.logo)
      setLogoUrl(results[0].org.logo);
  }, [results]);

  useEffect(() => {
    return () => {
      if (auth.info.user?.id) {
        makeReduxGetOneUser().getOne({ userId: Number(auth.info.user.id) });
      }
    };
  }, [logIn]);

  const onSubmit = handleSubmit(
    async ({ password, email, login, org }): Promise<any> => {
      if (!recaptchaRef.current && auth.errorCount >= 3)
        return AlertMessage({
          type: 'danger',
          message: 'Erro ao validar o ReCAPTCHA, tente novamente mais tarde.',
        });

      if (!auth.errorCount || auth.errorCount < 3)
        return makeReduxLogin().login({
          email,
          login,
          password,
        });

      const captchaToken = recaptchaRef?.current?.getValue();

      if (!captchaToken)
        return AlertMessage({
          type: 'danger',
          message: 'Por favor, valide o ReCAPTCHA.',
        });

      makeRemoteValidateCaptcha()
        .validate({
          response: captchaToken,
        })
        .then(res => {
          if (res.success) {
            return makeReduxLogin().login({
              email,
              login,
              password,
            });
          }

          AlertMessage({
            type: 'danger',
            message: 'Erro ao validar o ReCAPTCHA, tente novamente mais tarde.',
          });
        })
        .catch(err => {
          AlertMessage({
            type: 'danger',
            message: 'Erro ao validar o ReCAPTCHA, tente novamente mais tarde.',
          });
        });
    },
  );

  const loading = useSelector((store: iStore) => store.auth.loading);

  // const toggleShowPassword = () => {
  //   if (inputRef.current != null && inputRef.current === 'password') {
  //     setShowPassword(true);
  //     inputRef.current = 'text';
  //   }
  // };

  return (
    <Container>
      <Body>
        <Left>
          {logoUrl ? (
            <Image id="logoV4H" alt="Logo" src={logoUrl} width="200px" />
          ) : (
            <ImageContainer>
              <Icon
                src={IconV4HLogoHorizontalAlternative}
                width="280px"
                height="140px"
              />
            </ImageContainer>
          )}
          <Form id="loginAuthentication" onSubmit={onSubmit}>
            <Title>{translator('Fazer Login')}</Title>
            <Controller
              control={control}
              name="login"
              rules={{
                required: 'E-mail é obrigatório',
              }}
              render={({ value }) => (
                <Input
                  id="loginInput"
                  name="login"
                  type="text"
                  label={translator('E-mail')}
                  icon={IconEmail}
                  placeholder={translator('Digite o email cadastrado')}
                  value={value}
                  onChange={e =>
                    setValue('login', e.target.value.toLowerCase().trim(), {
                      shouldValidate: true,
                    })
                  }
                  error={Boolean(errors.login)}
                  message={errors?.login?.message}
                  autoFocus
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              rules={{
                required: 'Senha é obrigatória',
              }}
              render={({ value }) => (
                <Input
                  id="loginPassword"
                  type="password"
                  name="password"
                  label={translator('Senha')}
                  icon={IconPadlock}
                  placeholder={translator('Digite sua senha')}
                  value={value}
                  onChange={e =>
                    setValue('password', e.target.value, {
                      shouldValidate: true,
                    })
                  }
                  error={Boolean(errors.password)}
                  message={errors?.password?.message}
                />
              )}
            />

            <FlexContainer>
              <RemindMe>
                <input type="checkbox" />
                <span>{translator('Lembrar-me')}</span>
              </RemindMe>
              <Forgotpass id="loginForgotPass" href="/password/recovery">
                {translator('Esqueceu sua senha?')}
              </Forgotpass>
            </FlexContainer>

            {auth.errorCount >= 3 && !loading && (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey="6Lc4hHEmAAAAAL9e2reBU9fO_yRe9JdsHWfdGFLc"
              />
            )}

            <ButtonsContainer>
              <Button
                id="loginSubmit"
                type="submit"
                height="48px"
                disabled={!isValid}
              >
                {logIn
                  ? `${translator('Carregando')}...`
                  : translator('Entrar')}
              </Button>
              {window.config.hasFreemium && (
                <Button
                  id="signIn"
                  variant="secundary"
                  height="48px"
                  onClick={() => push('/register')}
                  disabled={logIn}
                >
                  {translator('Criar conta gratuita')}
                </Button>
              )}
              {/* <Button
                id="loginSubmit"
                type="submit"
                variant="secundary"
                height="48px"
                icon={IconCodata}
                rounded
              >
                Autenticar com CODATA
              </Button> */}
            </ButtonsContainer>
          </Form>
          <FooterLeft>
            <a
              href="https://docs.google.com/document/d/1rs7dPIBW_YWSFiBL1cjPHRgi1r9nfXbO2jUNU2y_4bU"
              target="_blank"
              rel="noreferrer"
            >
              {translator('Versão')} 3.75.1
            </a>
            <p>
              © {today.getFullYear()} Copyright.{' '}
              {translator('Todos os direitos reservados.')}
            </p>
          </FooterLeft>
        </Left>
        <Right>
          <Icon src={IconV4HAtendimentoWelcome} width="246px" height="180px" />
          <div className="middle-line" />
          <span>
            Esta é uma plataforma completa para atendimentos em Saúde Digital.{' '}
            <a href="https://wisecare.com.br/" target="_blank" rel="noreferrer">
              Clique aqui
            </a>{' '}
            para saber mais sobre este e outros produtos da Wisecare.
          </span>
          <FooterRight>
            <span>Consulte também os</span>
            <p>
              <a
                href="https://v4h.cloud/termos-de-uso/"
                target="_blank"
                rel="noreferrer"
              >
                {translator('Termos de Serviço')}
              </a>
              <span>{translator('e')}</span>
              <a
                href="https://v4h.cloud/politicas-de-privacidade/"
                target="_blank"
                rel="noreferrer"
              >
                {translator('Políticas de Privacidade')}
              </a>
            </p>
          </FooterRight>
        </Right>
      </Body>
      {loading && <V4hSpin fullScreen />}
    </Container>
  );
};

export default Login;
