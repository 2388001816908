import { iOrgUnit } from '~/domain/interfaces/models/OrgUnit';
import { OrgUnitTypes } from '~/domain/interfaces/redux/orgUnit/types';
import { OrgUnitActions } from './actions';

export const initialState: iOrgUnit = {
  pageSize: 0,
  totalItems: '0',
  selected: -1,
  loading: false,
  results: [],
};

const reducer = (state = initialState, action: OrgUnitActions): iOrgUnit => {
  switch (action.type) {
    case OrgUnitTypes.LIST:
      return { ...state, loading: true };
    case OrgUnitTypes.LIST_FAILED:
      return { ...state, loading: false };
    case OrgUnitTypes.LIST_SUCCESS: {
      return { ...state, ...action.payload, loading: false };
    }
    default:
      return state;
  }
};

export default reducer;
