import { GetAllSpecialty } from '~/domain/usecases/specialty/redux';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  SpecialtyTypes,
} from '~/domain/interfaces/redux/specialty/getAll';

export const getAllRequest = (
  payload: GetAllSpecialty.Params,
): iActionGetAll => ({
  type: SpecialtyTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllSpecialty.Model,
): iActionGetAllSuccess => ({
  type: SpecialtyTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: SpecialtyTypes.GETALL_FAILED,
});
