import React, { useEffect, useState } from 'react';
import { Container, IconHeader, Body, Footer } from './styles/onDemandStyles';
import {
  AppointmentIcon,
  BlockchainIcon,
  CloudBackupIcon,
  FacialRecognitionIcon,
  ProtectionIcon,
  PrescriptionIcon,
  ArrowUpGreen,
  ArrowDownRed,
} from '~/presentation/base/icons';
import { translator } from '../i18n';

enum Types {
  BACKUP = 'BACKUP',
  APPOINTMENT = 'APPOINTMENT',
  BLOCKCHAIN = 'BLOCKCHAIN',
  PROTECT = 'PROTECT',
  FACIAL_RECOGNITION = 'FACIAL_RECOGNITION',
  PRESCRIPTION = 'PRESCRIPTION',
}

enum coustValue {
  DOWN = 'DOWN',
  UP = 'UP',
}

interface ownProps {
  type: keyof typeof Types;
  value: number;
  percentValue: number;
  active?: boolean;
  width?: string;
  height?: string;
}

const ServiceCardOnDemand: React.FC<ownProps> = ({
  type,
  value,
  percentValue,
  active = false,
  width,
  height,
}) => {
  const [v, setV] = useState<number>(0);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setV(value);
  }, [v, value]);

  // TODO: Desabilitado temporariamente para evitar inconsistências
  // useEffect(() => {
  //   setV(0);
  //   setUpdate(prev => !prev);
  // }, [value]);

  // useEffect(() => {
  //   if (v !== value) setV(prev => prev + 1);
  // }, [v, update]);

  const renderIcons = (iconType: keyof typeof Types) => {
    switch (iconType) {
      case Types.BACKUP:
        return (
          <IconHeader active={active}>
            <CloudBackupIcon />
            <p>{translator('Backup em nuvem')}</p>
          </IconHeader>
        );
      case Types.APPOINTMENT:
        return (
          <IconHeader active={active}>
            <AppointmentIcon />
            <p>{translator('Atendimentos')}</p>
          </IconHeader>
        );
      case Types.BLOCKCHAIN:
        return (
          <IconHeader active={active}>
            <BlockchainIcon />
            <p>BlockChain</p>
          </IconHeader>
        );
      case Types.FACIAL_RECOGNITION:
        return (
          <IconHeader active={active}>
            <FacialRecognitionIcon />
            <p>{translator('Reconhecimento facial')}</p>
          </IconHeader>
        );
      case Types.PRESCRIPTION:
        return (
          <IconHeader active={active}>
            <PrescriptionIcon />
            <p>{translator('Prescrições')}</p>
          </IconHeader>
        );
      case Types.PROTECT:
        return (
          <IconHeader active={active}>
            <ProtectionIcon />
            <p>{translator('Proteção')}</p>
          </IconHeader>
        );
      default:
        return null;
    }
  };

  const renderBody = (iconType: keyof typeof Types) => {
    switch (iconType) {
      case Types.BACKUP:
        return (
          <>
            <Body active={active}>
              {active ? (
                <>
                  <div className="value">{`${v} GB`}</div>
                  {translator('Utilizados')}
                </>
              ) : (
                <div style={{ maxWidth: '180px' }}>
                  {translator(
                    'Este recurso não está disponível no seu pacote de serviços atual.',
                  )}
                </div>
              )}
            </Body>

            {active && (
              <Footer value={percentValue}>
                {percentValue >= 0 ? <ArrowUpGreen /> : <ArrowDownRed />}
                {`${
                  percentValue ? percentValue?.toString().replace('-', '') : 0
                }%`}
                <div className="text">{translator('desde o último mês')}</div>
              </Footer>
            )}
          </>
        );
      case Types.APPOINTMENT:
        return (
          <>
            <Body active={active}>
              {active ? (
                <>
                  <div className="value">{v}</div>
                  {translator('Atendimentos realizados')}
                </>
              ) : (
                <div style={{ maxWidth: '180px' }}>
                  {translator(
                    'Este recurso não está disponível no seu pacote de serviços atual.',
                  )}
                </div>
              )}
            </Body>

            {active && (
              <Footer value={percentValue}>
                {percentValue >= 0 ? <ArrowUpGreen /> : <ArrowDownRed />}
                {`${
                  percentValue ? percentValue?.toString().replace('-', '') : 0
                }%`}
                <div className="text">{translator('desde o último mês')}</div>
              </Footer>
            )}
          </>
        );
      case Types.BLOCKCHAIN:
        return (
          <>
            <Body active={active}>
              {active ? (
                <>
                  <div className="value">{v}</div>
                  {translator('Em')}
                  blockChain
                </>
              ) : (
                <div style={{ maxWidth: '180px' }}>
                  {translator(
                    'Este recurso não está disponível no seu pacote de serviços atual.',
                  )}
                </div>
              )}
            </Body>

            {active && (
              <Footer value={percentValue}>
                {percentValue >= 0 ? <ArrowUpGreen /> : <ArrowDownRed />}
                {`${
                  percentValue ? percentValue?.toString().replace('-', '') : 0
                }%`}
                <div className="text">{translator('desde o último mês')}</div>
              </Footer>
            )}
          </>
        );
      case Types.FACIAL_RECOGNITION:
        return (
          <>
            <Body active={active}>
              {active ? (
                <>
                  <div className="value">{v}</div>
                  {translator('Reconhecimentos realizados')}
                </>
              ) : (
                <div style={{ maxWidth: '180px' }}>
                  {translator(
                    'Este recurso não está disponível no seu pacote de serviços atual.',
                  )}
                </div>
              )}
            </Body>

            {active && (
              <Footer value={percentValue}>
                {percentValue >= 0 ? <ArrowUpGreen /> : <ArrowDownRed />}
                {`${
                  percentValue ? percentValue?.toString().replace('-', '') : 0
                }%`}
                <div className="text">{translator('desde o último mês')}</div>
              </Footer>
            )}
          </>
        );
      case Types.PRESCRIPTION:
        return (
          <>
            <Body active={active}>
              {active ? (
                <>
                  <div className="value">{v}</div>
                  {translator('Prescrições geradas')}
                </>
              ) : (
                <div style={{ maxWidth: '180px' }}>
                  {translator(
                    'Este recurso não está disponível no seu pacote de serviços atual.',
                  )}
                </div>
              )}
            </Body>

            {active && (
              <Footer value={percentValue}>
                {percentValue >= 0 ? <ArrowUpGreen /> : <ArrowDownRed />}
                {`${
                  percentValue ? percentValue?.toString().replace('-', '') : 0
                }%`}
                <div className="text">{translator('desde o último mês')}</div>
              </Footer>
            )}
          </>
        );
      case Types.PROTECT:
        return (
          <>
            <Body active={active}>
              {active ? (
                <>
                  <div className="value">{v}</div>
                  {translator('Atendimentos preservados')}
                </>
              ) : (
                <div style={{ maxWidth: '180px' }}>
                  {translator(
                    'Este recurso não está disponível no seu pacote de serviços atual.',
                  )}
                </div>
              )}
            </Body>

            {active && (
              <Footer value={percentValue}>
                {percentValue >= 0 ? <ArrowUpGreen /> : <ArrowDownRed />}
                {`${
                  percentValue ? percentValue?.toString().replace('-', '') : 0
                }%`}
                <div className="text">{translator('desde o último mês')}</div>
              </Footer>
            )}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container active={active} width={width} height={height}>
      {renderIcons(type)}
      {renderBody(type)}
    </Container>
  );
};

export default ServiceCardOnDemand;
