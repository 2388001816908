import { ListExamTypesDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux/ListExamTypesDiagnosisReport';
import {
  iActionListExamTypes,
  iActionListExamTypesFailed,
  iActionListExamTypesSuccess,
  DiagnosisReportTypes,
} from '~/domain/interfaces/redux/diagnosisReport/listExamTypes';

export const listExamTypesRequest = (
  payload: ListExamTypesDiagnosisReport.Params,
): iActionListExamTypes => ({
  type: DiagnosisReportTypes.LIST_EXAM_TYPES,
  payload,
});

export const listExamTypesSuccess = (
  params: ListExamTypesDiagnosisReport.Model,
): iActionListExamTypesSuccess => ({
  type: DiagnosisReportTypes.LIST_EXAM_TYPES_SUCCESS,
  payload: params,
});

export const listExamTypesFailed = (): iActionListExamTypesFailed => ({
  type: DiagnosisReportTypes.LIST_EXAM_TYPES_FAILED,
});
