import React from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import AppointmentEmpty from '../componentEmpty/AppointmentEmpty';
import { iListAppointment } from './interface';

import List from './List';
import { Appointment } from './Lists';
import { ConnectAppointment } from './mapper/MapperListRegisteredAppointment';

export interface ownProps {
  data: iListAppointment[];
  loading: boolean;
}

const ListAppointment: React.FC<ownProps> = ({ data }) => {
  const { loading } = useSelector((store: iStore) => store.appointment);

  return data.length === 0 && !loading ? (
    <AppointmentEmpty />
  ) : (
    <Appointment dataList={data} />
  );
};

export default ConnectAppointment(ListAppointment);
