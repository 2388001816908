import { GetProfessionalOnDuty as UseCaseReduxGetProfessionalOnDuty } from '~/domain/usecases/duty/redux';
import { GetProfessionalOnDuty as UseCaseRemoteGetProfessionalOnDuty } from '~/domain/usecases/duty/remote';
import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { RemoteGetProfessionalOnDuty } from '~/data/repository/duty';
import { ReduxGetProfessionalOnDuty } from '~/data/store/reducer/duty/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetProfessionalOnDuty =
  (): UseCaseRemoteGetProfessionalOnDuty =>
    new RemoteGetProfessionalOnDuty(makeApiUrl('/oncall'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetProfessionalOnDuty =
  (): UseCaseReduxGetProfessionalOnDuty => new ReduxGetProfessionalOnDuty();
