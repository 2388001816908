import React, { useMemo } from 'react';

import { Container, Header, CloseButton } from './styles';
import { Body, Content } from './styles/StyledUserAlreadyExists';

import { Button } from '../../UI';

import { ownProps } from '../interface';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { translator } from '../../i18n';

const UserDataFulfill: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.userAlreadyExists;

  const { active, actionCancel, actionOk } = message;

  const isRegisteringPatient = useMemo(
    () => window.location?.pathname?.includes('/participant'),
    [],
  );

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span style={{ color: '#EC5C52' }}>{translator('Atenção')}</span>
              <CloseButton onClick={actionCancel}>X</CloseButton>
            </Header>
            <Content>
              {/* TODO: Adicionar Role na mensagem (já está cadastrado como organizador neste setor) */}
              <span>
                {translator(
                  `O usuário já está cadastrado ${
                    isRegisteringPatient ? 'nesta organização' : 'neste setor'
                  }.`,
                )}
              </span>
            </Content>
            <Button
              id="button_confirm"
              rounded
              variant="primary"
              size="large"
              onClick={actionOk}
              autoFocus
            >
              {translator('OK')}
            </Button>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default UserDataFulfill;
