import { takeLatest } from 'redux-saga/effects';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { ProfessionalTypes } from '~/domain/interfaces/redux/professionalList/types';

const tasks = [
  takeLatest(ProfessionalTypes.GETALL, onGetAll),
  takeLatest(ProfessionalTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(ProfessionalTypes.GETALL_FAILED, onGetAllFailed),
  takeLatest(ProfessionalTypes.CREATE, onCreate),
  takeLatest(ProfessionalTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(ProfessionalTypes.CREATE_FAILED, onCreateFailed),
];

export default tasks;
