import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 24px;
  /* grid-template-columns: 100% 30%; */
  padding: 24px 73px 52px;
  justify-content: space-between;
`;

export const RightBox = styled.div`
  width: 100%;
  height: 576px;
  border-radius: 8px;
  border: 1px solid #dedede;
  background: #fff;
`;

export const Text = styled.div`
  color: #222529;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 16px;
`;
