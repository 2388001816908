import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { DeleteProfessionalSpecialty as UsecaseRemoteDeleteProfessionalSpecialty } from '~/domain/usecases/professionalList/remote';

import { RemoteDeleteProfessionalSpecialty } from '~/data/repository/professionalList';

/**
 * send request via API.
 */
export const makeRemoteDeleteProfessionalSpecialtys =
  (): UsecaseRemoteDeleteProfessionalSpecialty =>
    new RemoteDeleteProfessionalSpecialty(
      makeApiUrl('/professionals'),
      makeHttpClient(),
    );
