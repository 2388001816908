import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { translator } from '../i18n';

import {
  Available,
  Canceled,
  Container,
  Finished,
  Progress,
  Scheduled,
  Confirmed,
} from './styles/StyledSchedule';

interface ScheduleProps {
  status?:
    | 'AVAILABLE'
    | 'ENDED'
    | 'CANCELED'
    | 'INPROGRESS'
    | 'SCHEDULED'
    | 'CONFIRMED'
    | 'EXPIRED';
}

const ScheduleStatus: React.FC<ScheduleProps> = ({ status }) => {
  function renderStatus() {
    switch (status) {
      case 'CONFIRMED':
        return (
          <Confirmed>
            <text>{translator('Confirmado')}</text>
          </Confirmed>
        );

      case 'AVAILABLE':
        return (
          <Available>
            <text>{translator('Disponível')}</text>
          </Available>
        );

      case 'ENDED':
        return (
          <Finished>
            <text>{translator('Finalizado')}</text>
          </Finished>
        );
      case 'CANCELED':
        return (
          <Canceled>
            <text>{translator('Cancelado')}</text>
          </Canceled>
        );
      case 'INPROGRESS':
        return (
          <Progress>
            <text>{translator('Em andamento')}</text>
          </Progress>
        );
      case 'SCHEDULED':
        return (
          <Scheduled>
            <text>{translator('Agendado')}</text>
          </Scheduled>
        );

      case 'EXPIRED':
        return (
          <Finished>
            <text>{translator('Expirado')}</text>
          </Finished>
        );
      default:
        return <Skeleton animation="wave" variant="text" width="100px" />;
    }
  }
  return <Container>{renderStatus()}</Container>;
};
export default ScheduleStatus;
