import { ListShifts } from '~/domain/usecases/shifts/redux';

import {
  ShiftsTypes,
  iActionListShifts,
  iActionListShiftsFailed,
  iActionListShiftsSuccess,
} from '~/domain/interfaces/redux/shifts/list';

export const listShiftsRequest = (
  payload: ListShifts.Params,
): iActionListShifts => ({
  type: ShiftsTypes.GETALL,
  payload,
});

export const listShiftsSuccess = (
  params: ListShifts.Model,
): iActionListShiftsSuccess => ({
  type: ShiftsTypes.GETALL_SUCCESS,
  payload: params,
});

export const listShiftsFailed = (): iActionListShiftsFailed => ({
  type: ShiftsTypes.GETALL_FAILED,
});
