import React, { useState } from 'react';
import {
  Container,
  Content,
  Footer,
  ContainerModal,
  Text,
  RadioContainer,
} from './styles/StyledModalSketchReporting';
import { Icon, Typography, Button } from '@wisecare/design-system-web';
import { iMessage } from '~/domain/interfaces/models';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { closeModal } from '~/utils/closeModal';
import { makeRemoteEvaluateDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/EvaluateDiagnosisReportFactory';
import { AlertMessage } from '../messages/AlertMessage';

interface ownProps {
  message: iMessage;
}

const ModalEvaluateReport: React.FC<ownProps> = ({ message }) => {
  const { active, actionCancel, actionOk, data } = message;

  const [selectedOption, setSelectedOption] = useState<
    'HIGH' | 'NORMAL' | 'LOW' | undefined
  >();

  const msgName = MessageOptions.evaluateReport;

  const handleSubmit = () => {
    if (!selectedOption) {
      AlertMessage({
        title: 'Dados obrigatórios',
        message: 'Selecione uma opção para prosseguir',
        type: 'danger',
      });
      return;
    }

    makeRemoteEvaluateDiagnosisReport()
      .evaluate({
        id: data?.id,
        body: {
          risk: selectedOption,
          report: data?.report,
        },
      })
      .then(() => {
        actionOk?.();
      })
      .catch(() => {
        AlertMessage({
          title: 'Erro',
          message: 'Erro ao avaliar o laudo',
          type: 'danger',
        });
      });
  };

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Content>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#E6F0FB',
                  borderRadius: '9999px',
                  aspectRatio: '1',
                  width: '40px',
                }}
              >
                <Icon name="info" color="blue-7" size="h5" />
              </div>
              <Text>
                <Typography
                  variant="t1_18semibold"
                  style={{ fontFamily: 'Roboto' }}
                >
                  Classificar o laudo
                </Typography>
                <Typography
                  variant="b1_16regular"
                  style={{
                    color: '#656A6E',
                    fontFamily: 'Roboto',
                    fontSize: '16px',
                  }}
                >
                  Antes de assinar, é necessário enviar para o profissional
                  solicitante o nível de risco desse laudo:
                </Typography>
              </Text>
              <RadioContainer>
                <input
                  type="radio"
                  name="option1"
                  id="option1"
                  value="HIGH"
                  checked={selectedOption === 'HIGH'}
                  onChange={e => {
                    setSelectedOption(
                      e.target.value as 'HIGH' | 'NORMAL' | 'LOW',
                    );
                  }}
                />
                <div>
                  <strong>Alto. </strong>
                  (Risco máximo e necessita de maior atenção)
                </div>
              </RadioContainer>
              <RadioContainer>
                <input
                  type="radio"
                  name="option2"
                  id="option2"
                  value="NORMAL"
                  checked={selectedOption === 'NORMAL'}
                  onChange={e => {
                    setSelectedOption(
                      e.target.value as 'HIGH' | 'NORMAL' | 'LOW',
                    );
                  }}
                />
                <div>
                  <strong>Médio. </strong>
                  (Risco moderado para o paciente)
                </div>
              </RadioContainer>
              <RadioContainer>
                <input
                  type="radio"
                  name="option3"
                  id="option3"
                  value="LOW"
                  checked={selectedOption === 'LOW'}
                  onChange={e => {
                    setSelectedOption(
                      e.target.value as 'HIGH' | 'NORMAL' | 'LOW',
                    );
                  }}
                />
                <div>
                  <strong>Baixo. </strong>
                  (Não apresenta riscos para o paciente)
                </div>
              </RadioContainer>
            </Content>
            <Footer>
              <Button variant="secondary" onClick={actionCancel && closeModal}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={!selectedOption}
              >
                Ir para assinatura
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalEvaluateReport;
