import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 150px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
`;

export const Body = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 27em;
  max-height: 15em;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
`;

export const RegisterBody = styled.div`
  position: fixed;
  background: white;
  padding: 0px;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 26em;
  max-height: 24em;
  width: 60%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;

  span {
    width: 70%;
    margin-top: 27px;
    color: #7a7a7a;
    font-size: 14px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 120px;
  text-align: center;

  p {
    color: black;
    font-size: 18px;
    font-weight: bold;

    width: 230px;
    margin-left: 18px;
    text-align: center;
    text-overflow: ellipsis;
  }
`;

export const Text = styled.p`
  width: 70%;
  font-size: 17px;
  margin-top: 15px;
  text-align: center;
  text-overflow: ellipsis;
`;
