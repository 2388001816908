import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAppointmentType as UsecaseRemoteGetAppointmentType } from '~/domain/usecases/appointmentType/remote';
import { GetAllAppointmentType as UsecaseReduxGetAllAppointmentType } from '~/domain/usecases/appointmentType/redux';

import { RemoteGetAppointmentType } from '~/data/repository/appointmentType/RemoteGetAppointmentType';
import { ReduxGetAllAppointmentType } from '~/data/store/reducer/appointmentType/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAppointmentType =
  (): UsecaseRemoteGetAppointmentType =>
    new RemoteGetAppointmentType(
      makeApiUrl('/appointmentType/'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxGetAllAppointmentType =
  (): UsecaseReduxGetAllAppointmentType => new ReduxGetAllAppointmentType();
