/* eslint-disable @typescript-eslint/no-empty-function */
import { call, put } from 'redux-saga/effects';
import { iActionDeleteShifts } from '~/domain/interfaces/redux/shifts/delete';

import { DeleteShiftsById } from '~/domain/usecases/shifts/remote';

import {
  deleteShiftsByIdFailed,
  deleteShiftsByIdSuccess,
} from '~/data/store/reducer/shifts/actions/delete';

import {
  makeReduxListShifts,
  makeRemoteDeleteShiftsById,
} from '~/main/factories/usecases/shifts';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import storeDev from '../..';

export function* onDeleteShifts(action: iActionDeleteShifts) {
  const remoteDeleteShifts = makeRemoteDeleteShiftsById();

  try {
    const response: DeleteShiftsById.Model = yield call(
      remoteDeleteShifts.delete,
      action.payload,
    );

    yield put(deleteShiftsByIdSuccess(response));
  } catch (e) {
    yield put(deleteShiftsByIdFailed());
  }
}

export function onDeleteShiftsSuccess() {
  makeReduxListShifts().list({
    onCallId: storeDev.getState().shifts.onCallId,
    body: {
      date: storeDev.getState().shifts.date,
    },
  });
}

export function onDeleteShiftsFailed() {
  AlertMessage({
    message: 'Não foi possível excluir a escala.',
    type: 'danger',
  });
}
