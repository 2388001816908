import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllByCodeRequest } from '~/data/store/reducer/clinicalDocs/actions/getAllByCode';

import { iActionGetAllByCode } from '~/domain/interfaces/redux/clinicalDocs/getAllByCode';

import { GetAllClinicalDocsByCode } from '~/domain/usecases/clinicalDocs/redux';

export class ReduxGetAllClinicalDocsByCode implements GetAllClinicalDocsByCode {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  getAll = (params: GetAllClinicalDocsByCode.Params): iActionGetAllByCode =>
    this.send(getAllByCodeRequest(params));
}
