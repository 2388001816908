import styled from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const selectStyles = () => ({
  control: () => ({
    display: 'flex',
    alignItems: 'center',
    height: '46px',
    border: '1px solid #bfc4ca',
    boxShadow: 'none',
    borderRadius: '4px',
    background: '#fff',
    paddingLeft: '8px',

    '&:hover': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:focus': {
      borderColor: `${defaultTheme.primary}`,
    },

    '&:disabled': {
      backgroundColor: '#fafafb',

      '&:hover': {
        borderColor: '#bfc4ca',
      },
    },
  }),
  dropdownIndicator: (props: any) => ({
    ...props,
    color: 'inherit',
  }),
  input: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  placeholder: (props: any) => ({
    ...props,
    color: '#444a51',
  }),
  menu: (props: any) => ({
    ...props,
    zIndex: 2,
  }),
});

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.form`
  width: 854px;

  border-radius: 16px;
  border: 1px #dedede solid;
  background-color: white;
`;

export const HeaderModal = styled.div`
  padding: 16px 24px;
`;

export const Title = styled.div`
  color: #222529;
  font-size: 18px;
  font-family: Roboto;
  font-weight: 600;
  word-wrap: break-word;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;

  background-color: #dedede;
`;

export const PatientInfo = styled.div`
  width: 50%;
  border-right: 1px #f8f8f8 solid;
  padding: 24px;
`;

export const InformationTitle = styled.div`
  color: #636d77;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const InputContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 16px;
`;

export const InlineInputs = styled.div`
  display: flex;
  gap: 18px;
  align-items: first baseline;
`;

export const Content = styled.div`
  display: flex;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const AppointmentInfo = styled.div`
  width: 50%;
  padding: 24px;
`;

export const SubTitle = styled.div`
  color: #62666c;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
  word-wrap: break-word;
`;

export const BoxIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  border-radius: 5px;
  border: 1px #dedede solid;

  cursor: pointer;
`;

export const Box = styled.div`
  padding: 16px 0 16px 24px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 24px;
`;

export const TextAreaForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h3 {
    color: #444a51;
    font-size: 14px;
    margin-bottom: 4px;
  }

  textarea {
    resize: none;
    width: 400px;
    height: 120px;
    border-radius: 6px;
    border: 1px solid #b4b7b9;
    background: #fdfdfd;
    padding: 12px;

    ::placeholder {
      color: #818385;
    }
  }
`;

export const MessageError = styled.span`
  color: red;
  font-size: 12px;
`;
