/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '~/presentation/components/UI';
import {
  ConsultAsset,
  IconCopyInterconsulta,
  IconCopyLink,
  IconCopyLinkHover,
  IconSuccessIcon,
  IconSuccessInterconsult,
} from '~/presentation/base/icons';

import { translator } from '../i18n'; // TODO: check this component translation

import {
  Body,
  Container,
  ContainerModal,
  Footer,
  Header,
  HeaderTitle,
  LinkContainer,
  TextBold,
  TextBoldTitle,
  TextNormal,
} from './styles/styles';
import ConsultDetailFragment from './ConsultDetailFragment';
import { iStore } from '~/domain/interfaces/models';
import { iDutyRequesters } from '~/domain/interfaces/models/Duty';
import { makeRemoteRefuseAppointmentOnCall } from '~/main/factories/usecases/onCall/RefuseAppointmentOnCallFactory';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';

interface propsModalEnterInterconsult {
  message: iMessage;
}

const ModalEnterInterconsult: React.FC<propsModalEnterInterconsult> = ({
  message,
}) => {
  const [professionalName, setProfessionalName] = useState(
    'Manoel de Souza Lopes',
  );
  const [professionalSpecialty, setProfessionalSpecialty] =
    useState('Cardiologia');
  const [patient, setPatient] =
    useState<
      iDutyRequesters['dutyRequesters']['records'][0]['requester']['info']
    >();
  const { dutyRequesters, professionalOnDuty } = useSelector(
    (state: iStore) => state.duty,
  );

  const { active, actionOk, actionCancel, data } = message;

  useEffect(() => {
    if (data) {
      const requester = dutyRequesters?.records?.find(
        item => item.requester.id === data.requester,
      );

      const specialist = professionalOnDuty?.records?.find(
        item => item.list.specialist.id === data.specialist,
      );

      if (data.type === 'SPECIALIST' && requester) {
        setProfessionalName(requester.requester.name);
      } else if (data.type === 'REQUESTER' && specialist) {
        setProfessionalName(specialist.list.specialist.name);
      }

      setPatient(requester?.requester?.info);
      setProfessionalSpecialty(data.onCall?.descr);
    }
  }, [data, dutyRequesters?.records, professionalOnDuty?.records]);

  const handleCancel = () => {
    if (data?.type === 'SPECIALIST')
      makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
        available: false,
      });

    makeRemoteRefuseAppointmentOnCall().refuseAppointment({
      onCallId: Number(data?.onCall?.id),
      requesterId: Number(data?.requester),
      specialistId: Number(data?.specialist),
    });
    actionCancel?.();
  };

  const onSubmit = () => {
    actionOk?.();
  };

  const isEspecialist = data.type === 'SPECIALIST';

  const renderSubtitle = useMemo(() => {
    return data.type === 'SPECIALIST'
      ? `O profissional ${professionalName} solicitou 
    uma interconsulta no plantão de ${professionalSpecialty} para discutir o 
    caso do paciente abaixo`
      : `O profissional ${professionalName} aceitou sua solicitação para discutir o caso do paciente abaixo:`;
  }, [professionalName, professionalSpecialty, data]);

  const msgName = MessageOptions.enterInterconsult;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              {!isEspecialist && <IconSuccessInterconsult />}
              <HeaderTitle>
                <div className="title">
                  {isEspecialist
                    ? ` Entrar na interconsulta do plantão virtual`
                    : 'Sua interconsulta no plantão virtual foi criada'}
                </div>
                <div className="text">
                  {isEspecialist
                    ? `O profissional solicitante será notificado do seu aceite.
                  Envie o link abaixo para o profissional que estará com você no
                  atendimento.`
                    : `Copie o link abaixo e envie para o profissional que estará com você no atendimento.`}
                </div>
              </HeaderTitle>
            </Header>
            <Body>
              <LinkContainer>
                <div className="label">
                  {`Link para o profissional${
                    !isEspecialist ? ' convidado' : ''
                  }`}
                </div>
                <div className="link">
                  https://app.medicai.com.br/interconsulta/1234567890
                </div>
              </LinkContainer>
              <Button
                variant="secundary"
                size="small"
                icon={IconCopyInterconsulta}
                onClick={() => {
                  navigator.clipboard.writeText(
                    'https://app.medicai.com.br/interconsulta/1234567890',
                  );
                }}
              >
                Copiar link
              </Button>
            </Body>
            <Footer>
              <Button
                variant="secundary"
                size="bigger"
                onClick={() => {
                  handleCancel();
                }}
              >
                {translator('Cancelar')}
              </Button>
              <Button variant="primary" size="bigger" onClick={onSubmit}>
                {translator('Entrar na interconsulta')}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalEnterInterconsult;
