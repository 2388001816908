import { GetAllOrganizer } from '~/domain/usecases/organizer/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  BadRequestError,
  UnexpectedError,
  Forbidden,
} from '~/domain/errors';

export class RemoteGetAllOrganizer implements GetAllOrganizer {
  private readonly url: string;

  private readonly httpCLient: HttpClient<GetAllOrganizer.Model>;

  constructor(url: string, httpClient: HttpClient<GetAllOrganizer.Model>) {
    this.url = url;
    this.httpCLient = httpClient;
  }

  getAll = async (
    params: GetAllOrganizer.Params,
  ): Promise<GetAllOrganizer.Model> => {
    const httpResponse = await this.httpCLient.request({
      url: `${this.url}`,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
