/* eslint-disable react/react-in-jsx-scope */

import React, { useEffect, useState } from 'react';
import {
  AnamneseEmptyStateIllus,
  IconCameraOff,
  IconCameraOn,
  IconMicrophoneOff,
  IconMicrophoneOn,
  NewsEmptyStateIllus,
  VideosEmptyStateIllus,
} from '~/presentation/base/icons';

import { NavigationBar } from '../navigationBar';
import {
  WaitingRoomContentBody,
  WaitingRoomContentBodyContent,
  WaitingRoomContentFooter,
  WaitingRoomContentFooterButton,
  WaitingRoomContentFooterButtons,
  WaitingRoomContentHeader,
  WaitingRoomContentStyle,
} from './styles';

type Tabs = 'Configurações' | 'Anamnese' | 'Notícias' | 'Vídeos';

type WaitingRoomContentProps = {
  isMicOn: boolean;
  isCamOn: boolean;
  startCamera: () => void;
  stopCamera: () => void;
  toggleWebcam: () => void;
  stopMic: () => void;
  toggleMic: () => void;
};

const WaitingRoomContent: React.FC<WaitingRoomContentProps> = ({
  isCamOn,
  isMicOn,
  startCamera,
  toggleMic,
  toggleWebcam,
}) => {
  const [tabs, setTabs] = useState<Tabs[]>([
    'Configurações',
    // 'Anamnese',
    // 'Notícias',
    // 'Vídeos',
  ]);

  const [tabActive, setTabActive] = useState<Tabs>(tabs[0]);

  useEffect(() => {
    if (isCamOn) startCamera();
  }, [startCamera, tabActive]);

  return (
    <WaitingRoomContentStyle>
      <WaitingRoomContentHeader>
        <NavigationBar
          labels={tabs}
          active={tabActive}
          clickLabel={setTabActive}
        />
      </WaitingRoomContentHeader>
      <WaitingRoomContentBody>
        {isCamOn && tabActive === 'Configurações' ? (
          <video autoPlay id="webcam">
            <track kind="captions" />
          </video>
        ) : (
          <ContentTabComponent tabActive={tabActive} />
        )}
      </WaitingRoomContentBody>
      {tabActive === 'Configurações' && (
        <WaitingRoomContentFooter>
          <WaitingRoomContentFooterButtons>
            <WaitingRoomContentFooterButton
              isActive={isMicOn}
              onClick={toggleMic}
              type="button"
            >
              {isMicOn ? <IconMicrophoneOn /> : <IconMicrophoneOff />}
            </WaitingRoomContentFooterButton>
            <WaitingRoomContentFooterButton
              isActive={isCamOn}
              onClick={toggleWebcam}
              type="button"
            >
              {isCamOn ? <IconCameraOn /> : <IconCameraOff />}
            </WaitingRoomContentFooterButton>
          </WaitingRoomContentFooterButtons>
        </WaitingRoomContentFooter>
      )}
    </WaitingRoomContentStyle>
  );
};

export default WaitingRoomContent;

type ContentTabProps = {
  tabActive: Tabs;
};

type ContentTab = Record<
  Exclude<Tabs, 'Configurações'>,
  { message?: string; image: JSX.Element }
>;

const ContentTabComponent: React.FC<ContentTabProps> = ({ tabActive }) => {
  const content: ContentTab = {
    Anamnese: {
      message: 'O profissional não cadastrou nenhuma anamnese',
      image: <AnamneseEmptyStateIllus />,
    },

    Notícias: {
      message: 'Nenhuma notícia foi cadastrada até o momento ',
      image: <NewsEmptyStateIllus />,
    },

    Vídeos: {
      message: 'Nenhum vídeo foi cadastrado até o momento',
      image: <VideosEmptyStateIllus />,
    },
  };

  return tabActive !== 'Configurações' ? (
    <WaitingRoomContentBodyContent style={{ backgroundColor: '#FDFDFD' }}>
      {content[tabActive].image}
      <p>{content[tabActive].message}</p>
    </WaitingRoomContentBodyContent>
  ) : null;
};
