import { GetAllSupportDocs } from '~/domain/usecases/supportDoc/redux';
import {
  iActionGetAll,
  iActionGetAllFailed,
  iActionGetAllSuccess,
  SupportTypes,
} from '~/domain/interfaces/redux/supportDoc/getAll';

export const getAllRequest = (
  payload: GetAllSupportDocs.Params,
): iActionGetAll => ({
  type: SupportTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  Params: GetAllSupportDocs.Model,
): iActionGetAllSuccess => ({
  type: SupportTypes.GETALL_SUCCESS,
  payload: Params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: SupportTypes.GETALL_FAILED,
});
