import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AppointmentReportPdf as UsecaseRemoteAppointmentReportPdf } from '~/domain/usecases/report/remote';

import { RemoteAppointmentReportPdf } from '~/data/repository/report';

/**
 * send request via API.
 */
export const makeRemoteAppointmentReportPdf =
  (): UsecaseRemoteAppointmentReportPdf =>
    new RemoteAppointmentReportPdf(
      makeApiUrl('/reports/appointments/pdf'),
      makeHttpClient(),
    );
