import { takeLatest } from 'redux-saga/effects';
import { ProfessionTypes } from '~/domain/interfaces/redux/profession/types';
import { onGetAllRequest, onGetAllSuccess, onGetAllFailed } from './GetAll';
import {
  onProfessionalList,
  onProfessionalListSuccess,
  onProfessionalListFailed,
} from './ProfessionList';

const tasks = [
  takeLatest(ProfessionTypes.GETALL, onGetAllRequest),
  takeLatest(ProfessionTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(ProfessionTypes.GETALL_FAILED, onGetAllFailed),
  takeLatest(ProfessionTypes.PROFESSION_LIST, onProfessionalList),
  takeLatest(
    ProfessionTypes.PROFESSION_LIST_SUCCESS,
    onProfessionalListSuccess,
  ),
  takeLatest(ProfessionTypes.PROFESSION_LIST_FAILED, onProfessionalListFailed),
];

export default tasks;
