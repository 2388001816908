import styled from 'styled-components';

export const ContainerFooter = styled.form`
  width: 300px;
  height: auto;
  max-width: 300px;
  max-height: 400px;
  border-radius: 5px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.07);
  input {
    border: 0;
    padding: 15px;
  }
  button {
    border: 0;
    background: transparent;
    margin-right: 10px;
    cursor: pointer;
  }
`;
