import { GetInvoiceByOrg } from '~/domain/usecases/billing/redux';
import {
  BillingTypes,
  iActionGetInvoiceByOrg,
  iActionGetInvoiceByOrgFailed,
  iActionGetInvoiceByOrgSuccess,
} from '~/domain/interfaces/redux/billing/getInvoiceByOrg';

export const getRequest = (
  payload: GetInvoiceByOrg.Params,
): iActionGetInvoiceByOrg => ({
  type: BillingTypes.GET_INVOICE_BY_ORG,
  payload,
});

export const getSuccess = (
  params: GetInvoiceByOrg.Model,
): iActionGetInvoiceByOrgSuccess => ({
  type: BillingTypes.GET_INVOICE_BY_ORG_SUCCESS,
  payload: params,
});

export const getFailed = (): iActionGetInvoiceByOrgFailed => ({
  type: BillingTypes.GET_INVOICE_BY_ORG_FAILED,
});
