import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { iStore } from '~/domain/interfaces/models';
import { makeReduxAddAppointmentData } from '~/main/factories/usecases/appointment/AddAppointmentDataFactory';

import { ownProps } from '../DateAndTime';

export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { appointment } = useSelector((state: iStore) => state);

    Component.defaultProps = {
      state: appointment,
      actionAppointment: makeReduxAddAppointmentData(),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}
