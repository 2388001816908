import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { PostPasswordRecovery as UsecaseRemotePostPasswordRestory } from '~/domain/usecases/password/remote';

import { RemotePostPasswordRecovery } from '~/data/repository/password/RemotePostPasswordRecovery';

/**
 * send request via API.
 */

export const makeRemotePostPasswordRecovery =
  (): UsecaseRemotePostPasswordRestory =>
    new RemotePostPasswordRecovery(
      makeApiUrl('/password/recovery'),
      makeHttpClient(),
    );
