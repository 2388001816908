import { object, number, string, boolean } from 'zod';

export const schemaGeneralData = object({
  professional: number().nonnegative({
    message: 'Profissional inválido ou não encontrado.',
  }),
  appointment: number().nonnegative().optional(),
  // patient: number().nonnegative({
  //   message: 'Consultante inválido ou não encontrado.',
  // }),
  org: number().nonnegative({ message: 'Instituição Inválida' }),
  orgUnit: number().nonnegative().optional(),
});

export const schemaPacientData = object({
  // id: number().optional(),
  situation: string().nonempty().optional(),
  birthDate: string()
    .nonempty({ message: 'Insira a data de nascimento' })
    .optional(),
  patient: object({
    fullname: string().optional(),
    gender: string().optional(),
    age: number().nonnegative().optional(),
    id: number().optional(),
  }),
})
  .refine(
    data => {
      // if (!data.patient.id && data.situation === 'REGISTERED') {
      if (data.patient.id === -1 && data.situation === 'REGISTERED') {
        return false;
      }

      return true;
    },
    { message: 'Paciente inválido ou não encontrado.', path: ['patient.id'] },
  )
  .refine(
    data => {
      if (
        !data.birthDate &&
        // typeof data.patient.age === 'number' &&
        data.situation === 'NOT-REGISTERED'
      ) {
        return false;
      }
      return true;
    },
    { message: 'Preencha os campos obrigatórios.', path: ['birthDate'] },
  )
  .refine(
    data => {
      if (!data.patient.fullname && data.situation === 'NOT-REGISTERED') {
        return false;
      }
      return true;
    },
    { message: 'Preencha os campos obrigatórios.', path: ['patient.fullname'] },
  );

export const schema = object({
  appointment: number().nonnegative().optional(),
  org: number().nonnegative().optional(),
  orgUnit: number().nonnegative().optional(),
  professional: number().nonnegative().optional(),
  specialty: number().nonnegative().optional(),
  patient: number().nonnegative().optional(),
  type: number().nonnegative(),
  certificate: string().optional(),
  certificateId: string().optional(),
  sidebar: boolean().optional(),
  content: object({}).nonstrict().optional(),
  signatureSource: string().optional(),
}).refine(data => {
  // if ((!data.appointment && (!data.professional || !data.specialty || !data.org || !data.orgUnit))) return false;
  if (!data.appointment && (!data.professional || !data.org || !data.orgUnit))
    return false; // avulso
  if (
    data.appointment &&
    (data.professional || data.specialty || data.org || data.orgUnit)
  )
    return false; // vinculado
  return true;
});
