import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { ClearConsultant as UsecaseReduxClearConsultant } from '~/domain/usecases/consultant/redux';

import { ReduxClearConsultant } from '~/data/store/reducer/Consultant/usecases';

/**
 * send request via REDUX.
 */
export const makeReduxClearConsultant = (): UsecaseReduxClearConsultant =>
  new ReduxClearConsultant();
