import { makeApiUrl, makeHttpClient } from '~/main/factories/http';

import { CreateProfessionalSpecialty as UsecaseRemoteCreateProfessionalSpecialty } from '~/domain/usecases/professionalList/remote';

import { RemoteCreateProfessionalSpecialty } from '~/data/repository/professionalList';

/**
 * send request via API.
 */
export const makeRemoteCreateProfessionalSpecialtys =
  (): UsecaseRemoteCreateProfessionalSpecialty =>
    new RemoteCreateProfessionalSpecialty(
      makeApiUrl('/professionals'),
      makeHttpClient(),
    );
