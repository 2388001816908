import { call, put, select } from 'redux-saga/effects';
import { iActionCreate } from '~/domain/interfaces/redux/specialty/create';

export function* onCreateRequest(action: iActionCreate): Generator {
  yield 10;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onCreateFailed() {}
