import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllSpecialty as UsecaseReduxGetAllSpecialty } from '~/domain/usecases/specialty/redux';
import { GetAllSpecialty as UsecaseRemoteGetAllSpecialty } from '~/domain/usecases/specialty/remote';

import { RemoteGetAllSpecialty } from '~/data/repository/specialty';
import { ReduxGetAllSpecialty } from '~/data/store/reducer/specialty/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllSpecialty = (): UsecaseRemoteGetAllSpecialty =>
  new RemoteGetAllSpecialty(makeApiUrl('/specialties/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllSpecialty = (): UsecaseReduxGetAllSpecialty =>
  new ReduxGetAllSpecialty();
