import React from 'react';

import { ButtonsDiv, Container, CloseButton, Header } from './styles';
import { Body, Content } from './styles/StyledUserDataFulfill';

import { Button } from '../../UI';

import { ownProps } from '../interface';

import { MessageOptions } from '~/domain/interfaces/redux/message';
import { translator } from '../../i18n';

const UserDataFulfill: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.userDataFulfill;

  const { active, actionCancel, actionOk } = message;

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>{translator('Preenchimento de dados')}</span>
              <CloseButton onClick={actionCancel}>X</CloseButton>
            </Header>

            <Content>
              {translator(
                'Identificamos que o usuário já está cadastrado na plataforma. Deseja preencher automaticamente os campos com os seus dados?',
              )}
            </Content>
            <ButtonsDiv>
              <Button
                rounded
                variant="secundary"
                color="red"
                size="small"
                onClick={actionCancel}
              >
                {translator('Cancelar')}
              </Button>
              <Button
                rounded
                variant="primary"
                size="small"
                onClick={actionOk}
                autoFocus
              >
                {translator('OK')}
              </Button>
            </ButtonsDiv>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default UserDataFulfill;
