import React, { useState, useRef } from 'react';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';
import {
  ListItem,
  DrugAndDosage,
  DosageAndButton,
  ContainerItemPrescription,
} from './styles/StyledItemPrescription';
import MoreOptions from './MoreOptions';
import { More } from '~/presentation/components/UI/popover';
import { iPrescription } from './interface';
import { AlertMessage } from '../messages/AlertMessage';
import { translator } from '../i18n';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

interface ownProps {
  content?: Array<iPrescription>;
  setContent?: Function;
  updatePrescription?: Function;
  setUpdateButton?: Function;
}

const ItemPrescription: React.FC<ownProps> = ({
  content,
  setContent,
  updatePrescription,
  setUpdateButton,
}) => {
  function deletePrescription(
    arrPrescription: Array<iPrescription>,
    id: number,
  ) {
    setContent!(arrPrescription.filter(item => item.id !== id));
  }

  return (
    <ContainerItemPrescription>
      {content?.map(item => {
        return (
          <ListItem key={item.id}>
            <DrugAndDosage>
              <h1>{item.name}</h1>
              <h2>{item.posology}</h2>
            </DrugAndDosage>
            <DosageAndButton>
              <h1>{item.dosage}</h1>
              <More
                content={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <MoreOptions
                    actionDelete={() => deletePrescription(content, item.id!)}
                    actionEdit={() => {
                      updatePrescription!(item.id!);
                      setUpdateButton!(true);
                      AlertMessage({
                        message: intl.formatMessage({
                          id: 'Atualize os dados da sua prescrição.',
                        }),
                        type: 'info',
                      });
                    }}
                  />
                }
                positionY="top"
                positionX="left"
              />
            </DosageAndButton>
          </ListItem>
        );
      })}
    </ContainerItemPrescription>
  );
};

export default ItemPrescription;
