import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background: ${props => props.theme.background};
  overflow: auto;
  position: relative;
`;

export const Body = styled.div`
  /* margin-top: 40px; */
  width: 98%;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 300px 1fr;

  #left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #right {
    overflow-x: scroll;

    scrollbar-width: none;
  }

  #header {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
  }
`;

export const Chat = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  color: #7a7a7a;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const IconBalon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;

export const HistoryComponent = styled.div`
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
`;

export const Attendiment = styled.div`
  width: 25px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
`;

export const ButtonBig = styled.div`
  margin-left: 10px;
  padding: 10px;
  .btnAction {
    width: 200px;
  }
`;
export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;
