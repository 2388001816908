import React from 'react';
import { Barra, Container } from './styles/StyledBar';

interface ownProps {
  score: number;
  num: number;
}

const Bar: React.FC<ownProps> = ({ score, num }) => {
  return (
    <Container>
      <Barra scores={score} width={`${num}%`} />
    </Container>
  );
};

export default Bar;
