import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { CreateUser as UsecaseRemoteCreateUser } from '~/domain/usecases/users/remote';
import { CreateUser as UsecaseReduxCreateUser } from '~/domain/usecases/users/redux';

import { RemoteCreateUser } from '~/data/repository/users';
import { ReduxCreateUser } from '~/data/store/reducer/users/usecases';

/**
 * send request via API.
 */
export const makeRemoteCreateUser = (): UsecaseRemoteCreateUser =>
  new RemoteCreateUser(makeApiUrl('/users'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxCreateUser = (): UsecaseReduxCreateUser =>
  new ReduxCreateUser();
