import React, { useCallback, useState } from 'react';
import { GetUserDataWaitingRoom } from '~/domain/usecases/waitingRoom/remote';
import {
  ArrowLeftCarousel,
  ArrowRightCarousel,
} from '~/presentation/base/icons';
import { formattedUrl } from '~/utils/formattedUrl';

import {
  ArrowContainer,
  CarouselContainer,
  CarouselItem,
  Circle,
  Container,
  ContainerCircle,
  Content,
} from './styles';

interface WaitingRoomCarouselProps {
  medias: GetUserDataWaitingRoom.Model['medias'];
}

const WaitingRoomCarousel: React.FC<WaitingRoomCarouselProps> = ({
  medias,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const changeImage = useCallback(
    (index: number) => {
      if (index < 0 || index > medias.length - 1) return;

      setCurrentIndex(index);
    },
    [medias.length],
  );

  return (
    <Container>
      <Content>
        <ArrowContainer
          disabled={currentIndex === 0}
          onClick={() => changeImage(currentIndex - 1)}
        >
          <ArrowLeftCarousel />
        </ArrowContainer>
        <CarouselContainer>
          {medias.map(item => (
            <CarouselItem
              key={item.id}
              style={{ translate: `-${currentIndex * 100}%` }}
              src={formattedUrl(item.path)}
            />
          ))}
          {medias.length === 0 && (
            <CarouselItem
              style={{ translate: `-${currentIndex * 100}%` }}
              src="https://www.youtube.com/embed/VbfFmmdM_vY"
            />
          )}
        </CarouselContainer>
        <ArrowContainer
          onClick={() => changeImage(currentIndex + 1)}
          disabled={currentIndex === medias.length - 1}
        >
          <ArrowRightCarousel />
        </ArrowContainer>
      </Content>
      <ContainerCircle>
        {medias.map((item, index) => (
          <Circle key={item.id} active={index === currentIndex} />
        ))}
      </ContainerCircle>
    </Container>
  );
};

export default WaitingRoomCarousel;
