import { Dispatch } from 'redux';
import store from '~/data/store';
import { iActionSetup } from '~/domain/interfaces/redux/profession/setupProfession';
import { SetupProfession } from '~/domain/usecases/profession/redux';
import { setupProfession } from '../actions/SetupProfession';

export class ReduxSetupProfession implements SetupProfession {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  setup = (params: SetupProfession.Params): iActionSetup =>
    this.send(setupProfession(params));
}
