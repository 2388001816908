import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetBillingService as UsecaseRemoteGetBillingService } from '~/domain/usecases/billing/remote';
import { GetBillingService as UseCaseReduxGetBillingService } from '~/domain/usecases/billing/redux';

import { RemoteGetBillingService } from '~/data/repository/billing';
import { ReduxGetBillingService } from '~/data/store/reducer/billing/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetBillingService = (): UsecaseRemoteGetBillingService =>
  new RemoteGetBillingService(makeApiUrl('/orgs'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetBillingService = (): UseCaseReduxGetBillingService =>
  new ReduxGetBillingService();
