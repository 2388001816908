import { ListOnCallRequester } from '~/domain/usecases/onCallRequester/redux/ListOnCallRequester';
import {
  iActionList,
  iActionListFailed,
  iActionListSuccess,
  OnCallRequesterTypes,
} from '~/domain/interfaces/redux/onCallRequester/list';

export const listRequest = (
  payload: ListOnCallRequester.Params,
): iActionList => ({
  type: OnCallRequesterTypes.LIST,
  payload,
});

export const listSuccess = (
  params: ListOnCallRequester.Model,
): iActionListSuccess => ({
  type: OnCallRequesterTypes.LIST_SUCCESS,
  payload: params,
});

export const listFailed = (): iActionListFailed => ({
  type: OnCallRequesterTypes.LIST_FAILED,
});
