import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetClinicalDocs as UseCaseRemoteGetClinicalDocs } from '~/domain/usecases/clinicalDocs/remote';
import { GetClinicalDocs as UsecaseReduxGetClinicalDocs } from '~/domain/usecases/clinicalDocs/redux';

import { RemoteGetClinicalDocs } from '~/data/repository/clinicalDocs';
import { ReduxGetClinicalDocsById } from '~/data/store/reducer/clinicalDocs/usecases';

export const makeRemoteGetClinicalDocs = (): UseCaseRemoteGetClinicalDocs =>
  new RemoteGetClinicalDocs(makeApiUrl('/clinicalDocs'), makeHttpClient());

export const makeReduxGetClinicalDocs = (): UsecaseReduxGetClinicalDocs =>
  new ReduxGetClinicalDocsById();
