/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
import { Tooltip } from '@material-ui/core';

import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { iStore } from '~/domain/interfaces/models';
import { iOnCall } from '~/domain/interfaces/models/OnCall';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { makeRemoteAcceptOnCallSpecialist } from '~/main/factories/usecases/onCallSpecialist/AcceptOnCallSpecialistFactory';
import {
  IconExcludeRequest,
  IconFlag,
  IconMore,
  IconPenGray,
  NewIconDelete,
} from '~/presentation/base/icons';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { Popover } from '~/presentation/components/UI/popover';
import { calculateAgeAndMonths } from '~/utils/calculateAgeAndMonths';
import { closeModal } from '~/utils/closeModal';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { priorityMap } from '~/utils/mapPriority';
import { waitingColumns, waitingColumnsProfessional } from './columns';
import {
  ActionButton,
  ActionButtonContainer,
  CheckIcon,
  ContainerPopover,
  EmptyList,
  Priority,
  ProfessionalBadge,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
  Table,
  Td,
  Th,
  Title,
  Tr,
} from './styles';

type Row = iOnCallRequest['records'][0];

type SelectedRequest = Row | null;

type PatientDutyListProps = {
  selectedRequest: SelectedRequest;
  setSelectedRequest: (requester: SelectedRequest) => void;
  variant?: 'REQUESTER' | 'PROFESSIONAL';
  healthUnitId?: number;
};

export const WaitingService: React.FC<PatientDutyListProps> = ({
  setSelectedRequest,
  selectedRequest,
  variant,
  healthUnitId,
}) => {
  const { records } = useSelector((store: iStore) => store.onCallRequest);
  const specialistStatus = useSelector((store: iStore) => store.onCall);

  const recordsFiltered = records?.filter(item => {
    return (
      item.status === 'WAITING' &&
      (!healthUnitId || item.healthUnit.id === healthUnitId)
    );
  });

  const hasRequests = recordsFiltered?.length;

  return (
    <div
      style={{
        maxHeight: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Table>
        <thead>
          <tr>
            {variant === 'REQUESTER' ? (
              <>
                {waitingColumns.map(column => (
                  <Th key={column.id}>{column.label}</Th>
                ))}
              </>
            ) : (
              <>
                {waitingColumnsProfessional.map(column => (
                  <Th key={column.id}>{column.label}</Th>
                ))}
              </>
            )}
          </tr>
        </thead>

        <tbody>
          {recordsFiltered?.map(row => (
            <TableRowItem
              key={row.id}
              row={row}
              active={row.id === selectedRequest?.id}
              variant={variant}
              onClick={() => setSelectedRequest(row)}
              healthUnitId={healthUnitId}
              setSelectedRequest={setSelectedRequest}
              specialistStatus={specialistStatus}
            />
          ))}
        </tbody>
      </Table>
      {!hasRequests && (
        <EmptyList>Você não tem solicitações pendentes.</EmptyList>
      )}
    </div>
  );
};

type TableRowItemProps = {
  row: Row & { active?: boolean };
  active?: boolean;
  variant?: 'REQUESTER' | 'PROFESSIONAL';
  healthUnitId?: number;
  onClick: () => void;
  specialistStatus: iOnCall;
  setSelectedRequest: (requester: SelectedRequest) => void;
};

const TableRowItem: React.FC<TableRowItemProps> = ({
  row,
  variant,
  healthUnitId,
  setSelectedRequest,
  specialistStatus,
  ...rest
}) => {
  const { consultant, onCall, requester, healthUnit } = row;

  const { pathname } = useLocation();
  const last = pathname.split('/').pop();
  const id = last === 'me' ? undefined : Number(last);

  const { accepted } = specialistStatus;

  const getStatus = (): 'WAITING' | 'CHECK' | 'DISABLED' => {
    if (accepted?.state) {
      return accepted.request === row.id ? 'CHECK' : 'DISABLED';
    }
    return 'WAITING';
  };

  const professional = getProfessionalInfo();

  const isMyRequest = row.requester.id === Number(professional?.id);

  const created = Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
    .format(new Date(row.created))
    .replace(', ', ', às ');

  const contentPopover = (
    <ContainerPopover
      onClick={() =>
        makeReduxActiveMessage().active({
          active: 'requestConsultation',
          actionCancel: () => {
            closeModal();
          },
          userId: row.id,
          data: {
            isEditing: true,
            onSuccess: () => {
              makeReduxListOnCallRequest().list({
                body: {
                  dataControl: {
                    limit: 9999,
                  },
                  filters: {
                    requester: professional?.id,
                    status: 'WAITING',
                    healthUnit: healthUnitId,
                    onCall: id,
                  },
                },
              });

              // TODO: SelectedRequester deveria ser id para evitar remote
              makeRemoteListOnCallRequest()
                .list({
                  body: {
                    filters: {
                      id: Number(row?.id),
                    },
                  },
                })
                .then(res => {
                  setSelectedRequest(res.records[0]);
                })
                .catch(() => {
                  AlertMessage({
                    message: 'Erro ao buscar solicitação!',
                    type: 'danger',
                  });
                });
            },
          },
        })
      }
    >
      <IconPenGray width={18} height={18} />
      <Title>Editar dados da solicitação</Title>
    </ContainerPopover>
  );

  const contentPopoverRequester = (
    <ContainerPopover
      style={{
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        padding: '0',
        gap: '0',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
          padding: '16px',
          borderBottom: '1px solid #dedede',
        }}
        onClick={() => {
          makeReduxActiveMessage().active({
            active: 'requestConsultation',
            actionCancel: () => {
              closeModal();
            },
            userId: row.id,
            data: {
              isEditing: true,
              onSuccess: () => {
                makeReduxListOnCallRequest().list({
                  body: {
                    dataControl: {
                      limit: 9999,
                    },
                    filters: {
                      requester: professional?.id,
                      status: 'WAITING',
                      healthUnit: healthUnitId,
                      onCall: id,
                    },
                  },
                });

                makeRemoteListOnCallRequest()
                  .list({
                    body: {
                      filters: {
                        id: Number(row.id),
                        requester: professional?.id,
                        status: 'WAITING',
                        healthUnit: healthUnitId,
                        onCall: id,
                      },
                    },
                  })
                  .then(res => {
                    setSelectedRequest(res.records[0]);
                  })
                  .catch(() => {
                    AlertMessage({
                      message: 'Erro ao buscar solicitação!',
                      type: 'danger',
                    });
                  });
              },
            },
          });
        }}
      >
        <IconPenGray width={18} height={18} />
        <Title>Atualizar solicitação</Title>
      </div>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          justifyContent: 'flex-start',
          width: '100%',
          padding: '16px',
          borderBottom: '1px solid #dedede',
        }}
        onClick={() => {
          makeReduxActiveMessage().active({
            active: 'cancelInterconsult',
            actionCancel: () => {
              closeModal();
            },
            data: {
              id: row.id,
              onSuccess: () => {
                makeReduxListOnCallRequest().list({
                  body: {
                    dataControl: {
                      limit: 9999,
                    },
                    filters: {
                      requester: professional?.id,
                      status: 'WAITING',
                      healthUnit: healthUnitId,
                      onCall: id,
                    },
                  },
                });
                setSelectedRequest(null);
              },
            },
          });
        }}
      >
        <NewIconDelete width={18} height={18} />
        <Title style={{ color: '#DD0404' }}>Cancelar solicitação</Title>
      </div>
    </ContainerPopover>
  );

  return (
    <Tr {...rest}>
      <Td>
        <ProfessionalBadge>
          <ProfessionalBadgeInfo>
            <ProfessionalBadgeName>
              {variant === 'REQUESTER'
                ? requester?.fullname?.length
                  ? requester?.fullname
                  : 'Nome não informado'
                : consultant?.fullname?.length
                ? consultant?.fullname
                : 'Nome não informado'}
            </ProfessionalBadgeName>
            <ProfessionalBadgeRole>
              {variant === 'REQUESTER'
                ? onCall?.name
                : calculateAgeAndMonths(consultant?.birthdate)}
            </ProfessionalBadgeRole>
          </ProfessionalBadgeInfo>
        </ProfessionalBadge>
      </Td>
      <Td style={{ maxWidth: '200px' }}>
        <Tooltip title={onCall?.name} placement="top" arrow>
          <span>
            {variant === 'REQUESTER' ? healthUnit?.name : onCall?.name}
          </span>
        </Tooltip>
      </Td>
      <Td>
        <Priority priority={row.priority}>
          <IconFlag />
          {priorityMap(row.priority)}
        </Priority>
      </Td>
      <Td>{created}</Td>
      <Td align="right">
        <ActionButtonContainer>
          {variant === 'REQUESTER' && !isMyRequest && (
            <ActionButton
              disabled={accepted?.state}
              onClick={() =>
                professional &&
                makeRemoteAcceptOnCallSpecialist()
                  .accept({
                    onCallId: row.onCall.id,
                    specialistId: professional?.id,
                    requestId: row.id,
                  })
                  .then(() => {
                    makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
                      accepted: { state: true, request: row.id },
                    });
                  })
                  .catch(() => {
                    AlertMessage({
                      message: 'Erro ao aceitar solicitação!',
                      type: 'danger',
                    });
                  })
              }
            >
              <Tooltip title="Solicitação" placement="top" arrow>
                <CheckIcon width={18} height={18} status={getStatus()} />
              </Tooltip>
            </ActionButton>
          )}
          {variant === 'PROFESSIONAL' && (
            <ActionButton
              onClick={() =>
                makeReduxActiveMessage().active({
                  active: 'cancelInterconsult',
                  actionCancel: () => {
                    closeModal();
                  },
                  data: {
                    id: row.id,
                    onSuccess: () => {
                      makeReduxListOnCallRequest().list({
                        body: {
                          dataControl: {
                            limit: 9999,
                          },
                          filters: {
                            requester: professional?.id,
                            status: 'WAITING',
                            healthUnit: healthUnitId,
                            onCall: id,
                          },
                        },
                      });
                      setSelectedRequest(null);
                    },
                  },
                })
              }
            >
              <Tooltip title="Cancelar solicitação" placement="top" arrow>
                <IconExcludeRequest width={18} height={18} />
              </Tooltip>
            </ActionButton>
          )}
          {isMyRequest && (
            <ActionButton>
              {variant === 'REQUESTER' ? (
                <Popover
                  content={contentPopoverRequester}
                  originX="center"
                  originY="bottom"
                >
                  <Tooltip title="Mais opções" placement="top" arrow>
                    <IconMore width={32} height={32} />
                  </Tooltip>
                </Popover>
              ) : (
                <Popover
                  content={contentPopover}
                  originX="center"
                  originY="bottom"
                >
                  <Tooltip title="Mais opções" placement="top" arrow>
                    <IconMore width={32} height={32} />
                  </Tooltip>
                </Popover>
              )}
            </ActionButton>
          )}
        </ActionButtonContainer>
      </Td>
    </Tr>
  );
};
