import React from 'react';

import { Container, Input, Thumb, Track } from './styles';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  ref: React.RefObject<HTMLInputElement> | null;
};

const Switch: React.FC<Props> = ({ id, ref, ...rest }) => {
  return (
    <Container className="container" htmlFor={`switch-${id}`} key={id}>
      <Input type="checkbox" id={`switch-${id}`} {...rest} />
      <Track className="track">
        <Thumb className="thumb" />
      </Track>
    </Container>
  );
};

export default Switch;
