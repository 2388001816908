import { DeleteAppointment } from '~/domain/usecases/appointment/redux';
import {
  iActionDelete,
  iActionDeleteFailed,
  iActionDeleteSuccess,
  AppointmentTypes,
} from '~/domain/interfaces/redux/appointment/delete';

export const deleteRequest = (
  params: DeleteAppointment.Params,
): iActionDelete => ({
  type: AppointmentTypes.DELETE,
  payload: params,
});

export const deleteSuccess = (): iActionDeleteSuccess => ({
  type: AppointmentTypes.DELETE_SUCCESS,
});

export const deleteFailed = (): iActionDeleteFailed => ({
  type: AppointmentTypes.DELETE_FAILED,
});
