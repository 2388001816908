import styled from 'styled-components';

export const ContainerModal = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3;
`;

export const Container = styled.div`
  min-width: 530px;
  max-width: 530px;
  min-height: 310px;
  height: fit-content;
  border-radius: 10px;
  border: 1px solid #dedede;
  background: #fdfdfd;
  overflow-y: auto;
`;

// Start Header Components
export const Header = styled.div`
  width: 100%;
  // height: 110px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #dedede;
  padding: 16px 24px;
`;

export const HeaderTitle = styled.div`
  .title {
    font-weight: 600;
    font-size: 18px;
    color: #222529;
    margin-bottom: 8px;
  }

  .text {
    font-size: 16px;
    color: #818385;
  }
`;
// End Header Components

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding: 24px 28px;
  border-bottom: 1px solid #dedede;
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 8px;
  font-size: 16px;
  color: #222529;
  font-weight: 500;
`;

export const TextAreaForm = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  h3 {
    color: #444a51;
    font-size: 14px;
    margin-bottom: 4px;
  }

  textarea {
    resize: none;
    width: 400px;
    height: 120px;
    border-radius: 6px;
    border: 1px solid #b4b7b9;
    background: #fdfdfd;
    padding: 12px;

    ::placeholder {
      color: #818385;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  padding: 24px;
`;
