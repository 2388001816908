import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';

import _ from 'lodash';
import { iInvoiceResults, iStore } from '~/domain/interfaces/models';

import { ownProps, InvoiceSelectOptions } from '../interface';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { invoiceItems } = useSelector((state: iStore) => state.billing);

    Component.defaultProps = {
      invoiceMonths: MapInvoiceMonths(invoiceItems),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * Map list invoiceMonths to component.
 * @param invoiceItems list of invoiceMonths.
 */
export const MapInvoiceMonths = (
  invoiceItems: iInvoiceResults,
): InvoiceSelectOptions[] => {
  const now = new Date();

  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();

  const currentMonthCapitalize = now.toLocaleString(undefined, {
    month: 'long',
  });

  const currentMonthFormatted = currentMonthCapitalize.replace(
    currentMonthCapitalize[0],
    currentMonthCapitalize.charAt(0).toUpperCase(),
  );

  const arr: InvoiceSelectOptions[] = [
    {
      label: `${currentMonthFormatted} ${currentYear}`,
      value: {
        invoiceId: -2,
        month: currentMonth,
        year: currentYear,
      },
    },
  ];

  if (invoiceItems?.records) {
    invoiceItems?.records.forEach(item => {
      const formattedDate = new Date(item.begin);
      const month = formattedDate.toLocaleString('pt-BR', {
        month: 'long',
      });
      const formattedMonth = month.replace(
        month[0],
        month.charAt(0).toUpperCase(),
      );

      const label = `${formattedMonth} ${formattedDate.getFullYear()}`;

      arr.push({
        label,
        value: {
          invoiceId: item.id,
          month: formattedDate.getMonth() + 1,
          year: formattedDate.getFullYear(),
        },
      });
    });
  }

  const orderedArray = _.orderBy(
    arr,
    item => `${item.value.year}${String(item.value.month).padStart(2, '0')}`,
    ['desc'],
  );

  return orderedArray;
};
