import { array, number, object, string } from 'zod';
import { validateDate } from '~/utils/validDate';

const schema = object({
  profession: number().int().nonnegative(),
  name: string().max(32),
  descr: string().max(256).optional(),
});

export const schemaSpecialtyCreate = object({
  id: number().int().nonnegative({ message: 'Selecione uma especialidade' }),
  docProf: string().optional(),
  docProfUF: string(),
  docProfIssued: string()
    .refine(
      value => {
        if (!value?.length) return true;

        return validateDate(value);
      },
      {
        message: 'Preencha a data de emissão corretamente.',
      },
    )
    .optional(),
});

export const schemaSpecialty = object({
  specialty: array(
    object({
      id: number()
        .nonnegative({ message: 'Selecione uma especialidade' })
        .int(),
      docProf: string(),
      docProfUF: string(),
      docProfIssued: string()
        .refine(
          value => {
            if (!value?.length) return true;

            return validateDate(value);
          },
          {
            message: 'Preencha a data de emissão corretamente.',
          },
        )
        .optional(),
    }),
  ),
});

export default schema;
