import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { GetCitiesByUf } from '~/domain/usecases/externalServices/remote';
import { makeRemoteGetCitiesByUf } from '~/main/factories/usecases/externalServices/GetCitiesByUf';

interface UFContextData {
  ufs: Option[];
  cities: GetCitiesByUf.Model;
  city: Partial<GetCitiesByUf.Model[0]> | undefined;

  loading: boolean;

  loadCitiesByUf: (uf: string) => void;
  handleSelectCity: (city: Partial<GetCitiesByUf.Model[0]>) => void;
  loadUfs: () => void;
}

interface Option {
  label: string;
  value: string;
}

export const UFContext = createContext<UFContextData | undefined>(undefined);

export const useUF = (): UFContextData => {
  const context = useContext(UFContext);
  if (!context) {
    throw new Error('useUF must be used within a UFProvider');
  }
  return context;
};

export const UFProvider: React.FC = ({ children }) => {
  const [ufs, setUfs] = useState<Option[]>([
    {
      label: 'Paraíba',
      value: 'PB',
    },
  ]);
  const [city, setCity] = useState<Partial<GetCitiesByUf.Model[0]> | undefined>(
    undefined,
  );

  const [cities, setCities] = useState<GetCitiesByUf.Model>([]);
  const [loading, setLoading] = useState(false);

  const loadCitiesByUf = useCallback(async (uf: string) => {
    try {
      setLoading(true);
      const cities = await makeRemoteGetCitiesByUf().getCitiesByUf({ uf });

      setCities(cities);
    } catch (error) {
      console.error(error);
      setCities([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSelectCity = useCallback(
    (city: Partial<GetCitiesByUf.Model[0]>) => {
      setCity(city);
    },
    [],
  );

  const contextValue: UFContextData = {
    ufs,
    city,
    cities,
    loading,
    handleSelectCity,
    loadCitiesByUf,
    loadUfs: () => {},
  };

  useEffect(() => {
    loadCitiesByUf('PB');
  }, []);

  return (
    <UFContext.Provider value={contextValue}>{children}</UFContext.Provider>
  );
};
