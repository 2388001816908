import { OrgUnitList } from '~/domain/usecases/orgUnit/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
  Forbidden,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteOrgUnitList implements OrgUnitList {
  private readonly url: string;

  private readonly httpClient: HttpClient<OrgUnitList.Model>;

  constructor(url: string, httClient: HttpClient<OrgUnitList.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  list = async (params: OrgUnitList.Params): Promise<OrgUnitList.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      default:
        throw new UnexpectedError();
    }
  };
}
