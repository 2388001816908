import { GetAllNotificationUser } from '~/domain/usecases/Notifications/redux';
import {
  iActionGetAll,
  iActionGetAllSuccess,
  iActionGetAllFailed,
  NotificationTypes,
} from '~/domain/interfaces/redux/Notifications/getAll';

export const getAllRequest = (
  payload: GetAllNotificationUser.Params,
): iActionGetAll => ({
  type: NotificationTypes.GETALL,
  payload,
});

export const getAllSuccess = (
  params: GetAllNotificationUser.Model,
): iActionGetAllSuccess => ({
  type: NotificationTypes.GETALL_SUCCESS,
  payload: params,
});

export const getAllFailed = (): iActionGetAllFailed => ({
  type: NotificationTypes.GETALL_FAILED,
});
