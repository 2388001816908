import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { AppointmentFiltered as UseCaseRemoteAppointmentFiltered } from '~/domain/usecases/appointment/remote';
import { AppointmentFiltered as UseCaseReduxAppointmentFiltered } from '~/domain/usecases/appointment/redux';
import { RemoteAppointmentFiltered } from '~/data/repository/appointment/RemoteAppointmentFiltered';
import { ReduxAppointmentFiltered } from '~/data/store/reducer/appointment/usecases/ReduxAppointmentFiltered';

/**
 * send request via API.
 */
export const makeRemoteAppointmentFiltered =
  (): UseCaseRemoteAppointmentFiltered =>
    new RemoteAppointmentFiltered(
      makeApiUrl('/appointments/filter'),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxAppointmentFiltered =
  (): UseCaseReduxAppointmentFiltered => new ReduxAppointmentFiltered();
