/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';

import { IconCalendarInput } from '~/presentation/base/icons';
import { Container, ContainerRoot } from './styles';

const GlobalCss = withStyles({
  '@global': {
    '.MuiPickersCalendarHeader-switchHeader': {
      justifyContent: 'flex-end',
    },
    '.MuiPickersCalendarHeader-transitionContainer': {
      position: 'absolute',
      /* textAlign: 'start !important',
      right: '76px', */
    },
    '.MuiPickersCalendarHeader-transitionContainer > p': {
      textAlign: 'start',
      paddingLeft: '23px',
    },
  },
})(() => null);

const CssKeyboardDatePicker = withStyles({
  root: {
    paddingLeft: '9px',
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiInput-underline:before': {
      border: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      width: '100%',
      border: 'none',
    },
    '& .MuiInputBase-input::placeholder': {
      color: '#A7A7A7',
    },
  },
})(DatePicker);

interface ownProps {
  id?: string;
  label?: string;
  placeholder?: string;
  name?: string;
  state: Date | null;
  defaultValue?: string;
  setState: Function;
  error?: boolean;
  message?: string;
  minDate?: Date;
  disabled?: boolean;
  autofocus?: boolean;
  endAdornment?: boolean;
  width?: string;
  height?: string;
}

const InputKeyboardDate: React.FC<ownProps> = ({
  id,
  label,
  state,
  setState,
  message,
  endAdornment,
  width,
  height,
  ...rest
}): JSX.Element => {
  const [open, setOpen] = useState(false);
  const handlerDate = (date: Date | null) => {
    setState(date);
  };

  const handleOpen = () => {
    setOpen(prevState => !prevState);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ContainerRoot width={width} error={rest.error}>
        {label && <h5>{label}</h5>}
        <GlobalCss />
        <Container id={id} error={rest.error} style={{ height }}>
          <CssKeyboardDatePicker
            open={open}
            onOpen={handleOpen}
            onClose={handleClose}
            fullWidth
            disableToolbar
            autoOk
            variant="inline"
            format="dd/MM/yyyy"
            margin="dense"
            id={`date-picker-inline-${id}`}
            value={state}
            onChange={handlerDate}
            {...rest}
          />
        </Container>
        {endAdornment && (
          <div
            onClick={handleOpen}
            style={{
              position: 'absolute',
              right: 10,
              top: message ? '23%' : '34%',
              // bottom: message ? '0%' : '30%',
            }}
          >
            <IconCalendarInput />
          </div>
        )}
        {message && <p id="error">{message}</p>}
      </ContainerRoot>
    </>
  );
};

export default InputKeyboardDate;
