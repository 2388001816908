/* eslint-disable react/jsx-one-expression-per-line */
import { Tooltip } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { iStore } from '~/domain/interfaces/models';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { IconMore, IconPenGray } from '~/presentation/base/icons';
import { Popover } from '~/presentation/components/UI/popover';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';
import { TablePagination } from '~/presentation/components/tablePagination';
import { calculateAgeAndMonths } from '~/utils/calculateAgeAndMonths';
import { closeModal } from '~/utils/closeModal';
import { getProfessionalInfo } from '~/utils/getProfessionalInfo';
import { outcomeMap } from '~/utils/mapPriority';
import { followUpColumns, followUpColumnsProfessional } from './columns';
import {
  ActionButton,
  ActionButtonContainer,
  ContainerPopover,
  EmptyList,
  ListFooter,
  OutcomeBadgeStatus,
  ProfessionalBadge,
  ProfessionalBadgeInfo,
  ProfessionalBadgeName,
  ProfessionalBadgeRole,
  Table,
  Td,
  Th,
  Title,
  Tr,
} from './styles';

type Row = iOnCallRequest['records'][0];

type SelectedRequest = Row | null;

type PatientDutyListProps = {
  selectedRequest: SelectedRequest;
  setSelectedRequest: (requester: SelectedRequest) => void;
  variant?: 'REQUESTER' | 'PROFESSIONAL';
  healthUnitId?: number;
};

export const FollowUp: React.FC<PatientDutyListProps> = ({
  setSelectedRequest,
  selectedRequest,
  variant,
  healthUnitId,
}) => {
  const { records } = useSelector((store: iStore) => store.onCallRequest);

  const recordsFiltered = records?.filter(item => {
    return (
      item.status === 'INPROGRESS' &&
      (!healthUnitId || item.healthUnit.id === healthUnitId)
    );
  });

  const hasRequests = recordsFiltered?.length;

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Table>
        <thead>
          <tr>
            {variant === 'REQUESTER' ? (
              <>
                {followUpColumns.map(column => (
                  <Th key={column.id}>{column.label}</Th>
                ))}
              </>
            ) : (
              <>
                {followUpColumnsProfessional.map(column => (
                  <Th key={column.id}>{column.label}</Th>
                ))}
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {recordsFiltered?.map(row => (
            <TableRowItem
              key={row.id}
              row={row}
              active={row.id === selectedRequest?.id}
              onClick={() => setSelectedRequest(row)}
              variant={variant}
              setSelectedRequest={setSelectedRequest}
              healthUnitId={healthUnitId}
            />
          ))}
        </tbody>
      </Table>
      {!hasRequests ? (
        <EmptyList>Você não tem solicitações pendentes.</EmptyList>
      ) : (
        <ListFooter>
          <TablePagination
            itemPerPage={1}
            data={recordsFiltered}
            totalData={recordsFiltered.length}
          />
        </ListFooter>
      )}
    </div>
  );
};

type TableRowItemProps = {
  row: Row & { active?: boolean };
  active?: boolean;
  onClick: () => void;
  variant?: 'REQUESTER' | 'PROFESSIONAL';
  healthUnitId?: number;
  setSelectedRequest: (requester: SelectedRequest) => void;
};

const TableRowItem: React.FC<TableRowItemProps> = ({
  row,
  variant,
  healthUnitId,
  setSelectedRequest,
  ...rest
}) => {
  const { consultant, onCall, status, requester, healthUnit } = row;

  const { pathname } = useLocation();
  const last = pathname.split('/').pop();
  const id = last === 'me' ? undefined : Number(last);

  const professional = getProfessionalInfo();
  const isMyRequest =
    row.requester.id === Number(professional?.id) ||
    row.specialist.id === Number(professional?.id);

  const updated = Intl.DateTimeFormat('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
    .format(new Date(row.updated))
    .replace(', ', ', às ');

  const contentPopoverRequester = (
    <ContainerPopover
      onClick={() =>
        makeReduxActiveMessage().active({
          active: 'requestConsultation',
          actionCancel: () => {
            closeModal();
          },
          userId: row.id,
          data: {
            isEditing: true,
            onSuccess: () => {
              makeReduxListOnCallRequest().list({
                body: {
                  dataControl: {
                    limit: 9999,
                  },
                  filters: {
                    requester: professional?.id,
                    status: row.status,
                    healthUnit: healthUnitId,
                    onCall: id,
                  },
                },
              });

              // TODO: SelectedRequester deveria ser id para evitar remote
              makeRemoteListOnCallRequest()
                .list({
                  body: {
                    filters: {
                      id: Number(row?.id),
                      onCall: id,
                    },
                  },
                })
                .then(res => {
                  setSelectedRequest(res.records[0]);
                })
                .catch(() => {
                  AlertMessage({
                    message: 'Erro ao buscar solicitação!',
                    type: 'danger',
                  });
                });
            },
          },
        })
      }
    >
      <IconPenGray width={18} height={18} />
      <Title>
        {variant === 'REQUESTER'
          ? 'Atualizar solicitação'
          : 'Editar dados da solicitação'}
      </Title>
    </ContainerPopover>
  );

  const contentPopoverProfessional = (
    <ContainerPopover
      onClick={() => {
        makeReduxActiveMessage().active({
          active: 'updatedRequestWaiting',
          actionCancel: () => {
            closeModal();
          },
          actionOk: () => {
            closeModal();
          },
          data: {
            requestId: row.id,
            onSuccess: () => {
              makeReduxListOnCallRequest().list({
                body: {
                  dataControl: {
                    limit: 9999,
                  },
                  filters: {
                    requester: professional?.id,
                    status: row.status,
                    healthUnit: healthUnitId,
                    onCall: id,
                  },
                },
              });

              // TODO: SelectedRequester deveria ser id para evitar remote
              makeRemoteListOnCallRequest()
                .list({
                  body: {
                    filters: {
                      id: Number(row?.id),
                      onCall: id,
                    },
                  },
                })
                .then(res => {
                  setSelectedRequest(res.records[0]);
                })
                .catch(() => {
                  AlertMessage({
                    message: 'Erro ao buscar solicitação!',
                    type: 'danger',
                  });
                });
            },
          },
        });
      }}
    >
      <IconPenGray width={18} height={18} />
      <Title>Atualizar solicitação</Title>
    </ContainerPopover>
  );

  return (
    <Tr {...rest}>
      <Td>
        <ProfessionalBadge>
          <ProfessionalBadgeInfo>
            <ProfessionalBadgeName>
              {consultant?.fullname?.length
                ? consultant?.fullname
                : 'Nome não informado'}
            </ProfessionalBadgeName>
            <ProfessionalBadgeRole>
              {calculateAgeAndMonths(consultant?.birthdate)}
            </ProfessionalBadgeRole>
          </ProfessionalBadgeInfo>
        </ProfessionalBadge>
      </Td>
      <Td style={{ maxWidth: '200px' }}>
        <Tooltip title="requester.info.organization" placement="top" arrow>
          <Tooltip title={onCall?.specialtyName} placement="top" arrow>
            <span>
              {variant === 'REQUESTER'
                ? healthUnit?.name
                : onCall?.specialtyName}
            </span>
          </Tooltip>
        </Tooltip>
      </Td>

      <Td>{updated}</Td>
      <Td>
        <OutcomeBadgeStatus status={row.outcome?.msgKey}>
          {outcomeMap(row.outcome?.msgKey)}
        </OutcomeBadgeStatus>
      </Td>
      <Td align="right">
        <ActionButtonContainer>
          {isMyRequest && variant === 'REQUESTER' && (
            <ActionButton>
              <Popover
                content={contentPopoverProfessional}
                originX="center"
                originY="bottom"
              >
                <Tooltip title="Mais opções" placement="top" arrow>
                  <IconMore width={32} height={32} />
                </Tooltip>
              </Popover>
            </ActionButton>
          )}

          {isMyRequest && variant === 'PROFESSIONAL' && (
            <ActionButton>
              <Popover
                content={contentPopoverRequester}
                originX="center"
                originY="bottom"
              >
                <Tooltip title="Mais opções" placement="top" arrow>
                  <IconMore width={32} height={32} />
                </Tooltip>
              </Popover>
            </ActionButton>
          )}
        </ActionButtonContainer>
      </Td>
    </Tr>
  );
};
