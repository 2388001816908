import { call, put, select } from 'redux-saga/effects';
import { iActionListModalities } from '~/domain/interfaces/redux/diagnosisReport/listModalities';
import { ListModalitiesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote/ListModalitiesDiagnosisReport';
import { makeRemoteListModalitiesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListModalitiesDiagnosisReportFactory';
import {
  listModalitiesSuccess,
  listModalitiesFailed,
} from '~/data/store/reducer/diagnosisReport/actions/listModalities';

export function* onListModalities(action: iActionListModalities) {
  const remoteListModalitiesDiagnosisReport =
    makeRemoteListModalitiesDiagnosisReport();

  try {
    const response: ListModalitiesDiagnosisReport.Model = yield call(
      remoteListModalitiesDiagnosisReport.listModalities,
      action.payload,
    );
    yield put(
      listModalitiesSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listModalitiesFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListModalitiesSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListModalitiesFailed() {}
