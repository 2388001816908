import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetAllUsers as UsecaseRemoteGetAllUsers } from '~/domain/usecases/users/remote';
import { GetAllUsers as UsecaseReduxGetAllUsers } from '~/domain/usecases/users/redux';

import { RemoteGetAllUsers } from '~/data/repository/users';
import { ReduxGetAllUsers } from '~/data/store/reducer/users/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetAllUsers = (): UsecaseRemoteGetAllUsers =>
  new RemoteGetAllUsers(makeApiUrl('/users/list'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetAllUsers = (): UsecaseReduxGetAllUsers =>
  new ReduxGetAllUsers();
