import styled, { css } from 'styled-components';
import { defaultTheme } from '~/presentation/base/themes';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .docs {
    width: 100%;
    height: 768px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .add {
    width: 100%;
    height: 105px;
    background-color: #f4f7fe;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 16px 0px 16px;
    margin: 8px 16px 0px 16px;
  }

  .info {
    font-weight: 400;
    font-size: 12px;
    color: #7a7a7a;
    margin-top: 5px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  width: 100%;

  .title {
    font-size: 12px;
    margin-bottom: 29px;
  }
`;

interface IStyledUploadFilesProps {
  disabled?: boolean;
}

export const StyleInput = styled.div<IStyledUploadFilesProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  height: 39px;
  width: 100%;
  border: 1px solid #005bc8;
  border-radius: 6px;
  background: white;
  color: #005bc8;
  gap: 8px;
  transition: opacity 0.2s;
  font-size: 14px;
  line-height: 100%;
  font-weight: 500;
  font-style: normal;
  &:hover {
    background-color: #006ef2;
    color: white;
    transition: all 0.3s;
  }
  &:active {
    background-color: #004ba5;
    transition: 0.2s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

interface iContainer {
  size?: string;
  disabled?: boolean;
}

export const ContainerAdd = styled.label<iContainer>`
  display: flex;
  margin-top: 8px;
  margin-bottom: 9px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${defaultTheme.highlight};
  width: 100%;
  height: 22px;
  cursor: pointer;

  :hover {
    background-color: ${defaultTheme.primaryLight};
    transition: 0.5s;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      background-color: transparent;
    `}
`;
