import React, { useState } from 'react';

import {
  ContainerChooseDocuments,
  ContentInputs,
  ButtonAdd,
  ContainerLeft,
  ContainerRight,
  ListPrescription,
  Dosage,
  Drug,
  Posology,
  Form,
  Via,
  Duration,
  Quantity,
} from './styles/StyledAntiMicroPrescription';

import { Input, Button, Select } from '~/presentation/components/UI';

import { ItemAntiMicroPrescription } from '~/presentation/components/antimicrobial';
import { iAntiMicroPresctiption } from './interface';

import { translator } from '~/presentation/components/i18n';

const AntiMicroPrescription: React.FC = () => {
  const [prescription, setPrescription] = useState<iAntiMicroPresctiption[]>(
    [],
  );
  const [state, setState] = useState<iAntiMicroPresctiption>({
    drug: '',
    dosage: '',
    posology: '',
    via: '',
    duration: '',
    form: '',
    quantity: '',
  });

  function handleAdd() {
    setPrescription([...prescription, state]);
    setState({
      drug: '',
      dosage: '',
      posology: '',
      via: '',
      duration: '',
      form: '',
      quantity: '',
    });
  }
  return (
    <ContainerChooseDocuments>
      <ContainerLeft>
        <ContentInputs>
          <Drug>
            <Input
              label="Droga"
              placeholder="Inserir Medicação"
              value={state.drug}
              onChange={e => setState({ ...state, drug: e.target.value })}
            />
          </Drug>
          <Dosage>
            <Input
              label="Dosagem"
              placeholder="Inserir Dosagem"
              value={state.dosage}
              onChange={e => setState({ ...state, dosage: e.target.value })}
            />
          </Dosage>
          <Form>
            <Input
              label="Forma farmacêutica"
              placeholder="Inserir forma"
              value={state.form}
              onChange={e => setState({ ...state, form: e.target.value })}
            />
          </Form>
          <Via>
            <Input
              label="Via de administração"
              placeholder="Inserir via"
              value={state.via}
              onChange={e => setState({ ...state, via: e.target.value })}
            />
          </Via>
          <Quantity>
            <Input
              label="Quantidade"
              placeholder="Inserir quantidade"
              value={state.quantity}
              onChange={e => setState({ ...state, quantity: e.target.value })}
            />
          </Quantity>
          <Duration>
            <Input
              label="Duração do tratamento"
              placeholder="Selecione período"
              value={state.duration}
              onChange={e => setState({ ...state, duration: e.target.value })}
            />
          </Duration>
          <Posology>
            <Input
              label="Posologia"
              placeholder="Inserir Posologia"
              value={state.posology}
              onChange={e => setState({ ...state, posology: e.target.value })}
            />
          </Posology>
        </ContentInputs>
        <ButtonAdd>
          <p>
            Após preencher os campos, clique em “Adicionar” para incluir a
            medicação na prescrição da receita.
          </p>
          <Button
            id="btn_addDrug"
            size="medium"
            rounded
            variant="secundary"
            color="blue"
            onClick={handleAdd}
          >
            {'Adicionar >>'}
          </Button>
        </ButtonAdd>
      </ContainerLeft>
      <ContainerRight>
        <h2>Drogas Inseridas</h2>
        <ListPrescription>
          <ItemAntiMicroPrescription ArrPrescription={prescription} />
        </ListPrescription>
      </ContainerRight>
    </ContainerChooseDocuments>
  );
};

export default AntiMicroPrescription;
