import { Dispatch } from 'redux';
import store from '~/data/store';

import { createRequest } from '~/data/store/reducer/administrator/actions/create';

import { iActionCreate } from '~/domain/interfaces/redux/administrator/create';

import { CreateAdministrator } from '~/domain/usecases/administrator/redux';

export class ReduxCreateAdministrator implements CreateAdministrator {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  create = (params: CreateAdministrator.Params): iActionCreate =>
    this.send(createRequest(params));
}
