import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { createIntl, createIntlCache } from 'react-intl';
import { getLocale } from '~/utils/getLocale';
import translations from '~/infra/i18n/locales';
import { Button, Select } from '~/presentation/components/UI';

import {
  Container,
  Body,
  Profession,
  Message,
} from './style/StyledProfessionalData';

import { translator } from '~/presentation/components/i18n';

import {
  Specialty,
  iListProfessional,
  iListProfession,
  iRegisterProfessional,
} from './interface';
import { schemaProfessionalData } from '~/validation/validators/user/CreateUserValidator';
import { Navigator } from '../register/Navigator';
import { ConnectComponent } from './mapper/map';
import SpecialtyCard, { MySpecialty, Cards } from './SpecialtyFunction';
import { AlertMessage } from '../messages/AlertMessage';
import ListProfessionalData from './ListProfessionalData';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export interface externalProps {
  next: (data: iRegisterProfessional) => any;
  back: (data: iRegisterProfessional) => any;
  state?: iRegisterProfessional;
  cancel?: string;
}

export interface ownProps {
  profession: iListProfession[];
  filterSpecialty: (id: number | undefined) => Specialty[];
}

const PersonalData: React.FC<ownProps & externalProps> = ({
  back,
  next,
  state,
  profession,
  cancel,
  filterSpecialty,
}): JSX.Element => {
  const [specialty, setSpecialty] = useState(
    state?.professional?.specialties || [],
  );
  const [showLength, setShowLength] = useState(0);
  const [disable, setDisable] = useState(true);

  const [id, setId] = useState<number>(() => {
    if (specialty.length === 0) return 0;
    return specialty[specialty.length - 1].id!;
  });

  function handler(event: React.ChangeEvent<HTMLSelectElement>) {
    setValue('profession', Number(event.target.value));
    setProfessionalId(Number(event.target.value));
    setSelectedProfession(Number(event.target.value));
  }

  const { errors, handleSubmit, register, setValue, getValues } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaProfessionalData),
    defaultValues: {
      ...state?.professional,
    },
  });

  const [selectedProfession, setSelectedProfession] = useState(
    getValues().profession || -1,
  ); //  ** seleciona a profissao

  const [professionalId, setProfessionalId] = useState<number>(
    getValues().profession,
  );
  const onSubmit = handleSubmit(data => {
    if (specialty.length !== 0) {
      next({
        professional: {
          ...data,
          org: 1,
          orgUnit: 1,
          specialties: specialty!,
        },
      });
      return;
    }
    AlertMessage({
      message: intl.formatMessage({
        id: 'Você precisa adicionar uma especialidade.',
      }),
      type: 'danger',
    });
  });

  useEffect(() => {
    setProfessionalId(getValues().profession);
    console.log(filterSpecialty(professionalId));
  }, [professionalId]);

  const onBack = () => {
    back({
      professional: {
        org: getValues().org,
        orgUnit: getValues().orgUnit,
        profession: selectedProfession,
        specialties: specialty,
      },
    });

    // console.log('##professional: ', getValues().specialties);
  };

  const handleClear = () => {
    setSpecialty([]);
  };

  useEffect(() => {
    setSelectedProfession(getValues().profession || -1);
    setProfessionalId(getValues().profession);
  }, [profession]);

  return (
    <Container onSubmit={onSubmit}>
      <Profession>
        <Select
          name="profession"
          width="70%"
          label={translator('Profissão')}
          placeholder="Placeholder"
          defaultValue={state?.professional?.profession}
          value={selectedProfession}
          onChange={e => {
            handleClear();
            handler(e);
          }}
          register={() => register('profession')}
          error={Boolean(errors.profession)}
          message={errors?.profession?.message || ''}
          autoFocus
        >
          <option value="select">
            {translator('Selecione sua profissão')}
          </option>
          {profession.map(item => (
            <option value={item.id}>{item.name}</option>
          ))}
        </Select>
      </Profession>
      <Message>
        *
        {translator(
          'Adicione uma ou mais especialidades para concluir o cadastro',
        )}
      </Message>
      <Body>
        <SpecialtyCard
          setDisable={setDisable}
          specialty={filterSpecialty(professionalId)}
          addSpecialty={setSpecialty}
          state={{ specialty }}
          professionID={professionalId}
          id={id}
          setId={setId}
          setLength={setShowLength}
        />
      </Body>
      {/* <ListProfessionalData state={state} back={back} next={next} /> */}
      <Navigator back={onBack} cancel={cancel} isFinish isDisable={disable} />
    </Container>
  );
};

export default ConnectComponent(PersonalData);
