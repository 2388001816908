import styled from 'styled-components';

export interface ContainerProps {
  disabled?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 30px;
  height: 80%;
`;

export const ContainerCircle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
`;

export const ArrowContainer = styled.div<ContainerProps>`
  width: 35px;
  height: 100%;

  background-color: #fff;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '#FFFFFF')};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: #e6e6e6;
  }

  cursor: pointer;
`;

interface CircleProps {
  active?: boolean;
}

export const Circle = styled.div<CircleProps>`
  width: ${({ active }) => (active ? '20px' : '5px')};
  height: 5px;

  border-radius: 5px;

  background-color: ${({ active }) => (active ? '#DF2C26' : '#d9d9d9')};
`;

export const CarouselContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 616px;
  height: 100%;
`;

export const CarouselItem = styled.iframe`
  min-width: 616px;
  min-height: 300px;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 10px solid #32193a;
  border-radius: 5px;

  transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
`;

export const Image = styled.img`
  width: 100%;
`;
