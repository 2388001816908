/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { Button } from '~/presentation/components/UI';
import { AlertIconSuccess } from '~/presentation/base/icons';

import { translator } from '../i18n'; // TODO: check this component translation

import {
  Body,
  ConsultInfoContainer,
  ConsultInfoMessage,
  Container,
  ContainerModal,
  Footer,
  Header,
  ScrollContainer,
  TextBold,
  TextBoldTitle,
  TextNormalSubtitle,
} from './styles/styles';
import ConsultDetailFragment from './ConsultDetailFragment';
import { iStore } from '~/domain/interfaces/models';
import { iDutyRequesters } from '~/domain/interfaces/models/Duty';
import { makeRemoteRefuseAppointmentOnCall } from '~/main/factories/usecases/onCall/RefuseAppointmentOnCallFactory';
import { makeReduxSetSpecialistStatusOnCall } from '~/main/factories/usecases/onCall/SetSpecialistStatusOnCallFactory';
import { iOnCallRequest } from '~/domain/interfaces/models/OnCallRequest';

interface propsModalNewAvailableInterconsult {
  message: iMessage;
}

const ModalNewAvailableInterconsult: React.FC<
  propsModalNewAvailableInterconsult
> = ({ message }) => {
  const [professionalName, setProfessionalName] = useState('Não Consta');
  const [professionalSpecialty, setProfessionalSpecialty] =
    useState('Não Consta');
  const [patient, setPatient] = useState<iOnCallRequest['records'][0]>();
  const { records } = useSelector((state: iStore) => state.onCallRequest);

  const { active, actionOk, actionCancel, data } = message;

  useEffect(() => {
    if (data) {
      const requester = records?.find(
        item => Number(item.id) === Number(data.requestId),
      );

      if (data.type === 'SPECIALIST' && requester) {
        setProfessionalName(requester.requester.fullname);
      } else if (data.type === 'REQUESTER') {
        setProfessionalName(data?.specialist);
      }

      setPatient(requester);
      setProfessionalSpecialty(data.onCall?.descr);
    }
  }, [data, records]);

  const handleCancel = () => {
    if (data?.type === 'SPECIALIST')
      makeReduxSetSpecialistStatusOnCall().setSpecialistStatus({
        available: false,
      });

    /* makeRemoteRefuseAppointmentOnCall().refuseAppointment({
        onCallId: Number(data?.onCall?.id),
        requesterId: Number(data?.requester),
        specialistId: Number(data?.specialist),
      }); */
    actionCancel?.();
  };

  const onSubmit = () => {
    actionOk?.();
  };

  const renderTitle = useMemo(() => {
    return data.type === 'SPECIALIST'
      ? 'Interconsulta disponível!'
      : 'Seu pedido de interconsulta foi aceito!';
  }, [data]);

  const patientSex = useMemo(() => {
    if (!patient?.consultant?.sex) {
      return 'Não Informado';
    }
    return patient?.consultant.sex === 'M' ? 'Masculino' : 'Feminino';
  }, [patient]);

  const renderSubtitle = useMemo(() => {
    return data.type === 'SPECIALIST'
      ? `O profissional ${professionalName} já se encontra disponível para discutir o caso do paciente abaixo`
      : `O profissional ${professionalName}, na especialidade de ${professionalSpecialty}, aceitou sua solicitação para discutir o caso do paciente abaixo:`;
  }, [professionalName, professionalSpecialty, data]);

  const msgName = MessageOptions.newAvailableInterconsult;

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container>
            <Header>
              <AlertIconSuccess />
            </Header>
            <Body>
              <ConsultInfoMessage>
                <TextBoldTitle>{translator(renderTitle)}</TextBoldTitle>
                <TextNormalSubtitle>{renderSubtitle}</TextNormalSubtitle>
              </ConsultInfoMessage>
              <ConsultInfoContainer>
                <TextBold>{translator('Ficha do paciente')}</TextBold>
                <ScrollContainer>
                  <ConsultDetailFragment
                    label="Nome"
                    content={patient?.consultant.fullname}
                  />
                  <ConsultDetailFragment label="Sexo" content={patientSex} />
                  <ConsultDetailFragment
                    label="Idade"
                    content={`${patient?.consultant.birthdate ?? ''}`}
                  />
                  {patient?.consultant.docType && (
                    <ConsultDetailFragment
                      label="Cartão SUS"
                      content={patient?.consultant.docValue}
                    />
                  )}
                  <ConsultDetailFragment
                    label="Situação"
                    content={patient?.description ?? 'Não Informado'}
                  />
                </ScrollContainer>
              </ConsultInfoContainer>
            </Body>
            <Footer>
              <Button variant="primary" onClick={onSubmit}>
                {translator('Entrar na consulta')}
              </Button>
              <Button
                variant="secundary"
                onClick={() => {
                  handleCancel();
                }}
              >
                {translator('Dispensar')}
              </Button>
            </Footer>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalNewAvailableInterconsult;
