import { GetAppointmentType } from '~/domain/usecases/appointmentType/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';

import {
  InvalidCredentialsError,
  NotFound,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteGetAppointmentType implements GetAppointmentType {
  private readonly url: string;

  private readonly httpClient: HttpClient<GetAppointmentType.Model>;

  constructor(url: string, httpClient: HttpClient<GetAppointmentType.Model>) {
    this.url = url;
    this.httpClient = httpClient;
  }

  list = async (
    params: GetAppointmentType.Params,
  ): Promise<GetAppointmentType.Model> => {
    let query = '';

    Object.entries(params).forEach(([key, value]) => {
      query += `${key}=${value}&`;
    });

    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${query}`,
      method: 'get',
    });

    console.log('GetAppointmentType request: ', httpResponse);

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      default:
        throw new UnexpectedError();
    }
  };
}
