import { CreateAppointment } from '~/domain/usecases/appointment/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteCreateAppointment implements CreateAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateAppointment.Model>;

  constructor(url: string, httClient: HttpClient<CreateAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateAppointment.Params,
  ): Promise<CreateAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
