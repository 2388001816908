/* eslint-disable react/jsx-indent */
import React from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';
import { translator } from '~/presentation/components/i18n';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { ExcludeIcon, ExcludeDisabledIcon } from '~/presentation/base/icons';

import {
  Content,
  Img,
  InfoProfessional,
  Infos,
  Name,
  Speciality,
  Type,
  ContentType,
  Delete,
} from './styles/StyledItemParticipatingProfessionals';

import { ItemProps } from './interface';
import { iStore } from '~/domain/interfaces/models';

const ItemParticipatingProfessional: React.FC<ItemProps> = ({
  professional,
  handleDeleteInvite,
}) => {
  const { info, selectUser } = useSelector((store: iStore) => store.auth);

  const professionalId = info.professionals?.find(
    item => item.orgUnit.id === selectUser.orgUnitId,
  )?.id;

  return (
    <Content>
      <InfoProfessional>
        <Img>
          <AvatarImage size="mini" src={professional?.src} />
        </Img>
        <Infos>
          <Name>{professional?.name}</Name>
          <Speciality>{professional?.specialty}</Speciality>
        </Infos>
      </InfoProfessional>
      <ContentType>
        <Type>
          {professional?.type === 'INTERNAL'
            ? translator('profissional interno')
            : translator('profissional externo')}
        </Type>
        {selectUser.role !== 'PRO' ||
        (selectUser.role === 'PRO' && professionalId !== professional.id) ? (
          <Tooltip
            title="Excluir profissional"
            placement="top"
            arrow
            onClick={() => handleDeleteInvite()}
          >
            <div>
              <Delete id="exclude_professional" />
            </div>
          </Tooltip>
        ) : (
          <ExcludeDisabledIcon id="btn_disabled" />
        )}
      </ContentType>
    </Content>
  );
};

export default ItemParticipatingProfessional;
