import { call, put } from 'redux-saga/effects';
import { GetACL } from '~/domain/usecases/permissions/redux';
import { iActionGetACL } from '~/domain/interfaces/redux/permissions/GetACL';
import { makeRemoteGetACL } from '~/main/factories/usecases/permissions/GetACL';
import { History } from '~/main/routes';

import {
  GetACLFailed,
  GetACLSuccess,
} from '~/data/store/reducer/permissions/actions/getACL';

export function* onGetACL(action: iActionGetACL) {
  const ACL = makeRemoteGetACL();

  try {
    const response: GetACL.Model = yield call(ACL.get, action.payload);
    yield put(GetACLSuccess(response));
  } catch (e) {
    yield put(GetACLFailed());
  }
}

export function onGetACLSuccess() {
  History.push('/appointment');
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onGetACLFailed() {}
