import { DeleteShifts as UsecaseReduxDeleteShifts } from '~/domain/usecases/shifts/redux/DeleteShifts';
import { DeleteShiftsById as UsecaseRemoteDeleteShiftsById } from '~/domain/usecases/shifts/remote';

import { makeApiUrl, makeHttpClient } from '../../http';

import { RemoteDeleteShiftsById } from '~/data/repository/shifts';
import { ReduxDeleteShiftsById } from '~/data/store/reducer/shifts/usecases/ReduxDeleteShifts';

export const makeRemoteDeleteShiftsById = (): UsecaseRemoteDeleteShiftsById =>
  new RemoteDeleteShiftsById(
    makeApiUrl('/oncall/{onCallId}/shifts'),
    makeHttpClient(),
  );

export const makeReduxDeleteShifts = (): UsecaseReduxDeleteShifts =>
  new ReduxDeleteShiftsById();
