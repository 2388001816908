/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { translator } from '../i18n';
import { PropsArray } from './interface';
import ItemBillingAcordeon from './ItemBillingAcordeon';
import { GridHeader, Header } from './styles/StyledBillingAcordeon';

const ControlledAccordions: React.FC<PropsArray> = ({
  invoiceServiceItems,
  invoiceId,
  setShow,
  show,
}) => {
  function handleCollapseStatus() {
    // aqui onde muda a cor do botão
    setShow?.((prevState: boolean) => {
      if (!prevState) {
        return true;
      }
      return !prevState;
    });
  }

  return (
    <div style={{ width: '100%' }}>
      <GridHeader>
        <Header>
          <p>{translator('Descrição')}</p>
          <p>{translator('Franquia')}</p>
          <p>{translator('Utilizados')}</p>
          <p>{translator('Unidades')}</p>
          <p>{translator('Valor unitário')}</p>
          <p>{translator('Valor total')}</p>
        </Header>
      </GridHeader>
      <div
        style={{ width: '100%', maxHeight: '300px', overflowY: 'auto' }}
        onClick={handleCollapseStatus}
      >
        {invoiceServiceItems?.services?.map(info => (
          <ItemBillingAcordeon
            fullInvoice={invoiceServiceItems}
            invoiceServiceItems={info}
            show={show}
            setShow={setShow}
            key={info.id}
          />
        ))}
      </div>
    </div>
  );
};

export default ControlledAccordions;
