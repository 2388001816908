import { CreateAdministrator } from '~/domain/usecases/administrator/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';

export class RemoteCreateAdministrator implements CreateAdministrator {
  private readonly url: string;

  private readonly httpClient: HttpClient<CreateAdministrator.Model>;

  constructor(url: string, httClient: HttpClient<CreateAdministrator.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  create = async (
    params: CreateAdministrator.Params,
  ): Promise<CreateAdministrator.Model> => {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.unprocessableEntity:
        throw new UnexpectedError();
      default:
        throw new UnexpectedError();
    }
  };
}
