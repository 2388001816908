import { call, put, select } from 'redux-saga/effects';
import { iActionListExamTypes } from '~/domain/interfaces/redux/diagnosisReport/listExamTypes';
import { ListExamTypesDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote/ListExamTypesDiagnosisReport';
import { makeRemoteListExamTypesDiagnosisReport } from '~/main/factories/usecases/diagnosisReport/ListExamTypesDiagnosisReportFactory';
import {
  listExamTypesSuccess,
  listExamTypesFailed,
} from '~/data/store/reducer/diagnosisReport/actions/listExamTypes';

export function* onListExamTypes(action: iActionListExamTypes) {
  const remoteListExamTypesDiagnosisReport =
    makeRemoteListExamTypesDiagnosisReport();

  try {
    const response: ListExamTypesDiagnosisReport.Model = yield call(
      remoteListExamTypesDiagnosisReport.listExamTypes,
      action.payload,
    );
    yield put(
      listExamTypesSuccess({
        ...response,
      }),
    );
  } catch (e) {
    yield put(listExamTypesFailed());
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListExamTypesSuccess() {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function onListExamTypesFailed() {}
