import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1000px;
  width: 100%;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 30px;
  grid-template-areas: 'c c c' 'c c c' 'obs obs obs' 'transcription transcription transcription' 'footer footer footer';
`;
