import React, { createContext, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetDiagnosisContext } from './loadData';

interface RemoteReportContextData {
  disableField: boolean;

  cancelEdit: () => void;
  startEdit: () => void;
}

export const RemoteReportContext = createContext<
  RemoteReportContextData | undefined
>(undefined);

export const useRemoteReport = (): RemoteReportContextData => {
  const context = useContext(RemoteReportContext);
  if (!context) {
    throw new Error(
      'useRemoteReport must be used within a RemoteReportProvider',
    );
  }
  return context;
};

export const RemoteReportProvider: React.FC = ({ children }) => {
  const url = new URL(window.location.href);
  const [disableField, setDisableField] = useState(
    () => url.searchParams.get('edit') !== 'true',
  );

  const { loadDiagnosisData } = useGetDiagnosisContext();
  const { reset } = useFormContext();

  const contextValue: RemoteReportContextData = {
    disableField,
    cancelEdit: () => {
      reset();
      setDisableField(true);
      url.searchParams.set('edit', 'false');
      window.history.replaceState({}, '', url.toString());
      loadDiagnosisData();
    },
    startEdit() {
      setDisableField(false);
      url.searchParams.set('edit', 'true');
      window.history.replaceState({}, '', url.toString());
    },
  };

  return (
    <RemoteReportContext.Provider value={contextValue}>
      {children}
    </RemoteReportContext.Provider>
  );
};
