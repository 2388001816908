import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { StopAppointmentOnCall as UsecaseRemoteStopAppointmentOnCall } from '~/domain/usecases/onCall/remote';
import { StopAppointmentOnCall as UsecaseReduxStopAppointmentOnCall } from '~/domain/usecases/onCall/redux';

import { RemoteStopAppointmentOnCall } from '~/data/repository/onCall';
import { ReduxStopAppointmentOnCall } from '~/data/store/reducer/onCall/usecases';

/**
 * send request via API.
 */
export const makeRemoteStopAppointmentOnCall =
  (): UsecaseRemoteStopAppointmentOnCall =>
    new RemoteStopAppointmentOnCall(
      makeApiUrl(
        '/oncall/{onCallId}/specialists/{specialistId}/attend/{requesterId}/STOP',
      ),
      makeHttpClient(),
    );

/**
 * send request via REDUX.
 */
export const makeReduxStopAppointmentOnCall =
  (): UsecaseReduxStopAppointmentOnCall => new ReduxStopAppointmentOnCall();
