/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeReduxGetAllClinicalDocs } from '~/main/factories/usecases/clinicalDocs/GetAllClinicalDocsFactory';
import { iStore } from '~/domain/interfaces/models';

import { Unauthorized } from '~/presentation/pages/errors';
import { SupportDocDetailPage } from '~/presentation/pages/documentDetail';
import { V4hSpin } from '~/presentation/components/spin';

export const SupportDocsFactory: React.FC = () => {
  const loading = useSelector((state: iStore) => state.clinicalDocs.loading);
  const { orgId, role } = useSelector((store: iStore) => store.auth.selectUser);

  useEffect(() => {
    makeReduxGetAllClinicalDocs().getAll({
      filter: {
        org: orgId,
        enabled: true,
      },
    });
  }, []);

  if (loading) return <V4hSpin fullScreen background="white" />;

  switch (role) {
    case 'PRO':
      return <SupportDocDetailPage />;
    default:
      return <Unauthorized />;
  }
};
