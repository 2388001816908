import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 515px;

  button {
    max-width: 280px;
  }
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  margin-top: 50px;
`;

export const Image = styled.img`
  margin-right: 2px;
`;

export const TermContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 36px;
  background-color: #f4f7fe;
  margin: 40px 0;

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 19px;
  }

  .message {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
  }
`;

export const CheckTerm = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 40px;
  margin-bottom: 24px;

  input {
    margin-right: 8px;
  }
`;

export const CheckRecorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 75%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 54px;

  input {
    margin-right: 8px;
  }
`;

export const TermsLink = styled.div`
  cursor: pointer;
  color: #005bc8;
`;
