import styled from 'styled-components';

interface ownProps {
  value: number;
}

interface gridProps {
  columns: number;
  padding?: number;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #efefef;
  padding: 24px;
`;

export const ContainerView = styled.div`
  width: 100%;
  height: 100%;
  min-height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
`;
export const IconHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px;

  font-weight: bold;
`;

export const Body = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${props => props.theme.typographyDarkGray};
  font-size: 12px;
  margin: 10px 0;
  .value {
    font-size: 36px;
    color: ${props => props.theme.primaryDark};
    font-weight: bold;
  }
`;

export const Footer = styled.div<ownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  color: ${props =>
    props.value >= 0 ? props.theme.statusSucess : props.theme.statusCancel};
  font-size: 12px;

  div {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
  }

  .text {
    color: ${props => props.theme.typographyDarkGray};
  }
`;

export const GridContainer = styled.div<gridProps>`
  width: 100%;
  height: 100%;
  padding: ${props => props.padding};
  background: #fff;
  /* border: 1px solid #efefef; */
  display: grid;
  overflow: auto;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-template-rows: ${props => props.columns} 2fr;
`;

export const FooterButton = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
`;
