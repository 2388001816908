import React, { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { iStore, iInvoiceResults } from '~/domain/interfaces/models';

import { ownProps, DashboardSelectOptions } from '../interface';

/**
 * Connect component.
 * @param Component componente to connect.
 */
export function ConnectComponent<P>(
  Component: ComponentType<P & ownProps>,
): React.FC<P> {
  const Render: React.FC<P> = ({ ...rest }) => {
    const { invoiceItems } = useSelector((store: iStore) => store.billing);

    Component.defaultProps = {
      dashboardMonths: MapDashboardMonths(invoiceItems),
      ...(rest as P),
    };

    return <Component {...(rest as P & ownProps)} />;
  };

  return Render;
}

/**
 * Map list dashboard months to component.
 * @param dashboardMonths list of dashboardMonths.
 */
export const MapDashboardMonths = (
  dashboard: iInvoiceResults,
): DashboardSelectOptions[] => {
  const now = new Date();

  const currentMonth = now.getMonth() + 1;
  const currentYear = now.getFullYear();

  const currentMonthCapitalize = now.toLocaleString(undefined, {
    month: 'long',
  });

  const currentMonthFormatted = currentMonthCapitalize.replace(
    currentMonthCapitalize[0],
    currentMonthCapitalize.charAt(0).toUpperCase(),
  );

  const arr: DashboardSelectOptions[] = [
    {
      label: `${currentMonthFormatted} ${currentYear}`,
      value: {
        month: currentMonth,
        year: currentYear,
      },
    },
  ];

  dashboard?.records?.forEach(item => {
    const formattedDate = new Date(item.begin);

    const month = formattedDate.toLocaleString(undefined, {
      month: 'long',
    });

    const formattedMonth = month.replace(
      month[0],
      month.charAt(0).toUpperCase(),
    );

    const label = `${formattedMonth} ${formattedDate.getFullYear()}`;

    arr.push({
      label,
      value: {
        month: formattedDate.getMonth() + 1,
        year: formattedDate.getFullYear(),
      },
    });
  });

  const orderedArray = _.orderBy(
    arr,
    item => `${item.value.year}${String(item.value.month).padStart(2, '0')}`,
    ['desc'],
  );

  return orderedArray;
};
