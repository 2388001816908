import { put, call } from 'redux-saga/effects';
import { createIntl, createIntlCache } from 'react-intl';
import translations from '~/infra/i18n/locales';
import { getLocale } from '~/utils/getLocale';

import { iActionAppointmentConfirm } from '~/domain/interfaces/redux/appointment/appointmentConfirmation';

import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

import { getAllRequest } from '~/data/store/reducer/appointment/actions/getAll';

import {
  confirmFailed,
  confirmSuccess,
  confirmRequest,
} from '~/data/store/reducer/appointment/actions/appointmentConfirmation';

import { makeRemotePatchAppointmentConfirmation } from '~/main/factories/usecases/appointment/PatchAppointmentConfirmationFactory';

import { AppointmentConfirmation } from '~/domain/usecases/appointment/redux';

const cache = createIntlCache();

const intl = createIntl(
  {
    locale: String(getLocale()),
    messages: translations[getLocale()],
  },
  cache,
);

export function* onConfirmRequest(action: iActionAppointmentConfirm) {
  const remoteAppointmentConfirm = makeRemotePatchAppointmentConfirmation();

  try {
    const response: AppointmentConfirmation.Model = yield call(
      remoteAppointmentConfirm.patch,
      action.payload,
    );
    yield put(confirmSuccess());
  } catch (err: any) {
    if (err.name === 'conflict') {
      AlertMessage({
        message: intl.formatMessage({ id: 'O agendamento já foi confirmado.' }),
        type: 'danger',
      });
    }
    yield put(confirmFailed());
  }
}

export function* onConfirmSuccess() {
  AlertMessage({
    message: intl.formatMessage({ id: 'Agendamento confirmado com sucesso.' }),
    type: 'success',
  });
  yield put(getAllRequest({}));
}

export function onConfirmFailed() {
  AlertMessage({
    type: 'danger',
    message: intl.formatMessage({ id: 'Falha ao confirmar o atendimento.' }),
  });
}
