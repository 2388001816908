import React, { useEffect, useState } from 'react';

import { HeaderSimples } from '~/presentation/components/header';
import { Menu } from '~/presentation/components/menu';
import SpecialtyCard from '~/presentation/components/specialtyCard';
import {
  Cards,
  Container,
  CardsContainer,
} from './styles/StyledSelectSpecialty';
import { makeRemoteListOnCall } from '~/main/factories/usecases/duty/ListOnCall';
import { ListOnCall } from '~/domain/usecases/duty/remote';

const SelectSpecialtyPage: React.FC = () => {
  const [specialty, setSpecialty] = useState<ListOnCall.Model['records']>();

  useEffect(() => {
    makeRemoteListOnCall()
      .get({})
      .then(res => {
        setSpecialty(res.records);
      })
      .catch(console.error);
  }, []);

  return (
    <Container>
      <Menu />
      <HeaderSimples title="Escolha uma especialidade" />
      <CardsContainer>
        <Cards>
          {specialty?.map(specialtyItem => (
            <SpecialtyCard
              key={specialtyItem?.id}
              icon={specialtyItem?.logo}
              name={specialtyItem?.descr}
              id={specialtyItem?.id}
            />
          ))}
        </Cards>
      </CardsContainer>
    </Container>
  );
};

export default SelectSpecialtyPage;
