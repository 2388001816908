import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { SignDiagnosisReport as UsecaseRemoteSignDiagnosisReport } from '~/domain/usecases/diagnosisReport/remote';
// import { SignDiagnosisReport as UsecaseReduxSignDiagnosisReport } from '~/domain/usecases/diagnosisReport/redux';

import { RemoteSignDiagnosisReport } from '~/data/repository/diagnosisReport';
// import { ReduxSignDiagnosisReport } from '~/data/store/reducer/diagnosisReport/usecases';

/**
 * send request via API.
 */
export const makeRemoteSignDiagnosisReport =
  (): UsecaseRemoteSignDiagnosisReport =>
    new RemoteSignDiagnosisReport(
      makeApiUrl('/reports/{report}/SIGN'),
      makeHttpClient()
    );

/**
 * send request via REDUX.
 */
/* export const makeReduxSignDiagnosisReport =
  (): UsecaseReduxSignDiagnosisReport =>
    new ReduxSignDiagnosisReport(); */
