import { UpdateSpecialty } from '~/domain/usecases/specialty/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadRequestError,
} from '~/domain/errors';
import '~/infra/global/variables';
import { AlertMessage } from '~/presentation/components/messages/AlertMessage';

export class RemoteUpdateSpecialty implements UpdateSpecialty {
  private readonly url: string;

  private readonly httpClient: HttpClient<UpdateSpecialty.Model>;

  constructor(url: string, httClient: HttpClient<UpdateSpecialty.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  update = async (
    params: UpdateSpecialty.Params,
  ): Promise<UpdateSpecialty.Model> => {
    // const { d } = params.specialties;
    // const { idSpecialty } = params.specialties;

    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.idProfessional}/specialties/${params.idSpecialty}`,
      method: 'patch',
      body: params.specialties,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.badRequest:
        throw new BadRequestError();
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      default:
        throw new UnexpectedError();
    }
  };
}
