/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FormEvent, useCallback, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { translator } from '~/presentation/components/i18n';
import { Button, Input } from '~/presentation/components/UI';
import { schemaPrescription } from '~/validation/validators/document/BasicPrescriptionContentValidator';
import Navigator from '../Navigator';
import { iPrescription, iValidation } from '../interface';
import ItemDrugs from '../ItemDrugs';
import {
  ButtonAdd,
  Container,
  ContainerItemDrugs,
  InfoAddDrugsText,
  ListDrugsTitle,
  Form,
} from '../styles/StyledMedication';

export interface ownProps {
  items: iPrescription[];
  setItems: Function;
  next: (data: iValidation) => void;
  back: (data: iValidation) => void;
  arrId: number;
  setArrId: Function;
  state?: iValidation;
}

const Medication: React.FC<ownProps> = props => {
  const { errors, handleSubmit, register, setValue, unregister } = useForm({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver(schemaPrescription),
    defaultValues: {
      dosage: props.state?.dosage || '',
      name: props.state?.name || '',
      posology: props.state?.posology || '',
    },
  });

  const [arrPrescription, setArrPrescription] = useState<iPrescription>({
    name: '',
    dosage: '',
    posology: '',
  });

  const [updateButton, setUpdateButton] = useState(false);
  const [validation, setValidation] = useState<boolean>(true);
  const [updatePrescriptionId, setUpdatePrescriptionId] = useState(0);

  const inputRef = useRef<HTMLInputElement>(null);

  function clearFields() {
    props.setArrId((prevState: number) => prevState + 1);

    setArrPrescription({ name: '', dosage: '', posology: '' });

    unregister('name');
    unregister('dosage');
    unregister('posology');
  }

  const addPrescription = handleSubmit(data => {
    props.setItems([...props.items, { ...data, id: props.arrId }]);

    clearFields();
  });

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    props.next({ drugs: props.items });
  };

  function setPrescription(id: number) {
    const result = props.items.filter(item => item.id === id);
    setUpdatePrescriptionId(id);
    setArrPrescription({
      name: result[0].name,
      dosage: result[0].dosage,
      posology: result[0].posology,
    });
    setValue('name', result[0].name);
    setValue('dosage', result[0].dosage);
    setValue('posology', result[0].posology);
  }

  function updatePrescription() {
    setUpdateButton(false);

    props.setItems((prevState: iPrescription[]) =>
      prevState.map((item: iPrescription) => {
        if (item.id === updatePrescriptionId) {
          return {
            ...item,
            ...{
              name: arrPrescription.name,
              dosage: arrPrescription.dosage,
              posology: arrPrescription.posology,
            },
          };
        }
        return item;
      }),
    );

    clearFields();
  }

  const onBack = () => props.back({ drugs: props.items });

  const handleInputFocus = useCallback(() => {
    if (inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <Container onSubmit={onSubmit}>
      <Form>
        <Input
          name="name"
          label={`${translator('Droga')}`}
          placeholder={`${translator('Insira o nome da droga')}`}
          onChange={e => {
            setValue('name', e.target.value);
            setArrPrescription({
              ...arrPrescription,
              [e.target.name]: e.target.value,
            });
          }}
          register={() => register('name')}
          value={arrPrescription.name}
          error={Boolean(errors.name)}
          message={errors.name?.message ? translator(errors.name?.message) : ''}
          ref={inputRef}
          autoFocus
          required
        />
        <Input
          name="dosage"
          label={`${translator('Dosagem')}`}
          placeholder={`${translator('Insira a dosagem')}`}
          onChange={e => {
            setValue('dosage', e.target.value);
            setArrPrescription({
              ...arrPrescription,
              [e.target.name]: e.target.value,
            });
          }}
          register={() => register('dosage')}
          error={Boolean(errors.dosage)}
          value={arrPrescription.dosage}
          message={
            errors.dosage?.message ? translator(errors.dosage?.message) : ''
          }
          required
        />
        <Input
          name="posology"
          placeholder={`${translator('Insira a posologia da medicação')}`}
          label="Posologia"
          onChange={e => {
            setValue('posology', e.target.value);
            setArrPrescription({
              ...arrPrescription,
              [e.target.name]: e.target.value,
            });
          }}
          register={() => register('posology')}
          value={arrPrescription.posology}
          error={Boolean(errors.posology)}
          message={
            errors.posology?.message ? translator(errors.posology?.message) : ''
          }
          required
        />
        <ButtonAdd>
          {updateButton ? (
            <>
              <InfoAddDrugsText>
                {`${translator(
                  'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita',
                )}`}
              </InfoAddDrugsText>
            </>
          ) : (
            <>
              <InfoAddDrugsText>
                {`${translator(
                  'Após preencher os campos, clique em “Adicionar” para incluir a medicação na prescrição da receita',
                )}`}
              </InfoAddDrugsText>
            </>
          )}
        </ButtonAdd>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // margin: '15px 0',
          }}
        >
          {updateButton ? (
            <Button
              id="btn_update"
              size="medium"
              rounded
              variant="secundary"
              onClick={updatePrescription}
            >
              {'Atualizar >>'}
            </Button>
          ) : (
            <Button
              id="btn_addDrug"
              size="medium"
              rounded
              variant="secundary"
              onClick={() => {
                handleInputFocus();
                addPrescription();
              }}
            >
              {`${translator('Adicionar >>')}`}
            </Button>
          )}
        </div>
        <ListDrugsTitle>
          <p>Drogas inseridas</p>
        </ListDrugsTitle>

        <ContainerItemDrugs>
          <ItemDrugs
            setContent={props.setItems}
            content={props.items}
            updatePrescription={setPrescription}
            setUpdateButton={setUpdateButton}
          />
        </ContainerItemDrugs>
      </Form>
      <Navigator
        back={onBack}
        isDisable={props.items.length !== 0 ? !validation : validation}
      />
    </Container>
  );
};

export default Medication;
