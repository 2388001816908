/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable react/jsx-curly-brace-presence */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import _, { set } from 'lodash';
import { iStore } from '~/domain/interfaces/models';
import { iMessage } from '~/domain/interfaces/models/Message';
import { MessageOptions } from '~/domain/interfaces/redux/message';
import { RegionalDoc } from '~/domain/usecases/regionaldoc/remote';
import { makeReduxGetAllHealthUnits } from '~/main/factories/usecases/healthUnits/GetAll';
import { makeRemoteGetAllRegionalDocs } from '~/main/factories/usecases/regionaldoc/GetAllRegionalDocs';
import {
  IconCloseModal,
  IconOtherProfessional,
  IconUserGray,
} from '~/presentation/base/icons';
import { Button, Input, Select } from '~/presentation/components/UI';
import { schemaJoinOnCall } from '~/validation/validators/duty/joinOnCallValidator';
import { makeRemoteListOnCall } from '~/main/factories/usecases/duty/ListOnCall';
import { ListOnCall } from '~/domain/usecases/duty/remote';
import { makeRemoteCreateOnCallRequest } from '~/main/factories/usecases/onCallRequest/CreateOnCallRequestFactory';
import { makeReduxActiveMessage } from '~/main/factories/usecases/message/Update';
import { closeModal } from '~/utils/closeModal';
import {
  makeReduxListOnCallRequest,
  makeRemoteListOnCallRequest,
} from '~/main/factories/usecases/onCallRequest/ListOnCallRequestFactory';
import { ListOnCallRequest } from '~/domain/usecases/onCallRequest/remote';
import { makeRemoteUpdateOnCallRequest } from '~/main/factories/usecases/onCallRequest/UpdateOnCallRequestFactory';
import {
  Control,
  DropdownIndicator,
  Option,
} from '../filter/styles/StyledFilterAppointmentHistory';
import { Label } from '../UI/select/styles';
import {
  AppointmentInfo,
  Box,
  BoxIcon,
  Container,
  ContainerModal,
  Content,
  Divider,
  Footer,
  HeaderContent,
  HeaderModal,
  InformationTitle,
  InlineInputs,
  InputContent,
  MessageError,
  PatientInfo,
  SubTitle,
  TextAreaForm,
  Title,
  selectStyles,
} from './styles/StyledModalRequestConsultation';
import { iField } from '../register/PersonalData';
import { AlertMessage } from '../messages/AlertMessage';

interface propsModalRequestConsultation {
  message: iMessage;
}

export type JoinOnCallBody = {
  name?: string;
  age?: number;
  sex?: 'NONE' | 'M' | 'F';
  situation?: string;
  susCard?: string;
  organization?: number;
  uf?: string;
  regType?: string;
  regValue?: string;
  city?: string;
  priority?: 'LOW' | 'NORMAL' | 'EMERGENCY';
  specialty: number;
  birthDate?: string;
};

const ModalRequestConsultation: React.FC<propsModalRequestConsultation> = ({
  message,
}) => {
  const [regionalDocs, setRegionalDocs] = useState<RegionalDoc[]>([]);
  const [appointmentSpecialty, setAppointmentSpecialty] = useState<string>('');
  const [specialty, setSpecialty] = useState<ListOnCall.Model['records']>();
  const [field, setField] = useState<iField>({
    name: 'CPF',
    descr: 'Cadastro de Pessoa Física',
  });
  const [selectedRequest, setSelectedRequest] =
    useState<ListOnCallRequest.Model['records']>();
  const auth = useSelector((store: iStore) => store.auth.info);
  const { orgUnitId } = useSelector((store: iStore) => store.auth.selectUser);
  const professional = auth.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  );

  const { active, actionCancel, data, userId } = message;

  const msgName = MessageOptions.requestConsultation;

  const {
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    control,
    formState: { errors, isValid },
  } = useForm<JoinOnCallBody>({
    mode: 'onChange',
    shouldFocusError: true,
    resolver: zodResolver<any>(schemaJoinOnCall),
    defaultValues: {
      sex: 'NONE',
      uf: 'PB',
      organization: 1,
    },
  });

  const handleCancel = () => {
    clearErrors();
    reset();
    // setSelectedRequest(selectedRequest);
    actionCancel?.();
  };

  const onSubmit = () => {
    const birthDateBack = watch('birthDate')?.split('/');

    if (data?.isEditing) {
      makeRemoteUpdateOnCallRequest()
        .update({
          requestId: Number(userId),
          body: {
            description: watch('situation') ?? undefined,
            priority: watch('priority') ?? undefined,
            consultant: {
              birthdate: watch('birthDate') ?? undefined,
              docType: watch('regType') ?? undefined,
              docValue: watch('regValue') ?? undefined,
              fullname: watch('name') ?? undefined,
              sex: watch('sex'),
            },
          },
        })
        .then(() => {
          AlertMessage({
            message: 'Solicitação atualizada com sucesso!',
            type: 'success',
          });
          data?.onSuccess();
          closeModal();
        })
        .catch(() => {
          AlertMessage({
            message: 'Falha ao atualizar a solicitação!',
            type: 'danger',
          });

          closeModal();
        });
    } else {
      makeRemoteCreateOnCallRequest()
        .create({
          onCall: Number(watch('specialty')),
          healthUnit: data?.isEditing
            ? selectedRequest?.[0]?.healthUnit?.id
            : data?.healthUnitId,
          consultant: {
            birthdate:
              birthDateBack && birthDateBack?.length >= 2
                ? `${birthDateBack[0]}/${birthDateBack[1]}/${birthDateBack[2]}`
                : undefined,
            docType: watch('regType'),
            docValue: watch('regValue'),
            fullname: watch('name'),
            sex: watch('sex') === 'NONE' ? undefined : watch('sex'),
          },
          description: watch('situation'),
          priority: watch('priority') ? watch('priority') : 'LOW',
          requester: professional?.id,
        })
        .then(() => {
          makeReduxActiveMessage().active({
            active: 'consultationRequestSuccess',
            appointmentSpecialty,
            actionCancel: () => {
              closeModal();
            },
          });
          makeReduxListOnCallRequest().list({
            body: {
              dataControl: {
                limit: 9999,
              },
              filters: {
                requester: professional?.id,
                healthUnit: data?.healthUnitId,
                status: 'WAITING',
              },
            },
          });
        })
        .catch(() => {
          AlertMessage({
            message: 'Falha ao enviar solicitação!',
            type: 'danger',
          });
        });
    }
    reset();
  };

  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  const defaultValueRegionalDocs = useMemo(() => {
    const doc = regionalDocs.find(item => item.name === watch('regType'));

    return doc;
  }, [regionalDocs, watch('regType')]);

  useEffect(() => {
    if (active !== msgName) return;

    makeRemoteGetAllRegionalDocs()
      .getAll({
        disablePagination: true,
        country: 'BRA',
      })
      .then(res => {
        setRegionalDocs(res.records);
      })
      .catch(() => {
        AlertMessage({
          message: 'Falha ao carregar documentos regionais!',
          type: 'danger',
        });
      });

    makeRemoteListOnCall()
      .get({})
      .then(res => {
        setSpecialty(res.records);
      })
      .catch(() => {
        AlertMessage({
          message: 'Falha ao carregar especialidades!',
          type: 'danger',
        });
      });

    makeReduxGetAllHealthUnits().getAll({
      limit: 9999,
    });
  }, [active, msgName]);

  useEffect(() => {
    if (userId) {
      makeRemoteListOnCallRequest()
        .list({
          body: {
            filters: {
              id: Number(userId),
            },
          },
        })
        .then(res => {
          setSelectedRequest(res.records);
        })
        .catch(() => {
          console.log('error');
        });
    }
  }, [userId, data?.isEditing]);

  // Adiciona os valores default na hora de editar
  useEffect(() => {
    if (active !== msgName) return;
    if (!data?.isEditing) {
      reset();
      return;
    }
    if (selectedRequest?.length) {
      setValue('name', selectedRequest?.[0]?.consultant?.fullname);
      setValue('sex', selectedRequest?.[0]?.consultant.sex);
      setValue('birthDate', selectedRequest?.[0]?.consultant?.birthdate);
      setValue('regType', selectedRequest?.[0]?.consultant?.docType);
      setValue('regValue', selectedRequest?.[0]?.consultant?.docValue);
      setValue('specialty', selectedRequest?.[0]?.onCall?.specialtyId);
      setValue('priority', selectedRequest?.[0]?.priority);
      setValue('situation', selectedRequest?.[0]?.description);
      setValue('organization', selectedRequest?.[0]?.healthUnit?.id);
    }
  }, [selectedRequest, data?.isEditing]);

  useEffect(() => {
    if (defaultValueRegionalDocs) {
      setField({
        name: defaultValueRegionalDocs.name,
        descr: defaultValueRegionalDocs.descr,
        mask: defaultValueRegionalDocs.mask,
        id: defaultValueRegionalDocs.id,
        regex: defaultValueRegionalDocs.regex,
      });
    }
  }, [defaultValueRegionalDocs]);

  return (
    <>
      {msgName === active && (
        <ContainerModal>
          <Container onSubmit={handleSubmit(onSubmit)}>
            <HeaderModal>
              <HeaderContent>
                <Title>Solicitar interconsulta</Title>
                <BoxIcon onClick={handleCancel}>
                  <IconCloseModal />
                </BoxIcon>
              </HeaderContent>
              <SubTitle>
                Você será notificado assim que o profissional especialista
                aceitar sua solicitação.
              </SubTitle>
            </HeaderModal>
            <Divider />
            <Content>
              <PatientInfo>
                <InformationTitle style={{ marginBottom: 16 }}>
                  <IconUserGray />
                  Dados do paciente
                </InformationTitle>
                <InputContent>
                  <Controller
                    control={control}
                    name="name"
                    render={({ value }) => (
                      <Input
                        label="Nome do paciente"
                        name="name"
                        value={value}
                        placeholder="Digite seu nome"
                        onChange={e =>
                          setValue('name', e.target.value, {
                            shouldValidate: true,
                          })
                        }
                        error={Boolean(errors.name)}
                        autoFocus
                        message={errors?.name?.message}
                      />
                    )}
                  />
                  <InlineInputs>
                    <Controller
                      control={control}
                      name="sex"
                      render={({ value, onChange }) => (
                        <Select
                          label="Sexo"
                          value={value}
                          width="50%"
                          onChange={onChange}
                          error={Boolean(errors.sex)}
                        >
                          <option value="NONE">Escolher sexo</option>
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </Select>
                      )}
                    />

                    <Controller
                      control={control}
                      name="birthDate"
                      render={({ value, onChange }) => (
                        <InputMask
                          mask={'99/99/9999'}
                          name="birthDate"
                          className="birthdate"
                          value={value}
                          onChange={onChange}
                        >
                          <Input
                            label="Data de nascimento"
                            width="50%"
                            value={value}
                            name="birthDate"
                            error={Boolean(errors.birthDate)}
                            message={
                              Boolean(errors.birthDate) &&
                              Boolean(errors.birthDate?.message)
                                ? String(errors.birthDate?.message)
                                : ''
                            }
                          />
                        </InputMask>
                      )}
                    />
                  </InlineInputs>
                  <InlineInputs>
                    <Controller
                      control={control}
                      name="regType"
                      render={({ onChange, value }) => (
                        <Select
                          width="50%"
                          label="Documento"
                          onChange={e => {
                            onChange(e.target.value);
                            setValue('regValue', '');
                          }}
                          value={value}
                          // error={
                          //   !data?.isEditing &&
                          //   Boolean(errors.regType) &&
                          //   Boolean(errors.regType?.message)
                          // }
                          // message={
                          //   !data?.isEditing &&
                          //   Boolean(errors.regType) &&
                          //   Boolean(errors.regType?.message)
                          //     ? String(errors.regType?.message)
                          //     : ''
                          // }
                        >
                          <option value="">Selecione</option>
                          {regionalDocs.length &&
                            regionalDocs.map(item => (
                              <option
                                id={`registerOption_${item.id}`}
                                value={item.name}
                              >
                                {item.name}
                              </option>
                            ))}
                        </Select>
                      )}
                    />

                    <Controller
                      control={control}
                      name="regValue"
                      render={({ onChange, value }) => (
                        <>
                          {field?.mask === '99-99999999-9' ||
                          field?.mask === '999.999.999-99' ||
                          field?.mask === '9999.99999.999999' ? (
                            <InputMask
                              mask={field?.mask}
                              name="regValue"
                              className="regValue"
                              value={value}
                              onChange={e => {
                                onChange(e.target.value.trim());
                              }}
                            >
                              <Input
                                width="50%"
                                label="Número do documento"
                                error={
                                  Boolean(errors.regValue) &&
                                  Boolean(errors.regValue?.message)
                                }
                                message={
                                  errors.regValue?.message
                                    ? String(errors.regValue?.message)
                                    : ''
                                }
                              />
                            </InputMask>
                          ) : (
                            <Input
                              width="50%"
                              label="Número do documento"
                              placeholder="0000000000"
                              onChange={e => onChange(e.target.value)}
                              value={value}
                              error={
                                Boolean(errors.regValue) &&
                                Boolean(errors.regValue?.message)
                              }
                              message={
                                errors.regValue?.message
                                  ? String(errors.regValue?.message)
                                  : ''
                              }
                            />
                          )}
                        </>
                      )}
                    />
                  </InlineInputs>
                </InputContent>
              </PatientInfo>
              <AppointmentInfo>
                <InformationTitle style={{ marginBottom: 16 }}>
                  <IconOtherProfessional />
                  Informações do atendimento
                </InformationTitle>
                <InlineInputs>
                  <Controller
                    control={control}
                    name="specialty"
                    render={({ value, onChange }) => (
                      <Select
                        label="Especialidade do atendimento"
                        width="65%"
                        name="specialty"
                        required
                        value={value}
                        onChange={e => {
                          onChange(Number(e.target.value));
                          setAppointmentSpecialty(e.target.value);
                        }}
                        error={Boolean(errors.specialty)}
                        message={
                          errors?.specialty?.message &&
                          String(errors?.specialty?.message)
                        }
                        disabled={data?.isEditing}
                      >
                        <option value="">Selecione</option>
                        {specialty?.map(item => (
                          <option value={item?.id}>{item.descr}</option>
                        ))}
                      </Select>
                    )}
                  />

                  <Controller
                    control={control}
                    name="priority"
                    render={({ value, onChange }) => (
                      <Select
                        label="Prioridade"
                        width="35%"
                        value={value}
                        name="priority"
                        onChange={e => onChange(e.target.value)}
                        disabled={data?.isEditing}
                        error={Boolean(errors.priority)}
                      >
                        <option value="LOW">Baixa</option>
                        <option value="NORMAL">Média</option>
                        <option value="EMERGENCY">Alta</option>
                      </Select>
                    )}
                  />
                </InlineInputs>

                <InlineInputs style={{ marginTop: 18 }}>
                  <Controller
                    control={control}
                    name="uf"
                    render={({ value }) => (
                      <Select
                        name="uf"
                        label="UF"
                        width="25%"
                        value={value}
                        disabled
                        error={Boolean(errors.uf)}
                        placeholder="Estado"
                      >
                        <option value="PB">PB</option>
                      </Select>
                    )}
                  />

                  <Controller
                    control={control}
                    name="organization"
                    render={({ value }) => (
                      <Select
                        label="Estabelecimento de saúde"
                        name="organization"
                        required
                        width="75%"
                        value={
                          data?.isEditing
                            ? selectedRequest?.[0]?.healthUnit?.id
                            : data?.healthUnitId
                        }
                        disabled
                        error={Boolean(errors.organization)}
                      >
                        <option
                          value={
                            data?.isEditing
                              ? selectedRequest?.[0]?.healthUnit?.id
                              : data?.healthUnitId
                          }
                        >
                          {data?.isEditing
                            ? selectedRequest?.[0]?.healthUnit?.name
                            : data?.healthUnitName}
                        </option>
                      </Select>
                    )}
                  />
                </InlineInputs>

                <InlineInputs style={{ marginTop: 22 }}>
                  <TextAreaForm>
                    <h3>Subjetivo e Objetivo</h3>
                    <Controller
                      control={control}
                      name="situation"
                      rules={{
                        maxLength: {
                          value: 4096,
                          message:
                            'Você atingiu o limite de 4096 caracteres para este campo.',
                        },
                      }}
                      render={({ value }) => (
                        <>
                          <textarea
                            style={{
                              border: errors.situation && '1px solid red',
                              width: '100%',
                              height: '120px',
                            }}
                            placeholder="Descreva a razão pela qual solicita uma interconsulta."
                            name="situation"
                            disabled={data?.isEditing}
                            value={value}
                            onChange={e =>
                              setValue('situation', e.target.value, {
                                shouldValidate: true,
                              })
                            }
                          />
                          {errors?.situation && (
                            <MessageError>
                              {errors?.situation?.message}
                            </MessageError>
                          )}
                        </>
                      )}
                    />
                  </TextAreaForm>
                </InlineInputs>
              </AppointmentInfo>
            </Content>
            <Divider />
            <Box
              style={{
                backgroundColor: '#F8F8F8',
                borderBottomLeftRadius: 16,
                borderBottomRightRadius: 16,
              }}
            >
              <Footer>
                <Button
                  variant="secundary"
                  type="button"
                  style={{ width: '233px' }}
                  onClick={handleCancel}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  style={{ width: '233px' }}
                  onKeyPress={e => handleKeyPress(e)}
                  disabled={data?.isEditing ? false : !watch('specialty')}
                >
                  Enviar Solicitação
                </Button>
              </Footer>
            </Box>
          </Container>
        </ContainerModal>
      )}
    </>
  );
};

export default ModalRequestConsultation;
