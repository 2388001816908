import { Dispatch } from 'redux';
import store from '~/data/store';
import { getAllRequest } from '~/data/store/reducer/appointmentType/actions/getAll';

import { iActionGetAll } from '~/domain/interfaces/redux/appointmentType/getAll';

import { GetAllAppointmentType } from '~/domain/usecases/appointmentType/redux';

export class ReduxGetAllAppointmentType implements GetAllAppointmentType {
  private send: Dispatch;

  constructor() {
    this.send = store.dispatch;
  }

  list = (params: GetAllAppointmentType.Params): iActionGetAll =>
    this.send(getAllRequest(params));
}
