import { UpdateSpecialty } from '~/domain/usecases/specialty/redux';
import {
  iActionUpdate,
  iActionUpdateFailed,
  iActionUpdateSuccess,
  SpecialtyTypes,
} from '~/domain/interfaces/redux/specialty/update';

export const updateRequest = (
  payload: UpdateSpecialty.Params,
): iActionUpdate => ({
  type: SpecialtyTypes.UPDATE,
  payload,
});

export const updateSuccess = (): iActionUpdateSuccess => ({
  type: SpecialtyTypes.UPDATE_SUCCESS,
});

export const updateFailed = (): iActionUpdateFailed => ({
  type: SpecialtyTypes.UPDATE_FAILED,
});
