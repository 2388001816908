import { iDutyRequesters } from '~/domain/interfaces/models/Duty';
import { DutyTypes } from '~/domain/interfaces/redux/duty/types';
import { DutyRequestersActions } from './actions';

export const initialState: iDutyRequesters = {
  loading: false,
  dutyRequesters: { records: [] },
  professionalOnDuty: { records: [] },
};

const reducer = (
  state = initialState,
  action: DutyRequestersActions,
): iDutyRequesters => {
  switch (action.type) {
    case DutyTypes.GET_DUTY_REQUESTERS:
    case DutyTypes.GET_PROFESSIONAL_ON_DUTY:
      return {
        ...state,
        loading: true,
      };

    case DutyTypes.GET_DUTY_REQUESTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        dutyRequesters: action.payload,
      };

    case DutyTypes.GET_PROFESSIONAL_ON_DUTY_SUCCESS:
      console.log('action.payload 1', action.payload);
      return {
        ...state,
        loading: false,
        professionalOnDuty: action.payload,
      };

    case DutyTypes.GET_DUTY_REQUESTERS_FAILED:
    case DutyTypes.GET_PROFESSIONAL_ON_DUTY_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
