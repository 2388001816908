import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { History } from '~/main/routes';
import { AvatarImage } from '~/presentation/components/avatar-roles';
import { translator } from '~/presentation/components/i18n';

import {
  Content,
  Header,
  Infos,
  UserInfo,
  PhotoView,
  LinkProfile,
  Name,
  Contact,
  Address,
  Email,
} from './styles/StyledUserInformation';

interface PropsInfo {
  id: number;
  user: number;
  name: string;
  recordAdvice?: string;
  specialty?: string;
}

interface User {
  user?: PropsInfo;
}

const InfoUser: React.FC<User> = ({ user }) => {
  return (
    <Content>
      <Header>{`${translator('Dados do profissional de saúde')}`}</Header>
      <Infos>
        <PhotoView>
          <AvatarImage size="large" name={user?.name} />
          {/* TODO: Issue #195 */}
          {/* <LinkProfile
            onClick={() => {
              const url = window.location.pathname;
              History.getHistory().push(`/profile/${user?.user}`, {
                from: url.replaceAll('/', ''),
              });
            }}
          >
            {translator('Ver perfil')}
          </LinkProfile> */}
        </PhotoView>
        <UserInfo>
          <Name>
            {`${translator('Nome')}:`}
            {!user?.name ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.name}</div>
            )}
          </Name>
          <Contact>
            {`${translator('Registro no conselho profissional')}:`}
            {!user?.recordAdvice ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.recordAdvice}</div>
            )}
          </Contact>
          <Address>
            {`${translator('Especialidade')}:`}
            {!user?.specialty ? (
              <Skeleton animation="wave" variant="text" width="150px" />
            ) : (
              <div className="info">{user?.specialty}</div>
            )}
          </Address>
          {/* <Email>
            {`${translator('Gênero')}:`}
            <div className="info">{user?.genre}</div>
          </Email> */}
        </UserInfo>
      </Infos>
    </Content>
  );
};

export default InfoUser;
