import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.background};
`;

export const Body = styled.div`
  padding-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LockScroll = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
`;
