import {
  iSignClinicalDocs,
  iSignClinicalDocsSuccess,
  ClinicalDocsTypes,
  iSignClinicalDocsFailed,
} from '~/domain/interfaces/redux/clinicalDocs/sign';
import { SignClinicalDocs } from '~/domain/usecases/clinicalDocs/redux/SignClinicalDocs';

export const SignRequest = (
  payload: SignClinicalDocs.Params,
): iSignClinicalDocs => ({
  type: ClinicalDocsTypes.SIGN,
  payload,
});

export const SignSuccess = (): iSignClinicalDocsSuccess => ({
  type: ClinicalDocsTypes.SIGN_SUCCESS,
});

export const SignFailed = (): iSignClinicalDocsFailed => ({
  type: ClinicalDocsTypes.SIGN_FAILED,
});
