import styled from 'styled-components';

interface iBar {
  active: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MiniBar = styled.div<iBar>`
  max-width: 40px;
  height: 2px;
  width: 100%;
  background: ${props =>
    props.active ? props.theme.primaryDark : props.theme.typographyLightGray};
`;

export const Bar = styled.div<iBar>`
  height: 2px;
  width: 100%;
  background: ${props =>
    props.active ? props.theme.primaryDark : props.theme.typographyLightGray};
`;
