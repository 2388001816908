import { DeleteOrgUnit } from '~/domain/usecases/orgUnit/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  InvalidCredentialsError,
  UnexpectedError,
  BadGateway,
  NotFound,
  Forbidden,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteDeleteOrgUnit implements DeleteOrgUnit {
  private readonly url: string;

  private readonly httpClient: HttpClient<DeleteOrgUnit.Model>;

  constructor(url: string, httClient: HttpClient<DeleteOrgUnit.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  delete = async (
    params: DeleteOrgUnit.Params,
  ): Promise<DeleteOrgUnit.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.ouId}`,
      method: 'delete',
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      case HttpStatusCode.badGateway:
        throw new BadGateway();
      default:
        throw new UnexpectedError();
    }
  };
}
