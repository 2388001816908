import styled, { css } from 'styled-components';

export const ContainerCards = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 202px;
  border-radius: 10px;
  height: 140px;
  border: 1px solid #dedede;
  cursor: pointer;
  background-color: #fdfdfd;

  :disabled {
    cursor: not-allowed;
    background-color: #f8f8f8;
    opacity: 0.5;
  }

  :hover:not(:active):not(:disabled) {
    background-color: #ecedee;
  }

  :focus-visible {
    box-shadow: 0px 0px 12px 0px rgba(58, 138, 234, 0.5);
    border: 2px solid #3d94fd;
  }

  :not(:disabled) {
    :active {
      box-shadow: 0px 0px 12px 0px rgba(58, 138, 234, 0.5);
      border: 2px solid #3d94fd;
    }
  }
`;

export const ContentCards = styled.div``;

export const Specialty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  justify-content: center;
`;

export const SpecialtyName = styled.h3`
  font-size: 14px;
  font-weight: 400;
  color: #222529;
  text-transform: capitalize;
`;
