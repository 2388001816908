import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetDashboardUnit as UsecaseRemoteGetDashboardUnit } from '~/domain/usecases/dashboard/remote';

import { RemoteGetDashboardUnit } from '~/data/repository/dashboard';

/**
 * send request via API.
 */
export const makeRemoteGetDashboardUnit = (): UsecaseRemoteGetDashboardUnit =>
  new RemoteGetDashboardUnit(makeApiUrl('/dashboard'), makeHttpClient());
