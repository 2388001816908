import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { LinksAppointment as UsecaseRemoteLinksAppointment } from '~/domain/usecases/appointment/remote';

import { RemoteLinksAppointment } from '~/data/repository/appointment';

/**
 * send request via API.
 */
export const makeRemoteLinksAppointment = (): UsecaseRemoteLinksAppointment =>
  new RemoteLinksAppointment(
    makeApiUrl('/appointments/{appointmentId}/links'),
    makeHttpClient(),
  );
