import { StartAppointment } from '~/domain/usecases/appointment/remote';
import { HttpClient, HttpStatusCode } from '~/data/protocols/http';
import {
  NotFound,
  UnexpectedError,
  Forbidden,
  AccessDeniedError,
} from '~/domain/errors';
import '~/infra/global/variables';

export class RemoteStartAppointment implements StartAppointment {
  private readonly url: string;

  private readonly httpClient: HttpClient<StartAppointment.Model>;

  constructor(url: string, httClient: HttpClient<StartAppointment.Model>) {
    this.url = url;
    this.httpClient = httClient;
  }

  start = async (
    params: StartAppointment.Params,
  ): Promise<StartAppointment.Model> => {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.appointmentId}/join`,
      method: 'patch',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new AccessDeniedError();
      case HttpStatusCode.forbidden:
        throw new Forbidden();
      case HttpStatusCode.notFound:
        throw new NotFound();
      default:
        throw new UnexpectedError();
    }
  };
}
