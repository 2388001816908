import * as datefns from 'date-fns';
import storeDev from '~/data/store';
import { iStore } from '~/domain/interfaces/models';
import { iUser } from '~/domain/interfaces/models/Auth';
import { makeReduxGetAllAppointment } from '~/main/factories/usecases/appointment/GetAllAppointmentFactory';

interface iFilter {
  appointmentId?: number;
  org?: number;
  orgUnit?: number[];
  professional?: number;
  professionalName?: string;
  consultant?: number;
  consultantName?: string;
  organizer?: number;

  scheduleFrom?: string;
  scheduleTo?: string;
  createdFrom?: string;
  createdTo?: string;
  updatedFrom?: string;
  updatedTo?: string;

  type?: string;

  allowInvite?: boolean;
  enabled?: boolean;
  hasDocClin?: boolean;
  hasDocSup?: boolean;

  healthPlan?: Array<string | number>;
  priority?: Array<string | number>;
  status?: Array<string | number>;
  modality?: Array<string | number>;
  hasCompanion?: boolean;
  hasOtherProf?: boolean;
}

export const getAppointmentsByDate = (
  date: string,
  userLogged: iUser,
  filter?: iFilter,
) => {
  const store: iStore = storeDev.getState();
  const { orgId, role, orgUnitId, orgUnits } = store.auth.selectUser;

  const dateInit = new Date(date);
  const scheduleFrom = datefns.format(dateInit, 'yyyy-MM-dd');
  const scheduleTo = datefns.format(datefns.addDays(dateInit, 2), 'yyyy-MM-dd');

  const professionalId = userLogged.professionals?.find(
    item => item.orgUnit.id === orgUnitId,
  )?.id;

  const consultantId = userLogged.consultants?.find(
    item => item.org?.id === orgId,
  )?.id;

  const orgUnitsFormatted =
    (orgUnits?.map(item => item.id).filter(Boolean) as number[]) ?? [];

  filter = {
    scheduleFrom,
    scheduleTo,
    org: orgId,
    orgUnit: role === 'ORG' || role === 'PRO' ? orgUnitsFormatted : undefined,
    ...filter,
  };

  switch (role) {
    case 'CON':
      makeReduxGetAllAppointment().getAll({
        filter: {
          consultant: consultantId,
          ...filter,
        },
      });
      break;

    case 'PRO':
      makeReduxGetAllAppointment().getAll({
        filter: {
          professional: professionalId,
          ...filter,
        },
      });
      break;

    case 'ORG':
    case 'ADM':
      makeReduxGetAllAppointment().getAll({
        filter,
      });
      break;

    default:
      break;
  }
};
