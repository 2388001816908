import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '~/presentation/components/UI';
import { iStore } from '~/domain/interfaces/models';
import { History } from '~/main/routes';
import { HealthUnitCard, Text } from './styles';

interface HealthUnitProps {
  data: number | undefined;
}

const HealthUnit: React.FC<HealthUnitProps> = ({ data }) => {
  const { records: healthUnits } = useSelector(
    (store: iStore) => store.healthUnits,
  );

  const selectedHealthUnit = useMemo(
    () => healthUnits?.find(healthUnit => healthUnit?.id === data),
    [data, healthUnits],
  );

  const redirect = useCallback(() => {
    History.getHistory().push('/duty/me', {
      specialty: 'cardiologia',
      healthUnitId: selectedHealthUnit?.id,
      healthUnitName: selectedHealthUnit?.name,
      cnes: selectedHealthUnit?.cnes,
      city: selectedHealthUnit?.city,
    });
  }, [selectedHealthUnit]);

  return (
    <HealthUnitCard>
      <div style={{ padding: 16 }}>
        <Text size={18} weight={600}>
          Unidade de Saúde
        </Text>
      </div>
      <div style={{ borderBottom: '1px solid #dedede' }} />
      {!selectedHealthUnit ? (
        <div
          style={{
            height: '300px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // padding: 16,
          }}
        >
          <Text size={14} weight={400} color="#818385">
            Selecione ao lado a unidade de saúde do seu plantão.
          </Text>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '300px',
            gap: 16,
            padding: 24,
          }}
        >
          <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
            <Text size={14} weight={500}>
              Nome:
            </Text>
            <Text size={14} weight={400}>
              {selectedHealthUnit?.name ?? 'Não informado'}
            </Text>
          </div>
          <div style={{ display: 'flex', gap: 8 }}>
            <Text size={14} weight={500}>
              CNES:
            </Text>
            <Text size={14} weight={400}>
              {selectedHealthUnit?.cnes ?? 'Não informado'}
            </Text>
          </div>
          <div style={{ display: 'flex', gap: 8 }}>
            <Text size={14} weight={500}>
              Endereço:
            </Text>
            <Text size={14} weight={400}>
              {selectedHealthUnit?.city?.name ?? 'Não informado'}
            </Text>
          </div>
        </div>
      )}
      <div style={{ borderBottom: '1px solid #dedede' }} />
      <div style={{ padding: '16px 32px' }}>
        <Button onClick={redirect} disabled={!selectedHealthUnit}>
          Entrar no plantão virtual
        </Button>
      </div>
    </HealthUnitCard>
  );
};

export default HealthUnit;
