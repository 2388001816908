import {
  number,
  object,
  string,
  boolean,
  array,
  union,
  literal,
  enum as a,
} from 'zod';

export const schemaGeneralData = object({
  consultant: number().nonnegative({
    message: 'Consultante inválido ou não encontrado.',
  }),
  healthPlan: number().nonnegative().optional(),
  type: number().nonnegative({
    message: 'Tipo de atendimento inválido.',
  }),
  title: string().max(128).optional(),
  healthUnit: number().optional(),
  priority: number().nonnegative().optional(),
  modality: a(['INPERSON', 'REMOTE']).optional(),
  obs: string()
    .max(1024, { message: 'Observação deve ter no máximo 1024 caracteres.' })
    .optional(),
  allowTranscription: boolean().optional(),
});

export const schemaDateAndTime = object({
  date: string()
    .nonempty({ message: 'Data inválida' })
    .min(1, { message: 'Hora de ínicio incorreta' }),
  hourStart: string().nonempty({ message: 'Hora de ínicio incorreta' }),
  hourEnd: string().nonempty({ message: 'Hora final incorreta' }),
});

export const schemaGuest = object({
  email: union([string().email({ message: 'Email inválido.' }), literal('')]),
  name: string().max(128).optional(),
});

export const schema = object({
  org: number().nonnegative(),
  orgUnit: number().nonnegative(),
  professional: number().nonnegative(),
  specialty: number().nonnegative(),
  consultant: number().nonnegative(),
  organizer: number().nonnegative(),
  healthPlan: number().nonnegative().optional(),
  healthUnit: number().nonnegative().optional(),
  obs: string()
    .max(1024, { message: 'Observação deve ter no máximo 1024 caracteres.' })
    .optional(),
  priority: number().nonnegative().optional(),
  modality: a(['INPERSON', 'REMOTE']).optional(),
  allowTranscription: boolean().optional(),
  type: number().nonnegative(),
  allowInvite: boolean().optional(),
  date: string(),
  duration: number(),
  note: string().nonempty().optional(),
  companions: array(
    object({
      id: number().nonnegative().optional(),
      name: string().optional(),
      phone: string().optional(),
      email: string(),
      role: a([
        'GRANDPARENT',
        'PARENT',
        'SPOUSE',
        'SIBLING',
        'SON',
        'RELATIVE',
        'FRIEND',
        'OTHER',
      ]),
    }),
  ).optional(),
  professionals: array(
    object({
      id: number().nonnegative().optional(),
      name: string(),
      phone: string().optional(),
      email: string().optional(),
      specialty: string(),
    }),
  ).optional(),
  title: string().optional(),
});
