import { makeApiUrl, makeHttpClient } from '~/main/factories/http';
import { GetInvoiceService as UsecaseRemoteGetInvoiceService } from '~/domain/usecases/billing/remote';
import { GetInvoiceService as UseCaseReduxGetInvoiceService } from '~/domain/usecases/billing/redux';

import { RemoteGetInvoiceService } from '~/data/repository/billing';
import { ReduxGetInvoiceService } from '~/data/store/reducer/billing/usecases';

/**
 * send request via API.
 */
export const makeRemoteGetInvoiceService = (): UsecaseRemoteGetInvoiceService =>
  new RemoteGetInvoiceService(makeApiUrl('/billing'), makeHttpClient());

/**
 * send request via REDUX.
 */
export const makeReduxGetInvoiceService = (): UseCaseReduxGetInvoiceService =>
  new ReduxGetInvoiceService();
