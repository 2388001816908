import { CancelAppointment } from '~/domain/usecases/appointment/redux';
import {
  iActionCancel,
  iActionCancelFailed,
  iActionCancelSuccess,
  AppointmentTypes,
} from '~/domain/interfaces/redux/appointment/cancelAppointment';

export const cancelRequest = (
  payload: CancelAppointment.Params,
): iActionCancel => ({
  type: AppointmentTypes.CANCEL,
  payload,
});

export const cancelSuccess = (): iActionCancelSuccess => ({
  type: AppointmentTypes.CANCEL_SUCCESS,
});

export const cancelFailed = (): iActionCancelFailed => ({
  type: AppointmentTypes.CANCEL_FAILED,
});
