import { takeLatest } from 'redux-saga/effects';
import { onGetAll, onGetAllSuccess, onGetAllFailed } from './GetAll';
import { onCreate, onCreateSuccess, onCreateFailed } from './Create';
import { ConsultantTypes } from '~/domain/interfaces/redux/Consultant/types';

const tasks = [
  takeLatest(ConsultantTypes.GETALL, onGetAll),
  takeLatest(ConsultantTypes.GETALL_SUCCESS, onGetAllSuccess),
  takeLatest(ConsultantTypes.GETALL_FAILED, onGetAllFailed),
  takeLatest(ConsultantTypes.CREATE, onCreate),
  takeLatest(ConsultantTypes.CREATE_SUCCESS, onCreateSuccess),
  takeLatest(ConsultantTypes.CREATE_FAILED, onCreateFailed),
];

export default tasks;
